import { fromJS } from 'immutable';
import get from 'lodash/get';
import { AnyAction } from 'redux';

import * as ActionTypes from './actionTypes';
import { GetOwnerResponse, OwnerState } from './types';

const initialState = fromJS({
  fullProducts: {},
});

interface OnProductAction extends AnyAction {
  meta: {
    productId: string;
  };
  payload?: GetOwnerResponse;
}

function onProductLoading(state: OwnerState, { meta }: OnProductAction) {
  return state.setIn(
    ['fullProducts', meta.productId],
    fromJS({ isLoaded: false, value: {} }),
  );
}

function onProductLoaded(
  state: OwnerState,
  { meta, payload }: OnProductAction,
) {
  const productId = get(meta, ['productId']);
  const product = get(payload, ['value']);

  return state.setIn(
    ['fullProducts', productId],
    fromJS({ isLoaded: true }).setIn(['value'], product),
  );
}

function onProductLoadingIsErrored(
  state: OwnerState,
  { meta }: OnProductAction,
) {
  return state.updateIn(['fullProducts', meta.productId], (oldValue) =>
    oldValue.setIn(['isDirty'], true).setIn(['hasErrors'], true),
  );
}

const ownerReducer = (state: OwnerState = initialState, action: AnyAction) => {
  switch (action.type) {
    case ActionTypes.GET_PRODUCT_FULL_INFO.STARTED:
      return onProductLoading(state, action as OnProductAction);

    case ActionTypes.GET_PRODUCT_FULL_INFO.SUCCEEDED:
      return onProductLoaded(state, action as OnProductAction);
    case ActionTypes.GET_PRODUCT_FULL_INFO.ERRORED:
      return onProductLoadingIsErrored(state, action as OnProductAction);

    default:
      return state;
  }
};

export default ownerReducer;
