import styled from 'styled-components';

import { rem } from '../../helpers';
import { getTextColorBasedOnProps } from '../../theme/helpers';

const H4 = styled.h4.attrs({ role: 'presentation' })`
  font-size: ${rem('15px')};
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
  margin: 0;
  padding-top: ${rem('4px')};
  padding-bottom: ${rem('4px')};
  color: ${(props) => getTextColorBasedOnProps(props)};
  text-align: ${(props) => props.align};
`;

export default H4;
