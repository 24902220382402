import React from 'react';
import { $StepNumber, $ColContainer } from './StepNumber.styles';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { ClaimGroupDiagnosticTreePageReducerState } from '../../ClaimGroupDiagnosticTreePage.reducer';
import messages from '../../messages';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';
import { ICONS_TYPE } from '@savgroup-front-common/types';
import { useTheme } from 'styled-components';

interface StepNumberProps {
  currentDiagnosticTreeStep: ClaimGroupDiagnosticTreePageReducerState;
}

const StepNumber: React.FC<StepNumberProps> = ({
  currentDiagnosticTreeStep,
}) => {
  const theme = useTheme();

  return (
    <$ColContainer sm={3}>
      {currentDiagnosticTreeStep.latestStep.map((lastStep) => {
        return (
          <$StepNumber
            key={'uniqueName' in lastStep ? lastStep.uniqueName : ''}
          >
            <SafeFormattedMessageWithoutSpread
              message={messages.stepNumber}
              values={{
                value:
                  'currentStepNumber' in lastStep
                    ? lastStep.currentStepNumber
                    : undefined,
              }}
            />
            <MyAccountIcon
              icon={ICONS_TYPE.CHECK_CIRCLE_OUTLINE_ICON}
              color={theme.colors.success}
            />
          </$StepNumber>
        );
      })}
      <$StepNumber>
        <SafeFormattedMessageWithoutSpread
          message={messages.stepNumber}
          values={{ value: currentDiagnosticTreeStep.currentStepNumber }}
        />
      </$StepNumber>
    </$ColContainer>
  );
};

StepNumber.displayName = 'StepNumber';

export default StepNumber;
