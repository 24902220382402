import React, { FC } from 'react';
import {
  $CardContentSmallThumbnail,
  $CardContentSmallThumbnailWrapper,
} from '../MultiProductInfoDescription.styles';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';
import { ICONS_TYPE } from '@savgroup-front-common/types';
import useGetModelById from 'myaccount/hooks/useGetModelById';
import { ProductImage } from '@savgroup-front-common/core/src/atoms/ProductImage';

interface ModelsIconProps {
  modelId: string;
}

export const ModelsIcon: FC<ModelsIconProps> = ({ modelId }) => {
  const { model, isLoading } = useGetModelById({ modelId });

  if (isLoading) {
    return <></>;
  }

  return (
    <$CardContentSmallThumbnailWrapper>
      <$CardContentSmallThumbnail>
        <>
          {model?.pictureUrl ? (
            <ProductImage pictureUrl={model?.pictureUrl} title="pictureUrl" />
          ) : (
            <MyAccountIcon icon={ICONS_TYPE.BOX_ICON} />
          )}
        </>
      </$CardContentSmallThumbnail>
    </$CardContentSmallThumbnailWrapper>
  );
};

ModelsIcon.displayName = 'ModelsIcon';
