import * as Yup from 'yup';

import messages from './messages';

export default () => {
  return Yup.object().shape({
    comment: Yup.string(),
    file: Yup.object()
      .nullable()
      .test('required-file', messages.fileRequired, (value) => {
        return Boolean(value);
      }),
  });
};
