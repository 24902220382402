import { useQuery } from 'react-query';

import { STALE_TIME } from '@savgroup-front-common/constants';
import { OwnerService } from 'myaccount/api';

export const GetOrderDetail = 'getOrderDetailsQuery';

export const useGetOrderDetailsQuery = ({ orderId }: { orderId?: string }) => {
  const { data: orderDetail } = useQuery(
    [GetOrderDetail, { orderId }],
    async () => {
      if (!orderId) {
        return undefined;
      }

      const response = await OwnerService.getOrderDetailsQuery({
        orderId,
      });

      if (response.failure) {
        return undefined;
      }

      return response.value;
    },
    {
      staleTime: STALE_TIME.MINUTES_1,
    },
  );

  return {
    orderDetail,
  };
};
