import React, { useContext } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useTitle } from 'react-use';

import { NeedHelp } from '@savgroup-front-common/core/src/components/NeedHelp';
import { safeFormattedIntlString } from '@savgroup-front-common/core/src/formatters';
import { AppErrorBoundary } from '@savgroup-front-common/core/src/molecules/ErrorBoundary';
import { AuthConfiguration } from 'myaccount/configuration';

import { BASE_ROUTES, ROUTES } from '../routes/Routes';

import Header from './header/Header';
import { InitContext } from './InitProvider/InitProvider.context';
import messages from './messages';
import { $PageWrapper } from './NewLayout.styles';
import {
  otherPageProfessionalConfig,
  professionalStepsConfig,
} from './ProfessionalRoutes.config';
import StepWrapper from './StepWrapper/StepWrapper';

interface ProfessionalProps {
  sellerId: string;
}

const ProfessionalLayout: React.FC<ProfessionalProps> = ({ sellerId }) => {
  const { enableServicePortal } = useContext(InitContext);
  const location: any = useLocation();

  useTitle(safeFormattedIntlString(messages.b2BPortalTitle));

  return (
    <AppErrorBoundary>
      <$PageWrapper>
        <NeedHelp clientId={AuthConfiguration.clientId} />
        <Header sellerId={sellerId} enableServicePortal={enableServicePortal} />

        <Switch location={location.location}>
          {enableServicePortal && (
            <Redirect
              from={ROUTES.PRODUCTS}
              to={`${BASE_ROUTES.PROFESSIONAL}${BASE_ROUTES.HOME}`}
              push
            />
          )}
          {otherPageProfessionalConfig.map((page) => (
            <Route
              key={page.name}
              exact
              path={page.path}
              component={page.component}
            />
          ))}
          <Route path={BASE_ROUTES.PROFESSIONAL}>
            {professionalStepsConfig.map((page) => {
              return (
                <Route
                  key={page.name}
                  exact
                  path={page.path}
                  render={() => (
                    <StepWrapper
                      enableServicePortal={enableServicePortal}
                      isBtoBPortalEnabled
                    >
                      <page.component />
                    </StepWrapper>
                  )}
                />
              );
            })}
          </Route>
        </Switch>
      </$PageWrapper>
    </AppErrorBoundary>
  );
};

ProfessionalLayout.displayName = 'ProfessionalLayout';

export default ProfessionalLayout;
