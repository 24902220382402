import React, { FunctionComponent, ReactElement, createContext } from 'react';
import { Grid } from 'react-styled-flexboxgrid';
import { useMedia } from 'react-use';

import { media } from '@savgroup-front-common/constants';

import { $Content, $ContentWrapper } from './BanneredLayout.styles';
import { useLayout } from '../NewLayout.hooks';

export type StepWrapperContextValues = {
  containerHeight?: number;
};

export const StepWrapperContext = createContext<StepWrapperContextValues>({
  containerHeight: undefined,
});

const Content: FunctionComponent<{
  children?: ReactElement | ReactElement[];
  hasBannerUrl?: boolean;
}> = ({ children, hasBannerUrl }) => {
  const isGridFluidActive = useMedia(media.maxWidth.md);
  const { enableServicePortal } = useLayout();

  return (
    <$ContentWrapper>
      <Grid fluid={isGridFluidActive}>
        <$Content
          $hasBannerUrl={hasBannerUrl}
          $enableServicePortal={enableServicePortal}
        >
          {children}
        </$Content>
      </Grid>
    </$ContentWrapper>
  );
};

Content.displayName = 'Content';
export default Content;
