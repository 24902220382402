import styled from 'styled-components';

import { Link } from '@savgroup-front-common/core/src/atoms/link';

export const $ResumeCardContainer = styled.div`
  background: #f7f7f7;
  border-radius: 8px;
  padding: 18px 24px;
  height: fit-content;
`;

export const $ResumeDescriptionProduct = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;
export const $ResumePictureProduct = styled.div``;
export const $CardContentThumbnailWrapper = styled.div``;
export const $CardContentThumbnail = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e1e4e8;
  border-radius: 4px;
  margin-right: 16px;
  padding: 2px;
  max-width: 40px;
  max-height: 40px;
  overflow: hidden;
  img {
    object-fit: contain;
    max-height: 4.28571rem;
    max-width: 4.28571rem;
  }
`;

export const $ResumeProductTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

export const $ResumeProductTitle = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.systemColors.defaultTextColor};
`;

export const $ResumeInfoProduct = styled.div``;

export const $ResumeInfoProductTitle = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.systemColors.defaultTextColor};
`;

export const $ResumeInfoProductSerialNumber = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  font-size: 14px;
  line-height: 16px;
  color: #8a94a6;
  padding-top: 8px;
`;

export const $ResumeInfoProductTag = styled.div`
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.success};
`;

export const $ResumeSeparator = styled.div`
  border: 1px solid #e1e4e8;
  width: 100%;
  height: 0;
  margin: 1rem 0;
`;

export const $ResumeStateProduct = styled.div``;

export const $ResumeStateLabel = styled.span`
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  font-size: ${({ theme }) => theme.fonts.size.ultraSmall};
  line-height: 20px;
  color: #8a94a6;
  margin-right: 0.5rem;
`;
export const $ResumeStateValue = styled.span`
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  font-size: ${({ theme }) => theme.fonts.size.ultraSmall};
  line-height: 20px;
  color: ${({ theme }) => theme.systemColors.defaultTextColor};
`;

export const $ResumeStateProductModify = styled(Link)`
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: right;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.systemColors.defaultTextColor};
`;
export const $ResumeStateProductReason = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  font-size: ${({ theme }) => theme.fonts.size.ultraSmall};
  line-height: 20px;
  color: ${({ theme }) => theme.systemColors.defaultTextColor};
`;
export const $ResumeStateProductKeyValue = styled.div`
  margin: 0.5rem 0;
`;
export const $ResumeStateProductDocuments = styled.div`
  ${$ResumeStateValue} {
    display: block;
    margin-top: 0.5rem;
    margin-left: 0;
  }
`;

export const $ResumeStateValueDocument = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  font-size: ${({ theme }) => theme.fonts.size.ultraSmall};
  line-height: 16px;
  text-decoration-line: underline;
  margin-bottom: 0.5rem;
  & svg {
    margin-bottom: -2px;
  }
`;

export const $ResumePriceProduct = styled.div``;
export const $ResumePriceProductInfoAmountWrapper = styled.div`
  display: flex;
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  font-size: ${({ theme }) => theme.fonts.size.ultraSmall};
  color: ${({ theme }) => theme.systemColors.defaultTextColor};
  justify-content: space-between;
  margin-bottom: 0.5rem;
  align-items: center;
`;
export const $ResumePriceProductInfoAmountLabel = styled.div`
  line-height: 20px;
`;
export const $ResumePriceProductInfoAmountValue = styled.div`
  line-height: 14px;
`;
export const $ResumePriceProductInfoAmountTotalLabel = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
`;
export const $ResumePriceProductInfoAmountTotalValue = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
`;
export const $ResumePriceProductInfoAmountTotalDescriptoinTax = styled.span`
  color: #8a94a6;
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  margin-left: 0.5rem;
  font-size: ${({ theme }) => theme.fonts.size.ultraSmall};
`;
