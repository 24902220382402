import moment from 'moment';

import { STANDARD_DATE_TIME_FORMAT } from '@savgroup-front-common/constants';
import {
  ADDITIONAL_INFORMATION_TYPES,
  TEXT_ADDITIONAL_INFORMATION_TYPES,
} from '@savgroup-front-common/types';

import { intl } from '../../../../helpers';

export const formatDate = (date: any): string =>
  intl?.formatDate(date, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }) || '';

const getAdditionalInformationLabel = ({
  informationType,
  additionalInformationStringValue,
  additionalInformationEnumValue,
  additionalInformationEntitiesMap,
}: {
  informationType: ADDITIONAL_INFORMATION_TYPES;
  additionalInformationStringValue?: string;
  additionalInformationEnumValue?: string;
  additionalInformationEntitiesMap: Record<string, { label: string }>;
}): string | undefined => {
  if (TEXT_ADDITIONAL_INFORMATION_TYPES.includes(informationType)) {
    return additionalInformationStringValue;
  }

  if (ADDITIONAL_INFORMATION_TYPES.DATE === informationType) {
    return additionalInformationStringValue
      ? formatDate(
          moment(
            additionalInformationStringValue,
            STANDARD_DATE_TIME_FORMAT,
          ).toDate(),
        )
      : additionalInformationStringValue;
  }

  if (
    additionalInformationEnumValue &&
    ADDITIONAL_INFORMATION_TYPES.ENUM === informationType
  ) {
    return additionalInformationEntitiesMap[additionalInformationEnumValue]
      .label;
  }

  return undefined;
};

export default getAdditionalInformationLabel;
