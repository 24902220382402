import { Col, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants/src/shared';

export const $QuoteItemSummaryRow = styled(Row)`
  margin-bottom: 0.75rem;
  color: ${({ theme }) => theme.colors.paragraphTextColor};
  padding: 0;

  @media ${media.minWidth.md} {
    padding: 0 2rem;
  }
`;

export const $QuoteItemSummariesStyled = styled.div`
  margin-top: 10px;
`;

export const $QuoteItemSummariesTitle = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  margin-bottom: 10px;
  margin-top: 15px;
`;

export const $QuoteItemSummariesCommments = styled.div`
  padding: 0;
  color: ${({ theme }) => theme.colors.paragraphTextColor};

  @media ${media.minWidth.md} {
    padding: 0 2rem;
  }
`;

export const $InlinePriceCol = styled(Col)`
  flex: none;
`;
