import React, { FC } from 'react';

import LoaderTools from './LoaderTools';
import messages from './messages';
import BaseLoader from '../../../../../../../molecules/BaseLoader/BaseLoader';
import { BASE_LOADER_DIRECTION } from '../../../../../../../molecules/BaseLoader';

export const AppointmentCalendarLoader: FC = () => {
  return (
    <BaseLoader
      size="56px"
      customLoader={<LoaderTools />}
      hasMargin={false}
      direction={BASE_LOADER_DIRECTION.COLUMN}
      messageList={[
        messages.searchingForTechnician,
        messages.searchingAvailability,
        messages.synchronizeAgendas,
        messages.regroupingTimeslots,
        messages.optimizingTechnicianItinerary,
      ]}
    />
  );
};

AppointmentCalendarLoader.displayName = 'AppointmentCalendarLoader';
