import React, { FunctionComponent } from 'react';

import { $CardSeparator } from './Card.styles';

interface CardSeparatorProps {
  withoutPaddingTop?: boolean;
  withoutPaddingBottom?: boolean;
  withoutPadding?: boolean;
}

const CardSeparator: FunctionComponent<CardSeparatorProps> = ({
  withoutPaddingTop = false,
  withoutPaddingBottom = false,
  withoutPadding = false,
}) => (
  <$CardSeparator
    $withoutPaddingTop={withoutPaddingTop}
    $withoutPaddingBottom={withoutPaddingBottom}
    $withoutPadding={withoutPadding}
  />
);

CardSeparator.displayName = 'CardSeparator';

export default CardSeparator;
