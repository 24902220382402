import { fromJS } from 'immutable';
import get from 'lodash/get';

export function onRmaSupplierInfosLoading(state, { meta }) {
  const claimId = get(meta, ['claimId']);

  return state.setIn(
    ['rmaSupplierInfos', claimId],
    fromJS({ isLoaded: false, isDirty: false, value: undefined }),
  );
}

export function onRmaSupplierInfosErrored(state, { meta }) {
  const claimId = get(meta, ['claimId']);

  return state.updateIn(['rmaSupplierInfos', claimId], (oldValue) =>
    oldValue.setIn(['isDirty'], true).setIn(['hasErrors'], true),
  );
}

export function onRmaSupplierInfosLoaded(state, { payload, meta }) {
  const claimId = get(meta, ['claimId']);
  const value = get(payload, 'value');

  return state.setIn(
    ['rmaSupplierInfos', claimId],
    fromJS({ isLoaded: true, isDirty: false }).setIn(['value'], value),
  );
}
