import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';

import { REVALIDATE_MODES } from '@savgroup-front-common/constants';
import { FileShortSummary, OrderSummary } from '@savgroup-front-common/types';
import { ClaimService } from 'myaccount/api';
import { STEP_CONFIG } from 'myaccount/view/app/NewLayout/ClassiqueRoutes.config';
import {
  StepContext,
  StepContextValues,
} from 'myaccount/view/app/NewLayout/StepsProvider/StepsProvider.context';

import chooseProductSchema from './ChooseProductPage.schema';
import { ChoiceProductValues } from './ChooseProductPage.types';

const SET_CREATE_CLAIM = 'setCreateClaim';

interface UseChooseProductReturnValues {
  orders: OrderSummary[];
  openedFiles: FileShortSummary[];
  issue?: {
    id: string;
    winningWarrantyTypeId: string;
    name: string;
    didactic: string;
    fromProductWarranty: boolean;
    displayOrder: number;
    ownerProductIds?: string[];
    sellerProductIds?: string[];
    uniqueName: string;
    key: string;
    iconKey: string;
  };
  formContext: UseFormReturn<ChoiceProductValues>;
  onSubmit: () => void;
  isLoadingSubmit: boolean;
}

const useChooseProduct = (): UseChooseProductReturnValues => {
  const { values, handleNextStep, handleValidateStep, checkIfIsMultiProduct } =
    useContext<StepContextValues>(StepContext);
  const { ownerId, orders, issue } = values;
  const { removeAllNotifications, pushErrors } = useToasts();

  const formContext = useForm<ChoiceProductValues>({
    resolver: yupResolver(chooseProductSchema),
    defaultValues: {
      selectedOwnerProductIds: [],
    },
    mode: REVALIDATE_MODES.ON_CHANGE,
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = formContext;

  const { mutateAsync: handleCreateClaim, isLoading: isLoadingSubmit } =
    useMutation(
      [SET_CREATE_CLAIM],
      async ({
        issueId,
        ownerId,
        ownerProductIds,
      }: {
        issueId: string;
        ownerId: string;
        ownerProductIds?: string[];
      }) => {
        removeAllNotifications();
        if (!issueId || !ownerId || !ownerProductIds) {
          return undefined;
        }

        const response = await ClaimService.setCreateClaimGroup({
          issueId,
          ownerId,
          ownerProductIds,
        });

        if (response.failure) {
          pushErrors(response.errors);

          return undefined;
        }

        return response.value;
      },
    );

  const onSubmit = handleSubmit(async ({ selectedOwnerProductIds }) => {
    if (issue && ownerId) {
      const response = await handleCreateClaim({
        issueId: issue.id,
        ownerId,
        ownerProductIds: selectedOwnerProductIds,
      });

      if (!response) {
        return undefined;
      }

      checkIfIsMultiProduct(selectedOwnerProductIds.length > 1);

      return handleNextStep(STEP_CONFIG.CLAIM_GROUP_REASON, response);
    }

    return undefined;
  });

  useEffect(() => {
    handleValidateStep(!!isValid);
  }, [handleValidateStep, isValid]);

  return {
    orders: orders || [],
    openedFiles: values?.openedFiles || [],
    issue: values?.issue || undefined,
    formContext,
    onSubmit,
    isLoadingSubmit,
  };
};

export default useChooseProduct;
