import { defineMessages } from 'react-intl';

export default defineMessages({
  defaultActorInfoTitle: {
    id: 'view.procedure.defaultActorInfoTitle',
    defaultMessage: 'Contact info',
  },
  fullName: {
    id: 'general.fullName',
    defaultMessage: 'Full name',
  },
  address: {
    id: 'view.country.form.address',
    defaultMessage: 'Address',
  },
  doorCode: {
    id: 'general.doorCode',
    defaultMessage: 'Door code',
  },
  phone: {
    id: 'view.search.client.phoneNumber',
    defaultMessage: 'Phone',
  },
  additionalInformation: {
    id: 'general.additionalInformation',
    defaultMessage: 'Additional information',
  },
  floor: { id: 'general.floor', defaultMessage: 'Floor' },
  additionalAddress: {
    id: 'general.additionalAddress',
    defaultMessage: 'Additional address',
  },
  email: {
    id: 'view.card.client.email',
    defaultMessage: 'Email',
  },
  website: {
    id: 'view.procedure.website',
    defaultMessage: 'Website',
  },
  note: {
    id: 'view.actor.note',
    defaultMessage: 'Note',
  },
  actorType: {
    id: 'view.actor.type',
    defaultMessage: 'Type',
  },
});
