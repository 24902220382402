export const buildInputName = ({
  prefixFormName,
  name,
}: {
  prefixFormName?: string;
  name: string;
}) => {
  if (!prefixFormName) {
    return name;
  }

  return `${prefixFormName}.${name}`;
};
