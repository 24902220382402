import { useMutation } from 'react-query';
import { HANDLING_MODES } from '@savgroup-front-common/constants';
import {
  AddressInfoDto,
  PickupPointAddress,
} from '@savgroup-front-common/types';
import { ClaimService } from '../../../../../api';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { useParams } from 'react-router-dom';

export const useSetClaimGroupHandling = () => {
  const { claimGroupId } = useParams<{ claimGroupId: string }>();
  const { removeAllNotifications, pushErrors } = useToasts();

  const {
    mutateAsync: handleSetDepositHandling,
    isLoading: isLoadingDepositHandlingSubmit,
  } = useMutation(
    ['setDepositHandling'],
    async ({
      handlingMode,
      carrierCustomerPriceId,
      carrierSellerPriceId,
      pickupPointId,
      pickupPointAddress,
      address,
    }: {
      handlingMode: HANDLING_MODES;
      pickupPointId?: string;
      pickupPointAddress?: PickupPointAddress | null;
      carrierCustomerPriceId?: string;
      carrierSellerPriceId?: string;
      address?: AddressInfoDto | null;
    }) => {
      removeAllNotifications();

      const responseHandling = await ClaimService.setClaimGroupHandling({
        claimGroupId,
        payload: {
          handlingMode,
          carrierCustomerPriceId,
          carrierSellerPriceId,
          pickupPointId,
          pickupPointAddress,
          address,
        },
      });

      if (responseHandling.failure) {
        pushErrors(responseHandling.errors);

        return undefined;
      }

      return responseHandling.value;
    },
  );

  return {
    handleSetDepositHandling,
    isLoadingDepositHandlingSubmit,
  };
};
