import { format, getTime } from 'date-fns';
import React, { FunctionComponent } from 'react';

import { Timeslot } from '@savgroup-front-common/types';

import { CalendarTimeBlock } from '../../AppointmentCalendarLegacy.styles';

import message from './messages';
import { $SingleSlotSelector } from './TimeSlotSelectorLegacy.styles';
import { SafeFormattedMessage } from '../../../../../../../../formatters';
import { useTakeInterventionAppointmentLegacyContext } from '../../../../TakeInterventionAppointmentLegacy.context';

interface TimeSlotSelectorLegacyProps {
  timeslot: Timeslot;
  isActive: boolean;
  onTimeslotSelect: ({
    selectedTimeslot,
  }: {
    selectedTimeslot: Timeslot;
  }) => void;
}

const TimeSlotSelectorLegacy: FunctionComponent<
  TimeSlotSelectorLegacyProps
> = ({ timeslot, isActive = true, onTimeslotSelect }) => {
  const { selectedTimeslot } = useTakeInterventionAppointmentLegacyContext();
  const { allDaySchedule, start, end } = timeslot;

  return (
    <CalendarTimeBlock
      $fullHeight={allDaySchedule}
      key={`${getTime(timeslot.start)}-${getTime(timeslot.end)}`}
      clickable={isActive}
      active={
        selectedTimeslot && timeslot
          ? selectedTimeslot.internalId === timeslot.internalId
          : false
      }
      onClick={() =>
        isActive ? onTimeslotSelect({ selectedTimeslot: timeslot }) : undefined
      }
      data-testid="schedule"
    >
      {allDaySchedule && (
        <$SingleSlotSelector>
          <SafeFormattedMessage {...message.allDaySlotLabel} />
        </$SingleSlotSelector>
      )}
      {!allDaySchedule && (
        <div>
          <div>{format(start, 'HH:mm')}</div>
          <div>{' - '}</div>
          <div>{format(end, 'HH:mm')}</div>
        </div>
      )}
    </CalendarTimeBlock>
  );
};

TimeSlotSelectorLegacy.displayName = 'TimeSlotSelectorLegacy';

export default TimeSlotSelectorLegacy;
