import styled from 'styled-components';

export const $ImportantHighlight = styled.span`
  font-size: 16px;
  font-weight: bold;
`;

export const $LineContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-grow: 2;
`;
export const $Line = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.mainTextColor};
  margin: 0 1rem;
  width: 100%;
  height: 51%;
  padding: 0 1rem;
`;

export const $Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
`;

export const $PriceField = styled.div`
  min-width: 70px;
  text-align: right;
`;
