export const arrayBufferFromBlob = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      resolve(event.target.result);
    };
    reader.onerror = (event) => {
      reject(event.message);
    };
    reader.readAsArrayBuffer(file);
  });
};
