import { defineMessages } from 'react-intl';

export default defineMessages({
  uploadFailed: {
    id: 'view.attachmentsList.uploadFailed',
    defaultMessage: 'Upload failed!',
  },
  uploadSucceeded: {
    id: 'view.attachmentsList.uploadSucceeded',
    defaultMessage: 'Upload succeeded!',
  },
});
