import { useQuery } from 'react-query';

import { FileProductSummary } from '@savgroup-front-common/types';
import { CatalogService } from 'myaccount/api';

export const GetModelByIdQuery = 'getModelByIdQuery';

export interface ProductsInfoDto extends FileProductSummary {
  buyDate?: Date;
  fileReference: string;
  isClosed: boolean;
  solutionId: string;
  fileId?: string;
}

export const useGetModelByIdQuery = ({
  productsInfo,
}: {
  productsInfo?: ProductsInfoDto[];
}) => {
  const { data: models, isLoading } = useQuery(
    [GetModelByIdQuery, { productsInfo }],
    async () => {
      if (!productsInfo || productsInfo.length === 0) {
        return undefined;
      }
      const modelIdUsed: string[] = [];

      return Promise.all(
        productsInfo?.map(async (productInfo) => {
          if (!modelIdUsed.includes(productInfo.modelId)) {
            modelIdUsed.push(productInfo.modelId);
            const response = await CatalogService.getModelByIdQuery({
              modelId: productInfo.modelId,
            });

            if (response.failure) {
              return undefined;
            }

            return response.value;
          }

          return undefined;
        }),
      );
    },
    {
      staleTime: Infinity,
      suspense: true,
    },
  );

  return {
    models,
    isLoading,
  };
};
