/**
 * transition to data without immutable
 * @deprecated Since we will no longer use immutable on components this method will be removed
 */
export const getFromDataByKey = (
  data: any,
  key: string,
  fallbackValue: any = null,
) => {
  if (data === undefined || data === null) {
    return fallbackValue;
  }

  if (typeof data.get === 'function') {
    return data.get(key) || fallbackValue;
  }

  return data[key] !== undefined ? data[key] : fallbackValue;
};

/**
 * transition to data without immutable
 * @deprecated Since we will no longer use immutable on components this method will be removed
 */
export const getInFromDataByKey = (
  data: any,
  keys: string[] = [],
  fallbackValue: any = null,
) => {
  return keys.reduce((acc, key) => {
    return getFromDataByKey(acc, key, fallbackValue);
  }, data);
};

/**
 * transition to data without immutable
 * @deprecated Since we will no longer use immutable on components this method will be removed
 */
export const toJS = (data: any) => {
  if (data === undefined || data === null) {
    return data;
  }

  if (typeof data.toJS === 'function') {
    return data.toJS();
  }

  return data;
};
