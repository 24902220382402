import { useContext, useEffect } from 'react';

import {
  StepContext,
  StepContextValues,
} from 'myaccount/view/app/NewLayout/StepsProvider/StepsProvider.context';

import useGetClaimGroupSummary from '../../hooks/useGetClaimGroupSummary';
import useGetIssuesByOrder from '../../hooks/useGetIssuesByOrder';
import { useLayout } from '../NewLayout.hooks';

interface UseStepProviderArgs {
  claimGroupId?: string;
  issueId?: string;
}

interface UseStepProviderReturnValues {
  isLoading: boolean;
  name: string;
}

const useStepWrapper = ({
  claimGroupId,
  issueId,
}: UseStepProviderArgs): UseStepProviderReturnValues => {
  const { login } = useLayout();
  const { values, handleUpdateValue, checkIfIsMultiProduct } =
    useContext<StepContextValues>(StepContext);
  const orderIds = values.orders?.map((order) => order.orderId);
  const orderId = orderIds?.at(0) || undefined;
  const { issues, isIssuesLoading } = useGetIssuesByOrder({ orderId });

  const { claims, claimsIsLoading } = useGetClaimGroupSummary({
    claimGroupId,
  });

  useEffect(() => {
    if (issueId && issues?.length) {
      const issueValue = issues?.find((issue) => issue.id === issueId);

      return handleUpdateValue({
        value: {
          issue: issueValue,
        },
      });
    }
    if (claimGroupId && claims?.length) {
      checkIfIsMultiProduct(claims?.length > 1);

      handleUpdateValue({
        value: {
          claimGroup: {
            claimGroupId,
            claims,
          },
        },
      });
    }

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claims, issues]);

  useEffect(() => {
    if (
      !!values.claimGroup?.claimGroupId &&
      claimGroupId !== values.claimGroup?.claimGroupId
    ) {
      return handleUpdateValue({
        value: {
          reason: undefined,
          claimGroup: {
            claimGroupId: undefined,
            claims: [],
          },
        },
      });
    }

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimGroupId]);

  return {
    isLoading: claimsIsLoading || isIssuesLoading,
    name: values?.login?.firstName || login.firstName || '',
  };
};

export default useStepWrapper;
