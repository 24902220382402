import { ClaimGroupConfirmation } from 'myaccount/api/Claim/getClaimGroupConfirmationQuery';
import { useGetSolutionsByClaim } from 'myaccount/view/app/hooks/useGetSolutionsByClaim';

interface UseResumeSolutionArgs {
  claimGroupConfirmation?: ClaimGroupConfirmation;
}

export const useResumeSolution = ({
  claimGroupConfirmation,
}: UseResumeSolutionArgs) => {
  const claimGroupId = claimGroupConfirmation?.claimGroupId;
  const { solutions } = useGetSolutionsByClaim({
    claimGroupId,
  });

  return {
    solution: solutions?.find(
      (solution) =>
        solution.solutionGroupId === claimGroupConfirmation?.solutionGroupId,
    ),
  };
};
