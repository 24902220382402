import React, {
  createRef,
  FunctionComponent,
  MutableRefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useMeasure } from 'react-use';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';

import { useOnScreen } from '../../hooks/useOnScreen';
import { ChevronLeftIcon, ChevronRightIcon } from '../icons';

import {
  $ButtonScroller,
  $ScrollDetector,
  $TabContainer,
  $TabItemWrapper,
  $TabViewList,
  $TabViewNav,
  $TabViewSelector,
  PADDING_BUTTONS,
} from './TabScroller.styles';

const MINIMUM_TABS_COUNTS_TO_SHOW_BUTTON_SCROLLER = 4;

interface TabscrollerProps {
  dataTestId?: string;
  totalCount: number;
  currentIndex: number;
  minimumCountToShowButtonScroller?: number;
  fluid?: boolean;
  hasBottomBorder?: boolean;
  hasOverflow?: boolean;
  componentThemeName?: string;
  hideButton?: boolean;
  hasErrors?: boolean;
  isNewUiEnabled?: boolean;
}

const TabScroller: FunctionComponent<
  React.PropsWithChildren<TabscrollerProps>
> = ({
  children,
  dataTestId,
  totalCount,
  currentIndex,
  minimumCountToShowButtonScroller = MINIMUM_TABS_COUNTS_TO_SHOW_BUTTON_SCROLLER,
  fluid = false,
  hasBottomBorder = false,
  hasOverflow = true,
  componentThemeName,
  hideButton = false,
  hasErrors = false,
  isNewUiEnabled = false,
}) => {
  const [containerRef, { width: totalWidth }] = useMeasure<HTMLElement>();
  const [shrinkedContainerRef, { width: displayedWidth }] =
    useMeasure<HTMLDivElement>();
  const listRef = useRef<HTMLElement | null>();

  const [elRefs, setElRefs] = useState<MutableRefObject<HTMLDivElement>[]>([]);
  const [position, setPosition] = useState(0);
  const [width, setWidth] = useState(0);
  const [allChildrenWidth, setAllChildrenWidth] = useState(0);

  useEffect(() => {
    // add or remove refs
    setElRefs((innerRef) =>
      Array(totalCount)
        .fill(null)
        .map((_, index) => innerRef[index] || createRef()),
    );
  }, [totalCount]);

  const first = elRefs?.[0]?.current?.clientWidth || 0;

  useLayoutEffect(() => {
    if (elRefs[currentIndex] && elRefs[currentIndex]?.current) {
      const newPosition = elRefs.reduce(
        (acc, { current }, index) =>
          index < currentIndex ? acc + (current?.clientWidth || 0) : acc,
        0,
      );
      const newWidth = elRefs[currentIndex]?.current?.clientWidth;

      setPosition(newPosition);
      setWidth(newWidth || first);
      const newAllChildrenWidth = elRefs.reduce(
        (acc, { current }) => acc + (current?.clientWidth || 0) + 20,
        -20,
      );

      setAllChildrenWidth(newAllChildrenWidth);
    }
  }, [allChildrenWidth, currentIndex, elRefs, first]);

  useEffect(() => {
    if (listRef.current && listRef.current?.scroll) {
      listRef.current?.scroll({
        top: 0,
        left: position - (displayedWidth - PADDING_BUTTONS) / 2 + width / 2,
        behavior: 'smooth',
      });
    }
  }, [position, width, displayedWidth, totalWidth]);

  const scrollRight = useCallback(() => {
    if (listRef.current) {
      listRef.current.scroll({
        top: 0,
        left: listRef.current.scrollLeft - 300,
        behavior: 'smooth',
      });
    }
  }, []);
  const scrollLeft = useCallback(() => {
    if (listRef.current && listRef.current?.scroll) {
      listRef.current.scroll({
        top: 0,
        left: listRef.current.scrollLeft + 300,
        behavior: 'smooth',
      });
    }
  }, []);

  const leftScrollDetectorRef = useRef<HTMLDivElement>(null);
  const rightScrollDetectorRef = useRef<HTMLDivElement>(null);

  const isLeftScrollDetectorExist = useOnScreen(leftScrollDetectorRef);
  const isRightScrollDetectorExist = useOnScreen(rightScrollDetectorRef);

  const isButtonScrollerVisible =
    !hideButton &&
    totalCount > minimumCountToShowButtonScroller &&
    (allChildrenWidth >= displayedWidth ||
      !isLeftScrollDetectorExist ||
      !isRightScrollDetectorExist) &&
    allChildrenWidth > 0;

  return (
    <$TabContainer
      ref={shrinkedContainerRef}
      $fluid={fluid}
      $hasBottomBorder={hasBottomBorder}
      $isNewUiEnabled={isNewUiEnabled}
      $isButtonScrollerVisible={isButtonScrollerVisible}
    >
      <$TabViewNav
        ref={(ref) => {
          if (ref) {
            containerRef(ref);
          }
          if (ref) {
            listRef.current = ref;
          }
        }}
        $hasOverflow={hasOverflow}
        $fluid={fluid}
        $componentThemeName={componentThemeName}
      >
        <$TabViewList
          $componentThemeName={componentThemeName}
          $fluid={fluid}
          $isNewUiEnabled={isNewUiEnabled}
        >
          <$ScrollDetector ref={leftScrollDetectorRef} />
          {React.Children.map(children, (child: any, index) => {
            const isHidden =
              child?.props?.isHidden ||
              child?.props?.children[0]?.props?.isHidden;

            if (isHidden) {
              return null;
            }

            return (
              <li>
                <$TabItemWrapper ref={elRefs[index]} $fluid={fluid}>
                  {child}
                </$TabItemWrapper>
              </li>
            );
          })}
          <$ScrollDetector ref={rightScrollDetectorRef} />
        </$TabViewList>

        <$TabViewSelector
          $position={position}
          $width={width}
          $index={currentIndex}
          $totalWidth={totalWidth || 0}
          data-testid={dataTestId}
          $componentThemeName={componentThemeName}
          $hasErrors={hasErrors}
          $isNewUiEnabled={isNewUiEnabled}
        />
      </$TabViewNav>
      {isButtonScrollerVisible && (
        <>
          <$ButtonScroller
            type={BUTTON_TYPES.BUTTON}
            onClick={scrollRight}
            $componentThemeName={componentThemeName}
            $isNewUiEnabled={isNewUiEnabled}
          >
            <ChevronLeftIcon size={isNewUiEnabled ? '34px' : undefined} />
          </$ButtonScroller>
          <$ButtonScroller
            type={BUTTON_TYPES.BUTTON}
            onClick={scrollLeft}
            $componentThemeName={componentThemeName}
            $isNewUiEnabled={isNewUiEnabled}
          >
            <ChevronRightIcon size={isNewUiEnabled ? '34px' : undefined} />
          </$ButtonScroller>
        </>
      )}
    </$TabContainer>
  );
};

TabScroller.displayName = 'TabScroller';

export default TabScroller;
