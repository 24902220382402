import React, { FunctionComponent } from 'react';
import { useMedia } from 'react-use';

import { media } from '@savgroup-front-common/constants';

import {
  $CardContentHistory,
  $CardContentHistoryLegend,
  $CardContentHistoryLegendWrapper,
  $CardContentHistoryPoint,
  $CardContentHistoryWrapper,
  $CardContentHistoryPointContainer,
  $CardContentHistoryDashedLine,
  $Divider,
} from './FileProgress.styles';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { FileStateHistorySummary } from 'myaccount/api/Workflow/getFileStatesQuery';

interface FileProgressProps {
  histories: FileStateHistorySummary[];
}

const FileProgress: FunctionComponent<FileProgressProps> = ({ histories }) => {
  const isMobileView = useMedia(media.maxWidth.xs);

  const lastState =
    histories &&
    histories
      .slice(-2)
      ?.filter((x) => x)
      ?.map((historyItem) => historyItem.state)
      .reverse();

  return (
    <>
      {isMobileView && <$Divider />}

      <$CardContentHistoryWrapper>
        <$CardContentHistory>
          <$CardContentHistoryDashedLine isFirst />
          <$CardContentHistoryPointContainer>
            <$CardContentHistoryPoint active disabled={false} />

            <$CardContentHistoryPoint active={false} disabled />
          </$CardContentHistoryPointContainer>
        </$CardContentHistory>

        <$CardContentHistoryLegendWrapper>
          <$CardContentHistoryLegend disabled={false}>
            {lastState?.at(0)?.label && (
              <SafeFormattedMessageWithoutSpread
                message={lastState?.at(0)?.label || ''}
              />
            )}
          </$CardContentHistoryLegend>

          <$CardContentHistoryLegend disabled>
            {lastState?.at(1)?.label && (
              <SafeFormattedMessageWithoutSpread
                message={lastState?.at(1)?.label || ''}
              />
            )}
          </$CardContentHistoryLegend>
        </$CardContentHistoryLegendWrapper>
      </$CardContentHistoryWrapper>
    </>
  );
};

FileProgress.displayName = 'ProductInfoHistory';
export default FileProgress;
