import { useSelector } from 'react-redux';

import { CommonAppState } from '../CommonAppState';

import { Action, SuperAction } from './constants';
import {
  getActionErrorsSelector,
  someActionIsLoadingSelector,
  wasEveryActionLoadedSelector,
} from './selectors';

export const useSomeActionIsLoadingSelector = (actions: SuperAction[]) => {
  return useSelector((state: CommonAppState) =>
    someActionIsLoadingSelector(state, actions),
  );
};

export const useWasEveryActionLoadedSelector = (actions: SuperAction[]) => {
  return useSelector((state: CommonAppState) =>
    wasEveryActionLoadedSelector(state, actions),
  );
};

export const useSelectActionError = (action: SuperAction) => {
  return useSelector((state: CommonAppState) =>
    getActionErrorsSelector(state, action.action as Action, action.indexer),
  );
};
