import { add } from 'date-fns';
import React, { FC } from 'react';

import Card from '../../../../../atoms/Cards/Card';
import { SafeFormattedMessageWithoutSpread } from '../../../../../formatters/SafeFormattedMessageWithoutSpread';
import { isEmpty } from '../../../../../helpers/isEmpty';
import { BaseLoader } from '../../../../../molecules/BaseLoader';
import AppointmentCalendar from '../../../../AppointmentCalendar/AppointmentCalendar';
import { useGetPageSize } from '../../../hooks/useGetPageSize';
import LoaderTools from '../../LoaderTools';
import { useTakeInterventionAppointmentContext } from '../TakeInterventionAppointment.context';
import { useInterventionAppointment } from '../TakeInterventionAppointment.hooks';
import { PAGINATION_START_FROM_DAY } from '../TakeInterventionAppointment.types';

import {
  $AppointmentCalendarContainer,
  $CalendarCard,
  $Title,
} from './AppointmentCalendarContainer.styles';
import InterventionAppointmentHeader from './InterventionAppointmentHeader/InterventionAppointmentHeader';
import messages from './messages';

interface AppointmentCalendarContainerProps {
  fileId: string;
  sellerId: string;
  onSubmit: () => void;
  isLoading?: boolean;
  isActive?: boolean;
}

const AppointmentCalendarContainer: FC<
  React.PropsWithChildren<AppointmentCalendarContainerProps>
> = ({
  children,
  fileId,
  sellerId,
  onSubmit,
  isLoading = false,
  isActive = true,
}) => {
  const { handleValidateSchedule, isCreateTechnicalInterventionLoading } =
    useInterventionAppointment({ fileId, onSubmit, sellerId });

  const { pageNumber, handlePageChange, hasErrors, detailedSchedules } =
    useTakeInterventionAppointmentContext();

  const pageSize = useGetPageSize();

  const detailedSchedulesToDisplay = isEmpty(detailedSchedules)
    ? new Array(pageSize).fill(null).map((_, index) => {
        const date = add(new Date(), {
          days: (pageNumber - 1) * pageSize + PAGINATION_START_FROM_DAY + index,
        });

        return {
          date,
          timeslots: [],
        };
      })
    : detailedSchedules || [];

  if (!detailedSchedules) {
    return <BaseLoader />;
  }

  return (
    <Card colored>
      <$Title>
        <SafeFormattedMessageWithoutSpread message={messages.whatINeedToDo} />
      </$Title>

      <InterventionAppointmentHeader />

      <$Title>
        <SafeFormattedMessageWithoutSpread message={messages.title} />
      </$Title>

      <$CalendarCard>
        <$AppointmentCalendarContainer>
          <AppointmentCalendar
            schedules={detailedSchedulesToDisplay}
            isGetTimeslotsLoading={isLoading}
            isSubmitLoading={isCreateTechnicalInterventionLoading}
            isLoading={isLoading}
            isNextPageDisabled={false}
            isActive={isActive}
            onValidateSchedule={handleValidateSchedule}
            messageList={[
              messages.searchingForTechnician,
              messages.searchingAvailability,
              messages.synchronizeAgendas,
              messages.regroupingTimeslots,
              messages.optimizingTechnicianItinerary,
            ]}
            customLoader={<LoaderTools />}
            onPageNextClick={() =>
              handlePageChange({ newPageNumber: pageNumber + 1 })
            }
            onPagePreviousClick={() =>
              handlePageChange({ newPageNumber: pageNumber - 1 })
            }
            hasErrors={hasErrors}
            isFirstPage={pageNumber === 1}
          >
            {children}
          </AppointmentCalendar>
        </$AppointmentCalendarContainer>
      </$CalendarCard>
    </Card>
  );
};

AppointmentCalendarContainer.displayName = 'AppointmentCalendarContainer';

export default AppointmentCalendarContainer;
