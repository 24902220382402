import React, { FC, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';

import { Modal } from '@savgroup-front-common/core/src/atoms/modal';
import SwipeableDrawer, {
  DRAWER_SIZE,
} from '@savgroup-front-common/core/src/atoms/SwipeableDrawer/SwipeableDrawer';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';
import { ICONS_TYPE, PickupPoint } from '@savgroup-front-common/types';
import {
  PROFESSIONAL_STEP_CONFIG,
  PROFESSIONAL_STEP_PROGRESS,
} from 'myaccount/view/app/NewLayout/ProfessionalRoutes.config';
import { buildProfessionalRoute } from 'myaccount/view/app/NewLayout/ProfessionalRoutes.helpers';

import useGetPickupPoints from '../../../../app/hooks/useGetPickupPoints';
import StepLayoutProfessional from '../../../../app/NewLayout/StepLayout/StepLayoutProfessional';
import { AddressManagement } from '../../../../components/AddressManagement';
import HandlingCard from '../../../../components/HandlingCard';
import PickupPointSelector from '../../../../components/PickupPointSelector';
import { ResumeSolution } from '../../../../components/ResumeSolution';
import { ResumeCard } from '../../components/ResumeCard';
import useClaimGroupDepositPage from '../ClaimGroupDepositPage.hooks';
import {
  $DepositItemWrapper,
  $DepositResultWrapper,
  $DepositWrapper,
  $Heading,
  $HeadingIcon,
  $LeftColumn,
} from '../ClaimGroupDepositPage.styles';
import messages from '../messages';

export const ClaimGroupDeposit: FC = () => {
  const history = useHistory();
  const { claimGroupId } = useParams<{ claimGroupId: string }>();

  const [openResumeModal, setOpenResumeModal] = useState(false);

  const {
    claimGroupConfirmation,
    groupedCarriers,
    formContext,
    changeSelection,
    onSubmit,
    handling,
    isDepositHandlingLoading,
    chooseHandlingSelected,
    handlingSelected,
    homeSelectedAddressChanged,
  } = useClaimGroupDepositPage();

  const { setValue } = formContext;

  const {
    sellerId,
    carriers,
    addressSelected,
    pickupPoints,
    noPickupPoints,
    addresses,
    handleSetAddressSelected,
    selectedPickupPoint,
    pickupPointsIsLoading,
  } = useGetPickupPoints({
    selectedCarrierType: chooseHandlingSelected,
    groupedCarriers,
    claimGroupId,
  });

  const handleSetSelectedPickupPoint = async (
    pickupPointSelected?: PickupPoint,
  ) => {
    if (!pickupPointSelected) {
      return;
    }

    setValue('pickupPointSelected', pickupPointSelected);

    await onSubmit();
  };

  const handleOpenModal = () => setOpenResumeModal((prev) => !prev);

  const handlePreviousStep = () => {
    history.push(
      buildProfessionalRoute({
        route: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_SOLUTION_PAGE,
        payload: { claimGroupId },
      }),
    );
  };

  return (
    <>
      <FormProvider {...formContext}>
        <form onSubmit={onSubmit}>
          <StepLayoutProfessional
            stepName={PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_DEPOSIT_PAGE}
            customSteps={PROFESSIONAL_STEP_PROGRESS}
            handlePreviousStep={handlePreviousStep}
          >
            <$DepositWrapper>
              <$LeftColumn>
                <ResumeSolution
                  claimGroupId={claimGroupId}
                  claimGroupConfirmation={claimGroupConfirmation}
                  customBuildUpdateSolutionUrl={buildProfessionalRoute({
                    route: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_SOLUTION_PAGE,
                    payload: { claimGroupId },
                  })}
                />
                <$Heading>
                  <$HeadingIcon>
                    <MyAccountIcon icon={ICONS_TYPE.DEPOSIT_ICON} />
                  </$HeadingIcon>
                  <SafeFormattedMessageWithoutSpread
                    message={
                      handling?.hasHome
                        ? messages.handlingTitleOnlyHome
                        : messages.handlingTitle
                    }
                  />
                </$Heading>
                <$DepositItemWrapper>
                  {isDepositHandlingLoading && <BaseLoader />}
                  {!isDepositHandlingLoading && (
                    <HandlingCard groupedCarriers={groupedCarriers} />
                  )}
                </$DepositItemWrapper>
              </$LeftColumn>

              <$DepositResultWrapper>
                <ResumeCard />
              </$DepositResultWrapper>
            </$DepositWrapper>

            <SwipeableDrawer
              isOpen={handlingSelected}
              onClose={changeSelection}
              hasBackdrop
              hasPadding={false}
              hasBackdropScroll={false}
              hasCloseButton={false}
            >
              {handlingSelected && (
                <PickupPointSelector
                  maxDistance={200}
                  pickupPoints={pickupPoints}
                  selectedPickupPointId={
                    selectedPickupPoint ? selectedPickupPoint.id : undefined
                  }
                  onSelectPickupPoint={handleSetSelectedPickupPoint}
                  searchAddress={addressSelected}
                  userAddresses={addresses}
                  onAddressSelected={handleSetAddressSelected}
                  carriers={carriers}
                  onClose={changeSelection}
                  pickupPointsIsLoading={pickupPointsIsLoading}
                  sellerId={sellerId}
                  noPickupPoints={noPickupPoints}
                />
              )}
            </SwipeableDrawer>

            <SwipeableDrawer
              isOpen={homeSelectedAddressChanged}
              size={DRAWER_SIZE.SMALL}
              onClose={changeSelection}
              hasBackdrop
              hasBackdropScroll={false}
            >
              <AddressManagement />
            </SwipeableDrawer>
          </StepLayoutProfessional>
        </form>
      </FormProvider>
      <Modal
        isOpen={openResumeModal}
        onClose={handleOpenModal}
        shouldShowPadding={false}
      >
        <ResumeCard />
      </Modal>
    </>
  );
};

ClaimGroupDeposit.displayName = 'ClaimGroupDeposit';
