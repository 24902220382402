/* eslint-disable no-console */
// Seller info
import { fromJS } from 'immutable';
import get from 'lodash/get';

export function onSellerInfoLoading(state, { meta }) {
  const sellerId = get(meta, ['sellerId']);
  const sellerProductId = get(meta, ['sellerProductId']);

  if (!sellerId) {
    console.warn('[onSellerInfoLoading] sellerId is undefined');

    return state;
  }
  if (!sellerProductId) {
    console.warn('[onSellerInfoLoading] sellerProductId is undefined');

    return state;
  }

  return state.setIn(
    ['sellerInfo', sellerId, sellerProductId],
    fromJS({ isLoaded: false, isDirty: false }),
  );
}

export function onSellerInfoErrored(state, { meta }) {
  const sellerId = get(meta, ['sellerId']);
  const sellerProductId = get(meta, ['sellerProductId']);

  if (!sellerId) {
    console.warn('[onSellerInfoLoading] sellerId is undefined');

    return state;
  }
  if (!sellerProductId) {
    console.warn('[onSellerInfoLoading] sellerProductId is undefined');

    return state;
  }

  return state.updateIn(['sellerInfo', sellerId, sellerProductId], (oldValue) =>
    oldValue.setIn(['isDirty'], true).setIn(['hasErrors'], true),
  );
}

export function onSellerInfoLoaded(state, { payload, meta }) {
  const sellerId = get(meta, ['sellerId']);
  const sellerProductId = get(meta, ['sellerProductId']);

  if (!sellerId) {
    console.warn('[onSellerInfoLoading] sellerId is undefined');

    return state;
  }
  if (!sellerProductId) {
    console.warn('[onSellerInfoLoading] sellerProductId is undefined');

    return state;
  }

  return state.setIn(
    ['sellerInfo', sellerId, sellerProductId],
    fromJS({ isLoaded: true, isDirty: false }).setIn(['value'], payload.value),
  );
}
