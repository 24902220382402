import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  HandlingSummary,
} from '@savgroup-front-common/types';

 
const ENDPOINT = `${APIConfiguration.claim}claimGroups/${COMMON_QUERY_PARAMS.CLAIM_GROUP_ID}/handling`;

interface GetHandlingByClaimGroupArgs {
  claimGroupId: string;
}

const getHandlingByClaimGroup = async ({
  claimGroupId,
}: GetHandlingByClaimGroupArgs): Promise<
  BackResponse<HandlingSummary> | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(
      COMMON_QUERY_PARAMS.CLAIM_GROUP_ID,
      claimGroupId,
    );

    return await apiCall<BackResponse<HandlingSummary>>(url, {
      method: SUPPORTED_METHODS.GET,
    });
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default getHandlingByClaimGroup;
