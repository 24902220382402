import { defineMessages } from 'react-intl';

export default defineMessages({
  solutionTitle: {
    id: 'components.myaccount.claims.handling.cards.solutionTitle',
    defaultMessage: 'Solution : ',
  },
  notificationsTitle: {
    id: 'components.myaccount.claims.confirmation.cards.notificationsTitle',
    defaultMessage: 'Be notified of the progress of my request',
  },
  phoneFormatError: {
    id: 'components.common.addressManagement.addressForm.phoneFormatError',
    defaultMessage: 'Phone number formatted incorrectly',
  },
  phone: {
    id: 'components.common.addressManagement.addressForm.phone',
    defaultMessage: 'Téléphone',
  },
  mail: {
    id: 'components.common.addressManagement.addressForm.mail',
    defaultMessage: 'Mail',
  },
  modifyButton: {
    id: 'components.myaccount.claims.handling.cards.modifyButton',
    defaultMessage: 'Modify',
  },
});
