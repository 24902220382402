import React, { ReactNode, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { useMedia } from 'react-use';

import {
  ALLOWED_MIME_TYPES,
  DOCUMENT_TYPES,
  IMAGE_DOCUMENT_TYPES,
  media,
  MIME_TYPES,
} from '@savgroup-front-common/constants';
import { ExtendedFile, MessageType } from '@savgroup-front-common/types';

import { CommonClaimService, CommonWorkflowService } from '../../../api';
import { MultiFileUploadHookForm } from '../../../atoms/Form';
import { FieldMessages } from '../../../atoms/Form/common/helpers/getFinalFieldState.types';
import { NewDesignMultiFileUploadHookForm } from '../../../atoms/Form/NewDesignMultiFileUploadHookForm';
import { toast } from '../../../atoms/Toast';
import { safeFormattedIntlString } from '../../../formatters';
import { useToasts } from '../../../molecules/NotificationsProvider';
import messages from '../messages';

import { downloadMultiFileAdditionalInformationBlob } from './helpers/downloadMultiFileAdditionalInformationBlob';
import { QrCodeImportModal } from './QrCodeImportModal/QrCodeImportModal';

interface AdditionalInformationMultiFileInputProps {
  name: string;
  label?: MessageType | string;
  postLabel: string | ReactNode;
  value?: { value: ExtendedFile }[];
  onChange: (files?: File[]) => void;
  onRemove?: (file: ExtendedFile, index: number) => void;
  isRequired: boolean;
  errors: FieldMessages;
  isLiveUpload: boolean;
  isImportByQrCode: boolean;
  additionalInformationId: string;
  dataTestId: string;
  claimIds: string[];
  documentType?: DOCUMENT_TYPES;
  isNewDesign?: boolean;
}

export const AdditionalInformationMultiFileInput: React.FC<
  AdditionalInformationMultiFileInputProps
> = ({
  name,
  label,
  postLabel,
  value = [],
  onChange,
  onRemove,
  isRequired,
  errors,
  isLiveUpload,
  isImportByQrCode,
  additionalInformationId,
  dataTestId,
  claimIds,
  documentType,
  isNewDesign = false,
}) => {
  const isMobileView = useMedia(media.maxWidth.xs);
  const formContext = useFormContext();
  const { setValue, getValues } = formContext;

  const { pushErrors } = useToasts();
  const { fileId } = useParams<{ fileId?: string }>();

  const [isOpen, setIsOpen] = useState(false);

  const { mutateAsync: handleUploadFinishClick, isLoading } = useMutation(
    ['syncrhonizeFileAdditionalInformationForm'],
    async () => {
      const currentFiles = getValues(name) ?? [];

      if (fileId) {
        const response = await CommonWorkflowService.getShortFileInfoQuery({
          fileId,
        });

        if (response.failure) {
          pushErrors(response.errors);

          return;
        }

        const fileProduct = response.value.fileProducts.find(
          (fileProduct) => fileProduct.claimId === claimIds[0],
        );
        const fileAdditionalInformation =
          fileProduct?.fileAdditionalInformation?.find(
            (additionalInformation) =>
              additionalInformation.additionalInformationId ===
              additionalInformationId,
          );

        const files = await downloadMultiFileAdditionalInformationBlob({
          fileAdditionalInformation,
        });

        if (files) {
          setValue(name, [
            ...currentFiles,
            ...files.map((file) => ({ value: file })),
          ]);

          toast.success(
            safeFormattedIntlString(messages.fileUploadedSuccessfully),
          );
        }
      } else {
        const response =
          await CommonClaimService.getAdditionalInformationByClaimQuery({
            claimId: claimIds[0],
          });

        if (response.failure) {
          setIsOpen(false);

          return;
        }

        const additionalInformations = response.value?.map(
          (x) => x.productAdditionalInformation,
        );

        const fileAdditionalInformation = additionalInformations?.find(
          (additionalInformation) =>
            additionalInformation.additionalInformationId ===
            additionalInformationId,
        );

        const files = await downloadMultiFileAdditionalInformationBlob({
          fileAdditionalInformation,
        });

        if (files) {
          setValue(name, [
            ...currentFiles,
            ...files.map((file) => ({ value: file })),
          ]);

          toast.success(
            safeFormattedIntlString(messages.fileUploadedSuccessfully),
          );
        }
      }

      setIsOpen(false);
    },
  );

  const allowedMimeTypes = Object.values(IMAGE_DOCUMENT_TYPES).includes(
    documentType as DOCUMENT_TYPES,
  )
    ? [MIME_TYPES.JPEG, MIME_TYPES.PNG, MIME_TYPES.IMAGE_HEIC, MIME_TYPES.PDF]
    : ALLOWED_MIME_TYPES;

  return (
    <>
      {isImportByQrCode && !isMobileView && claimIds[0] && (
        <QrCodeImportModal
          isOpen={isOpen}
          additionalInformationId={additionalInformationId}
          claimId={claimIds[0]}
          onUploadFinishClick={handleUploadFinishClick}
          isSubmitLoading={isLoading}
        />
      )}

      {isNewDesign && (
        <NewDesignMultiFileUploadHookForm
          allowedMimeTypes={allowedMimeTypes}
          name={name}
          label={label}
          postLabel={postLabel}
          onSelect={onChange}
          onRemove={onRemove}
          files={value?.map((value) => value.value) ?? []}
          isRequired={isRequired}
          errors={errors}
          isLiveUpload={isLiveUpload}
          onImportByQrCodeClick={
            isImportByQrCode && !isMobileView
              ? () => {
                  setIsOpen(true);
                }
              : undefined
          }
          dataTestId={dataTestId}
          isFullWidth
        />
      )}

      {!isNewDesign && (
        <MultiFileUploadHookForm
          allowedMimeTypes={allowedMimeTypes}
          hollow
          name={name}
          label={label}
          postLabel={postLabel}
          onSelect={onChange}
          onRemove={onRemove}
          files={value?.map((value) => value.value) ?? []}
          isRequired={isRequired}
          errors={errors}
          isLiveUpload={isLiveUpload}
          isFullWidth
          onImportByQrCodeClick={
            isImportByQrCode && !isMobileView
              ? () => {
                  setIsOpen(true);
                }
              : undefined
          }
          dataTestId={dataTestId}
        />
      )}
    </>
  );
};

AdditionalInformationMultiFileInput.displayName =
  'AdditionalInformationMultiFileInput';
