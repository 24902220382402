import { APIConfiguration } from '@savgroup-front-common/configuration';
import { HANDLING_GROUPS } from '@savgroup-front-common/constants';
import { PickupPoint } from '@savgroup-front-common/types';

const carrierChronopost = 'Chronopost';
const carrierChronopostBrand = 'chrono';

export const getUrl = ({
  sellerId,
  pickupPoint,
}: {
  sellerId: string;
  pickupPoint: PickupPoint;
}) => {
  const carrierName =
    pickupPoint.carrierBrand && pickupPoint.carrierBrand === carrierChronopost
      ? carrierChronopostBrand
      : (
          pickupPoint.chain ||
          pickupPoint.carrierBrand ||
          HANDLING_GROUPS.EXTERNAL
        ).toLowerCase();

  return [
    `${APIConfiguration.imageCDN}sellers/${sellerId}/${carrierName}.png`,
    `${APIConfiguration.catalogCDN}carriers/${carrierName}.png`,
  ];
};
