import { useQuery } from 'react-query';

import { ClaimService } from '../../../../api';

export const GetClaimContextSummaryQuery = 'getClaimContextSummaryQuery';

export const useGetClaimContextSummaryQuery = ({
  claimId,
}: {
  claimId?: string;
}) => {
  const { data: claimContextSummary } = useQuery(
    [GetClaimContextSummaryQuery, { claimId }],
    async () => {
      if (!claimId) {
        return undefined;
      }

      const response = await ClaimService.getClaimContextSummaryQuery({
        claimId,
      });

      if (response.failure) {
        return undefined;
      }

      return response.value;
    },
    { cacheTime: Infinity },
  );

  return {
    claimContextSummary,
  };
};
