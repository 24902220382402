import React from 'react';

import { ProductImage } from '@savgroup-front-common/core/src/atoms/ProductImage';
import { OrderDetailsProduct } from '@savgroup-front-common/types';

import {
  $CardContentThumbnail,
  $CardContentThumbnailWrapper,
  $ResumeDescriptionProduct,
  $ResumeInfoProduct,
  $ResumeInfoProductTag,
  $ResumeInfoProductTitle,
  $ResumePictureProduct,
} from '../ResumeCard.styles';

import useResumeCardHeader from './ResumeCardHeader.hooks';

interface ResumeCardHeaderProps {
  product: OrderDetailsProduct;
}

const ResumeCardHeader: React.FC<ResumeCardHeaderProps> = ({ product }) => {
  const { modelDetail } = useResumeCardHeader({
    product,
  });

  return (
    <$ResumeDescriptionProduct>
      <$ResumePictureProduct>
        <$CardContentThumbnailWrapper>
          <$CardContentThumbnail>
            <ProductImage pictureUrl={modelDetail?.modelTypeId} />
          </$CardContentThumbnail>
        </$CardContentThumbnailWrapper>
      </$ResumePictureProduct>
      <$ResumeInfoProduct>
        <$ResumeInfoProductTitle>
          {product.batchQuantity} x {modelDetail?.name}
        </$ResumeInfoProductTitle>
        <$ResumeInfoProductTag />
      </$ResumeInfoProduct>
    </$ResumeDescriptionProduct>
  );
};

ResumeCardHeader.displayName = 'ResumeCardHeader';

export default ResumeCardHeader;
