import React, { FC } from 'react';
import { Controller, FormProvider } from 'react-hook-form';

import {
  Autocomplete,
  FormGroup,
} from '@savgroup-front-common/core/src/atoms/Form';
import { safeFormattedIntlString } from '@savgroup-front-common/core/src/formatters';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';

import useClaimGroupReason from './ReasonPage.hooks';
import messages from './messages';
import { useStepOrchestratorContext } from '@savgroup-front-common/core/src/molecules/StepsOrchestrator';
import { IssuesReasonsPageForm } from './ReasonPage.types';
import StepLayoutProfessional from '../../../../../app/NewLayout/StepLayout/StepLayoutProfessional';
import {
  PROFESSIONAL_STEP_CONFIG,
  PROFESSIONAL_STEP_PROGRESS,
} from 'myaccount/view/app/NewLayout/ProfessionalRoutes.config';

const ReasonPage: FC = () => {
  const { reasons, onSubmit, formContext, isLoading, isSubmitLoading } =
    useClaimGroupReason();

  const {
    control,
    formState: { errors, isValid },
  } = formContext;

  const options = reasons.map((reason) => ({
    label: reason.name,
    value: reason.id,
    data: reason,
  }));

  const { previousStep } = useStepOrchestratorContext<IssuesReasonsPageForm>();

  const handlePreviousStep = () => previousStep();

  if (isLoading) {
    return <BaseLoader />;
  }

  return (
    <FormProvider {...formContext}>
      <form onSubmit={onSubmit}>
        <StepLayoutProfessional
          handlePreviousStep={handlePreviousStep}
          stepName={PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_ISSUES_REASONS_PAGE}
          isDisabled={!isValid}
          customSteps={PROFESSIONAL_STEP_PROGRESS}
          isLoading={isSubmitLoading}
          minHeight
        >
          <FormGroup dataTestId="dropdown-select-reason">
            <Controller
              control={control}
              name="reason"
              render={({ field }) => (
                <Autocomplete
                  name={field.name}
                  options={options}
                  isRequired
                  ref={field.ref}
                  onChange={field.onChange}
                  value={field.value}
                  errors={errors}
                  label={safeFormattedIntlString(messages.selectReason)}
                  shouldAutoSelectFirstOptions={options.length === 1}
                />
              )}
            />
          </FormGroup>
        </StepLayoutProfessional>
      </form>
    </FormProvider>
  );
};

ReasonPage.displayName = 'ReasonPage';

export default ReasonPage;
