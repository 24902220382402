import { useQuery } from 'react-query';

import { FileShortSummary } from '@savgroup-front-common/types';
import { WorkflowService } from 'myaccount/api';

export const GetMultipleFilesByOrderQuery = 'GetMultipleFilesByOrderQuery';

export const useGetMultipleFilesByOrderQuery = ({
  orderIds,
}: {
  orderIds?: string[];
}) => {
  const { data: ordersDetail, isLoading } = useQuery(
    [GetMultipleFilesByOrderQuery, { orderIds }],
    async () => {
      if (!orderIds || orderIds.length === 0) {
        return undefined;
      }

      return Promise.all(
        orderIds?.map(async (orderId) => {
          const response = await WorkflowService.getFilesByOrderQuery({
            orderId,
          });

          if (response.failure) {
            return undefined;
          }

          return response.value;
        }),
      ).then(
        (value) =>
          value
            .filter((orderDetail) => orderDetail)
            .flat(1) as FileShortSummary[],
      );
    },
    {
      suspense: false,
    },
  );

  return {
    ordersDetail,
    isLoading,
  };
};
