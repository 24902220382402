import { OwnerService } from '../../../../api';
import { useLayout } from '../../../app/NewLayout/NewLayout.hooks';
import { useQuery } from 'react-query';
import { adaptCustomerProducts } from './HomePageFiles.adapters';

const useListOwnerProductsQuery = () => {
  const { ownerId } = useLayout();

  const { data: products, isLoading } = useQuery(
    ['useListOwnerProductsQuery', { ownerId }],
    async () => {
      const response = await OwnerService.listOwnerProductsQuery({
        ownerId,
      });

      if (response.failure) {
        return undefined;
      }

      return adaptCustomerProducts(response.value);
    },
    {
      staleTime: Infinity,
      suspense: true,
      enabled: !!ownerId,
    },
  );

  return {
    products,
    isLoading,
  };
};

export default useListOwnerProductsQuery;
