import { defineMessages } from 'react-intl';

export default defineMessages({
  breadcrumbMyRequests: {
    id: 'components.myaccount.orderPage.breadcrumbMyRequests',
    defaultMessage: 'My requests',
  },
  bannerTitle: {
    id: 'components.myaccount.orderPage.bannerTitle',
    defaultMessage: 'Requests for after-sales service ',
  },
  bannerTitleUnknown: {
    id: 'components.myaccount.orderPage.bannerTitleUnknown',
    defaultMessage: 'Request unknown',
  },
  bannerDescription: {
    id: 'components.myaccount.orderPage.bannerDescription',
    defaultMessage: 'Manage your current requests here',
  },
  actionNewRequest: {
    id: 'components.myaccount.orderPage.actionNewRequest',
    defaultMessage: 'New request',
  },
});
