import React, { FunctionComponent } from 'react';

import { ChevronRightIcon } from '../../protons/icons';

import { $Container } from './ChevronDisplay.styles';

interface ChevronDisplayProps {
  isOpen?: boolean;
  color?: string;
}

const ChevronDisplay: FunctionComponent<ChevronDisplayProps> = ({
  isOpen = false,
  color = 'black',
}) => {
  return (
    <$Container $isOpen={isOpen}>
      <ChevronRightIcon color={color} />
    </$Container>
  );
};

ChevronDisplay.displayName = 'ChevronDisplay';

export default ChevronDisplay;
