import React from 'react';
import { Row } from 'react-styled-flexboxgrid';
import { useTheme } from 'styled-components';

import { Heading } from '@savgroup-front-common/core/src/atoms/Heading';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { FLEX_ALIGN } from '@savgroup-front-common/types';

import { STEP_CONFIG } from '../../../ClassiqueRoutes.config';

import { getStepHeaderTitle } from './StepTitle.helpers';
import { PROFESSIONAL_STEP_CONFIG } from '../../../ProfessionalRoutes.config';

interface StepTitleProps {
  stepName: STEP_CONFIG | PROFESSIONAL_STEP_CONFIG;
}

const StepTitle: React.FC<StepTitleProps> = ({ stepName }) => {
  const theme = useTheme();

  return (
    <Row start="xs">
      <Heading level={2} align={FLEX_ALIGN.LEFT} color={theme.colors.black}>
        <SafeFormattedMessageWithoutSpread
          message={getStepHeaderTitle(stepName)}
        />
      </Heading>
    </Row>
  );
};

StepTitle.displayName = 'StepTitle';

export default StepTitle;
