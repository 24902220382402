import moment from 'moment';
import PropTypes from 'prop-types';

import { productProps } from './productProps';

export const orderProps = {
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  marketplace: PropTypes.string,
  orderId: PropTypes.string,
  orderReference: PropTypes.string,
  ownerId: PropTypes.string,
  products: PropTypes.arrayOf(productProps),
  productsCount: PropTypes.number,
  purchaseDate: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.string,
  ]),
  sellerId: PropTypes.string,
  sellerName: PropTypes.string,
  storeId: PropTypes.string,
  storePurchasedAt: PropTypes.string,
  tenantId: PropTypes.string,
  totalPrice: PropTypes.number,
  totalPriceCurrencyCode: PropTypes.string,
};

export const orderDefaultProps = {
  email: null,
  firstName: null,
  lastName: null,
  marketplace: null,
  orderId: null,
  orderReference: null,
  ownerId: null,
  products: [],
  productsCount: 0,
  purchaseDate: null,
  sellerId: null,
  sellerName: null,
  storeId: null,
  storePurchasedAt: null,
  tenantId: null,
  totalPrice: 0,
  totalPriceCurrencyCode: null,
};
