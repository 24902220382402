import * as Yup from 'yup';

import messages from './messages';

const chooseProductSchema = Yup.object().shape({
  selectedOwnerProductIds: Yup.array()
    .of(Yup.string())
    .min(1, messages.onKeyIsNeededError),
});

export default chooseProductSchema;
