export const mapFileStatesToFileActions = (fileStatuses) =>
  fileStatuses.map((fileStatus) => {
    const { fileId, module, stateDate, state, comment, toCustomerComment } =
      fileStatus;

    return {
      fileId,
      module,
      fileStatusDate: stateDate,
      fileStatus: { ...state },
      comment,
      toCustomerComment,
      actions: [],
    };
  });

const mapQuoteItemSummaries = (quoteItemSummaries, valueAddedTaxRate) =>
  quoteItemSummaries.map((quoteItem) => {
    const getVATIncludedPrice = (vatRATE, price) => price + vatRATE * price;
    const { partsPrice, laborPrice, bundlePrice } = quoteItem;
    const workingLaborPrice = getVATIncludedPrice(
      valueAddedTaxRate,
      laborPrice,
    );
    const workingPartPrice = getVATIncludedPrice(valueAddedTaxRate, partsPrice);
    const workingBundlePrice = getVATIncludedPrice(
      valueAddedTaxRate,
      bundlePrice,
    );

    let totalPrice = 0;

    if (workingPartPrice !== 0 && workingLaborPrice !== 0) {
      totalPrice = workingPartPrice + workingLaborPrice;
    } else {
      totalPrice = workingBundlePrice;
    }

    return {
      ...quoteItem,
      partsPrice: workingPartPrice,
      bundlePrice: workingBundlePrice,
      laborPrice: workingLaborPrice,
      totalPrice,
    };
  });

export const mapQuotes = (quotes) =>
  quotes.map((quote) => {
    const workingQuote = {
      ...quote,
      status: quote.state,
      quoteItemSummaries: mapQuoteItemSummaries(
        quote.quoteItemSummaries,
        quote.valueAddedTaxRate,
      ),
    };

    delete workingQuote.state;

    return workingQuote;
  });
