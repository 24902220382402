import { ANIMATION_DIRECTION } from '@savgroup-front-common/constants/src/shared';
import { ActionTypeIsNotSupportedError } from '@savgroup-front-common/exceptions';

import { MENU_ACTION_TYPES } from './Menu.types';

interface MenuState {
  animationType: ANIMATION_DIRECTION;
  path: number[];
  disabledAnimation: boolean;
}

export type MenuAction =
  | {
      type: MENU_ACTION_TYPES.ADD_PATH;
      payload: { index: number };
    }
  | {
      type: MENU_ACTION_TYPES.REMOVE_PATH;
    }
  | {
      type: MENU_ACTION_TYPES.RESET_PATH;
    };

export const MenuInit = (): MenuState => ({
  animationType: ANIMATION_DIRECTION.NEXT,
  path: [],
  disabledAnimation: false,
});

export const MenuReducer = (
  state: MenuState,
  action: MenuAction,
): MenuState => {
  switch (action.type) {
    case MENU_ACTION_TYPES.ADD_PATH:
      return {
        ...state,
        disabledAnimation: true,
        animationType: ANIMATION_DIRECTION.NEXT,
        path: [...state.path, action.payload.index],
      };

    case MENU_ACTION_TYPES.REMOVE_PATH:
      return {
        ...state,
        disabledAnimation: true,
        animationType: ANIMATION_DIRECTION.PREVIOUS,
        path: state.path.slice(0, state.path.length - 1),
      };
    case MENU_ACTION_TYPES.RESET_PATH:
      return {
        ...state,

        disabledAnimation: false,
        animationType: ANIMATION_DIRECTION.NEXT,
        path: [],
      };

    default:
      throw new ActionTypeIsNotSupportedError();
  }
};
