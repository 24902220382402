import React, { FC } from 'react';
import { Transition } from 'react-transition-group';
import { useMeasure } from 'react-use';

import { collapseAnimationDuration, shouldMount } from './Collapse.helpers';
import useAsyncCollapse from './Collapse.hooks';
import $Content from './Collapse.styles';

interface CollapseProps {
  isOpen: boolean;
  unmountOnExit?: boolean;
}

const Collapse: FC<React.PropsWithChildren<CollapseProps>> = ({
  children,
  isOpen = false,
  unmountOnExit = true,
}) => {
  const [ref, { height }] = useMeasure<HTMLDivElement>();
  const asyncIsOpen = useAsyncCollapse({ isOpen });

  return (
    <Transition
      in={asyncIsOpen}
      timeout={collapseAnimationDuration}
      unmountOnExit={unmountOnExit}
    >
      {(animationState) =>
        shouldMount({ unmountOnExit, animationState, isOpen }) && (
          <$Content
            $isOpen={isOpen}
            $unmountOnExit={unmountOnExit}
            animationState={animationState}
            $height={height}
            animationDuration={collapseAnimationDuration}
          >
            <div ref={ref}>{children}</div>
          </$Content>
        )
      }
    </Transition>
  );
};

Collapse.displayName = 'Collapse';

export default Collapse;
