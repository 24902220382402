import { tint } from 'polished';
import { Col, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { Tag } from '@savgroup-front-common/core/src/atoms/Tag';
import { rem } from '@savgroup-front-common/core/src/helpers';

export const InfoLine = styled(Row)`
  display: flex;
  align-items: center;
  padding: 24px 0;

  div {
    &:last-child {
      text-align: right;
    }
  }
`;

export const InfoCol = styled(Col)`
  && {
    display: flex;
    flex-direction: column;
  }
`;

export const InfoTag = styled(Tag)`
  align-self: flex-end;
`;

export const Separator = styled('div')`
  background-color: ${({ theme }) => tint(0.6, theme.colors.default)};
  height: 1px;
  margin-bottom: ${rem('6px')};
`;

export const Tab = styled(Col)`
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  flex-basis: auto;
  padding-right: 16px;
  margin: 0 0.5rem;
  cursor: pointer;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
`;
