import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  OrderSummary,
} from '@savgroup-front-common/types';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.owner}owners/${COMMON_QUERY_PARAMS.OWNER_ID}/products/${COMMON_QUERY_PARAMS.OWNER_PRODUCT_ID}/full`;

interface GetOrderByOwnerIdPayload {
  ownerId: string;
  productId: string;
}

const getProductFullInformations = async ({
  ownerId,
  productId,
}: GetOrderByOwnerIdPayload): Promise<
  BackResponse<OrderSummary> | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.OWNER_ID, ownerId).replace(
      COMMON_QUERY_PARAMS.OWNER_PRODUCT_ID,
      productId,
    );

    const response = await apiCall<BackResponse<OrderSummary>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default getProductFullInformations;
