import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  CURRENCIES,
} from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared/supportedMethods';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.repairer}files/${COMMON_QUERY_PARAMS.FILE_ID}/quotes/withmargin`;

interface GetQuoteInfoByFileIdQueryArgs {
  fileId: string;
}

interface GetQuoteInfoByFileIdQueryReturnValue {
  amountVAT: number;
  amountWithoutVAT: number;
  author: {
    userId: string;
    externalId: string;
    fullname: string;
  };
  comments: string;
  createdDate: Date;
  currencyCode: CURRENCIES;
  editedDate: Date;
  fileId: string;
  id: string;
  quoteItemSummaries: {
    bundlePrice: number;
    id: string;
    laborPrice: number;
    name: string;
  }[];
  state: string;
  valueAddedTaxRate: number;
}

async function getQuoteInfoByFileIdQuery({
  fileId,
}: GetQuoteInfoByFileIdQueryArgs): Promise<
  BackResponse<GetQuoteInfoByFileIdQueryReturnValue[]> | BackResponseFailure
> {
  try {
    const url = buildUrl(ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId));

    return await apiCall(url, {
      method: SUPPORTED_METHODS.GET,
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default getQuoteInfoByFileIdQuery;
