import React, { FunctionComponent } from 'react';

import { safeFormattedIntlString } from '@savgroup-front-common/core/src/formatters';

import messages from './messages';
import { MonoProductInfo } from './MonoProductInfo';

const ProductsInfoUnknown: FunctionComponent = () => {
  const translatedUnknownMessage = safeFormattedIntlString(
    messages.unknownMessage,
  );
  const productInfo = {
    fileReference: translatedUnknownMessage,
    isClosed: true,
    modelBrandName: translatedUnknownMessage,
    modelName: translatedUnknownMessage,
    orderLineReference: '',
    serialNumber: translatedUnknownMessage,
    solutionName: translatedUnknownMessage,
    solutionUniqueName: '',
  };

  return (
    <>
      <MonoProductInfo
        key={productInfo.orderLineReference}
        productInfo={productInfo}
      />
    </>
  );
};

ProductsInfoUnknown.displayName = 'ProductsInfoUnknown';
export default ProductsInfoUnknown;
