import md5 from 'md5';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col } from 'react-styled-flexboxgrid';

import { SafeFormattedMessage } from '@savgroup-front-common/core/src/formatters';
import { formatReverseMoneyToString } from '@savgroup-front-common/core/src/formatters/intl';
import { RawQuotationAdministrativeFeeEnriched } from '@savgroup-front-common/types';

import messages from '../messages';
import {
  $InlinePriceCol,
  $QuoteItemSummaryRow,
} from '../QuotationValidationSummary.styles';

interface AdministrativeFeesSummaryLineProps {
  administrativeFees?: RawQuotationAdministrativeFeeEnriched[];
}

const AdministrativeFeesSummaryLine: FunctionComponent<
  React.PropsWithChildren<AdministrativeFeesSummaryLineProps>
> = ({ administrativeFees }) => {
  if (!administrativeFees) {
    return <></>;
  }

  return (
    <>
      {administrativeFees.map(
        ({ description, quantity, amountIncludedTax }) => {
          return (
            <$QuoteItemSummaryRow
              key={md5(formatReverseMoneyToString(amountIncludedTax))}
              between="xs"
            >
              <Col xs={7} sm={8}>
                <SafeFormattedMessage {...messages.administrativeFees} /> -{' '}
                {quantity > 1 ? `${quantity}x` : null}
                {description}
              </Col>
              <$InlinePriceCol xs={5} sm={4}>
                {formatReverseMoneyToString(amountIncludedTax)}
                <FormattedMessage {...messages.vatIncluded} />
              </$InlinePriceCol>
            </$QuoteItemSummaryRow>
          );
        },
      )}
    </>
  );
};

AdministrativeFeesSummaryLine.displayName = 'AdministrativeFeesSummaryLine';

export default AdministrativeFeesSummaryLine;
