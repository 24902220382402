 
import { createReducer } from 'myaccount/helpers/reducerUtils';

import { PROFILEMGMT_PROFILE_UPDATED } from '../ProfileManagement/actionTypes';

import {
  LOGIN_CHANGE_PHONE,
  LOGIN_SIGNEDIN,
  LOGIN_SIGNEDOUT,
  LOGIN_SIGNIN,
  LOGIN_SIGNOUT,
} from './loginConst';

const initialState = {
  isLoggedIn: false,
  id: null,
  firstName: null,
  lastName: null,
  phone: null,
  email: null,
  failure: false,
  errors: [],
  isRunning: false,
  roles: [],
  profiles: [],
  permissions: [],
};

export function signInApply(state) {
  return Object.assign({}, state, { isRunning: true });
}

export function signedInApply(state, payload) {
  const newState = Object.assign({}, state, payload, {
    isLoggedIn: true,
    isRunning: false,
  });

  newState.isLoggedIn = !payload.failure;

  return newState;
}

export function signOutApply(state) {
  return Object.assign({}, state, { isRunning: true });
}

export function signedOutApply() {
  return Object.assign({}, initialState);
}

export function profileUpdatedApply(state, payload) {
  return {
    ...state,
    firstName: payload.firstName,
    lastName: payload.lastName,
    phone: payload.phone,
  };
}

export function profileUpdatePhoneApply(state, payload) {
  return {
    ...state,
    phone: payload.phone,
  };
}

const loginFeatureReducer = createReducer(initialState, {
  [LOGIN_SIGNIN]: signInApply,
  [LOGIN_SIGNEDIN]: signedInApply,
  [LOGIN_SIGNOUT]: signOutApply,
  [LOGIN_SIGNEDOUT]: signedOutApply,
  [PROFILEMGMT_PROFILE_UPDATED]: profileUpdatedApply,
  [LOGIN_CHANGE_PHONE]: profileUpdatePhoneApply,
});

export default loginFeatureReducer;
