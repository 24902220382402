import { defineMessages } from 'react-intl';

export default defineMessages({
  startByScanProductToReturn: {
    id: 'components.b2b.startByScanProductToReturn',
    defaultMessage: 'Start to scan the products to return',
  },
  add: {
    id: 'components.b2b.cart.add',
    defaultMessage: 'Add',
  },
  searchModelPlaceholder: {
    id: 'components.b2b.cart.searchModelPlaceholder',
    defaultMessage: 'Search by Reference, EAN or Sku',
  },
  scanButton: {
    id: 'components.b2b.cart.scanButton',
    defaultMessage: 'Scan',
  },
  productAddedToCart: {
    id: 'components.b2b.cart.productAddedToCart',
    defaultMessage: '{batchQuantity}x {modelName}',
  },
});
