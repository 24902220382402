import validator from 'validator';
import * as Yup from 'yup';

import { HANDLING_GROUPS } from '@savgroup-front-common/constants';
import {
  globalMessages,
  isValidPhoneNumber,
} from '@savgroup-front-common/core/src/helpers';

import messages from './messages';

const ClaimGroupDeliveryPageSchema = Yup.object().shape({
  chooseHandling: Yup.string().required(globalMessages.form.required),
  chooseHandlingPickupPoint: Yup.string().nullable(),
  pickupPointSelected: Yup.object().nullable(),
  addressSelected: Yup.object()
    .nullable()
    .when('chooseHandling', {
      is: HANDLING_GROUPS.EXTERNAL || HANDLING_GROUPS.HOME,
      then: Yup.object().shape({
        address: Yup.string().required(messages.requiredField),
        phone: Yup.string()
          .required(messages.requiredField)
          .test(
            'validPhoneNumber',
            messages.phoneFormatError,
            (value, context) => {
              const { countryCode } = context.parent;

              return isValidPhoneNumber(value, countryCode);
            },
          ),
        mail: Yup.string().required(messages.requiredField),
        companyName: Yup.string(),
        countryCode: Yup.string().required(messages.requiredField),
        lastName: Yup.string().required(messages.requiredField),
        firstName: Yup.string().required(messages.requiredField),
        postalCode: Yup.string()
          .required(messages.requiredField)
          .test('validPostalCode', messages.badFormatError, (value) => {
            if (value) {
              return validator.isAlphanumeric(value.replace(/\s+/g, ''));
            }

            return false;
          }),
        city: Yup.string().required(messages.requiredField),
        hasElevator: Yup.boolean(),
        hasParkingSpace: Yup.boolean(),
        housingType: Yup.string(),
        floor: Yup.string(),
        doorCode: Yup.string(),
        additionalInformation: Yup.string(),
      }),
    }),
});

export default ClaimGroupDeliveryPageSchema;
