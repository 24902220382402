/* eslint-disable max-len */
import React, { FC } from 'react';
import { useTheme } from 'styled-components';

const PictureIntervention: FC = () => {
  const theme = useTheme();

  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45.0596 42.9067L36.5166 51.4496C36.2919 51.6741 36.1137 51.9406 35.9921 52.234C35.8705 52.5273 35.8079 52.8418 35.8079 53.1594C35.8079 53.477 35.8705 53.7915 35.9921 54.0848C36.1137 54.3782 36.2919 54.6448 36.5166 54.8692V54.8692C36.9698 55.3223 37.5844 55.5768 38.2252 55.5768C38.866 55.5768 39.4806 55.3223 39.9338 54.8692L48.4767 46.3239M53.6907 46.7649L58.3766 51.4508C58.8297 51.904 59.0842 52.5186 59.0842 53.1594C59.0842 53.8002 58.8297 54.4148 58.3766 54.868V54.868C57.9234 55.321 57.3088 55.5756 56.668 55.5756C56.0272 55.5756 55.4126 55.321 54.9594 54.868L47.4569 47.3667M41.0515 37.2239L44.4711 40.6435M41.0515 37.2239L38.4862 38.077L35.9246 33.8055L37.6331 32.0969L41.9058 34.6598L41.0515 37.2239V37.2239Z"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.0596 42.9067C44.0397 40.3052 44.2391 36.8916 46.3416 34.7891C48.4441 32.6866 52.3229 32.2262 54.458 33.5083L50.7847 37.1816L50.4427 40.9407L54.2018 40.6L57.8752 36.9254C59.1584 39.0618 58.6968 42.9405 56.5943 45.0418C54.4918 47.1443 51.0795 47.3437 48.478 46.3239"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9158 27.2566L31.9158 14.4232L52.9158 27.2566M31.8492 51.7566H16.9825C16.6112 51.7566 16.2551 51.6091 15.9925 51.3465C15.73 51.084 15.5825 50.7279 15.5825 50.3566V35.4232"
        stroke="#2A2833"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

PictureIntervention.displayName = 'PictureIntervention';

export default PictureIntervention;
