import { Store } from '@savgroup-front-common/types';

export enum STORE_DOMAIN {
  LIST = 'list',
  BY_SELLER_ID = 'bySellerId',
}

export type StoreId = string;
export type SellerId = string;

export interface StoresDomainState {
  [STORE_DOMAIN.LIST]: Record<StoreId, Store>;
  [STORE_DOMAIN.BY_SELLER_ID]: Record<SellerId, StoreId[]>;
}
