import styled from 'styled-components';

export const $MDXContent = styled.div`
  a {
    color: ${({ theme }) => theme.colors.linkColor};
  }
  a:hover {
    text-decoration: underline;
  }
`;
