import React, { FunctionComponent } from 'react';

import { FileSummary } from '@savgroup-front-common/types';
import { FileStateHistorySummary } from 'myaccount/api/Workflow/getFileStatesQuery';

import ProductInfoHeader from '../ProductInfoHeader/ProductInfoHeader';
import ProductInfoHistory from '../ProductInfoHistory/ProductInfoHistory';
import { ProductsInfoMinimal } from '../ProductsInfo.types';

import { $Card, $CardContent, $Divider } from './MultiProductInfo.styles';
import MultiProductInfoDescription from './MultiProductInfoDescription/MultiProductInfoDescription';

const MultiProductInfo: FunctionComponent<{
  fileInfo?: FileSummary;
  productsInfo: ProductsInfoMinimal[];
  histories?: FileStateHistorySummary[];
  hasCreatedDate?: boolean;
  hasHistory?: boolean;
  hasOpenProductButton?: boolean;
  onClick?: () => void;
}> = ({
  fileInfo,
  productsInfo,
  histories = [],
  hasCreatedDate = false,
  hasHistory = false,
  hasOpenProductButton = false,
  onClick,
}) => {
  return (
    <$Card minimal elevation={2} onClick={onClick} isCliquable={!!onClick}>
      <ProductInfoHeader
        fileInfo={fileInfo}
        productInfo={productsInfo[0]}
        hasCreatedDate={hasCreatedDate}
        hasOpenProductButton={hasOpenProductButton}
        onClick={onClick}
      />
      <$Divider />
      <$CardContent>
        {productsInfo && (
          <MultiProductInfoDescription productsInfo={productsInfo} />
        )}
        {hasHistory && <ProductInfoHistory histories={histories} />}
      </$CardContent>
    </$Card>
  );
};

MultiProductInfo.displayName = 'MultiProductInfo';
export default MultiProductInfo;
