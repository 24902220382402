import React, { FC, Fragment } from 'react';
import { FormattedDate } from 'react-intl';
import { useTheme } from 'styled-components';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { Tag } from '@savgroup-front-common/core/src/atoms/Tag';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { ChevronRightIcon } from '@savgroup-front-common/core/src/protons/icons';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';
import { ClaimContextState, ICONS_TYPE } from '@savgroup-front-common/types';

import { OwnerProductSummaryDto } from '../../HomePage.types';

import { useGetProductsDetails } from './HomePageProductItem.hooks';
import {
  $CardContentThumbnail,
  $CardTagHeader,
  $CardTagWrapperHeader,
  $ChevronForMobile,
  $HomePageProductItemWrapper,
  $ProductItemAction,
  $ProductItemContentWrapper,
  $ProductItemInfo,
  $ProductItemInfoDate,
  $ProductItemInfoLabel,
  $ProductItemPictureWrapper,
  $ProductItemTitle,
  $ProductItemWrapper,
  $Separator,
} from './HomePageProductItem.styles';
import messages from './messages';
import { ProductImage } from '@savgroup-front-common/core/src/atoms/ProductImage';

const HomePageProductItem: FC<{ product: OwnerProductSummaryDto }> = ({
  product,
}) => {
  const theme = useTheme();
  const {
    productsDetails,
    issues,
    isLoading,
    handleCreateClaimWithIssue,
    handleFollowClaim,
    handleFinalizeClaim,
  } = useGetProductsDetails({
    product,
  });

  const hasNotOpenFileAndClaimId =
    !product.hasOpenFile && (!!product.claimGroupId || !!product.claimId);
  const hasOpenFile = product.hasOpenFile || hasNotOpenFileAndClaimId;
  const isNullBuyDateForBack =
    productsDetails.buyDate && productsDetails.buyDate?.getFullYear() <= 1900;

  if (isLoading) {
    return (
      <$HomePageProductItemWrapper $isSmall>
        <BaseLoader hasMargin={false} hasHeightAuto />
      </$HomePageProductItemWrapper>
    );
  }

  return (
    <$HomePageProductItemWrapper $isDisabled={hasOpenFile}>
      <$ProductItemWrapper>
        {(productsDetails?.pictureUrl || productsDetails?.modelTypeId) && (
          <$ProductItemPictureWrapper>
            <$CardContentThumbnail>
              <ProductImage pictureUrl={productsDetails?.pictureUrl} />
            </$CardContentThumbnail>
          </$ProductItemPictureWrapper>
        )}
        <$ProductItemContentWrapper>
          <$CardTagWrapperHeader>
            {productsDetails?.solutionName && (
              <$CardTagHeader>
                <Tag isSmall neutral>
                  {productsDetails?.solutionName}
                </Tag>
              </$CardTagHeader>
            )}
          </$CardTagWrapperHeader>
          <$ProductItemTitle>
            {productsDetails?.locales && productsDetails?.locales[0]
              ? productsDetails?.locales[0].label
              : productsDetails?.name}
          </$ProductItemTitle>
          <$ProductItemInfo>
            {productsDetails?.buyDate && !isNullBuyDateForBack && (
              <$ProductItemInfoDate>
                <$ProductItemInfoLabel>
                  <SafeFormattedMessageWithoutSpread
                    message={messages.infoDateLabel}
                  />
                </$ProductItemInfoLabel>
                <FormattedDate value={productsDetails?.buyDate} />
              </$ProductItemInfoDate>
            )}
          </$ProductItemInfo>
        </$ProductItemContentWrapper>
      </$ProductItemWrapper>
      <$ProductItemAction>
        {!hasOpenFile &&
          issues?.map((issue, idx) => (
            <Fragment key={issue.id}>
              {idx !== 0 && <$Separator />}
              <Button
                type={BUTTON_TYPES.BUTTON}
                fluid
                naked
                onClick={() =>
                  handleCreateClaimWithIssue({
                    ownerId: productsDetails.ownerId,
                    ownerProductIds: [product.ownerProductId],
                    issueId: issue.id,
                  })
                }
              >
                <MyAccountIcon
                  icon={issue.iconKey as ICONS_TYPE}
                  color={theme.colors.primary}
                />
                <span>{issue.name}</span>
                <$ChevronForMobile>
                  <ChevronRightIcon color={theme.colors.paragraphTextColor} />
                </$ChevronForMobile>
              </Button>
            </Fragment>
          ))}
        {product.fileId && (
          <Button
            type={BUTTON_TYPES.BUTTON}
            fluid
            naked
            onClick={() =>
              handleFollowClaim({ fileId: product.fileId as string })
            }
          >
            <MyAccountIcon
              icon={ICONS_TYPE.FOLDER_ICON}
              color={theme.colors.primary}
            />
            <SafeFormattedMessageWithoutSpread
              message={messages.followRepair}
            />
            <$ChevronForMobile>
              <ChevronRightIcon color={theme.colors.paragraphTextColor} />
            </$ChevronForMobile>
          </Button>
        )}
        {hasNotOpenFileAndClaimId && (
          <>
            {product.fileId && <$Separator />}
            <Button
              type={BUTTON_TYPES.BUTTON}
              fluid
              naked
              onClick={() =>
                handleFinalizeClaim(ClaimContextState.WAITING_FOR_REASON)
              }
            >
              <MyAccountIcon
                icon={ICONS_TYPE.CONSUMABLE_ICON}
                color={theme.colors.primary}
              />
              <SafeFormattedMessageWithoutSpread
                message={messages.finalizeClaim}
              />
              <$ChevronForMobile>
                <ChevronRightIcon color={theme.colors.paragraphTextColor} />
              </$ChevronForMobile>
            </Button>
          </>
        )}
      </$ProductItemAction>
    </$HomePageProductItemWrapper>
  );
};

HomePageProductItem.displayName = 'HomePageProductItem';
export default HomePageProductItem;
