import React from 'react';

import { ProductImage } from '@savgroup-front-common/core/src/atoms/ProductImage';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { OrderDetailsProduct } from '@savgroup-front-common/types';

import { Claim } from '../../../app/NewLayout/StepsProvider/StepsProvider.types';
import messages from '../messages';
import {
  $CardContentThumbnail,
  $CardContentThumbnailWrapper,
  $ResumeDescriptionProduct,
  $ResumeInfoProduct,
  $ResumeInfoProductSerialNumber,
  $ResumeInfoProductTag,
  $ResumeInfoProductTitle,
  $ResumePictureProduct,
  $ResumeSeparator,
} from '../ResumeCard.styles';

import useResumeCardHeader from './ResumeCardHeader.hooks';

interface ResumeCardHeaderProps {
  product: OrderDetailsProduct;
  currentClaim?: Claim;
  index: number;
}

const ResumeCardHeader: React.FC<ResumeCardHeaderProps> = ({
  product,
  currentClaim,
  index,
}) => {
  const { modelDetail, serialNumber } = useResumeCardHeader({
    product,
    currentClaim,
  });

  return (
    <>
      <$ResumeDescriptionProduct>
        <$ResumePictureProduct>
          <$CardContentThumbnailWrapper>
            <$CardContentThumbnail>
              <ProductImage pictureUrl={modelDetail?.pictureUrl} />
            </$CardContentThumbnail>
          </$CardContentThumbnailWrapper>
        </$ResumePictureProduct>

        <$ResumeInfoProduct>
          <$ResumeInfoProductTitle
            data-testid={`ResumeCardHeader_modelDisplayName_${index}`}
          >
            {product?.modelDisplayName}
          </$ResumeInfoProductTitle>

          {serialNumber && (
            <$ResumeInfoProductSerialNumber>
              <SafeFormattedMessageWithoutSpread
                message={messages.serialNumber}
                values={{
                  serialNumber,
                }}
                dataTestId={`ResumeCardHeader_serialNumber_${index}`}
              />
            </$ResumeInfoProductSerialNumber>
          )}

          <$ResumeInfoProductTag />
        </$ResumeInfoProduct>
      </$ResumeDescriptionProduct>
      <$ResumeSeparator />
    </>
  );
};

ResumeCardHeader.displayName = 'ResumeCardHeader';

export default ResumeCardHeader;
