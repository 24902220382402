import capitalize from 'lodash/capitalize';
import fromPairs from 'lodash/fromPairs';
import get from 'lodash/get';
import head from 'lodash/head';
import { matchPath } from 'react-router-dom';
import { createSelector } from 'reselect';

import { Selectors as ClaimSelectors } from '@savgroup-front-common/core/src/domains/claims';
import { pathname } from '@savgroup-front-common/core/src/domains/selectors';

import { ROUTES } from '../../view/app/routes/Routes';

export const currentClaimGroupIdSelector = createSelector(
  [pathname],
  (path) => {
    const options = matchPath(path, {
      path: ROUTES.CLAIM_GROUP_DETAILS,
      exact: false,
      strict: false,
    });
    const claimGroupId = get(options, 'params.claimGroupId');

    return claimGroupId;
  },
);

export const currentClaimGroupHandlingModeSelector = createSelector(
  [pathname],
  (path) => {
    const options = matchPath(path, {
      path: ROUTES.CLAIM_GROUP_HANDLING,
      exact: true,
      strict: false,
    });
    const handlingMode = get(options, 'params.handlingMode') || '';

    return capitalize(handlingMode);
  },
);

export const currentClaimGroup = createSelector(
  [currentClaimGroupIdSelector, ClaimSelectors.claimGroups],
  (claimGroupId, claimGroups) =>
    claimGroups.getIn([claimGroupId, 'value']) || [],
);

export const currentClaims = createSelector(
  [currentClaimGroup, ClaimSelectors.claims],
  (claimGroup, claims) =>
    fromPairs(
      claimGroup.map((claimId) => [claimId, claims.getIn([claimId, 'value'])]),
    ),
);

export const currentSelectedIssueId = createSelector(
  [currentClaimGroup, currentClaims],
  (claimGroup, claims) => get(claims, [head(claimGroup), 'issueId']),
);

export const currentIssues = createSelector(
  [currentClaimGroup, ClaimSelectors.issues],
  (claimGroup, issues) =>
    fromPairs(claimGroup.map((claimId) => [claimId, issues.getIn([claimId])])),
);

export const currentReasons = createSelector(
  [currentClaimGroup, ClaimSelectors.reasons],
  (claimGroup, reasons) =>
    fromPairs(claimGroup.map((claimId) => [claimId, reasons.getIn([claimId])])),
);

export const currentProducts = createSelector(
  [currentClaimGroup, ClaimSelectors.products],
  (claimGroup, products) =>
    fromPairs(
      claimGroup.map((claimId) => [
        claimId,
        products.getIn([claimId, 'value']),
      ]),
    ),
);

export const currentComments = createSelector(
  [currentClaimGroup, ClaimSelectors.comments],
  (claimGroup, comments) =>
    fromPairs(claimGroup.map((claimId) => [claimId, comments.get(claimId)])),
);

export const currentClaimUploads = createSelector(
  [currentClaimGroup, ClaimSelectors.claimUploads],
  (claimGroup, claimUploads) =>
    fromPairs(
      claimGroup.map((claimId) => [claimId, claimUploads.get(claimId, {})]),
    ),
);

export const currentGroupHandlingInfo = createSelector(
  [currentClaimGroupIdSelector, ClaimSelectors.groupHandling],
  (claimGroupId, groupHandling) => groupHandling.get(claimGroupId),
);

export const selectGroupHandlingInfo = createSelector(
  [currentGroupHandlingInfo],
  (groupHandlingInfo) =>
    groupHandlingInfo ? groupHandlingInfo.toJS()?.value : undefined,
);

export const currentGroupHandlingMethods = createSelector(
  [
    currentClaimGroupIdSelector,
    ClaimSelectors.groupCarriers,
    currentClaimGroupHandlingModeSelector,
  ],
  (claimGroupId, groupCarriers, handlingMode) => {
    return groupCarriers.getIn([claimGroupId, handlingMode]);
  },
);

export const currentClaimGroupConfirmation = createSelector(
  [currentClaimGroupIdSelector, ClaimSelectors.claimGroupsConfirmation],
  (claimGroupId, claimGroupsConfirmation) =>
    claimGroupsConfirmation.get(claimGroupId),
);
