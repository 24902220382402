import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';
import { IssueSummaryDto } from '../../types/IssueSummaryDto';

 
const ENDPOINT = `${APIConfiguration.claim}issues/forOwner/${COMMON_QUERY_PARAMS.OWNER_ID}`;

interface GetIssuesForOwnerQueryArgs {
  sellerId: string;
  ownerId: string;
}

export const getIssuesForOwnerQuery = async ({
  sellerId,
  ownerId,
}: GetIssuesForOwnerQueryArgs): Promise<
  BackResponse<IssueSummaryDto[]> | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.OWNER_ID, ownerId);

    const response = await apiCall<BackResponse<IssueSummaryDto[]>>(
      buildUrl(url, { sellerId }),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};
