import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  PAYMENT_STATUS,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.payment}claimGroups/${COMMON_QUERY_PARAMS.CLAIM_GROUP_ID}/lastPayment`;

interface GetClaimGroupLastPaymentQueryArgs {
  claimGroupId: string;
}

export interface LastPayment {
  paymentId: string;
  status: PAYMENT_STATUS;
}

async function getClaimGroupLastPaymentQuery(
  payload: GetClaimGroupLastPaymentQueryArgs,
): Promise<BackResponse<LastPayment> | BackResponseFailure> {
  try {
    const url = ENDPOINT.replace(
      COMMON_QUERY_PARAMS.CLAIM_GROUP_ID,
      payload.claimGroupId,
    );

    const response = await apiCall<BackResponse<LastPayment>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default getClaimGroupLastPaymentQuery;
