import { useMutation, useQueryClient } from 'react-query';
import CatalogService from '../../../../../api/Catalog/CatalogService';
import useGetClientInformation from '../../../../app/hooks/useGetClientInformation';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { OwnerService } from '../../../../../api';
import { useParams } from 'react-router-dom';
import {
  GET_BUSINESS_RETURN,
  useGetBusinessReturn,
} from './hooks/useGetBusinessReturn';
import { UseFormReturn } from 'react-hook-form';
import { CartManagerForm } from './CartManager.types';
import { toast } from '@savgroup-front-common/core/src/atoms/Toast';
import { safeFormattedIntlString } from '@savgroup-front-common/core/src/formatters';
import messages from './messages';

export const useAddProductToBusinessReturn = ({
  formContext,
}: {
  formContext: UseFormReturn<CartManagerForm>;
}) => {
  const queryClient = useQueryClient();
  const { orderReference } = useParams<{ orderReference: string }>();
  const { clientInformation } = useGetClientInformation();
  const { setValue } = formContext;

  const { pushErrors } = useToasts();

  const { order } = useGetBusinessReturn();

  const { mutateAsync: handleProductScan, isLoading: isAddProductLoading } =
    useMutation(
      ['setProductToBusinessReturnCommand'],
      async ({ value, typeName }: { value: string; typeName: string }) => {
        const sellerId = clientInformation?.sellerId;

        if (!sellerId || !order) {
          return undefined;
        }

        const result = await CatalogService.scanProductCommand({
          sellerId,
          scannedValue: value,
          scannedType: typeName,
        });

        if (result.failure) {
          pushErrors(result.errors);

          return undefined;
        }

        const { model } = result.value;

        const oldProduct = order.products.find(
          (product) => product.modelId === model.id,
        );

        const response = await OwnerService.setProductToBusinessReturnCommand({
          sellerId,
          modelId: model.id,
          batchQuantity: oldProduct?.batchQuantity
            ? oldProduct.batchQuantity + 1
            : 1,
          orderReference,
        });

        if (response.failure) {
          pushErrors(response.errors);

          return undefined;
        }

        if (oldProduct?.batchQuantity) {
          setValue(
            `product.${oldProduct.modelId}.batchQuantity`,
            oldProduct.batchQuantity + 1,
          );

          toast.success(
            safeFormattedIntlString(messages.productAddedToCart, {
              modelName: model.name,
              batchQuantity: (oldProduct.batchQuantity ?? 0) + 1,
            }),
            {
              autoClose: 500,
            },
          );
        } else {
          toast.success(
            safeFormattedIntlString(messages.productAddedToCart, {
              modelName: model.name,
              batchQuantity: 1,
            }),
            {
              autoClose: 500,
            },
          );
        }

        await queryClient.invalidateQueries([GET_BUSINESS_RETURN]);

        return undefined;
      },
    );

  const {
    mutateAsync: handleAddProductBySearchValue,
    isLoading: isAddProductBySearchValueLoading,
  } = useMutation([], async (searchValue: string) => {
    const sellerId = clientInformation?.sellerId;

    if (!sellerId || !order) {
      return undefined;
    }

    const [resultByEan, resultBySku] = await Promise.all([
      CatalogService.getModelByEanQuery({
        sellerId,
        ean: searchValue,
      }),
      CatalogService.getModelBySkuQuery({
        sellerId,
        sku: searchValue,
      }),
    ]);

    if (!resultByEan.failure || !resultBySku.failure) {
      const modelByEan = !resultByEan.failure ? resultByEan.value : undefined;
      const modelBySku = !resultBySku.failure ? resultBySku.value : undefined;

      const model = modelByEan?.at(0) ?? modelBySku?.at(0);

      if (!model) {
        return undefined;
      }

      const response = await OwnerService.setProductToBusinessReturnCommand({
        sellerId,
        modelId: model.id,
        batchQuantity: 1,
        orderReference,
      });

      if (response.failure) {
        pushErrors(response.errors);

        return undefined;
      }

      toast.success(
        safeFormattedIntlString(messages.productAddedToCart, {
          modelName: model.name,
          batchQuantity: 1,
        }),
      );

      const oldProduct = order.products.find(
        (product) => product.modelId === model.id,
      );

      if (oldProduct) {
        setValue(
          `product.${oldProduct.modelId}.batchQuantity`,
          (oldProduct.batchQuantity ?? 0) + 1,
        );
      }

      await queryClient.invalidateQueries([GET_BUSINESS_RETURN]);

      return undefined;
    }

    return undefined;
  });

  return {
    handleAddProductBySearchValue,
    handleProductScan,
    isAddProductLoading:
      isAddProductLoading || isAddProductBySearchValueLoading,
  };
};

export const useRemoveProductFromBusinessReturn = ({
  formContext,
}: {
  formContext: UseFormReturn<CartManagerForm>;
}) => {
  const queryClient = useQueryClient();
  const { orderReference } = useParams<{ orderReference: string }>();
  const { clientInformation } = useGetClientInformation();

  const { setValue } = formContext;

  const { pushErrors } = useToasts();

  const {
    mutateAsync: handleRemoveProduct,
    isLoading: isRemoveProductLoading,
  } = useMutation([], async ({ modelId }: { modelId: string }) => {
    if (!clientInformation?.sellerId) {
      return undefined;
    }

    const response = await OwnerService.removeProductToBusinessReturnCommand({
      sellerId: clientInformation?.sellerId,
      orderReference,
      modelId,
    });

    if (response.failure) {
      pushErrors(response.errors);

      return undefined;
    }

    setValue(`product.${modelId}.batchQuantity`, undefined);

    await queryClient.invalidateQueries([GET_BUSINESS_RETURN]);

    return response.value;
  });

  return {
    handleRemoveProduct,
    isRemoveProductLoading,
  };
};
