import { add, format } from 'date-fns';

import { STANDARD_DATE_TIME_FORMAT_DATE_FNS } from '@savgroup-front-common/constants';

type AddSchedulePayload =
  | {
      daysFromToday?: number;
      startHours?: number;
      startMinutes?: number;
      startSeconds?: number;
      endHours?: number;
      endMinutes?: number;
      endSeconds?: number;
      daysToExclude?: number[];
    }
  | undefined;

export class InterventionAppointmentMockBuilder {
  #schedules: { start: string; finish: string }[] = [];

  get schedules(): { start: string; finish: string }[] {
    return this.#schedules;
  }

  addSchedule(
    {
      daysFromToday = 0,
      startHours = 0,
      startMinutes = 0,
      startSeconds = 0,
      endHours = 0,
      endMinutes = 0,
      endSeconds = 0,
      daysToExclude = [],
    }: AddSchedulePayload = {
      daysFromToday: 0,
      startHours: 0,
      startMinutes: 0,
      startSeconds: 0,
      endHours: 0,
      endMinutes: 0,
      endSeconds: 0,
      daysToExclude: [],
    },
  ): InterventionAppointmentMockBuilder {
    const referenceDate = add(new Date(), { days: 1 + daysFromToday });

    if (daysToExclude.includes(referenceDate.getDay())) {
      return this;
    }

    referenceDate.setHours(startHours);
    referenceDate.setMinutes(startMinutes);
    referenceDate.setSeconds(startSeconds);

    const start = `${format(
      referenceDate,
      STANDARD_DATE_TIME_FORMAT_DATE_FNS,
    )}`;

    referenceDate.setHours(endHours);
    referenceDate.setMinutes(endMinutes);
    referenceDate.setSeconds(endSeconds);

    this.#schedules.push({
      start,
      finish: `${format(referenceDate, STANDARD_DATE_TIME_FORMAT_DATE_FNS)}`,
    });

    return this;
  }

  addDemoSchedules() {
    return this.addSchedule({ startHours: 8, endHours: 13, daysToExclude: [0] })
      .addSchedule({ startHours: 13, endHours: 19, daysToExclude: [0] })
      .addSchedule({
        startHours: 8,
        endHours: 13,
        daysFromToday: 1,
        daysToExclude: [0],
      })
      .addSchedule({
        startHours: 13,
        endHours: 19,
        daysFromToday: 1,
        daysToExclude: [0],
      })
      .addSchedule({
        startHours: 8,
        endHours: 13,
        daysFromToday: 2,
        daysToExclude: [0],
      })
      .addSchedule({
        startHours: 13,
        endHours: 19,
        daysFromToday: 2,
        daysToExclude: [0],
      });
  }

  addStandardSchedules(
    { daysToExclude }: { daysToExclude?: number[] } = { daysToExclude: [] },
  ) {
    return this.addSchedule({ startHours: 10, endHours: 11, daysToExclude })
      .addSchedule({
        startHours: 12,
        startMinutes: 1,
        endHours: 13,
        endMinutes: 1,
        daysToExclude,
      })
      .addSchedule({
        startHours: 14,
        startMinutes: 2,
        endHours: 15,
        endMinutes: 3,
        daysToExclude,
      })
      .addSchedule({ daysFromToday: 1, startHours: 10, endHours: 11 })
      .addSchedule({
        daysFromToday: 1,
        startHours: 12,
        endHours: 13,
        startMinutes: 1,
        endMinutes: 1,
        daysToExclude,
      })
      .addSchedule({
        daysFromToday: 1,
        startHours: 14,
        endHours: 15,
        startMinutes: 2,
        endMinutes: 3,
        daysToExclude,
      })
      .addSchedule({ daysFromToday: 2, startHours: 10, endHours: 11 })
      .addSchedule({
        daysFromToday: 2,
        startHours: 12,
        endHours: 13,
        startMinutes: 1,
        endMinutes: 1,
        daysToExclude,
      })
      .addSchedule({
        daysFromToday: 2,
        startHours: 14,
        endHours: 15,
        startMinutes: 2,
        endMinutes: 3,
        daysToExclude,
      });
  }
}
