import { add, formatISO } from 'date-fns';
import { PAGINATION_START_FROM_DAY } from '../../../TakeInterventionAppointmentLegacy.constants';

export const getStartAndEndDate = ({
  pageNumber,
  pageSize,
}: {
  pageNumber: number;
  pageSize: number;
}) => {
  const startDate = add(new Date(), {
    days: 1,
  }).setHours(0, 0, 0);

  return {
    startDate: formatISO(
      add(startDate, {
        days: (pageNumber - 1) * pageSize + PAGINATION_START_FROM_DAY,
      }),
    ),
    endDate: formatISO(
      add(startDate, {
        days:
          (pageNumber - 1) * pageSize +
          PAGINATION_START_FROM_DAY +
          (pageSize - 1) +
          1,
      }),
    ),
  };
};
