import { NavArrowDown } from 'iconoir-react';
import React, { FunctionComponent } from 'react';

import { IconPropsInterface } from '../Icon.types';

const ArrowDownIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => <NavArrowDown width={size} height={size} color={color} />;

ArrowDownIcon.displayName = 'ArrowDownIcon';

export default ArrowDownIcon;
