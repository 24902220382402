import * as Yup from 'yup';

import {
  globalMessages,
  isPhoneNumber,
} from '@savgroup-front-common/core/src/helpers';

import messages from './messages';

const claimGroupConfirmationSchema = Yup.object().shape({
  phone: Yup.string()
    .required(globalMessages.form.required)
    .test('validPhoneNumber', messages.phoneFormatError, (value) => {
      return isPhoneNumber(value);
    }),
  email: Yup.string().required(globalMessages.form.required),
  cgv: Yup.bool()
    .oneOf([true], globalMessages.form.required)
    .required(globalMessages.form.required),
});

export default claimGroupConfirmationSchema;
