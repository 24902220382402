import { useEffect } from 'react';

import { DOCUMENT_TYPES } from '@savgroup-front-common/constants/src/shared';

interface UseGetDocumentStatusByIdArgs {
  fileId: string;
  type: string;
  getDocumentStatusByFileId: ({
    fileId,
    type,
  }: {
    fileId: string;
    type: string;
  }) => void;
}

const useGetDocumentStatusById = ({
  fileId,
  type,
  getDocumentStatusByFileId,
}: UseGetDocumentStatusByIdArgs): void => {
  useEffect(() => {
    if (type && DOCUMENT_TYPES.SHIPPING_LABEL !== type) {
      getDocumentStatusByFileId({ fileId, type });
    }
  }, [fileId, type, getDocumentStatusByFileId]);
};

export default useGetDocumentStatusById;
