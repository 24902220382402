import get from 'lodash/get';
import { ActionMeta } from 'redux-actions';

import { ClaimsState } from '../types';

interface OnLoadTranslationSuccessMeta {
  issueId: string;
  reasonId: string;
  warrantyTypeId: string;
  solutionId: string;
}

function onLoadTranslationSuccess(
  state: ClaimsState,
  { payload, meta }: ActionMeta<any, OnLoadTranslationSuccessMeta>,
): ClaimsState {
  const value = get(payload, 'value');
  const { issueName, reasonName, warrantyTypeName, solutionName } = value;
  const issues = state.getIn(['translations', 'issues']);
  const reasons = state.getIn(['translations', 'reasons']);
  const solutions = state.getIn(['translations', 'solutions']);
  const warranties = state.getIn(['translations', 'warranties']);
  const { issueId, reasonId, warrantyTypeId, solutionId } = meta;

  return state.update('translations', (oldValue) =>
    oldValue
      .set('issues', { ...issues, [issueId]: issueName })
      .set('reasons', { ...reasons, [reasonId]: reasonName })
      .set('solutions', {
        ...solutions,
        [solutionId]: solutionName,
      })
      .set('warranties', {
        ...warranties,
        [warrantyTypeId]: warrantyTypeName,
      }),
  );
}

export default onLoadTranslationSuccess;
