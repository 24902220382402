import React from 'react';
import ReactIntersectionObserver from 'react-intersection-observer';

function supportsIntersectionObserver() {
  return (
    'IntersectionObserver' in global &&
    'IntersectionObserverEntry' in global &&
    'intersectionRatio' in global.IntersectionObserverEntry.prototype
  );
}

export const VisibilityObserver = ({ children, ...restProps }) =>
  supportsIntersectionObserver() ? (
    <ReactIntersectionObserver {...restProps}>
      {children}
    </ReactIntersectionObserver>
  ) : (
    children({ inView: true })
  );
