import { STEP_CONFIG, stepsConfig } from '../ClassiqueRoutes.config';

export const buildPath = (stepName: STEP_CONFIG, param?: string) => {
  const path = stepsConfig.find((item) => item.name === stepName)?.path;

  if (param) {
    switch (stepName) {
      case STEP_CONFIG.CHOOSE_PRODUCTS: {
        return path?.replace(':issueId', param);
      }
      case STEP_CONFIG.CLAIM_GROUP_REASON: {
        return path?.replace(':claimGroupId', param);
      }
      case STEP_CONFIG.CLAIM_GROUP_PRODUCT_INFO: {
        return path?.replace(':claimGroupId', param);
      }
      case STEP_CONFIG.CLAIM_GROUP_DOCUMENT_RELATED_TO_PRODUCT: {
        return path?.replace(':claimGroupId', param);
      }
      case STEP_CONFIG.CLAIM_GROUP_DOCUMENT_RELATED_TO_CLAIM: {
        return path?.replace(':claimGroupId', param);
      }
      case STEP_CONFIG.CLAIM_GROUP_SOLUTION: {
        return path?.replace(':claimGroupId', param);
      }
      case STEP_CONFIG.CLAIM_GROUP_DEPOSIT: {
        return path?.replace(':claimGroupId', param);
      }
      case STEP_CONFIG.CLAIM_GROUP_DELIVERY: {
        return path?.replace(':claimGroupId', param);
      }
      case STEP_CONFIG.CLAIM_GROUP_CONFIRMATION: {
        return path?.replace(':claimGroupId', param);
      }
      default:
        return path;
    }
  }

  return path;
};
