import React, { FunctionComponent, useContext } from 'react';

import { ContentTemplateContext } from '../../MDXCustomComponents.context';
import getAdditionalInformationLabel from '../helpers/getAdditionalInformationLabel';

const FileAddInfo: FunctionComponent<React.PropsWithChildren<{ uniqueName: string }>> = ({
  uniqueName,
}) => {
  const {
    isLoading,
    additionalInformationEntitiesMap = {},
    customerFile,
  } = useContext(ContentTemplateContext);
  const fileProducts = customerFile?.fileProducts || [];

  if (isLoading) {
    return <></>;
  }

  const concernedAdditionalInformation = Object.values(
    additionalInformationEntitiesMap,
  ).find(
    (item) => item?.additionalInformationEntity?.uniqueName === uniqueName,
  );

  if (
    concernedAdditionalInformation &&
    !concernedAdditionalInformation.additionalInformationEntity
      .uniquenessByProduct
  ) {
    const fileAdditionalInformation =
      fileProducts[0].fileAdditionalInformation?.find(
        (item) =>
          item.additionalInformationId === concernedAdditionalInformation.id,
      );

    if (!fileAdditionalInformation) {
      return <></>;
    }

    const label = getAdditionalInformationLabel({
      informationType:
        concernedAdditionalInformation.additionalInformationEntity
          .informationType,
      additionalInformationEntitiesMap,
      additionalInformationEnumValue:
        fileAdditionalInformation.additionalInformationEnumValue,
      additionalInformationStringValue:
        fileAdditionalInformation.additionalInformationStringValue,
    });

    return <>{label}</>;
  }

  const value = concernedAdditionalInformation
    ? fileProducts.reduce<string[]>((acc, fileProduct) => {
        const fileAdditionalInformation =
          fileProduct.fileAdditionalInformation?.find(
            (item) =>
              item.additionalInformationId ===
              concernedAdditionalInformation.id,
          );

        if (!fileAdditionalInformation) {
          return acc;
        }

        const { informationType } =
          concernedAdditionalInformation.additionalInformationEntity;
        const { additionalInformationStringValue } = fileAdditionalInformation;
        const { additionalInformationEnumValue } = fileAdditionalInformation;

        const label = getAdditionalInformationLabel({
          informationType,
          additionalInformationStringValue,
          additionalInformationEnumValue,
          additionalInformationEntitiesMap,
        });

        if (label) {
          return [...acc, label];
        }

        return acc;
      }, [])
    : [];

  return <>{value.join(', ')}</>;
};

FileAddInfo.displayName = 'FileAddInfo';

export default FileAddInfo;
