import styled from 'styled-components';

import { rem } from '../../helpers';
import { getTextColorBasedOnProps } from '../../theme/helpers';

const H2 = styled.h2.attrs({ role: 'presentation' })<{
  medium?: boolean;
  heading?: boolean;
  align?: string;
}>`
  font-size: ${rem('24px')};
  font-weight: ${(props) => (props.medium ? 500 : 400)};
  margin: 0;
  padding-top: ${({ heading }) => (heading ? rem('32px') : '0')};
  padding-bottom: ${({ heading }) => (heading ? rem('32px') : '0')};
  color: ${(props) => getTextColorBasedOnProps(props)};
  text-align: ${(props) => props.align};
`;

export default H2;
