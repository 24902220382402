import React, { FC, RefObject } from 'react';

import { SUPPORTED_COMPONENTS } from '@savgroup-front-common/types';

import { SafeFormattedMessage } from '../../../formatters';
import Menu from '../../Menu/Menu';
import { MENU_ITEM_TYPES, MENU_POSITIONS } from '../../Menu/Menu.types';
import { RouteSubTabConfig } from '../RouteTabs.types';

import {
  $SubMenuLinkCustom,
  $SubTabMessage,
  $SubTabMessageWithIcon,
} from './RouteSubTab.styles';

interface RouteTabsSubMenuProps {
  subTab: RouteSubTabConfig[];
  wrapperRef: RefObject<any>;
  isOpen: boolean;
  onClose: () => void;
  currentSubTabName?: string;
}

const RouteTabsSubMenu: FC<RouteTabsSubMenuProps> = ({
  subTab,
  wrapperRef,
  isOpen,
  onClose,
  currentSubTabName,
}) => {
  return (
    <Menu
      menuItems={subTab
        .filter((tab) => !tab.isHidden)
        .map((tab) => {
          return {
            ...tab,
            type: MENU_ITEM_TYPES.CUSTOM,
            key: tab.name,
            children: (
              <$SubMenuLinkCustom
                to={tab.url}
                $isActive={currentSubTabName === tab.name}
              >
                {tab.icon && (
                  <$SubTabMessageWithIcon>
                    {tab.icon}
                    <$SubTabMessage>
                      {SafeFormattedMessage(tab.message)}
                    </$SubTabMessage>
                  </$SubTabMessageWithIcon>
                )}
                {!tab.icon && SafeFormattedMessage(tab.message)}
              </$SubMenuLinkCustom>
            ),
          };
        })}
      isOpen={isOpen}
      wrapperRef={wrapperRef}
      onClose={onClose}
      position={MENU_POSITIONS.BOTTOM}
      componentThemeName={SUPPORTED_COMPONENTS.ROUTE_TAB_SUB_MENU_CONTENT}
    />
  );
};

RouteTabsSubMenu.displayName = 'RouteTabsSubMenu';

export default RouteTabsSubMenu;
