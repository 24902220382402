import React from 'react';

import { Tag } from '@savgroup-front-common/core/src/atoms/Tag';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import {
  FileShortSummary,
  OrderDetailsProduct,
  PRODUCT_OUTCOME,
} from '@savgroup-front-common/types';

import {
  $ChooseProductTagContainer,
  $ChooseProductTagOpenFileContainer,
} from './ChooseProductTag.styles';
import messages from './messages';

interface ChooseProductTagProps {
  product: OrderDetailsProduct;
  openedFiles: FileShortSummary[];
  isInProgress?: boolean;
  notEligible?: boolean;
  isSmall?: boolean;
}

const ChooseProductTag: React.FC<ChooseProductTagProps> = ({
  product,
  openedFiles,
  isInProgress = false,
  notEligible = false,
  isSmall = false,
}) => {
  const isOutcomeHistorySwapped = product?.outcomeHistory?.some(
    (history) => history.outcome === PRODUCT_OUTCOME.SWAPPED,
  );
  const isOutcomeHistoryRefunded = product?.outcomeHistory?.some(
    (history) => history.outcome === PRODUCT_OUTCOME.REFUNDED,
  );
  const isOutcomeHistoryRepaired = product?.outcomeHistory?.some(
    (history) => history.outcome === PRODUCT_OUTCOME.REPAIRED,
  );

  const isOpenedFile = openedFiles.some((file) => {
    return file.fileProducts.some(
      ({ ownerProductId }) => ownerProductId === product.ownerProductId,
    );
  });

  const isNotEligible =
    notEligible &&
    !isOutcomeHistoryRefunded &&
    !isOutcomeHistorySwapped &&
    !isOutcomeHistoryRepaired &&
    !isOpenedFile &&
    !isInProgress;

  return (
    <$ChooseProductTagContainer>
      {isInProgress && (
        <$ChooseProductTagOpenFileContainer>
          <Tag alert isSmall={isSmall}>
            <SafeFormattedMessageWithoutSpread
              message={messages.isInProgress}
            />
          </Tag>
        </$ChooseProductTagOpenFileContainer>
      )}
      {isOutcomeHistoryRepaired && (
        <Tag neutral isSmall={isSmall}>
          <SafeFormattedMessageWithoutSpread message={messages.isRepaired} />
        </Tag>
      )}
      {isOutcomeHistoryRefunded && (
        <Tag neutral isSmall={isSmall}>
          <SafeFormattedMessageWithoutSpread message={messages.isRefunded} />
        </Tag>
      )}
      {isOutcomeHistorySwapped && (
        <Tag neutral isSmall={isSmall}>
          <SafeFormattedMessageWithoutSpread message={messages.isSwapped} />
        </Tag>
      )}
      {isOpenedFile && (
        <$ChooseProductTagOpenFileContainer>
          <Tag neutral isSmall={isSmall}>
            <SafeFormattedMessageWithoutSpread message={messages.fileOpened} />
          </Tag>
        </$ChooseProductTagOpenFileContainer>
      )}
      {isNotEligible && (
        <Tag neutral isSmall={isSmall}>
          <SafeFormattedMessageWithoutSpread message={messages.notEligible} />
        </Tag>
      )}
    </$ChooseProductTagContainer>
  );
};

ChooseProductTag.displayName = 'ChooseProductTag';

export default ChooseProductTag;
