import { add } from 'date-fns';
import moment from 'moment';
import React, { FC, useContext } from 'react';
import { FormattedDate } from 'react-intl';

import { NOTIFICATION_TYPES } from '@savgroup-front-common/constants';

import Banner from '../../../../../../../atoms/Banners/Banner';
import FormGroup from '../../../../../../../atoms/Form/FormGroup';
import { SafeFormattedMessageWithoutSpread } from '../../../../../../../formatters';
import { isEmpty } from '../../../../../../../helpers/isEmpty';
import BannersConsumer from '../../../../../../../molecules/NotificationsProvider/BannersProvider/BannersConsumer';
import BannersProviderContext from '../../../../../../../molecules/NotificationsProvider/BannersProvider/BannersProvider.context';
import { DetailedSchedule } from '../../../../../../AppointmentCalendar/types/DetailedSchedule';
import { useGetPageSize } from '../../../../../hooks/useGetPageSize';
import { PAGINATION_START_FROM_DAY } from '../../../TakeInterventionAppointmentLegacy.constants';
import { useTakeInterventionAppointmentLegacyContext } from '../../../TakeInterventionAppointmentLegacy.context';
import { byStartLocalTime } from '../AppointmentCalendarLegacy.helpers';
import {
  CalendarDayBlock,
  CalendarDayDate,
  CalendarDayName,
  TimeSlotSelectorContainer,
} from '../AppointmentCalendarLegacy.styles';
import { AppointmentCalendarLoader } from '../AppointmentCalendarLoader/AppointmentCalendarLoader';
import messages from '../messages';

import {
  $AppointmentCalendarScreen,
  $EmptyDay,
  $TimeSlotSelectorContainer,
  $UnderlinedRow,
} from './AppointmentCalendarScreenLegacy.styles';
import { EmptyWeekLegacy } from './EmptyWeekLegacy';
import TimeSlotSelectorLegacy from './TimeSlotSelectorLegacy';

interface AppointmentCalendarScreenLegacyProps {
  isActive: boolean;
  isGetTimeslotsLoading: boolean;
  detailedSchedules?: DetailedSchedule[];
}

const AppointmentCalendarScreenLegacy: FC<
  AppointmentCalendarScreenLegacyProps
> = ({ isActive, isGetTimeslotsLoading, detailedSchedules }) => {
  const pageSize = useGetPageSize();
  const { pageNumber, handleSelectTimeSlot } =
    useTakeInterventionAppointmentLegacyContext();
  const { notifications } = useContext(BannersProviderContext);

  const isAllDetailedScheduleEmpty = (detailedSchedules || []).every(
    ({ timeslots = [] }) =>
      timeslots.filter((timeslot) => !!timeslot).length === 0,
  );

  const detailedSchedulesToDisplay =
    detailedSchedules === undefined
      ? new Array(pageSize).fill(null).map((_, index) => {
          return {
            date: add(new Date(), {
              days:
                (pageNumber - 1) * pageSize + PAGINATION_START_FROM_DAY + index,
            }),
            timeslots: [],
          };
        })
      : detailedSchedules;

  const shouldDisplayAlert =
    (!detailedSchedules || detailedSchedules.length === 0) &&
    !isGetTimeslotsLoading &&
    notifications.length === 0;

  return (
    <$AppointmentCalendarScreen>
      <$UnderlinedRow $columnCount={detailedSchedulesToDisplay.length}>
        {detailedSchedulesToDisplay.map(({ date }) => (
          <div key={date.toISOString()}>
            <CalendarDayBlock>
              <CalendarDayName>
                <FormattedDate value={date} weekday="long" />
              </CalendarDayName>
              <CalendarDayDate>{moment(date).format('DD/MM')}</CalendarDayDate>
            </CalendarDayBlock>
          </div>
        ))}
      </$UnderlinedRow>

      <BannersConsumer />

      {shouldDisplayAlert && (
        <FormGroup>
          <Banner notificationType={NOTIFICATION_TYPES.ALERT} hollow>
            <SafeFormattedMessageWithoutSpread
              message={messages.noAvailableTechnicalInterventionSlots}
            />
          </Banner>
        </FormGroup>
      )}

      {isGetTimeslotsLoading && <AppointmentCalendarLoader />}

      {!isGetTimeslotsLoading &&
        isEmpty(detailedSchedules) &&
        !shouldDisplayAlert && <EmptyWeekLegacy />}

      {!isAllDetailedScheduleEmpty && notifications.length === 0 && (
        <$TimeSlotSelectorContainer
          $columnCount={detailedSchedulesToDisplay.length}
        >
          {detailedSchedulesToDisplay.map(({ date, timeslots = [] }) => {
            if (timeslots.length === 0) {
              return <$EmptyDay key={date.toISOString()}>-</$EmptyDay>;
            }

            const allDaySchedule = timeslots.find(
              (timeslot) => timeslot.allDaySchedule,
            );

            if (allDaySchedule) {
              return (
                <div key={date.toISOString()}>
                  <TimeSlotSelectorLegacy
                    isActive={isActive}
                    timeslot={allDaySchedule}
                    onTimeslotSelect={handleSelectTimeSlot}
                  />
                </div>
              );
            }

            return (
              <div key={date.toISOString()}>
                <TimeSlotSelectorContainer>
                  {timeslots.sort(byStartLocalTime).map((timeslot) => {
                    return (
                      <TimeSlotSelectorLegacy
                        key={timeslot.internalId}
                        isActive={isActive}
                        timeslot={timeslot}
                        onTimeslotSelect={handleSelectTimeSlot}
                      />
                    );
                  })}
                </TimeSlotSelectorContainer>
              </div>
            );
          })}
        </$TimeSlotSelectorContainer>
      )}
    </$AppointmentCalendarScreen>
  );
};

AppointmentCalendarScreenLegacy.displayName = 'AppointmentCalendarScreenLegacy';

export default AppointmentCalendarScreenLegacy;
