import { fromJS, Map } from 'immutable';

import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';

import {
  CLEAR_CLAIM_CONFIRMATION_ERRORS,
  CONFIRM_CLAIM_GROUP,
  CREATE_CLAIM_FOR_OWNER,
  CREATE_CLAIM_GROUP_WITH_ISSUE_FOR_OWNER,
  DELETE_CLAIM_BY_ID,
  DELETE_CLAIM_GROUP_BY_ID,
  DELETE_DOCUMENT_FOR_CLAIM_GROUP,
  DELETE_REASON_ADDITIONAL_INFORMATION_FILE,
  GET_ORDER_BY_CLAIM_GROUP,
  GET_REASON_COMMENT,
  GET_RMA_SUPPLIER_INFOS,
  GET_SELLER_INFO,
  LOAD_CARRIER_SUMMARY_BY_CLAIM_GROUP,
  LOAD_CARRIERS_OPTIONS_BY_CLAIM,
  LOAD_CARRIERS_OPTIONS_BY_CLAIM_GROUP,
  LOAD_CLAIM_GROUP_BY_ID,
  LOAD_CLAIM_PRODUCT,
  LOAD_CLAIMS_BY_GROUP_ID,
  LOAD_CONFIRMATION_INFO_BY_CLAIM,
  LOAD_CONFIRMATION_INFO_BY_CLAIM_GROUP,
  LOAD_FULL_CLAIMS_BY_GROUP_ID,
  LOAD_HANDLING_BY_CLAIM_GROUP,
  LOAD_ISSUES_OPTIONS_BY_CLAIM,
  LOAD_ISSUES_OPTIONS_BY_ORDER,
  LOAD_ISSUES_OPTIONS_FROM_ALL_ORDERS,
  LOAD_PICKUP_POINTS_OPTIONS_BY_CARRIER,
  LOAD_REASONS_OPTIONS_BY_CLAIM,
  LOAD_SOLUTIONS_OPTIONS_BY_CLAIM,
  LOAD_TRANSLATION,
  SELECT_HANDLING_FOR_CLAIM_GROUP,
  SELECT_ISSUE_FOR_CLAIM,
  SELECT_REASON_FOR_CLAIM,
  SELECT_SOLUTION_FOR_CLAIM,
  SET_DOCUMENT_FOR_CLAIM_GROUP,
  SET_REASON_ADDITIONAL_INFORMATION,
  SET_REASON_ADDITIONAL_INFORMATION_FILE,
} from '../actionTypes';
import { SET_DEAD_END_FOR_CLAIM_GROUP } from '../actionTypes/claimDeadEnd';
import { LOAD_STORE_ACTOR_BY_CLAIM_ID } from '../actionTypes/storeActor';
import { CLAIMS_DOMAIN } from '../constants';

import {
  onCarriersLoaded,
  onCarriersLoading,
  onCarriersLoadingErrored,
} from './carriers';
import {
  onAdditionalInfoFileSucceeded,
  onAdditionalInfoSucceeded,
  onClaimDeleted,
  onClaimGroupConfirmationErrored,
  onClaimGroupConfirmationPending,
  onClaimGroupConfirmed,
  onClaimLoaded,
  onClearClaimConfirmationErrors,
  onDeleteAdditionalInfoFileSucceeded,
} from './claim';
import {
  onClaimConfirmationLoaded,
  onClaimConfirmationLoading,
  onClaimConfirmationLoadingErrored,
} from './claimConfirmation';
import {
  onClaimCreationForOwnerLoaded,
  onClaimCreationForOwnerLoading,
  onClaimCreationForOwnerLoadingErrored,
} from './claimCreationForOwner';
import {
  onClaimGroupConfirmationLoaded,
  onClaimGroupConfirmationLoading,
  onClaimGroupConfirmationLoadingErrored,
} from './claimGroupConfirmation';
import {
  onClaimGroupCreationForOwnerLoaded,
  onClaimGroupCreationForOwnerLoading,
  onClaimGroupCreationForOwnerLoadingErrored,
} from './claimGroupCreationForOwner';
import {
  onClaimGroupDeleted,
  onClaimGroupLoaded,
  onClaimGroupLoading,
  onClaimGroupLoadingErrored,
} from './claimGroups';
import {
  onCarrierForClaimGroupLoaded,
  onCarrierForClaimGroupLoading,
  onCarrierForClaimGroupLoadingErrored,
} from './claimGroupsCarrier';
import {
  onCarriersForClaimGroupLoaded,
  onCarriersForClaimGroupLoading,
  onCarriersForClaimGroupLoadingErrored,
} from './claimGroupsCarriers';
import {
  onDeadEndForClaimGroupErrored,
  onDeadEndForClaimGroupStarted,
  onDeadEndForClaimGroupSucceeded,
  onOrdersLoaded,
} from './claimGroupsDeadEnd';
import {
  onHandlingForClaimGroupLoaded,
  onHandlingForClaimGroupLoading,
  onHandlingForClaimGroupLoadingErrored,
  onHandlingForClaimGroupSelected,
  onHandlingForClaimGroupSelectError,
} from './claimGroupsHandling';
import {
  onClaimGroupUploadDeletionErrored,
  onClaimGroupUploadDeletionStarted,
  onClaimGroupUploadDeletionSucceeded,
  onClaimGroupUploadErrored,
  onClaimGroupUploadPending,
  onClaimGroupUploadProgress,
  onClaimGroupUploadSucceeded,
} from './claimGroupUpload';
import { onFullClaimSuccess } from './fullClaim';
import {
  onIssueSelected,
  onIssuesLoaded,
  onIssuesLoading,
  onIssuesLoadingErrored,
} from './issue';
import {
  onIssuesByOrderErrored,
  onIssuesByOrderLoaded,
  onIssuesByOrderLoading,
  onIssuesFromAllOrdersFinish,
  onIssuesFromAllOrdersLoading,
} from './issuesForOrder';
import {
  onOrderIdByClaimGroupErrored,
  onOrderIdByClaimGroupLoaded,
  onOrderIdByClaimGroupLoading,
} from './orderIdByClaimGroup';
import {
  onPickupPointsLoaded,
  onPickupPointsLoading,
  onPickupPointsLoadingErrored,
} from './pickupPoints';
import {
  onProductErrored,
  onProductLoaded,
  onProductLoading,
} from './products';
import {
  onReasonSelect,
  onReasonSelected,
  onReasonsLoaded,
  onReasonsLoading,
  onReasonsLoadingErrored,
} from './reason';
import {
  onCommentErrored,
  onCommentLoaded,
  onCommentLoading,
} from './reasonComment';
import {
  onSellerInfoErrored,
  onSellerInfoLoaded,
  onSellerInfoLoading,
} from './sellerInfo';
import {
  onSolutionSelected,
  onSolutionsLoaded,
  onSolutionsLoading,
  onSolutionsLoadingErrored,
} from './solution';
import { onStoreActorLoaded } from './storeActor';
import {
  onRmaSupplierInfosErrored,
  onRmaSupplierInfosLoaded,
  onRmaSupplierInfosLoading,
} from './supplierInfos';
import onLoadTranslationSuccess from './translations';

const MYORDERS_LOADED = 'MYORDERS_LOADED';

const initialState = fromJS({
  isBypassingDateRules: fromJS({}),
  claims: fromJS({}),
  claimGroups: fromJS({}),
  claimGroupStates: fromJS({}),
  fullClaims: fromJS({}),
  issuesForOrder: fromJS({}),
  issues: fromJS({}),
  reasons: fromJS({}),
  solutions: fromJS({}),
  handling: fromJS({}),
  carrier: fromJS({}),
  carriers: fromJS({}),
  pickupPoints: fromJS({}),
  createdClaims: fromJS({}),
  createdClaimGroups: fromJS({}),
  createdClaimsForOwner: fromJS({}),
  createdClaimGroupsForOwner: fromJS({}),
  claimsConfirmation: fromJS({}),
  claimUploads: fromJS({}),
  documents: fromJS({}),
  products: fromJS({}),
  comments: fromJS({}),
  groupHandling: fromJS({}),
  groupCarrier: fromJS({}),
  groupCarriers: fromJS({}),
  claimGroupsConfirmation: fromJS({}),
  claimConfirmationStatus: fromJS({}),
  sellerInfo: fromJS({}),
  orderIssues: fromJS({ values: fromJS({}) }),
  claimInfoDebugger: fromJS({}),
  rmaSupplierInfos: fromJS({}),
  procedureMarkdown: fromJS({}),
  orderIdByClaimGroup: fromJS({}),
  claimGroupUploads: fromJS({}),
  [CLAIMS_DOMAIN.STORES_ACTOR]: {},
  translations: Map({ issues: {}, reasons: {}, solutions: {}, warranties: {} }),
});

export default function reducer(state = initialState, action) {
  try {
    switch (action.type) {
      case LOAD_CLAIMS_BY_GROUP_ID.SUCCEEDED:
        return onClaimLoaded(state, action);

      case LOAD_CLAIM_GROUP_BY_ID.STARTED:
        return onClaimGroupLoading(state, action);
      case LOAD_CLAIM_GROUP_BY_ID.ERRORED:
        return onClaimGroupLoadingErrored(state, action);
      case LOAD_CLAIM_GROUP_BY_ID.SUCCEEDED:
        return onClaimGroupLoaded(state, action);

      case LOAD_FULL_CLAIMS_BY_GROUP_ID.SUCCEEDED:
        return onFullClaimSuccess(state, action);

      case DELETE_CLAIM_BY_ID.SUCCEEDED:
        return onClaimDeleted(state, action);

      case DELETE_CLAIM_GROUP_BY_ID.SUCCEEDED:
        return onClaimGroupDeleted(state, action);

      case CONFIRM_CLAIM_GROUP.STARTED:
        return onClaimGroupConfirmationPending(state, action);
      case CONFIRM_CLAIM_GROUP.ERRORED:
        return onClaimGroupConfirmationErrored(state, action);
      case CONFIRM_CLAIM_GROUP.SUCCEEDED:
        return onClaimGroupConfirmed(state, action);

      case LOAD_ISSUES_OPTIONS_BY_CLAIM.STARTED:
        return onIssuesLoading(state, action);
      case LOAD_ISSUES_OPTIONS_BY_CLAIM.ERRORED:
        return onIssuesLoadingErrored(state, action);
      case LOAD_ISSUES_OPTIONS_BY_CLAIM.SUCCEEDED:
        return onIssuesLoaded(state, action);
      case SELECT_ISSUE_FOR_CLAIM.SUCCEEDED:
        return onIssueSelected(state, action);
      case LOAD_ISSUES_OPTIONS_FROM_ALL_ORDERS.STARTED:
        return onIssuesFromAllOrdersLoading(state, action);
      case LOAD_ISSUES_OPTIONS_FROM_ALL_ORDERS.SUCCEEDED:
        return onIssuesFromAllOrdersFinish(state, action);

      case LOAD_REASONS_OPTIONS_BY_CLAIM.STARTED:
        return onReasonsLoading(state, action);
      case LOAD_REASONS_OPTIONS_BY_CLAIM.ERRORED:
        return onReasonsLoadingErrored(state, action);
      case LOAD_REASONS_OPTIONS_BY_CLAIM.SUCCEEDED:
        return onReasonsLoaded(state, action);
      case SELECT_REASON_FOR_CLAIM.STARTED:
        return onReasonSelect(state, action);
      case SELECT_REASON_FOR_CLAIM.SUCCEEDED:
        return onReasonSelected(state, action);

      case LOAD_SOLUTIONS_OPTIONS_BY_CLAIM.STARTED:
        return onSolutionsLoading(state, action);
      case LOAD_SOLUTIONS_OPTIONS_BY_CLAIM.ERRORED:
        return onSolutionsLoadingErrored(state, action);
      case LOAD_SOLUTIONS_OPTIONS_BY_CLAIM.SUCCEEDED:
        return onSolutionsLoaded(state, action);
      case SELECT_SOLUTION_FOR_CLAIM.SUCCEEDED:
        return onSolutionSelected(state, action);

      case LOAD_HANDLING_BY_CLAIM_GROUP.STARTED:
        return onHandlingForClaimGroupLoading(state, action);
      case LOAD_HANDLING_BY_CLAIM_GROUP.ERRORED:
        return onHandlingForClaimGroupLoadingErrored(state, action);
      case LOAD_HANDLING_BY_CLAIM_GROUP.SUCCEEDED:
        return onHandlingForClaimGroupLoaded(state, action);
      case SELECT_HANDLING_FOR_CLAIM_GROUP.SUCCEEDED:
        return onHandlingForClaimGroupSelected(state, action);
      case SELECT_HANDLING_FOR_CLAIM_GROUP.ERRORED:
        return onHandlingForClaimGroupSelectError(state, action);

      case SET_DEAD_END_FOR_CLAIM_GROUP.STARTED:
        return onDeadEndForClaimGroupStarted(state, action);
      case SET_DEAD_END_FOR_CLAIM_GROUP.ERRORED:
        return onDeadEndForClaimGroupErrored(state, action);
      case SET_DEAD_END_FOR_CLAIM_GROUP.SUCCEEDED:
        return onDeadEndForClaimGroupSucceeded(state, action);

      case MYORDERS_LOADED:
        return onOrdersLoaded(state, action.payload);

      case LOAD_CARRIER_SUMMARY_BY_CLAIM_GROUP.STARTED:
        return onCarrierForClaimGroupLoading(state, action);
      case LOAD_CARRIER_SUMMARY_BY_CLAIM_GROUP.ERRORED:
        return onCarrierForClaimGroupLoadingErrored(state, action);
      case LOAD_CARRIER_SUMMARY_BY_CLAIM_GROUP.SUCCEEDED:
        return onCarrierForClaimGroupLoaded(state, action);

      case LOAD_CARRIERS_OPTIONS_BY_CLAIM.STARTED:
        return onCarriersLoading(state, action);
      case LOAD_CARRIERS_OPTIONS_BY_CLAIM.ERRORED:
        return onCarriersLoadingErrored(state, action);
      case LOAD_CARRIERS_OPTIONS_BY_CLAIM.SUCCEEDED:
        return onCarriersLoaded(state, action);

      case LOAD_CARRIERS_OPTIONS_BY_CLAIM_GROUP.STARTED:
        return onCarriersForClaimGroupLoading(state, action);
      case LOAD_CARRIERS_OPTIONS_BY_CLAIM_GROUP.ERRORED:
        return onCarriersForClaimGroupLoadingErrored(state, action);
      case LOAD_CARRIERS_OPTIONS_BY_CLAIM_GROUP.SUCCEEDED:
        return onCarriersForClaimGroupLoaded(state, action);

      case LOAD_PICKUP_POINTS_OPTIONS_BY_CARRIER.STARTED:
        return onPickupPointsLoading(state, action);
      case LOAD_PICKUP_POINTS_OPTIONS_BY_CARRIER.ERRORED:
        return onPickupPointsLoadingErrored(state, action);
      case LOAD_PICKUP_POINTS_OPTIONS_BY_CARRIER.SUCCEEDED:
        return onPickupPointsLoaded(state, action);

      case CREATE_CLAIM_FOR_OWNER.STARTED:
        return onClaimCreationForOwnerLoading(state, action);
      case CREATE_CLAIM_FOR_OWNER.ERRORED:
        return onClaimCreationForOwnerLoadingErrored(state, action);
      case CREATE_CLAIM_FOR_OWNER.SUCCEEDED:
        return onClaimCreationForOwnerLoaded(state, action);

      case CREATE_CLAIM_GROUP_WITH_ISSUE_FOR_OWNER.STARTED:
        return onClaimGroupCreationForOwnerLoading(state, action);
      case CREATE_CLAIM_GROUP_WITH_ISSUE_FOR_OWNER.ERRORED:
        return onClaimGroupCreationForOwnerLoadingErrored(state, action);
      case CREATE_CLAIM_GROUP_WITH_ISSUE_FOR_OWNER.SUCCEEDED:
        return onClaimGroupCreationForOwnerLoaded(state, action);

      case LOAD_CONFIRMATION_INFO_BY_CLAIM.STARTED:
        return onClaimConfirmationLoading(state, action);
      case LOAD_CONFIRMATION_INFO_BY_CLAIM.ERRORED:
        return onClaimConfirmationLoadingErrored(state, action);
      case LOAD_CONFIRMATION_INFO_BY_CLAIM.SUCCEEDED:
        return onClaimConfirmationLoaded(state, action);

      case LOAD_CONFIRMATION_INFO_BY_CLAIM_GROUP.STARTED:
        return onClaimGroupConfirmationLoading(state, action);
      case LOAD_CONFIRMATION_INFO_BY_CLAIM_GROUP.ERRORED:
        return onClaimGroupConfirmationLoadingErrored(state, action);
      case LOAD_CONFIRMATION_INFO_BY_CLAIM_GROUP.SUCCEEDED:
        return onClaimGroupConfirmationLoaded(state, action);

      case SET_DOCUMENT_FOR_CLAIM_GROUP.STARTED:
        return onClaimGroupUploadPending(state, action);
      case SET_DOCUMENT_FOR_CLAIM_GROUP.ERRORED:
        return onClaimGroupUploadErrored(state, action);
      case SET_DOCUMENT_FOR_CLAIM_GROUP.SUCCEEDED:
        return onClaimGroupUploadSucceeded(state, action);
      case SET_DOCUMENT_FOR_CLAIM_GROUP.PROGRESS:
        return onClaimGroupUploadProgress(state, action);
      case SET_DOCUMENT_FOR_CLAIM_GROUP.CANCELLED:
        return onClaimGroupUploadDeletionSucceeded(state, action);

      case DELETE_DOCUMENT_FOR_CLAIM_GROUP.STARTED:
        return onClaimGroupUploadDeletionStarted(state, action);
      case DELETE_DOCUMENT_FOR_CLAIM_GROUP.ERRORED:
        return onClaimGroupUploadDeletionErrored(state, action);
      case DELETE_DOCUMENT_FOR_CLAIM_GROUP.SUCCEEDED:
        return onClaimGroupUploadDeletionSucceeded(state, action);

      case LOAD_CLAIM_PRODUCT.STARTED:
        return onProductLoading(state, action);
      case LOAD_CLAIM_PRODUCT.ERRORED:
        return onProductErrored(state, action);
      case LOAD_CLAIM_PRODUCT.SUCCEEDED:
        return onProductLoaded(state, action);

      case GET_REASON_COMMENT.STARTED:
        return onCommentLoading(state, action);
      case GET_REASON_COMMENT.ERRORED:
        return onCommentErrored(state, action);
      case GET_REASON_COMMENT.SUCCEEDED:
        return onCommentLoaded(state, action);

      case GET_SELLER_INFO.STARTED:
        return onSellerInfoLoading(state, action);
      case GET_SELLER_INFO.ERRORED:
        return onSellerInfoErrored(state, action);
      case GET_SELLER_INFO.SUCCEEDED:
        return onSellerInfoLoaded(state, action);

      case CLEAR_CLAIM_CONFIRMATION_ERRORS:
        return onClearClaimConfirmationErrors(state, action);

      case LOAD_ISSUES_OPTIONS_BY_ORDER.STARTED:
        return onIssuesByOrderLoading(state, action);
      case LOAD_ISSUES_OPTIONS_BY_ORDER.ERRORED:
        return onIssuesByOrderErrored(state, action);
      case LOAD_ISSUES_OPTIONS_BY_ORDER.SUCCEEDED:
        return onIssuesByOrderLoaded(state, action);
      case GET_RMA_SUPPLIER_INFOS.STARTED:
        return onRmaSupplierInfosLoading(state, action);
      case GET_RMA_SUPPLIER_INFOS.ERRORED:
        return onRmaSupplierInfosErrored(state, action);
      case GET_RMA_SUPPLIER_INFOS.SUCCEEDED:
        return onRmaSupplierInfosLoaded(state, action);

      case GET_ORDER_BY_CLAIM_GROUP.STARTED:
        return onOrderIdByClaimGroupLoading(state, action);
      case GET_ORDER_BY_CLAIM_GROUP.ERRORED:
        return onOrderIdByClaimGroupErrored(state, action);
      case GET_ORDER_BY_CLAIM_GROUP.SUCCEEDED:
        return onOrderIdByClaimGroupLoaded(state, action);
      case SET_REASON_ADDITIONAL_INFORMATION.END:
        return onAdditionalInfoSucceeded(state, action);
      case SET_REASON_ADDITIONAL_INFORMATION_FILE.SUCCEEDED:
        return onAdditionalInfoFileSucceeded(state, action);
      case DELETE_REASON_ADDITIONAL_INFORMATION_FILE.SUCCEEDED:
        return onDeleteAdditionalInfoFileSucceeded(state, action);
      case LOAD_TRANSLATION.SUCCEEDED:
        return onLoadTranslationSuccess(state, action);

      case LOAD_STORE_ACTOR_BY_CLAIM_ID.SUCCEEDED: {
        return onStoreActorLoaded(state, action);
      }

      default:
        return state;
    }
  } catch (e) {
    logCritical(e);

    return state;
  }
}
