import React from 'react';

import { Modal, MODAL_SIZES } from '../../../../atoms/modal';
import { AdaptedQuotationNewModel } from '../../types/AdaptedQuotationNewModel';

import ConfirmQuotationRejectModalContent from './ConfirmQuotationRejectModalContent';

interface ConfirmQuotationRejectModalProps {
  isConfirmRejectModalOpen: boolean;
  handleCancelConfirmReject: () => void;
  handleRejectWithDestruction: ({
    rejectReason,
  }: {
    rejectReason: string;
  }) => void;
  handleRejectWithReturn: ({ rejectReason }: { rejectReason: string }) => void;
  quotation: AdaptedQuotationNewModel;
}

const ConfirmQuotationRejectModal: React.FC<
  ConfirmQuotationRejectModalProps
> = ({
  isConfirmRejectModalOpen,
  handleCancelConfirmReject,
  handleRejectWithDestruction,
  handleRejectWithReturn,
  quotation,
}) => {
  return (
    <Modal
      size={MODAL_SIZES.MEDIUM}
      onClose={handleCancelConfirmReject}
      isOpen={isConfirmRejectModalOpen}
    >
      <ConfirmQuotationRejectModalContent
        onClose={handleCancelConfirmReject}
        onRejectWithDestruction={handleRejectWithDestruction}
        onRejectWithReturn={handleRejectWithReturn}
        quotation={quotation}
      />
    </Modal>
  );
};

ConfirmQuotationRejectModal.displayName = 'ConfirmQuotationRejectModal';

export default ConfirmQuotationRejectModal;
