import React from 'react';
import { ClaimGroupDiagnosticTreePageReducerState } from '../../ClaimGroupDiagnosticTreePage.reducer';
import {
  $RowContainer,
  $RowBannerContainer,
  $RowBannerContainerText,
  $RowBannerContainerIcon,
  $RowBannerContainerButton,
  $RowBannerContent,
  $TextContainer,
  $ButtonOtherDemand,
} from './StepFinal.styles';
import { useTheme } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { FLEX_ALIGN } from '@savgroup-front-common/types';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { Heading } from '@savgroup-front-common/core/src/atoms/Heading';

import {
  IconDiagnosticTreeFinalStepNotRepared,
  IconDiagnosticTreeFinalStepRepared,
  IconDiagnosticTreeTools,
} from 'myaccount/view/components/IconDiagnosticTreeFinalStep';
import { DIAGNOSTIC_STATUS } from '../../ClaimGroupDiagnosticTreePage.types';
import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import {
  buildClaimReasonUrl,
  buildClaimIssuesUrl,
} from 'myaccount/view/app/NewLayout/ClassiqueLayout.helpers';
import messages from '../../messages';
import { ValuesState } from 'myaccount/view/app/NewLayout/StepsProvider/StepsProvider.types';

interface StepFinalProps {
  currentDiagnosticTreeStep: ClaimGroupDiagnosticTreePageReducerState;
  claimGroupId: string;
  handleUpdateValue: ({ value }: { value: Partial<ValuesState> }) => void;
}

const StepFinal: React.FC<StepFinalProps> = ({
  currentDiagnosticTreeStep,
  claimGroupId,
  handleUpdateValue,
}) => {
  const history = useHistory();
  const theme = useTheme();

  return (
    <>
      <$RowContainer center="xs">
        {currentDiagnosticTreeStep?.currentStep?.status ===
          DIAGNOSTIC_STATUS.REPAIRED && (
          <>
            <IconDiagnosticTreeFinalStepRepared />
            <Heading
              level={3}
              align={FLEX_ALIGN.CENTER}
              color={theme.colors.black}
            >
              {currentDiagnosticTreeStep?.currentStep.title && (
                <SafeFormattedMessageWithoutSpread
                  message={currentDiagnosticTreeStep?.currentStep.title}
                />
              )}
            </Heading>
            {currentDiagnosticTreeStep?.currentStep.instructions && (
              <SafeFormattedMessageWithoutSpread
                message={currentDiagnosticTreeStep?.currentStep.instructions}
              />
            )}
            <$ButtonOtherDemand>
              <Button
                type={BUTTON_TYPES.BUTTON}
                primary
                onClick={() => {
                  handleUpdateValue({
                    value: {
                      issue: undefined,
                      reason: undefined,
                      solution: undefined,
                      chosenProduct: undefined,
                      handlingDeposit: undefined,
                      handlingDelivery: undefined,
                      claimGroup: {
                        claimGroupId: undefined,
                        claims: [],
                      },
                      diagnosticIsAlreadyMade: false,
                    },
                  });

                  return history.push(buildClaimIssuesUrl());
                }}
              >
                <SafeFormattedMessageWithoutSpread
                  message={messages.stepFinalRepairedAnotherDemand}
                />
              </Button>
            </$ButtonOtherDemand>
          </>
        )}
        {currentDiagnosticTreeStep?.currentStep?.status ===
          DIAGNOSTIC_STATUS.NOT_REPAIRED && (
          <>
            <IconDiagnosticTreeFinalStepNotRepared />
            <$TextContainer>
              <Heading
                level={3}
                align={FLEX_ALIGN.CENTER}
                color={theme.colors.black}
              >
                {currentDiagnosticTreeStep?.currentStep.title && (
                  <SafeFormattedMessageWithoutSpread
                    message={currentDiagnosticTreeStep?.currentStep.title}
                  />
                )}
              </Heading>
              {currentDiagnosticTreeStep?.currentStep.instructions && (
                <SafeFormattedMessageWithoutSpread
                  message={currentDiagnosticTreeStep?.currentStep.instructions}
                />
              )}
            </$TextContainer>
            <$RowBannerContainer>
              <$RowBannerContent>
                <$RowBannerContainerIcon>
                  <IconDiagnosticTreeTools />
                </$RowBannerContainerIcon>
                <$RowBannerContainerText>
                  <SafeFormattedMessageWithoutSpread
                    message={messages.stepFinalRepairedBannerTitle}
                  />
                  <SafeFormattedMessageWithoutSpread
                    message={messages.stepFinalRepairedBannerSubtitle}
                  />
                </$RowBannerContainerText>
              </$RowBannerContent>
              <$RowBannerContainerButton>
                <Button
                  type={BUTTON_TYPES.BUTTON}
                  primary
                  onClick={() => {
                    handleUpdateValue({
                      value: {
                        diagnosticIsAlreadyMade: true,
                      },
                    });

                    return history.push(buildClaimReasonUrl({ claimGroupId }));
                  }}
                >
                  <SafeFormattedMessageWithoutSpread
                    message={messages.stepFinalRepairedBannerButton}
                  />
                </Button>
              </$RowBannerContainerButton>
            </$RowBannerContainer>
          </>
        )}
      </$RowContainer>
    </>
  );
};

StepFinal.displayName = 'StepFinal';

export default StepFinal;
