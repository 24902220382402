import { useQuery } from 'react-query';

import { CommonWorkflowService } from '../api';
import { GetFileHandlingPayload } from '../api/Workflow/getFileHandling';
import { useToasts } from '../molecules/NotificationsProvider';

interface UseGetFileHandlingArgs {
  fileId?: string;
}

interface UseGetFileHandlingReturnValue {
  fileHandling?: GetFileHandlingPayload;
}

const GetFileHandlingQuery = 'GetFileHandlingQuery';

const useGetFileHandling = ({
  fileId,
}: UseGetFileHandlingArgs): UseGetFileHandlingReturnValue => {
  const { pushErrors, removeAllNotifications } = useToasts();

  const { data: fileHandling } = useQuery(
    [GetFileHandlingQuery, { fileId }],
    async () => {
      if (!fileId) {
        return undefined;
      }
      removeAllNotifications();
      const response = await CommonWorkflowService.getFileHandling({ fileId });

      if (response.failure) {
        pushErrors(response.errors);

        return undefined;
      }

      return response.value;
    },
    { staleTime: Infinity, suspense: true },
  );

  return { fileHandling };
};

export default useGetFileHandling;
