import { call, put, takeEvery } from 'redux-saga/effects';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { callAndGetResponse } from '../../../services';

import {
  LOAD_STORE_ACTOR_BY_CLAIM_ID,
  SET_STORE_ACTOR,
} from '../actionTypes/storeActor';

function* loadStoreActorOptionsWorker({ payload: { claimId } }) {
  const meta = {
    claimId,
    indexer: claimId,
  };

  yield call(
    callAndGetResponse,
    LOAD_STORE_ACTOR_BY_CLAIM_ID,
    `${APIConfiguration.claim}claims/${claimId}/storeActor`,
    { method: SUPPORTED_METHODS.GET },
    meta,
  );
  yield put(LOAD_STORE_ACTOR_BY_CLAIM_ID.end(meta));
}
export function* loadStoreActorOptionsWatcher() {
  yield takeEvery(
    LOAD_STORE_ACTOR_BY_CLAIM_ID.BASE,
    loadStoreActorOptionsWorker,
  );
}

function* setStoreActorWorker({ payload: { claimId, storeActorId } }) {
  const meta = {
    claimId,
    storeActorId,
    indexer: claimId,
  };

  yield call(
    callAndGetResponse,
    SET_STORE_ACTOR,
    `${APIConfiguration.claim}claims/${claimId}/storeActor`,
    { method: SUPPORTED_METHODS.PUT, json: { storeActorId } },
    meta,
  );
  yield put(SET_STORE_ACTOR.end(meta));
}
export function* setStoreActorWatcher() {
  yield takeEvery(SET_STORE_ACTOR.BASE, setStoreActorWorker);
}
