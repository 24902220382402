import { useContext, useEffect, useReducer, Reducer } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';
import { REVALIDATE_MODES } from '@savgroup-front-common/constants';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useMutation } from 'react-query';
import { ClaimService } from 'myaccount/api';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import {
  StepContext,
  StepContextValues,
} from 'myaccount/view/app/NewLayout/StepsProvider/StepsProvider.context';
import claimGroupDiagnosticTreePageReducer, {
  ClaimGroupDiagnosticTreePageReducerState,
  ClaimGroupDiagnosticTreePageReducerAction,
  initDiagnosticTreeState,
} from './ClaimGroupDiagnosticTreePage.reducer';
import {
  DIAGNOSTIC_ACTION_TYPES,
  ClaimGroupDiagnosticTreeValue,
} from './ClaimGroupDiagnosticTreePage.types';
import { ClaimGroupDiagnosticTreeSchema } from './ClaimGroupDiagnosticTreePage.schema';
import { ValuesState } from 'myaccount/view/app/NewLayout/StepsProvider/StepsProvider.types';
import {
  buildClaimReasonUrl,
  buildClaimDiagnosticTreeUrl,
} from 'myaccount/view/app/NewLayout/ClassiqueLayout.helpers';

const GET_START_OR_CONTINUE_DIAGNOSTIC_TREE =
  'getStartOrContinueDiagnosticTree';

const HANDLE_DIAGNOSTIC_RESPONSE_COMMAND_QUERY =
  'handleDiagnosticResponseCommandQuery';

interface UseClaimGroupDiagnosticTreePageArgs {
  diagnosticTreeUniqueName: string;
}

interface UseClaimGroupDiagnosticTreePageReturnValue {
  diagnosticTreeState?: ClaimGroupDiagnosticTreePageReducerState;
  isLoading: boolean;
  formContext: UseFormReturn<ClaimGroupDiagnosticTreeValue>;
  claimGroupId: string;
  handleSubmitResponse: () => void;
  handlePreviousStep: () => void;
  handleUpdateValue: ({ value }: { value: Partial<ValuesState> }) => void;
  enableServicePortal: boolean;
}

const useClaimGroupDiagnosticTreePage = ({
  diagnosticTreeUniqueName,
}: UseClaimGroupDiagnosticTreePageArgs): UseClaimGroupDiagnosticTreePageReturnValue => {
  const { values, handleUpdateValue, enableServicePortal } =
    useContext<StepContextValues>(StepContext);
  const [diagnosticTreeState, diagnosticTreeDispatch] = useReducer<
    Reducer<
      ClaimGroupDiagnosticTreePageReducerState,
      ClaimGroupDiagnosticTreePageReducerAction
    >
  >(claimGroupDiagnosticTreePageReducer, initDiagnosticTreeState());
  const { removeAllNotifications, pushErrors } = useToasts();
  const history = useHistory();
  const claims = values.claimGroup?.claims;
  const claimGroupId = values.claimGroup?.claimGroupId || '';
  const claimId = claims?.at(0)?.claimId || '';

  const formContext = useForm<ClaimGroupDiagnosticTreeValue>({
    resolver: yupResolver(ClaimGroupDiagnosticTreeSchema),
    mode: REVALIDATE_MODES.ON_CHANGE,
  });

  const { handleSubmit, reset } = formContext;

  const {
    mutateAsync: handleStartOrContinueDiagnosticTree,
    isLoading: isStartOrContinueDiagnosticTreeLoading,
  } = useMutation(
    [GET_START_OR_CONTINUE_DIAGNOSTIC_TREE],
    async ({
      claimId,
      treeUniqueName,
    }: {
      claimId: string;
      treeUniqueName: string;
    }) => {
      removeAllNotifications();

      const response = await ClaimService.startOrContinueDiagnosticTreeQuery({
        claimId,
        treeUniqueName,
      });

      if (response.failure) {
        pushErrors(response.errors);

        return undefined;
      }

      diagnosticTreeDispatch({
        type: DIAGNOSTIC_ACTION_TYPES.START,
        payload: response.value,
      });

      return response.value;
    },
  );

  const {
    mutateAsync: handleUpdateDiagnosticTree,
    isLoading: ishandleDiagnosticResponseCommandLoading,
  } = useMutation(
    [HANDLE_DIAGNOSTIC_RESPONSE_COMMAND_QUERY],
    async ({
      claimId,
      treeUniqueName,
      nodeUniqueName,
      responseUniqueName,
    }: {
      claimId: string;
      treeUniqueName: string;
      nodeUniqueName: string;
      responseUniqueName: string;
    }) => {
      if (!responseUniqueName) {
        return undefined;
      }
      removeAllNotifications();

      const response = await ClaimService.handleDiagnosticResponseCommandQuery({
        claimId,
        treeUniqueName,
        nodeUniqueName,
        responseUniqueName,
        force: diagnosticTreeState.isPrevious,
      });

      if (response.failure) {
        pushErrors(response.errors);

        return undefined;
      }

      diagnosticTreeDispatch({
        type: DIAGNOSTIC_ACTION_TYPES.NEXT_STEP,
        payload: response.value,
      });

      return response.value;
    },
  );

  const handlePreviousStep = () => {
    const isFirstStep = !diagnosticTreeState.latestStep.length;

    if (isFirstStep) {
      handleUpdateValue({
        value: {
          reason: undefined,
          diagnosticIsAlreadyMade: false,
        },
      });

      if (claimGroupId) {
        history.push(buildClaimReasonUrl({ claimGroupId }));
      }

      return undefined;
    }

    reset();

    diagnosticTreeDispatch({
      type: DIAGNOSTIC_ACTION_TYPES.PREVIOUS_STEP,
    });

    return history.push(
      buildClaimDiagnosticTreeUrl({
        claimGroupId,
        diagnosticTreeUniqueName,
        stepName: `step${diagnosticTreeState.currentStepNumber - 1}`,
      }),
    );
  };

  const handleSubmitResponse = handleSubmit(({ response }) => {
    if (response?.uniqueName) {
      handleUpdateDiagnosticTree({
        claimId,
        treeUniqueName: diagnosticTreeUniqueName,
        nodeUniqueName: diagnosticTreeState?.currentStep?.uniqueName || '',
        responseUniqueName: response?.uniqueName,
      });

      reset();

      return history.push(
        buildClaimDiagnosticTreeUrl({
          claimGroupId,
          diagnosticTreeUniqueName,
          stepName: `step${diagnosticTreeState.currentStepNumber + 1}`,
        }),
      );
    }

    return undefined;
  });

  useEffect(() => {
    handleStartOrContinueDiagnosticTree({
      claimId,
      treeUniqueName: diagnosticTreeUniqueName,
    });
  }, [handleStartOrContinueDiagnosticTree, claimId, diagnosticTreeUniqueName]);

  return {
    diagnosticTreeState,
    isLoading:
      isStartOrContinueDiagnosticTreeLoading ||
      ishandleDiagnosticResponseCommandLoading,
    formContext,
    claimGroupId,
    handleSubmitResponse,
    handlePreviousStep,
    handleUpdateValue,
    enableServicePortal,
  };
};

export default useClaimGroupDiagnosticTreePage;
