import useGetSellerConfiguration from '../../../../hooks/useGetSellerConfiguration';
import { ROUTES } from '../../../../routes/Routes';
import { useIsBTobEnabled } from '../../../../hooks/useIsBTobEnabled';
import { buildProfessionalRoute } from '../../../ProfessionalRoutes.helpers';
import { PROFESSIONAL_HOME_CONFIG } from '../../../ProfessionalRoutes.config';

interface UseGetSellerUrlArgs {
  sellerId: string;
}

export const useGetSellerUrl = ({ sellerId }: UseGetSellerUrlArgs) => {
  const { sellerConfiguration } = useGetSellerConfiguration({ sellerId });

  const isBTobEnabled = useIsBTobEnabled();

  const defaultRoute = isBTobEnabled
    ? buildProfessionalRoute({
        route: PROFESSIONAL_HOME_CONFIG.PROFESSIONAL_HOME_PAGE,
      })
    : ROUTES.HOME;

  const sellerUrl = sellerConfiguration?.defaultOwnerOrderUrl
    ? { href: sellerConfiguration?.defaultOwnerOrderUrl, isExternal: true }
    : { href: defaultRoute, isExternal: false };

  return {
    sellerUrl,
  };
};
