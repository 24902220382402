import { SparePartSearchSummary } from '@savgroup-front-common/types';

import { QuotationPendingCustomerValidationNewModel } from './QuotationPendingCustomerValidation.types';

export const adaptAlternativeSpareParts = ({
  quotation,
}: {
  quotation: QuotationPendingCustomerValidationNewModel;
}) => {
  const alternativeSpareParts = (quotation.sparePartLines || []).reduce<
    SparePartSearchSummary[]
  >((acc, line) => {
    return [...acc, ...(line.alternativeSpareParts || [])];
  }, []);

  return alternativeSpareParts.reduce<
    Record<string, Record<string, SparePartSearchSummary>>
  >((acc, alternativeSparePart) => {
    return {
      ...acc,
      [alternativeSparePart.sparePartId]: {
        ...(acc[alternativeSparePart.sparePartId] || {}),
        [alternativeSparePart.sparePartSupplierAssociationId]:
          alternativeSparePart,
      },
    };
  }, {});
};
