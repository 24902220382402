import React, { FunctionComponent } from 'react';

import { FileId, Timeslot } from '@savgroup-front-common/types';

import {
  $AppointmentCalendarContainer,
  $CalendarCard,
  $Title,
} from './AppointmentCalendarContainerLegacy.styles';
import { InterventionAppointmentHeaderLegacy } from './InterventionAppointmentHeaderLegacy/InterventionAppointmentHeaderLegacy';
import messages from './messages';
import { format } from 'date-fns';
import AppointmentCalendarLegacy from './AppointmentCalendarLegacy/AppointmentCalendarLegacy';
import {
  safeFormattedIntlString,
  SafeFormattedMessageWithoutSpread,
} from '../../../../../formatters';
import Card from '../../../../../atoms/Cards/Card';

interface AppointmentCalendarContainerProps {
  fileId: FileId;
  onSubmit: ({ toCustomerComment }: { toCustomerComment: string }) => void;
  isLoading?: boolean;
  isNextPageDisabled?: boolean;
  isActive?: boolean;
}

const AppointmentCalendarContainerLegacy: FunctionComponent<
  React.PropsWithChildren<AppointmentCalendarContainerProps>
> = ({
  children,
  fileId,
  onSubmit,
  isLoading = false,
  isNextPageDisabled = false,
  isActive = true,
}) => {
  return (
    <Card colored>
      <$Title>
        <SafeFormattedMessageWithoutSpread message={messages.whatINeedToDo} />
      </$Title>

      <InterventionAppointmentHeaderLegacy />

      <$Title>
        <SafeFormattedMessageWithoutSpread message={messages.title} />
      </$Title>

      <$CalendarCard>
        <$AppointmentCalendarContainer>
          <AppointmentCalendarLegacy
            fileId={fileId}
            isLoading={isLoading}
            isNextPageDisabled={isNextPageDisabled}
            isActive={isActive}
            onValidateSchedule={({
              selectedTimeslot,
            }: {
              selectedTimeslot: Timeslot;
            }) => {
              const startDate = format(selectedTimeslot.start, 'dd/MM/yyyy');
              const startTime = format(selectedTimeslot.start, 'HH:mm');
              const endTime = format(selectedTimeslot.end, 'HH:mm');

              onSubmit({
                toCustomerComment: safeFormattedIntlString(
                  messages.selectedAppointment,
                  { startDate, startTime, endTime },
                ),
              });
            }}
          >
            {children}
          </AppointmentCalendarLegacy>
        </$AppointmentCalendarContainer>
      </$CalendarCard>
    </Card>
  );
};

AppointmentCalendarContainerLegacy.displayName =
  'AppointmentCalendarContainerLegacy';

export default AppointmentCalendarContainerLegacy;
