import { Col, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { H2 } from '@savgroup-front-common/core/src/atoms/headings';
import { rem } from '@savgroup-front-common/core/src/helpers';

export const ButtonWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;

  & > * {
    margin-left: 0.5rem;
  }
`;

export const StyledRow = styled(Row)`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 24px;
  padding-bottom: 30px;
  border-radius: 4px;
  margin-top: ${rem('20px')};
`;

export const StyledHeader = styled(H2)`
  padding-top: 0;
  padding-bottom: 15px;
  font-weight: bold;
`;

export const StyledButton = styled(Button)`
  width: 183px;
`;

export const $Col = styled(Col)`
  margin-bottom: 1rem;
`;
