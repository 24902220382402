/* eslint-disable no-console */
import { fromJS } from 'immutable';
import get from 'lodash/get';

import { SET_REASON_COMMENT_FOR_CLAIM_GROUP } from '../actionTypes';

export function onCommentLoading(state, { meta }) {
  const claimId = get(meta, ['claimId']);

  if (!claimId) {
    console.warn('[onClaimLoading] ClaimId is undefined');

    return state;
  }

  return state.setIn(
    ['comments', claimId],
    fromJS({ isLoaded: false, isDirty: false, value: undefined }),
  );
}

export function onCommentErrored(state, { meta }) {
  const claimId = get(meta, ['claimId']);

  if (!claimId) {
    console.warn('[onClaimLoadingErrored] ClaimId is undefined');

    return state;
  }

  // TODO errors
  return state.updateIn(['comments', claimId], (oldValue) =>
    oldValue.setIn(['isDirty'], true).setIn(['hasErrors'], true),
  );
}

export function onCommentLoaded(state, { type, payload, meta }) {
  const claimId = get(meta, ['claimId']);

  let comment;

  if (type === SET_REASON_COMMENT_FOR_CLAIM_GROUP.SUCCEEDED) {
    comment = get(meta, ['reasonComment']);
  } else {
    comment = get(payload, ['value']);
  }
  if (!claimId) {
    console.warn('[onClaimLoaded] ClaimId is undefined.');

    return state;
  }

  return state.setIn(
    ['comments', claimId],
    fromJS({ isLoaded: true, isDirty: false }).setIn(['value'], comment),
  );
}
