import React, { FunctionComponent } from 'react';

import { ModuleSummary, TransitionSummary } from '@savgroup-front-common/types';

import { ReturnProductForm } from '../../../ReturnProductForm';

import { $ModalContent } from './RejectQuotationModalContent.styles';

interface RejectQuotationModalContentProps {
  onClose: VoidFunction;
  onSubmit: ({ selectedTransition }: { selectedTransition: any }) => void;
  transitions: TransitionSummary[];
  module: ModuleSummary;
}

const RejectQuotationModalContent: FunctionComponent<
  React.PropsWithChildren<RejectQuotationModalContentProps>
> = ({ onClose, onSubmit, transitions, module }) => {
  return (
    <$ModalContent>
      <ReturnProductForm
        actions={[{ transitions, module }]}
        onSubmit={onSubmit}
        isModal
        closeModal={onClose}
      />
    </$ModalContent>
  );
};

RejectQuotationModalContent.displayName = 'RejectQuotationModalContent';

export default RejectQuotationModalContent;
