import getModelByIdQuery from './getModelByIdQuery';
import getModelsBySearchModelQuery from './getModelsBySearchModelQuery';
import { scanProductCommand } from './scanProductCommand';
import { getModelByEanQuery } from './getModelsByEanQuery';
import { getModelBySkuQuery } from './getModelBySkuQuery';

const CatalogService = {
  getModelByIdQuery,
  getModelsBySearchModelQuery,
  scanProductCommand,
  getModelByEanQuery,
  getModelBySkuQuery,
};

export default CatalogService;
