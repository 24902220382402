import { IssuesReasonsPageSubmitValues } from './IssuesReasonsPage.types';
import { useCallback } from 'react';
import { buildProfessionalRoute } from 'myaccount/view/app/NewLayout/ProfessionalRoutes.helpers';
import { PROFESSIONAL_STEP_CONFIG } from 'myaccount/view/app/NewLayout/ProfessionalRoutes.config';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import useGetClientInformation from '../../../app/hooks/useGetClientInformation';
import { useSelector } from 'react-redux';
import { ownerIdSelector } from '../../../../domains/Customer/selectors';
import { useQuery } from 'react-query';
import { OwnerService } from '../../../../api';

export const useIssuesReasonsPage = () => {
  const history = useHistory();

  const { removeAllNotifications, pushErrors } = useToasts();
  const { orderReference } = useParams<{ orderReference?: string }>();
  const { clientInformation } = useGetClientInformation();
  const ownerId = useSelector(ownerIdSelector);

  const { data, isLoading } = useQuery(
    [
      'startOrContinueBusinessReturnCommand',
      { sellerId: clientInformation?.sellerId, orderReference, ownerId },
    ],
    async () => {
      removeAllNotifications();

      if (!clientInformation?.sellerId) {
        return undefined;
      }

      const response = await OwnerService.startOrContinueBusinessReturnCommand({
        ownerId,
        sellerId: clientInformation?.sellerId,
        orderReference,
      });

      if (response.failure) {
        pushErrors(response.errors);

        return undefined;
      }

      return response.value;
    },
    { enabled: !!orderReference },
  );

  const handleSubmit = useCallback(
    async (values: IssuesReasonsPageSubmitValues) => {
      history.push(
        buildProfessionalRoute({
          route: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_PRODUCTS_TO_RETURN_PAGE,
          payload: { orderReference: values.order.orderReference },
        }),
      );
    },
    [history],
  );

  return {
    handleSubmit,
    order: data?.order,
    eligibility: data?.eligibility,
    isLoading,
  };
};
