import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  Claim,
} from '@savgroup-front-common/types';

 
const ENDPOINT = `${APIConfiguration.claim}claims/${COMMON_QUERY_PARAMS.CLAIM_ID}`;

interface GetClaimByClaimIdArgs {
  claimId: string;
}

const getClaimByClaimId = async ({
  claimId,
}: GetClaimByClaimIdArgs): Promise<
  BackResponse<Claim> | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.CLAIM_ID, claimId);

    const response = await apiCall<BackResponse<Claim>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default getClaimByClaimId;
