import React, { FunctionComponent } from 'react';

import { ActorSummary } from '@savgroup-front-common/types';

import { Heading } from '../../../../atoms/Heading';
import { SafeFormattedMessage } from '../../../../formatters';
import { GenericActorInformation } from '../GenericActorInformation';

import messages from './messages';

interface DefaultActorInformationProps {
  actor: ActorSummary;
  withTitle?: boolean;
}

const DefaultActorInformation: FunctionComponent<
  React.PropsWithChildren<DefaultActorInformationProps>
> = ({ actor, withTitle = true }) => {
  return (
    <div data-testid="actorInformation">
      {withTitle && (
        <Heading level={3}>
          <SafeFormattedMessage {...messages.defaultActorInfoTitle} />
        </Heading>
      )}

      <GenericActorInformation actor={actor} />
    </div>
  );
};

DefaultActorInformation.displayName = 'ActorInformation';

export default DefaultActorInformation;
