import { AdditionalInformationDto } from '@savgroup-front-common/types';

export const injectInternalId =
  (claimId: string, claimIds?: string[]) =>
  (neededInformationItem: AdditionalInformationDto) => {
    const { uniquenessByProduct, id } = neededInformationItem;

    if (uniquenessByProduct) {
      return {
        ...neededInformationItem,
        internalId: `${id}_${claimId}`,
        claimIds: [claimId],
      };
    }

    return { ...neededInformationItem, internalId: id, claimIds };
  };
