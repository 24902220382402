import { useQuery } from 'react-query';

import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import {
  ENTITY_TYPE,
  PAYMENT_METHOD,
  PAYMENT_STATUS,
} from '@savgroup-front-common/types';
import { PaymentService, RepairerService } from 'myaccount/api';

import { PaymentStatus } from '../../../../../../../components/Payments/CashRegisterPayment/CashRegisterPayment.types';

interface UseGetPaymentUrlReturnArgs {
  fileId?: string;
  claimGroupId?: string;
  paymentUrl?: string;
}

const useGetPaymentStatus = ({
  fileId,
  claimGroupId,
  paymentUrl,
}: UseGetPaymentUrlReturnArgs): PaymentStatus | undefined => {
  const { pushErrors, removeAllNotifications } = useToasts();

  const { data: paymentStatus } = useQuery(
    ['useGetPaymentUrl', { claimGroupId, fileId }],
    async () => {
      if (!claimGroupId || !fileId) {
        return undefined;
      }

      removeAllNotifications();

      const quotes = await RepairerService.getQuoteInfoByFileIdQuery({
        fileId,
      });

      if (quotes.failure) {
        pushErrors(quotes.errors);

        return undefined;
      }

      const quoteFiltered = quotes.value.filter(
        (quote) => quote.state === 'Approved',
      );

      const createPaymentResponse = await PaymentService.createPaymentCommand({
        entityId: quoteFiltered?.at(0)?.id || '',
        entityType: ENTITY_TYPE.QUOTE,
        paymentMethod: PAYMENT_METHOD.EXTERNAL_WEBSITE,
      });

      if (createPaymentResponse.failure) {
        pushErrors(createPaymentResponse.errors);

        return undefined;
      }

      const lastPaymentResponse = await PaymentService.getFileLastPaymentQuery({
        fileId,
      });

      if (lastPaymentResponse.failure) {
        pushErrors(lastPaymentResponse.errors);

        return undefined;
      }

      if (lastPaymentResponse.value?.status !== PAYMENT_STATUS.CREATED) {
        return { status: PAYMENT_STATUS.CREATED };
      }
      const issuePaymentResponse = await PaymentService.issuePaymentCommand({
        paymentId: lastPaymentResponse.value.paymentId,
      });

      if (issuePaymentResponse.failure) {
        pushErrors(issuePaymentResponse.errors);

        return undefined;
      }

      return {
        status: PAYMENT_STATUS.CREATED,
        paymentUrlWithExternalId: paymentUrl
          ?.replace('{externalId}', issuePaymentResponse.value)
          .replace(
            '{callback}',
            window.location.href.concat('?paymentConfirmed'),
          ),
      };
    },
    {
      staleTime: Infinity,
      suspense: true,
    },
  );

  return paymentStatus;
};

export default useGetPaymentStatus;
