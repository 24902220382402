import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import { REVALIDATE_MODES } from '@savgroup-front-common/constants';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import {
  ADDITIONAL_INFORMATION_TYPES,
  OrderProductSummary,
} from '@savgroup-front-common/types';
import { ClaimService } from 'myaccount/api';
import { AdditionalInformationExtended, RELATED_TO } from 'myaccount/types';
import { STEP_CONFIG } from 'myaccount/view/app/NewLayout/ClassiqueRoutes.config';
import {
  StepContext,
  StepContextValues,
} from 'myaccount/view/app/NewLayout/StepsProvider/StepsProvider.context';

import useGetClaimReasons from '../../app/hooks/useGetClaimReasons';
import { Claim } from '../../app/NewLayout/StepsProvider/StepsProvider.types';

import claimGroupProductInfoSchema from './ClaimGroupProductInfoPage.schema';
import { ClaimGroupProductInfoValues } from './ClaimGroupProductInfoPage.types';
import {
  prepareReasonAdditionalInformation,
  reasonAdapter,
} from './helpers/reason.adapters';

const useClaimGroupProductInfo = () => {
  const { removeAllNotifications, pushErrors } = useToasts();
  const { values, handleNextStep, handleValidateStep } =
    useContext<StepContextValues>(StepContext);

  const claims = values.claimGroup?.claims || [];
  const claimGroupId = values.claimGroup?.claimGroupId || '';

  const ownerProductIds = claims.map((claim) => claim.ownerProductId);
  const products = values.orders
    ?.at(0)
    ?.products.filter((product) =>
      ownerProductIds.includes(product.ownerProductId),
    )
    .reduce<Record<string, OrderProductSummary>>((acc, curr) => {
      if (curr) {
        return {
          ...acc,
          [curr.ownerProductId]: curr,
        };
      }

      return acc;
    }, {});

  const { reasons, reasonsIsLoading } = useGetClaimReasons({
    claimGroupId,
  });

  const claimIds = claims?.map((claim) => claim.claimId) || [];

  const neededInformationOnlyRelatedToProduct = claims.reduce(
    (acc: AdditionalInformationExtended[], curr: Claim) => {
      if (curr) {
        const { reasonId } = curr;
        const reasonSelected = reasons.find((reason) => reason.id === reasonId);

        if (reasonSelected) {
          const reason = reasonAdapter({
            reasonSelected,
            claimIds,
            currentClaimId: curr.claimId,
          });

          const neededInformation = reason?.neededInformation
            .filter((item) => item.type !== ADDITIONAL_INFORMATION_TYPES.FILE)
            .filter((item) => item.relatedTo === RELATED_TO.PRODUCT);

          return [...acc, ...neededInformation];
        }

        return acc;
      }

      return acc;
    },
    [],
  );

  const adaptAdditionalInformation = (claim: Claim) => {
    const { claimId } = claim;

    return claim.additionalClaimInformation?.reduce((acc, curr) => {
      if (curr.additionalInformationEnumValue) {
        return {
          ...acc,
          [`${curr.additionalInformationId}_${claimId}`]: {
            value: curr.additionalInformationEnumValue,
          },
        };
      }
      if (curr.additionalInformationStringValue) {
        return {
          ...acc,
          [`${curr.additionalInformationId}_${claimId}`]:
            curr.additionalInformationStringValue,
        };
      }

      return acc;
    }, {});
  };

  const formContext = useForm<ClaimGroupProductInfoValues>({
    resolver: yupResolver(
      claimGroupProductInfoSchema({
        claims,
        neededInformationOnlyRelatedToProduct,
      }),
    ),
    defaultValues: claims?.reduce((acc, curr) => {
      if (curr) {
        return {
          ...acc,
          [curr.claimId]: {
            reasonAdditionalInformationProductInfo:
              adaptAdditionalInformation(curr),
          },
        };
      }

      return acc;
    }, {}),
    mode: REVALIDATE_MODES.ON_CHANGE,
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = formContext;

  const {
    mutateAsync: handleAdditionalInformation,
    isLoading: isLoadingAdditionalInformation,
  } = useMutation(
    ['setAdditionalInformation'],
    async ({
      claimId,
      additionalClaimInformationValues,
    }: {
      claimId: string;
      additionalClaimInformationValues: { id: string; value: string }[];
    }) => {
      removeAllNotifications();
      const responseAdditionalInformation =
        await ClaimService.setClaimAdditionalInformation({
          claimId,
          additionalClaimInformationValues,
        });

      if (responseAdditionalInformation.failure) {
        pushErrors(responseAdditionalInformation.errors);

        return undefined;
      }

      return responseAdditionalInformation;
    },
  );

  const onSubmit = handleSubmit(async (reasonsResult) => {
    const hasAdditionnalInformationFileRelatedToProduct = claims
      .map((claim: Claim) => {
        const { reasonId } = claim;
        const reasonSelected = reasons.find((reason) => reason.id === reasonId);

        if (reasonSelected) {
          return reasonSelected.neededInformation.some(
            (item) =>
              item.type === ADDITIONAL_INFORMATION_TYPES.FILE &&
              item.relatedTo === RELATED_TO.PRODUCT,
          );
        }

        return false;
      })
      .some((x) => x);

    const hasAdditionnalInformationFileRelatedToClaim = claims
      .map((claim: Claim) => {
        const { reasonId } = claim;
        const reasonSelected = reasons.find((reason) => reason.id === reasonId);

        if (reasonSelected) {
          return reasonSelected.neededInformation.some(
            (item) =>
              item.type === ADDITIONAL_INFORMATION_TYPES.FILE &&
              item.relatedTo === RELATED_TO.CLAIM,
          );
        }

        return false;
      })
      .some((x) => x);

    try {
      await Promise.all(
        claims.map(async (claim) => {
          const neededInformationOnlyRelatedToProductWithoutFile =
            neededInformationOnlyRelatedToProduct
              .filter((item) => item.type !== ADDITIONAL_INFORMATION_TYPES.FILE)
              .filter((item) => item.relatedTo === RELATED_TO.PRODUCT);

          const responseAdditionalInformation =
            await handleAdditionalInformation({
              claimId: claim.claimId,
              additionalClaimInformationValues:
                prepareReasonAdditionalInformation(
                  claim.claimId,
                  reasonsResult[claim.claimId]
                    ?.reasonAdditionalInformationProductInfo,
                  neededInformationOnlyRelatedToProductWithoutFile,
                ),
            });

          if (!responseAdditionalInformation) {
            return undefined;
          }

          return responseAdditionalInformation;
        }),
      );

      if (hasAdditionnalInformationFileRelatedToProduct) {
        return handleNextStep(
          STEP_CONFIG.CLAIM_GROUP_DOCUMENT_RELATED_TO_PRODUCT,
          claimGroupId,
        );
      }

      if (hasAdditionnalInformationFileRelatedToClaim) {
        return handleNextStep(
          STEP_CONFIG.CLAIM_GROUP_DOCUMENT_RELATED_TO_CLAIM,
          claimGroupId,
        );
      }

      return handleNextStep(STEP_CONFIG.CLAIM_GROUP_SOLUTION, claimGroupId);
    } catch (err: any) {
      pushErrors(err);

      return undefined;
    }
  });

  useEffect(() => {
    handleValidateStep(isValid);
  }, [handleValidateStep, isValid]);

  return {
    claims,
    products,
    isLoading: reasonsIsLoading,
    neededInformationOnlyRelatedToProduct,
    formContext,
    onSubmit,
    isLoadingSubmit: isLoadingAdditionalInformation,
  };
};

export default useClaimGroupProductInfo;
