import { buffers } from 'redux-saga';
import { actionChannel, call, take } from 'redux-saga/effects';

const arrayOfMap = (keyValueMap) => {
  if (keyValueMap.values) {
    return Array.from(keyValueMap.values());
  }
  const items = new Array(keyValueMap.size);

  keyValueMap.forEach((item) => items.push(item));

  return items;
};

const firstEntryOfMap = (keyValueMap) => {
  if (keyValueMap.entries) {
    const entries = keyValueMap.entries();

    if (entries.next) {
      return entries.next().value;
    }
  }
  const entries = new Array(keyValueMap.size);

  keyValueMap.forEach((item, key) => entries.push([key, item]));

  return entries[0];
};

const squashBuffer = (
  getId = (value) => value,
  merge = (_oldItem, newItem) => newItem,
) => {
  let queue = new Map();

  return {
    isEmpty: () => queue.size === 0,
    flush: () => {
      const oldQueue = arrayOfMap(queue);

      queue = new Map();

      return oldQueue;
    },
    put: (item) => {
      const id = getId(item);
      const oldItem = queue.get(id);

      queue.delete(id);
      queue.set(id, merge(oldItem, item));
    },
    take: () => {
      if (queue.size > 0) {
        const [id, item] = firstEntryOfMap(queue);

        queue.delete(id);

        return item;
      }

      return undefined;
    },
  };
};

export function* squashTakeLatest(pattern, saga, getId, mergeActions, ...args) {
  const channel = yield actionChannel(
    pattern,
    squashBuffer(getId, mergeActions),
  );

  while (true) {
    const action = yield take(channel);

    yield call(saga, action, ...args);
  }
}

export function* throttledTakeLatest(pattern, saga, ...args) {
  const throttledPattern = yield actionChannel(pattern, buffers.sliding(1));

  while (true) {
    const action = yield take(throttledPattern);

    yield call(saga, action, ...args);
  }
}
