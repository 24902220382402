enum HANDLING_UNIQUE_NAME {
  RELAY_POINT_DEPOSIT = 'RelayPointDeposit',
  POST_OFFICE_DEPOT = 'PostOfficeDepot',
  ON_SITE_COLLECTION = 'OnSiteCollection',
  HOME_DELIVERY = 'HomeDelivery',
  PICKUP_COUNTER = 'PickupCounter',
  HOME_PICKUP = 'HomePickup',
  POST_OFFICE_DELIVERY = 'PostOfficeDelivery',
  PICKUP_STATION_DELIVERY = 'PickUpStationDelivery',
  DROP_AT_STORE_DEPOSIT = 'DropAtStoreDeposit',
  SELLER_PROVIDED_CARRIER = 'SellerProvidedCarrier',
  SELLER_PROVIDED_CARRIER_2 = 'SellerProvidedCarrier2',
  SELLER_PROVIDED_CARRIER_3 = 'SellerProvidedCarrier3',
  EXTERNAL_HOME_PICKUP = 'ExternalHomePickup',
  IMMEDIATE_DROP_AT_STORE_DEPOSIT = 'ImmediateDropAtStoreDeposit',
  IMMEDIATE_PICKUP_AT_STORE_DELIVERY = 'ImmediatePickUpAtStoreDelivery',
  SELF_DEPOSIT_CARRIER = 'SelfDepositCarrier',
  PICKUP_STORE_DELIVERY = 'PickUpStoreDelivery',
  HOME_INTERVENTION = 'HomeIntervention',
}

export default HANDLING_UNIQUE_NAME;
