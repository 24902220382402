/* eslint-disable no-console */
import { fromJS } from 'immutable';
import get from 'lodash/get';

export function onClaimGroupLoading(state, { meta }) {
  const claimGroupId = get(meta, ['claimGroupId']);

  if (!claimGroupId) {
    console.warn('[onClaimGroupLoading] ClaimGroupId is undefined');

    return state;
  }

  return state.setIn(
    ['claimGroups', claimGroupId],
    fromJS({ isLoaded: false, isDirty: false, value: undefined }),
  );
}

export function onClaimGroupLoadingErrored(state, { meta }) {
  const claimGroupId = get(meta, ['claimGroupId']);

  if (!claimGroupId) {
    console.warn('[onClaimLoadingErrored] ClaimGroupId is undefined');

    return state;
  }

  // TODO errors
  return state.updateIn(['claimGroups', claimGroupId], (oldValue) =>
    oldValue.setIn(['isDirty'], true).setIn(['hasErrors'], true),
  );
}

export function onClaimGroupLoaded(state, { payload, meta }) {
  const claimGroupId = get(meta, ['claimGroupId']);
  const claimGroup = get(payload, ['value']);

  if (!claimGroupId) {
    console.warn('[onClaimGroupLoaded] ClaimGroupId is undefined.');

    return state;
  }

  return state.setIn(
    ['claimGroups', claimGroupId],
    fromJS({ isLoaded: true, isDirty: false }).setIn(['value'], claimGroup),
  );
}

export function onClaimGroupDeleted(state, { meta }) {
  const claimGroupId = get(meta, ['claimGroupId']);

  if (!claimGroupId) {
    console.warn('[onClaimGroupDeleted] ClaimGroupId is undefined.');

    return state;
  }

  return state.deleteIn(['claimGroups', claimGroupId]);
}
