/* eslint-disable no-console */
import { fromJS } from 'immutable';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';

import { getCarrierBrand } from '../../../helpers';

export function onCarriersForClaimGroupLoading(state, { meta }) {
  const claimGroupId = get(meta, ['claimGroupId']);
  const handlingMode = get(meta, ['handlingMode']);

  if (!claimGroupId) {
    console.warn('[onCarriersForClaimGroupLoading] ClaimGroupId is undefined');

    return state;
  }

  return state.setIn(
    ['groupCarriers', claimGroupId, handlingMode],
    fromJS({ isLoaded: false, isDirty: false, value: undefined }),
  );
}

export function onCarriersForClaimGroupLoadingErrored(state, { meta, errors }) {
  const claimGroupId = get(meta, ['claimGroupId']);
  const handlingMode = get(meta, ['handlingMode']);

  if (!claimGroupId) {
    console.warn(
      '[onCarriersForClaimGroupLoadingErrored] ClaimGroupId is undefined',
    );

    return state;
  }

  // TODO errors
  return state.updateIn(
    ['groupCarriers', claimGroupId, handlingMode],
    (oldValue) =>
      oldValue
        .setIn(['isDirty'], true)
        .setIn(['hasErrors'], true)
        .setIn(['errors'], errors)
        .setIn(['value'], undefined),
  );
}

export function onCarriersForClaimGroupLoaded(state, { payload, meta }) {
  const claimGroupId = get(meta, ['claimGroupId']);
  const handlingMode = get(meta, ['handlingMode']);
  const carriers = groupBy(
    map(get(payload, ['value']), (carrier) => ({
      ...carrier,
      carrierBrand: getCarrierBrand(carrier.carrierName),
    })),
    ({ productType }) => `${handlingMode}_${productType}`,
  );

  if (!claimGroupId) {
    console.warn('[onCarriersForClaimGroupLoaded] ClaimGroupId is undefined.');

    return state;
  }

  return state.setIn(
    ['groupCarriers', claimGroupId, handlingMode],
    fromJS({ isLoaded: true, isDirty: false }).setIn(['value'], carriers),
  );
}
