import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  ReverseMoneyNumberAmount,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.payment}files/${COMMON_QUERY_PARAMS.FILE_ID}/payments`;

interface GetFilePaymentsQueryArgs {
  fileId: string;
}

export interface FilePayment {
  paymentId: string;
  paymentMethod: string;
  paymentReference: string;
  paymentDate: Date;
  paymentLineId: string;
  serviceId: string;
  serviceType: string;
  label: string;
  priceIncludingVat: ReverseMoneyNumberAmount;
  vatRatePercentage: number;
  targetKind: string;
  targetId: string;
}

async function getFilePaymentsQuery(
  payload: GetFilePaymentsQueryArgs,
): Promise<BackResponse<FilePayment[]> | BackResponseFailure> {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, payload.fileId);

    return await apiCall<BackResponse<FilePayment[]>>(url, {
      method: SUPPORTED_METHODS.GET,
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default getFilePaymentsQuery;
