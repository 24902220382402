import { evaluate, EvaluateOptions } from '@mdx-js/mdx';
import { MDXContent as MDX } from 'mdx/types';
import React, { FC, useEffect, useState } from 'react';
import {
  Col as FlexCol,
  Row as FlexRow,
  ReactStyledFlexboxgrid,
} from 'react-styled-flexboxgrid';
import * as runtime from 'react/jsx-runtime';
import styled from 'styled-components';

import getTextColorBasedOnProps from '../../theme/helpers/getTextColorBasedOnProps';

import { useFormatMdxContent } from './formatters/useFormatMdxContent';
import { $MDXContent } from './MDXContent.styles';

const Row: FC<React.PropsWithChildren<ReactStyledFlexboxgrid.IRowProps>> = ({
  children,
  ...rest
}) => <FlexRow {...rest}>{children}</FlexRow>;

Row.displayName = 'Row';

const Col: FC<React.PropsWithChildren<ReactStyledFlexboxgrid.IColProps>> = ({
  children,
  ...rest
}) => <FlexCol {...rest}>{children}</FlexCol>;

Col.displayName = 'Col';

const defaultComponent = {
  Row,
  Col,
  H3: styled.h3`
    && {
      font-size: 1.5rem;
      color: ${(props) => getTextColorBasedOnProps(props, 'inherit')};
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  `,
  H4: styled.h4`
    && {
      color: ${(props) => getTextColorBasedOnProps(props, 'inherit')};
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  `,
  Strong: styled.strong`
    && {
      color: ${(props) => getTextColorBasedOnProps(props, 'inherit')};
    }
  `,
  Ol: styled.ol`
    && {
      margin: 0;
      text-align: left;
      padding-left: 30px;
      line-height: 3rem;
    }
    && > li {
      margin: 0 0 0.8rem 0;
    }
  `,
  Li: styled.li`
    && {
      line-height: 1.5rem;
    }
  `,
  Hr: styled.hr`
    && {
      border-top: 1px solid #e0e0e0;
    }
  `,
};

interface MDXContentProps {
  customComponents?: Record<string, unknown>;
}

export const MDXContent: FC<React.PropsWithChildren<MDXContentProps>> =
  React.memo(({ children, customComponents = {} }) => {
    const components = {
      ...defaultComponent,
      ...customComponents,
    };

    const content = useFormatMdxContent(children as string);

    const [Content, setContent] = useState<MDX>(() => () => <></>);

    useEffect(() => {
      evaluate(content, runtime as EvaluateOptions).then((mdxModule) =>
        setContent(() => mdxModule.default),
      );
    }, [content]);

    return (
      <$MDXContent>
        <Content components={components} />
      </$MDXContent>
    );
  });
