import { Trash } from 'iconoir-react';
import React, { FunctionComponent } from 'react';

import { IconPropsInterface } from './Icon.types';

const DeleteIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => {
  return <Trash width={size} height={size} color={color} />;
};

DeleteIcon.displayName = 'DeleteIcon';

export default DeleteIcon;
