import React, { FunctionComponent } from 'react';

import { $CardContentHistoryDashedLine } from './ProductInfoHistory.styles';

const ProductInfoHistoryDashedLine: FunctionComponent<{
  disabled?: boolean;
  isFirst?: boolean;
  isDashed?: boolean;
}> = ({ disabled, isFirst = false, isDashed = true }) => {
  return (
    <$CardContentHistoryDashedLine
      disabled={disabled}
      isFirst={isFirst}
      isDashed={isDashed}
      viewBox="0 0 160 9"
    >
      <line x1="0" y1="5" x2="100%" y2="5" />
    </$CardContentHistoryDashedLine>
  );
};

ProductInfoHistoryDashedLine.displayName = 'ProductInfoHistoryDashedLine';
export default ProductInfoHistoryDashedLine;
