import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  CURRENCIES,
  HANDLING_MODES,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

 
const ENDPOINT = `${APIConfiguration.claim}claimGroups/${COMMON_QUERY_PARAMS.CLAIM_GROUP_ID}/carriers`;

export interface Carrier {
  carrierBrand?: string;
  carrierCompany?: string;
  carrierCustomerPriceId?: string;
  carrierId?: string;
  carrierName?: string;
  carrierProductId?: string;
  carrierSellerPriceId?: string;
  currencyCode?: CURRENCIES;
  handlingMode: HANDLING_MODES;
  price?: number;
  priceWithTax?: number;
  productType: string;
  transportReach?: string;
  carrierTransportMethodUniqueName?: string;
}

interface GetCarriersByClaimArgs {
  claimGroupId: string;
  handlingMode: string;
}

const getClaimGroupCarrierQuery = async ({
  claimGroupId,
  handlingMode,
}: GetCarriersByClaimArgs): Promise<
  BackResponse<Carrier[]> | BackResponseFailure
> => {
  try {
    const url = buildUrl(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.CLAIM_GROUP_ID, claimGroupId),
      {
        mode: handlingMode,
      },
    );

    const response = await apiCall<BackResponse<Carrier[]>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default getClaimGroupCarrierQuery;
