import { defineMessages } from 'react-intl';

export default defineMessages({
  isEligible: {
    id: 'components.b2b.isEligible',
    defaultMessage: 'Is eligible',
  },
  isNotEligible: {
    id: 'components.b2b.isNotEligible',
    defaultMessage: 'Is not eligible',
  },
});
