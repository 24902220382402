import { AdditionalInformationDto } from '@savgroup-front-common/types';

export function indexWithUniquenessByProduct() {
  return (
    acc: {
      counter: number;
      previousIsGrouped?: boolean;
      indexedNeededInformation: (AdditionalInformationDto & {
        internalId: string;
        claimIds: string[];
        fileUploadEndpoints?: {
          [key: string]: {
            uploadEndpoint: string;
            deleteEndpoint: string;
          };
        };
        index?: number;
        isEven: boolean;
      })[];
    },
    item: AdditionalInformationDto & {
      internalId: string;
      claimIds: string[];
      fileUploadEndpoints?: {
        [key: string]: {
          uploadEndpoint: string;
          deleteEndpoint: string;
        };
      };
    },
  ) => {
    const { counter } = acc;

    let { previousIsGrouped } = acc;

    const { uniquenessByProduct } = item;

    if (uniquenessByProduct) {
      return {
        counter: 0,
        previousIsGrouped: true,
        indexedNeededInformation: [
          ...acc.indexedNeededInformation,
          { ...item, isEven: false },
        ],
      };
    }

    if (previousIsGrouped) {
      previousIsGrouped = false;
    }

    return {
      counter: counter + 1,
      previousIsGrouped,
      indexedNeededInformation: [
        ...acc.indexedNeededInformation,
        {
          ...item,
          index: counter,
          isEven: counter % 2 === 0,
        },
      ],
    };
  };
}
