import React from 'react';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';

import useLoadOrder from './hooks/useLoadOrder';
import { useLayout } from './NewLayout.hooks';
import { useIsBTobEnabled } from '../hooks/useIsBTobEnabled';
import ClassiqueLayout from './ClassiqueLayout';
import ProfessionalLayout from './ProfessionalLayout';
import InitProvider from './InitProvider/InitProvider.context';
 
const NewLayout: React.FC<unknown> = () => {
  const {
    sellerId,
    ownerId,
    login,
    enableServicePortal,
  } = useLayout();
  const isBtoBPortalEnabled = useIsBTobEnabled();
  const { orders, openedFiles, closedFiles, isLoading } = useLoadOrder({
    ownerId,
  });

  if (!sellerId || isLoading) {
    return <BaseLoader />;
  }

  if (isBtoBPortalEnabled) {
    return (
      <InitProvider
        ownerId={ownerId}
        login={login}
        orders={orders}
        openedFiles={openedFiles}
        closedFiles={closedFiles}
        enableServicePortal={enableServicePortal}
      >
        <ProfessionalLayout sellerId={sellerId} />
      </InitProvider>
    );
  }

  return (
    <InitProvider
      ownerId={ownerId}
      login={login}
      orders={orders}
      openedFiles={openedFiles}
      closedFiles={closedFiles}
      enableServicePortal={enableServicePortal}
    >
      <ClassiqueLayout sellerId={sellerId} />
    </InitProvider>
  );
};

NewLayout.whyDidYouRender = true;
NewLayout.displayName = 'NewLayout';

export default NewLayout;
