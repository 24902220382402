const enum Origin {
  UNDEFINED = 'Undefined',
  SHARE_BY_LINK = 'ShareByLink',
  MESSENGER_CENTRAL = 'MessengerCentral',
  WORKFLOW_NOTIFICATION = 'WorkflowNotification',
  PUBLIC_API = 'PublicApi',
  MY_ACCOUNT = 'MyAccount',
  BACKOFFICE = 'Backoffice',
  CUSTOMER_CRM = 'CustomerCRM',
}

export default Origin;
