import { Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { H2 } from '@savgroup-front-common/core/src/atoms/headings';
import { mediaQuery } from '@savgroup-front-common/core/src/grid/helpers';

export const $DevicePasswordTitle = styled(H2)`
  padding-top: 0;
  padding-bottom: 8px;
`;

export const $DeviceFormContainer = styled(Row)`
  margin-top: 24px;

  ${mediaQuery.md`
    justify-content: flex-end;
    margin-top: 0;
  `};
`;

export const $DevicePasswordButton = styled(Button)`
  ${mediaQuery.sm`
    align-self: flex-end;
    margin-top: 16px;
  `};
`;
