import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'components.homePickup.title',
    defaultMessage: 'Take an appointment for home pickup',
  },
  howDoesItWorks: {
    id: 'components.homePickup.howDoesItWorks',
    defaultMessage: 'How does it works ?',
  },

  takeAppointment: {
    id: 'components.homePickup.takeAppointment',
    defaultMessage: 'Take appointment',
  },
  takeAppointmentDidactic: {
    id: 'components.homePickup.takeAppointmentDidactic',
    defaultMessage: 'with a technician for a home intervention',
  },

  handling: {
    id: 'components.homePickup.handling',
    defaultMessage: 'The product is taken',
  },
  handlingDidactic: {
    id: 'components.homePickup.interventionDidactic',
    defaultMessage: 'by the carrier',
  },

  takeAppointmentDone: {
    id: 'components.homePickup.takeAppointmentDone',
    defaultMessage: "It's done!",
  },
  takeAppointmentDoneDidactic: {
    id: 'components.homePickup.takeAppointmentDoneDidactic',
    defaultMessage: 'your product has, if possible, been repaired',
  },
});
