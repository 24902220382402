import Immutable, { fromJS } from 'immutable';
import { AnyAction } from 'redux';

import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';

import * as actionTypes from './actionTypes';
import { ACTORS_DOMAIN } from './constants';

type ActorsState = Immutable.Map<
  string,
  Immutable.Map<string, { [key: string]: Record<string, unknown> }>
>;

const initialState = fromJS({
  [ACTORS_DOMAIN.VALUES]: {},
});

interface OnGetActorByIdSuccessAction extends AnyAction {
  payload: { value: Record<string, unknown> };
  meta: { actorId: string };
}

function onGetActorByIdSuccess(
  state: ActorsState,
  { payload, meta }: OnGetActorByIdSuccessAction,
) {
  const { actorId } = meta;
  const { value } = payload;

  return state.updateIn(
    [ACTORS_DOMAIN.VALUES, actorId],
    {},
    (oldValue = {}) => {
      return { ...oldValue, ...value };
    },
  );
}

export default function mainReducer(
  state: ActorsState = initialState,
  action: AnyAction,
) {
  try {
    switch (action.type) {
      case actionTypes.GET_ACTOR_SUMMARY_BY_ID.SUCCEEDED:
        return onGetActorByIdSuccess(
          state,
          action as OnGetActorByIdSuccessAction,
        );
      default:
        return state;
    }
  } catch (e) {
    logCritical(e);

    return state;
  }
}
