import React, { FC, Suspense } from 'react';

import { Card } from '@savgroup-front-common/core/src/atoms/Cards';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { ErrorBoundary } from '@savgroup-front-common/core/src/molecules/ErrorBoundary';
import { BannersConsumer } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { TakeHomePickupAppointmentHeader } from '@savgroup-front-common/core/src/molecules/TakeHomePickupAppointmentHeader/TakeHomePickupAppointmentHeader';
import { FileSummary, ModuleSummary } from '@savgroup-front-common/types';

import {
  $AppointmentCardContainer,
  $ExpectContainer,
} from '../ExpectsContainer.styles';

import { TakeAppointmentContent } from './TakeAppointmentContent';

interface TakeAppointmentProps {
  cancelToState?: string;
  fileSummary: FileSummary;
  fileStatusName: string;
  targetStateName: string;
  module: ModuleSummary;
}

export const TakeAppointment: FC<TakeAppointmentProps> = ({
  cancelToState,
  fileSummary,
  fileStatusName,
  targetStateName,
  module,
}) => {
  return (
    <Suspense fallback={<BaseLoader />}>
      <ErrorBoundary
        fallback={
          <$ExpectContainer>
            <$AppointmentCardContainer>
              <BannersConsumer />
            </$AppointmentCardContainer>
          </$ExpectContainer>
        }
      >
        <$ExpectContainer>
          <$AppointmentCardContainer>
            <TakeHomePickupAppointmentHeader />

            <Card>
              <TakeAppointmentContent
                cancelToState={cancelToState}
                fileSummary={fileSummary}
                fileStatusName={fileStatusName}
                targetStateName={targetStateName}
                module={module}
              />
            </Card>
          </$AppointmentCardContainer>
        </$ExpectContainer>
      </ErrorBoundary>
    </Suspense>
  );
};

TakeAppointment.displayName = 'TakeAppointment';
