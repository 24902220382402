import { defineMessages } from 'react-intl';

export default defineMessages({
  paymentWithoutStripeTitle: {
    id: 'view.myaccount.step.validationPage.paymentWithoutStripeTitle',
    defaultMessage: 'Payment 100% secured',
  },
  paymentWithoutStripeDidactic: {
    id: 'view.myaccount.step.validationPage.paymentWithoutStripeDidactic',
    defaultMessage: 'Payment without stripe didactic',
  },
  paymentWithoutStripeButton: {
    id: 'components.myaccount.claims.confirmation.paymentForm.pay',
    defaultMessage: 'Pay {amount}',
  },
  noPaymentFound: {
    id: 'view.myaccount.step.validationPage.noPaymentFound',
    defaultMessage: 'No payment found',
  },
});
