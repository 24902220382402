import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useGetModelByIdQuery } from 'myaccount/view/app/hooks/useGetModelByIdQuery';
import { useGetMultipleCustomerFileFullSummaryQuery } from 'myaccount/view/app/hooks/useGetMultipleCustomerFileFullSummaryQuery';
import { useGetMultipleFilesByOrderQuery } from 'myaccount/view/app/hooks/useGetMultipleFilesByOrderQuery';
import { useGetMultipleFileStatesQuery } from 'myaccount/view/app/hooks/useGetMultipleFileStatesQuery';
import { useGetMultipleSolutionTypeQuery } from 'myaccount/view/app/hooks/useGetMultipleSolutionTypeQuery';
import { useGetOrderByOwnerId } from 'myaccount/view/app/hooks/useGetOrderByOwnerId';
import { buildFileFollowupUrl } from 'myaccount/view/app/NewLayout/ClassiqueLayout.helpers';
import { useLayout } from 'myaccount/view/app/NewLayout/NewLayout.hooks';

import {
  adaptAllProducts,
  adaptMultiCustomerFileFullSummary,
} from './OrdersPage.adapters';

export const useOrdersPage = () => {
  const { ownerId } = useLayout();
  const { push } = useHistory();
  const { orders } = useGetOrderByOwnerId({ ownerId });
  const { ordersDetail, isLoading: isLoadingOrderDetail } =
    useGetMultipleFilesByOrderQuery({
      orderIds: orders ? orders.map((order) => order.orderId) : undefined,
    });

  const fileIds = ordersDetail?.map((fileSummary) => fileSummary.fileId);
  const { multiProductsInfo, refetchFileSummary } =
    useGetMultipleCustomerFileFullSummaryQuery({
      fileIds: !isLoadingOrderDetail ? fileIds : undefined,
    });

  const multiProductsInfoAdapted =
    adaptMultiCustomerFileFullSummary(multiProductsInfo);

  const { models } = useGetModelByIdQuery({
    productsInfo: multiProductsInfoAdapted?.flat(1),
  });

  const { solutions } = useGetMultipleSolutionTypeQuery({
    solutionTypeIds: multiProductsInfoAdapted
      ?.flat(1)
      .map((productInfo) => productInfo?.solutionId),
  });

  const { multiHistories, refetchHistories } = useGetMultipleFileStatesQuery({
    fileIds: !isLoadingOrderDetail ? fileIds : undefined,
  });

  useEffect(() => {
    refetchFileSummary();
    refetchHistories();
  }, [isLoadingOrderDetail, refetchFileSummary, refetchHistories]);

  const handleShowProduct = ({ fileId }: { fileId?: string }) => {
    if (!fileId) {
      return;
    }

    push(buildFileFollowupUrl({ fileId }));
  };

  return {
    productsInfoAndHistoryList: adaptAllProducts({
      models,
      solutions,
      multiProductsInfoAdapted,
      multiHistories,
    }),
    handleShowProduct,
  };
};
