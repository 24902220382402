import { useQuery } from 'react-query';

import { ClaimService } from 'myaccount/api';

export const CreateInvoiceWithClaimGroup = 'createInvoiceWithClaimGroup';

export const useCreateInvoiceWithClaimGroup = ({
  claimGroupId,
}: {
  claimGroupId?: string;
}) => {
  const { data, isLoading, isRefetching } = useQuery(
    [CreateInvoiceWithClaimGroup],
    async () => {
      if (!claimGroupId) {
        return undefined;
      }

      const response = await ClaimService.createInvoiceQuery({
        claimGroupId,
      });

      return response.errors;
    },
  );

  return {
    errors: data,
    isLoading: isLoading || isRefetching,
  };
};
