import styled from 'styled-components';

import { rem } from '../../helpers';
import { getTextColorBasedOnProps } from '../../theme/helpers';

const H3 = styled.h3.attrs({ role: 'presentation' })`
  font-size: ${rem('16px')};
  font-weight: ${({ medium }) => (medium ? 500 : 400)};
  margin: 0;
  padding-top: ${rem('4px')};
  padding-bottom: ${rem('4px')};
  color: ${(props) => getTextColorBasedOnProps(props)};
  text-align: ${(props) => props.align};
`;

export default H3;
