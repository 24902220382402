import { useMemo } from 'react';

import ClaimsToFinalizePage from '../ClaimsToFinalizePage/ClaimsToFinalizePage';
import { TABS_HOME } from '../HomePageProfessional.constants';
import HomePageFiles from '../HomePageFiles/HomePageFiles';
import messages from '../messages';
import {
  HOME_PROFESSIONAL_ROUTES,
  ROUTES_PROFESSIONAL,
} from 'myaccount/view/app/routes/ProfessionalRoutes';
import { FileShortSummary } from '@savgroup-front-common/types';

interface UseHomeTabsArgs {
  openedFiles: FileShortSummary[];
  closedFiles: FileShortSummary[];
}

const useHomeTabs = ({ openedFiles, closedFiles }: UseHomeTabsArgs) => {
  const hasFiles = Boolean(openedFiles.length || closedFiles.length);

  const tabsConfig = useMemo(() => {
    if (hasFiles) {
      return [
        {
          name: TABS_HOME.REPAIRS,
          path: HOME_PROFESSIONAL_ROUTES.REPAIRS,
          exact: false,
          Component: HomePageFiles,
          message: messages.myRepairsLabel,
          url: HOME_PROFESSIONAL_ROUTES.REPAIRS,
          icon: 'FolderIcon',
        },
      ];
    }

    return [
      {
        name: 'home',
        path: ROUTES_PROFESSIONAL.HOME,
        exact: false,
        Component: ClaimsToFinalizePage,
        message: messages.myProductsLabel,
        url: ROUTES_PROFESSIONAL.HOME,
        icon: 'SmallShopIcon',
      },
    ];
  }, [hasFiles]);

  return {
    tabsConfig,
  };
};

export default useHomeTabs;
