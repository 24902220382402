import { Product } from '@savgroup-front-common/types';

import useGetModelDetailByIdQuery from '../../../../app/hooks/useGetModelDetailByIdQuery';

interface UseChooseProductCardArgs {
  product: Product;
}

const useChooseProductCard = ({ product }: UseChooseProductCardArgs) => {
  const { modelDetail, isLoading } = useGetModelDetailByIdQuery({ product });

  return {
    modelDetail,
    isLoading,
  };
};

export default useChooseProductCard;
