import get from 'lodash/get';
import { createSelector } from 'reselect';

import { FileId, TechnicalIntervention } from '@savgroup-front-common/types';

import { CommonAppState } from '../CommonAppState';

import {
  TECHNICAL_INTERVENTIONS_DOMAIN,
  TECHNICAL_INTERVENTIONS_DOMAIN_KEY,
} from './constants';

const selectTechnicalInterventionsDomain = (state: CommonAppState) =>
  state[TECHNICAL_INTERVENTIONS_DOMAIN_KEY];

export const selectTechnicalInterventionsByFileId = createSelector(
  [
    selectTechnicalInterventionsDomain,
    (_: any, { fileId }: { fileId: FileId }) => fileId,
  ],
  (state, fileId): TechnicalIntervention | undefined =>
    get(state, [TECHNICAL_INTERVENTIONS_DOMAIN.list, fileId]),
);
