import * as Yup from 'yup';

import messages from './messages';

const claimGroupReasonSchema = () => {
  return Yup.object().shape({
    reason: Yup.object()
      .nullable()
      .test(
        'required-reason',
        messages.reasonIsRequired,
        function validateRequiredReason(option) {
          if (!option) {
            return false;
          }

          return option.value !== null && option.value !== undefined;
        },
      )
      .required(messages.reasonIsRequired),
  });
};

export default claimGroupReasonSchema;
