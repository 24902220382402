import React from 'react';
import { useMedia } from 'react-use';
import { useTheme } from 'styled-components';

import {
  CURRENCIES,
  media,
  HANDLING_UNIQUE_NAME,
} from '@savgroup-front-common/constants';
import { ICONS_TYPE } from '@savgroup-front-common/types/src/Icon';
import { ClaimGroupConfirmation } from 'myaccount/api/Claim/getClaimGroupConfirmationQuery';
import {
  SimpleItemCard,
  SimpleItemCardMobile,
} from 'myaccount/view/atoms/SimpleItemCard';

import messages from './messages';
import { getHandlingData } from '../../../helpers';

interface ResumeDepositProps {
  claimGroupConfirmation?: ClaimGroupConfirmation;
}

const ResumeDeposit: React.FC<ResumeDepositProps> = ({
  claimGroupConfirmation,
}) => {
  const isMobileView = useMedia(media.maxWidth.xs);
  const theme = useTheme();

  const addressName = `${
    claimGroupConfirmation?.depositAddress?.firstname || ''
  } ${claimGroupConfirmation?.depositAddress?.lastname || ''}`;
  const address = claimGroupConfirmation?.depositAddress?.address;
  const postalCode = claimGroupConfirmation?.depositAddress?.postalCode;
  const city = claimGroupConfirmation?.depositAddress?.city;
  const addressFormated = `${address} ${postalCode} ${city}`;

  const { label, description } = getHandlingData({
    carrierUniqueName:
      claimGroupConfirmation?.carrierDepositTransportMethodUniqueName as HANDLING_UNIQUE_NAME,
  });

  return isMobileView ? (
    <SimpleItemCardMobile
      icon={ICONS_TYPE.DEPOSIT_ICON}
      iconColor={theme.colors.primary}
      prefixTitle={messages.depositTitle}
      title={label}
      didactic={description}
      address={{
        addressName,
        addressStreet: addressFormated,
      }}
      price={{
        priceValue: claimGroupConfirmation?.carrierDepositPriceWithTax,
        priceCurrencyCode:
          claimGroupConfirmation?.carrierDepositPriceWithTaxCurrencyCode as CURRENCIES,
      }}
    />
  ) : (
    <SimpleItemCard
      icon={ICONS_TYPE.DEPOSIT_ICON}
      iconColor={theme.colors.primary}
      prefixTitle={messages.depositTitle}
      title={label}
      didactic={description}
      address={{
        addressName,
        addressStreet: addressFormated,
      }}
      price={{
        priceValue: claimGroupConfirmation?.carrierDepositPriceWithTax,
        priceCurrencyCode:
          claimGroupConfirmation?.carrierDepositPriceWithTaxCurrencyCode as CURRENCIES,
      }}
    />
  );
};

ResumeDeposit.displayName = 'ResumeDeposit';

export default ResumeDeposit;
