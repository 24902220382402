import styled from 'styled-components';

export const $QuotationLineHead = styled.div<{
  $isActive: boolean;
  $gridTemplateColumns: string;
}>`
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  color: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.mainTextColor : theme.colors.paragraphTextColor};
  text-align: left;
  background-color: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.headerTitleColor : theme.colors.secondaryColor2};

  & div {
    padding: 1rem;
  }

  display: grid;
  grid-template-columns: ${({ $gridTemplateColumns }) => {
    return $gridTemplateColumns;
  }};
`;
