import React, { FC } from 'react';

import { NOTIFICATION_TYPES } from '@savgroup-front-common/constants';

import messages from '../../messages';

import { $EmptyWeek } from './EmptyWeekLegacy.styles';
import FormGroup from '../../../../../../../../atoms/Form/FormGroup';
import { SafeFormattedMessageWithoutSpread } from '../../../../../../../../formatters/SafeFormattedMessageWithoutSpread';
import Banner from '../../../../../../../../atoms/Banners/Banner';

const EmptyWeekLegacy: FC = () => {
  return (
    <$EmptyWeek>
      <FormGroup>
        <Banner
          notificationType={NOTIFICATION_TYPES.DEFAULT}
          hollow
          hasIcon={false}
        >
          <SafeFormattedMessageWithoutSpread
            message={messages.noAvailableTechnicalInterventionSlotsForThisPage}
          />
        </Banner>
      </FormGroup>
    </$EmptyWeek>
  );
};

EmptyWeekLegacy.displayName = 'EmptyWeekLegacy';

export default EmptyWeekLegacy;
