import { defineMessages } from 'react-intl';

export default defineMessages({
  Reconditioned: {
    id: 'sparePart.condition.Reconditioned',
    defaultMessage: 'Reconditioned',
  },
  New: {
    id: 'sparePart.condition.New',
    defaultMessage: 'New',
  },
  Used: {
    id: 'sparePart.condition.Used',
    defaultMessage: 'Used',
  },
});
