import { defineMessages } from 'react-intl';

export default defineMessages({
  devicePasswordTitle: {
    id: 'components.myaccount.myReturn.devicePasswordForm.devicePasswordTitle',
    defaultMessage: 'Unlock code',
  },
  devicePasswordSubTitle: {
    id: 'components.myaccount.myReturn.devicePasswordForm.devicePasswordSubTitle',
    defaultMessage: 'We need to unlock your device in order to repair it.',
  },
  enterDevicePassword: {
    id: 'components.myaccount.myReturn.devicePasswordForm.enterDevicePassword',
    defaultMessage: "Enter your device's password",
  },
  submit: {
    id: 'components.myaccount.myReturn.transitionsForm.submit',
    defaultMessage: 'Submit',
  },
  requiredDevicePassword: {
    id: 'components.myaccount.myReturn.quoteInformation.commentMandatory',
    defaultMessage: 'This field is required',
  },
});
