import { cancelled as cancelledSaga } from '@redux-saga/core/effects';
import noop from 'lodash/noop';
import toPairs from 'lodash/toPairs';
import { END, eventChannel } from 'redux-saga';
import { put, take } from 'redux-saga/effects';

import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';

import { logError } from '../../../configuration/src/appInsights/AppInsights';

import { CreateRequestResponse } from './request';

const XHR_DONE_STATE = 4;

export interface XhrWithEventChannelEvent {
  done?: boolean;
  ok?: boolean;
  status?: number;
  statusText?: string;
  response?: any;
  progress?: number;
}

export function xhrWithEventChannel() {
  const xhr = new XMLHttpRequest();

  let xhrCancelled = false;

  let cancellationSignal = noop;
  const xhrEventChannel = eventChannel<XhrWithEventChannelEvent>((emitter) => {
    if (xhr.upload) {
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const progress = Math.floor((event.loaded / event.total) * 100);

          emitter({ progress });
        }
      };
    }
    xhr.onload = () => {
      emitter({
        done: true,
        ok: xhr.status < 400,
        status: xhr.status,
        statusText: xhr.statusText,
        response: xhr.response,
        progress: 100,
      });
    };
    xhr.onerror = () => {
      emitter({
        done: true,
        ok: false,
        status: xhr.status,
        statusText: xhr.statusText,
        response: xhr.response,
      });
    };
    cancellationSignal = () => {
      emitter(END);
    };

    return () => {
      cancellationSignal = noop;
      if (xhr.readyState !== XHR_DONE_STATE) {
        xhrCancelled = true;
        xhr.abort();
      }
    };
  });

  return {
    isCancelled() {
      return xhrCancelled;
    },
    cancellationSignal,
    rawXhr: xhr,
    xhrEventChannel,
    setHeaders: (headers: Record<string, string | undefined>) => {
      for (const [name, value] of toPairs(headers)) {
        if (value !== undefined) {
          xhr.setRequestHeader(name, value);
        }
      }
    },
  };
}

export function tryParseJson(data?: any) {
  try {
    return JSON.parse(data);
  } catch (e) {
    logError(e);

    return data;
  }
}

function* reduxExtendedRequestSaga(
  action: CreateRequestResponse,
  url: string,
  config: {
    method: SUPPORTED_METHODS;
    headers?: Record<string, string>;
    body?: FormData;
    accessToken: string;
    language: string;
  },
  meta: Record<string, unknown>,
): any {
  const { method, headers, body, accessToken, language } = config;

  const {
    rawXhr,
    setHeaders,
    cancellationSignal,
    isCancelled,
    xhrEventChannel,
  } = xhrWithEventChannel();

  try {
    rawXhr.open(method, url);
    setHeaders({
      Accept: 'application/json',
      'Access-Control-Max-Age': '600',
      'Accept-Language': language,
      Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      ...headers,
    });
    yield put(action.start({ ...meta, cancellationSignal }));

    rawXhr.send(body);

    while (true) {
      const event = yield take(xhrEventChannel);

      yield put(action.progress(event.progress, meta));
      if (event.done) {
        const response = tryParseJson(event.response);

        if (event.ok) {
          return yield put(action.success(response, meta));
        }

        return yield put(action.error(response, meta));
      }
    }
  } catch (error) {
    return yield put(action.error(error, meta));
  } finally {
    if (yield cancelledSaga()) {
      xhrEventChannel.close();
      yield put(action.cancel(meta));
    } else if (isCancelled()) {
      yield put(action.cancel(meta));
    }
  }
}

export default reduxExtendedRequestSaga;
