import get from 'lodash/get';
import head from 'lodash/head';
import React, { FunctionComponent } from 'react';
import { Col } from 'react-styled-flexboxgrid';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { ActionRow } from '@savgroup-front-common/core/src/atoms/ActionRow';
import { Card } from '@savgroup-front-common/core/src/atoms/Cards';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import {
  CheckIcon,
  CrossIcon,
  DownloadIcon,
} from '@savgroup-front-common/core/src/protons/icons';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { SUPPORTED_ICON_POSITIONS } from '@savgroup-front-common/core/src/helpers';
import { BannersConsumer } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import {
  FileShortSummary,
  ModuleSummary,
  SUPPORTED_COMPONENTS,
  TransitionSummary,
} from '@savgroup-front-common/types';

import {
  QuotationValidationHeader,
  QuotationValidationSummary,
  QuotationValidationTotalCosts,
  RejectQuotationModalContent,
} from './components';
import {
  useGetPendingCustomerValidationQuotation,
  useHandleValidateQuote,
  useQuotationValidation,
} from './hooks';
import messages from './messages';
import { printDocumentVoucher } from './QuotationValidation.helpers';
import {
  Modal,
  MODAL_SIZES,
} from '@savgroup-front-common/core/src/atoms/modal';

interface QuotationValidationProps {
  rejectTransitions: TransitionSummary[];
  file: FileShortSummary;
  currentModule: ModuleSummary;
  isLoading?: boolean;
  fromState: string;
}

const QuotationValidation: FunctionComponent<
  React.PropsWithChildren<QuotationValidationProps>
> = ({
  rejectTransitions,
  file,
  currentModule,
  isLoading = false,
  fromState,
}) => {
  const {
    onRejectQuoteButtonClick,
    isRejectModalOpen,
    onRejectModalClose,
    quoteDocuments,
  } = useQuotationValidation({ file });
  const {
    pendingCustomerValidationQuotation: quotation,
    isLoading: isGetQuotationLoading,
  } = useGetPendingCustomerValidationQuotation({
    fileId: file.fileId,
  });
  const { handleValidateQuote, handleRejectQuotation, isSubmitLoading } =
    useHandleValidateQuote({
      file,
      quoteId: quotation?.id,
      currentModule,
      fromState,
    });

  if (quotation === undefined || isGetQuotationLoading) {
    return (
      <Card>
        <BaseLoader />
      </Card>
    );
  }

  const {
    createdDate,
    totalDiscountIncludedTax,
    totalAmountToBePaid,
    preTaxTotalAmount,
    totalTax,
  } = quotation;

  return (
    <Card>
      <BannersConsumer />

      <QuotationValidationHeader createdDate={createdDate} />

      <QuotationValidationSummary quotation={quotation} />
      <Card colored>
        <QuotationValidationTotalCosts
          totalTax={totalTax}
          totalDiscountIncludedTax={totalDiscountIncludedTax}
          preTaxTotalAmount={preTaxTotalAmount}
          totalAmountToBePaid={totalAmountToBePaid}
        />
      </Card>

      <ActionRow>
        {head(quoteDocuments) && (
          <Col xs={12} sm>
            <Button
              type={BUTTON_TYPES.BUTTON}
              onClick={() => {
                printDocumentVoucher({
                  returnVoucherUrl: get(head(quoteDocuments), 'url') as string,
                });
              }}
              icon={<DownloadIcon />}
              position={SUPPORTED_ICON_POSITIONS.RIGHT}
              isLoading={isLoading}
              componentThemeName={SUPPORTED_COMPONENTS.FILE_DOWNLOADER_BUTTON}
              secondary
            />
          </Col>
        )}
        <Col xs={12} sm>
          <Modal
            onClose={onRejectModalClose}
            isOpen={isRejectModalOpen}
            size={MODAL_SIZES.MEDIUM}
          >
            <RejectQuotationModalContent
              onSubmit={async ({ selectedTransition }) => {
                await handleRejectQuotation({ selectedTransition });
              }}
              onClose={onRejectModalClose}
              transitions={rejectTransitions}
              module={currentModule}
            />
          </Modal>
          <Button
            secondary
            onClick={onRejectQuoteButtonClick}
            type={BUTTON_TYPES.BUTTON}
            icon={<CrossIcon />}
            isLoading={isLoading || isSubmitLoading}
          >
            <SafeFormattedMessageWithoutSpread message={messages.rejectQuote} />
          </Button>
        </Col>
        <Col xs={12} sm>
          <Button
            primary
            onClick={handleValidateQuote}
            type={BUTTON_TYPES.BUTTON}
            isLoading={isLoading || isSubmitLoading}
            icon={<CheckIcon />}
            dataTestId="approveQuoteButton"
          >
            <SafeFormattedMessageWithoutSpread
              message={messages.approveQuote}
            />
          </Button>
        </Col>
      </ActionRow>
    </Card>
  );
};

QuotationValidation.displayName = 'QuotationValidation';

export default QuotationValidation;
