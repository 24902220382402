import { useQuery } from 'react-query';

import { STALE_TIME } from '@savgroup-front-common/constants';

import { CommonPaymentService } from '../api';
import { useToasts } from '../molecules/NotificationsProvider';

export const useGetOwnerDeductibleAndAlreadyPaidAmount = ({
  fileId,
}: {
  fileId: string;
}) => {
  const { removeAllNotifications, pushErrors } = useToasts();

  const { data, isLoading } = useQuery(
    [
      'getOwnerDeductibleAndAlreadyPaidAmountQuery',
      {
        fileId,
      },
    ],
    async () => {
      removeAllNotifications();

      const response =
        await CommonPaymentService.getOwnerDeductibleAndAlreadyPaidAmountQuery({
          fileId,
        });

      if (response.failure) {
        pushErrors(response.errors);

        return undefined;
      }

      return response.value;
    },
    {
      staleTime: STALE_TIME.SECONDS_10,
    },
  );

  return {
    alreadyPaidAmountInPreviousQuotation:
      data?.alreadyPaidAmountInPreviousQuotation,
    deductibleAmountFromClaim: data?.deductibleAmountFromClaim,
    isLoading,
  };
};
