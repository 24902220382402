import { useQuery } from 'react-query';

import { ClaimService } from 'myaccount/api';
import { SolutionType } from 'myaccount/api/Claim/getSolutionTypeQuery';

export const GetMultipleSolutionTypeQuery = 'getMultipleSolutionTypeQuery';

export const useGetMultipleSolutionTypeQuery = ({
  solutionTypeIds,
}: {
  solutionTypeIds?: string[];
}) => {
  const { data: solutions } = useQuery(
    [GetMultipleSolutionTypeQuery, { solutionTypeIds }],
    async () => {
      if (!solutionTypeIds) {return undefined;}

      const solutionIdUsed: string[] = [];

      return Promise.all(
        solutionTypeIds?.map(async (solutionTypeId) => {
          if (!solutionIdUsed.includes(solutionTypeId)) {
            solutionIdUsed.push(solutionTypeId);
            const response = await ClaimService.getSolutionTypeQuery({
              solutionTypeId,
            });

            if (response.failure) {
              return undefined;
            }

            return response.value;
          }

          return undefined;
        }),
      ).then(
        (value) =>
          value.filter((solutionTypeId) => solutionTypeId) as SolutionType[],
      );
    },
  );

  return {
    solutions,
  };
};
