import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';

import { AdditionalInformationDto } from '@savgroup-front-common/types';

import {
  DIDACTICS_ADDITIONAL_INFORMATION_TYPES,
  INDEXING_STRATEGIES,
} from '../AdditionalInformation.types';
import { indexNeededInformation } from '../helpers/indexNeededInformation';

interface AdditionalInformation extends AdditionalInformationDto {
  internalId: string;
  claimIds: string[];
  fileUploadEndpoints?: {
    [key: string]: {
      uploadEndpoint: string;
      deleteEndpoint: string;
    };
  };
}

interface UseAdditionalInformationArgs {
  neededInformation: AdditionalInformation[];
  ignoreUniquenessByProduct?: boolean;
}

const useAdditionalInformation = ({
  neededInformation,
  ignoreUniquenessByProduct,
}: UseAdditionalInformationArgs) => {
  const { indexedNeededInformation } = sortBy(neededInformation, 'displayOrder')
    .filter(
      ({ type }) => !DIDACTICS_ADDITIONAL_INFORMATION_TYPES.includes(type),
    )
    .reduce<{
      counter: number;
      previousIsGrouped?: boolean;
      indexedNeededInformation: (AdditionalInformation & {
        index?: number;
        isEven: boolean;
      })[];
    }>(
      indexNeededInformation({
        strategy: ignoreUniquenessByProduct
          ? INDEXING_STRATEGIES.WITHOUT_UNIQUENESS_BY_PRODUCT
          : INDEXING_STRATEGIES.WITH_UNIQUENESS_BY_PRODUCT,
      }),
      {
        indexedNeededInformation: [],
        previousIsGrouped: false,
        counter: 0,
      },
    );

  const neededInformationGroupedById = groupBy(
    indexedNeededInformation,
    ({ id }) => id,
  );
  const neededInformationGroupedByIdValues = Object.entries(
    neededInformationGroupedById,
  );

  return {
    neededInformationGroupedByIdValues,
  };
};

export default useAdditionalInformation;
