import sortBy from 'lodash/sortBy';
import React, { useContext } from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { Grid, Row } from 'react-styled-flexboxgrid';
import { useTheme } from 'styled-components';

import { EmptyPlaceHolderWithIllustration } from '@savgroup-front-common/core/src/atoms/EmptyPlaceholder/EmptyPlaceHolderWithIllustration';
import {
  FormGroup,
  RadioButtonGroup,
} from '@savgroup-front-common/core/src/atoms/Form';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { RadioCardColumn } from '@savgroup-front-common/core/src/molecules/RadioCardColumn';
import { CloudErrorIcon } from '@savgroup-front-common/core/src/protons/icons';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';
import StepLayout from 'myaccount/view/app/NewLayout/StepLayout/StepLayout';
import { StepContext } from 'myaccount/view/app/NewLayout/StepsProvider/StepsProvider.context';

import { $ColSvgItem, $RowContainer } from '../../components/common/Container';

import { getColumn } from './IssuesPage.helpers';
import useIssuesPage from './IssuesPage.hooks';
import { $Col, $PlaceholderPosition } from './IssuesPage.styles';
import messages from './messages';

const IssuesPage: React.FC = () => {
  const theme = useTheme();
  const { values } = useContext(StepContext);
  const {
    issues,
    issuesCount,
    issuePageIsSkipped,
    isIssuesLoading,
    formContext,
    onSubmit,
    isSkipLoading,
  } = useIssuesPage({
    orders: values?.orders,
  });

  const { control } = formContext;

  if ((isIssuesLoading && !issuePageIsSkipped) || isSkipLoading) {
    return <BaseLoader />;
  }

  return (
    <FormProvider {...formContext}>
      <form
        onSubmit={(event) => {
          event.preventDefault();

          return undefined;
        }}
      >
        <StepLayout noConfirmButton isStepEmpty={issues.length === 0}>
          <FormGroup>
            {issues.length === 0 && (
              <$PlaceholderPosition>
                <EmptyPlaceHolderWithIllustration
                  illustration={<CloudErrorIcon />}
                  texts={[
                    messages.noIssuesFound,
                    messages.contactCustomerServices,
                  ]}
                />
              </$PlaceholderPosition>
            )}
            {issues.length > 0 && (
              <Controller
                control={control}
                name="choiceIssue"
                render={({ field }) => {
                  return (
                    <RadioButtonGroup name="choiceIssueRadioButtonGroup">
                      <Grid fluid>
                        <$RowContainer>
                          {sortBy(issues, ['displayOrder']).map((issue) => {
                            return (
                              <$Col
                                key={issue.name}
                                xs={12}
                                sm={6}
                                md={getColumn(issuesCount)}
                              >
                                <RadioCardColumn
                                  title={issue.name}
                                  didactics={issue.didactic}
                                  name={field.name}
                                  value={issue.id}
                                  isChecked={field.value === issue.id}
                                  onChange={field.onChange}
                                  onClick={() =>
                                    onSubmit({ choiceIssue: issue.id })
                                  }
                                  image={
                                    <Row center="xs">
                                      <$ColSvgItem xs={12}>
                                        <MyAccountIcon
                                          icon={issue.iconKey}
                                          color={
                                            field.value === issue.id
                                              ? theme.colors.primary
                                              : theme.colors.paragraphTextColor
                                          }
                                        />
                                      </$ColSvgItem>
                                    </Row>
                                  }
                                />
                              </$Col>
                            );
                          })}
                        </$RowContainer>
                      </Grid>
                    </RadioButtonGroup>
                  );
                }}
              />
            )}
          </FormGroup>
        </StepLayout>
      </form>
    </FormProvider>
  );
};

IssuesPage.displayName = 'IssuesPage';

export default IssuesPage;
