import { COMMON_STEPS } from '@savgroup-front-common/constants/src';
import { Step } from '@savgroup-front-common/core/src/molecules/StepsOrchestrator';

import { IssuesPage } from './steps/IssuesPage';
import { ReasonPage } from './steps/ReasonsPage';

export enum ISSUES_REASONS_STEP_NAMES {
  SELECT_ISSUE = 'selectIssue',
  SELECT_REASONS = 'selectReason',
}

const selectIssueStep: Step<Record<string, unknown>> = {
  name: ISSUES_REASONS_STEP_NAMES.SELECT_ISSUE,
  nextStep: ISSUES_REASONS_STEP_NAMES.SELECT_REASONS,
  Component: IssuesPage,
  message: ISSUES_REASONS_STEP_NAMES.SELECT_ISSUE,
};

const selectReasonStep: Step<Record<string, unknown>> = {
  name: ISSUES_REASONS_STEP_NAMES.SELECT_REASONS,
  nextStep: COMMON_STEPS.SUBMIT,
  Component: ReasonPage,
  message: ISSUES_REASONS_STEP_NAMES.SELECT_REASONS,
};

export const ISSUES_REASONS_STEPS = [selectIssueStep, selectReasonStep];
