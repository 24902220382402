import { DOCUMENT_TYPES } from '@savgroup-front-common/constants';
import { toast } from '@savgroup-front-common/core/src/atoms/Toast';
import { isBackResponseFailure } from '@savgroup-front-common/types';
import { CarrierService, ClaimService, DocumentService } from 'myaccount/api';

const useGetDocument = () => {
  const downloadFileWithUrl = ({
    data,
    name,
  }: {
    data?: Blob;
    name: string;
  }): void => {
    if (!data) {
      return;
    }

    const url = window.URL.createObjectURL(data);
    const a = document.createElement('a');

    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = name;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const handleActionDownloadDocument = async ({
    downloadType,
    tokenId,
    fileId,
    moduleDefinitionId,
    moduleInstanceId,
    fileName,
  }: {
    downloadType: string;
    tokenId?: string;
    fileId?: string;
    moduleDefinitionId?: string;
    moduleInstanceId?: string;
    fileName: string;
  }) => {
    let response;

    if (downloadType === DOCUMENT_TYPES.SHIPPING_LABEL) {
      if (!moduleDefinitionId || !moduleInstanceId || !fileId) {
        return undefined;
      }

      const responseUrl = await CarrierService.getCarrierLabelUrlQuery({
        fileId,
        moduleDefinitionId,
        moduleInstanceId,
      });

      if (isBackResponseFailure(responseUrl)) {
        toast.error(responseUrl.errors.at(0)?.message);

        return undefined;
      }

      const tokenId = responseUrl.value?.split('token=')[1];

      if (!tokenId) {
        return undefined;
      }

      response = await CarrierService.getCarrierLabelDocument({
        fileId,
        tokenId,
      });
    } else if (downloadType === DOCUMENT_TYPES.RETURN_VOUCHER) {
      if (!tokenId) {
        return undefined;
      }

      response = await DocumentService.downloadDocument({
        tokenId,
      });
    } else if (
      downloadType === DOCUMENT_TYPES.ADDITIONAL_INFORMATION_DOCUMENTS
    ) {
      if (!tokenId) {
        return undefined;
      }

      response = await DocumentService.downloadDocumentAdditionalInformation({
        tokenId,
      });
    } else {
      if (!tokenId || !fileName) {
        return undefined;
      }
      response = await ClaimService.getClaimDocument({ fileName, tokenId });
    }

    if (isBackResponseFailure(response)) {
      toast.error(response.errors.at(0)?.message);

      return undefined;
    }

    if (!isBackResponseFailure(response)) {
      downloadFileWithUrl({ data: response, name: fileName });
    }

    return response;
  };

  return { handleActionDownloadDocument };
};

export default useGetDocument;
