import React, { FunctionComponent } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { useIsNewUiEnabled } from '../../hooks/useIsNewUiEnabled';

import { VERTICAL_MENU_ANIMATION_DURATION } from './Menu.helpers';
import { $MenuContainer, $MenuTransition } from './Menu.styles';
import { MENU_POSITIONS } from './Menu.types';

interface MenuAnimatorProps {
  position?: MENU_POSITIONS | { top: string; left: string };
  dataTestId?: string;
  classNames: string;
  currentDepth: number;
  componentThemeName?: string;
}
const MenuAnimator: FunctionComponent<
  React.PropsWithChildren<MenuAnimatorProps>
> = ({
  position,
  dataTestId,
  classNames,
  currentDepth,
  children,
  componentThemeName,
}) => {
  const isNewUiEnabled = useIsNewUiEnabled();

  return (
    <$MenuContainer
      animationDuration={VERTICAL_MENU_ANIMATION_DURATION}
      $position={position}
    >
      <TransitionGroup
        component={null}
        childFactory={(child) => React.cloneElement(child, { classNames })}
      >
        <CSSTransition
          key={currentDepth}
          timeout={VERTICAL_MENU_ANIMATION_DURATION}
          unmountOnExit
          classNames={classNames}
        >
          {(animationState) => (
            <$MenuTransition
              animationDuration={VERTICAL_MENU_ANIMATION_DURATION}
              animationState={animationState}
              $position={position}
              data-testid={dataTestId}
              $componentThemeName={componentThemeName}
              $isNewUiEnabled={isNewUiEnabled}
            >
              {children}
            </$MenuTransition>
          )}
        </CSSTransition>
      </TransitionGroup>
    </$MenuContainer>
  );
};

MenuAnimator.displayName = 'MenuAnimator';

export default MenuAnimator;
