import { call, put, takeEvery } from 'redux-saga/effects';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import { callAndGetResponse } from '../../../services';

import { VERIFY_ADDRESS } from './actionTypes';

function* verifyAddressWorker({ payload }) {
  yield call(
    callAndGetResponse,
    VERIFY_ADDRESS,
    `${APIConfiguration.carrier}label/verify`,
    {
      method: SUPPORTED_METHODS.POST,
      json: {
        carrierCompany: payload.carrierCompany,
        address: payload.address,
      },
    },
  );
  yield put(VERIFY_ADDRESS.end());
}

export function* verifyAddressWatcher() {
  yield takeEvery(VERIFY_ADDRESS.BASE, verifyAddressWorker);
}
