import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.claim}claimGroups/${COMMON_QUERY_PARAMS.CLAIM_GROUP_ID}/confirmation`;

interface SetClaimConfirmationArgs {
  claimGroupId: string;
  payload: {
    phoneNumber?: string;
    mail?: string;
    swornStatement?: boolean;
    generalSalesCondition?: boolean;
    iMEI?: string;
    devicePassword?: string;
    serialNumber?: string;
    invoiceId?: string;
    sourceTokenId?: string;
    issueDate?: Date;
    origin?: string;
    ticketId?: string;
  };
}

const setClaimConfirmation = async ({
  claimGroupId,
  payload,
}: SetClaimConfirmationArgs): Promise<
  BackResponse<string> | BackResponseFailure
> => {
  const url = ENDPOINT.replace(
    COMMON_QUERY_PARAMS.CLAIM_GROUP_ID,
    claimGroupId,
  );

  try {
    const response = await apiCall<BackResponse<string>>(url, {
      method: SUPPORTED_METHODS.PUT,
      json: payload,
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default setClaimConfirmation;
