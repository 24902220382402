import head from 'lodash/head';
import moment from 'moment';
import React from 'react';
import { Controller, useFormContext, UseFormReturn } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';

import { DOCUMENT_TYPES } from '@savgroup-front-common/constants';
import {
  ADDITIONAL_INFORMATION_TYPES,
  MessageType,
} from '@savgroup-front-common/types';

import { CommonAttachmentService } from '../../api';
import { FloatInput, FormGroup, LegacyDatepicker } from '../../atoms/Form';
import { FieldMessages } from '../../atoms/Form/common/helpers/getFinalFieldState.types';
import { Tooltip } from '../../atoms/Tooltip';
import { ActionCreators as fileUploadActionCreators } from '../../domains/fileUpload';
import { hasFailureResponse } from '../../helpers';
import {
  InputHookForm,
  NumberInputHookForm,
  TextareaHookForm,
} from '../../molecules/Form';
import { InformationIcon } from '../../protons/icons';

import { $ToolTipContainer } from './AdditionalInformation.styles';
import AdditionalInformationEnum from './AdditionalInformationEnum';
import AdditionalInformationFileInput from './AdditionalInformationFileInput';
import { buildInputName } from './AdditionalInformationInput.helpers';
import { $ToolTipFormatter } from './AdditionalInformationInput.styles';
import { AdditionalInformationMultiFileInput } from './AdditionalInformationMultiFileInput/AdditionalInformationMultiFileInput';

interface AdditionalInformationInputProps {
  id: string;
  type: string;
  possibleValues?: Array<{ id: string; key: string; name: string }>;
  isRequired?: boolean;
  label?: MessageType | string;
  placeholder?: string | undefined;
  description?: string | undefined;
  formContext?: UseFormReturn & { warnings?: FieldMessages };
  fileUploadEndpoints?:
    | Record<
        string,
        {
          uploadEndpoint: string;
          deleteEndpoint: string;
        }
      >
    | {
        uploadEndpoint: string;
        deleteEndpoint: string;
      };
  isLiveUpload?: boolean;
  prefixFormName?: string | undefined;
  onAdditionalInformationSave: () => Promise<void>;
  isNewDesign?: boolean;
  isScrollIntoView?: boolean;
  validatingRegex?: string;
  importByQrCode?: boolean;
  claimIds?: string[];
  documentType?: DOCUMENT_TYPES;
  shouldAllowQrCode?: boolean;
}

export const AdditionalInformationInput: React.FC<
  AdditionalInformationInputProps
> = ({
  id,
  type,
  possibleValues = [],
  isRequired = false,
  label = undefined,
  placeholder = undefined,
  description = undefined,
  formContext = undefined,
  fileUploadEndpoints = {},
  isLiveUpload = false,
  prefixFormName = undefined,
  onAdditionalInformationSave,
  isNewDesign = false,
  isScrollIntoView = false,
  validatingRegex,
  importByQrCode = false,
  claimIds = [],
  documentType,
  shouldAllowQrCode,
}) => {
  const defaultContext = useFormContext();

  const theme = useTheme();

  const {
    control,
    formState: { errors },
    register,
    setValue,
    trigger,
  } = formContext || defaultContext;
  const warnings = formContext?.warnings || {};

  const dispatch = useDispatch();
  const name = buildInputName({ prefixFormName, name: id });

  const postLabel = description && (
    <$ToolTipContainer>
      <Tooltip
        position="top"
        arrow
        hideDelay={500}
        html={<$ToolTipFormatter>{description}</$ToolTipFormatter>}
      >
        <InformationIcon color={theme.colors.primary} size="20px" />
      </Tooltip>
    </$ToolTipContainer>
  );

  switch (type) {
    case ADDITIONAL_INFORMATION_TYPES.IRIS_SYMPTOM_CODE:
    case ADDITIONAL_INFORMATION_TYPES.IRIS_SECTION_CODE:
    case ADDITIONAL_INFORMATION_TYPES.IRIS_REPAIR_CODE:
    case ADDITIONAL_INFORMATION_TYPES.IRIS_EXTENDED_CONDITION_CODE:
    case ADDITIONAL_INFORMATION_TYPES.IRIS_DEFECT_CODE:
    case ADDITIONAL_INFORMATION_TYPES.IRIS_CONDITION_CODE:
    case ADDITIONAL_INFORMATION_TYPES.ENUM: {
      return (
        <FormGroup>
          <AdditionalInformationEnum
            id={id}
            name={name}
            label={label}
            isRequired={isRequired}
            postLabel={postLabel}
            placeholder={placeholder}
            possibleValues={possibleValues}
            formContext={formContext}
            onAdditionalInformationSave={onAdditionalInformationSave}
            isScrollIntoView={isScrollIntoView}
          />
        </FormGroup>
      );
    }
    case ADDITIONAL_INFORMATION_TYPES.DATE: {
      return (
        <FormGroup>
          <Controller
            control={control}
            name={name}
            render={({ field }) => (
              <LegacyDatepicker
                ref={field.ref}
                errors={errors}
                warnings={warnings}
                isRequired={isRequired}
                placeholder={placeholder}
                label={label}
                name={field.name}
                postLabel={postLabel}
                onChange={field.onChange}
                value={moment(field.value)}
                onBlur={async () => {
                  field.onBlur();
                  await onAdditionalInformationSave();
                }}
                dataTestId={`additional-information-input-datepicker-${id}`}
              />
            )}
            defaultValue={null}
          />
        </FormGroup>
      );
    }
    case ADDITIONAL_INFORMATION_TYPES.INTEGER: {
      return (
        <FormGroup>
          <NumberInputHookForm
            errors={errors}
            warnings={warnings}
            name={name}
            isRequired={isRequired}
            placeholder={placeholder}
            label={label}
            postLabel={postLabel}
            onBlur={async () => {
              await onAdditionalInformationSave();
            }}
            register={register}
            dataTestId={`additional-information-input-integer-${id}`}
            isScrollIntoView={isScrollIntoView}
          />
        </FormGroup>
      );
    }
    case ADDITIONAL_INFORMATION_TYPES.DECIMAL: {
      return (
        <FormGroup>
          <Controller
            name={name}
            control={control}
            render={({ field }) => {
              return (
                <FloatInput
                  ref={field.ref}
                  errors={errors}
                  warnings={warnings}
                  name={name}
                  isRequired={isRequired}
                  placeholder={placeholder}
                  label={label}
                  postLabel={postLabel}
                  onBlur={async () => {
                    await onAdditionalInformationSave();
                  }}
                  value={field.value}
                  onChange={field.onChange}
                  dataTestId={`additional-information-input-decimal-${id}`}
                />
              );
            }}
          />
        </FormGroup>
      );
    }
    case ADDITIONAL_INFORMATION_TYPES.IMEI: {
      return (
        <FormGroup>
          <InputHookForm
            errors={errors}
            warnings={warnings}
            name={name}
            isRequired={isRequired}
            placeholder={placeholder}
            label={label}
            postLabel={postLabel}
            dataTestId={`additional-information-input-imei-${id}`}
            onBlur={async () => {
              await onAdditionalInformationSave();
            }}
            register={register}
            isScrollIntoView={isScrollIntoView}
          />
        </FormGroup>
      );
    }
    case ADDITIONAL_INFORMATION_TYPES.MULTI_FILES: {
      return (
        <FormGroup>
          <Controller
            control={control}
            name={name}
            defaultValue={null}
            render={({ field }) => {
              return (
                <AdditionalInformationMultiFileInput
                  name={name}
                  label={label}
                  postLabel={postLabel}
                  errors={errors}
                  isRequired={isRequired}
                  additionalInformationId={id}
                  claimIds={claimIds}
                  documentType={documentType}
                  onChange={async (files) => {
                    if (isLiveUpload) {
                      if (!('uploadEndpoint' in fileUploadEndpoints)) {
                        const responses = await Promise.all(
                          Object.values(fileUploadEndpoints).map(
                            (fileUploadEndpoint) => {
                              return CommonAttachmentService.uploadAttachmentWithProgress(
                                {
                                  files,
                                  endpoint: fileUploadEndpoint.uploadEndpoint,
                                  notify: ({ progress }) => {
                                    setValue(
                                      name,
                                      files?.map((file, index) => {
                                        return {
                                          ...(field.value[index] || {}),
                                          progress,
                                          value: file,
                                        };
                                      }),
                                    );

                                    trigger(name);
                                  },
                                },
                              );
                            },
                          ),
                        );

                        if (responses.some(hasFailureResponse)) {
                          const response = responses.find(hasFailureResponse);

                          setValue(
                            name,
                            files?.map((file, index) => {
                              return {
                                ...(field.value[index] || {}),
                                errors: response?.errors,
                                value: file,
                              };
                            }),
                          );

                          await trigger(name);
                        } else {
                          const response = responses[0];

                          setValue(
                            name,
                            files?.map((file, index) => {
                              return {
                                ...(field.value[index] || {}),
                                ...((response as any).value || {}),
                                progress: 100,
                                value: file,
                              };
                            }),
                          );
                        }
                      } else {
                        const response =
                          await CommonAttachmentService.uploadAttachmentWithProgress(
                            {
                              files,
                              endpoint:
                                fileUploadEndpoints.uploadEndpoint as string,
                              notify: ({ progress }) => {
                                setValue(
                                  name,
                                  files?.map((file, index) => {
                                    return {
                                      ...(field.value[index] || {}),
                                      progress,
                                      value: file,
                                    };
                                  }),
                                );
                              },
                            },
                          );

                        if (response.failure) {
                          setValue(
                            name,
                            files?.map((file, index) => {
                              return {
                                ...(field.value[index] || {}),
                                value: file,
                                errors: response.errors,
                              };
                            }),
                          );
                        } else {
                          setValue(
                            name,
                            files?.map((file, index) => {
                              return {
                                ...(field.value[index] || {}),
                                ...((response as any).value || {}),
                                progress: 100,
                                value: file,
                              };
                            }),
                          );
                        }

                        await trigger(name);
                      }

                      return;
                    }

                    const allFiles = (field.value || []).concat(
                      files?.map((file, index) => ({
                        ...(field.value?.at(index) || {}),
                        value: file,
                      })),
                    );

                    field.onChange(allFiles);
                  }}
                  onRemove={(_file, index) => {
                    if (field.value.errors) {
                      field.onChange(null);

                      return;
                    }

                    if (isLiveUpload) {
                      if (!('uploadEndpoint' in fileUploadEndpoints)) {
                        Object.values(fileUploadEndpoints).map(
                          (fileUploadEndpoint) => {
                            return dispatch(
                              fileUploadActionCreators.deleteFile({
                                endpoint: fileUploadEndpoint.deleteEndpoint,
                                indexer: id,
                                blobValue:
                                  field.value?.additionalInformationStringValue,
                              }),
                            );
                          },
                        );
                      } else {
                        dispatch(
                          fileUploadActionCreators.deleteFile({
                            endpoint: fileUploadEndpoints.deleteEndpoint,
                            indexer: id,
                            blobValue:
                              field.value?.additionalInformationStringValue,
                          }),
                        );
                      }
                    }

                    field.onChange(
                      field.value?.filter((_: any, i: number) => i !== index) ??
                        [],
                    );
                  }}
                  value={field.value}
                  isLiveUpload={isLiveUpload}
                  isNewDesign={isNewDesign}
                  dataTestId={`additional-information-input-fileupload-${id}`}
                  isImportByQrCode={shouldAllowQrCode ? importByQrCode : false}
                />
              );
            }}
          />
        </FormGroup>
      );
    }
    case ADDITIONAL_INFORMATION_TYPES.FILE: {
      return (
        <FormGroup>
          <Controller
            control={control}
            name={name}
            defaultValue={null}
            render={({ field }) => {
              return (
                <AdditionalInformationFileInput
                  name={name}
                  label={label}
                  postLabel={postLabel}
                  errors={errors}
                  isRequired={isRequired}
                  additionalInformationId={id}
                  claimIds={claimIds}
                  documentType={documentType}
                  onChange={async (files) => {
                    const file = head(files);

                    if (files?.length !== 1) {
                      return;
                    }

                    if (isLiveUpload) {
                      if (!('uploadEndpoint' in fileUploadEndpoints)) {
                        const responses = await Promise.all(
                          Object.values(fileUploadEndpoints).map(
                            (fileUploadEndpoint) => {
                              return CommonAttachmentService.uploadAttachmentWithProgress(
                                {
                                  file,
                                  endpoint: fileUploadEndpoint.uploadEndpoint,
                                  notify: ({ progress }) => {
                                    setValue(name, {
                                      ...(field.value || {}),
                                      progress,
                                      value: file,
                                    });
                                    trigger(name);
                                  },
                                },
                              );
                            },
                          ),
                        );

                        if (responses.some(hasFailureResponse)) {
                          const response = responses.find(hasFailureResponse);

                          setValue(name, {
                            ...(field.value || {}),
                            errors: (response as any).errors,
                            value: file,
                          });
                          await trigger(name);
                        } else {
                          const response = responses[0];

                          setValue(name, {
                            ...(field.value || {}),
                            ...((response as any).value || {}),
                            progress: 100,
                            value: file,
                          });
                        }
                      } else {
                        const response =
                          await CommonAttachmentService.uploadAttachmentWithProgress(
                            {
                              file,
                              endpoint:
                                fileUploadEndpoints.uploadEndpoint as string,
                              notify: ({ progress }) => {
                                setValue(name, {
                                  ...(field.value || {}),
                                  progress,
                                  value: file,
                                });
                              },
                            },
                          );

                        if (response.failure) {
                          setValue(name, {
                            ...(field.value || {}),
                            value: file,
                            errors: response.errors,
                          });
                        } else {
                          setValue(name, {
                            ...(field.value || {}),
                            ...((response as any).value || {}),
                            progress: 100,
                            value: file,
                          });
                        }

                        await trigger(name);
                      }

                      return;
                    }

                    field.onChange({ ...(field.value || {}), value: file });
                  }}
                  onRemove={() => {
                    if (field.value.errors) {
                      field.onChange(null);

                      return;
                    }

                    if (!('uploadEndpoint' in fileUploadEndpoints)) {
                      Object.values(fileUploadEndpoints).map(
                        (fileUploadEndpoint) => {
                          return dispatch(
                            fileUploadActionCreators.deleteFile({
                              endpoint: fileUploadEndpoint.deleteEndpoint,
                              indexer: id,
                              blobValue:
                                field.value?.additionalInformationStringValue,
                            }),
                          );
                        },
                      );
                    } else {
                      dispatch(
                        fileUploadActionCreators.deleteFile({
                          endpoint: fileUploadEndpoints.deleteEndpoint,
                          indexer: id,
                          blobValue:
                            field.value?.additionalInformationStringValue,
                        }),
                      );
                    }

                    field.onChange(null);
                  }}
                  value={field.value}
                  isLiveUpload={isLiveUpload}
                  isNewDesign={isNewDesign}
                  dataTestId={`additional-information-input-fileupload-${id}`}
                  isImportByQrCode={shouldAllowQrCode ? importByQrCode : false}
                />
              );
            }}
          />
        </FormGroup>
      );
    }
    case ADDITIONAL_INFORMATION_TYPES.TEXT_AREA: {
      return (
        <FormGroup>
          <TextareaHookForm
            label={label}
            postLabel={postLabel}
            name={name}
            errors={errors}
            warnings={warnings}
            isRequired={isRequired}
            autoHeight
            onBlur={async () => {
              await onAdditionalInformationSave();
            }}
            register={register}
            isScrollIntoView={isScrollIntoView}
            dataTestId={`additional-information-input-textArea-${id}`}
          />
        </FormGroup>
      );
    }
    default: {
      return (
        <FormGroup>
          <InputHookForm
            errors={errors}
            warnings={warnings}
            isRequired={isRequired}
            placeholder={placeholder}
            label={label}
            name={name}
            postLabel={postLabel}
            onBlur={async () => {
              await onAdditionalInformationSave();
            }}
            dataTestId={`additional-information-input-input-${id}`}
            register={register}
            trigger={trigger}
            isScrollIntoView={isScrollIntoView}
            validatingRegex={validatingRegex}
          />
        </FormGroup>
      );
    }
  }
};

AdditionalInformationInput.displayName = 'AdditionalInformationInput';
