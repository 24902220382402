import React, { ChangeEventHandler, FunctionComponent } from 'react';

import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';

import {
  $AgreementText,
  StyledCheckbox,
  StyledLink,
} from './AgreementCheckbox.styles';
import messages from './messages';

interface AgreementCheckboxProps {
  isChecked: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  agreementHref: string;
}

const AgreementCheckbox: FunctionComponent<
  React.PropsWithChildren<AgreementCheckboxProps>
> = ({ isChecked, onChange, agreementHref }) => {
  return (
    <StyledCheckbox
      name="agreement"
      checked={isChecked}
      onChange={onChange}
      label={
        <$AgreementText>
          <SafeFormattedMessageWithoutSpread
            message={messages.termsAndConditions}
          />{' '}
          <StyledLink
            href={agreementHref}
            target="_blank"
            rel="noopener noreferrer"
          >
            <SafeFormattedMessageWithoutSpread
              message={messages.goToConditions}
            />
          </StyledLink>
        </$AgreementText>
      }
    />
  );
};

AgreementCheckbox.displayName = 'AgreementCheckbox';

export default AgreementCheckbox;
