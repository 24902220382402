import { ReturnEligibility } from '../../../../../../api/Owner/startOrContinueBusinessReturnCommand';

export const useIsOrderLineEligible = ({
  orderLineReference,
  eligibility,
}: {
  orderLineReference: string;
  eligibility: ReturnEligibility;
}) => {
  return !eligibility.errors.some(
    (error) => error.orderLineReference === orderLineReference,
  );
};
