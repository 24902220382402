import { createRequest } from '../../services/request';

export const SAVE_DEVICE_PASSWORD = createRequest('SAVE_DEVICE_PASSWORD');
export const RESET_PAYMENT_FORM_ERRORS = 'RESET_PAYMENT_FORM_ERRORS';
export const PAY_INVOICE = createRequest('PAY_INVOICE');
export const SAVE_EXPECTS = createRequest('SAVE_EXPECTS');
export const SET_PICKUP_APPOINTMENT = createRequest(
  'REVERS.IO/SET_PICKUP_APPOINTMENT',
);

export const USE_MY_OWN_CARRIER = createRequest('WORKFLOW/USE_MY_OWN_CARRIER');
export const LOAD_FILE_HANDLING = createRequest('LOAD_FILE_HANDLING');
export const FORCE_CLOSE_FILE = createRequest('FORCE_CLOSE_FILE');
export const GET_FILE_ACTIONS = createRequest('WORKFLOW/GET_USER_ACTIONS');

export const SET_SYNC_USER_ACTION = createRequest(
  'WORKFLOW/SET_SYNC_USER_ACTION',
);
export const SET_USER_ACTION_AND_HYDRATE_FILE_SUMMARY = createRequest(
  'WORKFLOW/SET_USER_ACTION_AND_HYDRATE_FILE_SUMMARY',
);
export const SET_USER_ACTIONS_IS_EMPTY = createRequest(
  'WORKFLOW/SET_USER_ACTIONS_IS_EMPTY',
);

export const EXPLODE_FILE = createRequest('WORKFLOW/EXPLODE_FILE');
export const EXPLODE_FILE_ALONG_SOLUTIONS = createRequest(
  'WORKFLOW/EXPLODE_FILE_ALONG_SOLUTIONS',
);
export const LOAD_OWNER_PRODUCT_FILES = createRequest(
  'WORKFLOW/LOAD_OWNER_PRODUCT_FILES',
);

export const SET_COMMERCIAL_SOLUTION = createRequest(
  'WORKFLOW/SET_COMMERCIAL_SOLUTION',
);

export const SPLIT_FILE = createRequest('WORKFLOW/SPLIT_FILE');
export const REFRESH_FILE_HANDLER = createRequest(
  'WORKFLOW/REFRESH_FILE_HANDLER',
);
export const CLEAR_SPLIT_FILE_STATE = 'WORKFLOW/CLEAR_SPLIT_FILE_STATE';

export const UPDATE_EXPLODE_FILE_STATE = 'WORKFLOW/UPDATE_EXPLODE_FILE_STATE';
