import { Col } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { mediaQuery } from '@savgroup-front-common/core/src/grid/helpers';

export const $DeliveredSparePartIconAndText = styled.div`
  display: flex;
  align-items: center;
`;

export const $DeliveredSparePartIconAndTextMessage = styled.span`
  margin-left: 1rem;
  margin-right: 1rem;
`;

export const $DeliveredSparePartColWithPaddingTopForXs = styled(Col)`
  ${mediaQuery.xs`
        padding-top: 1em;
    `}
  ${mediaQuery.sm`
        padding-top: 0;
    `}
`;

export const $DeliveredSparePartColAlignCenter = styled(Col)`
  display: flex;
  align-items: center;
  min-width: 175px;
  color: ${({ theme }) => theme.colors.success};

  ${mediaQuery.xs`
    justify-content: center;
    margin-top: .75rem;
    width: 100%;
  `}

  ${mediaQuery.sm`
    justify-content: center;
    margin-top: 0;
    width: auto;
  `}
`;

export const $DeliveredSparePartActionButtonWrapper = styled(Col)`
  display: flex;
  justify-content: flex-end;
  min-width: 175px;

  ${mediaQuery.xs`
    justify-content: center;
    margin-top: .75rem;
  `}
`;

export const $DeliveredSparePartActionButton = styled(Button)`
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.lightGrey : theme.colors.primary};
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.black : theme.colors.white};
  padding: 0.5rem 2rem;
  height: auto;

  &:active,
  &:focus,
  &:active:hover,
  &:hover {
    background-color: ${({ theme, disabled }) =>
      disabled ? theme.colors.lightGrey : theme.colors.primaryHover};
    color: ${({ theme, disabled }) =>
      disabled ? theme.colors.black : theme.colors.white};
  }

  ${mediaQuery.xs`
    width: 100%;
  `}
`;
