// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import convert from 'heic-convert/browser';

import { MIME_TYPES } from '@savgroup-front-common/constants';

export class ImageConverter {
  static heiToJpg = async (file: File) => {
    const isHeif = file.name.includes('.heif');
    const isHeic = file.name.includes('.heic');

    if (isHeif || isHeic) {
      const output = await convert({
        buffer: new Uint8Array(await file.arrayBuffer()),
        format: 'JPEG',
      });

      return new File(
        [output],
        file.name.replace(isHeif ? '.heif' : '.heic', '.jpg'),
        {
          type: MIME_TYPES.JPEG,
        },
      );
    }

    return file;
  };
}
