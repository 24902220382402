import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  FileShortSummary,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.workflow}owners/${COMMON_QUERY_PARAMS.OWNER_ID}/files/opened`;

interface GetOpenedFilesQueryPayload {
  ownerId: string;
  orderId?: string;
}

type GetOpenedFilesQueryResponse = FileShortSummary[];

type GetOpenedFilesQuery = (
  payload: GetOpenedFilesQueryPayload,
) => Promise<BackResponse<GetOpenedFilesQueryResponse> | BackResponseFailure>;

const getOpenedFilesQuery: GetOpenedFilesQuery = async ({
  ownerId,
  orderId,
}) => {
  try {
    return await apiCall<BackResponse<GetOpenedFilesQueryResponse>>(
      buildUrl(ENDPOINT.replace(COMMON_QUERY_PARAMS.OWNER_ID, ownerId), {
        orderId,
      }),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default getOpenedFilesQuery;
