import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { COUNTRY_CODES } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  PickupPoint,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.carrier}pickuppoints/coord`;

export interface GetPickupPointsArgs {
  countryCode: COUNTRY_CODES | string;
  address: string;
  sellerId?: string;
  productType: string;
  carrierCompany: string;
  carrierName: string;
  distance?: number;
  maxPickupPoint?: number;
  claimId: string;
}

const getPickupPoints = async ({
  countryCode,
  address,
  sellerId,
  productType,
  carrierCompany,
  distance = 40,
  maxPickupPoint = 10,
  claimId,
}: GetPickupPointsArgs): Promise<
  BackResponse<PickupPoint[]> | BackResponseFailure
> => {
  try {
    const payload = {
      CountryCode: countryCode,
      carrierCompany:
        carrierCompany === 'chrono' ? 'Chronopost' : carrierCompany,
      distance,
      maxPickupPoint,
      productType,
      sellerId,
      address,
      claimId,
    };

    const parameters = pickBy(payload, identity);
    const query = buildUrl(ENDPOINT, parameters);

    const response = await apiCall<BackResponse<PickupPoint[]>>(query, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default getPickupPoints;
