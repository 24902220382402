import * as Yup from 'yup';

import { AdditionalInformationSchema } from '@savgroup-front-common/core/src/components/AdditionalInformationWithReactHookForm';
import { globalMessages } from '@savgroup-front-common/core/src/helpers';
import { Reason } from 'myaccount/types';

import { neededInformationFiltered } from './helpers/reason.adapters';
import messages from './messages';

const claimGroupReasonSchema = ({
  reasonSelected,
  isMultiProduct,
}: {
  reasonSelected: Reason | undefined;
  isMultiProduct: boolean;
}) => {
  const neededInformationFilteredResult = neededInformationFiltered(
    reasonSelected?.neededInformation,
    isMultiProduct,
  );

  const reasonAdditionalInformationSchema = AdditionalInformationSchema({
    neededInformationList: neededInformationFilteredResult,
  });

  return Yup.object().shape({
    reason: Yup.object()
      .nullable()
      .test(
        'required-reason',
        messages.reasonIsRequired,
        function validateRequiredReason(option) {
          if (!option) {
            return false;
          }

          return option.value !== null && option.value !== undefined;
        },
      )
      .required(messages.reasonIsRequired),
    reasonAdditionalInformation: reasonAdditionalInformationSchema,
    reasonComment: reasonSelected?.mandatoryComment
      ? Yup.string().nullable().required(globalMessages.form.required)
      : Yup.string().nullable(),
  });
};

export default claimGroupReasonSchema;
