import { useQuery } from 'react-query';

import { STALE_TIME } from '@savgroup-front-common/constants';
import { CommonCarrierService } from '@savgroup-front-common/core/src/api';
import { CarrierProductSummary } from '@savgroup-front-common/core/src/api/Carrier/getCarrierProductByIdQuery';

const GetMultiCarrierProductById = 'getMultiCarrierProductById';

const useGetMultiCarrierProductByIdQuery = ({
  carrierProductIds,
}: {
  carrierProductIds?: string[];
}) => {
  const { data: multiCarrierProductSummary } = useQuery(
    [GetMultiCarrierProductById, { carrierProductIds }],
    async () => {
      if (!carrierProductIds) {
        return undefined;
      }

      return Promise.all(
        carrierProductIds?.map(async (carrierProductId) => {
          const response =
            await CommonCarrierService.getCarrierProductByIdQuery({
              carrierProductId,
            });

          if (response.failure) {
            return undefined;
          }

          return response.value;
        }),
      ).then(
        (value) =>
          value.filter(
            (carrierProductId) => carrierProductId,
          ) as CarrierProductSummary[],
      );
    },
    {
      staleTime: STALE_TIME.MINUTES_10,
    },
  );

  return {
    multiCarrierProductSummary,
  };
};

export default useGetMultiCarrierProductByIdQuery;
