/* eslint-disable no-console */
import { fromJS } from 'immutable';
import get from 'lodash/get';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import { ActionMeta } from 'redux-actions';

import { ClaimsState } from '../types';

interface SolutionMeta {
  claimId: string;
}

export function onSolutionsLoading(
  state: ClaimsState,
  { meta }: ActionMeta<any, SolutionMeta>,
): ClaimsState {
  const claimId = get(meta, ['claimId']);

  if (!claimId) {
    console.warn('[onSolutionsLoading] ClaimId is undefined');

    return state;
  }

  return state.setIn(
    ['solutions', claimId],
    fromJS({ isLoaded: false, isDirty: false, value: undefined }),
  );
}

export function onSolutionsLoadingErrored(
  state: ClaimsState,
  { meta }: ActionMeta<any, SolutionMeta>,
): ClaimsState {
  const claimId = get(meta, ['claimId']);

  if (!claimId) {
    console.warn('[onSolutionsLoadingErrored] ClaimId is undefined');

    return state;
  }

  // TODO errors
  return state.updateIn(['solutions', claimId], (oldValue) =>
    oldValue.setIn(['isDirty'], true).setIn(['hasErrors'], true),
  );
}

interface OnSolutionsLoadedPayload {
  value: {
    solutions: [
      {
        solutionTypeId: string;
      },
    ];
    selectedWarrantyId: string;
  };
}

export function onSolutionsLoaded(
  state: ClaimsState,
  { payload, meta }: ActionMeta<OnSolutionsLoadedPayload, SolutionMeta>,
): ClaimsState {
  const claimId = get(meta, ['claimId']);
  const solutions = map(
    orderBy(get(payload, ['value', 'solutions']), ['name'], ['asc']),
    (solution) => ({
      id: solution.solutionTypeId,
      ...solution,
    }),
  );
  const selectedWarrantyId = get(payload, ['value', 'selectedWarrantyId']);

  if (!claimId) {
    console.warn('[onSolutionsLoaded] ClaimId is undefined.');

    return state;
  }

  return state.setIn(
    ['solutions', claimId],
    fromJS({ isLoaded: true, isDirty: false })
      .setIn(['selectedWarrantyId'], selectedWarrantyId)
      .setIn(['value'], solutions),
  );
}

interface OnSolutionSelectedPayload {
  value: {
    claimId: string;
  };
}

export function onSolutionSelected(
  state: ClaimsState,
  { payload, meta }: ActionMeta<OnSolutionSelectedPayload, SolutionMeta>,
): ClaimsState {
  const claimId = get(meta, ['claimId']);
  const claim = get(payload, ['value']);

  if (!claimId) {
    console.warn('[onSolutionSelected] ClaimId is undefined.');

    return state;
  }

  return state.updateIn(['claims', claimId], fromJS({}), (oldValue) =>
    fromJS({ isLoaded: true, isDirty: false }).setIn(['value'], {
      ...oldValue.get('value'),
      ...claim,
    }),
  );
}
