import { CURRENCIES } from '@savgroup-front-common/constants';

import { MARKETING_OFFER_TYPE } from './CommercialSolution';
import { ProductStates } from './Order';
import { ModelDimension } from './SparePartCatalog';

export interface ProductHistory {
  createdDate: string;
  fileId: string;
  fileReference: string;
  isHistoricalFile: boolean;
  issueId: string;
  reasonId: string;
  stateDate: string;
  stateId: string;
  stateName: string;
  stateShortLabel: string;
  warrantyTypeId: string;
}

export enum ProductType {
  SINGLE_PRODUCT = 'SingleProduct',
  BATCH = 'Batch',
}

export interface Product {
  sellerProductId: string;
  orderLineReference: string;
  modelTypeId: string;
  modelTypeName: string;
  brandName: string;
  modelName: string;
  price: number;
  shippingPrice: number;
  soldWarrantyPrice: number;
  priceCurrencyCode: CURRENCIES;
  state: ProductStates;
  notOpenForClaims: boolean;
  modelId: string;
  brandId: string;
  supplierId: string;
  externalIdentifier: string;
  soldWarrantyId?: string;
  ownerProductId: string;
  ownerId?: string;
  modelDimensions?: ModelDimension;
  ean: string;
  weight?: number;
  sku: string;
  productHistory?: ProductHistory[];
  orderProductAdditionalInfo?: {
    uniqueKey: string;
    value: string;
  }[];
  claimId?: string;
  claimGroupId?: string;
  isAvailableForClaims: boolean;
  outcomeHistory?: OutcomeHistory[];
  orderDeliveryDateUtc: string;
  noWarranty?: boolean;
  orderProductType?: ProductType;
  batchQuantity?: number;
  brandDisplayName: string;
  modelDisplayName: string;
}

export interface GroupedProduct {
  brandId: string;
  brandName: string;
  ean: string;
  externalIdentifier: string;
  modelId: string;
  modelName: string;
  modelTypeId: string;
  modelTypeName: string;
  notOpenForClaims: boolean;
  orderLineReference: string;
  orderLineReferences: string[];
  ownerProductId: string;
  price: number;
  priceCurrencyCode: CURRENCIES;
  sellerProductId: string;
  shippingPrice: number;
  sku: string;
  soldWarrantyPrice: number;
  state: ProductStates;
  supplierId: string;
  productHistory?: ProductHistory[];
  isAvailableForClaims: boolean;
}

export interface ProductToReturn {
  quantity?: number;
  ownerProductId?: string | string[];
  unitPrice?: string;
  orderLineReference?: string;
  orderLineReferences?: string[];
  quantityToReturn?: number;

  model?: { value: string } | string;
  modelId?: string;
  modelName?: string;
  modelTypeId?: string;
  modelTypeName?: string;
  sellerProductId?: string;
  isMutable?: boolean;
  internalId?: string;
  modelDisplayName: string;
  modelTypeDisplayName: string;
  brandDisplayName: string;
}

export enum PRODUCT_OUTCOME {
  NOT_SET = 'NotSet',
  SWAPPED = 'Swapped',
  REFUNDED = 'Refunded',
  REPAIRED = 'Repaired',
  CANCELLED = 'Cancelled',
}

export interface OutcomeHistory {
  fileId: string;
  externalFileId: string;
  outcome: PRODUCT_OUTCOME;
  outcomeType: MARKETING_OFFER_TYPE;
  date: string;
  downtime: number;
  interventionReportDateUTC: Date;
  irisCode: {
    conditionCode: string;
    symptomCode: string;
    sectionCode: string;
    defectCode: string;
    repairCode: string;
    extendedConditionCode: string;
  };
}

export interface ProductInformation {
  brandId: string;
  brandName: string;
  ean: string;
  modelId: string;
  modelName: string;
  modelTypeId: string;
  modelTypeName: string;
  price: number;
  priceCurrencyCode: CURRENCIES;
  ownerProductId: string;
  sellerProductId: string;
  shippingPrice: number;
  sku: string;
  soldWarrantyPrice: number;
  state: string;
  supplierId: string;
  productHistory?: ProductHistory[];
  quantity?: number;
  internalId?: string;
  weight: number;
  modelDimensions: ModelDimension;
  outcomeHistory?: OutcomeHistory[];
  isDisabled?: boolean;
  modelDisplayName: string;
  brandDisplayName: string;
}

export interface ProductOrientation {
  id: string;
  name: string;
  uniqueName: string;
  productOutcome: string;
  orderDisplay: number;
  isPresetDefaultValue: boolean;
}

export interface SoldServices {
  orderLineReference: string;
  name: string;
  sku: string;
  price: number;
  priceCurrencyCode: CURRENCIES;
  soldServiceType?: string;
}

export enum OrderDelivery {
  NOT_SET = 'NotSet',
  HOME_DELIVERY = 'HomeDelivery',
  SCHEDULED_DOOR_STEP_DELIVERY = 'ScheduledDoorstepDelivery',
  SCHEDULED_IN_HOME_DELIVERY = 'ScheduledInHomeDelivery',
  IN_STORE_PICKUP_DELIVERY = 'InStorePickUpDelivery',
  LOCAL_PICKUP_DELIVERY = 'LocalPickUpDelivery',
  CLICK_AND_COLLECT = 'ClickAndCollect',
  RESERVE_AND_COLLECT = 'ReserveAndCollect',
}

export enum ModelState {
  UNDEFINED = 'Undefined',
  NEW = 'New',
  USED = 'Used',
  RECONDITIONED = 'Reconditioned',
}

export enum SafetyRiskStatus {
  NONE = 'None',
  DECLARED = 'Declared',
  CONFIRMED = 'Confirmed',
  REJECTED = 'Rejected',
}

export interface OwnerProductSummary {
  ownerId: string;
  ownerProductId: string;
  sellerId: string;
  sellerProductId: string;
  sellerBrand?: string;
  buyDate?: string;
  orderDeliveryDateUtc?: string;
  orderDeliveryMethod: OrderDelivery;
  marketPlace?: string;
  soldWarrantyId?: string;
  soldWarrantyPurchaseDate?: string;
  claimId?: string;
  claimGroupId?: string;
  fileId?: string;
  hasOpenFile: boolean;
  orderId?: string;
  orderReference?: string;
  orderLineReference?: string;
  externalIdentifier?: string;
  modelId: string;
  notOpenForClaims: boolean;
  isLowValue?: boolean;
  currencyCode?: string;
  price: number;
  shippingPrice: number;
  soldWarrantyPrice: number;
  state: ModelState;
  imei: string[];
  serialNumber?: string;
  outcomesHistory: OutcomeHistory[];
  countRepairs: number;
  deliveryTransportCarrier: {
    deliveryCarrierName?: string;
    deliveryCarrierCode?: string;
  };
  safetyRiskStatus: SafetyRiskStatus;
}
