import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  SellerConfiguration,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.config}sellers/${COMMON_QUERY_PARAMS.SELLER_ID}/configuration`;

const getSellerConfigurationQuery = async ({
  sellerId,
}: {
  sellerId: string;
}): Promise<BackResponseFailure | BackResponse<SellerConfiguration>> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.SELLER_ID, sellerId);

    const response = await apiCall<BackResponse<SellerConfiguration>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default getSellerConfigurationQuery;
