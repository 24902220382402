import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Col, Row } from 'react-styled-flexboxgrid';

import {
  BUTTON_TYPES,
  HOUSING_TYPES,
  INPUT_TYPES,
  POSITIONS,
} from '@savgroup-front-common/constants/src/shared';
import {
  Autocomplete,
  FormGroup,
} from '@savgroup-front-common/core/src/atoms/Form';
import { ActionRow } from '@savgroup-front-common/core/src/atoms/ActionRow';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import {
  safeFormattedIntlString,
  SafeFormattedMessageWithoutSpread,
} from '@savgroup-front-common/core/src/formatters';
import {
  InputHookForm,
  SwitchHookForm,
  TextareaHookForm,
} from '@savgroup-front-common/core/src/molecules/Form';
import { FLEX_DIRECTION } from '@savgroup-front-common/types';

import { $CardContainer, $Col, $Heading } from './AddressForm.styles';
import { ADDRESS_MANAGEMENT_FIELDS } from './AddressForm.types';
import messages from './messages';
import RadioButton from '../components/RadioButton/RadioButton';
import SuggestedAddressModalRadioButtonInformation from '../components/SuggestedAddressModalRadioButtonInformation';

const MAX_LENGTH_ADDITIONAL_INFORMATION = 50;

const AddressForm = ({ countriesOptions }: { countriesOptions: any[] }) => {
  const formContext = useFormContext();
  const {
    register,
    control,
    formState: { errors, isValid },
    watch,
    setValue,
    getValues,
  } = formContext;

  const shouldBeChoiceAnAddressValues = watch('shouldBeChoiceAnAddress');
  const keepSuggestedAddressValue = watch('keepSuggestedAddress');
  const keepAddressEnteredValue = watch('keepAddressEntered');

  const handleChangeKeepSuggestedAddress = () => {
    setValue('keepSuggestedAddress', true);
    setValue('keepAddressEntered', false);
  };

  const handleChangeKeepAddressEntered = () => {
    setValue('keepSuggestedAddress', false);
    setValue('keepAddressEntered', true);
  };

  const { addressSelected, addressSuggested } = getValues();

  const habitationOptions = [
    {
      value: HOUSING_TYPES.HOUSE,
      label: safeFormattedIntlString(messages.house),
    },
    {
      value: HOUSING_TYPES.APARTMENT,
      label: safeFormattedIntlString(messages.flat),
    },
    {
      value: HOUSING_TYPES.OTHER,
      label: safeFormattedIntlString(messages.other),
    },
  ];

  return (
    <div>
      <$Heading>
        <SafeFormattedMessageWithoutSpread message={messages.information} />
      </$Heading>
      <$CardContainer>
        <Row>
          <Col xs={12} sm={6} data-firstname-addressform>
            <FormGroup>
              <InputHookForm
                errors={errors}
                name={ADDRESS_MANAGEMENT_FIELDS.FIRST_NAME}
                placeholder={messages.firstName}
                label={messages.firstName}
                register={register}
                type={INPUT_TYPES.TEXT}
                isRequired
              />
            </FormGroup>
          </Col>
          <Col xs={12} sm={6} data-lastname-addressform>
            <FormGroup>
              <InputHookForm
                errors={errors}
                name={ADDRESS_MANAGEMENT_FIELDS.LAST_NAME}
                placeholder={messages.lastName}
                label={messages.lastName}
                register={register}
                type={INPUT_TYPES.TEXT}
                isRequired
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col xs={12} data-company-addressform>
            <FormGroup>
              <InputHookForm
                errors={errors}
                name={ADDRESS_MANAGEMENT_FIELDS.COMPANY_NAME}
                placeholder={messages.companyName}
                label={messages.companyName}
                register={register}
                type={INPUT_TYPES.TEXT}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6} data-phonenumber-addressform>
            <FormGroup>
              <InputHookForm
                label={messages.phone}
                name={ADDRESS_MANAGEMENT_FIELDS.PHONE}
                maxLength={25}
                register={register}
                errors={errors}
                type={INPUT_TYPES.TEXT}
                isRequired
              />
            </FormGroup>
          </Col>
        </Row>
      </$CardContainer>
      <$CardContainer>
        <Row>
          <Col xs={12} data-location-addressform>
            <FormGroup>
              <InputHookForm
                label={messages.address}
                name={ADDRESS_MANAGEMENT_FIELDS.ADDRESS}
                register={register}
                errors={errors}
                type={INPUT_TYPES.TEXT}
                isRequired
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <FormGroup>
              <InputHookForm
                label={messages.additionalAddress}
                name={ADDRESS_MANAGEMENT_FIELDS.ADDITIONAL_ADDRESS}
                placeholder={messages.additionalInformationsPlaceHolder}
                register={register}
                errors={errors}
                type={INPUT_TYPES.TEXT}
                dataTestId="additionalAddressInput"
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col xs={12} sm={4} data-postal-addressform>
            <FormGroup>
              <InputHookForm
                label={messages.postalCode}
                name={ADDRESS_MANAGEMENT_FIELDS.POSTAL_CODE}
                register={register}
                errors={errors}
                type={INPUT_TYPES.TEXT}
                isRequired
              />
            </FormGroup>
          </Col>
          <Col xs={12} sm={4} data-city-addressform>
            <FormGroup>
              <InputHookForm
                label={messages.city}
                name={ADDRESS_MANAGEMENT_FIELDS.CITY}
                register={register}
                errors={errors}
                type={INPUT_TYPES.TEXT}
                isRequired
              />
            </FormGroup>
          </Col>
          <Col xs={12} sm={4} data-countrycode-addressform>
            <FormGroup>
              <Controller
                control={control}
                name={ADDRESS_MANAGEMENT_FIELDS.COUNTRY_CODE}
                render={({ field }) => {
                  return (
                    <Autocomplete
                      name={field.name}
                      tabSelectsValue={false}
                      isClearable={false}
                      backspaceRemovesValue={false}
                      hideSelectedOptions={false}
                      label={messages.country}
                      placeholder={messages.chooseOption}
                      options={countriesOptions}
                      errors={errors}
                      value={field.value}
                      onChange={(option) => field.onChange(option.value)}
                      ref={field.ref}
                      isRequired
                    />
                  );
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={4}>
            <FormGroup>
              <SwitchHookForm
                register={register}
                name={ADDRESS_MANAGEMENT_FIELDS.HAS_ELEVATOR}
                label={messages.elevator}
                alignLabel={FLEX_DIRECTION.COLUMN}
              />
            </FormGroup>
          </Col>
          <Col xs={12} sm={4}>
            <FormGroup>
              <SwitchHookForm
                register={register}
                name={ADDRESS_MANAGEMENT_FIELDS.HAS_PARKING_SPACE}
                label={messages.parking}
                alignLabel={FLEX_DIRECTION.COLUMN}
              />
            </FormGroup>
          </Col>
          <Col xs={12} sm={4}>
            <FormGroup>
              <Controller
                control={control}
                name={ADDRESS_MANAGEMENT_FIELDS.HOUSING_TYPE}
                render={({ field }) => (
                  <Autocomplete
                    name={field.name}
                    errors={errors}
                    label={messages.housing}
                    options={habitationOptions}
                    onChange={(option) => field.onChange(option.value)}
                    value={field.value}
                    ref={field.ref}
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6}>
            <FormGroup>
              <InputHookForm
                label={messages.floor}
                name={ADDRESS_MANAGEMENT_FIELDS.FLOOR}
                maxLength={25}
                register={register}
                errors={errors}
                type={INPUT_TYPES.TEXT}
              />
            </FormGroup>
          </Col>
          <Col xs={12} sm={6}>
            <FormGroup>
              <InputHookForm
                label={messages.intercom}
                name={ADDRESS_MANAGEMENT_FIELDS.DOOR_CODE}
                maxLength={25}
                register={register}
                errors={errors}
                type={INPUT_TYPES.TEXT}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <FormGroup>
              <TextareaHookForm
                label={messages.additionalInfo}
                name={ADDRESS_MANAGEMENT_FIELDS.ADDITIONAL_INFORMATION}
                errors={errors}
                maxLength={MAX_LENGTH_ADDITIONAL_INFORMATION}
                register={register}
              />
            </FormGroup>
          </Col>
        </Row>
        {shouldBeChoiceAnAddressValues && (
          <Row>
            <Col xs={6}>
              <RadioButton
                name="keepSuggestedAddress"
                checked={keepSuggestedAddressValue}
                onChange={handleChangeKeepSuggestedAddress}
                isFluid
                label={
                  <SuggestedAddressModalRadioButtonInformation
                    title={messages.suggestedAddress}
                    checked={keepSuggestedAddressValue}
                    address={addressSuggested}
                  />
                }
                labelPosition={POSITIONS.LEFT}
              />
            </Col>
            <Col xs={6}>
              <RadioButton
                name="keepAddressEntered"
                checked={keepAddressEnteredValue}
                onChange={handleChangeKeepAddressEntered}
                isFluid
                label={
                  <SuggestedAddressModalRadioButtonInformation
                    title={messages.addressEntered}
                    checked={keepAddressEnteredValue}
                    address={addressSelected}
                  />
                }
                labelPosition={POSITIONS.LEFT}
              />
            </Col>
          </Row>
        )}
      </$CardContainer>
      <ActionRow>
        <$Col xs={12} sm={3}>
          <Button
            type={BUTTON_TYPES.SUBMIT}
            data-addressnextstep-button
            fluid
            primary
            isError={!isValid}
            dataTestId="submitButton"
          >
            <SafeFormattedMessageWithoutSpread message={messages.next} />
          </Button>
        </$Col>
      </ActionRow>
    </div>
  );
};

AddressForm.displayName = 'AddressForm';

export default AddressForm;
