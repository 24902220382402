import * as ActionTypes from './actionTypes';

const initialState = {
  isLoading: true,
};

function setLoadingPageState(state, action) {
  const newState = {
    isLoading: action.payload,
  };

  return newState;
}

function fileTrackingReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.FILE_TRACKING_PAGE_LOADING.BASE:
      return setLoadingPageState(state, action);
    default:
      return state;
  }
}

export default fileTrackingReducer;
