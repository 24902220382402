import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';

import Card from '../../../../../atoms/Cards/Card';

export const $Title = styled.div`
  text-align: center;
  font-size: ${({ theme }) => theme.fonts.size.medium};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  margin-bottom: 1.5rem;
`;

export const $ActionRow = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.5rem;
`;

export const $AppointmentCalendarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
`;

export const $CalendarCard = styled(Card)`
  @media ${media.maxWidth.sm} {
    margin-left: -1rem;
    margin-right: -1rem;
    padding-left: 0;
    padding-right: 0;
  }
`;
