import React, { FC } from 'react';

import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { formatReverseMoneyToString } from '@savgroup-front-common/core/src/formatters/intl';
import { ReverseMoneyNumberAmount } from '@savgroup-front-common/types';

import messages from './messages';
import {
  $ImportantHighlight,
  $Line,
  $LineContainer,
  $PriceField,
  $Row,
} from './QuotationValidationTotalCosts.styles';

interface QuotationValidationTotalCostsProps {
  totalTax: ReverseMoneyNumberAmount;
  totalDiscountIncludedTax: ReverseMoneyNumberAmount;
  preTaxTotalAmount: ReverseMoneyNumberAmount;
  totalAmountToBePaid: ReverseMoneyNumberAmount;
}

const QuotationValidationTotalCosts: FC<QuotationValidationTotalCostsProps> = ({
  totalTax,
  totalDiscountIncludedTax,
  preTaxTotalAmount,
  totalAmountToBePaid,
}) => {
  const totalDiscountIncludedTaxAmount = totalDiscountIncludedTax?.amount;

  return (
    <>
      <$Row>
        <SafeFormattedMessageWithoutSpread
          message={messages.totalVATExcluded}
        />

        <$LineContainer>
          <$Line />
        </$LineContainer>

        <$PriceField data-testid="QuotationValidationTotalCosts_preTaxTotalAmount">
          {formatReverseMoneyToString(preTaxTotalAmount)}
        </$PriceField>
      </$Row>

      {totalDiscountIncludedTax && totalDiscountIncludedTaxAmount !== 0 && (
        <$Row>
          <SafeFormattedMessageWithoutSpread message={messages.totalDiscount} />

          <$LineContainer>
            <$Line />
          </$LineContainer>

          <$PriceField data-testid="QuotationValidationTotalCosts_totalDiscountIncludedTax">
            {formatReverseMoneyToString(totalDiscountIncludedTax)}
          </$PriceField>
        </$Row>
      )}

      <$Row>
        <SafeFormattedMessageWithoutSpread message={messages.vat} />

        <$LineContainer>
          <$Line />
        </$LineContainer>

        <$PriceField data-testid="QuotationValidationTotalCosts_totalTax">
          {formatReverseMoneyToString(totalTax)}
        </$PriceField>
      </$Row>

      <$Row>
        <$ImportantHighlight>
          <SafeFormattedMessageWithoutSpread
            message={messages.totalVATIncluded}
          />
        </$ImportantHighlight>

        <$LineContainer>
          <$Line />
        </$LineContainer>
        <$ImportantHighlight>
          <$PriceField data-testid="QuotationValidationTotalCosts_totalAmountToBePaid">
            {formatReverseMoneyToString(totalAmountToBePaid)}
          </$PriceField>
        </$ImportantHighlight>
      </$Row>
    </>
  );
};

QuotationValidationTotalCosts.displayName = 'QuotationValidationTotalCosts';

export default QuotationValidationTotalCosts;
