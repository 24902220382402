/* eslint-disable no-console */
import { fromJS } from 'immutable';
import get from 'lodash/get';
import map from 'lodash/map';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { getCarrierBrand } from '../../../helpers';

export function onClaimGroupConfirmationLoading(state, { meta }) {
  const claimGroupId = get(meta, ['claimGroupId']);

  if (!claimGroupId) {
    console.warn('[onClaimGroupConfirmationLoading] ClaimGroupId is undefined');

    return state;
  }

  return state.setIn(
    ['claimGroupsConfirmation', claimGroupId],
    fromJS({ isLoaded: false, isDirty: false, value: undefined }),
  );
}

export function onClaimGroupConfirmationLoadingErrored(state, { meta }) {
  const claimGroupId = get(meta, ['claimGroupId']);

  if (!claimGroupId) {
    console.warn(
      '[onClaimGroupConfirmationLoadingErrored] ClaimGroupId is undefined',
    );

    return state;
  }

  // TODO errors
  return state.updateIn(['claimGroupsConfirmation', claimGroupId], (oldValue) =>
    oldValue.setIn(['isDirty'], true).setIn(['hasErrors'], true),
  );
}

export function onClaimGroupConfirmationLoaded(state, { payload, meta }) {
  const claimGroupId = get(meta, ['claimGroupId']);
  const claimGroupConfirmation = get(payload, ['value']);

  if (!claimGroupId) {
    console.warn('[onClaimGroupConfirmationLoaded] ClaimGroupId is undefined.');

    return state;
  }

  return state.setIn(
    ['claimGroupsConfirmation', claimGroupId],
    fromJS({ isLoaded: true, isDirty: false }).setIn(['value'], {
      ...claimGroupConfirmation,
      carrierDeliveryBrandName: getCarrierBrand(
        get(claimGroupConfirmation, 'carrierDeliveryName'),
      ),
      carrierDepositBrandName: getCarrierBrand(
        get(claimGroupConfirmation, 'carrierDepositName'),
      ),
      claims: map(get(claimGroupConfirmation, 'claims'), (claim) => ({
        ...claim,
        claimId: get(claim, 'claimId') || get(claim, 'claimdId'),
        documents: map(get(claim, 'documents'), (document) => ({
          ...document,
          url: `${APIConfiguration.base}${document.url}&filename=${document.originalFileName}`,
        })),
      })),
    }),
  );
}
