import { defineMessages } from 'react-intl';

export default defineMessages({
  modify: {
    id: 'components.myaccount.chooseItemCard.modify',
    defaultMessage: 'Modify',
  },
  free: {
    id: 'components.myaccount.claims.handling.cards.free',
    defaultMessage: 'Free',
  },
  startingFrom: {
    id: 'components.myaccount.claims.handling.cards.startingFrom',
    defaultMessage: 'starting from {price}',
  },
});
