import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { DOCUMENT_TYPES } from '@savgroup-front-common/constants';
import {
  ActionCreators,
  Selectors,
} from '@savgroup-front-common/core/src/domains/attachments';
import { FileShortSummary } from '@savgroup-front-common/types';

import { useMyAccountTypedSelector } from '../../../../../../../hooks';

const useQuotationValidation = ({ file }: { file: FileShortSummary }) => {
  const dispatch = useDispatch();
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);

  const onRejectQuoteButtonClick = () => {
    setIsRejectModalOpen(true);
  };
  const onRejectModalClose = useCallback(() => {
    setIsRejectModalOpen(false);
  }, []);

  useEffect(() => {
    dispatch(ActionCreators.getDocumentsByClaimId({ claimId: file.claimId }));
  }, [dispatch, file.claimId]);

  const documents =
    useMyAccountTypedSelector<Record<string, unknown>[]>((state) =>
      Selectors.selectDocumentByClaimId(state, { claimId: file.claimId }),
    ) || [];

  const quoteDocuments = documents.filter(
    ({ name }) => name === DOCUMENT_TYPES.QUOTE,
  );

  return {
    quoteDocuments,
    onRejectModalClose,
    onRejectQuoteButtonClick,

    isRejectModalOpen,
  };
};

export default useQuotationValidation;
