import styled from 'styled-components';

import { rem } from '../../helpers';

const H5 = styled.h5.attrs({ role: 'presentation' })`
  font-size: ${rem('13px')};
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
  color: ${(props) => props.theme.colors.paragraphTextColor};
  margin: 0;
  padding-top: ${rem('8px')};
  padding-bottom: ${rem('8px')};
  text-align: ${(props) => props.align};
`;

export default H5;
