import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useReducer,
  useState,
} from 'react';

import { ErrorFromBack } from '../../../../helpers';
import createGenericContext from '../../../../helpers/createGenericContext';
import { DetailedSchedule } from '../../../AppointmentCalendar/types/DetailedSchedule';
import { useGetPageSize } from '../../hooks/useGetPageSize';

import { getDetailedSchedulesByPage } from './TakeInterventionAppointment.helpers';
import {
  interventionAppointmentInit,
  InterventionAppointmentState,
  takeInterventionAppointmentReducer,
} from './TakeInterventionAppointment.reducer';
import { INTERVENTION_APPOINTMENT_ACTION_TYPES } from './TakeInterventionAppointment.types';

export interface TakeInterventionAppointmentLegacyContextArgs
  extends InterventionAppointmentState {
  pageNumber: number;
  detailedSchedules?: DetailedSchedule[];
  handleValidateTimeSlot: () => void;
  handlePageChange: ({ newPageNumber }: { newPageNumber: number }) => void;
  handleSetErrors: (payload: { errors: ErrorFromBack[] | Error[] }) => void;
}

const [
  useTakeInterventionAppointmentContext,
  TakeInterventionAppointmentProvider,
] = createGenericContext<TakeInterventionAppointmentLegacyContextArgs>();

const TakeInterventionAppointmentContextProvider: FC<
  PropsWithChildren<{
    detailedSchedules?: DetailedSchedule[];
  }>
> = ({ children, detailedSchedules = [] }) => {
  const pageSize = useGetPageSize();
  const [state, dispatch] = useReducer(
    takeInterventionAppointmentReducer,
    undefined,
    interventionAppointmentInit,
  );

  const handleValidateTimeSlot = useCallback(() => {
    dispatch({
      type: INTERVENTION_APPOINTMENT_ACTION_TYPES.VALIDATE_TIMESLOT,
    });
  }, []);
  const handleSetErrors = useCallback(
    (payload: { errors: ErrorFromBack[] | Error[] }) => {
      dispatch({
        type: INTERVENTION_APPOINTMENT_ACTION_TYPES.SET_ERRORS,
        payload,
      });
    },
    [],
  );
  const [pageNumber, setPageNumber] = useState(1);

  const handlePageChange = useCallback(
    ({ newPageNumber }: { newPageNumber: number }) => {
      setPageNumber(newPageNumber);
    },
    [],
  );

  return (
    <TakeInterventionAppointmentProvider
      value={{
        ...state,
        pageNumber,
        detailedSchedules: getDetailedSchedulesByPage({
          detailedSchedules,
          pageNumber,
          pageSize,
        }),
        handleValidateTimeSlot,
        handleSetErrors,
        handlePageChange,
      }}
    >
      {children}
    </TakeInterventionAppointmentProvider>
  );
};

TakeInterventionAppointmentContextProvider.displayName =
  'TakeInterventionAppointmentContextProvider';

export {
  TakeInterventionAppointmentContextProvider,
  useTakeInterventionAppointmentContext,
};
