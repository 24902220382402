import get from 'lodash/get';

const clientIdByHostname: Record<string, string> = {
  'sav.auchan.fr': 'auchan.revers.io',
  'mesretours.maisonsdumonde.com': 'maisonsdumonde.revers.io',
};

const authorityByHostname: Record<string, string> = {
  'auchan.revers.io': 'https://auchan-login.revers.io',
  'sav.auchan.fr': 'https://sav-login.auchan.fr',
  'maisonsdumonde.revers.io': 'https://maisonsdumonde-login.revers.io',
  'mesretours.maisonsdumonde.com':
    'https://mesretours-login.maisonsdumonde.com',
};

const authorityByClientId: Record<string, string> = {
  'revers.io': 'https://myaccount-login.revers.io',
  'retours.carrefour.fr': 'https://retours-login.carrefour.fr',
  'auchan.revers.io': 'https://sav-login.auchan.fr',
  'maisonsdumonde.revers.io': 'https://mesretours-login.maisonsdumonde.com',
};

const { hostname } = window.location;

function setAuthority(authority: string | undefined = undefined) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { clientId } = this;

  const defaultAuthority = authorityByClientId[clientId];
  const defaultAuthorityFromHostname = get(authorityByHostname, hostname);

  if (hostname !== 'localhost' && defaultAuthorityFromHostname) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.authority = defaultAuthorityFromHostname;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  this.authority = authority || defaultAuthority;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return this;
}

const defaultClientId = 'myaccount.revers.io';

function computeClientId() {
  if (import.meta.env.VITE_CLIENTID) {
    return import.meta.env.VITE_CLIENTID;
  }
  if (hostname !== 'localhost') {
    return clientIdByHostname[hostname] || window.location.hostname;
  }

  return defaultClientId;
}

const clientId = computeClientId();

const AuthConfiguration = {
  clientId,
  authority: authorityByClientId[clientId],
  scope: 'openid email api',
  setAuthority,
};

export default AuthConfiguration;
