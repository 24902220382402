import React, { useContext } from 'react';

import { Field } from '../../../molecules/Field';
import { ActorInformationItem } from '../../ActorInformation/components/GenericActorInformation/GenericActorInformation.styles';
import messages from '../../ActorInformation/components/GenericActorInformation/messages';
import { ContentTemplateContext } from '../MDXCustomComponents.context';

const AdditionalAddressCurrentActor = () => {
  const { actorSummary } = useContext(ContentTemplateContext);
  const address = actorSummary?.address;
  const additionalAddress = address?.additionalAddress;

  return (
    <>
      {additionalAddress && (
        <ActorInformationItem>
          <Field label={messages.additionalAddress} copy={additionalAddress}>
            {additionalAddress}
          </Field>
        </ActorInformationItem>
      )}
    </>
  );
};

AdditionalAddressCurrentActor.displayName = 'AdditionalAddressCurrentActor';

export default AdditionalAddressCurrentActor;
