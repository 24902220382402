import { defineMessages } from 'react-intl';

export default defineMessages({
  followRepair: {
    id: 'components.myaccount.homePage.productItem.followRepair',
    defaultMessage: 'Follow my repair',
  },
  addProduct: {
    id: 'components.myaccount.homePage.productItem.addProduct',
    defaultMessage: 'Add a product',
  },
  askRepair: {
    id: 'components.myaccount.homePage.productItem.askRepair',
    defaultMessage: 'Ask for a repair',
  },
  askCare: {
    id: 'components.myaccount.homePage.productItem.askCare',
    defaultMessage: 'Request a care',
  },
  finalizeClaim: {
    id: 'components.myaccount.homePage.productItem.finalizeClaim',
    defaultMessage: 'Finalize the request',
  },
  infoDateLabel: {
    id: 'components.myaccount.homePage.productItem.infoDateLabel',
    defaultMessage: 'Purchase date',
  },
  infoPriceLabel: {
    id: 'components.myaccount.homePage.productItem.infoPriceLabel',
    defaultMessage: 'Price',
  },
  isClosed: {
    id: 'components.myaccount.productInfo.isClosed',
    defaultMessage: 'File closed',
  },
  isOpen: {
    id: 'components.myaccount.productInfo.isOpen',
    defaultMessage: 'File opened',
  },
});
