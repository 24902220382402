import { useQuery } from 'react-query';

import { logError } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { Model } from '@savgroup-front-common/types';

import { CatalogService } from '../api';

const GetModelByIdQuery = 'getModelByIdQuery';

interface UseGetModelByIdArgs {
  modelId?: string;
}

interface UseGetModelByIdReturnValue {
  isLoading: boolean;
  model?: Model;
}

const useGetModelById = ({
  modelId,
}: UseGetModelByIdArgs): UseGetModelByIdReturnValue => {
  const { data: model, isLoading } = useQuery(
    [GetModelByIdQuery, { modelId }],
    async () => {
      if (!modelId) {
        return undefined;
      }
      const response = await CatalogService.getModelByIdQuery({ modelId });

      if (response.failure) {
        logError(response.errors);

        return undefined;
      }

      return response.value;
    },
    { staleTime: Infinity },
  );

  return { model, isLoading };
};

export default useGetModelById;
