import { fromJS } from 'immutable';

import * as ActionTypes from '../actionTypes';

function onLoadFileHandlingLoading(state, { meta }) {
  const { fileId } = meta;

  return state.updateIn(
    [fileId],
    fromJS({ isLoaded: false, isDirty: false }),
    (oldValue) => oldValue.set('isLoaded', false).set('isDirty', true),
  );
}

function onLoadFileHandlingSuccess(state, { meta, payload }) {
  const { fileId } = meta;

  return state.setIn(
    [fileId],
    fromJS({ isLoaded: true, isDirty: false }).set('value', payload.value),
  );
}

function onLoadFileHandlingError(state, { meta, errors }) {
  const { fileId } = meta;

  return state.setIn(
    [fileId],
    fromJS({ isLoaded: true, isDirty: true, hasErrors: true }).set(
      'errors',
      errors,
    ),
  );
}

export default function reducer(state = fromJS({}), action) {
  switch (action.type) {
    case ActionTypes.LOAD_FILE_HANDLING.STARTED:
      return onLoadFileHandlingLoading(state, action);
    case ActionTypes.LOAD_FILE_HANDLING.ERRORED:
      return onLoadFileHandlingError(state, action);
    case ActionTypes.LOAD_FILE_HANDLING.SUCCEEDED:
      return onLoadFileHandlingSuccess(state, action);

    default:
      return state;
  }
}
