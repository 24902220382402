import styled from 'styled-components';

export const $RadioButtonWrapper = styled.div`
  [class*='RadioCardWithCheckboxColumnstyles__RadioCardContainer-'] {
    border-radius: 8px;
  }
  [class*='RadioCardWithCheckboxColumnstyles__RadioCardTitle'] {
    padding-bottom: unset;
  }
  [class*='RadioCardWithCheckboxColumnstyles__ChooseProductCardCheckboxContainer-'] {
    display: none;
  }
`;

export const $Question = styled.div`
  font-size: 18px;
  colors: ${({ theme }) => theme.colors.black};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  margin-bottom: 2rem;
`;
