import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared/supportedMethods';
import { buildUrl } from '@savgroup-front-common/core/src/helpers';
import { GotAnErrorFromEndpoint } from '@savgroup-front-common/exceptions';
import { RequiredPayloadFieldIsNotProvided } from '@savgroup-front-common/exceptions/src/client/errors/RequiredPayloadFieldIsNotProvided';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { BaseBackResponse, FileId } from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.workflow}files/technicalIntervention/timeSlots`;

export interface TimeslotFromBack {
  start: string;
  finish: string;
}

interface ConfirmPaymentIntentCommandResponse extends BaseBackResponse {
  value: TimeslotFromBack[];
}

interface GetTechnicalInterventionTimeSlotsPayload {
  fileId: FileId;
}

async function getTechnicalInterventionTimeSlots({
  fileId,
}: GetTechnicalInterventionTimeSlotsPayload): Promise<TimeslotFromBack[]> {
  const functionName = 'getTechnicalInterventionTimeSlots';

  if (!fileId) {
    throw new RequiredPayloadFieldIsNotProvided('fileId', functionName);
  }

  const url = buildUrl(ENDPOINT, {
    fileId,
  });

  const response: ConfirmPaymentIntentCommandResponse = await apiCall(url, {
    method: SUPPORTED_METHODS.GET,
  });

  if (response.failure) {
    throw new GotAnErrorFromEndpoint(
      `Got an exception trying to ${functionName}`,
      response.errors,
    );
  }

  return response.value;
}

export default getTechnicalInterventionTimeSlots;
