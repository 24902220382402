import fileUploadsReducer from '../FileUploads/fileUploadsReducer';
import * as LoginConst from '../Login/loginConst';

import * as ActionTypes from './actionTypes';

const initialState = {
  fileUploads: {},
  rma: null,
  isLoaded: false,
  ownerId: null,
  isRunning: false,
  isChecked: false,
  claimSummary: {
    isLoaded: false,
    isLoading: false,
    fileId: null,
    fileNumber: null,
    templateName: null,
    headerMessage: null,
    contentTitle: null,
    content1: null,
    content2: null,
    documents: [],
    issueId: null,
    issueName: null,
    reasonName: null,
    solutionName: null,
  },
};

function isLoaded(state, action) {
  let result;

  switch (action.type) {
    default:
      result = state;
  }

  return result;
}

function isLoading(state, action) {
  let result = state;

  switch (action.type) {
    default:
      result = state;
  }

  return result;
}

function isChecked(state, action) {
  switch (action.type) {
    case ActionTypes.GET_CLAIM.SUCCEEDED:
      return true;
    case ActionTypes.GET_CLAIM.ERRORED:
      return false;
    default:
      return state;
  }
}

function onReceiveClaimOwnerId(state, action) {
  switch (action.type) {
    case ActionTypes.GET_CLAIM.SUCCEEDED:
      return action.payload.value.ownerId;
    default:
      return state;
  }
}

function onClaimInfoLoaded(state, action) {
  switch (action.type) {
    case ActionTypes.GET_CLAIM.SUCCEEDED: {
      const { issueId, reasonId } = action.payload.value || {};

      return {
        issueId,
        reasonId,
      };
    }
    default:
      return state;
  }
}

function onRecieveClaimSummary(state, action) {
  if (action.type === ActionTypes.LOAD_CLAIM_SUMMARY.BASE) {
    return {
      ...state,
      isLoading: true,
      isLoaded: false,
    };
  }

  if (action.type === ActionTypes.LOAD_CLAIM_SUMMARY.ERRORED) {
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
    };
  }

  if (action.type === ActionTypes.LOAD_CLAIM_SUMMARY.SUCCEEDED) {
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      ...action.payload.value,
    };
  }

  return state;
}

function claimReducer(state = initialState, action) {
  switch (action.type) {
    case LoginConst.LOGIN_SIGNEDOUT:
      return { ...initialState };

    default:
      return {
        fileUploads: fileUploadsReducer(state.fileUploads, action),
        isLoaded: isLoaded(state.isLoaded, action),
        ownerId: onReceiveClaimOwnerId(state.ownerId, action),
        isRunning: isLoading(state.isRunning, action),
        isChecked: isChecked(state.isChecked, action),
        claimInfo: onClaimInfoLoaded(state.claimInfo, action),
        claimSummary: onRecieveClaimSummary(state.claimSummary, action),
      };
  }
}

export default claimReducer;
