import { useLayoutEffect, useState } from 'react';

import { GoogleApiConfiguration } from '@savgroup-front-common/configuration';

const useInitGoogleMap = (): { isLoading: boolean } => {
  const [isLoading, setIsLoading] = useState(true);

  useLayoutEffect(() => {
    setIsLoading(true);

    const script = document.createElement('script');

    script.src = `https://maps.googleapis.com/maps/api/js?key=${GoogleApiConfiguration.apiKey}&libraries=places`;
    script.type = 'text/javascript';

    document.body.appendChild(script);

    setIsLoading(false);
  }, []);

  return { isLoading };
};

export default useInitGoogleMap;
