import { CustomerFileSummary, Model } from '@savgroup-front-common/types';
import { SolutionType } from 'myaccount/api/Claim/getSolutionTypeQuery';
import { FileStateHistorySummary } from 'myaccount/api/Workflow/getFileStatesQuery';
import { ProductsInfoDto } from 'myaccount/view/app/hooks/useGetModelByIdQuery';

export const adaptProductsFromFileFollowupsPage = ({
  models,
  productsInfoAdapted,
  solution,
}: {
  models?: (Model | undefined)[];
  productsInfoAdapted?: ProductsInfoDto[];
  solution?: SolutionType;
}) => {
  const { uniqueName: solutionUniqueName, name: solutionName } = solution || {
    uniqueName: '',
    name: '',
  };

  return productsInfoAdapted?.map((productInfoAdapted) => {
    const model = models?.find(
      (model) => model?.id === productInfoAdapted.modelId,
    );

    return {
      purchaseDate: productInfoAdapted.buyDate,
      fileReference: productInfoAdapted.fileReference,
      isClosed: productInfoAdapted.isClosed,
      serialNumber: productInfoAdapted.serialNumber,
      orderLineReference: productInfoAdapted.orderLineReference,
      solutionUniqueName,
      solutionName,
      modelBrandName: model?.brandName,
      modelTypeName: model?.modelTypeName,
      modelTypeId: model?.modelTypeId,
      modelName: model?.name,
      modelPictureUrl: model?.pictureUrl,
      modelId: productInfoAdapted.modelId,
      displayName: model?.displayName,
      brandDisplayName: model?.brandDisplayName,
    };
  });
};

export const adaptHistory = (histories?: FileStateHistorySummary[]) => {
  return histories?.sort((a, b) => b.module.wave - a.module.wave);
};

export const adaptCustomerFileFullSummary = (
  customerFileSummary?: CustomerFileSummary,
) => {
  return customerFileSummary?.fileProducts.map((fileProduct) => ({
    ...fileProduct,
    buyDate: new Date(customerFileSummary.buyDate),
    fileReference: customerFileSummary.fileReference,
    isClosed: customerFileSummary.isClosed,
    solutionId: customerFileSummary.solutionId,
  }));
};
