/* eslint-disable no-console */
import { fromJS } from 'immutable';
import get from 'lodash/get';

export function onClaimGroupCreationForOwnerLoading(state, { meta }) {
  const ownerId = get(meta, ['ownerId']);
  const ownerProductIds = get(meta, ['ownerProductIds']);

  if (!ownerId || !ownerProductIds) {
    console.warn(
      '[onClaimGroupCreationForOwnerLoading] OwnerId/OwnerProductIds is undefined',
    );

    return state;
  }

  return ownerProductIds.reduce(
    (acc, ownerProductId) =>
      acc.setIn(
        ['createdClaimGroupsForOwner', ownerId, ownerProductId],
        fromJS({ isLoaded: false, isDirty: false, value: undefined }),
      ),
    state,
  );
}

export function onClaimGroupCreationForOwnerLoadingErrored(state, { meta }) {
  const ownerId = get(meta, ['ownerId']);
  const ownerProductIds = get(meta, ['ownerProductIds']);

  if (!ownerId || !ownerProductIds) {
    console.warn(
      '[onClaimGroupCreationForOwnerLoadingErrored] OwnerId/ownerProductIds is undefined',
    );

    return state;
  }

  // TODO errors
  return ownerProductIds.reduce(
    (s, ownerProductId) =>
      s.updateIn(
        ['createdClaimGroupsForOwner', ownerId, ownerProductId],
        (oldValue) =>
          oldValue.setIn(['isDirty'], true).setIn(['hasErrors'], true),
      ),
    state,
  );
}

export function onClaimGroupCreationForOwnerLoaded(state, { payload, meta }) {
  const ownerId = get(meta, ['ownerId']);
  const ownerProductIds = get(meta, ['ownerProductIds']);
  const claimGroupId = get(payload, ['value']);

  if (!ownerId || !ownerProductIds) {
    console.warn(
      '[onClaimGroupCreationForOwnerLoaded] OwnerId/ownerProductIds is undefined.',
    );

    return state;
  }

  return ownerProductIds.reduce(
    (s, ownerProductId) =>
      s.setIn(
        ['createdClaimGroupsForOwner', ownerId, ownerProductId],
        fromJS({ isLoaded: true, isDirty: false }).setIn(
          ['value'],
          claimGroupId,
        ),
      ),
    state,
  );
}
