import React, { FunctionComponent } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import { useIsNewUiEnabled } from '../../hooks/useIsNewUiEnabled';

import { $TabViewTransitionContainer } from './RouteTabs.styles';
import { RouteTabConfig } from './RouteTabs.types';

interface RouteTabsBodyProps {
  config: RouteTabConfig[];
}

const RouteTabsBody: FunctionComponent<RouteTabsBodyProps> = ({ config }) => {
  const location: any = useLocation();
  const isNewUiEnabled = useIsNewUiEnabled();

  return (
    <$TabViewTransitionContainer $isNewUi={isNewUiEnabled}>
      <Switch location={location.location ? location.location : location}>
        {config.map(({ path, url, Component, exact, subTab, name }) => {
          if (subTab) {
            return subTab.map(
              ({ path, Component, exact, url, name: subName }) => {
                return (
                  <Route key={url} exact={exact} path={path}>
                    <div data-testid={`routeTabBody_${name}_${subName}`}>
                      {Component && <Component />}
                    </div>
                  </Route>
                );
              },
            );
          }

          return (
            <Route key={url} exact={exact} path={path}>
              <div data-testid={`routeTabBody_${name}`}>
                {Component && <Component />}
              </div>
            </Route>
          );
        })}
      </Switch>
    </$TabViewTransitionContainer>
  );
};

RouteTabsBody.displayName = 'RouteTabsBody';

export default RouteTabsBody;
