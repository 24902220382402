import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';

export const $QuotationTitle = styled.div`
  padding: 1rem;

  & > div > * {
    margin-right: 0.25rem;
  }

  @media ${media.maxWidth.sm} {
    padding: 0;
  }
`;

export const $LightSpan = styled.span`
  font-weight: ${({ theme }) => theme.fonts.weight.light};
`;
