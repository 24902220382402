import deleteAttachment from './deleteAttachment';
import downloadAttachment from './downloadAttachment';
import uploadAttachements from './uploadAttachment';
import uploadAttachmentWithProgress from './uploadAttachmentWithProgress';
import uploadMultiAttachments from './uploadMultiAttachments';
import urlExists from './urlExists';

export const CommonAttachmentService = {
  uploadAttachements,
  uploadMultiAttachments,
  uploadAttachmentWithProgress,

  downloadAttachment,

  deleteAttachment,
  urlExists,
};
