import React, { FunctionComponent } from 'react';

import { ArrowRightIcon } from '../Arrow/ArrowRight.icon';
import { IconPropsInterface } from '../Icon.types';

const ChevronRightIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => <ArrowRightIcon size={size} color={color} />;

ChevronRightIcon.displayName = 'ChevronRightIcon';

export default ChevronRightIcon;
