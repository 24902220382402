import { HANDLING_GROUPS } from '@savgroup-front-common/constants';
import { ICONS_TYPE } from '@savgroup-front-common/types/src/Icon';

const getHandlingIcon = ({ pickupMode }: { pickupMode: HANDLING_GROUPS }) => {
  switch (pickupMode) {
    case HANDLING_GROUPS.HOME:
    case HANDLING_GROUPS.HOME_INTERVENTION:
      return ICONS_TYPE.HOME_ICON;
    case HANDLING_GROUPS.PICKUP_STORE:
    case HANDLING_GROUPS.PICKUP_STORE_DELIVERY:
      return ICONS_TYPE.SMALL_SHOP_ICON;
    case HANDLING_GROUPS.PICKUP_POINT:
      return ICONS_TYPE.BOX_ICON;

    default:
      return ICONS_TYPE.DELIVERY_TRUCK_ICON;
  }
};

export default getHandlingIcon;
