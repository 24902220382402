import { createSelector } from 'reselect';

const routerState = (state: any) => {
  return state.router;
};

export const location = createSelector(
  [routerState],
  (router) => router.location,
);

export const pathname = createSelector(
  [location],
  (currentLocation) => currentLocation.pathname,
);

export const search = createSelector(
  [location],
  (currentLocation) => currentLocation.search,
);
