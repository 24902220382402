import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  HANDLING_MODES,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  AddressInfoDto,
  BackResponse,
  BackResponseFailure,
  HandlingSummary,
  PickupPointAddress,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.claim}claimGroups/${COMMON_QUERY_PARAMS.CLAIM_GROUP_ID}/handling`;

interface SetClaimGroupHandlingArgs {
  claimGroupId?: string;
  payload: {
    handlingMode: HANDLING_MODES;
    pickupPointId?: string;
    pickupPointAddress?: PickupPointAddress | null;
    carrierCustomerPriceId?: string;
    carrierSellerPriceId?: string;
    address?: AddressInfoDto | null;
  };
}

const setClaimGroupHandling = async ({
  claimGroupId,
  payload,
}: SetClaimGroupHandlingArgs): Promise<
  BackResponse<HandlingSummary> | BackResponseFailure
> => {
  const url = ENDPOINT.replace(
    COMMON_QUERY_PARAMS.CLAIM_GROUP_ID,
    claimGroupId || '',
  );

  try {
    const response = await apiCall<BackResponse<HandlingSummary>>(url, {
      method: SUPPORTED_METHODS.POST,
      json: payload,
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default setClaimGroupHandling;
