import useGetModelDetailByIdQuery from '../../../app/hooks/useGetModelDetailByIdQuery';

const useProductHeader = ({ product }: any) => {
  const { modelDetail } = useGetModelDetailByIdQuery({ product });

  return {
    model: modelDetail,
  };
};

export default useProductHeader;
