import { fromJS } from 'immutable';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';

export function onReasonsLoading(state, { meta }) {
  const claimId = get(meta, ['claimId']);

  if (!claimId) {
    // eslint-disable-next-line no-console
    console.warn('[onReasonsLoading] ClaimId is undefined');

    return state;
  }

  return state.setIn(
    ['reasons', claimId],
    fromJS({ isLoaded: false, isDirty: false, value: undefined }),
  );
}

export function onReasonsLoadingErrored(state, { meta }) {
  const claimId = get(meta, ['claimId']);

  if (!claimId) {
    // eslint-disable-next-line no-console
    console.warn('[onReasonsLoadingErrored] ClaimId is undefined');

    return state;
  }

  // TODO errors
  return state.updateIn(['reasons', claimId], (oldValue) =>
    oldValue.setIn(['isDirty'], true).setIn(['hasErrors'], true),
  );
}

export function onReasonsLoaded(state, { payload, meta }) {
  const claimId = get(meta, ['claimId']);
  const reasons = orderBy(
    get(payload, ['value']),
    ['displayOrder', 'name', 'kind'],
    ['asc', 'asc', 'asc'],
  );

  if (!claimId) {
    // eslint-disable-next-line no-console
    console.warn('[onReasonsLoaded] ClaimId is undefined.');

    return state;
  }

  return state.setIn(
    ['reasons', claimId],
    fromJS({ isLoaded: true, isDirty: false }).setIn(['value'], reasons),
  );
}

export function onReasonSelect(state, { meta }) {
  const claimId = get(meta, ['claimId']);

  if (!claimId) {
    // eslint-disable-next-line no-console
    console.warn('[onReasonSelected] ClaimId is undefined.');

    return state;
  }

  return state.setIn(
    ['solutions', claimId],
    fromJS({ isLoaded: false, isDirty: false }),
  );
}

export function onReasonSelected(state, { payload, meta }) {
  const claimId = get(meta, ['claimId']);
  const claim = get(payload, ['value']);

  if (!claimId) {
    // eslint-disable-next-line no-console
    console.warn('[onReasonSelected] ClaimId is undefined.');

    return state;
  }

  return state
    .updateIn(['claims', claimId], fromJS({}), (oldValue) =>
      fromJS({ isLoaded: true, isDirty: false }).setIn(['value'], {
        ...oldValue.get('value'),
        ...claim,
      }),
    )
    .setIn(['solutions', claimId], fromJS({ isLoaded: true, isDirty: false }));
}
