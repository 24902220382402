import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

import { buildUrl, prepareResponseFailure } from '../../helpers';
import { apiCall } from '../../services';

const ENDPOINT = `${APIConfiguration.sparePart}sparepart/sellingPrice`;

export interface SellingRateSummary {
  preTaxSellingPrice: number;
  vatRate: number;
  sellingPriceIncludedTax: number;
}

export default async function getSellingPriceForSellerQuery({
  sellerId,
  preTaxBuyingPrice,
}: {
  sellerId: string;
  preTaxBuyingPrice: number;
}): Promise<BackResponse<SellingRateSummary> | BackResponseFailure> {
  try {
    return await apiCall<BackResponse<SellingRateSummary>>(
      buildUrl(ENDPOINT, { sellerId, preTaxBuyingPrice }),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
