import styled from 'styled-components';

export const $CardContentThumbnailWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  padding: 0.25rem 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.borders.radius};
  margin-right: 1rem;
  max-width: 40px;
  height: 40px;
  overflow: hidden;
`;

export const $CardContentThumbnail = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  && svg {
    max-width: 36px;
    max-height: 36px;
  }

  && img {
    object-fit: contain;
    max-height: 4.285714rem;
    max-width: 4.285714rem;
  }
`;

export const $CardContentDescription = styled.div`
  flex: 2;
`;

export const $CardContentProductName = styled.div``;

export const $CardContentSerialNumber = styled.div`
  color: ${({ theme }) => theme.colors.tertiary};
`;

export const $CardContentContainer = styled.div`
  display: flex;
`;
