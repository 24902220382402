export function isEmpty<T>(value: T | undefined): boolean {
  if (value === null || value === undefined) {
    return true;
  }

  if (typeof value === 'string') {
    return value === '';
  }

  if (Array.isArray(value)) {
    return value.length === 0 || value.every((element) => isEmpty(element));
  }

  if (typeof value === 'object') {
    return Object.values(value).filter((x) => !!x).length === 0;
  }

  return false;
}
