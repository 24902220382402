import { AnyAction } from 'redux';
import { ActionMeta } from 'redux-actions';

import {
  BaseBackResponse,
  FileId,
  TechnicalIntervention,
} from '@savgroup-front-common/types';

import * as actionTypes from './actionTypes';
import { TECHNICAL_INTERVENTIONS_DOMAIN } from './constants';
import { TechnicalInterventionsState } from './types';

export const technicalInterventionsInitialState: TechnicalInterventionsState = {
  [TECHNICAL_INTERVENTIONS_DOMAIN.list]: {},
};

interface GetTechnicalInterventionByFileIdResponse extends BaseBackResponse {
  value: TechnicalIntervention;
}

const onGetTechnicalInterventionsByFileIdSuccess = (
  state: TechnicalInterventionsState,
  action: ActionMeta<
    GetTechnicalInterventionByFileIdResponse,
    { fileId: FileId }
  >,
): TechnicalInterventionsState => {
  const { value } = action.payload;
  const { fileId } = action.meta;
  const existingTechnicalInterventions =
    state[TECHNICAL_INTERVENTIONS_DOMAIN.list];

  return {
    ...state,
    [TECHNICAL_INTERVENTIONS_DOMAIN.list]: {
      ...existingTechnicalInterventions,
      [fileId]: value,
    },
  };
};

export default function technicalInterventionsReducer(
  state = technicalInterventionsInitialState,
  action: AnyAction,
): TechnicalInterventionsState {
  switch (action.type) {
    case actionTypes.GET_TECHNICAL_INTERVENTIONS_BY_FILE_ID.SUCCEEDED:
      return onGetTechnicalInterventionsByFileIdSuccess(
        state,
        action as ActionMeta<
          GetTechnicalInterventionByFileIdResponse,
          { fileId: FileId }
        >,
      );

    default:
      return state;
  }
}
