import { CustomerFileSummary, Model } from '@savgroup-front-common/types';
import { SolutionType } from 'myaccount/api/Claim/getSolutionTypeQuery';
import { FileStateHistorySummary } from 'myaccount/api/Workflow/getFileStatesQuery';
import { ProductsInfoDto } from 'myaccount/view/app/hooks/useGetModelByIdQuery';

export const adaptAllProducts = ({
  models,
  solutions,
  multiProductsInfoAdapted,
  multiHistories,
}: {
  models?: (Model | undefined)[];
  solutions?: SolutionType[];
  multiProductsInfoAdapted?: ProductsInfoDto[][];
  multiHistories?: FileStateHistorySummary[];
}) => {
  return multiProductsInfoAdapted
    ?.map((productsInfoAdapted) => {
      return {
        productsInfo: productsInfoAdapted?.map((productInfoAdapted) => {
          const model = models?.find(
            (model) => model?.id === productInfoAdapted.modelId,
          );

          const { uniqueName: solutionUniqueName, name: solutionName } =
            solutions?.find(
              (solution) => solution.id === productInfoAdapted.solutionId,
            ) || {
              uniqueName: '',
              name: '',
            };

          return {
            purchaseDate: productInfoAdapted.buyDate,
            fileReference: productInfoAdapted.fileReference,
            isClosed: productInfoAdapted.isClosed,
            serialNumber: productInfoAdapted.serialNumber,
            orderLineReference: productInfoAdapted.orderLineReference,
            fileId: productInfoAdapted.fileId,
            solutionUniqueName,
            solutionName,
            modelBrandName: model?.brandName,
            modelTypeName: model?.modelTypeName,
            modelTypeId: model?.modelTypeId,
            modelName: model?.name,
            modelPictureUrl: model?.pictureUrl,
            modelId: productInfoAdapted.modelId,
            displayName: model?.displayName,
            brandDisplayName: model?.brandDisplayName,
          };
        }),
        histories: multiHistories?.filter(
          (histories) => histories.fileId === productsInfoAdapted[0].fileId,
        ),
      };
    })
    .sort((a, b) => {
      const dateA = a.productsInfo[0]?.purchaseDate?.getTime();
      const dateB = b.productsInfo[0]?.purchaseDate?.getTime();

      if (dateA && dateB && dateA !== dateB) {
        return dateA - dateB;
      }

      const isClosedA = a.productsInfo[0].isClosed;
      const isClosedB = b.productsInfo[0].isClosed;

      if (dateA === dateB && isClosedA !== isClosedB) {
        return isClosedA && !isClosedB ? 1 : -1;
      }

      const regexp = new RegExp('[A-Za-z/-]*', 'g');
      const numberReferenceA = Number(
        a.productsInfo[0].fileReference.replaceAll(regexp, ''),
      );
      const numberReferenceB = Number(
        b.productsInfo[0].fileReference.replaceAll(regexp, ''),
      );

      if (
        dateA === dateB &&
        isClosedA === isClosedB &&
        numberReferenceA !== numberReferenceB
      ) {
        return numberReferenceB - numberReferenceA;
      }

      return 1;
    });
};

export const adaptMultiCustomerFileFullSummary = (
  response?: CustomerFileSummary[],
) => {
  return response?.map(
    (fileSummary) =>
      fileSummary &&
      fileSummary.fileProducts.map((fileProduct) => ({
        ...fileProduct,
        fileId: fileSummary.fileId,
        buyDate: new Date(fileSummary.buyDate),
        fileReference: fileSummary.fileReference,
        isClosed: fileSummary.isClosed,
        solutionId: fileSummary.solutionId,
      })),
  );
};
