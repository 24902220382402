import { ReverseMoneyNumberAmount } from '@savgroup-front-common/types';
import { CURRENCIES } from '@savgroup-front-common/constants';

export const ReverseMoneyHelper = {
  add: (
    a?: ReverseMoneyNumberAmount,
    b?: ReverseMoneyNumberAmount,
    ...rest: ReverseMoneyNumberAmount[]
  ) => {
    const total = (rest || []).reduce((acc, c) => {
      return acc + (c?.amount || 0);
    }, (a?.amount || 0) + (b?.amount || 0));

    return {
      amount: total,
      currency: a?.currency || CURRENCIES.EUR,
    };
  },
  sub: (a?: ReverseMoneyNumberAmount, b?: ReverseMoneyNumberAmount) => {
    return {
      amount: (a?.amount || 0) - (b?.amount || 0),
      currency: a?.currency || CURRENCIES.EUR,
    };
  },
  abs: (a?: ReverseMoneyNumberAmount) => {
    return {
      amount: Math.abs(a?.amount || 0),
      currency: a?.currency || CURRENCIES.EUR,
    };
  },
  multiplyByNumber: (a?: ReverseMoneyNumberAmount, b?: number) => {
    return {
      amount: (a?.amount || 0) * (b || 0),
      currency: a?.currency || CURRENCIES.EUR,
    };
  },
  multiply: (a?: ReverseMoneyNumberAmount, b?: ReverseMoneyNumberAmount) => {
    return {
      amount: (a?.amount || 0) * (b?.amount || 0),
      currency: a?.currency || CURRENCIES.EUR,
    };
  },
};
