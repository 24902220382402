import { defineMessages } from 'react-intl';

export default defineMessages({
  issues: {
    id: 'view.myaccount.step.issues.title',
    defaultMessage: 'Issue title',
  },
  chooseProduct: {
    id: 'view.myaccount.step.chooseProduct.title',
    defaultMessage: 'chooseProduct title',
  },
  claimGroupReason: {
    id: 'view.myaccount.step.claimGroupReason.title',
    defaultMessage: 'claimGroupReason title',
  },
  claimGroupDiagnosticTree: {
    id: 'view.myaccount.step.claimGroupDiagnosticTree.title',
    defaultMessage: 'claimGroupDiagnosticTree title',
  },
  claimGroupProductInfo: {
    id: 'view.myaccount.step.claimGroupProductInfo.title',
    defaultMessage: 'claimGroupProductInfo title',
  },
  claimGroupDocumentRelatedToProduct: {
    id: 'view.myaccount.step.claimGroupDocumentRelatedToProduct.title',
    defaultMessage: 'claimGroupDocument Related to Product title',
  },
  claimGroupDocumentRelatedToClaim: {
    id: 'view.myaccount.step.claimGroupDocumentRelatedToClaim.title',
    defaultMessage: 'claimGroupDocument Related to Claim title',
  },
  claimGroupSolution: {
    id: 'view.myaccount.step.claimGroupSolution.title',
    defaultMessage: 'claimGroupSolution title',
  },
  claimGroupDeposit: {
    id: 'view.myaccount.step.claimGroupDeposit.title',
    defaultMessage: 'claimGroupDeposit title',
  },
  claimGroupDelivery: {
    id: 'view.myaccount.step.claimGroupDelivery.title',
    defaultMessage: 'claimGroupDelivery title',
  },
  claimGroupConfirmation: {
    id: 'view.myaccount.step.claimGroupConfirmation.title',
    defaultMessage: 'claimGroupConfirmation title',
  },

  professionalHomePage: {
    id: 'view.b2b.professional.homePage',
    defaultMessage: 'Home',
  },
  professionalIssuesAndReasonsPage: {
    id: 'view.b2b.professional.issuesAndReasonsPage',
    defaultMessage: 'Issues and reasons',
  },
  professionalConfirmationPage: {
    id: 'view.b2b.professional.confirmationPage',
    defaultMessage: 'Confirmation',
  },
  professionalProductsToReturnPage: {
    id: 'view.b2b.professional.productsToReturnPage',
    defaultMessage: 'Products to return',
  },
  professionalSolutionPage: {
    id: 'view.b2b.professional.solutionPage',
    defaultMessage: 'Solution',
  },
  professionalHandlingPage: {
    id: 'view.b2b.professional.handlingPage',
    defaultMessage: 'Handling',
  },
});
