import { format, parse } from 'date-fns';

import { STANDARD_DATE_TIME_FORMAT_DATE_FNS } from '@savgroup-front-common/constants';
import { SALESFORCE_INTEGRATION_TYPE } from '@savgroup-front-common/types';

export const reformatDate = ({
  date,
  integrationType,
  destinationFormat,
}: {
  date?: string;
  integrationType?: SALESFORCE_INTEGRATION_TYPE;
  destinationFormat: string;
}) => {
  if (integrationType === SALESFORCE_INTEGRATION_TYPE.Standard) {
    return date
      ? format(new Date(date.replace('Z', '')), destinationFormat)
      : undefined;
  }

  return date
    ? format(
        parse(date, STANDARD_DATE_TIME_FORMAT_DATE_FNS, new Date()),
        destinationFormat,
      )
    : undefined;
};
