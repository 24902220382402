import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';

export const $QuotationLineHead = styled.div<{ $isActive?: boolean }>`
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  color: ${({ theme, $isActive = false }) =>
    $isActive ? theme.colors.mainTextColor : theme.colors.paragraphTextColor};
  text-align: left;

  padding: 1rem;
  background-color: ${({ theme, $isActive = false }) =>
    $isActive ? theme.colors.headerTitleColor : theme.colors.secondaryColor2};
`;

export const $QuotationLine = styled.div<{
  $isActive: boolean;
  $isSelected?: boolean;
}>`
  text-align: left;
  opacity: ${({ $isSelected = true }) => ($isSelected ? undefined : 0.4)};

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 1rem 0;
  color: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.mainTextColor : theme.colors.paragraphTextColor};

  @media ${media.maxWidth.sm} {
    margin-bottom: 0;
  }
`;

export const $AmountCell = styled.div`
  text-align: right;
`;

export const $Container = styled.div`
  margin-bottom: 1rem;
`;
