import styled from 'styled-components';
import { decelerateTimingFunctionTransition } from '../../animations/timingFunction';

interface $ContainerProps {
  $isOpen: boolean;
}

export const $Container = styled.div<$ContainerProps>`
  line-height: 0;
  & > svg {
    transform: ${({ $isOpen }) => {
      if ($isOpen) {
        return 'rotate(90deg)';
      }

      return 'rotate(0)';
    }};

    transition-duration: 0.2s;
    transition-property: transform;
    ${decelerateTimingFunctionTransition};
  }
`;
