import { defineMessages } from 'react-intl';

export default defineMessages({
  noIssuesFound: {
    id: 'components.myaccount.issues.noIssuesFound',
    defaultMessage: 'There is no issue found for this order',
  },
  contactCustomerServices: {
    id: 'components.myaccount.issues.contactCustomerServices',
    defaultMessage: 'Contact customer services for more information',
  },
});
