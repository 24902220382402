import useChooseProductCard from 'myaccount/view/app/hooks/useGetModelDetailByIdQuery';

import { OrderDetailsProduct } from '@savgroup-front-common/types';

const useResumeCardHeader = ({ product }: { product: OrderDetailsProduct }) => {
  const { modelDetail } = useChooseProductCard({ product });

  return { modelDetail };
};

export default useResumeCardHeader;
