import { v4 } from 'uuid';

import {
  QuotationNewModelSparePartsDto,
  RecipientType,
} from '@savgroup-front-common/types';

import { safeFormattedIntlString } from '../../../formatters';
import { getRecipientTypeMessage } from '../../../helpers/i18n/getRecipientTypeMessage';
import { AdaptedQuotationNewModelSparePart } from '../types/AdaptedQuotationNewModelSparePart';

export const adaptSparePartLine = ({
  sparePart,
}: {
  sparePart: QuotationNewModelSparePartsDto;
}): AdaptedQuotationNewModelSparePart => {
  return {
    id: sparePart.id || v4(),
    preTaxUnitBuyingPrice: sparePart?.sparePart?.preTaxUnitBuyingPrice,
    recommendedSalePriceIncludingTaxes: sparePart?.amountIncludedTax,
    isRequiredForRepair: sparePart?.isRequiredForRepair,
    isSelectedForRepair: sparePart?.isSelectedForRepair,
    requestLineId: sparePart?.sparePart?.requestLineId,
    condition: sparePart?.sparePart?.condition,
    sparePartId: sparePart?.sparePartId,
    sparePartSupplierAssociationId:
      sparePart?.sparePart?.sparePartSupplierAssociationId,
    sparePartSupplierId: sparePart?.supplierId,
    recipient: sparePart?.recipient
      ? {
          value: sparePart?.recipient?.recipientType,
          data: sparePart?.recipient?.recipientType,
          label: safeFormattedIntlString(
            getRecipientTypeMessage(sparePart?.recipient?.recipientType),
          ),
        }
      : {
          value: RecipientType.OWNER,
          data: RecipientType.OWNER,
          label: safeFormattedIntlString(
            getRecipientTypeMessage(RecipientType.OWNER),
          ),
        },
    supplierName: sparePart?.sparePart?.supplierName,
    supplierReference: sparePart?.sparePart?.supplierReference,
    manufacturerReference: sparePart?.sparePart?.manufacturerReference,
    manufacturerId: sparePart?.sparePart?.manufacturerId,
    manufacturerName: sparePart?.sparePart?.manufacturerName,
    manufacturerLabel: sparePart?.sparePart?.manufacturerLabel,
    subCategory: sparePart?.sparePart?.subCategory,
    stockName: sparePart?.sparePart?.stockName,
  };
};
