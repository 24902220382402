import { useSelector } from 'react-redux';

import { PERMISSIONS } from '@savgroup-front-common/constants';
import { selectPermissions } from '../domains/login/selectors';

import { useIsSuperAdmin } from './useHasRole';

export const useActivePermissions = (): PERMISSIONS[] => {
  return useSelector(selectPermissions);
};

const hasPermission = (
  permissions: PERMISSIONS[],
  neededPermission: PERMISSIONS,
): boolean => permissions.includes(neededPermission);

export function useHasPermission(permission: PERMISSIONS): boolean {
  const activePermissions = useActivePermissions();
  const isSuperAdmin = useIsSuperAdmin();

  return hasPermission(activePermissions, permission) || isSuperAdmin;
}

export function useHasEveryPermissions(permissions: PERMISSIONS[]): boolean {
  const activePermissions = useActivePermissions();
  const isSuperAdmin = useIsSuperAdmin();

  return permissions.every(
    (permission) =>
      hasPermission(activePermissions, permission) || isSuperAdmin,
  );
}

export function useHasSomePermissions(permissions: PERMISSIONS[]): boolean {
  const activePermissions = useActivePermissions();
  const isSuperAdmin = useIsSuperAdmin();

  return permissions.some(
    (permission) =>
      hasPermission(activePermissions, permission) || isSuperAdmin,
  );
}

export function hasSomePermissions(
  activePermissions: PERMISSIONS[],
  permissions: PERMISSIONS[],
): boolean {
  return permissions.some((permission) =>
    hasPermission(activePermissions, permission),
  );
}
