import { defineMessages } from 'react-intl';

export default defineMessages({
  titleCard: {
    id: 'components.myaccount.fileHistory.titleCard',
    defaultMessage: "File's history",
  },
  emptyHistory: {
    id: 'components.myaccount.fileHistory.emptyHistory',
    defaultMessage: 'No history available',
  },
});
