import { defineMessages } from 'react-intl';

export default defineMessages({
  joinAnAttachment: {
    id: 'view.SectionTitle.joinAnAttachment',
    defaultMessage: 'Join a picture or a video of your problem',
  },
  fileInput: {
    id: 'view.card.FileAttachmentsSection.button',
    defaultMessage: 'Attach file',
  },
  dragAndDropFiles: {
    id: 'view.import.fileUploader.dragAndDropFiles',
    defaultMessage: 'Drag and drop files here',
  },
});
