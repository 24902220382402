import * as Yup from 'yup';

import { DEVICE_PASSWORD_FORM_FIELDS } from './DevicePasswordForm.types';
import messages from './messages';

function devicePasswordFormSchema() {
  return Yup.object().shape({
    [DEVICE_PASSWORD_FORM_FIELDS.devicePassword]: Yup.string().required(
      messages.requiredDevicePassword,
    ),
  });
}

export default devicePasswordFormSchema;
