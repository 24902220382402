import { defineMessages } from 'react-intl';

export default defineMessages({
  titleCard: {
    id: 'components.myaccount.productInfo.titleCard',
    defaultMessage: 'File n° {fileReference} ',
  },
  titleCardDate: {
    id: 'components.myaccount.productInfo.titleCardDate',
    defaultMessage: 'from {date}',
  },
  noProductDetected: {
    id: 'components.myaccount.productInfo.noProductDetected',
    defaultMessage: 'no product detected',
  },
  isAllReturned: {
    id: 'components.myaccount.productInfo.isAllReturned',
    defaultMessage: 'all file returned',
  },
  isClosed: {
    id: 'components.myaccount.productInfo.isClosed',
    defaultMessage: 'File closed',
  },
  isOpen: {
    id: 'components.myaccount.productInfo.isOpen',
    defaultMessage: 'File opened',
  },
  serialNumber: {
    id: 'components.myaccount.productInfo.serialNumber',
    defaultMessage: 'Serial number: {serialNumber}',
  },
  unknownMessage: {
    id: 'components.myaccount.productInfo.unknownMessage',
    defaultMessage: 'Unknown',
  },
  multiproductLength: {
    id: 'components.myaccount.productInfo.multiproductLength',
    defaultMessage: '{itemsLength} articles',
  },
  quantity: {
    id: 'components.myaccount.productInfo.quantity',
    defaultMessage: 'Quantity: {quantity}',
  },
});
