import { useQuery } from 'react-query';

import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { PaymentService } from 'myaccount/api';

const GetOpenedInvoiceWithClaimGroupQuery =
  'getOpenedInvoiceWithClaimGroupQuery';

export const useGetOpenedInvoiceWithClaimGroup = ({
  claimGroupId,
}: {
  claimGroupId?: string;
}) => {
  const { pushErrors } = useToasts();

  const { data: invoice } = useQuery(
    [GetOpenedInvoiceWithClaimGroupQuery],
    async () => {
      if (!claimGroupId) {
        return undefined;
      }

      const response = await PaymentService.getOpenedInvoiceQuery({
        claimGroupId,
      });

      if (response.failure) {
        if (response.statusCode === 400) {
          return undefined;
        }

        pushErrors(response.errors);

        return undefined;
      }

      return response.value;
    },
  );

  return {
    invoice,
  };
};
