import React, { FC } from 'react';

import { Modal, MODAL_SIZES } from '../../../../atoms/modal';
import { BaseLoader } from '../../../../molecules/BaseLoader';

import { useQrCodeImportModal } from './QrCodeImportModal.hooks';
import { QrCodeImportModalContent } from './QrCodeImportModalContent/QrCodeImportModalContent';

interface QrCodeImportProps {
  isOpen: boolean;
  isSubmitLoading: boolean;
  onUploadFinishClick: () => void;
  additionalInformationId: string;
  claimId: string;
}

export const QrCodeImportModal: FC<QrCodeImportProps> = ({
  isOpen,
  onUploadFinishClick,
  additionalInformationId,
  claimId,
  isSubmitLoading,
}) => {
  const { uploadQrCode, isLoading, uploadUrl } = useQrCodeImportModal({
    additionalInformationId,
    claimId,
  });

  return (
    <Modal
      isOpen={isOpen}
      size={MODAL_SIZES.SMALL}
      shouldCloseOnDimmerClick
      onClose={onUploadFinishClick}
    >
      {isLoading && <BaseLoader />}

      {uploadQrCode && (
        <QrCodeImportModalContent
          uploadQrCode={uploadQrCode}
          onUploadFinishClick={onUploadFinishClick}
          isSubmitLoading={isSubmitLoading}
          uploadUrl={uploadUrl}
        />
      )}
    </Modal>
  );
};

QrCodeImportModal.displayName = 'QrCodeImport';
