import { defineMessages } from 'react-intl';

export default defineMessages({
  rejectQuote: {
    id: 'components.myaccount.myReturn.quoteInformation.rejectQuote',
    defaultMessage: 'Reject quote',
  },
  approveQuote: {
    id: 'components.myaccount.myReturn.quoteInformation.approveQuote',
    defaultMessage: 'Accept quote',
  },
  date: {
    id: 'components.myaccount.myReturn.quoteInformation.date',
    defaultMessage: 'Send the : ',
  },
  downloadQuote: {
    id: 'components.myaccount.myReturn.quoteInformation.downloadQuote',
    defaultMessage: 'Télécharger le devis',
  },
  quoteReference: {
    id: 'components.myaccount.myReturn.quoteInformation.quoteNumber',
    defaultMessage: 'Validate the quote',
  },
  vatIncluded: {
    id: 'components.myaccount.myReturn.quoteInformation.vatIncluded',
    defaultMessage: 'VAT incl.',
  },
  vat: {
    id: 'components.myaccount.myReturn.quoteInformation.vat',
    defaultMessage: 'VAT',
  },
  spareParts: {
    id: 'components.myaccount.myReturn.quoteInformation.spareParts',
    defaultMessage: 'Spare parts :',
  },
  labor: {
    id: 'components.myaccount.myReturn.quoteInformation.labor',
    defaultMessage: 'Labor :',
  },
  package: {
    id: 'components.myaccount.myReturn.quoteInformation.package',
    defaultMessage: 'Package (spare parts and labor incl.) :',
  },
  totalVATExcluded: {
    id: 'components.myaccount.myReturn.quoteInformation.totalVATExcluded',
    defaultMessage: 'Total VAT excl.',
  },
  VAT: {
    id: 'components.myaccount.myReturn.quoteInformation.VAT',
    defaultMessage: 'VAT',
  },
  totalVATIncluded: {
    id: 'components.myaccount.myReturn.quoteInformation.totalVATIncluded',
    defaultMessage: 'Total VAT incl.',
  },
  noQuoteProvided: {
    id: 'components.myaccount.myReturn.quoteInformation.noQuoteProvided',
    defaultMessage: 'No quote at the moment',
  },
  comment: {
    id: 'components.myaccount.myReturn.quoteInformation.comment',
    defaultMessage: 'write a comment here',
  },
  commentMandatory: {
    id: 'components.myaccount.myReturn.quoteInformation.commentMandatory',
    defaultMessage: 'This field is required',
  },
  leaveAcomment: {
    id: 'components.myaccount.myReturn.quoteInformation.leaveAcomment',
    defaultMessage: 'Leave a comment',
  },
  validate: {
    id: 'general.validate',
    defaultMessage: 'Valider',
  },
  description: {
    id: 'components.myaccount.myReturn.quoteInformation.titleDescription',
    defaultMessage: 'Description',
  },
  quoteComment: {
    id: 'components.myaccount.myReturn.quoteInformation.quoteComment',
    defaultMessage: 'Comment',
  },
  downloadQuoteVoucher: {
    id: 'components.myaccount.myReturn.quoteInformation.downloadQuoteVoucher',
    defaultMessage: 'Download quote voucher',
  },
  'components.myaccount.quote.noTransitionFound': {
    id: 'components.myaccount.quote.noTransitionFound',
    defaultMessage: 'Missing transition',
  },
});
