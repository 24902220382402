import { fromJS } from 'immutable';
import get from 'lodash/get';

export function onProductLoading(state, { meta }) {
  const claimId = get(meta, ['claimId']);

  if (!claimId) {
    return state;
  }

  return state.setIn(
    ['products', claimId],
    fromJS({ isLoaded: false, isDirty: false, value: undefined }),
  );
}

export function onProductErrored(state, { meta }) {
  const claimId = get(meta, ['claimId']);

  if (!claimId) {
    return state;
  }

  // TODO errors
  return state.updateIn(['products', claimId], (oldValue) =>
    oldValue.setIn(['isDirty'], true).setIn(['hasErrors'], true),
  );
}

export function onProductLoaded(state, { payload, meta }) {
  const claimId = get(meta, ['claimId']);
  const product = get(payload, ['value']);

  if (!claimId) {
    return state;
  }

  return state.setIn(
    ['products', claimId],
    fromJS({ isLoaded: true, isDirty: false }).setIn(['value'], product),
  );
}
