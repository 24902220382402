import React, { FunctionComponent } from 'react';

import { GetFullClaimContextQueryReturnValues } from '@savgroup-front-common/core/src/api/Claim/getFullClaimContextQuery';
import { Tag } from '@savgroup-front-common/core/src/atoms/Tag';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';

import FileDocument from './FileDocument/FileDocument';
import useGetDocuments from './FileDocuments.hooks';
import {
  $Divider,
  $DocumentsWrapper,
  $FileHeader,
  $Header,
  $NoDocumentAvailable,
  $Wrapper,
} from './FileDocuments.styles';
import messages from './messages';

const FileDocuments: FunctionComponent<{
  fullClaim: GetFullClaimContextQueryReturnValues;
}> = ({ fullClaim }) => {
  const { allDocuments } = useGetDocuments({ fullClaim });

  return (
    <$Wrapper>
      <$Header>
        <$FileHeader>
          <SafeFormattedMessageWithoutSpread message={messages.titleCard} />
        </$FileHeader>

        <Tag isSmall primary>
          {allDocuments?.length}
        </Tag>
      </$Header>
      <$Divider />
      <$DocumentsWrapper>
        {allDocuments?.map((document) => (
          <FileDocument
            key={
              document.originalFileName ||
              document.attachmentId ||
              document.documentName
            }
            document={document}
          />
        ))}

        {allDocuments.length === 0 && (
          <$NoDocumentAvailable>
            <SafeFormattedMessageWithoutSpread message={messages.noDocuments} />
          </$NoDocumentAvailable>
        )}
      </$DocumentsWrapper>
    </$Wrapper>
  );
};

FileDocuments.displayName = 'FileDocuments';
export default FileDocuments;
