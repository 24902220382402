import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  FileLabel,
} from '@savgroup-front-common/types';

 
const ENDPOINT = `${APIConfiguration.carrier}carriers/files/${COMMON_QUERY_PARAMS.FILE_ID}/labels`;

interface GetCarrierLabelsInfosQueryArgs {
  fileId: string;
}

const getCarrierLabelsInfosQuery = async ({
  fileId,
}: GetCarrierLabelsInfosQueryArgs): Promise<
  BackResponse<FileLabel[] | undefined> | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId);

    const response = await apiCall<BackResponse<FileLabel[] | undefined>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default getCarrierLabelsInfosQuery;
