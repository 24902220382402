import { all, call, put, takeEvery } from 'redux-saga/effects';

import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import { callAndGetResponse } from '@savgroup-front-common/core/src/services';

import * as ActionTypes from './actionTypes';

export function* setFileActionWorker({ payload = {} }) {
  const { fileId, fromState, toState, comment, toCustomerComment, module } =
    payload;

  yield call(
    callAndGetResponse,
    ActionTypes.SET_FILE_ACTION,
    `${APIConfiguration.workflow}files/${fileId}/actions`,
    {
      method: SUPPORTED_METHODS.PUT,
      json: {
        fromState,
        toState,
        module,
        comment,
        toCustomerComment,
      },
    },
  );
  yield put(ActionTypes.SET_FILE_ACTION.end());
}
function* setFileActionWatcher() {
  yield takeEvery(ActionTypes.SET_FILE_ACTION.BASE, setFileActionWorker);
}

export default function* workflowSaga() {
  try {
    yield all([setFileActionWatcher()]);
  } catch (error) {
    logCritical(error);
  }
}
