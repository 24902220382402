import React, { FunctionComponent, useContext } from 'react';

import { logError } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';

import { ContentTemplateContext } from '../../MDXCustomComponents.context';

import { STORE_ACTOR_FIELD_NAMES } from './StoreActor.types';

interface StoreActorProps {
  fieldName?: STORE_ACTOR_FIELD_NAMES;
}

const StoreActor: FunctionComponent<
  React.PropsWithChildren<StoreActorProps>
> = ({ fieldName }) => {
  const { isLoading, storeActor } = useContext(ContentTemplateContext);

  if (
    !fieldName ||
    !Object.values(STORE_ACTOR_FIELD_NAMES).includes(fieldName)
  ) {
    logError(
      `FieldName should be provided from one of this list [${Object.values(
        STORE_ACTOR_FIELD_NAMES,
      ).join(', ')}]`,
    );

    return <></>;
  }

  if (isLoading) {
    return <></>;
  }

  const address = storeActor?.address.address;
  const postalCode = storeActor?.address.postalCode;
  const city = storeActor?.address.city;

  return (
    <>
      {fieldName === STORE_ACTOR_FIELD_NAMES.ADDRESS && (
        <div>
          <div>{address}</div>
          <div>
            {postalCode}, {city}
          </div>
        </div>
      )}
    </>
  );
};

StoreActor.displayName = 'StoreActor';

export default StoreActor;
