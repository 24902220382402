import { logError } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';

export interface UrlExistsArgs {
  url: string;
}

async function urlExists({ url }: UrlExistsArgs): Promise<boolean> {
  try {
    const response = await fetch(url, {
      method: SUPPORTED_METHODS.HEAD,
    });

    return response.status === 200;
  } catch (error) {
    logError(error);

    return false;
  }
}

export default urlExists;
