import { NavArrowUp } from 'iconoir-react';
import React, { FunctionComponent } from 'react';

import { IconPropsInterface } from '../Icon.types';

export const ArrowUpIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => <NavArrowUp width={size} height={size} color={color} />;

ArrowUpIcon.displayName = 'ArrowUpIcon';
