export enum ADDRESS_MANAGEMENT_FIELDS {
  FIRST_NAME = 'addressSelected.firstName',
  LAST_NAME = 'addressSelected.lastName',
  CIVILITY = 'addressSelected.civility',
  COMPANY_NAME = 'addressSelected.companyName',
  PHONE = 'addressSelected.phone',
  COUNTRY_CODE = 'addressSelected.countryCode',
  ADDRESS = 'addressSelected.address',
  CITY = 'addressSelected.city',
  ADDITIONAL_ADDRESS = 'addressSelected.additionalAddress',
  POSTAL_CODE = 'addressSelected.postalCode',
  HAS_ELEVATOR = 'addressSelected.hasElevator',
  HAS_PARKING_SPACE = 'addressSelected.hasParkingSpace',
  HOUSING_TYPE = 'addressSelected.housingType',
  FLOOR = 'addressSelected.floor',
  DOOR_CODE = 'addressSelected.doorCode',
  ADDITIONAL_INFORMATION = 'addressSelected.additionalInformation',
}
