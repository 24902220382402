/* eslint-disable max-len */
import React, { FC } from 'react';
import { useTheme } from 'styled-components';

const PictureCalendar: FC = () => {
  const theme = useTheme();

  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000Z/svg"
    >
      <path
        d="M41.75 17V12.5M41.75 17V21.5M41.75 17H31.625M14.75 30.5V21.5C14.75 20.3065 15.2241 19.1619 16.068 18.318C16.9119 17.4741 18.0565 17 19.25 17H23.75M23.75 12.5V21.5M55.25 30.5V21.5C55.25 20.3065 54.7759 19.1619 53.932 18.318C53.0881 17.4741 51.9435 17 50.75 17H49.625M14.75 30.5V50.75C14.75 51.9435 15.2241 53.0881 16.068 53.932C16.9119 54.7759 18.0565 55.25 19.25 55.25H50.75C51.9435 55.25 53.0881 54.7759 53.932 53.932C54.7759 53.0881 55.25 51.9435 55.25 50.75V30.5H14.75Z"
        stroke="#2A2833"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="23" cy="37.5" r="2.5" fill={theme.colors.primary} />
      <circle cx="23" cy="46.5" r="2.5" fill={theme.colors.primary} />
      <circle cx="31" cy="37.5" r="2.5" fill={theme.colors.primary} />
      <circle cx="31" cy="46.5" r="2.5" fill={theme.colors.primary} />
      <circle cx="39" cy="37.5" r="2.5" fill={theme.colors.primary} />
      <circle cx="39" cy="46.5" r="2.5" fill={theme.colors.primary} />
      <circle cx="47" cy="37.5" r="2.5" fill={theme.colors.primary} />
      <circle cx="47" cy="46.5" r="2.5" fill={theme.colors.primary} />
    </svg>
  );
};

PictureCalendar.displayName = 'PictoCalendar';

export default PictureCalendar;
