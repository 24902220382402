import React, { FunctionComponent, RefObject } from 'react';

import { MENU_POSITIONS, MenuItem } from './Menu.types';
import MenuContent from './MenuContent';

interface MenuitemProps {
  menuItems: MenuItem[];
  isOpen: boolean;
  onClose?: () => void;
  position?: MENU_POSITIONS | { top: string; left: string };
  dataTestId?: string;
  wrapperRef?: RefObject<any>;
  wrapperRefs?: RefObject<any>[];
  componentThemeName?: string;
}
const Menu: FunctionComponent<MenuitemProps> = ({
  menuItems = [],
  isOpen = false,
  onClose = () => undefined,
  position,
  dataTestId,
  wrapperRef,
  wrapperRefs,
  componentThemeName,
}) => {
  if (menuItems.length === 0 || !isOpen) {
    return null;
  }

  return (
    <MenuContent
      menuItems={menuItems}
      onClose={onClose}
      position={position}
      dataTestId={dataTestId}
      wrapperRef={wrapperRef}
      wrapperRefs={wrapperRefs}
      componentThemeName={componentThemeName}
    />
  );
};

Menu.displayName = 'Menu';

export default Menu;
