import { defineMessages } from 'react-intl';

export default defineMessages({
  solutionTitle: {
    id: 'components.myaccount.claims.handling.cards.solutionTitle',
    defaultMessage: 'Solution : ',
  },
  modifyButton: {
    id: 'components.myaccount.claims.handling.cards.modifyButton',
    defaultMessage: 'Modify',
  },
});
