import React, { FC, useRef, useState } from 'react';
import { $InputGroup } from '../CartManager/CartManager.styles';
import { Input } from '@savgroup-front-common/core/src/atoms/Form';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { KeyboardIcon } from '@savgroup-front-common/core/src/protons/icons';
import messages from '../CartManager/messages';
import { BUTTON_TYPES, POSITIONS } from '@savgroup-front-common/constants';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';

interface SearchModelProps {
  handleAddProduct: (searchValue: string) => Promise<undefined>;
  isAddProductLoading: boolean;
}

const SearchModel: FC<SearchModelProps> = ({
  handleAddProduct,
  isAddProductLoading,
}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const ref = useRef<HTMLInputElement>(null);

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();

        await handleAddProduct(searchValue);

        setSearchValue('');
      }}
    >
      <$InputGroup>
        <Input
          placeholder={messages.searchModelPlaceholder}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          onKeyPress={async (e) => {
            if (e.key === 'Enter') {
              e.preventDefault();

              await handleAddProduct(searchValue);
              setSearchValue('');

              if (ref?.current) {
                ref.current.focus();
              }
            }
          }}
          value={searchValue}
          icon={<KeyboardIcon />}
          iconPosition={POSITIONS.RIGHT}
          ref={ref}
        />
        <Button
          type={BUTTON_TYPES.BUTTON}
          primary
          hollow
          isLoading={isAddProductLoading}
          onClick={async () => {
            await handleAddProduct(searchValue);
            setSearchValue('');

            if (ref?.current) {
              ref.current.focus();
            }
          }}
        >
          <SafeFormattedMessageWithoutSpread message={messages.add} />
        </Button>
      </$InputGroup>
    </form>
  );
};

SearchModel.displayName = 'SearchModel';

export default SearchModel;
