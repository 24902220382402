import { useQuery } from 'react-query';

import { ClaimService } from 'myaccount/api';

export const GetSolutionTypeQuery = 'getSolutionTypeQuery';

export const useGetSolutionTypeQuery = ({
  solutionTypeId,
}: {
  solutionTypeId?: string;
}) => {
  const { data: solution, isLoading } = useQuery(
    [GetSolutionTypeQuery, { solutionTypeId }],
    async () => {
      if (!solutionTypeId) {return undefined;}

      const response = await ClaimService.getSolutionTypeQuery({
        solutionTypeId,
      });

      if (response.failure) {
        return undefined;
      }

      return response.value;
    },
  );

  return {
    solution,
    isLoading,
  };
};
