import { OwnerProductSummary } from '@savgroup-front-common/types';
import { OwnerProductSummaryDto } from '../HomePage.types';

export const adaptCustomerProducts = (
  products: OwnerProductSummary[],
): OwnerProductSummaryDto[] => {
  return products
    .map((product) => ({
      ...product,
      buyDate: product.buyDate ? new Date(product.buyDate) : undefined,
    }))
    .sort((a, b) => {
      if (
        (a.buyDate && a.buyDate.getFullYear() < 1900) ||
        !a.buyDate ||
        !b.buyDate
      ) {
        return -1;
      }

      return b.buyDate?.getTime() - a.buyDate?.getTime();
    });
};
