import styled from 'styled-components';

export const $ResumeDescriptionProduct = styled.div`
  display: flex;
  flex-direction: column;
  background: #f7f7f7;
  border-radius: 8px;
  padding: 18px 24px;
  height: fit-content;
`;

export const $ResumeProduct = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
`;

export const $CardContentThumbnail = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e1e4e8;
  border-radius: 4px;
  margin-right: 16px;
  padding: 2px;
  max-width: 40px;
  max-height: 40px;
  overflow: hidden;
  img {
    object-fit: contain;
    max-height: 4.28571rem;
    max-width: 4.28571rem;
  }
`;

export const $ResumeInfoProductTitle = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.systemColors.defaultTextColor};
`;

export const $ResumeInfoProductSerialNumber = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  font-size: 14px;
  line-height: 16px;
  color: #8a94a6;
`;

export const $ResumeInfoProductTag = styled.div`
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.success};
`;

export const $ResumeModalOpenButtonContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const $ResumeModalOpenButton = styled('button')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
  width: fit-content;
  padding: 0.5rem 1rem;
  border: 1px solid #c9ced6;
  border-radius: 4px;
`;
