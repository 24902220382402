import { createAction } from '@reduxjs/toolkit';

import { FileId } from '@savgroup-front-common/types';

import * as actionType from './actionTypes';

export interface GetTechnicalInterventionByFileIdPayload {
  fileId: FileId;
}

export const getTechnicalInterventionsByFileId =
  createAction<GetTechnicalInterventionByFileIdPayload>(
    actionType.GET_TECHNICAL_INTERVENTIONS_BY_FILE_ID.BASE,
  );
