import { IssuesReasonsPage } from '../../pages/professionalPages/IssuesReasonsPage';
import { ProductsToReturnPage } from '../../pages/professionalPages/ProductsToReturnPage';
import { ClaimGroupConfirmationPage } from '../../pages/professionalPages/claimGroupConfirmationPage';
import { ClaimGroupSolutionPage } from '../../pages/professionalPages/claimGroupSolutionPage';
import { ClaimGroupDepositPage } from '../../pages/professionalPages/claimGroupDepositPage';
import { ClaimGroupDeliveryPage } from '../../pages/professionalPages/claimGroupDeliveryPage';
import { ROUTES_PROFESSIONAL } from 'myaccount/view/app/routes/ProfessionalRoutes';
import { BASE_ROUTES } from 'myaccount/view/app/routes/Routes';
import HomePageProfessional from 'myaccount/view/pages/professionalPages/HomePage/HomePageProfessional';

import FileDetailPage from '../../newDesignPages/FileFollowupsPage/FileFollowupsPage';

export interface RoutesConfig {
  name: PROFESSIONAL_STEP_CONFIG | string;
  path: string;
  component: React.FC;
}

export enum PROFESSIONAL_STEP_CONFIG {
  PROFESSIONAL_ISSUES_REASONS_PAGE = 'ProfessionalIssuesReasonsPage',
  PROFESSIONAL_PRODUCTS_TO_RETURN_PAGE = 'ProfessionalProductsToReturnPage',
  PROFESSIONAL_DEPOSIT_PAGE = 'ProfessionalDepositPage',
  PROFESSIONAL_DELIVERY_PAGE = 'ProfessionalDeliveryPage',
  PROFESSIONAL_SOLUTION_PAGE = 'ProfessionalSolutionPage',
  PROFESSIONAL_CONFIRMATION_PAGE = 'ProfessionalConfirmationPage',
  PROFESSIONAL_FILE_FOLLOWUPS_PAGE = 'ProfessionalFileFollowups',
}
export enum PROFESSIONAL_HOME_CONFIG {
  PROFESSIONAL_HOME_PAGE = 'HomePageProfessional',
}

export const PROFESSIONAL_STEP_PROGRESS: Record<string, string[]>[] = [
  {
    issue: [
      PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_ISSUES_REASONS_PAGE.toString(),
    ],
  },
  {
    scanProducts: [
      PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_PRODUCTS_TO_RETURN_PAGE.toString(),
    ],
  },
  {
    summary: [
      PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_SOLUTION_PAGE.toString(),
      PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_DEPOSIT_PAGE.toString(),
      PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_DELIVERY_PAGE.toString(),
      PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_CONFIRMATION_PAGE.toString(),
    ],
  },
];

export const professionalStepsConfig: RoutesConfig[] = [
  {
    name: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_ISSUES_REASONS_PAGE,
    path: ROUTES_PROFESSIONAL.ISSUES_REASONS,
    component: IssuesReasonsPage,
  },
  {
    name: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_PRODUCTS_TO_RETURN_PAGE,
    path: ROUTES_PROFESSIONAL.PRODUCTS_TO_RETURN,
    component: ProductsToReturnPage,
  },
  {
    name: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_SOLUTION_PAGE,
    path: ROUTES_PROFESSIONAL.SOLUTIONS,
    component: ClaimGroupSolutionPage,
  },
  {
    name: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_DEPOSIT_PAGE,
    path: ROUTES_PROFESSIONAL.DEPOSIT,
    component: ClaimGroupDepositPage,
  },
  {
    name: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_DELIVERY_PAGE,
    path: ROUTES_PROFESSIONAL.DELIVERY,
    component: ClaimGroupDeliveryPage,
  },
  {
    name: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_CONFIRMATION_PAGE,
    path: ROUTES_PROFESSIONAL.CONFIRMATION,
    component: ClaimGroupConfirmationPage,
  },
];

export const otherPageProfessionalConfig: RoutesConfig[] = [
  {
    name: PROFESSIONAL_HOME_CONFIG.PROFESSIONAL_HOME_PAGE,
    path: `${BASE_ROUTES.PROFESSIONAL}${BASE_ROUTES.HOME}`,
    component: HomePageProfessional,
  },
  {
    name: PROFESSIONAL_HOME_CONFIG.PROFESSIONAL_HOME_PAGE,
    path: `${BASE_ROUTES.PROFESSIONAL}${BASE_ROUTES.HOME}/:homeTab`,
    component: HomePageProfessional,
  },
  {
    name: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_FILE_FOLLOWUPS_PAGE,
    path: ROUTES_PROFESSIONAL.FILE_FOLLOWUPS,
    component: FileDetailPage,
  },
];
