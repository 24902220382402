export enum FILE_STATES {
  CLOSED = 'Cloture',
  REFUNDED = 'Rbt_effectue',
  OUT_OF_WARRANTY_NON_CARRIABLE = 'Dossier_HG_non_transportable',
  WAITING_FOR_HANDLING = 'Depot_produit_en_attente',
  UNLOCK_CODE_REQUESTED = 'Code_deverouillage_attente',
  CLAIM_VALIDATED = 'Claim_validee',
  REFUND = 'Retract_validee',
  CLAIM_TO_VALIDATE = 'claim_to_validate',

  QUOTE_TO_VALIDATE = 'Devis_emis',
  QUOTE_VALIDATED = 'Devis_valide',
  REQUESTED_RETURN_QUOTE_REJECTED = 'Devis_refuse_retour',

  // TODO: REV-7798 responsible to remove ACH_PRISE_DE_RDV_INTERVENTION_DOMICILE_STATUS_02 entry
  InterventionDomicile = 'Intervention_Domicile',
  NEW = 'New',
  PLANNED = 'Planned',

  ISSUE_QUOTE = 'Attente_emission_devis',
  AWAITING_PAYMENT = 'Paiement_en_attente',
  PRODUCT_RETURN_CHOICE = 'wfmodulestate4',
  REQUEST_TO_RETURN_IN_THE_STATE = 'wfmodulestate5',
  PAYMENT_ACCEPTED = 'Paiement_ok',
  AWAITING_ADDITINAL_INFO = 'Claim_info_manquante',
  AWAITING_ACCOUNT_DESACTIVATION = 'Attente_desactivation_compte',
  AWAITING_APPOINTMENT_CONFIRMATION = 'Awaiting_appointment_confirmation',
  AWAITING_DATE_PICKUP = 'waiting_pickup',
}

export const fileStatesMap = {
  PRODUCT_ABANDON_QUOTE_REJECTED: {
    legacy: 'dev_refuse_abandon',
    new: 'Devis_refuse_abandon',
  },
};

export enum REFERENCED_MODULE_TYPES {
  TECHNICAL_INTERVENTION_MODULE = 'TechnicalInterventionModule',
}
