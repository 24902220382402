import { useSelector } from 'react-redux';

import { AddressInfoDto } from '@savgroup-front-common/types';

import {
  formatCompanyAddressLine,
  formatCustomerAddressLine,
} from '../formatters/address';
import { getCountryTranslationSelector } from '../domains/i18n/selectors';

const useAddressCompanyFormat = (address: Partial<AddressInfoDto>) => {
  const getCountry = useSelector(getCountryTranslationSelector);

  return formatCompanyAddressLine(address, getCountry);
};

export default useAddressCompanyFormat;
export const useAddressCustomerFormat = (address: AddressInfoDto) => {
  const getCountry = useSelector(getCountryTranslationSelector);

  return formatCustomerAddressLine(address, getCountry);
};
