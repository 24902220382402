import React, { ChangeEventHandler, FunctionComponent } from 'react';

import { INPUT_TYPES } from '@savgroup-front-common/constants/src';

import { isRoundNumber } from '../../../../helpers';
import Input from '../Input';
import { InputProps, InputPropsRef } from '../Input.types';

interface NumberInputProps extends InputProps {
  onChange: ChangeEventHandler<HTMLInputElement>;
  value?: string | number;
}
interface NumberInputRefProps extends InputPropsRef {
  onChange: ChangeEventHandler<HTMLInputElement>;
  value?: string | number;
}

const NumberInput: FunctionComponent<
  React.PropsWithChildren<NumberInputRefProps>
> = ({ onChange, shouldFocusOnMount, value, forwardedRef, type, ...props }) => {
  const customOnChange: React.ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    if (isRoundNumber(event.target.value)) {
      return onChange(event);
    }

     
    event.target.value = (value || '').toString();

    return onChange(event);
  };

  return (
    <Input
      {...props}
      type={type ?? INPUT_TYPES.TEXT}
      value={value}
      onChange={customOnChange}
      ref={forwardedRef}
      shouldFocusOnMount={shouldFocusOnMount}
      pattern="\d*"
      autofocus={shouldFocusOnMount}
    />
  );
};

NumberInput.displayName = 'NumberInput';

export default React.forwardRef<HTMLInputElement, NumberInputProps>(
  (props, ref) => <NumberInput forwardedRef={ref} {...props} />,
);
