import React, { FC } from 'react';
import { useMedia } from 'react-use';

import { media } from '@savgroup-front-common/constants';
import { Solution } from 'myaccount/api/Claim/getSolutionsByClaim';
import {
  ChooseItemCard,
  ChooseItemCardMobileView,
} from 'myaccount/view/components/ChooseItemCard';
import { CHOOSE_ITEM_CARD_TYPE } from 'myaccount/view/components/ChooseItemCard/ChooseItemCard.type';

import { useSolutionItem } from './SolutionItem.hooks';

interface SolutionItemProps {
  solution: Solution;
  onClick: () => void;
  dataTestId?: string;
}

const SolutionItem: FC<SolutionItemProps> = ({
  solution,
  onClick,
  dataTestId,
}) => {
  const isMobileView = useMedia(media.maxWidth.xs);
  const { iconKey } = useSolutionItem({
    solutionTypeId: solution.solutionTypeId,
  });

  if (isMobileView) {
    return (
      <ChooseItemCardMobileView
        key={solution.key}
        name="chooseSolution"
        value={solution.solutionTypeId}
        title={solution.name}
        price={solution.priceWithTax}
        description={solution.didactic}
        onClick={onClick}
        icon={iconKey}
        hasDisplayFreeAmount={false}
        chooseItemCardType={CHOOSE_ITEM_CARD_TYPE.NORMAL_CARD}
        dataTestId={dataTestId}
      />
    );
  }

  return (
    <ChooseItemCard
      key={solution.key}
      name="chooseSolution"
      value={solution.solutionTypeId}
      title={solution.name}
      price={solution.priceWithTax}
      description={solution.didactic}
      onClick={onClick}
      icon={iconKey}
      hasDisplayFreeAmount={false}
      chooseItemCardType={CHOOSE_ITEM_CARD_TYPE.NORMAL_CARD}
      dataTestId={dataTestId}
    />
  );
};

SolutionItem.displayName = 'SolutionItem';
export default SolutionItem;
