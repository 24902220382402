import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';

import { getThemeFromProps } from '../../theme/helpers';

export const ButtonContainer = styled('div')`
  & > div:not(:first-child) {
    margin-top: 10px;
  }
  margin-bottom: 10px;
`;

export const TimeSlotContainer = styled('div')`
  display: flex;
  justify-content: flex-start;
  background-color: #ffffff;
  padding: 16px 24px;
  color: ${(props) => getThemeFromProps(props).colors.paragraphTextColor};
`;

export const FormattedTimeContainer = styled('div')`
  margin-left: 10px;
`;

export const OpeningHourRow = styled('div')`
  display: flex;
  white-space: pre-wrap;
`;

export const OpeningHourColumn = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const $How = styled.div`
  display: flex;
  flex-direction: row;
  @media ${media.maxWidth.sm} {
    flex-direction: column;
  }
`;

export const $HowTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: ${({ theme }) => theme.systemColors.defaultTextColor};
  margin-bottom: 1rem;
`;

export const $HowSubtitle = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  font-size: ${({ theme }) => theme.fonts.size.small};
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: ${({ theme }) => theme.systemColors.defaultTextColor};
  margin-bottom: 0.5rem;
`;

export const $HowStep = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 52px;
  flex: 1;
  @media ${media.maxWidth.sm} {
    margin-bottom: 2rem;
  }
`;

export const $HowStepIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  margin-bottom: 0.5rem;
  svg {
    width: 55px;
    height: 55px;
  }
`;

export const $HowStepText = styled.div<{ $number?: number }>`
  position: relative;
  &:before {
    content: '${({ $number }) => $number}';
    position: absolute;
    left: -1.5rem;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    font-weight: 300;
    font-size: 30px;
    line-height: 28px;
    text-align: center;
    color: ${({ theme }) => theme.systemColors.defaultTextColor};
  }
  @media ${media.maxWidth.sm} {
    min-width: 250px;
  }
`;

export const $HowStepTitle = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  margin-bottom: 2px;
  text-align: center;

  color: ${({ theme }) => theme.colors.primary};
`;

export const $HowStepSubtitle = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  font-size: ${({ theme }) => theme.fonts.size.ultraSmall};
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  color: ${({ theme }) => theme.systemColors.defaultTextColor};
`;

export const $Line = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 56px;
  min-width: 700px;
  &:first-child {
    margin-top: 0;
  }
  @media ${media.maxWidth.sm} {
    flex-direction: column;
    min-width: unset;
  }
`;

export const $Documents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  width: 100%;

  ${ButtonContainer} {
    width: 100%;
  }
`;

export const $InfoHandling = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin-left: 32px;

  &:first-child {
    margin-left: 0;
  }
  @media ${media.maxWidth.sm} {
    margin-top: 2rem;
    margin-left: unset;
  }
`;

export const $InfoHandlingCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 16px;
  width: 100%;

  background: #ffffff;
  border-radius: 8px;
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.systemColors.defaultTextColor};

  & svg {
    margin-bottom: 0.5rem;
  }

  & div:first-child {
    margin-bottom: 0.5rem;
  }

  *:last-child {
    color: #8a94a6;
    margin-top: 0.5rem;
  }
`;
