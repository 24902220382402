import { useGetSolutionTypeQuery } from 'myaccount/view/app/hooks/useGetSolutionTypeQuery';

export const useSolutionItem = ({
  solutionTypeId,
}: {
  solutionTypeId?: string;
}) => {
  const { solution } = useGetSolutionTypeQuery({ solutionTypeId });

  return {
    iconKey: solution?.iconKey,
  };
};
