import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants/src/shared';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared/supportedMethods';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.payment}invoices/${COMMON_QUERY_PARAMS.INVOICE_ID}/paymentIntent`;

interface CreatePaymentIntentCommandPayload {
  invoiceId: string;
}

async function createPaymentIntent({
  invoiceId,
}: CreatePaymentIntentCommandPayload): Promise<
  BackResponse<string> | BackResponseFailure
> {
  const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.INVOICE_ID, invoiceId);

  try {
    const response = await apiCall<BackResponse<string>>(url, {
      method: SUPPORTED_METHODS.POST,
      json: {},
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default createPaymentIntent;
