import get from 'lodash/get';

import { CLAIM_CANCELLED } from '../Claim/actionTypes';
import { LOGIN_SIGNEDOUT } from '../Login/loginConst';

import {
  MYORDERS_ASSIGNED,
  MYORDERS_ASSIGNING,
  MYORDERS_FOUND,
  MYORDERS_LOADED,
  MYORDERS_NOT_FOUND,
  MYORDERS_ON_ASSIGN_ERRORS,
  MYORDERS_ON_ERRORS,
  MYORDERS_ON_SEARCH_ERRORS,
  MYORDERS_RUNNING,
  MYORDERS_SEARCHING,
  OWNER_PRODUCT_INFO_LOADED,
} from './actionTypes';

const initialState = {
  items: [],
  isLoaded: false,
  isRunning: false,
  isErrored: false,
  search: {
    order: {},
    isFound: false,
    isFinished: false,
    isRunning: false,
    isErrored: false,
  },
  assign: {
    isFinished: false,
    isRunning: false,
    isErrored: false,
  },
  products: {},
};

function onErrors(state, { errors }) {
  return {
    ...state,
    isRunning: false,
    errors,
    isErrored: true,
    isLoaded: false,
  };
}

function onOrdersLoading(state) {
  return {
    ...state,
    isRunning: true,
  };
}

function onLoadOrdersSuccess(state) {
  return {
    ...state,
    isRunning: false,
  };
}

function onOrdersLoaded(state, payload) {
  return {
    ...state,
    items: payload.items,
    isRunning: false,
    isLoaded: true,
  };
}

function onSearchErrors(state, { errors }) {
  return {
    ...state,
    search: {
      ...state.search,
      isFinished: true,
      isFound: false,
      isRunning: false,
      errors,
      isErrored: true,
    },
  };
}

function onOrderSearching(state) {
  return {
    ...state,
    search: {
      ...initialState.search,
      isRunning: true,
    },
  };
}

function onOrderFound(state, payload) {
  const newState = {
    ...state,
    search: {
      ...state.search,
      order: payload,
      isFinished: true,
      isFound: true,
      isRunning: false,
    },
  };

  return newState;
}

function onOwnerProductInfoLoaded(state, payload) {
  const ownerProductId = get(payload, ['ownerProductId']);

  return {
    ...state,
    products: {
      ...state.products,
      [ownerProductId]: payload,
    },
  };
}

function onOrderNotFound(state) {
  const newState = {
    ...state,
    search: {
      ...state.search,
      isFinished: true,
      isFound: false,
      isRunning: false,
    },
  };

  return newState;
}

function onAssignErrors(state, { errors }) {
  return {
    ...state,
    assign: {
      ...state.assign,
      isFinished: true,
      isRunning: false,
      errors,
      isErrored: true,
    },
  };
}

function onOrderAssigning(state) {
  return {
    ...state,
    assign: {
      ...state.assign,
      isRunning: true,
    },
  };
}

function onOrderAssigned(state) {
  return {
    ...state,
    isLoaded: false,
    search: {
      ...initialState.search,
    },
    assign: {
      ...state.assign,
      isFinished: true,
      isRunning: false,
    },
  };
}

function getProductByID(id, orders) {
  let searchproduct;

  orders.items.find((order) => {
    searchproduct = order.products.find((c) => c.ownerProductId === id);

    return searchproduct !== undefined;
  });

  return searchproduct;
}

function onClaimCancelled(state, productID) {
  if (productID) {
    const order = getProductByID(productID, state);

    if (order) {order.claimContextId = null;}
  }

  return {
    ...state,
  };
}

function onSignOut() {
  return { ...initialState };
}

export default function ordersReducer(state = { ...initialState }, action) {
  switch (action.type) {
    case MYORDERS_RUNNING.BASE:
      return onOrdersLoading(state);
    case MYORDERS_RUNNING.SUCCEEDED:
      return onLoadOrdersSuccess(state);
    case MYORDERS_SEARCHING:
      return onOrderSearching(state);
    case MYORDERS_ASSIGNING:
      return onOrderAssigning(state);
    case MYORDERS_LOADED.BASE:
      return onOrdersLoaded(state, action.payload);
    case MYORDERS_FOUND:
      return onOrderFound(state, action.payload);
    case OWNER_PRODUCT_INFO_LOADED:
      return onOwnerProductInfoLoaded(state, action.payload);
    case MYORDERS_NOT_FOUND:
      return onOrderNotFound(state);
    case MYORDERS_ASSIGNED:
      return onOrderAssigned(state);
    case CLAIM_CANCELLED:
      return onClaimCancelled(state, action.payload);
    case LOGIN_SIGNEDOUT:
      return onSignOut(state, action.payload);
    case MYORDERS_ON_ERRORS:
      return onErrors(state, action.payload);
    case MYORDERS_ON_SEARCH_ERRORS:
      return onSearchErrors(state, action.payload);
    case MYORDERS_ON_ASSIGN_ERRORS:
      return onAssignErrors(state, action.payload);
    default:
      return state;
  }
}
