import { Col, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';

export const $Row = styled(Row)`
  margin-bottom: 1rem;
`;

export const $Col = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 2rem;
`;

export const $Footer = styled.footer`
  display: flex;
  gap: 10px;

  & > *:first-child {
    font-size: ${({ theme }) => theme.fonts.size.huge};
    margin-top: 5px;
  }

  @media ${media.maxWidth.sm} {
    align-self: start;
  }
`;

export const $Title = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  text-transform: uppercase;
`;

export const $CenteredTitle = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  text-transform: uppercase;
  text-align: center;
`;

export const $TakeHomePickupAppointmentHeader = styled.div`
  margin-bottom: 1rem;
`;
