import { ACTOR_TYPES, ActorTypes } from '@savgroup-front-common/constants';

import messages from './messages/actorTypesMessages';

export const getActorTypesMessage = (actorType: ACTOR_TYPES | ActorTypes) => {
  switch (actorType) {
    case ACTOR_TYPES.REPAIRER: {
      return messages.Repairer;
    }
    case ACTOR_TYPES.SELLER: {
      return messages.Seller;
    }
    case ACTOR_TYPES.OWNER: {
      return messages.Owner;
    }
    case ACTOR_TYPES.RISK_CARRIER: {
      return messages.RiskCarrier;
    }
    case ACTOR_TYPES.WAREHOUSE: {
      return messages.Warehouse;
    }
    case ACTOR_TYPES.TECHNICAL: {
      return messages.Technical;
    }
    case ACTOR_TYPES.ACCOUNTABLE: {
      return messages.Accountable;
    }
    case ACTOR_TYPES.DATA: {
      return messages.Data;
    }
    case ACTOR_TYPES.ORDER: {
      return messages.Order;
    }
    case ACTOR_TYPES.SUPPLIER: {
      return messages.Supplier;
    }
    case ACTOR_TYPES.STORE: {
      return messages.Store;
    }
    case ACTOR_TYPES.INSURER: {
      return messages.Insurer;
    }
    case ACTOR_TYPES.PURCHASE_STORE: {
      return messages.PurchaseStore;
    }
    case ACTOR_TYPES.DEPOSIT_STORE: {
      return messages.DepositStore;
    }
    case ACTOR_TYPES.IMPLICIT_SUPPLIER: {
      return messages.ImplicitSupplier;
    }
    case ACTOR_TYPES.SITE: {
      return messages.Site;
    }
    case ACTOR_TYPES.DELIVERY_STORE: {
      return messages.DeliveryStore;
    }

    default: {
      return undefined;
    }
  }

  return undefined;
};
