import { combineReducers } from '../../helpers';

import {
  appointments as appointmentsReducer,
  commercialSolution as commercialSolutionReducer,
  explodeFileState as explodeFileStateReducer,
  userActions as fileActionsReducer,
  handling as handlingReducer,
  invoicePaymentStatus as invoicePaymentStatusReducer,
  loadOwnerProductFiles as loadOwnerProductFilesReducer,
  splitFileState as splitFileStateReducer,
} from './reducers';

const reducer = combineReducers({
  explodeFileState: explodeFileStateReducer,
  splitFileState: splitFileStateReducer,
  appointments: appointmentsReducer,
  commercialSolution: commercialSolutionReducer,
  userActions: fileActionsReducer,
  invoicePaymentStatus: invoicePaymentStatusReducer,
  handling: handlingReducer,
  loadOwnerProductFiles: loadOwnerProductFilesReducer,
});

export default reducer;
