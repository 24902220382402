import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  AddressInfoDto,
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

interface SetClaimAdditionalInformation {
  id: string;
  state: string;
  buyDate: Date;
  receptionDate: Date;
  issueOccurenceDate: Date;
  issueId: string;
  issueName: string;
  modelId: string;
  modelName: string;
  symptomId: string;
  reasonId: string;
  reasonName: string;
  soldWarrantyId: string;
  soldWarrantyName: string;
  warrantyTypeId: string;
  soldWarrantyPurchaseDate: Date;
  solutionTypeId: string;
  solutionTypeName: string;
  solutionPrice: number;
  solutionPriceCurrencyCode: string;
  warrantyTypeName: string;
  brandId: string;
  brandName: string;
  modelTypeId: string;
  modelTypeName: string;
  productState: string;
  depositAddress: AddressInfoDto;
  ownerAddress: AddressInfoDto;
  deliveryAddress: AddressInfoDto;
  onSiteInterventionAddress: AddressInfoDto;
  sellerName: string;
  sellerId: string;
  fileId: string;
  solutionGroupType: string;
  useSameAddress: boolean;
  includedDocuments: string[];
  allIncludedDocuments: {
    type: string;
    name: string;
    extension: string;
    id: string;
    uploaderId: string;
    uploaderType: string;
    uploadDateUtc: Date;
    customerVisibility: boolean;
  }[];
  additionalElements: string[];
  solutionGroupId: string;
  carrierDepositName: string;
  carrierDeliveryName: string;
  reasonComment: string;
}

interface SetClaimAdditionalInformationArgs {
  claimId: string;
  additionalClaimInformationValues: { id: string; value: string }[];
}

const ENDPOINT = `${APIConfiguration.claim}claims/${COMMON_QUERY_PARAMS.CLAIM_ID}/additionalInformation`;

const setClaimAdditionalInformation = async ({
  claimId,
  additionalClaimInformationValues,
}: SetClaimAdditionalInformationArgs): Promise<
  BackResponse<SetClaimAdditionalInformation> | BackResponseFailure
> => {
  if (!claimId) {
    throw new Error(
      'Could not call setClaimAdditionalInformation without a claimId.',
    );
  }
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.CLAIM_ID, claimId);

    const response = await apiCall<BackResponse<SetClaimAdditionalInformation>>(
      url,
      {
        method: SUPPORTED_METHODS.PUT,
        json: {
          additionalClaimInformation: additionalClaimInformationValues,
        },
      },
    );

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default setClaimAdditionalInformation;
