import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

 
const ENDPOINT = `${APIConfiguration.claim}additionalInformation/entities/${COMMON_QUERY_PARAMS.ID}`;

export interface AdditionalInformation {
  additionalInformationEntity: {
    id: string;
    uniqueName?: string;
    informationType: string;
    uniquenessByProduct: boolean;
    referenceType?: string;
    targetType: string;
    sellerId?: string;
  };
  label?: string;
  id?: string;
}

interface GetAdditionalInformationEntitySummaryQueryArgs {
  additionalInformationId: string;
  countryCode?: string;
}

const getAdditionalInformationEntitySummaryQuery = async ({
  additionalInformationId,
}: GetAdditionalInformationEntitySummaryQueryArgs): Promise<
  BackResponse<AdditionalInformation> | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(
      COMMON_QUERY_PARAMS.ID,
      additionalInformationId,
    );

    const response = await apiCall<BackResponse<AdditionalInformation>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default getAdditionalInformationEntitySummaryQuery;
