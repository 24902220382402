import React, { FC, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';

import messages from './messages';
import { useResumeCard } from './ResumeCard.hooks';
import {
  $ResumeCardContainer,
  $ResumeProductTitle,
  $ResumeProductTitleWrapper,
  $ResumeSeparator,
  $ResumeStateProduct,
  $ResumeStateProductReason,
} from './ResumeCard.styles';
import ResumeCardHeader from './ResumeCardHeader/ResumeCardHeader';
import { useGetClaimGroupConfirmation } from '../../../../app/hooks/useGetClaimGroupConfirmation';

const ResumeCard: FC = () => {
  const { claimGroupId } = useParams<{ claimGroupId: string }>();

  const { claimGroupConfirmation } = useGetClaimGroupConfirmation({
    claimGroupId,
  });

  const { products } = useResumeCard({
    claimGroupConfirmation,
  });

  return (
    <$ResumeCardContainer>
      <$ResumeStateProduct>
        <$ResumeProductTitleWrapper>
          <$ResumeProductTitle>
            <SafeFormattedMessageWithoutSpread
              message={messages.requestTitleResume}
            />
          </$ResumeProductTitle>
        </$ResumeProductTitleWrapper>

        <$ResumeStateProductReason>
          <SafeFormattedMessageWithoutSpread
            message={messages.issueTitle}
            values={{
              issueName: claimGroupConfirmation?.claims[0].issueName,
            }}
          />
        </$ResumeStateProductReason>
        <$ResumeStateProductReason>
          <SafeFormattedMessageWithoutSpread
            message={messages.reasonTitle}
            values={{
              reasonName: claimGroupConfirmation?.claims[0].reasonName,
            }}
          />
        </$ResumeStateProductReason>

        <$ResumeStateProductReason>
          <SafeFormattedMessageWithoutSpread
            message={messages.solutionTitle}
            values={{
              solutionName: claimGroupConfirmation?.solutionTypeName,
            }}
          />
        </$ResumeStateProductReason>
      </$ResumeStateProduct>

      {products &&
        products?.map((product, index) => {
          return (
            <Fragment key={product.ownerProductId}>
              <ResumeCardHeader product={product} />

              {index + 1 < products.length && <$ResumeSeparator />}
            </Fragment>
          );
        })}
    </$ResumeCardContainer>
  );
};

ResumeCard.displayName = 'ResumeCard';

export default ResumeCard;
