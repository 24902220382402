import { Action } from 'redux-actions';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { buildUrl } from '../../helpers';
import { callAndGetResponse } from '../../services';

import * as actionTypes from './actionTypes';
import { selectGetActorSummaryByIdWasLoaded } from './selectors';

interface GetActorSummaryWorkerPayload {
  actorId?: string;
  actorType?: string;
  sellerId?: string;
  ownerId?: string;
}
function* getActorSummaryWorker({
  payload = {},
}: {
  payload?: GetActorSummaryWorkerPayload;
}): any {
  const { actorId, actorType, sellerId, ownerId } = payload;
  const meta = {
    indexer: actorId,
    actorId,
    actorType,
  };

  if (!actorType) {
    return null;
  }

  const wasLoaded = yield select((state) =>
    selectGetActorSummaryByIdWasLoaded(state, { actorId }),
  );

  if (wasLoaded) {
    return null;
  }

  const url = buildUrl(`${APIConfiguration.actor}actors/summary`, {
    actorId,
    actorType,
    sellerId,
    ownerId,
  });

  yield call(
    callAndGetResponse,
    actionTypes.GET_ACTOR_SUMMARY_BY_ID,
    url,
    { method: SUPPORTED_METHODS.GET },
    meta,
  );

  yield put(actionTypes.GET_ACTOR_SUMMARY_BY_ID.end(meta));

  return true;
}
function* getActorSummaryWatcher() {
  yield takeEvery<Action<GetActorSummaryWorkerPayload>>(
    actionTypes.GET_ACTOR_SUMMARY_BY_ID.BASE,
    getActorSummaryWorker,
  );
}

export default function* mainSaga() {
  try {
    yield all([getActorSummaryWatcher()]);
  } catch (error) {
    logCritical(error);
  }
}
