import { defineMessages } from 'react-intl';

export default defineMessages({
  solutionTitle: {
    id: 'components.myaccount.claims.handling.cards.solutionTitle',
    defaultMessage: 'Solution : ',
  },
  repairProduct: {
    id: 'components.myaccount.claims.handling.cards.repairProduct',
    defaultMessage: 'Repair my product in the workshop',
  },
  estimatedTime: {
    id: 'components.myaccount.claims.handling.cards.estimatedTime',
    defaultMessage: 'estimated average time {estimatedTime}',
  },
  modifyButton: {
    id: 'components.myaccount.claims.handling.cards.modifyButton',
    defaultMessage: 'Modify',
  },
  handlingTitle: {
    id: 'components.myaccount.claims.handling.cards.handlingTitle',
    defaultMessage: 'Send my product',
  },
  handlingTitleOnlyHome: {
    id: 'components.myaccount.claims.handling.cards.handlingTitleOnlyHome',
    defaultMessage: 'Home intervention',
  },
  free: {
    id: 'components.myaccount.claims.handling.cards.free',
    defaultMessage: 'Free',
  },
  depositPickupPoint: {
    id: 'components.myaccount.claims.handling.cards.depositPickupPoint',
    defaultMessage: 'Drop off point',
  },
  depositPickupStore: {
    id: 'components.myaccount.claims.handling.cards.depositPickupStore',
    defaultMessage: 'Drop off at store',
  },
  depositHome: {
    id: 'components.myaccount.claims.handling.cards.depositHome',
    defaultMessage: 'Home pickup',
  },
  depositExternal: {
    id: 'components.myaccount.claims.handling.cards.depositExternal',
    defaultMessage: 'External transport',
  },
  depositHomeIntervention: {
    id: 'components.myaccount.claims.handling.cards.depositHomeIntervention',
    defaultMessage: 'Home intervention',
  },
  depositImmediateDropAtStore: {
    id: 'components.myaccount.claims.handling.cards.depositImmediateDropAtStore',
    defaultMessage: 'Immediate drop at store',
  },
  depositPickupPointDescription: {
    id: 'components.myaccount.claims.handling.cards.depositPickupPointDescription',
    defaultMessage:
      'Délai de prise en charge entre 2 et 5 jours. Déposez votre produit emballé dans le point relais proche de chez vous.',
  },
  depositPickupStoreDescription: {
    id: 'components.myaccount.claims.handling.cards.depositPickupStoreDescription',
    defaultMessage:
      'Délai de prise en charge moyen: 1 jours. Déposez votre produit dans le magasin proche de chez vous.',
  },
  depositHomeDescription: {
    id: 'view.card.handling.depositHomeDescription',
    defaultMessage:
      'Enlèvement prévu dans les 24h à 48h. Un transporteur se déplace chez vous.',
  },
  depositExternalDescription: {
    id: 'components.myaccount.claims.handling.cards.depositExternalDescription',
    defaultMessage: '???',
  },
  depositHomeInterventionDescription: {
    id: 'components.myaccount.claims.handling.cards.depositHomeInterventionDescription',
    defaultMessage:
      'Intervention à domicile, un technicien se déplace chez vous pour réparation sur place',
  },
  depositImmediateDropAtStoreDescription: {
    id: 'components.myaccount.claims.handling.cards.depositImmediateDropAtStoreDescription',
    defaultMessage:
      'Délai de prise en charge moyen: 1 jours. Déposez votre produit dans le magasin proche de chez vous.',
  },
  depositPickupPointHighlighting: {
    id: 'components.myaccount.claims.handling.cards.depositPickupPointHighlighting',
    defaultMessage: '',
  },
  depositPickupStoreHighlighting: {
    id: 'components.myaccount.claims.handling.cards.depositPickupStoreHighlighting',
    defaultMessage: 'Solution plus écologique',
  },
  depositHomeHighlighting: {
    id: 'view.card.handling.depositHomeHighlighting',
    defaultMessage: '',
  },
  depositExternalHighlighting: {
    id: 'components.myaccount.claims.handling.cards.depositExternalHighlighting',
    defaultMessage: '',
  },
  depositHomeInterventionHighlighting: {
    id: 'components.myaccount.claims.handling.cards.depositHomeInterventionHighlighting',
    defaultMessage: '',
  },
  depositImmediateDropAtStoreHighlighting: {
    id: 'components.myaccount.claims.handling.cards.depositImmediateDropAtStoreHighlighting',
    defaultMessage: 'Solution plus écologique',
  },
  deliveryPickupStoreDeliveryHighlighting: {
    id: 'components.myaccount.claims.handling.cards.deliveryPickupStoreDeliveryHighlighting',
    defaultMessage: 'Solution plus écologique',
  },
  deleteAddress: {
    id: 'components.common.addressManagement.deleteAddress',
    defaultMessage: 'Supprimer cette addresse',
  },
  editAddress: {
    id: 'components.common.addressManagement.editAddress',
    defaultMessage: 'Modifier cette addresse',
  },
  addAddress: {
    id: 'components.common.addressManagement.addAddress',
    defaultMessage: 'Ajouter une addresse',
  },
  areYouSure: {
    id: 'components.common.addressManagement.areYouSure',
    defaultMessage: 'Êtes vous certains ?',
  },
  youWillErase: {
    id: 'components.common.addressManagement.youWillErase',
    defaultMessage: 'Confirmer la suppression de cette addresse',
  },
  cancel: {
    id: 'components.common.addressManagement.cancel',
    defaultMessage: 'Annuler',
  },
  confirm: {
    id: 'components.common.addressManagement.confirm',
    defaultMessage: 'Confirmer',
  },
  back: {
    id: 'components.common.addressManagement.back',
    defaultMessage: 'Back',
  },
  requiredField: {
    id: 'components.common.breadCrumb.commentRequired',
    defaultMessage: 'This field is mandatory',
  },
  badFormatError: {
    id: 'components.common.addressManagement.addressForm.badFormatError',
    defaultMessage:
      'Ce champs ne peut pas contenir de caractères spéciaux (@,€,#,...)',
  },
  phoneFormatError: {
    id: 'components.common.addressManagement.addressForm.phoneFormatError',
    defaultMessage: 'Phone number formatted incorrectly',
  },
  maxLengthError: {
    id: 'components.common.addressManagement.addressForm.maxLengthError',
    defaultMessage: 'Ce champs est trop long :',
  },
});
