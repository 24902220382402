import { Dispatch } from 'react';

import { MenuAction } from './Menu.reducers';
import {
  MENU_ACTION_TYPES,
  MENU_ITEM_TYPES,
  MenuItem,
  MenuItemEnhanced,
} from './Menu.types';

export const VERTICAL_MENU_ANIMATION_DURATION = 300;

export const calculateEasing = (index: number, total: number): number => {
  const current = index / (total - 1);
  const ease = -((current - 1) * (current - 1) - 1);
  const totalDuration = total * 10;

  return ease * totalDuration;
};

export const transformMenu = (
  dispatch: Dispatch<MenuAction>,
  menus: MenuItem[],
  isDepth = false,
): MenuItemEnhanced[] =>
  menus.reduce((acc, menuItem, index) => {
    let returnValue = acc;

    if (isDepth && index === 0) {
      returnValue = [
        {
          type: MENU_ITEM_TYPES.BACK,
          key: MENU_ITEM_TYPES.BACK,
          onClick: () => dispatch({ type: MENU_ACTION_TYPES.REMOVE_PATH }),
        },
      ];
    }

    if (menuItem.type === MENU_ITEM_TYPES.MENU) {
      return [
        ...returnValue,
        {
          ...menuItem,
          items: transformMenu(dispatch, menuItem.items, true),
          onClick: () =>
            dispatch({
              type: MENU_ACTION_TYPES.ADD_PATH,
              payload: { index: index + Number(isDepth) },
            }),
        },
      ];
    }

    return [...returnValue, menuItem];
  }, [] as MenuItemEnhanced[]);
