import { AddressInfo, AddressInfoDto } from '@savgroup-front-common/types';

interface FormatHandlingAddressArgs {
  address?: AddressInfoDto;
}

const adaptAddressInfo = ({
  address,
}: FormatHandlingAddressArgs):
  | (AddressInfoDto & {
      firstName?: AddressInfo['firstName'];
      lastName?: AddressInfo['lastName'];
    })
  | undefined => {
  if (!address) {
    return undefined;
  }

  return {
    ...address,
    firstName: address?.firstname,
    lastName: address?.lastname,
  };
};

export default adaptAddressInfo;
