import { FileStateHistorySummary } from 'myaccount/api/Workflow/getFileStatesQuery';

export const useProductInfoHistory = ({
  histories,
}: {
  histories: FileStateHistorySummary[];
}) => {
  return {
    isFirst: histories[1]
      ? histories[1].module.definitionId === 'FileCreated'
      : false,
    isLast: histories[0]
      ? histories[0].module.definitionId === 'CLOTURE'
      : false,
    productsHistory:
      histories.length > 0
        ? [
            {
              label: histories[1]
                ? histories[1].state.label
                : histories[0].state.label,
              active: !histories[1],
              disabled: false,
            },
            {
              label:
                histories[1] && histories[0] ? histories[0].state.label : '',
              active: !!histories[1],
              disabled: !histories[1],
            },
          ]
        : [],
  };
};
