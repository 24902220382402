import { ActionTypeIsNotSupportedError } from '@savgroup-front-common/exceptions';

export interface ProfessionalPagesContextState {
  skippedSteps: Record<string, boolean>;
}

export enum ACTION_TYPES {
  ON_SKIP_STEP = 'ON_SKIP_STEP',
  ON_RESET = 'ON_RESET',
  ON_SKIP_STEPS = 'ON_SKIP_STEPS',
}

export type Action =
  | { type: ACTION_TYPES.ON_SKIP_STEP; payload: { step: string } }
  | { type: ACTION_TYPES.ON_SKIP_STEPS; payload: { steps: string[] } }
  | { type: ACTION_TYPES.ON_RESET };

export const initProfessionalPagesContextReducer =
  (): ProfessionalPagesContextState => {
    return {
      skippedSteps: {},
    };
  };

export const professionalPagesContextReducer = (
  state: ProfessionalPagesContextState,
  action: Action,
): ProfessionalPagesContextState => {
  switch (action.type) {
    case ACTION_TYPES.ON_SKIP_STEPS: {
      return {
        ...state,
        skippedSteps: action.payload.steps.reduce((acc, step) => {
          return {
            ...acc,
            [step]: true,
          };
        }, state.skippedSteps),
      };
    }
    case ACTION_TYPES.ON_SKIP_STEP: {
      return {
        ...state,
        skippedSteps: {
          ...state.skippedSteps,
          [action.payload.step]: true,
        },
      };
    }
    case ACTION_TYPES.ON_RESET: {
      return {
        ...state,
        skippedSteps: {},
      };
    }
    default: {
      throw new ActionTypeIsNotSupportedError();
    }
  }
};
