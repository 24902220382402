import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'view.quotation.refuseQuotation.title',
    defaultMessage: 'Refuse quotation ?',
  },
  rejectOptionTitle: {
    id: 'view.quotation.refuseQuotation.rejectOptionTitle',
    defaultMessage: 'Reject Option title',
  },
  rejectOptionDescription: {
    id: 'view.quotation.refuseQuotation.rejectOptionDescription',
    defaultMessage: 'Reject Option description',
  },
  rejectOptionLabel: {
    id: 'view.quotation.refuseQuotation.rejectOptionLabel',
    defaultMessage: 'Reject Option label',
  },
  rejectCommentTitle: {
    id: 'view.quotation.refuseQuotation.rejectCommentTitle',
    defaultMessage: 'Reject Comment title',
  },
  rejectCommentDescription: {
    id: 'view.quotation.refuseQuotation.rejectCommentDescription',
    defaultMessage: 'Reject Comment description',
  },
  rejectCommentLabel: {
    id: 'view.quotation.refuseQuotation.rejectCommentLabel',
    defaultMessage: 'Reject Comment label',
  },
  destructionOption: {
    id: 'view.quotation.destructionOption',
    defaultMessage: 'Destruct product',
  },
  returnOption: {
    id: 'view.quotation.returnOption',
    defaultMessage: 'Return product',
  },

  buttonCancel: {
    id: 'view.component.confirmModal.cancelButton',
    defaultMessage: 'Cancel',
  },
  buttonReject: {
    id: 'view.quotation.buttonReject',
    defaultMessage: 'Reject',
  },

  rejectQuotationDidactic: {
    id: 'view.quotation.rejectQuotationDidactic',
    defaultMessage: 'Refusing the quotation may incur additional fees',
  },
});
