import useGetClientInformation from '../../../../../app/hooks/useGetClientInformation';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { useSelector } from 'react-redux';
import { ownerIdSelector } from '../../../../../../domains/Customer/selectors';
import { useQuery } from 'react-query';
import { OwnerService } from '../../../../../../api';
import { useParams } from 'react-router-dom';
import { adaptSearchOrderDetails } from '../adapters/adaptSearchOrderDetails';

export const GET_BUSINESS_RETURN = 'getBusinessReturn';

export const useGetBusinessReturn = () => {
  const { orderReference } = useParams<{ orderReference: string }>();
  const { clientInformation } = useGetClientInformation();

  const { removeAllNotifications, pushErrors } = useToasts();
  const ownerId = useSelector(ownerIdSelector);

  const { data, isLoading } = useQuery(
    [
      GET_BUSINESS_RETURN,
      { sellerId: clientInformation?.sellerId, orderReference, ownerId },
    ],
    async () => {
      removeAllNotifications();

      if (!clientInformation?.sellerId) {
        return undefined;
      }

      const response = await OwnerService.startOrContinueBusinessReturnCommand({
        ownerId,
        sellerId: clientInformation?.sellerId,
        orderReference,
      });

      if (response.failure) {
        pushErrors(response.errors);

        return undefined;
      }

      return response.value;
    },
    { enabled: !!orderReference, staleTime: Infinity },
  );

  return {
    order: adaptSearchOrderDetails(data?.order),
    eligibility: data?.eligibility,
    isLoading,
  };
};
