import { ChooseProductPage } from 'myaccount/view/newDesignPages/chooseProductPage';
import { ClaimGroupConfirmationPage } from 'myaccount/view/newDesignPages/claimGroupConfirmationPage';
import { ClaimGroupDeliveryPage } from 'myaccount/view/newDesignPages/claimGroupDeliveryPage';
import { ClaimGroupDepositPage } from 'myaccount/view/newDesignPages/claimGroupDepositPage';
import { ClaimGroupDocumentPageRelatedToClaim } from 'myaccount/view/newDesignPages/claimGroupDocumentPageRelatedToClaim';
import { ClaimGroupDocumentPageRelatedToProduct } from 'myaccount/view/newDesignPages/claimGroupDocumentPageRelatedToProduct';
import { ClaimGroupProductInfoPage } from 'myaccount/view/newDesignPages/claimGroupProductInfoPage';
import { ClaimGroupReasonPage } from 'myaccount/view/newDesignPages/claimGroupReasonPage';
import { ClaimGroupDiagnosticTreePage } from 'myaccount/view/newDesignPages/claimGroupDiagnosticTreePage';
import { ClaimGroupSolutionPage } from 'myaccount/view/newDesignPages/claimGroupSolutionPage';
import FileDetailPage from 'myaccount/view/newDesignPages/FileFollowupsPage';
import { IssuesPage } from 'myaccount/view/newDesignPages/issuesPage';
import { OrdersPage } from 'myaccount/view/newDesignPages/OrdersPage';
import PageNotFound from 'myaccount/view/pages/PageNotFound';

import { BASE_ROUTES, ROUTES_NEW_DESIGN } from '../routes/Routes';
import HomePage from 'myaccount/view/newDesignPages/HomePage/HomePage';

export interface RoutesConfig {
  name: STEP_CONFIG | string;
  path: string;
  component: React.FC;
}

export enum STEP_CONFIG {
  ISSUES = 'Issues',
  CHOOSE_PRODUCTS = 'ChooseProduct',
  CLAIM_GROUP_REASON = 'ClaimGroupReason',
  CLAIM_GROUP_DIAGNOSTIC_TREE = 'ClaimGroupDiagnosticTree',
  CLAIM_GROUP_PRODUCT_INFO = 'ClaimGroupProductInfo',
  CLAIM_GROUP_DOCUMENT_RELATED_TO_CLAIM = 'ClaimGroupDocumentRelatedToClaim',
  CLAIM_GROUP_DOCUMENT_RELATED_TO_PRODUCT = 'ClaimGroupDocumentRelatedToProduct',
  CLAIM_GROUP_SOLUTION = 'ClaimGroupSolution',
  CLAIM_GROUP_DEPOSIT = 'ClaimGroupDeposit',
  CLAIM_GROUP_DELIVERY = 'ClaimGroupDelivery',
  CLAIM_GROUP_CONFIRMATION = 'ClaimGroupConfirmation',
}

export enum HOME_CONFIG {
  HOME_PAGE = 'HomePage',
  ORDER_PAGE = 'OrdersPage',
  PRODUCTS_PAGE = 'ProductPage',
  FILE_DETAIL_PAGE = 'FileDetailPage',
  PAGE_NOT_FOUND = 'PageNotFound',
}

export const stepsConfig: RoutesConfig[] = [
  {
    name: STEP_CONFIG.ISSUES,
    path: `${BASE_ROUTES.NEW_DESIGN}${ROUTES_NEW_DESIGN.ISSUES}`,
    component: IssuesPage,
  },
  {
    name: STEP_CONFIG.CHOOSE_PRODUCTS,
    path: `${BASE_ROUTES.NEW_DESIGN}${ROUTES_NEW_DESIGN.CHOOSE_PRODUCTS}`,
    component: ChooseProductPage,
  },
  {
    name: STEP_CONFIG.CLAIM_GROUP_REASON,
    path: `${BASE_ROUTES.NEW_DESIGN}${ROUTES_NEW_DESIGN.CLAIM_GROUP_REASON}`,
    component: ClaimGroupReasonPage,
  },
  {
    name: STEP_CONFIG.CLAIM_GROUP_DIAGNOSTIC_TREE,
    path: `${BASE_ROUTES.NEW_DESIGN}${ROUTES_NEW_DESIGN.CLAIM_GROUP_DIAGNOSTIC_TREE}`,
    component: ClaimGroupDiagnosticTreePage,
  },
  {
    name: STEP_CONFIG.CLAIM_GROUP_PRODUCT_INFO,
    path: `${BASE_ROUTES.NEW_DESIGN}${ROUTES_NEW_DESIGN.CLAIM_GROUP_PRODUCT_INFO}`,
    component: ClaimGroupProductInfoPage,
  },
  {
    name: STEP_CONFIG.CLAIM_GROUP_DOCUMENT_RELATED_TO_PRODUCT,
    path: `${BASE_ROUTES.NEW_DESIGN}${ROUTES_NEW_DESIGN.CLAIM_GROUP_DOCUMENT_RELATED_TO_PRODUCT}`,
    component: ClaimGroupDocumentPageRelatedToProduct,
  },
  {
    name: STEP_CONFIG.CLAIM_GROUP_DOCUMENT_RELATED_TO_CLAIM,
    path: `${BASE_ROUTES.NEW_DESIGN}${ROUTES_NEW_DESIGN.CLAIM_GROUP_DOCUMENT_RELATED_TO_CLAIM}`,
    component: ClaimGroupDocumentPageRelatedToClaim,
  },
  {
    name: STEP_CONFIG.CLAIM_GROUP_SOLUTION,
    path: `${BASE_ROUTES.NEW_DESIGN}${ROUTES_NEW_DESIGN.CLAIM_GROUP_SOLUTION}`,
    component: ClaimGroupSolutionPage,
  },
  {
    name: STEP_CONFIG.CLAIM_GROUP_DEPOSIT,
    path: `${BASE_ROUTES.NEW_DESIGN}${ROUTES_NEW_DESIGN.CLAIM_GROUP_HANDLING_DEPOSIT}`,
    component: ClaimGroupDepositPage,
  },
  {
    name: STEP_CONFIG.CLAIM_GROUP_DELIVERY,
    path: `${BASE_ROUTES.NEW_DESIGN}${ROUTES_NEW_DESIGN.CLAIM_GROUP_HANDLING_DELIVERY}`,
    component: ClaimGroupDeliveryPage,
  },
  {
    name: STEP_CONFIG.CLAIM_GROUP_CONFIRMATION,
    path: `${BASE_ROUTES.NEW_DESIGN}${ROUTES_NEW_DESIGN.CLAIM_GROUP_CONFIRMATION}`,
    component: ClaimGroupConfirmationPage,
  },
];

export const otherPageConfig: RoutesConfig[] = [
  {
    name: HOME_CONFIG.HOME_PAGE,
    path: `${BASE_ROUTES.NEW_DESIGN}${BASE_ROUTES.HOME}/:homeTab`,
    component: HomePage,
  },
  {
    name: HOME_CONFIG.ORDER_PAGE,
    path: `${BASE_ROUTES.NEW_DESIGN}${ROUTES_NEW_DESIGN.ORDER_CREATE_CLAIM}`,
    component: OrdersPage,
  },
  {
    name: HOME_CONFIG.PRODUCTS_PAGE,
    path: `${BASE_ROUTES.NEW_DESIGN}${ROUTES_NEW_DESIGN.PRODUCTS}`,
    component: OrdersPage,
  },
  {
    name: HOME_CONFIG.FILE_DETAIL_PAGE,
    path: `${BASE_ROUTES.NEW_DESIGN}${ROUTES_NEW_DESIGN.FILE_FOLLOWUPS}`,
    component: FileDetailPage,
  },
  {
    name: HOME_CONFIG.PAGE_NOT_FOUND,
    path: `${ROUTES_NEW_DESIGN.PAGE_NOT_FOUND}`,
    component: PageNotFound,
  },
];
