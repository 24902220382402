const MY_ACCOUNT = 'MyAccount';

const useGetOrigin = () => {
  const origin =
    (window.localStorage.getItem('LoggedInFrom') || '').replaceAll('"', '') ||
    MY_ACCOUNT;

  return {
    origin,
  };
};

export default useGetOrigin;
