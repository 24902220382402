import { APIConfiguration } from '@savgroup-front-common/configuration';

interface BuildFileUploadEndpointsProps {
  fileId?: string;
  claimIds?: string[];
  id: string;
}

export const buildFileUploadEndpoints = ({
  fileId,
  claimIds = [],
  id,
}: BuildFileUploadEndpointsProps) => {
  return claimIds.reduce((acc, claimId) => {
    return {
      ...acc,
      [claimId]: {
        uploadEndpoint: `${APIConfiguration.workflow}files/${fileId}/additionalInformation/${claimId}/${id}/file`,
        deleteEndpoint: `${APIConfiguration.workflow}files/${fileId}/additionalInformation/${claimId}/${id}`,
      },
    };
  }, {});
};
