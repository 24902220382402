import { Reducer } from 'react';

import {
  Claim,
  FileShortSummary,
  OrderSummary,
} from '@savgroup-front-common/types';

import { VALUE_ACTION_TYPES, ValuesState } from './StepsProvider.types';
import { LoginState } from '@savgroup-front-common/core/src/domains/login';

export type ValueAction = {
  type: VALUE_ACTION_TYPES.UPDATE_VALUE;
  payload: Partial<ValuesState>;
};

export const initValueState = ({
  ownerId,
  login,
  orders,
  openedFiles,
  closedFiles,
}: {
  ownerId?: string;
  login?: LoginState;
  orders?: OrderSummary[];
  openedFiles?: FileShortSummary[];
  closedFiles?: FileShortSummary[];
  claimGroup?: {
    claimGroupId?: string;
    claims?: Claim[];
  };
}): ValuesState => {
  return {
    ownerId,
    login,
    orders,
    openedFiles,
    closedFiles,
    claimGroup: {
      claimGroupId: undefined,
      claims: [],
    },
    diagnosticIsAlreadyMade: false,
  };
};

const valueReducer: Reducer<ValuesState, ValueAction> = (state, action) => {
  switch (action.type) {
    case VALUE_ACTION_TYPES.UPDATE_VALUE: {
      return { ...state, ...action.payload };
    }
    default: {
      return state;
    }
  }
};

export default valueReducer;
