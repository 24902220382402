import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  Model,
} from '@savgroup-front-common/types';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';

const ENDPOINT = `${APIConfiguration.catalog}models/byEan/${COMMON_QUERY_PARAMS.EAN}`;

interface GetModelBySkuQueryPayload {
  ean: string;
  sellerId: string;
}

export async function getModelByEanQuery({
  ean,
  sellerId,
}: GetModelBySkuQueryPayload): Promise<
  BackResponse<Model[]> | BackResponseFailure
> {
  try {
    return await apiCall<BackResponse<Model[]>>(
      buildUrl(ENDPOINT.replace(COMMON_QUERY_PARAMS.EAN, ean), { sellerId }),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
