import React, { FunctionComponent } from 'react';

import { RescheduleTechnicalInterventionModalContent } from './RescheduleTechnicalInterventionModalContent';
import { Modal } from '../../../atoms/modal';

type RescheduleTechnicalInterventionModalProps = React.PropsWithChildren<{
  fileId: string;
  sellerId: string;
  isOpen: boolean;
  onRescheduleSuccess: () => void;
  onCancel: () => void;
}>;

const RescheduleTechnicalInterventionModal: FunctionComponent<
  RescheduleTechnicalInterventionModalProps
> = ({ fileId, sellerId, isOpen, onRescheduleSuccess, onCancel }) => {
  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <RescheduleTechnicalInterventionModalContent
        fileId={fileId}
        sellerId={sellerId}
        onCancel={onCancel}
        onRescheduleSuccess={onRescheduleSuccess}
      />
    </Modal>
  );
};

RescheduleTechnicalInterventionModal.displayName =
  'RescheduleTechnicalIntervention';

export default RescheduleTechnicalInterventionModal;
