import { useQuery } from 'react-query';
import { CatalogService } from '../../../api';

export const useGetModelsBySearchModelQuery = ({
  maxResult,
  searchTerms,
  sellerId,
}: {
  maxResult: number;
  searchTerms: string;
  sellerId?: string;
}) => {
  const { data: models, isLoading } = useQuery(
    ['useGetModelsBySearchModelQuery', { maxResult, searchTerms, sellerId }],
    async () => {
      if (!maxResult || !sellerId) {
        return undefined;
      }

      const response = await CatalogService.getModelsBySearchModelQuery({
        maxResult,
        searchTerms,
        sellerId,
      });

      if (response.failure) {
        return undefined;
      }

      return response.value;
    },
    { staleTime: Infinity },
  );

  return {
    models,
    isLoading,
  };
};
