import React, { FC } from 'react';

import { SparePartSearchSummary } from '@savgroup-front-common/types';

import { createGenericContext } from '../../../helpers';

import { adaptAlternativeSpareParts } from './QuotationPendingCustomerValidation.adapters';
import { QuotationPendingCustomerValidationNewModel } from './QuotationPendingCustomerValidation.types';

export interface IssueAQuotationPageContextArgs {
  alternativeSpareParts: Record<string, Record<string, SparePartSearchSummary>>;
}

const [
  useQuotationPendingCustomerValidationContext,
  IssueAQuotationPageContextProvider,
] = createGenericContext<IssueAQuotationPageContextArgs>();

interface IssueAQuotationPageContextProviderProps {
  quotation: QuotationPendingCustomerValidationNewModel;
}

const QuotationPendingCustomerValidationContextProvider: FC<
  React.PropsWithChildren<IssueAQuotationPageContextProviderProps>
> = ({ children, quotation }) => {
  return (
    <IssueAQuotationPageContextProvider
      value={{
        alternativeSpareParts: adaptAlternativeSpareParts({ quotation }),
      }}
    >
      {children}
    </IssueAQuotationPageContextProvider>
  );
};

QuotationPendingCustomerValidationContextProvider.displayName =
  'QuotationPendingCustomerValidationContextProvider';

export {
  QuotationPendingCustomerValidationContextProvider,
  useQuotationPendingCustomerValidationContext,
};
