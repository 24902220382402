import React, { FC } from 'react';
import { useMedia } from 'react-use';

import { media } from '@savgroup-front-common/constants';

import { Skeleton } from '../../../../atoms/Skeleton';
import { rem } from '../../../../helpers';

const DEFAULT_HEIGHT = 47.98;

interface SparePartQuotationPendingCustomerValidationLineSkeletonProps {
  shouldShowRecipientColumn?: boolean;
}

const SparePartQuotationPendingCustomerValidationLineSkeleton: FC<
  SparePartQuotationPendingCustomerValidationLineSkeletonProps
> = ({ shouldShowRecipientColumn = true }) => {
  const isMobileView = useMedia(media.maxWidth.sm);

  if (isMobileView) {
    return (
      <>
        <div>
          <div>
            <Skeleton highContrast height={rem(DEFAULT_HEIGHT)} />
          </div>
        </div>
        <div>
          <div>
            <Skeleton height={rem(DEFAULT_HEIGHT)} />
          </div>
          <div>
            <Skeleton height={rem(DEFAULT_HEIGHT)} />
          </div>
          {shouldShowRecipientColumn && (
            <div>
              <Skeleton height={rem(DEFAULT_HEIGHT)} />
            </div>
          )}
          <div>
            <Skeleton height={rem(DEFAULT_HEIGHT)} marginBottom="1rem" />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <tr>
        <th colSpan={shouldShowRecipientColumn ? 3 : 4}>
          <Skeleton highContrast height={rem(DEFAULT_HEIGHT)} />
        </th>
        <th>
          <Skeleton highContrast height={rem(DEFAULT_HEIGHT)} />
        </th>
        {shouldShowRecipientColumn && (
          <th>
            <Skeleton highContrast height={rem(DEFAULT_HEIGHT)} />
          </th>
        )}
        <th>
          <Skeleton highContrast height={rem(DEFAULT_HEIGHT)} />
        </th>
      </tr>
      <tr>
        <td colSpan={shouldShowRecipientColumn ? 3 : 4}>
          <Skeleton height={rem(DEFAULT_HEIGHT)} />
        </td>
        <td>
          <Skeleton height={rem(DEFAULT_HEIGHT)} />
        </td>
        {shouldShowRecipientColumn && (
          <td>
            <Skeleton height={rem(DEFAULT_HEIGHT)} />
          </td>
        )}
        <td>
          <Skeleton height={rem(DEFAULT_HEIGHT)} />
        </td>
      </tr>
    </>
  );
};

SparePartQuotationPendingCustomerValidationLineSkeleton.displayName =
  'SparePartQuotationPendingCustomerValidationLineSkeleton';

export default SparePartQuotationPendingCustomerValidationLineSkeleton;
