import { INDEXING_STRATEGIES } from '../AdditionalInformation.types';

import { indexWithoutUniquenessByProduct } from './indexWithoutUniquenessByProduct';
import { indexWithUniquenessByProduct } from './indexWithUniquenessByProduct';

export const indexNeededInformation = ({
  strategy,
}: {
  strategy: INDEXING_STRATEGIES;
}) => {
  if (strategy === INDEXING_STRATEGIES.WITH_UNIQUENESS_BY_PRODUCT) {
    return indexWithUniquenessByProduct();
  }
  if (strategy === INDEXING_STRATEGIES.WITHOUT_UNIQUENESS_BY_PRODUCT) {
    return indexWithoutUniquenessByProduct();
  }

  throw new Error(`Strategy is no supported ${strategy}.`);
};
