import { defineMessages } from 'react-intl';

export default defineMessages({
  distance: {
    id: 'components.myaccount.claims.confirmation.addressConfirmation.distance',
    defaultMessage: 'à',
  },
  openingHours: {
    id: 'components.myaccount.claims.confirmation.addressConfirmation.openingHours',
    defaultMessage: 'Opening Hours',
  },
  free: {
    id: 'components.myaccount.claims.confirmation.addressConfirmation.free',
    defaultMessage: 'Free',
  },
  cancel: {
    id: 'view.commentModal.cancel',
    defaultMessage: 'Cancel',
  },
});
