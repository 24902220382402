import React, { useCallback, useState } from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';
import { useParams, useHistory } from 'react-router-dom';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { Input } from '@savgroup-front-common/core/src/atoms/Form';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';
import { ICONS_TYPE } from '@savgroup-front-common/types/src/Icon';

import AgreementCheckbox from './AgreementCheckbox';
import useClaimGroupConfirmationPage from './ClaimGroupConfirmationPage.hooks';
import {
  $AgreementRow,
  $ConfirmationItemWrapper,
  $ConfirmationResultWrapper,
  $ConfirmationWrapper,
  $Heading,
  $HeadingIcon,
  $LeftColumn,
} from './ClaimGroupConfirmationPage.styles';
import messages from './messages';

import { buildProfessionalRoute } from 'myaccount/view/app/NewLayout/ProfessionalRoutes.helpers';
import {
  PROFESSIONAL_STEP_CONFIG,
  PROFESSIONAL_STEP_PROGRESS,
} from 'myaccount/view/app/NewLayout/ProfessionalRoutes.config';
import StepLayoutProfessional from 'myaccount/view/app/NewLayout/StepLayout/StepLayoutProfessional';
import { ResumeSolution } from '../../../components/ResumeSolution';
import { ResumeDeposit } from '../../../components/ResumeDeposit';
import { ResumeDelivery } from '../../../components/ResumeDelivery';
import useGetClientInformation from '../../../app/hooks/useGetClientInformation';
import { useGetHandlingByClaimGroup } from '../../../app/hooks/useGetHandlingByClaimGroup';
import { ResumeCard } from '../components/ResumeCard';
import { Modal } from '@savgroup-front-common/core/src/atoms/modal';

const ClaimGroupConfirmationPage: React.FC = () => {
  const history = useHistory();
  const { claimGroupId } = useParams<{ claimGroupId: string }>();

  const [openResumeModal, setOpenResumeModal] = useState(false);
  const {
    claimGroupConfirmation,
    formContext,
    agreementUrl,
    isLoading,
    paymentRequired,
    isLoadingSubmit,
    onSubmit,
  } = useClaimGroupConfirmationPage();

  const { clientInformation } = useGetClientInformation();
  const sellerId = clientInformation?.sellerId;

  const { handling } = useGetHandlingByClaimGroup({ claimGroupId });
  const hasDeposit = handling?.hasDeposit;
  const hasDelivery = handling?.hasDelivery;

  const handlePreviousStep = useCallback(() => {
    if (hasDelivery) {
      history.push(
        buildProfessionalRoute({
          route: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_DELIVERY_PAGE,
          payload: { claimGroupId },
        }),
      );

      return undefined;
    }

    if (hasDeposit) {
      history.push(
        buildProfessionalRoute({
          route: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_DEPOSIT_PAGE,
          payload: { claimGroupId },
        }),
      );

      return undefined;
    }

    history.push(
      buildProfessionalRoute({
        route: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_SOLUTION_PAGE,
        payload: { claimGroupId },
      }),
    );

    return undefined;
  }, [claimGroupId, hasDelivery, hasDeposit, history]);

  const {
    control,
    formState: { errors },
  } = formContext;

  const handleOpenModal = () => setOpenResumeModal((prev) => !prev);

  if (paymentRequired && sellerId) {
    throw new Error(`Payment is not supported yet`);
  }

  if (isLoading || !claimGroupId) {
    return <BaseLoader />;
  }

  return (
    <>
      <Grid fluid>
        <FormProvider {...formContext}>
          <form onSubmit={onSubmit}>
            <StepLayoutProfessional
              stepName={PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_CONFIRMATION_PAGE}
              customSteps={PROFESSIONAL_STEP_PROGRESS}
              handlePreviousStep={handlePreviousStep}
              isLoading={isLoadingSubmit}
            >
              <$ConfirmationWrapper>
                <$LeftColumn>
                  <ResumeSolution
                    claimGroupId={claimGroupId}
                    claimGroupConfirmation={claimGroupConfirmation}
                    customBuildUpdateSolutionUrl={buildProfessionalRoute({
                      route:
                        PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_SOLUTION_PAGE,
                      payload: { claimGroupId },
                    })}
                  />
                  {hasDeposit && (
                    <ResumeDeposit
                      claimGroupConfirmation={claimGroupConfirmation}
                    />
                  )}
                  {hasDelivery && (
                    <ResumeDelivery
                      claimGroupConfirmation={claimGroupConfirmation}
                    />
                  )}

                  <$ConfirmationItemWrapper>
                    <$Heading>
                      <$HeadingIcon>
                        <MyAccountIcon icon={ICONS_TYPE.SEND_MAIL_ICON} />
                      </$HeadingIcon>
                      <SafeFormattedMessageWithoutSpread
                        message={messages.notificationsTitle}
                      />
                    </$Heading>
                    <Row>
                      <Col sm={6} xs={12}>
                        <Controller
                          control={control}
                          name="email"
                          render={({ field }) => {
                            return (
                              <Input
                                name={field.name}
                                value={field.value}
                                label={messages.mail}
                                disableBrowserAutocomplete
                                errors={errors}
                                onChange={field.onChange}
                              />
                            );
                          }}
                        />
                      </Col>
                      <Col sm={6} xs={12}>
                        <Controller
                          control={control}
                          name="phone"
                          render={({ field }) => {
                            return (
                              <Input
                                name={field.name}
                                value={field.value}
                                label={messages.phone}
                                disableBrowserAutocomplete
                                errors={errors}
                                onChange={field.onChange}
                              />
                            );
                          }}
                        />
                      </Col>
                    </Row>
                    <$AgreementRow>
                      <Col sm={12} xs={12}>
                        <Controller
                          control={control}
                          name="cgv"
                          render={({ field }) => {
                            return (
                              <AgreementCheckbox
                                agreementHref={agreementUrl}
                                isChecked={field.value}
                                onChange={field.onChange}
                              />
                            );
                          }}
                        />
                      </Col>
                    </$AgreementRow>
                  </$ConfirmationItemWrapper>
                </$LeftColumn>
                <$ConfirmationResultWrapper>
                  <ResumeCard />
                </$ConfirmationResultWrapper>
              </$ConfirmationWrapper>
            </StepLayoutProfessional>
          </form>
        </FormProvider>
      </Grid>
      <Modal
        isOpen={openResumeModal}
        onClose={handleOpenModal}
        shouldShowPadding={false}
      >
        <ResumeCard />
      </Modal>
    </>
  );
};

ClaimGroupConfirmationPage.displayName = 'ClaimGroupConfirmationPage';

export default ClaimGroupConfirmationPage;
