import { Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { mediaQuery } from '@savgroup-front-common/core/src/grid/helpers';

export const $PaymentContainer = styled('div')`
  padding: 28px;
  ${mediaQuery.sm`
    padding: 55px;
  `}
  border: 1px solid #c9ced6;
  border-radius: 8px;
`;

export const $PaymentButtonContainer = styled('div')`
  margin-top: 1rem;
`;

export const $PaymentInputContainer = styled(Row)`
  margin-bottom: 12px;
`;
