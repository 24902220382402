import { createRequest } from '../../services/request';

export const GET_SELLER_CONFIGURATION = createRequest(
  '@@SAV/SELLER_DOMAIN/GET_SELLER_CONFIGURATION',
);
export const GET_SELLERS_CONFIGURATION = createRequest(
  '@@SAV/SELLER_DOMAIN/GET_SELLERS_CONFIGURATION',
);

export const GET_SELLER_BY_ID = createRequest(
  '@@SAV/SELLER_DOMAIN/GET_SELLER_BY_ID',
);

export const SET_SELLER_CONFIGURATION = createRequest(
  '@@SAV/SELLER_DOMAIN/SET_SELLER_CONFIGURATION',
);
export const SET_SELLER_CONFIGURATION_OTHER = createRequest(
  '@@SAV/SELLER_DOMAIN/SET_SELLER_CONFIGURATION_OTHER',
);
export const SET_SELLER_CONFIGURATION_ACCEPTED_LANGUAGES = createRequest(
  '@@SAV/SELLER_DOMAIN/SET_SELLER_CONFIGURATION_ACCEPTED_LANGUAGES',
);
export const SET_SELLER_CONFIGURATION_TRANSPORT = createRequest(
  '@@SAV/SELLER_DOMAIN/SET_SELLER_CONFIGURATION_TRANSPORT',
);
export const SET_SELLER_CONFIGURATION_ACCOUNT_MANAGER = createRequest(
  '@@SAV/SELLER_DOMAIN/SET_SELLER_CONFIGURATION_ACCOUNT_MANAGER',
);
