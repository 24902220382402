import { defineMessages } from 'react-intl';

export default defineMessages({
  bannerTitle: {
    id: 'components.myaccount.homePage.bannerTitle',
    defaultMessage: 'Hello {userName}',
  },
  bannerDescription: {
    id: 'components.myaccount.homePage.bannerDescription',
    defaultMessage: 'Welcome in your care and repair space',
  },
  myProductsLabel: {
    id: 'components.myaccount.homePage.myProductsLabel',
    defaultMessage: 'My products',
  },
  myRepairsLabel: {
    id: 'components.myaccount.homePage.myRepairsLabel',
    defaultMessage: 'My repairs',
  },
});
