import { Dictionary, groupBy } from 'lodash';
import {
  HANDLING_GROUPS,
  HANDLING_MODES,
  HANDLING_TYPES,
} from '@savgroup-front-common/constants';
import { CarrierProductSummary } from '@savgroup-front-common/core/src/api/Carrier/getCarrierProductByIdQuery';
import { HandlingSummary } from '@savgroup-front-common/types';
import { Carrier } from 'myaccount/api/Claim/getClaimGroupCarrierQuery';
import getAllCarriers from './getAllCarriers';

const getGroupedCarriers = ({
  carriers,
  multiCarrierProductSummary,
  handlingInfo,
}: {
  carriers?: Carrier[];
  multiCarrierProductSummary?: CarrierProductSummary[];
  handlingInfo?: HandlingSummary;
}): {
  groupedCarriers: Dictionary<Carrier[]>;
  allCarriers: Carrier[];
} => {
  const allCarriers = getAllCarriers({
    handlingInfo,
    carriers,
    multiCarrierProductSummary,
    handlingMode: HANDLING_MODES.DELIVERY,
  });
  const groupedCarriers: Dictionary<Carrier[]> = groupBy(
    allCarriers,
    (carrier) => {
      switch (carrier.productType) {
        case HANDLING_TYPES.HOME_DELIVERY:
          return HANDLING_GROUPS.HOME;
        case HANDLING_TYPES.PICKUP_POST_OFFICE:
        case HANDLING_TYPES.PICKUP:
        case HANDLING_TYPES.PICKUP_COUNTER:
          return HANDLING_GROUPS.PICKUP_POINT;
        case HANDLING_TYPES.DROP_AT_STORE:
          return HANDLING_GROUPS.PICKUP_STORE;
        case HANDLING_TYPES.PICKUP_STORE_DELIVERY:
          return HANDLING_GROUPS.PICKUP_STORE_DELIVERY;
        case HANDLING_TYPES.SELLER_PROVIDED_CARRIER:
          return HANDLING_GROUPS.EXTERNAL;
        case HANDLING_TYPES.HOME_INTERVENTION:
          return HANDLING_GROUPS.HOME_INTERVENTION;
        default:
          return carrier.productType;
      }
    },
  );

  return { groupedCarriers, allCarriers };
};

export default getGroupedCarriers;
