import { defineMessages } from 'react-intl';

export default defineMessages({
  breadcrumbMyRequests: {
    id: 'components.myaccount.FileFollowupsPage.breadcrumbMyRequests',
    defaultMessage: 'My requests',
  },
  bannerTitle: {
    id: 'components.myaccount.FileFollowupsPage.bannerTitle',
    defaultMessage: 'Request n° {reference}',
  },
  bannerTitleUnknown: {
    id: 'components.myaccount.FileFollowupsPage.bannerTitleUnknown',
    defaultMessage: 'Request unknown',
  },
  bannerDescription: {
    id: 'components.myaccount.FileFollowupsPage.bannerDescription',
    defaultMessage: 'Follow the progress of your request here',
  },
  actionNewRequest: {
    id: 'components.myaccount.FileFollowupsPage.actionNewRequest',
    defaultMessage: 'New request',
  },
  claimSummaryDefaultMessage: {
    id: 'components.myaccount.FileFollowupsPage.claimSummaryDefaultMessage',
    defaultMessage: 'My file status ?',
  },
  claimSummaryDateMessage: {
    id: 'components.myaccount.FileFollowupsPage.claimSummaryDateMessage',
    defaultMessage: '{status} le {date}',
  },
  clientServiceCommentary: {
    id: 'components.myaccount.FileFollowupsPage.clientServiceCommentary',
    defaultMessage: 'Client service commentary : {value}',
  },
});
