import React, { FC } from 'react';
import { FormProvider } from 'react-hook-form';
import { Col } from 'react-styled-flexboxgrid';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import {
  CollectBankAccountDetailsForm,
  useCollectBankAccountDetailsForm,
} from '@savgroup-front-common/core/src/components/CollectBankAccountDetailsForm';
import { ActionRow } from '@savgroup-front-common/core/src/atoms/ActionRow';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { Card } from '@savgroup-front-common/core/src/atoms/Cards';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { BannersConsumer } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';

import messages from './messages';
import { CheckIcon } from '@savgroup-front-common/core/src/protons/icons';

interface CollectBankAccountDetailsProps {
  fileId: string;
  isLoading?: boolean;
  onSubmit: () => void;
}

const CollectBankAccountDetails: FC<CollectBankAccountDetailsProps> = ({
  fileId,
  isLoading = false,
  onSubmit,
}) => {
  const { handleSubmitCollectBankAccountDetails, formContext } =
    useCollectBankAccountDetailsForm({
      fileId,
      onUpsertSuccess: onSubmit,
    });
  const {
    formState: { isSubmitting },
  } = formContext;

  return (
    <Card>
      <FormProvider {...formContext}>
        <form onSubmit={handleSubmitCollectBankAccountDetails}>
          <BannersConsumer />

          <CollectBankAccountDetailsForm />

          <ActionRow>
            <Col xs={12} sm>
              <Button
                primary
                type={BUTTON_TYPES.SUBMIT}
                isLoading={isLoading || isSubmitting}
                icon={<CheckIcon />}
                dataTestId="submitButton"
              >
                <SafeFormattedMessageWithoutSpread
                  message={messages.validate}
                />
              </Button>
            </Col>
          </ActionRow>
        </form>
      </FormProvider>
    </Card>
  );
};

CollectBankAccountDetails.displayName = 'CollectBankAccountDetails';

export default CollectBankAccountDetails;
