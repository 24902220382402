import React, { FunctionComponent } from 'react';
import { TransitionStatus } from 'react-transition-group/Transition';
import { useMeasure } from 'react-use';

import {
  $AddAndRemoveContainer,
  $AddAndRemoveSizer,
} from './AddAndRemove.styles';

interface AddAndRemoveProps {
  animationState: TransitionStatus;
  animationDuration: number;
}

const AddAndRemove: FunctionComponent<
  React.PropsWithChildren<AddAndRemoveProps>
> = ({ animationState, animationDuration, children }) => {
  const [ref, { height }] = useMeasure<HTMLDivElement>();

  return (
    <$AddAndRemoveContainer $height={height} animationState={animationState}>
      <$AddAndRemoveSizer
        animationState={animationState}
        animationDuration={animationDuration}
        ref={ref}
      >
        {children}
      </$AddAndRemoveSizer>
    </$AddAndRemoveContainer>
  );
};

AddAndRemove.displayName = 'AddAndRemove';

export default AddAndRemove;
