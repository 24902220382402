import { GetPickupPointsArgs } from 'myaccount/api/Carrier/getPickupPoints';
import { Carrier } from 'myaccount/api/Claim/getClaimGroupCarrierQuery';

export const preparePickupPoints = ({
  carriers,
  countryCode,
  distance,
  maxPickupPoint,
  sellerId,
  address,
  claimId,
}: {
  carriers: Carrier[];
  countryCode: string;
  distance: number;
  maxPickupPoint: number;
  sellerId?: string;
  address: string;
  claimId?: string;
}) => {
  return (
    (carriers?.map((carrier) => ({
      countryCode,
      carrierCompany: carrier.carrierBrand,
      distance,
      maxPickupPoint,
      productType: carrier.productType,
      carrierProductId: carrier.carrierProductId,
      carrierName: carrier.carrierName,
      sellerId,
      address,
      claimId,
    })) as GetPickupPointsArgs[]) || []
  );
};
