import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import useCommonTypedSelector from '../../useCommonTypedSelector';
import * as ActorsActionCreators from '../actionCreators';
import * as ActorsSelectors from '../selectors';

interface UseGetActorSummaryArgs {
  actorId?: string;
  actorType?: string;
  sellerId?: string;
  ownerId?: string;
}

const useGetActorSummary = ({
  actorId,
  actorType,
  sellerId,
  ownerId,
}: UseGetActorSummaryArgs): { actorSummary: any; isLoading: boolean } => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      ActorsActionCreators.getActorSummaryById({
        actorId,
        actorType,
        sellerId,
        ownerId,
      }),
    );
  }, [actorId, actorType, dispatch, ownerId, sellerId]);

  const actorSummary = useCommonTypedSelector((store) =>
    ActorsSelectors.selectActorSummaryById(store, { actorId }),
  );

  return { actorSummary, isLoading: actorSummary === null };
};

export default useGetActorSummary;
