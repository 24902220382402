import { fromJS } from 'immutable';
import get from 'lodash/get';

export function onOrderIdByClaimGroupLoading(state, { meta }) {
  const claimGroupId = get(meta, ['claimGroupId']);

  if (!claimGroupId) {
    return state;
  }

  return state.setIn(
    ['orderIdByClaimGroup', claimGroupId],
    fromJS({ isLoaded: false, value: undefined }),
  );
}

export function onOrderIdByClaimGroupErrored(state, { meta }) {
  const claimGroupId = get(meta, ['claimGroupId']);

  if (!claimGroupId) {
    return state;
  }

  return state.setIn(
    ['orderIdByClaimGroup', claimGroupId],
    fromJS({ isLoaded: true, hasErrors: true }),
  );
}

export function onOrderIdByClaimGroupLoaded(state, { payload, meta }) {
  const claimGroupId = get(meta, ['claimGroupId']);
  const orderId = get(payload, ['value']);

  if (!claimGroupId) {
    return state;
  }

  return state.setIn(
    ['orderIdByClaimGroup', claimGroupId],
    fromJS({ isLoaded: true, hasErrors: false }).setIn(['value'], orderId),
  );
}
