import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';
import { Row } from 'react-styled-flexboxgrid';

export const $ClaimsSummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 72px;
  background: ${({ theme }) => theme.colors.secondaryColor2};
  border-radius: 8px;

  & > * {
    width: 100%;
  }
  @media ${media.maxWidth.sm} {
    padding: 40px 20px;
  }
  margin-bottom: 1rem;
`;

export const $Heading = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: ${({ theme }) => theme.systemColors.defaultTextColor};
  margin-bottom: 1.5rem;
`;
export const $Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const $Spacing = styled.div`
  margin-bottom: 25px;
`;

export const $CustomerCommentRow = styled(Row)`
  margin-top: 5px;
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  align-items: center;
`;

export const $IconPosition = styled.div`
  margin-right: 10px;
  display: flex;
`;
