import { useQuery } from 'react-query';

import { WorkflowService } from 'myaccount/api';

export const GetFullClaimContextQuery = 'GetFullClaimContextQuery';

export const useGetTechnicalInterventionQuery = ({
  fileId,
}: {
  fileId?: string;
}) => {
  const { data: technicalIntervention } = useQuery(
    [GetFullClaimContextQuery, { fileId }],
    async () => {
      if (!fileId) {return undefined;}

      const response = await WorkflowService.getTechnicalInterventionQuery({
        fileId,
      });

      if (response.failure) {
        return undefined;
      }

      return response.value;
    },
  );

  return {
    technicalIntervention,
  };
};
