enum HANDLING_TYPES {
  HOME_DELIVERY = 'HomeDelivery',
  PICKUP_POST_OFFICE = 'PickupPostOffice',
  PICKUP = 'Pickup',
  PICKUP_COUNTER = 'PickupCounter',
  DROP_AT_STORE = 'DropAtStore',
  SELLER_PROVIDED_CARRIER = 'SellerProvidedCarrier',
  PICKUP_STORE_DELIVERY = 'PickUpStoreDelivery',
  HOME_INTERVENTION = 'HomeIntervention',
}

export default HANDLING_TYPES;
