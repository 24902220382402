import { useQuery } from 'react-query';

import { CarrierService } from 'myaccount/api';

export const GetFullClaimContextQuery = 'GetFullClaimContextQuery';

export const useGetCarrierLabelsInfosQuery = ({
  fileId,
}: {
  fileId?: string;
}) => {
  const { data: carriersLabels } = useQuery(
    [GetFullClaimContextQuery, { fileId }],
    async () => {
      if (!fileId) {return undefined;}

      const response = await CarrierService.getCarrierLabelsInfosQuery({
        fileId,
      });

      if (response.failure) {
        return undefined;
      }

      return response.value;
    },
  );

  return {
    carriersLabels,
  };
};
