import React, { FC } from 'react';
import useListOwnerProductsQuery from './HomePageFiles.hooks';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import HomePageFileItem from './HomePageFileItem/HomePageFileItem';
import { $HomeProductsListWrapper } from '../HomePage.styles';

const HomePageFiles: FC = () => {
  const { products, isLoading } = useListOwnerProductsQuery();

  if (isLoading) {
    return <BaseLoader />;
  }

  return (
    <$HomeProductsListWrapper>
      {products?.map((product) => (
        <HomePageFileItem product={product} key={product.ownerProductId} />
      ))}
    </$HomeProductsListWrapper>
  );
};

HomePageFiles.displayName = 'HomePageFiles';
export default HomePageFiles;
