import React, { FunctionComponent } from 'react';
import { Transition } from 'react-transition-group';

import { ExtendedFile } from '@savgroup-front-common/types';

import { AddAndRemove, AddAndRemoveList } from '../../../AddAndRemove';
import { FieldMessages } from '../../common/helpers/getFinalFieldState.types';

import AttachmentItem from './components/AttachmentItem';

interface AttachmentsListProps {
  files: ExtendedFile[];
  onRemove?: (attachment: ExtendedFile, index: number) => void;
  name: string;
  isLiveUpload?: boolean;
  errors: FieldMessages;
}

const animationDuration = 200;

const AttachmentsList: FunctionComponent<
  React.PropsWithChildren<AttachmentsListProps>
> = ({ files = [], onRemove, name, isLiveUpload = true, errors }) => {
  return (
    <AddAndRemoveList component="ul" data-testid="attachementList">
      {files.map((file, index) => (
        <Transition
          key={
            file.internalId ||
            file.id ||
            file.name ||
            String(`${name}.${index}`)
          }
          timeout={animationDuration}
        >
          {(animationState) => (
            <AddAndRemove
              animationState={animationState}
              animationDuration={animationDuration}
            >
              <AttachmentItem
                index={index}
                file={file}
                onRemove={onRemove}
                name={`${name}.${index}`}
                isLiveUpload={isLiveUpload}
                errors={errors}
              />
            </AddAndRemove>
          )}
        </Transition>
      ))}
    </AddAndRemoveList>
  );
};

AttachmentsList.displayName = 'AttachmentsList';

export default AttachmentsList;
