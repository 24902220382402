/* eslint-disable max-len */
import React from 'react';
import { useTheme } from 'styled-components';

const LoaderTools = () => {
  const theme = useTheme();

  return (
    <svg id="4901291" viewBox="0 0 56 56" xmlns="http://www.w3.org/2000/svg">
      <g transform="matrix(1,0,0,1,0,0)">
        <g id="4901291" opacity="1" style={{ 'mix-blend-mode': 'normal' }}>
          <g>
            <defs>
              <clipPath
                id="4901291_clipPath"
                x="-50%"
                y="-50%"
                width="200%"
                height="200%"
              >
                <path
                  d="M0,0h56v0v56v0h-56v0v-56z"
                  fill="white"
                  clipRule="nonzero"
                />
              </clipPath>
            </defs>
            <g clipPath="url(#4901291_clipPath)">
              <path
                id="4901291_fill_path"
                d="M0,0h56v0v56v0h-56v0v-56z"
                fillRule="nonzero"
                fill="rgb(255, 255, 255)"
                fillOpacity="1"
                style={{ 'mix-blend-mode': 'normal' }}
              />
              <g transform="matrix(1,0,0,1,0,1.48)">
                <g
                  id="4901325"
                  opacity="1"
                  style={{ 'mix-blend-mode': 'normal' }}
                >
                  <g>
                    <defs>
                      <clipPath
                        id="4901325_clipPath"
                        x="-50%"
                        y="-50%"
                        width="200%"
                        height="200%"
                      >
                        <path
                          d="M0,0h56v0v53.0359v0h-56v0v-53.0359z"
                          fill="white"
                          clipRule="nonzero"
                        />
                      </clipPath>
                    </defs>
                    <g clipPath="url(#4901325_clipPath)">
                      <path
                        id="4901325_fill_path"
                        d="M0,0h56v0v53.0359v0h-56v0v-53.0359z"
                        fillRule="nonzero"
                        fill="rgb(255, 255, 255)"
                        fillOpacity="1"
                        style={{ 'mix-blend-mode': 'normal' }}
                      />
                      <g transform="matrix(0.9337679395350391,-0.3578790788750964,0.3578790788750964,0.9337679395350391,22.03,9.15)">
                        <g
                          id="4901326"
                          opacity="1"
                          style={{ 'mix-blend-mode': 'normal' }}
                        >
                          <g>
                            <g>
                              <g style={{ 'mix-blend-mode': 'normal' }}>
                                <path
                                  id="4901326_stroke_path"
                                  d="M19.604,16.18533l5.17067,5.17067c0.49992,0.50007 0.78076,1.17823 0.78076,1.88533c0,0.7071 -0.28084,1.38526 -0.78076,1.88534c-0.50007,0.49992 -1.17823,0.78076 -1.88533,0.78076c-0.7071,0 -1.38526,-0.28084 -1.88533,-0.78076l-8.27866,-8.27733M5.65733,5.65733l-2.83067,0.94133l-2.82667,-4.71333l1.88533,-1.88533l4.71467,2.828z M5.65733,5.65733l3.77333,3.77333M19.604,16.18533l-5.62537,-5.62537c-0.77921,-0.86948 -2.77971,-2.31261 -4.54796,-1.1293c-1.47869,1.72184 -0.36538,3.7175 0.3761,4.5001l2.91856,2.91856"
                                  fill="none"
                                  stroke={theme.colors.primary}
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeDasharray="0 0"
                                  strokeOpacity="1"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g transform="matrix(0.9452910557512494,0.3262281715558734,-0.3262281715558734,0.9452910557512494,9.2,21.07)">
                        <g
                          id="4901327"
                          opacity="1"
                          style={{ 'mix-blend-mode': 'normal' }}
                        >
                          <g>
                            <g>
                              <g transform="matrix(1,0,0,1,0,11.16)">
                                <g
                                  id="4901328"
                                  opacity="1"
                                  style={{ 'mix-blend-mode': 'normal' }}
                                >
                                  <g>
                                    <g>
                                      <path
                                        id="4901328_fill_path"
                                        d="M10.20876,0l-9.42667,9.42667c-0.24794,0.24766 -0.44463,0.54176 -0.57883,0.86549c-0.1342,0.32373 -0.20327,0.67073 -0.20327,1.02117c0,0.35044 0.06907,0.69745 0.20327,1.02118c0.1342,0.32373 0.33089,0.61783 0.57883,0.86549c0.50007,0.49992 1.17823,0.78076 1.88533,0.78076c0.7071,0 1.38526,-0.28084 1.88533,-0.78076l9.42667,-9.42933"
                                        fillRule="nonzero"
                                        fill="rgb(255, 255, 255)"
                                        fillOpacity="1"
                                        style={{ 'mix-blend-mode': 'normal' }}
                                      />
                                      <g style={{ 'mix-blend-mode': 'normal' }}>
                                        <path
                                          id="4901328_stroke_path"
                                          d="M10.20876,0l-9.42667,9.42667c-0.24794,0.24766 -0.44463,0.54176 -0.57883,0.86549c-0.1342,0.32373 -0.20327,0.67073 -0.20327,1.02117c0,0.35044 0.06907,0.69745 0.20327,1.02118c0.1342,0.32373 0.33089,0.61783 0.57883,0.86549c0.50007,0.49992 1.17823,0.78076 1.88533,0.78076c0.7071,0 1.38526,-0.28084 1.88533,-0.78076l9.42667,-9.42933"
                                          fill="none"
                                          stroke={theme.colors.primary}
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeDasharray="0 0"
                                          strokeOpacity="1"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                              <g transform="matrix(1,0,0,1,9.55,0)">
                                <g
                                  id="4901329"
                                  opacity="1"
                                  style={{ 'mix-blend-mode': 'normal' }}
                                >
                                  <g>
                                    <g>
                                      <path
                                        id="4901329_fill_path"
                                        d="M0.65869,11.15693c-1.12533,-2.87067 -0.90533,-6.63733 1.41467,-8.95733c2.32,-2.32 6.6,-2.828 8.956,-1.41333l-4.05333,4.05333l-0.37733,4.148l4.148,-0.376l4.05333,-4.05467c1.416,2.35733 0.90667,6.63733 -1.41333,8.956c-2.32,2.32 -6.08533,2.54 -8.956,1.41467"
                                        fillRule="nonzero"
                                        fill="rgb(255, 255, 255)"
                                        fillOpacity="1"
                                        style={{ 'mix-blend-mode': 'normal' }}
                                      />
                                      <g style={{ 'mix-blend-mode': 'normal' }}>
                                        <path
                                          id="4901329_stroke_path"
                                          d="M0.65869,11.15693c-1.12533,-2.87067 -0.90533,-6.63733 1.41467,-8.95733c2.32,-2.32 6.6,-2.828 8.956,-1.41333l-4.05333,4.05333l-0.37733,4.148l4.148,-0.376l4.05333,-4.05467c1.416,2.35733 0.90667,6.63733 -1.41333,8.956c-2.32,2.32 -6.08533,2.54 -8.956,1.41467"
                                          fill="none"
                                          stroke={theme.colors.primary}
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeDasharray="0 0"
                                          strokeOpacity="1"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g transform="matrix(1,0,0,1,36.04,25.13)">
                        <g
                          id="4901330"
                          opacity="0"
                          style={{ 'mix-blend-mode': 'normal' }}
                        >
                          <g>
                            <g>
                              <path
                                id="4901330_fill_path"
                                d="M0,0h2v0v2v0h-2v0v-2z"
                                fillRule="nonzero"
                                fill={theme.colors.primary}
                                fillOpacity="1"
                                style={{ 'mix-blend-mode': 'normal' }}
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g transform="matrix(1,0,0,1,12.04,25.13)">
                        <g
                          id="4901331"
                          opacity="0"
                          style={{ 'mix-blend-mode': 'normal' }}
                        >
                          <g>
                            <g>
                              <path
                                id="4901331_fill_path"
                                d="M0,0h2v0v2v0h-2v0v-2z"
                                fillRule="nonzero"
                                fill={theme.colors.primary}
                                fillOpacity="1"
                                style={{ 'mix-blend-mode': 'normal' }}
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g transform="matrix(1,0,0,1,24.04,13.13)">
                        <g
                          id="4901332"
                          opacity="0"
                          style={{ 'mix-blend-mode': 'normal' }}
                        >
                          <g>
                            <g>
                              <path
                                id="4901332_fill_path"
                                d="M0,0h2v0v2v0h-2v0v-2z"
                                fillRule="nonzero"
                                fill={theme.colors.primary}
                                fillOpacity="1"
                                style={{ 'mix-blend-mode': 'normal' }}
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g transform="matrix(0.7071067811865476,0.7071067811865475,-0.7071067811865475,0.7071067811865476,33.45,16.13)">
                        <g
                          id="4901333"
                          opacity="0"
                          style={{ 'mix-blend-mode': 'normal' }}
                        >
                          <g>
                            <g>
                              <path
                                id="4901333_fill_path"
                                d="M0,0h2v0v2v0h-2v0v-2z"
                                fillRule="nonzero"
                                fill={theme.colors.primary}
                                fillOpacity="1"
                                style={{ 'mix-blend-mode': 'normal' }}
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g transform="matrix(0.7071067811865476,0.7071067811865475,-0.7071067811865475,0.7071067811865476,16.45,16.13)">
                        <g
                          id="4901334"
                          opacity="0"
                          style={{ 'mix-blend-mode': 'normal' }}
                        >
                          <g>
                            <g>
                              <path
                                id="4901334_fill_path"
                                d="M0,0h2v0v2v0h-2v0v-2z"
                                fillRule="nonzero"
                                fill={theme.colors.primary}
                                fillOpacity="1"
                                style={{ 'mix-blend-mode': 'normal' }}
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g transform="matrix(0.7071067811865476,0.7071067811865475,-0.7071067811865475,0.7071067811865476,15.4521,33.1315)">
                        <g
                          id="4901335"
                          opacity="0"
                          style={{ 'mix-blend-mode': 'normal' }}
                        >
                          <g>
                            <g>
                              <path
                                id="4901335_fill_path"
                                d="M0,0h2v0v2v0h-2v0v-2z"
                                fillRule="nonzero"
                                fill={theme.colors.primary}
                                fillOpacity="1"
                                style={{ 'mix-blend-mode': 'normal' }}
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g transform="matrix(1,0,0,1,19.9998,9.5858)">
                <g
                  id="4901377"
                  opacity="0"
                  style={{ 'mix-blend-mode': 'normal' }}
                >
                  <g>
                    <g>
                      <g transform="matrix(1,0,0,1,21.0002,11.999999999999998)">
                        <g
                          id="4901378"
                          opacity="0"
                          style={{ 'mix-blend-mode': 'normal' }}
                        >
                          <g>
                            <g>
                              <path
                                id="4901378_fill_path"
                                d="M0,0h2v0v2v0h-2v0v-2z"
                                fillRule="nonzero"
                                fill={theme.colors.primary}
                                fillOpacity="1"
                                style={{ 'mix-blend-mode': 'normal' }}
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g transform="matrix(1,0,0,1,9.0002,0)">
                        <g
                          id="4901380"
                          opacity="0"
                          style={{ 'mix-blend-mode': 'normal' }}
                        >
                          <g>
                            <g>
                              <path
                                id="4901380_fill_path"
                                d="M0,0h2v0v2v0h-2v0v-2z"
                                fillRule="nonzero"
                                fill={theme.colors.primary}
                                fillOpacity="1"
                                style={{ 'mix-blend-mode': 'normal' }}
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g transform="matrix(0.7071067811865476,0.7071067811865475,-0.7071067811865475,0.7071067811865476,18.414299999999997,3)">
                        <g
                          id="4901381"
                          opacity="0"
                          style={{ 'mix-blend-mode': 'normal' }}
                        >
                          <g>
                            <g>
                              <path
                                id="4901381_fill_path"
                                d="M0,0h2v0v2v0h-2v0v-2z"
                                fillRule="nonzero"
                                fill={theme.colors.primary}
                                fillOpacity="1"
                                style={{ 'mix-blend-mode': 'normal' }}
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g transform="matrix(0.7071067811865476,0.7071067811865475,-0.7071067811865475,0.7071067811865476,1.4143000000000008,3)">
                        <g
                          id="4901382"
                          opacity="0"
                          style={{ 'mix-blend-mode': 'normal' }}
                        >
                          <g>
                            <g>
                              <path
                                id="4901382_fill_path"
                                d="M0,0h2v0v2v0h-2v0v-2z"
                                fillRule="nonzero"
                                fill={theme.colors.primary}
                                fillOpacity="1"
                                style={{ 'mix-blend-mode': 'normal' }}
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g transform="matrix(0.7071067811865476,0.7071067811865475,-0.7071067811865475,0.7071067811865476,19.414299999999997,20)">
                        <g
                          id="4901384"
                          opacity="0"
                          style={{ 'mix-blend-mode': 'normal' }}
                        >
                          <g>
                            <g>
                              <path
                                id="4901384_fill_path"
                                d="M0,0h2v0v2v0h-2v0v-2z"
                                fillRule="nonzero"
                                fill={theme.colors.primary}
                                fillOpacity="1"
                                style={{ 'mix-blend-mode': 'normal' }}
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g transform="matrix(1,0,0,1,19.9998,9.59)">
                <g
                  id="4901410"
                  opacity="0"
                  style={{ 'mix-blend-mode': 'normal' }}
                >
                  <g>
                    <g>
                      <g transform="matrix(1,0,0,1,21.0002,12)">
                        <g
                          id="4901403"
                          opacity="1"
                          style={{ 'mix-blend-mode': 'normal' }}
                        >
                          <g>
                            <g>
                              <path
                                id="4901403_fill_path"
                                d="M1,0v0c0.55228,0 1,0.44772 1,1v0c0,0.55228 -0.44772,1 -1,1v0c-0.55228,0 -1,-0.44772 -1,-1v0c0,-0.55228 0.44772,-1 1,-1z"
                                fillRule="nonzero"
                                fill={theme.colors.primary}
                                fillOpacity="1"
                                style={{ 'mix-blend-mode': 'normal' }}
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g transform="matrix(1,0,0,1,9.0002,0)">
                        <g
                          id="4901405"
                          opacity="1"
                          style={{ 'mix-blend-mode': 'normal' }}
                        >
                          <g>
                            <g>
                              <path
                                id="4901405_fill_path"
                                d="M1,0v0c0.55228,0 1,0.44772 1,1v0c0,0.55228 -0.44772,1 -1,1v0c-0.55228,0 -1,-0.44772 -1,-1v0c0,-0.55228 0.44772,-1 1,-1z"
                                fillRule="nonzero"
                                fill={theme.colors.primary}
                                fillOpacity="1"
                                style={{ 'mix-blend-mode': 'normal' }}
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g transform="matrix(0.7071067811865476,0.7071067811865475,-0.7071067811865475,0.7071067811865476,18.414299999999997,3)">
                        <g
                          id="4901406"
                          opacity="0"
                          style={{ 'mix-blend-mode': 'normal' }}
                        >
                          <g>
                            <g>
                              <path
                                id="4901406_fill_path"
                                d="M0,0h2v0v2v0h-2v0v-2z"
                                fillRule="nonzero"
                                fill={theme.colors.primary}
                                fillOpacity="1"
                                style={{ 'mix-blend-mode': 'normal' }}
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g transform="matrix(0.7071067811865476,0.7071067811865475,-0.7071067811865475,0.7071067811865476,1.4143000000000008,3)">
                        <g
                          id="4901407"
                          opacity="0"
                          style={{ 'mix-blend-mode': 'normal' }}
                        >
                          <g>
                            <g>
                              <path
                                id="4901407_fill_path"
                                d="M0,0h2v0v2v0h-2v0v-2z"
                                fillRule="nonzero"
                                fill={theme.colors.primary}
                                fillOpacity="1"
                                style={{ 'mix-blend-mode': 'normal' }}
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g transform="matrix(0.7071067811865476,0.7071067811865475,-0.7071067811865475,0.7071067811865476,19.414299999999997,20)">
                        <g
                          id="4901409"
                          opacity="0"
                          style={{ 'mix-blend-mode': 'normal' }}
                        >
                          <g>
                            <g>
                              <path
                                id="4901409_fill_path"
                                d="M0,0h2v0v2v0h-2v0v-2z"
                                fillRule="nonzero"
                                fill={theme.colors.primary}
                                fillOpacity="1"
                                style={{ 'mix-blend-mode': 'normal' }}
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g transform="matrix(1,0,0,1,19.9998,9)">
                <g
                  id="4901436"
                  opacity="0"
                  style={{ 'mix-blend-mode': 'normal' }}
                >
                  <g>
                    <g>
                      <g transform="matrix(1,0,0,1,21.0002,14)">
                        <g
                          id="4901429"
                          opacity="1"
                          style={{ 'mix-blend-mode': 'normal' }}
                        >
                          <g>
                            <g>
                              <path
                                id="4901429_fill_path"
                                d="M1,0h2c0.55228,0 1,0.44772 1,1v0c0,0.55228 -0.44772,1 -1,1h-2c-0.55228,0 -1,-0.44772 -1,-1v0c0,-0.55228 0.44772,-1 1,-1z"
                                fillRule="nonzero"
                                fill={theme.colors.primary}
                                fillOpacity="1"
                                style={{ 'mix-blend-mode': 'normal' }}
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g transform="matrix(1,0,0,1,9.0002,0)">
                        <g
                          id="4901431"
                          opacity="1"
                          style={{ 'mix-blend-mode': 'normal' }}
                        >
                          <g>
                            <g>
                              <path
                                id="4901431_fill_path"
                                d="M1,0v0c0.55228,0 1,0.44772 1,1v2c0,0.55228 -0.44772,1 -1,1v0c-0.55228,0 -1,-0.44772 -1,-1v-2c0,-0.55228 0.44772,-1 1,-1z"
                                fillRule="nonzero"
                                fill={theme.colors.primary}
                                fillOpacity="1"
                                style={{ 'mix-blend-mode': 'normal' }}
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g transform="matrix(0.7071067811865476,0.7071067811865475,-0.7071067811865475,0.7071067811865476,18.414299999999997,5)">
                        <g
                          id="4901432"
                          opacity="1"
                          style={{ 'mix-blend-mode': 'normal' }}
                        >
                          <g>
                            <g>
                              <path
                                id="4901432_fill_path"
                                d="M1,0v0c0.55228,0 1,0.44772 1,1v0c0,0.55228 -0.44772,1 -1,1v0c-0.55228,0 -1,-0.44772 -1,-1v0c0,-0.55228 0.44772,-1 1,-1z"
                                fillRule="nonzero"
                                fill={theme.colors.primary}
                                fillOpacity="1"
                                style={{ 'mix-blend-mode': 'normal' }}
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g transform="matrix(0.7071067811865476,0.7071067811865475,-0.7071067811865475,0.7071067811865476,1.4143000000000008,5)">
                        <g
                          id="4901433"
                          opacity="1"
                          style={{ 'mix-blend-mode': 'normal' }}
                        >
                          <g>
                            <g>
                              <path
                                id="4901433_fill_path"
                                d="M1,0v0c0.55228,0 1,0.44772 1,1v0c0,0.55228 -0.44772,1 -1,1v0c-0.55228,0 -1,-0.44772 -1,-1v0c0,-0.55228 0.44772,-1 1,-1z"
                                fillRule="nonzero"
                                fill={theme.colors.primary}
                                fillOpacity="1"
                                style={{ 'mix-blend-mode': 'normal' }}
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g transform="matrix(0.7071067811865476,0.7071067811865475,-0.7071067811865475,0.7071067811865476,19.414299999999997,22)">
                        <g
                          id="4901435"
                          opacity="1"
                          style={{ 'mix-blend-mode': 'normal' }}
                        >
                          <g>
                            <g>
                              <path
                                id="4901435_fill_path"
                                d="M1,0v0c0.55228,0 1,0.44772 1,1v0c0,0.55228 -0.44772,1 -1,1v0c-0.55228,0 -1,-0.44772 -1,-1v0c0,-0.55228 0.44772,-1 1,-1z"
                                fillRule="nonzero"
                                fill={theme.colors.primary}
                                fillOpacity="1"
                                style={{ 'mix-blend-mode': 'normal' }}
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g transform="matrix(1,0,0,1,16.9998,8)">
                <g
                  id="4901462"
                  opacity="0"
                  style={{ 'mix-blend-mode': 'normal' }}
                >
                  <g>
                    <g>
                      <g transform="matrix(1,0,0,1,27.0002,14)">
                        <g
                          id="4901455"
                          opacity="1"
                          style={{ 'mix-blend-mode': 'normal' }}
                        >
                          <g>
                            <g>
                              <path
                                id="4901455_fill_path"
                                d="M0.5,0v0c0.27614,0 0.5,0.22386 0.5,0.5v1c0,0.27614 -0.22386,0.5 -0.5,0.5v0c-0.27614,0 -0.5,-0.22386 -0.5,-0.5v-1c0,-0.27614 0.22386,-0.5 0.5,-0.5z"
                                fillRule="nonzero"
                                fill={theme.colors.primary}
                                fillOpacity="1"
                                style={{ 'mix-blend-mode': 'normal' }}
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g transform="matrix(1,0,0,1,12.0002,0)">
                        <g
                          id="4901457"
                          opacity="1"
                          style={{ 'mix-blend-mode': 'normal' }}
                        >
                          <g>
                            <g>
                              <path
                                id="4901457_fill_path"
                                d="M0.5,0h1c0.27614,0 0.5,0.22386 0.5,0.5v0c0,0.27614 -0.22386,0.5 -0.5,0.5h-1c-0.27614,0 -0.5,-0.22386 -0.5,-0.5v0c0,-0.27614 0.22386,-0.5 0.5,-0.5z"
                                fillRule="nonzero"
                                fill={theme.colors.primary}
                                fillOpacity="1"
                                style={{ 'mix-blend-mode': 'normal' }}
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g transform="matrix(0.7071067811865476,0.7071067811865475,-0.7071067811865475,0.7071067811865476,24.351799999999997,2.0624000000000002)">
                        <g
                          id="4901458"
                          opacity="1"
                          style={{ 'mix-blend-mode': 'normal' }}
                        >
                          <g>
                            <g>
                              <path
                                id="4901458_fill_path"
                                d="M1,0v0c0.55228,0 1,0.44772 1,1v4c0,0.55228 -0.44772,1 -1,1v0c-0.55228,0 -1,-0.44772 -1,-1v-4c0,-0.55228 0.44772,-1 1,-1z"
                                fillRule="nonzero"
                                fill={theme.colors.primary}
                                fillOpacity="1"
                                style={{ 'mix-blend-mode': 'normal' }}
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g transform="matrix(0.7071067811865476,0.7071067811865475,-0.7071067811865475,0.7071067811865476,1.4143000000000008,2)">
                        <g
                          id="4901459"
                          opacity="1"
                          style={{ 'mix-blend-mode': 'normal' }}
                        >
                          <g>
                            <g>
                              <path
                                id="4901459_fill_path"
                                d="M1,0h4c0.55228,0 1,0.44772 1,1v0c0,0.55228 -0.44772,1 -1,1h-4c-0.55228,0 -1,-0.44772 -1,-1v0c0,-0.55228 0.44772,-1 1,-1z"
                                fillRule="nonzero"
                                fill={theme.colors.primary}
                                fillOpacity="1"
                                style={{ 'mix-blend-mode': 'normal' }}
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g transform="matrix(0.7071067811865476,0.7071067811865475,-0.7071067811865475,0.7071067811865476,22.414299999999997,22)">
                        <g
                          id="4901461"
                          opacity="1"
                          style={{ 'mix-blend-mode': 'normal' }}
                        >
                          <g>
                            <g>
                              <path
                                id="4901461_fill_path"
                                d="M1,0h4c0.55228,0 1,0.44772 1,1v0c0,0.55228 -0.44772,1 -1,1h-4c-0.55228,0 -1,-0.44772 -1,-1v0c0,-0.55228 0.44772,-1 1,-1z"
                                fillRule="nonzero"
                                fill={theme.colors.primary}
                                fillOpacity="1"
                                style={{ 'mix-blend-mode': 'normal' }}
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g transform="matrix(1,0,0,1,16.9959,8)">
                <g
                  id="4901488"
                  opacity="0"
                  style={{ 'mix-blend-mode': 'normal' }}
                >
                  <g>
                    <g>
                      <g transform="matrix(1,0,0,1,27.0041,14)">
                        <g
                          id="4901481"
                          opacity="0"
                          style={{ 'mix-blend-mode': 'normal' }}
                        >
                          <g>
                            <g>
                              <path
                                id="4901481_fill_path"
                                d="M0,0h1v0v2v0h-1v0v-2z"
                                fillRule="nonzero"
                                fill={theme.colors.primary}
                                fillOpacity="1"
                                style={{ 'mix-blend-mode': 'normal' }}
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g transform="matrix(1,0,0,1,12.004100000000001,0)">
                        <g
                          id="4901483"
                          opacity="0"
                          style={{ 'mix-blend-mode': 'normal' }}
                        >
                          <g>
                            <g>
                              <path
                                id="4901483_fill_path"
                                d="M0,0h2v0v1v0h-2v0v-1z"
                                fillRule="nonzero"
                                fill={theme.colors.primary}
                                fillOpacity="1"
                                style={{ 'mix-blend-mode': 'normal' }}
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g transform="matrix(0.7071067811865476,0.7071067811865475,-0.7071067811865475,0.7071067811865476,24.593899999999998,2)">
                        <g
                          id="4901484"
                          opacity="1"
                          style={{ 'mix-blend-mode': 'normal' }}
                        >
                          <g>
                            <g>
                              <path
                                id="4901484_fill_path"
                                d="M0.4988,0h1.0024c0.27548,0 0.4988,0.22332 0.4988,0.4988v0c0,0.27548 -0.22332,0.4988 -0.4988,0.4988h-1.0024c-0.27548,0 -0.4988,-0.22332 -0.4988,-0.4988v0c0,-0.27548 0.22332,-0.4988 0.4988,-0.4988z"
                                fillRule="nonzero"
                                fill={theme.colors.primary}
                                fillOpacity="1"
                                style={{ 'mix-blend-mode': 'normal' }}
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g transform="matrix(0.7071067811865476,0.7071067811865475,-0.7071067811865475,0.7071067811865476,1.4143000000000008,2)">
                        <g
                          id="4901485"
                          opacity="1"
                          style={{ 'mix-blend-mode': 'normal' }}
                        >
                          <g>
                            <g>
                              <path
                                id="4901485_fill_path"
                                d="M0.50015,0v0c0.27623,0 0.50015,0.22392 0.50015,0.50015v0.9997c0,0.27623 -0.22392,0.50015 -0.50015,0.50015v0c-0.27623,0 -0.50015,-0.22392 -0.50015,-0.50015v-0.9997c0,-0.27623 0.22392,-0.50015 0.50015,-0.50015z"
                                fillRule="nonzero"
                                fill={theme.colors.primary}
                                fillOpacity="1"
                                style={{ 'mix-blend-mode': 'normal' }}
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g transform="matrix(0.7071067811865476,0.7071067811865475,-0.7071067811865475,0.7071067811865476,25.4182,25)">
                        <g
                          id="4901487"
                          opacity="1"
                          style={{ 'mix-blend-mode': 'normal' }}
                        >
                          <g>
                            <g>
                              <path
                                id="4901487_fill_path"
                                d="M0.5012,0v0c0.27681,0 0.5012,0.22439 0.5012,0.5012v0.9976c0,0.27681 -0.22439,0.5012 -0.5012,0.5012v0c-0.27681,0 -0.5012,-0.22439 -0.5012,-0.5012v-0.9976c0,-0.27681 0.22439,-0.5012 0.5012,-0.5012z"
                                fillRule="nonzero"
                                fill={theme.colors.primary}
                                fillOpacity="1"
                                style={{ 'mix-blend-mode': 'normal' }}
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g transform="matrix(1,0,0,1,44,22)">
                <g
                  id="4901507"
                  opacity="0"
                  style={{ 'mix-blend-mode': 'normal' }}
                >
                  <g>
                    <g>
                      <path
                        id="4901507_fill_path"
                        d="M0,0h1v0v2v0h-1v0v-2z"
                        fillRule="nonzero"
                        fill={theme.colors.primary}
                        fillOpacity="1"
                        style={{ 'mix-blend-mode': 'normal' }}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <g transform="matrix(1,0,0,1,29,8)">
                <g
                  id="4901509"
                  opacity="0"
                  style={{ 'mix-blend-mode': 'normal' }}
                >
                  <g>
                    <g>
                      <path
                        id="4901509_fill_path"
                        d="M0,0h2v0v1v0h-2v0v-1z"
                        fillRule="nonzero"
                        fill={theme.colors.primary}
                        fillOpacity="1"
                        style={{ 'mix-blend-mode': 'normal' }}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <g transform="matrix(0.7071067811865476,0.7071067811865475,-0.7071067811865475,0.7071067811865476,41.5898,10)">
                <g
                  id="4901510"
                  opacity="0"
                  style={{ 'mix-blend-mode': 'normal' }}
                >
                  <g>
                    <g>
                      <path
                        id="4901510_fill_path"
                        d="M0,0h2v0v0.9976v0h-2v0v-0.9976z"
                        fillRule="nonzero"
                        fill={theme.colors.primary}
                        fillOpacity="1"
                        style={{ 'mix-blend-mode': 'normal' }}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <g transform="matrix(0.7071067811865476,0.7071067811865475,-0.7071067811865475,0.7071067811865476,18.4102,10)">
                <g
                  id="4901511"
                  opacity="0"
                  style={{ 'mix-blend-mode': 'normal' }}
                >
                  <g>
                    <g>
                      <path
                        id="4901511_fill_path"
                        d="M0,0h1.0003v0v2v0h-1.0003v0v-2z"
                        fillRule="nonzero"
                        fill={theme.colors.primary}
                        fillOpacity="1"
                        style={{ 'mix-blend-mode': 'normal' }}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <g transform="matrix(0.7071067811865476,0.7071067811865475,-0.7071067811865475,0.7071067811865476,42.4141,33)">
                <g
                  id="4901513"
                  opacity="0"
                  style={{ 'mix-blend-mode': 'normal' }}
                >
                  <g>
                    <g>
                      <path
                        id="4901513_fill_path"
                        d="M0,0h1.0024v0v2v0h-1.0024v0v-2z"
                        fillRule="nonzero"
                        fill={theme.colors.primary}
                        fillOpacity="1"
                        style={{ 'mix-blend-mode': 'normal' }}
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <animateTransform
        href="#4901325"
        attributeName="transform"
        type="translate"
        values="0 0;0 0;0 -0.3455999999999999;0 -0.34210000000000007;0 0;0 0"
        dur="1.449s"
        repeatCount="indefinite"
        calcMode="spline"
        keyTimes="0;0.11;0.22;0.78;0.89;1"
        keySplines="0 0 0.15 1;0 0 0.58 1;0.5 0.35 0.15 1;0 0 0.58 1;0.5 0.35 0.58 1"
        additive="sum"
        fill="freeze"
      />
      <animateTransform
        href="#4901326"
        attributeName="transform"
        type="translate"
        values="0 0;0 0;1.9664000000000001 -1.4000999999999992;1.9659000000000013 -1.3973999999999993;-7.412699999999999 5.0389;-7.4140999999999995 5.0426;1.9664000000000001 -1.4000999999999992;1.9659000000000013 -1.3973999999999993;0 0"
        dur="1.449s"
        repeatCount="indefinite"
        calcMode="spline"
        keyTimes="0;0;0.11;0.11;0.22;0.78;0.89;0.89;1"
        keySplines="0 0 0.15 1;0 0 0.58 1;0.5 0.35 0.15 1;0 0 0.58 1;0.5 0.35 0.15 1;0 0 0.58 1;0.5 0.35 0.15 1;0 0 0.58 1"
        additive="sum"
        fill="freeze"
      />
      <animateTransform
        href="#4901326"
        attributeName="transform"
        type="rotate"
        values="0 0 0;0 0 0;19.8127 0 0;19.814 0 0;18.2493 0 0;18.254 0 0;19.8127 0 0;19.814 0 0;0 0 0"
        dur="1.449s"
        repeatCount="indefinite"
        calcMode="spline"
        keyTimes="0;0;0.11;0.11;0.22;0.78;0.89;0.89;1"
        keySplines="0 0 0.15 1;0 0 0.58 1;0.5 0.35 0.15 1;0 0 0.58 1;0.5 0.35 0.15 1;0 0 0.58 1;0.5 0.35 0.15 1;0 0 0.58 1"
        additive="sum"
        fill="freeze"
      />
      <animateTransform
        href="#4901327"
        attributeName="transform"
        type="translate"
        values="0 0;0 0;0.5483000000000011 1.8046000000000006;0.5471000000000004 1.8078000000000003;4.403700000000001 -7.182999999999998;4.4071 -7.182199999999998;0.5483000000000011 1.8046000000000006;0.5471000000000004 1.8078000000000003;0 0"
        dur="1.449s"
        repeatCount="indefinite"
        calcMode="spline"
        keyTimes="0;0;0.11;0.11;0.22;0.78;0.89;0.89;1"
        keySplines="0 0 0.15 1;0 0 0.58 1;0.5 0.35 0.15 1;0 0 0.58 1;0.5 0.35 0.15 1;0 0 0.58 1;0.5 0.35 0.15 1;0 0 0.58 1"
        additive="sum"
        fill="freeze"
      />
      <animateTransform
        href="#4901327"
        attributeName="transform"
        type="rotate"
        values="0 0 0;0 0 0;-16.6478 0 0;-16.6432 0 0;-18.382099999999998 0 0;-18.3832 0 0;-16.6478 0 0;-16.6432 0 0;0 0 0"
        dur="1.449s"
        repeatCount="indefinite"
        calcMode="spline"
        keyTimes="0;0;0.11;0.11;0.22;0.78;0.89;0.89;1"
        keySplines="0 0 0.15 1;0 0 0.58 1;0.5 0.35 0.15 1;0 0 0.58 1;0.5 0.35 0.15 1;0 0 0.58 1;0.5 0.35 0.15 1;0 0 0.58 1"
        additive="sum"
        fill="freeze"
      />
      <animate
        href="#4901330_fill_path"
        attributeName="d"
        values="M0,0h2v2h-2v-2z;M0,0h2v2h-2v-2z;M0,0h1.8203v2h-1.8203v-2z;M0,0h1.6391v2h-1.6391v-2z;M0,0h1.4749v2h-1.4749v-2z;M0,0h1.3289v2h-1.3289v-2z;M0,0h1.2033v2h-1.2033v-2z;M0,0h1.102v2h-1.102v-2z;M0,0h1.0311v2h-1.0311v-2z;M0,0h1.0003v2h-1.0003v-2z;M0,0h1v2h-1v-2z;M0,0h1v2h-1v-2z;M0,0h1.1218v2h-1.1218v-2z;M0,0h1.3084v2h-1.3084v-2z;M0,0h1.4777v2h-1.4777v-2z;M0,0h1.6293v2h-1.6293v-2z;M0,0h1.7614v2h-1.7614v-2z;M0,0h1.8704v2h-1.8704v-2z;M0,0h1.9513v2h-1.9513v-2z;M0,0h1.9955v2h-1.9955v-2z;M0,0h2v2h-2v-2z;M0,0h2v2h-2v-2z"
        dur="1.449s"
        repeatCount="indefinite"
        keyTimes="0;0.11;0.12;0.14;0.15;0.17;0.18;0.19;0.21;0.22;0.23;0.77;0.79;0.8;0.81;0.83;0.84;0.86;0.87;0.88;0.9;1"
        fill="freeze"
      />
      <animateTransform
        href="#4901330"
        attributeName="transform"
        type="translate"
        values="0 0;0 0;8.9619 -2.2424;8.9619 -2.2414999999999985;0 0;0 0"
        dur="1.449s"
        repeatCount="indefinite"
        calcMode="spline"
        keyTimes="0;0.11;0.22;0.78;0.89;1"
        keySplines="0 0 0.15 1;0 0 0.58 1;0.5 0.35 0.15 1;0 0 0.58 1;0.5 0.35 0.58 1"
        additive="sum"
        fill="freeze"
      />
      <animate
        href="#4901331_fill_path"
        attributeName="d"
        values="M0,0h2v2h-2v-2z;M0,0h2v2h-2v-2z;M0,0h2v1.8203h-2v-1.8203z;M0,0h2v1.6391h-2v-1.6391z;M0,0h2v1.4749h-2v-1.4749z;M0,0h2v1.3289h-2v-1.3289z;M0,0h2v1.2033h-2v-1.2033z;M0,0h2v1.102h-2v-1.102z;M0,0h2v1.0311h-2v-1.0311z;M0,0h2v1.0003h-2v-1.0003z;M0,0h2v1h-2v-1z;M0,0h2v1h-2v-1z;M0,0h2v1.1218h-2v-1.1218z;M0,0h2v1.3084h-2v-1.3084z;M0,0h2v1.4777h-2v-1.4777z;M0,0h2v1.6293h-2v-1.6293z;M0,0h2v1.7614h-2v-1.7614z;M0,0h2v1.8704h-2v-1.8704z;M0,0h2v1.9513h-2v-1.9513z;M0,0h2v1.9955h-2v-1.9955z;M0,0h2v2h-2v-2z;M0,0h2v2h-2v-2z"
        dur="1.449s"
        repeatCount="indefinite"
        keyTimes="0;0.11;0.12;0.14;0.15;0.17;0.18;0.19;0.21;0.22;0.23;0.77;0.79;0.8;0.81;0.83;0.84;0.86;0.87;0.88;0.9;1"
        fill="freeze"
      />
      <animateTransform
        href="#4901331"
        attributeName="transform"
        type="translate"
        values="0 0;0 0;17.9619 -16.2424;17.9619 -16.2415;12 -11.999999999999998;12.0019 -12.001499999999998;0 0"
        dur="1.449s"
        repeatCount="indefinite"
        calcMode="spline"
        keyTimes="0;0.11;0.22;0.78;0.89;0.89;1"
        keySplines="0 0 0.15 1;0 0 0.58 1;0.5 0.35 0.15 1;0 0 0.58 1;0.5 0.35 0.15 1;0 0 0.58 1"
        additive="sum"
        fill="freeze"
      />
      <animate
        href="#4901332_fill_path"
        attributeName="d"
        values="M0,0h2v2h-2v-2z;M0,0h2v2h-2v-2z;M0,0h2v1.8199h-2v-1.8199z;M0,0h2v1.6382h-2v-1.6382z;M0,0h2v1.4737h-2v-1.4737z;M0,0h2v1.3273h-2v-1.3273z;M0,0h2v1.2014h-2v-1.2014z;M0,0h2v1.0999h-2v-1.0999z;M0,0h2v1.0288h-2v-1.0288z;M0,0h2.9979h-2v-0.9979z;M0,0h2.9976h-2v-0.9976z;M0,0h2.9976h-2v-0.9976z;M0,0h2v1.1197h-2v-1.1197z;M0,0h2v1.3067h-2v-1.3067z;M0,0h2v1.4764h-2v-1.4764z;M0,0h2v1.6284h-2v-1.6284z;M0,0h2v1.7608h-2v-1.7608z;M0,0h2v1.8701h-2v-1.8701z;M0,0h2v1.9512h-2v-1.9512z;M0,0h2v1.9955h-2v-1.9955z;M0,0h2v2h-2v-2z;M0,0h2v2h-2v-2z"
        dur="1.449s"
        repeatCount="indefinite"
        keyTimes="0;0.11;0.12;0.14;0.15;0.17;0.18;0.19;0.21;0.22;0.23;0.77;0.79;0.8;0.81;0.83;0.84;0.86;0.87;0.88;0.9;1"
        fill="freeze"
      />
      <animateTransform
        href="#4901332"
        attributeName="transform"
        type="translate"
        values="0 0;0 0;18.551699999999997 -2.2424000000000017;18.551900000000003 -2.2415000000000003;9.414000000000001 2.9999999999999982;9.411900000000003 2.998499999999998;0 0"
        dur="1.449s"
        repeatCount="indefinite"
        calcMode="spline"
        keyTimes="0;0.11;0.22;0.78;0.89;0.89;1"
        keySplines="0 0 0.15 1;0 0 0.58 1;0.5 0.35 0.15 1;0 0 0.58 1;0.5 0.35 0.15 1;0 0 0.58 1"
        additive="sum"
        fill="freeze"
      />
      <animateTransform
        href="#4901332"
        attributeName="transform"
        type="rotate"
        values="0 0 0;0 0 0;45 0 0;45 0 0;0 0 0"
        dur="1.449s"
        repeatCount="indefinite"
        calcMode="spline"
        keyTimes="0;0.11;0.22;0.89;1"
        keySplines="0 0 0.15 1;0 0 0.58 1;0.5 0.35 0.15 1;0 0 0.58 1"
        additive="sum"
        fill="freeze"
      />
      <animate
        href="#4901333_fill_path"
        attributeName="d"
        values="M0,0h2v2h-2v-2z;M0,0h2v2h-2v-2z;M0,0h1.8204v2h-1.8204v-2z;M0,0h1.6392v2h-1.6392v-2z;M0,0h1.4751v2h-1.4751v-2z;M0,0h1.3291v2h-1.3291v-2z;M0,0h1.2036v2h-1.2036v-2z;M0,0h1.1023v2h-1.1023v-2z;M0,0h1.0314v2h-1.0314v-2z;M0,0h1.0006v2h-1.0006v-2z;M0,0h1.0003v2h-1.0003v-2z;M0,0h1.0003v2h-1.0003v-2z;M0,0h1.1221v2h-1.1221v-2z;M0,0h1.3086v2h-1.3086v-2z;M0,0h1.4778v2h-1.4778v-2z;M0,0h1.6294v2h-1.6294v-2z;M0,0h1.7614v2h-1.7614v-2z;M0,0h1.8705v2h-1.8705v-2z;M0,0h1.9513v2h-1.9513v-2z;M0,0h1.9955v2h-1.9955v-2z;M0,0h2v2h-2v-2z;M0,0h2v2h-2v-2z"
        dur="1.449s"
        repeatCount="indefinite"
        keyTimes="0;0.11;0.12;0.14;0.15;0.17;0.18;0.19;0.21;0.22;0.23;0.77;0.79;0.8;0.81;0.83;0.84;0.86;0.87;0.88;0.9;1"
        fill="freeze"
      />
      <animateTransform
        href="#4901333"
        attributeName="transform"
        type="translate"
        values="0 0;0 0;-14.041900000000002 -5.2424;-14.042100000000001 -5.2414999999999985;-17 0;-17.002100000000002 -0.0015000000000000568;0 0"
        dur="1.449s"
        repeatCount="indefinite"
        calcMode="spline"
        keyTimes="0;0.11;0.22;0.78;0.89;0.89;1"
        keySplines="0 0 0.15 1;0 0 0.58 1;0.5 0.35 0.15 1;0 0 0.58 1;0.5 0.35 0.15 1;0 0 0.58 1"
        additive="sum"
        fill="freeze"
      />
      <animateTransform
        href="#4901334"
        attributeName="transform"
        type="translate"
        values="0 0;0 0;-1.0000000000000018 17.000000000000004;-1.0021000000000022 16.998500000000003;0 0"
        dur="1.449s"
        repeatCount="indefinite"
        calcMode="spline"
        keyTimes="0;0.78;0.89;0.89;1"
        keySplines="0 0 0.15 1;0 0 0.58 1;0.5 0.35 0.15 1;0 0 0.58 1"
        additive="sum"
        fill="freeze"
      />
      <animate
        href="#4901377"
        attributeName="opacity"
        values="0;0;1;1;0;0"
        dur="1.449s"
        repeatCount="indefinite"
        calcMode="spline"
        keyTimes="0;0.11;0.22;0.22;0.33;1"
        keySplines="0 0 0.15 1;0 0 0.15 1;0.5 0.35 0.15 1;0 0 0.15 1;0.5 0.35 0.15 1"
        additive="sum"
        fill="freeze"
      />
      <animate
        href="#4901410"
        attributeName="opacity"
        values="0;0;1;1;0;0"
        dur="1.449s"
        repeatCount="indefinite"
        calcMode="spline"
        keyTimes="0;0.22;0.33;0.33;0.44;1"
        keySplines="0 0 0.15 1;0 0 0.15 1;0.5 0.35 0.15 1;0 0 0.15 1;0.5 0.35 0.15 1"
        additive="sum"
        fill="freeze"
      />
      <animate
        href="#4901436"
        attributeName="opacity"
        values="0;0;1;1;0;0"
        dur="1.449s"
        repeatCount="indefinite"
        calcMode="spline"
        keyTimes="0;0.33;0.44;0.44;0.55;1"
        keySplines="0 0 0.15 1;0 0 0.15 1;0.5 0.35 0.15 1;0 0 0.15 1;0.5 0.35 0.15 1"
        additive="sum"
        fill="freeze"
      />
      <animate
        href="#4901462"
        attributeName="opacity"
        values="0;0;1;1;0;0"
        dur="1.449s"
        repeatCount="indefinite"
        calcMode="spline"
        keyTimes="0;0.44;0.55;0.56;0.67;1"
        keySplines="0 0 0.15 1;0 0 0.15 1;0.5 0.35 0.15 1;0 0 0.15 1;0.5 0.35 0.15 1"
        additive="sum"
        fill="freeze"
      />
      <animate
        href="#4901488"
        attributeName="opacity"
        values="0;0;1;1;0;0"
        dur="1.449s"
        repeatCount="indefinite"
        calcMode="spline"
        keyTimes="0;0.56;0.67;0.67;0.78;1"
        keySplines="0 0 0.15 1;0 0 0.15 1;0.5 0.35 0.15 1;0 0 0.15 1;0.5 0.35 0.15 1"
        additive="sum"
        fill="freeze"
      />
      <animate
        href="#4901507"
        attributeName="opacity"
        values="0;0;1;1;0;0"
        dur="1.449s"
        repeatCount="indefinite"
        calcMode="spline"
        keyTimes="0;0.67;0.78;0.78;0.89;1"
        keySplines="0 0 0.15 1;0 0 0.15 1;0.5 0.35 0.15 1;0 0 0.15 1;0.5 0.35 0.15 1"
        additive="sum"
        fill="freeze"
      />
      <animate
        href="#4901509"
        attributeName="opacity"
        values="0;0;1;1;0;0"
        dur="1.449s"
        repeatCount="indefinite"
        calcMode="spline"
        keyTimes="0;0.67;0.78;0.78;0.89;1"
        keySplines="0 0 0.15 1;0 0 0.15 1;0.5 0.35 0.15 1;0 0 0.15 1;0.5 0.35 0.15 1"
        additive="sum"
        fill="freeze"
      />
      <animate
        href="#4901510"
        attributeName="opacity"
        values="0;0;1;1;0;0"
        dur="1.449s"
        repeatCount="indefinite"
        calcMode="spline"
        keyTimes="0;0.67;0.78;0.78;0.89;1"
        keySplines="0 0 0.15 1;0 0 0.15 1;0.5 0.35 0.15 1;0 0 0.15 1;0.5 0.35 0.15 1"
        additive="sum"
        fill="freeze"
      />
      <animate
        href="#4901511"
        attributeName="opacity"
        values="0;0;1;1;0;0"
        dur="1.449s"
        repeatCount="indefinite"
        calcMode="spline"
        keyTimes="0;0.67;0.78;0.78;0.89;1"
        keySplines="0 0 0.15 1;0 0 0.15 1;0.5 0.35 0.15 1;0 0 0.15 1;0.5 0.35 0.15 1"
        additive="sum"
        fill="freeze"
      />
      <animate
        href="#4901513"
        attributeName="opacity"
        values="0;0;1;1;0;0"
        dur="1.449s"
        repeatCount="indefinite"
        calcMode="spline"
        keyTimes="0;0.67;0.78;0.78;0.89;1"
        keySplines="0 0 0.15 1;0 0 0.15 1;0.5 0.35 0.15 1;0 0 0.15 1;0.5 0.35 0.15 1"
        additive="sum"
        fill="freeze"
      />
    </svg>
  );
};

LoaderTools.displayName = 'LoaderTools';

export default LoaderTools;
