import { groupBy, mapValues, size, uniqBy } from 'lodash';

import { PickupPoint } from '@savgroup-front-common/types';
import { Carrier } from 'myaccount/api/Claim/getClaimGroupCarrierQuery';

export const getPickupPoints = ({
  carriers,
  pickupPoints = [],
}: {
  carriers: Carrier[];
  pickupPoints?: PickupPoint[];
}) => {
  const allCarriers = carriers || [];
  const formattedPickupPoints = pickupPoints
    .map((pickupPoint) => {
      const carrier = allCarriers.find(
        (carrier) => carrier.carrierName === pickupPoint.carrierName,
      );

      return [
        {
          ...pickupPoint,
          ...carrier,
        },
      ];
    })
    .flat(1);

  return {
    pickupPoints: mapValues(
      groupBy(formattedPickupPoints, 'productType'),
      (group) =>
        mapValues(groupBy(group, 'carrierCompany'), (points) => ({
          isLoaded: size(points) > 0,
          points,
        })),
    ),
    numberOfPickupPointsLoaded: uniqBy(
      formattedPickupPoints,
      'carrierProductId',
    ).length,
  };
};
