import get from 'lodash/get';
import { v4 } from 'uuid';

export function onClaimGroupUploadPending(state, action) {
  const claimId = get(action, ['meta', 'claimId']);
  const name = get(action, ['meta', 'name']);
  const type = get(action, ['meta', 'type']);
  const size = get(action, ['meta', 'size']);

  if (!claimId) {
    return state;
  }

  return state.updateIn(['claimUploads', claimId], {}, (oldValue) => ({
    ...oldValue,
    hasErrors: false,
    errors: [],
    isDeleting: false,
    progress: 0,
    name,
    type,
    size,
    internalId: v4(),
  }));
}

export function onClaimGroupUploadErrored(state, action) {
  const { meta, errors } = action;
  const claimId = get(meta, ['claimId']);

  if (!claimId) {
    return state;
  }

  return state.updateIn(['claimUploads', claimId], {}, (oldValue) => ({
    ...oldValue,
    hasErrors: true,
    errors,
  }));
}

export function onClaimGroupUploadProgress(state, { payload, meta }) {
  const claimId = get(meta, ['claimId']);

  if (!claimId) {
    return state;
  }

  return state.updateIn(['claimUploads', claimId], {}, (oldValue) => ({
    ...oldValue,
    progress: payload,
  }));
}

export function onClaimGroupUploadSucceeded(state, { payload, meta }) {
  const claimId = get(meta, ['claimId']);
  const document = get(payload, 'value');

  if (!claimId) {
    return state;
  }

  return state.updateIn(['claimUploads', claimId], {}, (oldValue) => ({
    ...oldValue,
    ...document,
    progress: 100,
  }));
}

export function onClaimGroupUploadDeletionStarted(state, { meta }) {
  const claimId = get(meta, ['claimId']);

  if (!claimId) {
    return state;
  }

  return state.updateIn(['claimUploads', claimId], {}, (oldValue) => ({
    ...oldValue,
    isDeleting: true,
  }));
}

export function onClaimGroupUploadDeletionErrored(state, { meta }) {
  const claimId = get(meta, ['claimId']);

  if (!claimId) {
    return state;
  }

  return state.updateIn(['claimUploads', claimId], {}, (oldValue) => ({
    ...oldValue,
    isDeleting: false,
  }));
}

export function onClaimGroupUploadDeletionSucceeded(state, { meta }) {
  const claimId = get(meta, ['claimId']);

  if (!claimId) {
    return state;
  }

  return state.removeIn(['claimUploads', claimId]);
}
