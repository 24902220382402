import { createSelector } from 'reselect';

import { OWNER_DOMAIN_KEY } from './constants';
import { MyAccountRootState } from '../MyAccountRootState';

export const ownerState = (state: MyAccountRootState) =>
  state[OWNER_DOMAIN_KEY];

export const fullProducts = createSelector([ownerState], (state) =>
  state.get('fullProducts'),
);
