import { GetFileAdditionalInformationQueryResponse } from '@savgroup-front-common/core/src/api/Workflow/getFileAdditionalInformationQuery';

import { AdaptedNeededInformation } from '../AdditionalInformationExpect.types';
import { sortByClaimId } from '../helpers/sortByClaimId';

import adaptNeededFileAdditionalInformationByClaimIds from './adaptNeededFileAdditionalInformationByClaimIds';

const adaptNeededFileAdditionalInformation = ({
  neededFileAdditionalInformation = [],
  fileId,
  concernedClaimIds = [],
}: {
  neededFileAdditionalInformation?: GetFileAdditionalInformationQueryResponse;
  fileId?: string;
  concernedClaimIds?: string[];
}) => {
  const claimIds = neededFileAdditionalInformation.map(
    ({ claimId }) => claimId,
  );
  const cleanedNeededFileAdditionalInformation = neededFileAdditionalInformation
    .filter(({ claimId }) => {
      if (concernedClaimIds.length > 0) {
        return concernedClaimIds.includes(claimId);
      }

      return true;
    })
    .sort(sortByClaimId)
    .map(
      adaptNeededFileAdditionalInformationByClaimIds({
        claimIds,
        fileId,
      }),
    );

  return cleanedNeededFileAdditionalInformation.reduce<
    Record<string, AdaptedNeededInformation[]>
  >((acc, { claimId, neededInformation }) => {
    return {
      ...acc,
      [claimId]: neededInformation,
    };
  }, {});
};

export default adaptNeededFileAdditionalInformation;
