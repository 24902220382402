import { defineMessages } from 'react-intl';

export default defineMessages({
  owner: {
    id: 'global.riskCarrierType.owner',
    defaultMessage: 'Owner',
  },
  reseller: {
    id: 'global.riskCarrierType.reseller',
    defaultMessage: 'Reseller',
  },
  insurer: {
    id: 'global.riskCarrierType.insurer',
    defaultMessage: 'Insurer',
  },
  supplier: {
    id: 'global.recipientType.supplier',
    defaultMessage: 'Supplier',
  },
  manufacturer: {
    id: 'global.recipientType.manufacturer',
    defaultMessage: 'Manufacturer',
  },

  carrier: {
    id: 'global.recipientType.carrier',
    defaultMessage: 'Carrier',
  },
  fileActor: {
    id: 'global.recipientType.fileActor',
    defaultMessage: 'File actor',
  },
  purchasingDepartment: {
    id: 'global.recipientType.purchasingDepartment',
    defaultMessage: 'Purchasing department',
  },
  store: {
    id: 'global.recipientType.store',
    defaultMessage: 'Store',
  },
  site: {
    id: 'global.recipientType.site',
    defaultMessage: 'Site',
  },
  warehouse: {
    id: 'global.recipientType.warehouse',
    defaultMessage: 'Warehouse',
  },
  serviceCenter: {
    id: 'global.recipientType.serviceCenter',
    defaultMessage: 'Service Center',
  },
  undefined: {
    id: 'global.recipientType.undefined',
    defaultMessage: 'Undefined',
  },
});
