import { matchPath } from 'react-router-dom';
import { createSelector } from 'reselect';

import { pathname } from '@savgroup-front-common/core/src/domains/selectors';

import {
  ROUTES,
  BASE_ROUTES,
  ROUTES_NEW_DESIGN,
} from '../../view/app/routes/Routes';
import { ROUTES_PROFESSIONAL } from '../../view/app/routes/ProfessionalRoutes';

export const currentFileIdSelector = createSelector([pathname], (path) => {
  let matchUrl;

  if (path.startsWith(BASE_ROUTES.NEW_DESIGN)) {
    matchUrl = matchPath(path, {
      path: `${BASE_ROUTES.NEW_DESIGN}${ROUTES_NEW_DESIGN.FILE_FOLLOWUPS}`,
      exact: true,
      strict: false,
    });

    return matchUrl?.params?.fileId;
  }
  if (path.startsWith(BASE_ROUTES.PROFESSIONAL)) {
    matchUrl = matchPath(path, {
      path: ROUTES_PROFESSIONAL.FILE_FOLLOWUPS,
      exact: true,
      strict: false,
    });

    return matchUrl?.params?.fileId;
  }
  matchUrl = matchPath(path, {
    path: ROUTES.FILE_FOLLOWUPS,
    exact: true,
    strict: false,
  });

  return matchUrl?.params?.fileId;
});
