import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useReducer,
} from 'react';

import { Timeslot } from '@savgroup-front-common/types';

import { ErrorFromBack } from '../../../../helpers';
import createGenericContext from '../../../../helpers/createGenericContext';

import {
  interventionAppointmentInit,
  InterventionAppointmentState,
  takeInterventionAppointmentLegacyReducer,
} from './TakeInterventionAppointmentLegacy.reducer';
import { INTERVENTION_APPOINTMENT_ACTION_TYPES } from './TakeInterventionAppointmentLegacy.types';

export interface TakeInterventionAppointmentLegacyContextArgs
  extends InterventionAppointmentState {
  pageNumber: number;
  handleResetCalendar: () => void;
  handleSelectTimeSlot: (payload: { selectedTimeslot: Timeslot }) => void;
  handleValidateTimeSlot: () => void;
  handleSetErrors: (payload: { errors: ErrorFromBack[] | Error[] }) => void;
  handlePageChange: ({ newPageNumber }: { newPageNumber: number }) => void;
}

const [
  useTakeInterventionAppointmentLegacyContext,
  TakeInterventionAppointmentLegacyProvider,
] = createGenericContext<TakeInterventionAppointmentLegacyContextArgs>();

const TakeInterventionAppointmentLegacyContextProvider: FunctionComponent<
  PropsWithChildren<Record<never, never>>
> = ({ children }) => {
  const [state, dispatch] = useReducer(
    takeInterventionAppointmentLegacyReducer,
    undefined,
    interventionAppointmentInit,
  );

  const handleResetCalendar = useCallback(() => {
    dispatch({
      type: INTERVENTION_APPOINTMENT_ACTION_TYPES.RESET_CALENDAR,
    });
  }, []);
  const handleSelectTimeSlot = useCallback(
    (payload: { selectedTimeslot: Timeslot }) => {
      dispatch({
        type: INTERVENTION_APPOINTMENT_ACTION_TYPES.SELECT_TIMESLOT,
        payload,
      });
    },
    [],
  );
  const handleValidateTimeSlot = useCallback(() => {
    dispatch({
      type: INTERVENTION_APPOINTMENT_ACTION_TYPES.VALIDATE_TIMESLOT,
    });
  }, []);
  const handleSetErrors = useCallback(
    (payload: { errors: ErrorFromBack[] | Error[] }) => {
      dispatch({
        type: INTERVENTION_APPOINTMENT_ACTION_TYPES.SET_ERRORS,
        payload,
      });
    },
    [],
  );
  const handlePageChange = useCallback((payload: { newPageNumber: number }) => {
    dispatch({
      type: INTERVENTION_APPOINTMENT_ACTION_TYPES.PAGE_NUMBER_CHANGE,
      payload,
    });
  }, []);

  return (
    <TakeInterventionAppointmentLegacyProvider
      value={{
        ...state,
        handleResetCalendar,
        handleSelectTimeSlot,
        handleValidateTimeSlot,
        handleSetErrors,
        handlePageChange,
      }}
    >
      {children}
    </TakeInterventionAppointmentLegacyProvider>
  );
};

TakeInterventionAppointmentLegacyContextProvider.displayName =
  'TakeInterventionAppointmentLegacyContextProvider';

export {
  TakeInterventionAppointmentLegacyContextProvider,
  useTakeInterventionAppointmentLegacyContext,
};
