import { defineMessages } from 'react-intl';

export default defineMessages({
  subTotalPreVat: {
    id: 'view.quotationTab.subTotalPreVat.label',
    defaultMessage: 'Sub total HT',
  },
  subTotalIncludingVat: {
    id: 'view.quotationTab.subTotalIncludingVat.label',
    defaultMessage: 'Subtotal TTC',
  },
  discount: {
    id: 'view.quotationTab.discount.label',
    defaultMessage: 'Discount',
  },
  alreadyPaid: {
    id: 'view.quotationTab.alreadyPaid.label',
    defaultMessage: 'Already paid',
  },
  totalToBePaidByOwnerIncludingVat: {
    id: 'view.quotation.totalToBePaidByOwnerIncludingVat.label',
    defaultMessage: 'Total to be paid by owner',
  },
  bonus: {
    id: 'view.quotationTab.bonus.label',
    defaultMessage: 'Bonus',
  },
});
