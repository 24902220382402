import head from 'lodash/head';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { logVerbose } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { someActionIsLoadingSelector } from '@savgroup-front-common/core/src/domains/sagaRequestMetadata/selectors';
import { getStoresBySellerId } from '@savgroup-front-common/core/src/domains/store/actionCreators';
import { SAVE_EXPECTS } from '@savgroup-front-common/core/src/domains/workflow/actionTypes';
import { FileSummary, ModuleSummary } from '@savgroup-front-common/types';

import { submitWorkflowAction } from '../../../../../domains/Customer/actionCreators';
import {
  LOAD_CLAIM_CONFIRMATION_SCREEN_DATA,
  LOAD_CUSTOMER_FILE_SUMMARY,
  LOAD_FILE_STATE_HISTORY_LINES,
  LOAD_WORKFLOW_ACTIONS,
  SUBMIT_WORKFLOW_ACTION,
} from '../../../../../domains/Customer/actionTypes';
import { FileAction } from '../../../../../domains/Customer/Customer.types';
import { customerState } from '../../../../../domains/Customer/selectors';
import { useMyAccountTypedSelector } from '../../../../../hooks';

import adaptFirstAction from './ClientAction.adapters';
import { getTransitionWithExpect } from './ClientAction.helpers';

interface UseClientActionArgs {
  fileSummary: FileSummary;
  claimConfirmationData: {
    content1: string;
    content2: string;
    contentTitle: string;
    solutionName: string;
    warning: string;
  };
  workflowInfos: {
    file: {
      currentActions: FileAction[];
    };
    currentStatus?: {
      name: string;
      shortLabel: string;
      label: string;
    };
    currentModule?: ModuleSummary;
  };
  fileStatusName?: string;
  invoiceId: string;
  module?: ModuleSummary;
}

export const useClientAction = ({
  fileSummary,
  claimConfirmationData,
  workflowInfos,
  fileStatusName,
  invoiceId,
  module,
}: UseClientActionArgs) => {
  const dispatch = useDispatch();
  const customer = useMyAccountTypedSelector(customerState);

  const fileActions = customer?.availableFileActions || [];
  const currentActions = workflowInfos?.file?.currentActions;

  const { fileId } = fileSummary;

  const isSubmitWorkflowLoading = useMyAccountTypedSelector((store) =>
    someActionIsLoadingSelector(store, [
      { action: SUBMIT_WORKFLOW_ACTION, indexer: fileId },
      { action: LOAD_CUSTOMER_FILE_SUMMARY },
      { action: LOAD_WORKFLOW_ACTIONS },
      { action: LOAD_FILE_STATE_HISTORY_LINES },
      { action: LOAD_CLAIM_CONFIRMATION_SCREEN_DATA },
      { action: SAVE_EXPECTS },
    ]),
  );

  const content1 = claimConfirmationData?.content1;
  const warning = claimConfirmationData?.warning;

  const { firstTransitionWithExpect, otherTransitions } =
    getTransitionWithExpect(currentActions);

  useEffect(() => {
    dispatch(getStoresBySellerId({ sellerId: fileSummary.sellerId }));
  }, [dispatch, fileSummary.sellerId]);

  const handleSubmit = useCallback(
    ({
      module,
      fromState,
      toState,
      expectValues,
      comment = '',
      toCustomerComment = '',
    }: {
      module?: ModuleSummary;
      fromState?: string;
      toState?: string;
      toCustomerComment?: string;
      comment?: string;
      expectValues?: Record<string, any>;
    }) => {
      dispatch(
        submitWorkflowAction({
          module,
          fromState,
          toState,
          comment,
          expects: expectValues,
          toCustomerComment,
        }),
      );
    },
    [dispatch],
  );

  const toCustomerComment = head(fileActions)?.toCustomerComment;
  const targetStateName = firstTransitionWithExpect?.targetState.name;

  const firstAction = head(currentActions);
  const adaptedFirstAction = firstAction
    ? adaptFirstAction({
        action: firstAction,
        fileId,
        toCustomerComment,
      })
    : undefined;

  const currentStatusName = firstAction?.currentState.name;

  const handleInterventionAppointmentSubmit = useCallback(() => {
    handleSubmit({
      module,
      fromState: fileStatusName,
      toState: targetStateName,
    });
  }, [fileStatusName, targetStateName, module, handleSubmit]);
  const handleAwaitingPaymentSubmit = useCallback(() => {
    logVerbose(
      `handleAwaitingPaymentSubmit: FileId => ${fileId}, invoiceId => ${invoiceId}`,
      { fileId, invoiceId },
    );

    handleSubmit({
      module,
      fromState: fileStatusName,
      toState: targetStateName,
      expectValues: {
        Payment: {
          invoiceId,
        },
      },
    });
  }, [
    fileId,
    invoiceId,
    handleSubmit,
    module,
    fileStatusName,
    targetStateName,
  ]);

  return {
    firstAction,
    content1,
    isSubmitWorkflowLoading,
    firstTransitionWithExpect,
    otherTransitions,
    currentActions,
    warning,
    currentStatusName,
    fileActions,
    handleSubmit,
    adaptedFirstAction,
    targetStateName,
    handleAwaitingPaymentSubmit,
    handleInterventionAppointmentSubmit,
  };
};
