/* eslint-disable no-console */
import { fromJS } from 'immutable';
import filter from 'lodash/filter';
import get from 'lodash/get';
import reduce from 'lodash/reduce';

export function onHandlingForClaimGroupLoading(state, { meta }) {
  const claimGroupId = get(meta, ['claimGroupId']);

  if (!claimGroupId) {
    console.warn('[onHandlingForClaimGroupLoading] ClaimGroupId is undefined');

    return state;
  }

  return state.setIn(
    ['groupHandling', claimGroupId],
    fromJS({ isLoaded: false, isDirty: false, value: undefined }),
  );
}

export function onHandlingForClaimGroupLoadingErrored(state, { meta, errors }) {
  const claimGroupId = get(meta, ['claimGroupId']);

  if (!claimGroupId) {
    console.warn(
      '[onHandlingForClaimGroupLoadingErrored] ClaimGroupId is undefined',
    );

    return state;
  }

  // TODO errors
  return state.updateIn(['groupHandling', claimGroupId], (oldValue) =>
    oldValue
      .setIn(['isDirty'], true)
      .setIn(['hasErrors'], true)
      .setIn(['errors'], errors),
  );
}

export function onHandlingForClaimGroupLoaded(state, { payload, meta }) {
  const claimGroupId = get(meta, ['claimGroupId']);
  const claimGroup = get(payload, ['value']);

  if (!claimGroupId) {
    console.warn('[onHandlingForClaimGroupLoaded] ClaimGroupId is undefined.');

    return state;
  }

  return state.setIn(
    ['groupHandling', claimGroupId],
    fromJS({ isLoaded: true, isDirty: false }).setIn(['value'], claimGroup),
  );
}

export function onHandlingForClaimGroupSelected(state, { payload, meta }) {
  const claimGroupId = get(meta, ['claimGroupId']);
  const claimGroupState = get(payload, ['value']);

  if (!claimGroupId) {
    console.warn(
      '[onHandlingForClaimGroupSelected] ClaimGroupId is undefined.',
    );

    return state;
  }

  return state
    .updateIn(['claimGroupStates', claimGroupId, 'value'], (oldValue) => ({
      ...oldValue,
      state: claimGroupState,
    }))
    .setIn(['claimGroupStates', claimGroupId, 'errors'], null);
}

export function onHandlingForClaimGroupSelectError(state, { meta, errors }) {
  const claimGroupId = get(meta, ['claimGroupId']);

  if (!claimGroupId) {
    console.warn(
      '[onHandlingForClaimGroupSelectError] ClaimGroupId is undefined.',
    );

    return state;
  }
  const errorsMap = reduce(
    filter(errors, (error) => get(error, 'field')),
    (e, error) =>
      e.set(get(error, 'field').toLowerCase(), get(error, 'message')),
    fromJS({}),
  );

  return state.setIn(['claimGroupStates', claimGroupId, 'errors'], errorsMap);
}
