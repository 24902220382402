export enum EXPECTS {
  EXPECT_UNLOCK_CODE_REQUESTED = 'DevicePassword',
  EXPECT_AWAITING_PAYMENT = 'Payment',
  EXPECT_ISSUE_QUOTE = 'EditQuote',
  EXPECT_AWAITING_ACCOUNT_DESACTIVATION = 'UnlinkDevice',
  EXPECT_DOCUMENTS_NEEDED = 'DocumentsNeeded',
  EXPECT_TAKE_APPOINTMENT = 'TakeAppointment',
  EXPECT_TAKE_INTERVENTION_APPOINTMENT = 'TakeInterventionAppointment',
  EXPECT_FORCE_CLOSE_FILE = 'ForceCloseFile',
  EDIT_INTERVENTION_REPORT = 'EditInterventionReport',
  CARRIER_OUTSIDE_SYSTEM = 'CarrierOutsideSystem',
  SELLER_PROVIDED_CARRIER_LABEL_REQUIRED = 'SellerProvidedCarrierLabelRequired',
  SPARE_PART_REQUEST = 'SparePartRequest',
  COMMERCIAL_SOLUTION = 'Solcom',
  REFUND = 'Refund',
  AUTO_GENERATE_REFUND_BARCODES = 'Auto_GenerateRefundBarcodes',
  RDV_CONFORMITY = 'rdc_conformite',
  CONFORMITY_RECEPTION = 'ConformityReception',
  CHOOSE_ACTOR = 'ChooseActor',
  CHOOSE_ANY_ACTOR = 'ChooseAnyActor',
  QUOTATION_EXTERNAL = 'Quotation_External',
  QUOTATION_INTERNAL = 'Quotation_Internal',
  SELECT_WORK_TYPE_INTERVENTION = 'SelectWorkTypeIntervention',
  POSTPONE_FSL_SCHEDULE = 'PostponeFslSchedule',
  PRODUCT_ADEQUACY = 'ProductAdequacy',
  MARK_SPARE_PARTS_REQUEST_AS_DELIVERED = 'MarkSparePartRequestAsDelivered',
  ADDITIONAL_INFORMATION_NEEDED = 'AdditionalInformationNeeded',
  COLLECT_PAYMENT_AT_HOME = 'CollectPaymentAtHome',
  COLLECT_PAYMENT_AT_STORE = 'CollectPaymentAtStore',
  REJECT_WARRANTY = 'RejectWarranty',
  UNUSED_SPARE_PARTS = 'UnusedSpareParts',
  MAKE_MARKETING_OFFER = 'MakeMarketingOffer',
  MAKE_COMMERCIAL_GESTURE = 'MakeCommercialGesture',
  COLLECT_BANK_ACCOUNT_DETAILS = 'CollectBankAccountDetails',
  REJECT_SOLUTION = 'RejectSolution',
  ISSUE_A_QUOTATION = 'IssueAQuotation',
  OWNER_ADDRESS_CORRECTION = 'OwnerAddressCorrection',
  ANSWER_QUOTATION = 'AnswerQuotation',
  CREATE_VIDEO_CALL = 'CreateVideoCall',
}
