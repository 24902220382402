import { defineMessages } from 'react-intl';

export default defineMessages({
  cancel: {
    id: 'view.commentModal.cancel',
    defaultMessage: 'Cancel',
  },
  returnModalText1: {
    id: 'components.myaccount.claims.appointmentCard.returnModalText1',
    defaultMessage:
      'You are about to cancel the home pickup option. If there was any amount paid previously, no refund is possible. You will be though able to drop your package in a pickup points of your choice.',
  },
  returnModalText2: {
    id: 'components.myaccount.claims.appointmentCard.returnModalText2',
    defaultMessage: 'Do you want to proceed?',
  },
  submitButton: {
    id: 'components.myaccount.claims.appointmentCard.submitButton',
    defaultMessage: 'Confirm',
  },
});
