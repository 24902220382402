export type ActorTypes =
  | 'Repairer'
  | 'Seller'
  | 'Owner'
  | 'RiskCarrier'
  | 'Warehouse'
  | 'Technical'
  | 'Accountable'
  | 'Data'
  | 'Order'
  | 'Supplier'
  | 'Store'
  | 'PurchaseStore'
  | 'DepositStore'
  | 'ImplicitSupplier'
  | 'ImplicitStore'
  | 'Site'
  | 'DeliveryStore';

export enum ACTOR_TYPES {
  REPAIRER = 'Repairer',
  SELLER = 'Seller',
  OWNER = 'Owner',
  RISK_CARRIER = 'RiskCarrier',
  WAREHOUSE = 'Warehouse',
  TECHNICAL = 'Technical',
  ACCOUNTABLE = 'Accountable',
  DATA = 'Data',
  ORDER = 'Order',
  SUPPLIER = 'Supplier',
  STORE = 'Store',
  INSURER = 'Insurer',
  VAN = 'Van',
  PURCHASE_STORE = 'PurchaseStore',
  DEPOSIT_STORE = 'DepositStore',
  IMPLICIT_SUPPLIER = 'ImplicitSupplier',
  SITE = 'Site',
  DELIVERY_STORE = 'DeliveryStore',
}
