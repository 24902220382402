import React, { FunctionComponent } from 'react';

import { FileId } from '@savgroup-front-common/types';

import { TakeInterventionAppointmentLegacyContextProvider } from './TakeInterventionAppointmentLegacy.context';
import { AppointmentCalendarContainerLegacy } from './AppointmentCalendarContainerLegacy';

interface InterventionAppointmentContentProps {
  fileId: FileId;
  onSubmit: ({ toCustomerComment }: { toCustomerComment: string }) => void;
  isLoading?: boolean;
}

const TakeInterventionAppointmentLegacy: FunctionComponent<
  React.PropsWithChildren<InterventionAppointmentContentProps>
> = ({ children, fileId, onSubmit, isLoading = false }) => {
  return (
    <TakeInterventionAppointmentLegacyContextProvider>
      <AppointmentCalendarContainerLegacy
        fileId={fileId}
        onSubmit={onSubmit}
        isLoading={isLoading}
      >
        {children}
      </AppointmentCalendarContainerLegacy>
    </TakeInterventionAppointmentLegacyContextProvider>
  );
};

TakeInterventionAppointmentLegacy.displayName =
  'TakeInterventionAppointmentLegacy';

export default TakeInterventionAppointmentLegacy;
