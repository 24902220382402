import { defineMessages } from 'react-intl';

export default defineMessages({
  requestTitleResume: {
    id: 'components.myaccount.resumeCard.requestTitleResume',
    defaultMessage: 'Request',
  },
  depositTitle: {
    id: 'components.myaccount.claims.handling.cards.depositTitle',
    defaultMessage: 'Send product : ',
  },
  depositPickupPoint: {
    id: 'components.myaccount.claims.handling.cards.depositPickupPoint',
    defaultMessage: 'depositPickupPoint',
  },
  depositPickupStore: {
    id: 'components.myaccount.claims.handling.cards.depositPickupStore',
    defaultMessage: 'depositPickupStore',
  },
  depositHome: {
    id: 'components.myaccount.claims.handling.cards.depositHome',
    defaultMessage: 'depositHome',
  },
  depositExternal: {
    id: 'components.myaccount.claims.handling.cards.depositExternal',
    defaultMessage: 'depositExternal',
  },
  depositHomeIntervention: {
    id: 'components.myaccount.claims.handling.cards.depositHomeIntervention',
    defaultMessage: 'depositHomeIntervention',
  },
  depositImmediateDropAtStore: {
    id: 'components.myaccount.claims.handling.cards.depositImmediateDropAtStore',
    defaultMessage: 'depositImmediateDropAtStore',
  },

  deliveryTitle: {
    id: 'components.myaccount.claims.handling.cards.deliveryTitle',
    defaultMessage: 'Send product : ',
  },
  deliveryPickupPoint: {
    id: 'components.myaccount.claims.handling.cards.deliveryPickupPoint',
    defaultMessage: 'deliveryPickupPoint',
  },
  deliveryPickupStore: {
    id: 'components.myaccount.claims.handling.cards.deliveryPickupStore',
    defaultMessage: 'deliveryPickupStore',
  },
  deliveryHome: {
    id: 'components.myaccount.claims.handling.cards.deliveryHome',
    defaultMessage: 'Home delivery',
  },
  deliveryExternal: {
    id: 'components.myaccount.claims.handling.cards.deliveryExternal',
    defaultMessage: 'External delivery',
  },
  deliveryPickupStoreDelivery: {
    id: 'components.myaccount.claims.handling.cards.deliveryPickupStoreDelivery',
    defaultMessage: 'deliveryPickupStoreDelivery',
  },

  issueTitle: {
    id: 'view.createFile.selectIssueStep.issueTopTitleResume',
    defaultMessage: 'Issue: {issueName}',
  },
  reasonTitle: {
    id: 'view.createFile.selectReasonStep.reasonTopTitleResume',
    defaultMessage: 'Reason: {reasonName}',
  },
  solutionTitle: {
    id: 'view.createFile.selectSolutionStep.solutionTitleResume',
    defaultMessage: 'Solution: {solutionName}',
  },
});
