import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { BackResponseFailure } from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.claim}claimsGroups/${COMMON_QUERY_PARAMS.CLAIM_GROUP_ID}/invoices`;

interface CreateInvoiceQueryArgs {
  claimGroupId: string;
}

const createInvoiceQuery = async ({
  claimGroupId,
}: CreateInvoiceQueryArgs): Promise<BackResponseFailure> => {
  try {
    const url = ENDPOINT.replace(
      COMMON_QUERY_PARAMS.CLAIM_GROUP_ID,
      claimGroupId,
    );

    return await apiCall<BackResponseFailure>(url, {
      method: SUPPORTED_METHODS.PUT,
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default createInvoiceQuery;
