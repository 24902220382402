import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'components.selfcare.AdditionalInformationExpect.header',
    defaultMessage: 'Additional information needed',
  },
  subHeader: {
    id: 'components.selcare.AdditionalInformationExpect.subHeader',
    defaultMessage: 'Additional information needed description',
  },
  submit: {
    id: 'global.form.submit',
    defaultMessage: 'Submit',
  },
});
