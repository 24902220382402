import React from 'react';
import { UseFormReturn } from 'react-hook-form';

import { Collapse } from '@savgroup-front-common/core/src/atoms/Collapse';
import { AdditionalInformation } from '@savgroup-front-common/core/src/components/AdditionalInformationWithReactHookForm';
import { AdditionalInformationExtended } from 'myaccount/types';

interface ClaimGroupReasonPageAdditionalInformationsContentProps {
  formContext: UseFormReturn<any>;
  neededInformation: AdditionalInformationExtended[];
}

const ClaimGroupReasonPageAdditionalInformationsContent: React.FC<
  ClaimGroupReasonPageAdditionalInformationsContentProps
> = ({ formContext, neededInformation }) => {
  return (
    <Collapse isOpen={Boolean(neededInformation?.length)}>
      <AdditionalInformation
        neededInformation={neededInformation}
        formContext={formContext}
        prefixFormName="reasonAdditionalInformation"
        ignoreUniquenessByProduct
        fullSizeItem
        isScrollIntoView
        isNewDesign
        shouldAllowQrCode={false}
      />
    </Collapse>
  );
};

ClaimGroupReasonPageAdditionalInformationsContent.displayName =
  'ClaimGroupReasonPageAdditionalInformationsContent';

export default ClaimGroupReasonPageAdditionalInformationsContent;
