import { fromJS } from 'immutable';
import get from 'lodash/get';

import * as ActionTypes from './actionTypes';

const initialState = fromJS({});

function onUploadFileSuccess(state, { meta, payload }) {
  const value = get(payload, ['value']);
  const { indexer } = meta;

  return state.set(indexer, value);
}

function onDeleteFileSuccess(state, { meta }) {
  const { indexer } = meta;

  return state.set(indexer, null);
}

export default function mainReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.UPLOAD_FILE.SUCCEEDED:
      return onUploadFileSuccess(state, action);

    case ActionTypes.DELETE_FILE.SUCCEEDED:
      return onDeleteFileSuccess(state, action);

    default:
      return state;
  }
}
