import React, { ChangeEventHandler, FunctionComponent, Ref } from 'react';

import { FLEX_DIRECTION, MessageType } from '@savgroup-front-common/types';

import { SafeFormattedMessageWithoutSpread } from '../../../formatters';
import { Label } from '../common/Label/Label';

import {
  $Label,
  $LabelContainer,
  $StyledSwitch,
  $SwitchContainer,
  $SwitchInput,
} from './Switch.styles';

export interface SwitchProps {
  name?: string;
  value?: string;
  label?: MessageType | string;
  id?: string;
  isDisabled?: boolean;
  danger?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  dataTestId?: string;
  checked?: boolean;
  shouldStopClickPropagation?: boolean;
  alignLabel?: FLEX_DIRECTION;
  isSmall?: boolean;
}

interface SwitchPropsRef extends SwitchProps {
  forwardedRef: Ref<HTMLInputElement>;
}

const Switch: FunctionComponent<SwitchPropsRef> = (props) => {
  const {
    label,
    id,
    isDisabled = false,
    danger = false,
    forwardedRef,
    dataTestId = 'switch',
    onChange,
    checked,
    name,
    value,
    shouldStopClickPropagation = false,
    alignLabel,
    isSmall = false,
  } = props;

  return (
    <$Label
      disabled={isDisabled}
      danger={danger}
      alignLabel={alignLabel}
      $small={isSmall}
    >
      {label && (
        <$LabelContainer alignLabel={alignLabel}>
          <Label hasNoMarginBottom>
            <SafeFormattedMessageWithoutSpread message={label} />
          </Label>
        </$LabelContainer>
      )}
      <$SwitchContainer
        $small={isSmall}
        onClick={
          shouldStopClickPropagation
            ? (e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()
            : undefined
        }
      >
        <>
          <$SwitchInput
            {...props}
            value={value}
            onChange={onChange}
            checked={checked}
            id={id}
            disabled={isDisabled}
            ref={forwardedRef}
            data-testid={dataTestId}
            name={name}
            $small={isSmall}
          />
          <$StyledSwitch
            disabled={isDisabled}
            danger={danger}
            $small={isSmall}
          />
        </>
      </$SwitchContainer>
    </$Label>
  );
};

Switch.displayName = 'Switch';

export default React.forwardRef<HTMLInputElement, SwitchProps>((props, ref) => (
  <Switch forwardedRef={ref} {...props} />
));
