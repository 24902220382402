import { createContext } from 'react';
import { TransitionStatus } from 'react-transition-group/Transition';

export interface RouteTabsContextReturnValue {
  animationState: TransitionStatus;
  animationDuration: number;
}

const RouteTabsContext = createContext<RouteTabsContextReturnValue>({
  animationState: 'unmounted',
  animationDuration: 400,
});

export default RouteTabsContext;
