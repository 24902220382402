import fileTypeLib from 'file-type';

export function getFileType(input) {
  const fileType = fileTypeLib(input);

  if (fileType && fileType.ext === 'msi') {
    // see discussion here https://github.com/sindresorhus/file-type/issues/162
    return null;
  }

  return fileType;
}
