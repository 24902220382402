import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';
import { getCustomComponentProperty } from '@savgroup-front-common/core/src/helpers';
import {
  SUPPORTED_COMPONENTS,
  SUPPORTED_PROPERTIES,
} from '@savgroup-front-common/types';

export const $HomeContentWrapper = styled.div`
  background-color: white;
  border-radius: ${({ theme }) =>
    getCustomComponentProperty({
      theme,
      componentName: SUPPORTED_COMPONENTS.CARD,
      property: SUPPORTED_PROPERTIES.BORDER_RADIUS,
      fallback: theme.borders.radius,
    })};
  min-height: 70vh;
  padding: 1.8rem 3rem;
  gap: 1.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media ${media.maxWidth.xs} {
    padding: 1rem;
  }

  & [class*='TabContainer'] {
    padding: 0;
  }

  & [class*='RouteTabLink'] {
    padding: 1rem;
    padding-top: 0;
  }

  & [class*='TabViewList'] {
    width: fit-content;
    padding: 0;

    & a:-webkit-any-link {
      text-decoration: none;
    }

    & [class*='RouteTabLink'] {
      & [class*='MessageWithIcon'] {
        flex-direction: column;
        gap: 0.25rem;

        & > svg {
          width: 32px;
          height: 32px;
          margin: 0;
          fill: none;
        }
      }
      &:hover,
      &:focus {
        & [class*='MessageWithIcon'] > svg path[stroke] {
          stroke: ${({ theme }) => theme.colors.primary} !important;
        }
        & [class*='MessageWithIcon'] > svg path[fill] {
          fill: ${({ theme }) => theme.colors.primary} !important;
        }
      }
      &:focus {
        & [class*='MessageWithIcon'] > span {
          color: ${({ theme }) => theme.colors.primary};
        }
      }
    }
  }

  & [class*='TabViewNav'] {
    width: fit-content;
    padding: 0;
    margin: auto;
  }
`;

export const $HomeProductsListWrapper = styled.div`
  gap: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  & [class*='MonoProductInfostyles__Card'],
  &
    [class*='MonoProductInfostyles__Card']
    ~ [class*='MonoProductInfostyles__Card'] {
    margin-top: 0;
    margin-bottom: 0;
  }
`;
