import { defineMessages } from 'react-intl';

export default defineMessages({
  uploadFinish: {
    id: 'components.additionalInformation.qrCodeImport.uploadFinish',
    defaultMessage: 'Finish',
  },
  title: {
    id: 'components.additionalInformation.qrCodeImport.title',
    defaultMessage: 'Import QR code',
  },
  didactic: {
    id: 'components.additionalInformation.qrCodeImport.didactic',
    defaultMessage:
      'Scan the QR code to import the file with your device, once you have finished importing the documents you need to click on the finish button',
  },
});
