import { defineMessages } from 'react-intl';

export default defineMessages({
  searchingForTechnician: {
    id: 'appointmentCalendarLoader.searchingForTechnician',
    defaultMessage: 'We are searching for technician',
  },
  searchingAvailability: {
    id: 'appointmentCalendarLoader.searchingAvailability',
    defaultMessage: 'We are searching for availability',
  },
  synchronizeAgendas: {
    id: 'appointmentCalendarLoader.synchronizeAgendas',
    defaultMessage: 'Synchronizing agendas',
  },
  regroupingTimeslots: {
    id: 'appointmentCalendarLoader.regroupingTimeslots',
    defaultMessage: 'Regrouping timeslots',
  },
  optimizingTechnicianItinerary: {
    id: 'appointmentCalendarLoader.optimizingTechnicianItinerary',
    defaultMessage: 'Optimising technician itinerary',
  },
});
