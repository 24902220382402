import { filter, maxBy } from 'lodash';
import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'react-styled-flexboxgrid';
import { useTheme } from 'styled-components';

import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { intl } from '@savgroup-front-common/core/src/helpers';
import { InformationIcon } from '@savgroup-front-common/core/src/protons/icons';
import { CustomerFileSummary } from '@savgroup-front-common/types';
import { FileStateHistorySummary } from 'myaccount/api/Workflow/getFileStatesQuery';

import { useGetClaimContextHandlingQuery } from '../../hooks/useGetClaimContextHandlingQuery';
import { useGetClaimContextSummaryQuery } from '../../hooks/useGetClaimContextSummaryQuery';
import messages from '../../messages';
import { useClientAction } from '../ClientAction/ClientAction.hooks';
import { shouldDisplayConfirmedTechnicalInterventionSchedule } from '../ClientAction/ConfirmedTechnicalInterventionSchedule/ConfirmedTechnicalInterventionSchedule.helpers';
import useGetClientData from '../ClientAction/hooks/useGetClientData';

import { useClaimsSummary } from './ClaimsSummary.hooks';
import {
  $Body,
  $ClaimsSummaryWrapper,
  $CustomerCommentRow,
  $Heading,
  $IconPosition,
  $Spacing,
} from './ClaimsSummary.styles';
import ClaimsSummaryContent from './ClaimsSummaryContent';

interface ClaimsSummaryProp {
  fileInfo: CustomerFileSummary;
  fileStatus?: FileStateHistorySummary;
}

const NOT_TRANSLATED_LABEL = 'NOT TRANSLATED LABEL';

const ClaimsSummary: FunctionComponent<ClaimsSummaryProp> = ({
  fileInfo,
  fileStatus,
}) => {
  const { fileId } = useParams<{ fileId: string }>();
  const { claimId } = fileInfo.claimInfoSummary;
  const theme = useTheme();
  const {
    homePickupInfos,
    rmaSupplier,
    storeInfo,
    storeActor,
    trackingNumbers,
    partnerTransportDocumentUrl,
  } = useClaimsSummary({
    fileInfo,
  });
  const { claimContextSummary } = useGetClaimContextSummaryQuery({
    claimId,
  });
  const { claimContextHandling } = useGetClaimContextHandlingQuery({
    claimId,
  });

  const {
    action,
    workflowInfos,
    fileSummary,
    claimConfirmationData,
    module,
    fileStatusName,
    invoiceId,
  } = useGetClientData();
  const { firstTransitionWithExpect } = useClientAction({
    fileSummary,
    claimConfirmationData,
    workflowInfos,
    fileStatusName,
    invoiceId,
    module,
  });

  if (
    !fileId ||
    !claimContextSummary ||
    firstTransitionWithExpect?.expect ||
    shouldDisplayConfirmedTechnicalInterventionSchedule({
      action,
      workflowInfos,
    })
  ) {
    return (
      <>
        <$ClaimsSummaryWrapper>
          <$Heading>
            <SafeFormattedMessageWithoutSpread
              message={messages.claimSummaryDefaultMessage}
            />
          </$Heading>
          <$Body>
            <Row>
              <SafeFormattedMessageWithoutSpread
                message={messages.claimSummaryDateMessage}
                values={{
                  status: fileStatus?.state?.label,
                  date: intl?.formatDate(fileStatus?.stateDate, {
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric',
                  }),
                }}
              />
            </Row>
            {fileStatus?.toCustomerComment && (
              <$CustomerCommentRow>
                <$IconPosition>
                  <InformationIcon color={theme.colors.primary} />
                </$IconPosition>
                <SafeFormattedMessageWithoutSpread
                  message={messages.clientServiceCommentary}
                  values={{ value: fileStatus.toCustomerComment }}
                />
              </$CustomerCommentRow>
            )}
          </$Body>
        </$ClaimsSummaryWrapper>
        <$Spacing />
      </>
    );
  }

  const { content1, content2, contentTitle, solutionName } =
    claimContextSummary;
  const deposit = claimContextHandling?.deposit;
  const isTwoColumnsLayout = !!content2;

  if (!content1 && !content2) {
    return (
      <$ClaimsSummaryWrapper>
        <$Heading>
          <SafeFormattedMessageWithoutSpread
            message={messages.claimSummaryDefaultMessage}
          />
        </$Heading>
        <$Body>
          <Row>
            <SafeFormattedMessageWithoutSpread
              message={messages.claimSummaryDateMessage}
              values={{
                status: fileStatus?.state?.label,
                date: intl?.formatDate(fileStatus?.stateDate, {
                  day: 'numeric',
                  month: 'numeric',
                  year: 'numeric',
                }),
              }}
            />
          </Row>
          {fileStatus?.toCustomerComment && (
            <$CustomerCommentRow>
              <$IconPosition>
                <InformationIcon color={theme.colors.primary} />
              </$IconPosition>
              <SafeFormattedMessageWithoutSpread
                message={messages.clientServiceCommentary}
                values={{ value: fileStatus.toCustomerComment }}
              />
            </$CustomerCommentRow>
          )}
        </$Body>
      </$ClaimsSummaryWrapper>
    );
  }

  return (
    <>
      <$ClaimsSummaryWrapper>
        {contentTitle && contentTitle !== NOT_TRANSLATED_LABEL && (
          <$Heading>{contentTitle}</$Heading>
        )}

        <$Body>
          <Row>
            {!!content1 && (
              <Col
                xs={12}
                md={isTwoColumnsLayout ? 6 : 12}
                data-testid="followUpMessage"
              >
                <ClaimsSummaryContent
                  content={content1}
                  depositTrackingInfo={maxBy(
                    filter(trackingNumbers, { handlingDirection: 'FromOwner' }),
                    'wave',
                  )}
                  isLoading={false}
                  solutionName={solutionName}
                  storeAddress={deposit}
                  homePickupInfos={homePickupInfos}
                  rmaSupplier={rmaSupplier}
                  storeInfo={storeInfo}
                  storeActor={storeActor}
                  fileId={fileId}
                  claimId={claimId}
                  partnerTransportDocumentUrl={partnerTransportDocumentUrl}
                  fileSummary={fileInfo}
                />
              </Col>
            )}

            {!!content2 && (
              <Col xs={12} md={6}>
                <ClaimsSummaryContent
                  content={content2}
                  depositTrackingInfo={maxBy(
                    filter(trackingNumbers, {
                      handlingDirection: 'FromOwner',
                    }),
                    'wave',
                  )}
                  isLoading={false}
                  solutionName={solutionName}
                  storeAddress={deposit}
                  storeActor={storeActor}
                  homePickupInfos={homePickupInfos}
                  fileId={fileId}
                  claimId={claimId}
                  partnerTransportDocumentUrl={partnerTransportDocumentUrl}
                  fileSummary={fileInfo}
                />
              </Col>
            )}
          </Row>
        </$Body>
      </$ClaimsSummaryWrapper>
    </>
  );
};

ClaimsSummary.displayName = 'ClaimsSummary';
export default ClaimsSummary;
