import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared/supportedMethods';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { RequiredPayloadFieldIsNotProvided } from '@savgroup-front-common/exceptions/src/client/errors/RequiredPayloadFieldIsNotProvided';
import {
  BackResponse,
  BackResponseFailure,
  QuotationEnrichedDto,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.repairer}files/${COMMON_QUERY_PARAMS.FILE_ID}/quotation/pendingCustomerValidation`;

async function getPendingCustomerValidationByFileId({
  fileId,
}: {
  fileId: string;
}): Promise<BackResponse<QuotationEnrichedDto> | BackResponseFailure> {
  const functionName = 'getPendingCustomerValidationByFileId';

  if (!fileId) {
    throw new RequiredPayloadFieldIsNotProvided('fileId', functionName);
  }

  const url = buildUrl(ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId));

  try {
    return await apiCall(url, {
      method: SUPPORTED_METHODS.GET,
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default getPendingCustomerValidationByFileId;
