import React from 'react';

import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';

import useInitApp from './App.hooks';
import NewLayout from './NewLayout/NewLayout';

const AppRoutes: React.FC = () => {
  const { isLoading } = useInitApp();

  if (isLoading) {
    return <BaseLoader />;
  }

  return <NewLayout />;
};

AppRoutes.displayName = 'AppRoutes';

export default AppRoutes;
