import React, { useContext } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useTitle } from 'react-use';

import { NeedHelp } from '@savgroup-front-common/core/src/components/NeedHelp';
import { safeFormattedIntlString } from '@savgroup-front-common/core/src/formatters';
import { retryImportChunk } from '@savgroup-front-common/core/src/helpers/retryImportChunk';
import { AppErrorBoundary } from '@savgroup-front-common/core/src/molecules/ErrorBoundary';
import { AuthConfiguration } from 'myaccount/configuration';

import {
  BASE_ROUTES,
  HOME_ROUTES,
  ROUTES,
  ROUTES_NEW_DESIGN,
} from '../routes/Routes';

import { otherPageConfig, stepsConfig } from './ClassiqueRoutes.config';
import Header from './header/Header';
import { InitContext } from './InitProvider/InitProvider.context';
import messages from './messages';
import { $PageWrapper } from './NewLayout.styles';
import StepProvider from './StepsProvider/StepsProvider.context';
import StepWrapper from './StepWrapper/StepWrapper';

const ClientVisioPage = React.lazy(() =>
  retryImportChunk(() => import('../../pages/ClientVisioPage/ClientVisioPage')),
);

interface ClassiqueLayoutProps {
  sellerId: string;
}

const ClassiqueLayout: React.FC<ClassiqueLayoutProps> = ({ sellerId }) => {
  const { enableServicePortal } = useContext(InitContext);
  const location: any = useLocation();

  useTitle(safeFormattedIntlString(messages.selfcareTitle));

  return (
    <AppErrorBoundary>
      <$PageWrapper>
        <NeedHelp clientId={AuthConfiguration.clientId} />
        <Header sellerId={sellerId} enableServicePortal={enableServicePortal} />

        <Switch location={location.location}>
          <Route
            exact
            path={`${BASE_ROUTES.NEW_DESIGN}${ROUTES_NEW_DESIGN.VIDEO_CALL}`}
          >
            <ClientVisioPage />
          </Route>

          {enableServicePortal && (
            <Redirect
              from={ROUTES.PRODUCTS}
              to={`${BASE_ROUTES.NEW_DESIGN}${BASE_ROUTES.HOME}`}
              push
            />
          )}
          {otherPageConfig.map((page) => (
            <Route
              key={page.name}
              exact
              path={page.path}
              component={page.component}
            />
          ))}
          <Route path={BASE_ROUTES.NEW_DESIGN}>
            <StepProvider>
              {stepsConfig.map((page) => {
                return (
                  <Route
                    key={page.name}
                    exact
                    path={page.path}
                    render={() => (
                      <StepWrapper enableServicePortal={enableServicePortal}>
                        <page.component />
                      </StepWrapper>
                    )}
                  />
                );
              })}
            </StepProvider>
          </Route>

          <Redirect
            from={ROUTES.HOME}
            to={
              enableServicePortal
                ? HOME_ROUTES.PRODUCTS
                : `${BASE_ROUTES.NEW_DESIGN}${ROUTES.PRODUCTS}`
            }
            push
          />
          <Redirect
            to={
              enableServicePortal
                ? HOME_ROUTES.PRODUCTS
                : `${BASE_ROUTES.NEW_DESIGN}${ROUTES.PRODUCTS}`
            }
          />
        </Switch>
      </$PageWrapper>
    </AppErrorBoundary>
  );
};

export default ClassiqueLayout;
