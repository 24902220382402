import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  AssemblingModel,
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.catalog}models/bySearchModel`;

interface GetRepairableByModelIdPayload {
  maxResult: number;
  searchTerms: string;
  sellerId: string;
}

async function getModelsBySearchModelQuery({
  maxResult,
  searchTerms,
  sellerId,
}: GetRepairableByModelIdPayload): Promise<
  BackResponse<AssemblingModel[]> | BackResponseFailure
> {
  try {
    const response = await apiCall<BackResponse<AssemblingModel[]>>(
      buildUrl(ENDPOINT, { maxResult, searchTerms, sellerId }),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default getModelsBySearchModelQuery;
