import { NavArrowRight } from 'iconoir-react';
import React, { FunctionComponent } from 'react';

import { IconPropsInterface } from '../Icon.types';

export const ArrowRightIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => <NavArrowRight width={size} height={size} color={color} />;

ArrowRightIcon.displayName = 'ArrowRightIcon';
