import { RefObject, useReducer } from 'react';

import { getAnimationDirectionClassNames } from '../../animations/helper';
import { useCloseWhenClickOutside } from '../../hooks';

import { transformMenu } from './Menu.helpers';
import { MenuInit, MenuReducer } from './Menu.reducers';
import {
  MENU_ACTION_TYPES,
  MENU_ITEM_TYPES,
  MenuItem,
  MenuItemEnhanced,
  SubMenuEnhancedProps,
} from './Menu.types';

interface UseMenuArgs {
  onClose: () => void;
  menuItems: MenuItem[];
  wrapperRef?: RefObject<unknown>;
  wrapperRefs?: RefObject<unknown>[];
}
interface UseMenuReturnValue {
  classNames: string;
  path: number[];
  list: MenuItemEnhanced[];
  disabledAnimation: boolean;
}

const useMenu = ({
  onClose,
  menuItems,
  wrapperRef,
  wrapperRefs,
}: UseMenuArgs): UseMenuReturnValue => {
  const [{ path, animationType, disabledAnimation }, dispatch] = useReducer(
    MenuReducer,
    {},
    MenuInit,
  );

  useCloseWhenClickOutside({
    refs: wrapperRefs || ([wrapperRef] as RefObject<any>[]),
    onClose: () => {
      onClose();
      dispatch({ type: MENU_ACTION_TYPES.RESET_PATH });
    },
  });

  const list = path.reduce((acc, p) => {
    if (acc[p].type === MENU_ITEM_TYPES.MENU) {
      return (acc[p] as SubMenuEnhancedProps).items;
    }

    return acc;
  }, transformMenu(dispatch, menuItems));

  const classNames = getAnimationDirectionClassNames(animationType);

  return { classNames, list, path, disabledAnimation };
};

export default useMenu;
