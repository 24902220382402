import styled from 'styled-components';

import { ButtonLink } from '@savgroup-front-common/core/src/atoms/button';

export const $Heading = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  font-size: 20px;
  line-height: 23px;

  color: ${({ theme }) => theme.colors.darkBackground};
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
`;

export const $PaymentLabel = styled.div`
  color: ${({ theme }) => theme.colors.paragraphTextColor};
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
`;

export const $ButtonLink = styled(ButtonLink)`
  width: auto;
  text-decoration: unset;
  margin-top: 22px;
`;
