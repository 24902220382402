import { Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { Checkbox } from '@savgroup-front-common/core/src/atoms/checkbox';
import { H2 } from '@savgroup-front-common/core/src/atoms/headings';

export const UnlinkTitleH2 = styled(H2)<{ heading: boolean }>`
  padding-top: 0;
  padding-bottom: 0;
  font-size: 20px;
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
  line-height: 28px;
`;

export const CheckBoxPlacement = styled(Checkbox)<{
  label: string;
  name: string;
}>`
  margin: 30px 0 30px auto;
  color: ${({ theme }) => theme.colors.headingTextColor};
`;

export const RowButtonContainer = styled(Row)`
  margin-top: 16px;
`;

export const UnlinkWrapper = styled(Row)`
  margin-bottom: 16px;
  align-items: center;
`;
