import { Col } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

export const $Col = styled(Col)`
  [class*='RadioCardContainer'] {
    border-radius: 8px;
  }
`;

export const $PlaceholderPosition = styled.div`
  p {
    margin: 0;
    padding: 2px;
  }
`;
