export const combineReducers =
  (reducers: Record<string, any> = {}) =>
  (state: any = {}, action: any) => {
    const newState = state;

    for (const [key, reducer] of Object.entries(reducers)) {
      newState[key] = reducer(state?.[key], action);
    }

    return newState;
  };
