import { API_COMMON_ERROR } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { apiCall } from '../../services';
import {
  BackResponseFailure,
  BaseBackResponse,
} from '@savgroup-front-common/types';

import { prepareResponseFailure } from '../../helpers';

export interface DeleteAttachmentArgs {
  endpoint: string;
}

async function deleteAttachment({
  endpoint,
}: DeleteAttachmentArgs): Promise<BaseBackResponse | BackResponseFailure> {
  try {
    const response = await apiCall<BaseBackResponse>(endpoint, {
      method: SUPPORTED_METHODS.DELETE,
    });

    if (response.statusCode === 401) {
      return prepareResponseFailure({
        errors: [{ code: API_COMMON_ERROR.UNHANDLED }],
      });
    }

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default deleteAttachment;
