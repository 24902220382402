import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import {
  BackResponse,
  BackResponseFailure,
  QuotationNewModelDto,
} from '@savgroup-front-common/types';

import { prepareResponseFailure } from '../../helpers';
import { apiCall } from '../../services';

const ENDPOINT = `${APIConfiguration.repairer}files/${COMMON_QUERY_PARAMS.FILE_ID}/quotations`;

export const getQuotationsByFileIdQuery = async ({
  fileId,
}: {
  fileId: string;
}): Promise<BackResponseFailure | BackResponse<QuotationNewModelDto[]>> => {
  try {
    return await apiCall<BackResponse<QuotationNewModelDto[]>>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};
