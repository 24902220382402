import { rem, transparentize } from 'polished';
import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';

export const $StepProgressWrapper = styled.div<{
  $isFluid: boolean;
  $withoutMarginBottom: boolean;
}>`
  display: flex;
  align-items: center;
  margin-bottom: ${({ $withoutMarginBottom }) =>
    $withoutMarginBottom ? undefined : '2.5rem'};
  justify-content: center;
  width: ${({ $isFluid }) => ($isFluid ? '100%' : undefined)};

  @media ${media.minWidth.sm} {
    margin-bottom: ${({ $withoutMarginBottom }) =>
      $withoutMarginBottom ? undefined : rem(88)};
  }
`;

export const $StepProgressItem = styled.div`
  display: flex;
  flex-direction: column;
  width: ${rem(80)};
  justify-content: center;
  align-items: center;
`;

export const $StepProgressCircle = styled.div<{ $active?: boolean }>`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: ${({ theme, $active = false }) =>
    $active
      ? theme.colors.primary
      : transparentize(0.95, theme.colors.paragraphTextColor)};
  color: ${({ theme, $active }) =>
    $active ? theme.colors.white : theme.colors.paragraphTextColor};
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const $StepProgressLabel = styled.div<{ $active?: boolean }>`
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  font-size: ${rem(12)};
  line-height: ${rem(20)};
  margin-top: ${rem(9)};
  color: ${({ theme, $active = false }) =>
    $active ? theme.colors.primary : theme.colors.paragraphTextColor};
`;

export const $StepProgressSeparator = styled.div<{ $active?: boolean }>`
  display: flex;
  flex: 1;
  height: 2rem;
  min-width: 50px;
  align-self: flex-start;
  align-items: center;
  &:after {
    content: '';
    height: 2px;
    width: 100%;
    background: ${({ theme, $active = false }) =>
      $active
        ? theme.colors.primary
        : transparentize(0.95, theme.colors.paragraphTextColor)};
    border-radius: 999px;
  }

  &:last-child {
    display: none;
  }
`;
