import React, { useContext, useState } from 'react';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { useHistory, useParams } from 'react-router-dom';

import { useGetSolutionsByClaim } from '../../../app/hooks/useGetSolutionsByClaim';

import { ClaimGroupSolution } from './ClaimGroupSolution/ClaimGroupSolution';
import { useGetClaimGroupConfirmation } from '../../../app/hooks/useGetClaimGroupConfirmation';
import { ProfessionalPagesContextProvider } from '../ProfessionalPages.context';
import { buildProfessionalRoute } from 'myaccount/view/app/NewLayout/ProfessionalRoutes.helpers';
import { PROFESSIONAL_STEP_CONFIG } from 'myaccount/view/app/NewLayout/ProfessionalRoutes.config';
import { ClaimService } from '../../../../api';
import { CURRENCIES } from '@savgroup-front-common/constants';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';

const ClaimGroupSolutionPage: React.FC = () => {
  const { pushErrors } = useToasts();
  const history = useHistory();
  const { claimGroupId } = useParams<{ claimGroupId: string }>();

  const { skippedSteps, onSkipStep } = useContext(
    ProfessionalPagesContextProvider,
  );
  const isAlreadySkipped =
    skippedSteps[PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_SOLUTION_PAGE];

  const [isSkipLoading, setIsSkipLoading] = useState(false);

  const { solutions } = useGetSolutionsByClaim({
    claimGroupId,
    onSuccess: async (response) => {
      if (response && response.solutions.length === 1 && !isAlreadySkipped) {
        setIsSkipLoading(true);

        const solution = response.solutions[0];

        const responseSetSolution =
          await ClaimService.setClaimGroupSolutionCommand({
            claimGroupId,
            solutionTypeId: solution?.solutionTypeId,
            solutionPrice: solution?.price
              ? {
                  amount: solution.price,
                  currency: solution?.priceCurrencyCode as CURRENCIES,
                }
              : undefined,
          });

        if (responseSetSolution.failure) {
          pushErrors(responseSetSolution?.errors);

          setIsSkipLoading(false);

          return undefined;
        }

        onSkipStep(PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_SOLUTION_PAGE);

        history.push(
          buildProfessionalRoute({
            route: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_DEPOSIT_PAGE,
            payload: { claimGroupId },
          }),
        );
      }

      return undefined;
    },
  });
  const { isLoading } = useGetClaimGroupConfirmation({
    claimGroupId,
  });

  if (!solutions || isLoading || isSkipLoading) {
    return <BaseLoader />;
  }

  return <ClaimGroupSolution />;
};

ClaimGroupSolutionPage.displayName = 'ClaimGroupSolutionPage';

export default ClaimGroupSolutionPage;
