import styled from 'styled-components';

const $EmptyPlaceholder = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.paragraphTextColor};
  svg {
    margin-bottom: -30px;
  }
`;

export default $EmptyPlaceholder;
