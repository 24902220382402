import { fromJS } from 'immutable';
import get from 'lodash/get';

import * as ActionTypes from '../actionTypes';

function onloadOwnerProductFilesLoading(state, { meta }) {
  const ownerProductIds = get(meta, 'ownerProductIds');

  let newState = state;

  ownerProductIds.forEach((ownerProductId) => {
    newState = newState.update(
      ownerProductId,
      fromJS({ isLoaded: false, value: undefined, hasErrors: false }),
      (oldValue) => oldValue.set('hasErrors', true),
    );
  });

  return newState;
}

function onloadOwnerProductFilesErrored(state, { meta }) {
  const ownerProductIds = get(meta, 'ownerProductIds');
  let newState = state;

  ownerProductIds.forEach((ownerProductId) => {
    newState = newState.update(ownerProductId, (oldValue) =>
      oldValue.set('hasErrors', true),
    );
  });

  return newState;
}

function onloadOwnerProductFilesLoaded(state, { payload, meta }) {
  const ownerProductIds = get(meta, 'ownerProductIds');
  const value = get(payload, 'value');
  let newState = state;

  ownerProductIds.map((ownerProductId) => {
    newState = newState.update(ownerProductId, (oldValue) =>
      oldValue.set('isLoaded', true).set('value', value[ownerProductId]),
    );

    return undefined;
  });

  return newState;
}

export default function reducer(state = fromJS({}), action) {
  switch (action.type) {
    case ActionTypes.LOAD_OWNER_PRODUCT_FILES.STARTED: {
      return onloadOwnerProductFilesLoading(state, action);
    }

    case ActionTypes.LOAD_OWNER_PRODUCT_FILES.SUCCEEDED: {
      return onloadOwnerProductFilesLoaded(state, action);
    }

    case ActionTypes.LOAD_OWNER_PRODUCT_FILES.ERRORED: {
      return onloadOwnerProductFilesErrored(state, action);
    }

    default: {
      return state;
    }
  }
}
