import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { FormattedDate } from 'react-intl';

import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { FileShortSummary, Product } from '@savgroup-front-common/types';

import {
  $ProductBillingNumber,
  $ProductContainer,
  $ProductHeader,
} from './ChooseProductPage.styles';
import { ChoiceProductValues } from './ChooseProductPage.types';
import useChooseProductContent from './ChooseProductPageContent.hooks';
import ChooseProductCard from './components/ChooseProductCard/ChooseProductCard';
import messages from './messages';

interface ChooseProductPageContentProps {
  orderId: string;
  openedFiles: FileShortSummary[];
  issue?: {
    id: string;
    winningWarrantyTypeId: string;
    name: string;
    didactic: string;
    fromProductWarranty: boolean;
    displayOrder: number;
    ownerProductIds?: string[];
    sellerProductIds?: string[];
    uniqueName: string;
    key: string;
    iconKey: string;
  };
  formContext: UseFormReturn<ChoiceProductValues>;
}

const ChooseProductPageContent: React.FC<ChooseProductPageContentProps> = ({
  orderId,
  openedFiles,
  issue,
  formContext,
}) => {
  const {
    orderDetail,
    isLoading,
    eligibleProducts,
    notEligibleProducts,
    notEligibleProductsFollowUpClaim,
    isGenericOrder,
  } = useChooseProductContent({ orderId, issue });

  if (!orderDetail || isLoading) {
    return <></>;
  }

  return (
    <$ProductContainer>
      <$ProductHeader>
        {isGenericOrder ? (
          <SafeFormattedMessageWithoutSpread
            message={messages.isGenericHeader}
          />
        ) : (
          <>
            <$ProductBillingNumber>
              <SafeFormattedMessageWithoutSpread
                message={messages.billingLabel}
                values={{
                  value: orderDetail.orderReference,
                }}
              />{' '}
              <SafeFormattedMessageWithoutSpread
                message={messages.additionalDateText}
              />{' '}
              <FormattedDate value={orderDetail.purchaseDate} />
            </$ProductBillingNumber>
            <SafeFormattedMessageWithoutSpread
              message={messages.numberItems}
              values={{
                value: orderDetail.productsCount,
              }}
            />
          </>
        )}
      </$ProductHeader>
      {eligibleProducts.map((product: Product, index) => {
        return (
          <ChooseProductCard
            key={product.ownerProductId}
            openedFiles={openedFiles}
            product={product}
            formContext={formContext}
            dataTestId={`eligible-product-${index}`}
          />
        );
      })}
      {notEligibleProductsFollowUpClaim.map((product: Product, index) => {
        return (
          <ChooseProductCard
            key={product.ownerProductId}
            openedFiles={openedFiles}
            product={product}
            formContext={formContext}
            isDisabled
            isInProgress
            notEligible
            dataTestId={`not-eligible-followup-claim-product-${index}`}
          />
        );
      })}
      {notEligibleProducts.map((product: Product, index) => {
        return (
          <ChooseProductCard
            key={product.ownerProductId}
            openedFiles={openedFiles}
            product={product}
            formContext={formContext}
            isDisabled
            notEligible
            dataTestId={`not-eligible-product-${index}`}
          />
        );
      })}
    </$ProductContainer>
  );
};

ChooseProductPageContent.displayName = 'ChooseProductPageContent';

export default ChooseProductPageContent;
