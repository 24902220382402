import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'components.myaccount.myReturn.returnProductForm.header',
    defaultMessage: 'Please choose one of the following options',
  },
  warning: {
    id: 'components.myaccount.myReturn.returnProductForm.warning',
    defaultMessage: 'This option is subject to additional transport fees.',
  },
  noOptions: {
    id: 'components.myaccount.myReturn.returnProductForm.noOptions',
    defaultMessage: 'No options availables',
  },
});
