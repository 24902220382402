import { createSelector } from 'reselect';

import { CLAIMS_DOMAIN, CLAIMS_DOMAIN_KEY } from '../constants';

const claimsState = (state) => state[CLAIMS_DOMAIN_KEY];

export const selectStoreActorOptions = createSelector(
  [claimsState, (state, { claimIds }) => claimIds],
  (state, claimIds) =>
    claimIds
      .map((claimId) =>
        state.getIn([CLAIMS_DOMAIN.STORES_ACTOR, claimId], null),
      )
      .filter((v) => v),
);
