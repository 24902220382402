import React from 'react';
import { AuthConfiguration } from 'myaccount/configuration';
import { useParams } from 'react-router-dom';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { NeedHelp } from '@savgroup-front-common/core/src/components/NeedHelp';
import BanneredLayout from 'myaccount/view/app/NewLayout/BanneredLayout/BanneredLayout';
import messages from './messages';
import {
  $LayoutGrid,
  $Card,
  $CardContent,
  $LayoutGridDefault,
} from './StepWrapper.styles';

import useStepWrapper from './StepWrapper.hooks';
import { safeFormattedIntlString } from '@savgroup-front-common/core/src/formatters';

interface StepWrapperProps {
  enableServicePortal?: boolean;
  isBtoBPortalEnabled?: boolean;
}

const StepWrapper: React.FC<React.PropsWithChildren<StepWrapperProps>> = ({
  enableServicePortal,
  isBtoBPortalEnabled,
  children,
}) => {
  const { claimGroupId, issueId } = useParams<{
    claimGroupId: string;
    issueId: string;
  }>();

  const { isLoading, name } = useStepWrapper({
    claimGroupId,
    issueId,
  });

  if (isLoading) {
    return <BaseLoader />;
  }

  if (enableServicePortal) {
    return (
      <>
        <NeedHelp clientId={AuthConfiguration.clientId} />
        <BanneredLayout isHomePage>
          <BanneredLayout.BannerTitle
            title={
              isBtoBPortalEnabled
                ? undefined
                : safeFormattedIntlString(messages.bannerTitle, {
                    reference: name,
                  })
            }
            description={
              isBtoBPortalEnabled ? undefined : messages.bannerDescription
            }
          />
          <BanneredLayout.Content hasBannerUrl>
            <$Card>
              <$CardContent>
                <$LayoutGrid fluid>{children}</$LayoutGrid>
              </$CardContent>
            </$Card>
          </BanneredLayout.Content>
        </BanneredLayout>
      </>
    );
  }

  return <$LayoutGridDefault>{children}</$LayoutGridDefault>;
};

StepWrapper.displayName = 'StepWrapper';

export default StepWrapper;
