import { useContext } from 'react';

import { AdditionalInformation } from 'myaccount/api/Claim/getAdditionalInformationEntitySummaryQuery';
import {
  AdditionalClaimInformation,
  ClaimGroupConfirmation,
} from 'myaccount/api/Claim/getClaimGroupConfirmationQuery';
import { useGetMultiAdditionalInformationEntitySummaryQuery } from 'myaccount/view/app/hooks/useGetMultiAdditionalInformationEntitySummaryQuery';
import { useGetOrderByOwnerId } from 'myaccount/view/app/hooks/useGetOrderByOwnerId';
import { useGetOrderDetailsQuery } from 'myaccount/view/app/hooks/useGetOrderDetailsQuery';
import { useGetSolutionsByClaim } from 'myaccount/view/app/hooks/useGetSolutionsByClaim';
import { useLayout } from 'myaccount/view/app/NewLayout/NewLayout.hooks';
import { STEP_CONFIG } from 'myaccount/view/app/NewLayout/ClassiqueRoutes.config';
import { buildPath } from 'myaccount/view/app/NewLayout/StepsProvider/StepContext.helpers';
import {
  StepContext,
  StepContextValues,
} from 'myaccount/view/app/NewLayout/StepsProvider/StepsProvider.context';

import {
  getMultiAdditionalInformationEnumValue,
  getMultiAdditionalInformationStringValue,
  getMultiAdditionalInformationValue,
} from './ResumeCard.helpers';

export const useResumeCard = ({
  claimGroupConfirmation,
}: {
  claimGroupConfirmation?: ClaimGroupConfirmation;
}) => {
  const { values } = useContext<StepContextValues>(StepContext);
  const claimGroupId = values.claimGroup?.claimGroupId;
  const claims = values.claimGroup?.claims;

  const ownerProductIds = claims?.map((claim) => claim.ownerProductId);
  const additionalInformationIds = claimGroupConfirmation?.claims
    .flatMap((claim) =>
      claim?.additionalClaimInformation?.map(
        (additionalInformation) =>
          additionalInformation.additionalInformationId,
      ),
    )
    .reduce((acc: string[], curr) => {
      if (!acc.includes(curr)) {
        return [...acc, curr];
      }

      return acc;
    }, []);

  const { ownerId } = useLayout();
  const { orders } = useGetOrderByOwnerId({ ownerId });
  const { orderDetail } = useGetOrderDetailsQuery({
    orderId: orders ? orders[0].orderId : undefined,
  });

  const products = orderDetail?.products.filter((product) =>
    ownerProductIds?.includes(product.ownerProductId),
  );

  const { additionalInformations } =
    useGetMultiAdditionalInformationEntitySummaryQuery({
      additionalInformationIds,
    });

  const { diagnostic_magasin } = getMultiAdditionalInformationValue({
    additionalInformations,
    additionalClaimInformation:
      claimGroupConfirmation?.claims[0]?.additionalClaimInformation,
    comparaisonRule: 'uniqueName',
    compareValues: ['diagnostic_magasin'],
  });

  const additionalInformationStringIdsLabels =
    getMultiAdditionalInformationStringValue({
      additionalInformations,
      additionalClaimInformation:
        claimGroupConfirmation?.claims[0]?.additionalClaimInformation,
    });

  const additionalInformationIdsLabels = getMultiAdditionalInformationEnumValue(
    {
      additionalInformations,
      additionalClaimInformation:
        claimGroupConfirmation?.claims[0]?.additionalClaimInformation,
    },
  );

  const allInformations = useGetMultiAdditionalInformationEntitySummaryQuery({
    additionalInformationIds: additionalInformationIdsLabels?.map(
      (info) => info.enumValue,
    ),
  });

  const pairLabelValue = allInformations.additionalInformations?.map(
    (inforamtion: AdditionalInformation) => ({
      label: additionalInformationIdsLabels.find(
        (idAndLabel) => idAndLabel.enumValue === inforamtion.id,
      )?.label,
      value: inforamtion.label,
    }),
  );

  const claimAdditionalInformationDocuments: string[] =
    (claimGroupConfirmation?.claims[0]?.additionalClaimInformation
      ?.filter(
        (additionalInformation: AdditionalClaimInformation) =>
          additionalInformation.additionalInformationFileValue &&
          additionalInformation.additionalInformationFileValue
            .customerVisibility,
      )
      .flat()
      .map((claimAdditionalInformationDocument) => {
        return (
          claimAdditionalInformationDocument.additionalInformationFileValue
            ?.fileName || undefined
        );
      })
      .filter((documentName) => documentName) as string[]) || [];

  const documents =
    claimGroupConfirmation?.claims[0]?.documents
      .filter((document) => document.customerVisibility)
      .map((document) => document.name) || [];

  const { solutions } = useGetSolutionsByClaim({
    claimGroupId: claimGroupConfirmation?.claimGroupId,
  });

  const firstClaim = claims?.at(0);

  const linkModify = firstClaim
    ? buildPath(STEP_CONFIG.CLAIM_GROUP_REASON, claimGroupId)
    : undefined;

  return {
    products,
    claims: claims || [],
    description: diagnostic_magasin,
    pairLabelValue,
    pairStringKeyValues: additionalInformationStringIdsLabels,
    documents: [...claimAdditionalInformationDocuments, ...documents],

    solution: solutions?.find(
      (solution) =>
        solution.solutionGroupId === claimGroupConfirmation?.solutionGroupId,
    ),
    linkModify,
  };
};
