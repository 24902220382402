import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared/supportedMethods';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { RequiredPayloadFieldIsNotProvided } from '@savgroup-front-common/exceptions/src/client/errors/RequiredPayloadFieldIsNotProvided';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  FileId,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.workflow}files/technicalIntervention/cancel`;

interface CancelTechnicalInterventionPayload {
  fileId: FileId;
}

async function cancelTechnicalIntervention({
  fileId,
}: CancelTechnicalInterventionPayload): Promise<
  BackResponse<string> | BackResponseFailure
> {
  const functionName = 'cancelTechnicalIntervention';

  if (!fileId) {
    const exception = new RequiredPayloadFieldIsNotProvided(
      'fileId',
      functionName,
    );

    return prepareResponseFailure(exception);
  }

  try {
    return await apiCall(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: {
        fileId,
      },
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default cancelTechnicalIntervention;
