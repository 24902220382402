import { defineMessages } from 'react-intl';

export default defineMessages({
  vatIncluded: {
    id: 'components.myaccount.myReturn.quoteInformation.vatIncluded',
    defaultMessage: 'VAT incl.',
  },
  description: {
    id: 'components.myaccount.myReturn.quoteInformation.titleDescription',
    defaultMessage: 'Description',
  },
  quoteComment: {
    id: 'components.myaccount.myReturn.quoteInformation.quoteComment',
    defaultMessage: 'Comment',
  },
  service: {
    id: 'components.myaccount.quoteInformation.service',
    defaultMessage: 'Service',
  },
  spareParts: {
    id: 'components.myaccount.quoteInformation.spareParts',
    defaultMessage: 'Spare parts',
  },
  administrativeFees: {
    id: 'components.myaccount.quoteInformation.administrativeFees',
    defaultMessage: 'Administrative Fees',
  },
  discount: {
    id: 'components.myaccount.quoteInformation.discount',
    defaultMessage: 'Discount',
  },
  destructionFee: {
    id: 'components.myaccount.quoteInformation.destructionFee',
    defaultMessage: 'Destruction Fee',
  },
  returnFee: {
    id: 'components.myaccount.quoteInformation.returnFee',
    defaultMessage: 'Return Fee',
  },
});
