import useChooseProductCard from 'myaccount/view/app/hooks/useGetModelDetailByIdQuery';
import { useGetMultiAdditionalInformationEntitySummaryQuery } from 'myaccount/view/app/hooks/useGetMultiAdditionalInformationEntitySummaryQuery';

import { getMultiAdditionalInformationValue } from '../ResumeCard.helpers';
import { OrderDetailsProduct } from '@savgroup-front-common/types';
import { Claim } from '../../../app/NewLayout/StepsProvider/StepsProvider.types';

const INFORMATION_TYPE = 'informationType';
const SERIAL_NUMBER = 'SerialNumber';

const useResumeCardHeader = ({
  product,
  currentClaim,
}: {
  product: OrderDetailsProduct;
  currentClaim?: Claim;
}) => {
  const { modelDetail } = useChooseProductCard({ product });

  const additionalClaimInformationIds =
    currentClaim?.additionalClaimInformation.map(
      (item) => item.additionalInformationId,
    );

  const { additionalInformations } =
    useGetMultiAdditionalInformationEntitySummaryQuery({
      additionalInformationIds: additionalClaimInformationIds,
    });

  const { SerialNumber } = getMultiAdditionalInformationValue({
    additionalInformations,
    additionalClaimInformation: currentClaim?.additionalClaimInformation as any,
    comparaisonRule: INFORMATION_TYPE,
    compareValues: [SERIAL_NUMBER],
  });

  return { modelDetail, serialNumber: SerialNumber };
};

export default useResumeCardHeader;
