import { defineMessages } from 'react-intl';

export default defineMessages({
  free: {
    id: 'components.myaccount.claims.handling.cards.free',
    defaultMessage: 'Free',
  },
  depositTitle: {
    id: 'components.myaccount.claims.handling.cards.depositTitle',
    defaultMessage: 'Send product : ',
  },
  depositPickupPoint: {
    id: 'components.myaccount.claims.handling.cards.depositPickupPoint',
    defaultMessage: 'depositPickupPoint',
  },
  depositPickupStore: {
    id: 'components.myaccount.claims.handling.cards.depositPickupStore',
    defaultMessage: 'depositPickupStore',
  },
  depositHome: {
    id: 'components.myaccount.claims.handling.cards.depositHome',
    defaultMessage: 'depositHome',
  },
  depositExternal: {
    id: 'components.myaccount.claims.handling.cards.depositExternal',
    defaultMessage: 'depositExternal',
  },
  depositHomeIntervention: {
    id: 'components.myaccount.claims.handling.cards.depositHomeIntervention',
    defaultMessage: 'depositHomeIntervention',
  },
  depositImmediateDropAtStore: {
    id: 'components.myaccount.claims.handling.cards.depositImmediateDropAtStore',
    defaultMessage: 'depositImmediateDropAtStore',
  },

  depositPickupPointDidactics: {
    id: 'components.myaccount.claims.handling.cards.depositPickupPointDescription',
    defaultMessage: 'PickupDidactics',
  },
  depositPickupStoreDidactics: {
    id: 'components.myaccount.claims.handling.cards.depositPickupStoreDescription',
    defaultMessage: 'DropAtStoreDidactics',
  },
  depositHomeDidactics: {
    id: 'components.myaccount.claims.handling.cards.depositHomeDescription',
    defaultMessage: 'PickupDidactics',
  },
  depositExternalDidactics: {
    id: 'components.myaccount.claims.handling.cards.depositExternalDescription',
    defaultMessage: 'PickupDidactics',
  },
  depositHomeInterventionDidactics: {
    id: 'components.myaccount.claims.handling.cards.depositHomeInterventionDescription',
    defaultMessage: 'depositHomeIntervention',
  },
  depositImmediateDropAtStoreDidactics: {
    id: 'components.myaccount.claims.handling.cards.depositImmediateDropAtStoreDescription',
    defaultMessage: 'depositImmediateDropAtStore',
  },
});
