import { createRequest } from '@savgroup-front-common/core/src/services/request';

export const MYORDERS_RUNNING = createRequest('MYORDERS_RUNNING');
export const MYORDERS_LOADED = createRequest('MYORDERS_LOADED');

export const MYORDERS_SELECT_PRODUCT = 'MYORDERS_SELECT_PRODUCT';
export const MYORDERS_ON_ERRORS = 'MYORDERS_ON_ERRORS';
export const MYORDERS_SEARCHING = 'MYORDERS_SEARCHING';
export const MYORDERS_ON_SEARCH_ERRORS = 'MYORDERS_ON_SEARCH_ERRORS';
export const MYORDERS_FOUND = 'MYORDERS_FOUND';
export const OWNER_PRODUCT_INFO_LOADED = 'OWNER_PRODUCT_INFO_LOADED';
export const MYORDERS_NOT_FOUND = 'MYORDERS_NOT_FOUND';
export const MYORDERS_ASSIGNING = 'MYORDERS_ASSIGNING';
export const MYORDERS_ASSIGNED = 'MYORDERS_ASSIGNED';
export const MYORDERS_ON_ASSIGN_ERRORS = 'MYORDERS_ON_ASSIGN_ERRORS';

export const LOAD_ORDERS_DATA = createRequest('LOAD_ORDERS_DATA');
export const LOAD_ORDERS = createRequest('LOAD_ORDERS');
