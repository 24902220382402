import React, { FunctionComponent } from 'react';

import { safeFormattedIntlString } from '@savgroup-front-common/core/src/formatters';
import BanneredLayout from 'myaccount/view/app/NewLayout/BanneredLayout/BanneredLayout';

import FileFollowups from './FileFollowups/FileFollowups';
import {
  useBreadCrumbs,
  useGetProductsDetails,
} from './FileFollowupsPage.hooks';
import messages from './messages';

const FileFollowupsPage: FunctionComponent = () => {
  const {
    fileId,
    productsDetails,
    isLoadingSolution,
    isLoadingModel,
    isLoadingFileSummary,
  } = useGetProductsDetails();

  const isLoading = isLoadingSolution || isLoadingModel || isLoadingFileSummary;

  const breadCrumbs = useBreadCrumbs({ isLoading, productsDetails, fileId });

  return (
    <>
      <BanneredLayout>
        <BanneredLayout.BannerTitle
          breadcrumbs={breadCrumbs}
          title={
            productsDetails
              ? safeFormattedIntlString(messages.bannerTitle, {
                  reference: isLoading
                    ? '...'
                    : productsDetails[0]?.fileReference,
                })
              : messages.bannerTitleUnknown
          }
          description={messages.bannerDescription}
        />
        <BanneredLayout.Content>
          <FileFollowups />
        </BanneredLayout.Content>
      </BanneredLayout>
    </>
  );
};

FileFollowupsPage.displayName = 'FileFollowupsPage';

export default FileFollowupsPage;
