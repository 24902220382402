import get from 'lodash/get';
import head from 'lodash/head';
import { createSelector } from 'reselect';

import { Selectors as WorkflowSelectors } from '@savgroup-front-common/core/src/domains/workflow';

import { MyAccountRootState } from '../MyAccountRootState';

export const customerState = (state: MyAccountRootState) => state.customer;
export const loginState = (state: MyAccountRootState) => state.login;

export const customerStates = createSelector([customerState], (customer) =>
  get(customer, 'availableFileActions'),
);
export const activeFileStateName = createSelector(
  [customerStates],
  (states) => {
    return get(head(states), ['fileStatus', 'name']);
  },
);
export const activeTransitions = createSelector([customerStates], (states) => {
  return get(head(states), ['transitions']);
});

export const fileStates = createSelector([customerState], (customer) =>
  get(customer, 'fileStates'),
);

export const customerInfosSelector = createSelector(
  [customerState],
  (customer) => {
    const {
      modelId,
      currentStatus,
      sellerId,
      fileReference,
      issueId,
      reasonId,
      solutionId,
      ownerProductId,
      warrantyTypeId,
      isLoading,
      fileProducts,
    } = customer.file;
    const moduleSummary = get(customer.customerFileSummary, 'moduleSummary');

    return {
      issueId,
      modelId,
      reasonId,
      solutionId,
      productId: ownerProductId,
      workflowCurrentStatus: currentStatus,
      moduleSummary,
      sellerId,
      fileReference,
      warrantyTypeId,
      isLoading,
      fileProducts,
    };
  },
);

export const selectSellerIdFromFile = createSelector(
  [customerState],
  (customer) => customer?.file?.sellerId,
);

export const ownerIdSelector = createSelector(
  [loginState],
  (login) => login.id,
);

export const quotesWithMargin = createSelector(
  [customerState],
  (customer) => customer.documents.quotes,
);

export const invoiceToPay = createSelector(
  [customerState, WorkflowSelectors.invoicePaymentStatusSelector],
  (customer, invoicePaymentStatus: any) => {
    const invoice = customer.documents.invoiceToPay;

    return invoice && { ...invoice, ...invoicePaymentStatus.get(invoice.id) };
  },
);

export const selectCustomerFileSummary = createSelector(
  [customerState],
  (customer) => customer.customerFileSummary,
);
