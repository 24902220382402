import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  CIVILITY,
  COMMON_QUERY_PARAMS,
  HOUSING_TYPES,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  ReverseMoney,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.claim}claims/${COMMON_QUERY_PARAMS.CLAIM_ID}/solution`;

interface Address {
  addressId: string;
  civility: CIVILITY;
  firstname: string;
  lastname: string;
  address: string;
  additionalAddress: string;
  housingType: HOUSING_TYPES;
  doorCode: string;
  floor: string;
  hasElevator: boolean;
  hasParkingSpace: boolean;
  postalCode: string;
  city: string;
  countryCode: string;
  phone: string;
  companyName: string;
  additionalInformation: string;
  isDefault: boolean;
}

interface SetClaimSolutionResponse {
  id: string;
  state: string;
  buyDate: string;
  receptionDate: string;
  issueOccurenceDate: string;
  issueId: string;
  issueName: string;
  modelId: string;
  modelName: string;
  symptomId: string;
  reasonId: string;
  reasonName: string;
  soldWarrantyId: string;
  soldWarrantyName: string;
  warrantyTypeId: string;
  soldWarrantyPurchaseDate: string;
  solutionTypeId: string;
  solutionTypeName: string;
  solutionPrice: 0;
  solutionPriceCurrencyCode: string;
  warrantyTypeName: string;
  brandId: string;
  brandName: string;
  modelTypeId: string;
  modelTypeName: string;
  productState: string;
  depositAddress: Address;
  ownerAddress: Address;
  deliveryAddress: Address;
  onSiteInterventionAddress: Address;
  sellerName: string;
  sellerId: string;
  fileId: string;
  solutionGroupType: string;
  useSameAddress: boolean;
  includedDocuments: string[];
  allIncludedDocuments: {
    type: string;
    name: string;
    extension: string;
    id: string;
  }[];
  additionalElements: string[];
  solutionGroupId: string;
  carrierDepositName: string;
  carrierDeliveryName: string;
  reasonComment: string;
}

interface SetClaimSolutionPayload {
  claimId: string;
  solutionTypeId: string;
  solutionPrice?: ReverseMoney;
}

async function setClaimSolutionCommand({
  claimId,
  solutionTypeId,
  solutionPrice,
}: SetClaimSolutionPayload): Promise<
  BackResponse<SetClaimSolutionResponse> | BackResponseFailure
> {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.CLAIM_ID, claimId);

    const response = await apiCall<BackResponse<SetClaimSolutionResponse>>(
      url,
      {
        method: SUPPORTED_METHODS.PUT,
        json: {
          solutionTypeId,
          solutionPrice,
        },
      },
    );

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

export default setClaimSolutionCommand;
