import React, { useContext } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import {
  FormGroup,
  Textarea,
} from '@savgroup-front-common/core/src/atoms/Form';
import {
  StepContext,
  StepContextValues,
} from 'myaccount/view/app/NewLayout/StepsProvider/StepsProvider.context';

import { ClaimGroupReasonValues } from './ClaimGroupReasonPage.types';
import messages from './messages';

interface ClaimGroupReasonPageCommentContentProps {
  formContext: UseFormReturn<ClaimGroupReasonValues>;
}

const ClaimGroupReasonPageCommentContent: React.FC<
  ClaimGroupReasonPageCommentContentProps
> = ({ formContext }) => {
  const { values } = useContext<StepContextValues>(StepContext);

  const { control } = formContext;

  if (!values.reason?.mandatoryComment) {
    return <></>;
  }

  return (
    <FormGroup>
      <Controller
        control={control}
        name="reasonComment"
        render={({ field }) => (
          <Textarea
            isRequired
            name={field.name}
            label={messages.reasonCommentLabel}
            value={field.value}
            onChange={field.onChange}
            dataTestId="comment-text-area"
            isScrollIntoView
          />
        )}
      />
    </FormGroup>
  );
};

ClaimGroupReasonPageCommentContent.displayName =
  'ClaimGroupReasonPageCommentContent';

export default ClaimGroupReasonPageCommentContent;
