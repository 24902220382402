import { defineMessages } from 'react-intl';

export default defineMessages({
  addProduct: {
    id: 'components.myaccount.homePage.productItem.addProduct',
    defaultMessage: 'Add a product',
  },
  searchProductPlaceholder: {
    id: 'components.myaccount.homePage.productItem.searchProductPlaceholder',
    defaultMessage: "Product's name, reference, ...",
  },
  addProductSubmit: {
    id: 'components.myaccount.homePage.productItem.addProductSubmit',
    defaultMessage: 'Add',
  },
});
