import startsWith from 'lodash/startsWith';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  AdditionalClaimInformation,
  AdditionalInformation,
} from '@savgroup-front-common/types';

import { CommonAttachmentService } from '../../../../api';

export const downloadMultiFileAdditionalInformationBlob = async ({
  fileAdditionalInformation,
}: {
  fileAdditionalInformation?:
    | AdditionalInformation
    | AdditionalClaimInformation;
}) => {
  if (
    !fileAdditionalInformation ||
    !fileAdditionalInformation.additionalInformationFilesValue
  ) {
    return undefined;
  }

  return Promise.all(
    fileAdditionalInformation.additionalInformationFilesValue.map(
      async (item) => {
        const isUrlReady =
          startsWith(item.url ?? '', 'https://') ||
          startsWith(item.url ?? '', '//localhost/');
        const formattedUrl = isUrlReady
          ? item.url
          : `${APIConfiguration.base}${item.url}`;

        const blob = await CommonAttachmentService.downloadAttachment({
          endpoint: formattedUrl,
        });

        if ('failure' in blob) {
          return undefined;
        }

        return new File([blob], item.fileName, {
          type: blob.type,
        });
      },
    ),
  );
};
