import get from 'lodash/get';
import toLower from 'lodash/toLower';

import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import { changeLocaleLanguage } from '@savgroup-front-common/core/src/domains/i18n/actions';
import { apiCall } from '@savgroup-front-common/core/src/services';

import {
  LOGIN_SIGNEDIN,
  LOGIN_SIGNEDOUT,
  LOGIN_SIGNIN,
  LOGIN_SIGNOUT,
} from './loginConst';

export function loginSignIn() {
  return { type: LOGIN_SIGNIN };
}

export function loginSignedIn(payload) {
  return (dispatch) => {
    const preferedLanguage = get(payload, ['preference', 'language']);

    if (preferedLanguage) {
      dispatch(changeLocaleLanguage({ locale: toLower(preferedLanguage) }));
    }
    dispatch({ type: LOGIN_SIGNEDIN, payload });
  };
}

export function loginSignOut() {
  return { type: LOGIN_SIGNOUT };
}

export function loginSignedOut() {
  return { type: LOGIN_SIGNEDOUT };
}

export function signIn() {
  return (dispatch) => {
    dispatch(loginSignIn());

    return apiCall(`${APIConfiguration.owner}owners/me`, {
      method: SUPPORTED_METHODS.GET,
    }).then((result) => {
      dispatch(
        loginSignedIn({
          isLoaded: true,
          ...result.value,
          failure: result.failure,
          errors: result.errors,
        }),
      );
    });
  };
}

export function signOut() {
  return (dispatch) => {
    dispatch(loginSignOut());

    return Promise.resolve(dispatch(loginSignedOut()));
  };
}
