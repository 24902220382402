import useGetIssuesByOrder from 'myaccount/view/app/hooks/useGetIssuesByOrder';
import { OwnerProductSummaryDto } from '../../HomePage.types';
import useGetModelById from 'myaccount/hooks/useGetModelById';
import { useCallback } from 'react';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { ClaimService } from 'myaccount/api';
import {
  buildClaimReasonUrl,
  buildClaimStepUrl,
  buildFileFollowupUrl,
} from 'myaccount/view/app/NewLayout/ClassiqueLayout.helpers';
import { useHistory } from 'react-router-dom';
import { useGetCustomerFileFullSummaryQuery } from 'myaccount/view/app/hooks/useGetCustomerFileFullSummaryQuery';
import { useGetSolutionTypeQuery } from 'myaccount/view/app/hooks/useGetSolutionTypeQuery';
import { useGetClaimContextsByGroupIdQuery } from 'myaccount/view/app/hooks/useGetClaimContextsByGroupIdQuery';
import { ClaimContextState } from '@savgroup-front-common/types';

export const useGetProductsDetails = ({
  product,
}: {
  product: OwnerProductSummaryDto;
}) => {
  const { removeAllNotifications, pushErrors } = useToasts();
  const { push } = useHistory();
  const { model, isLoading: isModelLoading } = useGetModelById({
    modelId: product?.modelId,
  });
  const { issues, isIssuesLoading } = useGetIssuesByOrder({
    orderId: product.orderId,
  });
  const { productsInfo } = useGetCustomerFileFullSummaryQuery({
    fileId: product.fileId,
  });
  const { solution, isLoading: isLoadingSolution } = useGetSolutionTypeQuery({
    solutionTypeId: productsInfo?.solutionId,
  });
  const { claimContexts, isLoading: isLoadingClaimContexts } =
    useGetClaimContextsByGroupIdQuery({
      claimGroupId: product.claimGroupId,
    });

  const handleCreateClaimWithIssue = useCallback(
    async ({
      ownerId,
      ownerProductIds,
      issueId,
    }: {
      ownerId: string;
      ownerProductIds?: string[];
      issueId: string;
    }) => {
      if (!ownerId || !ownerProductIds || !issueId) {
        return undefined;
      }

      removeAllNotifications();

      const response = await ClaimService.setCreateClaimGroup({
        ownerId,
        ownerProductIds,
        issueId,
      });

      if (response.failure) {
        pushErrors(response.errors);

        return undefined;
      }

      const claimGroupId = response.value;

      push(buildClaimReasonUrl({ claimGroupId }));

      return claimGroupId;
    },
    [push, pushErrors, removeAllNotifications],
  );

  const handleFollowClaim = useCallback(
    ({ fileId }: { fileId: string }) => {
      push(buildFileFollowupUrl({ fileId }));
    },
    [push],
  );

  const handleFinalizeClaim = useCallback(
    (claimContextsState: ClaimContextState) => {
      if (product.claimGroupId) {
        push(
          buildClaimStepUrl({
            claimContextsState,
            claimGroupId: product.claimGroupId,
          }),
        );
      }
    },
    [product.claimGroupId, push],
  );

  return {
    productsDetails: {
      ...product,
      ...model,
      solutionName: solution?.name,
    },
    issues,
    claimContexts,
    isLoading:
      isModelLoading ||
      isIssuesLoading ||
      isLoadingSolution ||
      isLoadingClaimContexts,
    handleCreateClaimWithIssue,
    handleFollowClaim,
    handleFinalizeClaim,
  };
};
