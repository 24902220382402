import { Col, Grid } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { mediaQuery } from '@savgroup-front-common/core/src/grid/helpers';
import { rem } from '@savgroup-front-common/core/src/helpers';

export const $SelectContainerGrid = styled(Grid)`
  height: 100vh;
  width: 100%;
  overflow: hidden;
`;

export const $SelectContainerRow = styled('div')`
  display: flex;
  flex-direction: row-reverse;
`;

export const $ColWithoutGutter = styled(Col)`
  && {
    background-color: #ffffff;
    ${mediaQuery.sm`
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  `};
  }
`;
export const $BackButtonContainer = styled($ColWithoutGutter)`
  position: relative;
  z-index: 0;
  background-color: #ffffff;
  padding-left: 1rem;
  display: flex;
  justify-content: end;
  margin-bottom: 1rem;
`;
export const $CloseButton = styled(Button)`
  padding: 0;
`;
export const $AddressContainer = styled.div`
  position: relative;
  background-color: #ffffff;
`;

export const $ColMapContainer = styled(Col)`
  width: 100%;
  padding-left: unset;
  padding-right: unset;
`;

export const $ColListContainer = styled(Col)`
  height: 100vh;
  width: 100%;
  padding: 1rem;
  overflow: scroll;

  [class*='RadioCardWithCheckboxColumnstyles__RadioCardContainer'] {
    padding: ${rem(20)} ${rem(16)};
  }

  [class*='ChooseItemCardstyles__ItemContentTextWrapper'] {
    padding-right: ${rem(16)};
  }

  [class*='ChooseItemCardstyles__ItemTitle'] {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
  }

  [class*='ChooseItemCardstyles__ItemDescription'] {
    font-weight: ${({ theme }) => theme.fonts.weight.normal};
    font-size: 14px;
    line-height: 16px;
  }
`;

export const $NoPickupPoint = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem;
`;
