import React, { FunctionComponent } from 'react';

import { ActorSummary } from '@savgroup-front-common/types';

import { Heading } from '../../../../atoms/Heading';
import { SafeFormattedMessage } from '../../../../formatters';
import { GenericActorInformation } from '../GenericActorInformation';

import messages from './messages';

interface RepairerInformationProps {
  repairer: ActorSummary;
  withTitle?: boolean;
}

const RepairerInformation: FunctionComponent<
  React.PropsWithChildren<RepairerInformationProps>
> = ({ repairer, withTitle = true }) => {
  return (
    <div key={repairer.id} data-testid="repairerInformation">
      {withTitle && (
        <Heading level={3} key="repairerInfoTitle">
          <SafeFormattedMessage {...messages.repairerInfoTitle} />
        </Heading>
      )}

      <GenericActorInformation actor={repairer} />
    </div>
  );
};

RepairerInformation.displayName = 'RepairerInformation';

export default RepairerInformation;
