/* eslint-disable no-console */
import { fromJS } from 'immutable';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';

import { getCarrierBrand } from '../../../helpers';

export function onCarriersLoading(state, { meta }) {
  const claimId = get(meta, ['claimId']);
  const handlingMode = get(meta, ['handlingMode']);

  if (!claimId) {
    console.warn('[onCarriersLoading] ClaimId is undefined');

    return state;
  }

  return state.setIn(
    ['carriers', claimId, handlingMode],
    fromJS({ isLoaded: false, isDirty: false, value: undefined }),
  );
}

export function onCarriersLoadingErrored(state, { meta }) {
  const claimId = get(meta, ['claimId']);
  const handlingMode = get(meta, ['handlingMode']);

  if (!claimId) {
    console.warn('[onCarriersLoadingErrored] ClaimId is undefined');

    return state;
  }

  // TODO errors
  return state.updateIn(['carriers', claimId, handlingMode], (oldValue) =>
    oldValue.setIn(['isDirty'], true).setIn(['hasErrors'], true),
  );
}

export function onCarriersLoaded(state, { payload, meta }) {
  const claimId = get(meta, ['claimId']);
  const handlingMode = get(meta, ['handlingMode']);
  const carriers = groupBy(
    map(get(payload, ['value']), (carrier) => ({
      ...carrier,
      carrierBrand: getCarrierBrand(carrier.carrierName),
    })),
    ({ productType }) => `${handlingMode}_${productType}`,
  );

  if (!claimId) {
    console.warn('[onCarriersLoaded] ClaimId is undefined.');

    return state;
  }

  return state.setIn(
    ['carriers', claimId, handlingMode],
    fromJS({ isLoaded: true, isDirty: false }).setIn(['value'], carriers),
  );
}
