import { endsWith, last } from 'lodash';
import moment, { Moment } from 'moment';

const formatDigit = (first: string, second: string) => {
  if (first.length === 1 && second.length > 0) {
    return [`0${first}`, second];
  }
  if (first.length > 2) {
    return [first.slice(0, 2), `${first.slice(2, first.length)}${second}`];
  }

  return [first, second];
};

export const formatInputDate = (value: string) => {
  const splitted = value.split('/');

  let [days = '', months = '', years = ''] = splitted;

  [days, months] = formatDigit(days, months);
  [months, years] = formatDigit(months, years);

  const filtered = [days, months, years].filter((v) => v.length);
  const isSlashLast = filtered.length < 3 && endsWith(value, '/');
  const isLastFull =
    filtered && filtered.length < 3 && last(filtered)?.length === 2;

  return `${filtered.join('/')}${isSlashLast || isLastFull ? '/' : ''}`;
};

export const formatValueToDate = (value?: Moment) => {
  if (moment.isMoment(value)) {
    return value.isValid() ? value.toDate() : undefined;
  }

  return value;
};
