import { createContext, useContext } from 'react';

import { FileSummary } from '@savgroup-front-common/types';

interface FileFollowupsContextReturnValue {
  fileSummary?: FileSummary;
}

const FileFollowupsContext = createContext<FileFollowupsContextReturnValue>({
  fileSummary: undefined,
});

export const useFileFollowupsContext = () => useContext(FileFollowupsContext);

export default FileFollowupsContext;
