import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { Col, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { H2 } from '../../atoms/headings';
import { formatDay, formatTime, weekDay } from '../../formatters';
import { intlShape } from '../../helpers/proptypes';

import messages from './messages';

const InfoContainer = styled('div')`
  margin-bottom: 24px;
`;

const InfoCol = styled(Col)`
  margin: 1px 0;
`;

const DateContainer = styled(Row)`
  margin-bottom: 10px;
`;

const FormattedWeekDay = injectIntl(({ intl, weekDay }) => {
  return capitalize(moment().locale(intl.locale).day(weekDay).format('dddd'));
});

class PickupPointItemInfo extends React.Component {
  static displayHours(hours, intl) {
    if (isEmpty(hours)) {
      return (
        <Row>
          <InfoCol xs={12}>-</InfoCol>
        </Row>
      );
    }

    return map(hours, (h) => (
      <Row key={`${h.start}-${h.end}`}>
        <InfoCol xs={12}>
          {formatTime(h.start, intl.locale)} - {formatTime(h.end, intl.locale)}
        </InfoCol>
      </Row>
    ));
  }

  render() {
    const { openingHours, intl } = this.props;

    return (
      <InfoContainer>
        <Row center="xs" style={{ marginBottom: '24px' }}>
          <H2 style={{ padding: '8px 0 16px 0' }} heading>
            {intl.formatMessage(messages.title)}
          </H2>
        </Row>
        {openingHours && openingHours.length > 0
          ? map(
              orderBy(openingHours, ({ day }) => weekDay(day, intl.locale)),
              ({ day, hours }) => (
                <DateContainer between="xs" key={day}>
                  <Col>
                    <FormattedWeekDay weekDay={formatDay(day, intl.locale)} />
                  </Col>
                  <Col>{PickupPointItemInfo.displayHours(hours, intl)}</Col>
                </DateContainer>
              ),
            )
          : null}
      </InfoContainer>
    );
  }
}

PickupPointItemInfo.propTypes = {
  intl: intlShape.isRequired,
  openingHours: PropTypes.arrayOf(
    PropTypes.shape({
      day: PropTypes.string,
      hours: PropTypes.arrayOf(
        PropTypes.shape({
          start: PropTypes.string,
          end: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
};

PickupPointItemInfo.defaultProps = {};

export default injectIntl(PickupPointItemInfo);
