import { defineMessages } from 'react-intl';

import { MessageType } from '@savgroup-front-common/types';

export default defineMessages<string, MessageType>({
  uploadFailed: {
    id: 'view.attachmentsList.uploadFailed',
    defaultMessage: 'Upload failed!',
  },
  uploadSucceeded: {
    id: 'view.attachmentsList.uploadSucceeded',
    defaultMessage: 'Upload succeeded!',
  },
});
