import PropTypes from 'prop-types';

export const claimProps = {
  claimCreationDateUtc: PropTypes.string,
  claimId: PropTypes.string,
  groupId: PropTypes.string,
  orderLineReference: PropTypes.string,
  ownerProductId: PropTypes.string,
  product: PropTypes.shape({}),
  state: PropTypes.string,
};
