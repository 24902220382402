/* eslint-disable no-console */
import { fromJS } from 'immutable';
import get from 'lodash/get';

export function onCarrierForClaimGroupLoading(state, { meta }) {
  const claimGroupId = get(meta, ['claimGroupId']);

  if (!claimGroupId) {
    console.warn('[onCarrierForClaimGroupLoading] ClaimGroupId is undefined');

    return state;
  }

  return state.setIn(
    ['groupCarrier', claimGroupId],
    fromJS({ isLoaded: false, isDirty: false, value: undefined }),
  );
}

export function onCarrierForClaimGroupLoadingErrored(state, { meta }) {
  const claimGroupId = get(meta, ['claimGroupId']);

  if (!claimGroupId) {
    console.warn(
      '[onCarrierForClaimGroupLoadingErrored] ClaimGroupId is undefined',
    );

    return state;
  }

  // TODO errors
  return state.updateIn(['groupCarrier', claimGroupId], (oldValue) =>
    oldValue.setIn(['isDirty'], true).setIn(['hasErrors'], true),
  );
}

export function onCarrierForClaimGroupLoaded(state, { payload, meta }) {
  const claimGroupId = get(meta, ['claimGroupId']);
  const carrier = get(payload, ['value']);

  if (!claimGroupId) {
    console.warn('[onCarrierForClaimGroupLoaded] ClaimGroupId is undefined.');

    return state;
  }

  return state.setIn(
    ['groupCarrier', claimGroupId],
    fromJS({ isLoaded: true, isDirty: false }).setIn(['value'], carrier),
  );
}
