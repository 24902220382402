import React, { FunctionComponent } from 'react';

import { ACTOR_TYPES } from '@savgroup-front-common/constants';
import { ActorSummary } from '@savgroup-front-common/types';

import { BaseLoader } from '../../molecules/BaseLoader';

import { DefaultActorInformation } from './components/DefaultActorInformation';
import { RepairerInformation } from './components/RepairerInformation';

interface ActorInformationContentProps {
  isLoading: boolean;
  actor: ActorSummary;
  withTitle?: boolean;
}

export const ActorInformationContent: FunctionComponent<
  ActorInformationContentProps
> = ({ isLoading, actor, withTitle = true }) => {
  const actorType = actor?.actorType;

  return (
    <>
      {isLoading && <BaseLoader />}

      {!isLoading && actorType === ACTOR_TYPES.REPAIRER && (
        <RepairerInformation
          key={actor.id}
          repairer={actor}
          withTitle={withTitle}
        />
      )}

      {!isLoading && actorType !== ACTOR_TYPES.REPAIRER && (
        <DefaultActorInformation actor={actor} withTitle={withTitle} />
      )}
    </>
  );
};

ActorInformationContent.displayName = 'ActorInformationContent';
