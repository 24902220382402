import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { GoogleApiConfiguration } from '@savgroup-front-common/configuration';

import { MapWithControlledZoom } from './MapWithControlledZoom';
import { $MapContainer } from './PickupPointMap.styles';
import { VisibilityObserver } from './VisibilityObserver';

class PickupPointMap extends Component {
  static getBounds(address, pickupPoints) {
    const bounds = {
      north: address.location.lat,
      east: address.location.lng,
      south: address.location.lat,
      west: address.location.lng,
    };

    if (pickupPoints.length > 0) {
      pickupPoints.forEach((pickupPoint) => {
        const { latitude: lat, longitude: lng } = pickupPoint;

        if (bounds.north < lat) {
          bounds.north = lat;
        }
        if (bounds.east < lng) {
          bounds.east = lng;
        }

        if (bounds.south > lat) {
          bounds.south = lat;
        }
        if (bounds.west > lng) {
          bounds.west = lng;
        }
      });
    }

    const centerLat = address.location.lat;
    const centerlng = address.location.lng;

    return {
      bounds,
      center: { lat: centerLat, lng: centerlng },
    };
  }

  render() {
    const { searchAddress, pickupPoints } = this.props;

    if (
      !searchAddress ||
      !searchAddress.location ||
      searchAddress.location.lat === undefined
    ) {
      return null;
    }

    const boundInfo = PickupPointMap.getBounds(searchAddress, pickupPoints);

    return (
      <VisibilityObserver threshold={0}>
        {({ inView, ref }) => (
          <$MapContainer ref={ref}>
            <MapWithControlledZoom
              key={`${boundInfo.bounds.north - boundInfo.bounds.south}-${
                boundInfo.bounds.west - boundInfo.bounds.east
              }-${inView}`}
              googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing&key=${GoogleApiConfiguration.apiKey}`}
              loadingElement={<div style={{ height: '100%' }} />}
              containerElement={
                <div style={{ height: '100vh', width: '100%' }} />
              }
              mapElement={<div style={{ height: '100vh' }} />}
              center={boundInfo.center}
              bounds={boundInfo.bounds}
              {...this.props}
              pickupPoints={pickupPoints}
            />
          </$MapContainer>
        )}
      </VisibilityObserver>
    );
  }
}

PickupPointMap.propTypes = {
  searchAddress: PropTypes.shape({
    location: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
  }).isRequired,
  iconsPath: PropTypes.shape({
    point: PropTypes.string,
    selectedPoint: PropTypes.string,
    home: PropTypes.string,
  }),
  handlingContext: PropTypes.string.isRequired,
  onSelectPickupPoint: PropTypes.func.isRequired,
  pickupPoints: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedPickupPointId: PropTypes.string,
};

PickupPointMap.defaultProps = {
  iconsPath: {
    point: '/images/carriers/Logo_Chrono_marker.png',
    selectedPoint: '/images/carriers/Logo_Chrono_marker_active.png',
    home: '/images/Group 14@1x.png',
  },
  selectedPickupPointId: null,
};

PickupPointMap.displayName = 'PickupPointMap';

export default PickupPointMap;
