import React, { FunctionComponent, Ref, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { Col, Row } from 'react-styled-flexboxgrid';

import { BUTTON_TYPES } from '@savgroup-front-common/constants/src/shared';
import { MessageType } from '@savgroup-front-common/types';

import {
  SafeFormattedMessage,
  SafeFormattedMessageWithoutSpread,
} from '../../../formatters';
import { ImageConverter } from '../../../helpers/ImageConverter';
import { useCombinedRefs } from '../../../hooks';
import { AttachmentIcon, PlusIcon } from '../../../protons/icons';
import { Button } from '../../button';
import { FieldMessage } from '../common';
import { getFinalFieldState } from '../common/helpers/getFinalFieldState';
import { FieldMessages } from '../common/helpers/getFinalFieldState.types';
import { Label } from '../common/Label/Label';

import AttachmentsList from './AttachmentsLister';
import { Attachment } from './AttachmentsLister/AttachmentItem.types';
import {
  $Col,
  $Dropzone,
  $FileInput,
  $IconContainer,
} from './FileUpload.styles';
import messages from './messages';

interface FileUploadProps {
  label?: MessageType;
  postLabel?: any;
  isRequired?: boolean;
  dropzone?: boolean;
  isError?: boolean;
  isWarning?: boolean;
  isSuccess?: boolean;
  disabled?: boolean;
  isDisabled?: boolean;
  forwardedRef?: Ref<HTMLInputElement> | (() => void);
  allowedMimeTypes?: string[];
  errors?: FieldMessages;
  warnings?: FieldMessages;
  successes?: FieldMessages;
  isLoading?: boolean;
  fileNames?: any[];
  hollow?: boolean;
  onRemove?: (attachment: Attachment) => void;
  name: string;
  dataTestId?: string;
  onSelect: (files?: File[]) => void;
  dropZoneMessage?: MessageType;
}
interface FileUploadPropsRef extends FileUploadProps {
  forwardedRef?: Ref<HTMLInputElement> | (() => void);
}

const FileUpload: FunctionComponent<
  React.PropsWithChildren<FileUploadPropsRef>
> = ({
  label,
  postLabel = null,
  dropzone = false,
  name,
  isRequired = false,
  isError = false,
  isWarning = false,
  isSuccess = false,
  disabled = false,
  isDisabled = false,
  allowedMimeTypes = [],
  fileNames = [],
  forwardedRef = null,
  errors = {},
  warnings = {},
  successes = {},
  onSelect,
  onRemove = undefined,
  isLoading = false,
  hollow = false,
  dataTestId,
  dropZoneMessage,
}) => {
  const transitionDisabled = disabled || isDisabled;
  const [status, message] = getFinalFieldState({
    errors: { isStatus: isError, messages: errors },
    warnings: { isStatus: isWarning, messages: warnings },
    successes: { isStatus: isSuccess, messages: successes },
    name,
  });

  const fileRef = useRef<HTMLInputElement>(null);
  const combinedRefs = useCombinedRefs(fileRef, forwardedRef);

  const handleClickInput = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (fileRef.current) {
      fileRef.current.click();
    }
    event.preventDefault();
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: async (docs: File[]) => {
      const newFiles = await Promise.all(
        docs.map(async (file) => ImageConverter.heiToJpg(file)),
      );

      onSelect(newFiles);
    },
  });

  return (
    <>
      {label && (
        <Label
          isRequired={isRequired}
          htmlFor={name}
          postLabel={postLabel}
          status={status}
        >
          {SafeFormattedMessage(label)}
        </Label>
      )}
      <$FileInput
        {...getInputProps()}
        id={name}
        name={name}
        ref={combinedRefs}
        disabled={transitionDisabled}
        type="file"
        accept={allowedMimeTypes.join(',')}
        onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
          const files = e.target.files ? Array.from(e.target.files) : undefined;

          if (!files) {
            onSelect([]);

            e.target.value = '';

            return undefined;
          }

          const newFiles = await Promise.all(
            files.map(async (file) => ImageConverter.heiToJpg(file)),
          );

          onSelect(newFiles);

          e.target.value = '';

          return undefined;
        }}
        data-testid="fileUpload"
      />
      <Row between="sm">
        <$Col $dropzone={dropzone} xs={12} sm>
          {dropzone && (
            <$IconContainer $isDragActive={isDragActive}>
              <AttachmentIcon />

              <$Dropzone
                {...(getRootProps() as any)}
                onClick={handleClickInput}
                type={BUTTON_TYPES.BUTTON}
                $isDragActive={isDragActive}
                $isDisabled={transitionDisabled}
              >
                <p>
                  <SafeFormattedMessageWithoutSpread
                    message={dropZoneMessage ?? messages.dragAndDropFiles}
                  />
                </p>
              </$Dropzone>
            </$IconContainer>
          )}
          {!dropzone && (
            <Button
              disabled={transitionDisabled}
              primary
              hollow={hollow}
              onClick={handleClickInput}
              danger={isError}
              type={BUTTON_TYPES.BUTTON}
              icon={<PlusIcon />}
              isLoading={isLoading}
            >
              <SafeFormattedMessageWithoutSpread message={messages.fileInput} />
            </Button>
          )}
          <FieldMessage
            message={message}
            status={status}
            dataTestId={dataTestId}
          />
        </$Col>

        {fileNames.length > 0 && (
          <Col style={{ flex: 1 }} xs={12} sm>
            <AttachmentsList
              name={name}
              fileNames={fileNames}
              onRemove={onRemove}
            />
          </Col>
        )}
      </Row>
    </>
  );
};

FileUpload.displayName = 'FileUpload';

export default React.forwardRef<HTMLInputElement, FileUploadProps>(
  (props, ref) => <FileUpload forwardedRef={ref} {...props} />,
);
 
