import { fromJS } from 'immutable';
import get from 'lodash/get';

export function onIssuesByOrderLoading(state, { meta }) {
  const orderId = get(meta, ['orderId']);

  return state.updateIn(
    ['orderIssues', 'values', orderId],
    fromJS({ isLoaded: false, value: undefined }),
    (oldValue) => oldValue.set('isDirty', false).setIn(['hasErrors'], false),
  );
}

export function onIssuesByOrderErrored(state, { meta }) {
  const orderId = get(meta, ['orderId']);

  return state.updateIn(['orderIssues', 'values', orderId], (oldValue) =>
    oldValue.setIn(['isDirty'], true).setIn(['hasErrors'], true),
  );
}

export function onIssuesByOrderLoaded(state, { payload, meta }) {
  const orderId = get(meta, ['orderId']);
  const issues = get(payload, ['value']);

  return state.updateIn(['orderIssues', 'values', orderId], (oldValue) =>
    oldValue.set('isLoaded', true).set('value', issues),
  );
}

export function onIssuesFromAllOrdersLoading(state) {
  return state.setIn(['orderIssues', 'loaded'], false);
}
export function onIssuesFromAllOrdersFinish(state) {
  return state.setIn(['orderIssues', 'loaded'], true);
}
