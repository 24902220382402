import { defineMessages } from 'react-intl';

export default defineMessages({
  validate: {
    id: 'components.myaccount.interventionAppointment.validate',
    defaultMessage: 'Validate',
  },
  requiredTimeSlotSelection: {
    id: 'components.myaccount.interventionAppointment.requiredTimeslot',
    defaultMessage: 'Timeslot selection is required',
  },
  noAvailableSchedule: {
    id: 'components.myaccount.interventionAppointment.noAvailableSchedule',
    defaultMessage: 'There are no available schedule please retry later.',
  },
  description: {
    id: 'components.myaccount.claims.appointmentCard.description',
    defaultMessage:
      'Please select one of the following time slots to confirm your home pick up.',
  },
  selectedAppointment: {
    id: 'components.myaccount.interventionAppointment.selectedAppointment',
    defaultMessage: '{startDate} from {startTime} to {endTime}',
  },
  pleaseContactSupportToTakeAppointment: {
    id: 'components.myaccount.interventionAppointment.pleaseContactSupportToTakeAppointment',
    defaultMessage: 'Please contact support to take appointment',
  },
  createTechnicalInterventionError: {
    id: 'components.myaccount.interventionAppointment.createTechnicalInterventionError',
    defaultMessage: 'Please contact support to take appointment',
  },
  integrationTypeIsNotConfigured: {
    id: 'components.interventionAppointment.integrationTypeIsNotConfigured',
    defaultMessage: 'FSL integration type is not configured.',
  },
});
