import React, { FunctionComponent } from 'react';

import { ActorSummary } from '@savgroup-front-common/types';

import { Card } from '../../atoms/Cards';

import { ActorInformationContent } from './ActorInformationContent';

interface ActorInformationProps {
  actor?: ActorSummary;
  isLoading?: boolean;
  withBorder?: boolean;
  withCard?: boolean;
  withTitle?: boolean;
}

export const ActorInformation: FunctionComponent<ActorInformationProps> = ({
  actor = null,
  isLoading = false,
  withBorder = false,
  withCard = true,
  withTitle = true,
}) => {
  if (!actor) {
    return null;
  }

  if (!withCard) {
    return (
      <ActorInformationContent
        actor={actor}
        isLoading={isLoading}
        withTitle={withTitle}
      />
    );
  }

  return (
    <Card withBorder={withBorder}>
      <ActorInformationContent
        actor={actor}
        isLoading={isLoading}
        withTitle={withTitle}
      />
    </Card>
  );
};

ActorInformation.displayName = 'ActorInformation';
