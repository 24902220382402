import React, { FC } from 'react';

import { SafeFormattedMessageWithoutSpread } from '../../../../../../formatters/SafeFormattedMessageWithoutSpread';
import messages from './messages';

import {
  $Col,
  $Footer,
  $Row,
  $Title,
} from './InterventionAppointmentHeader.styles';
import PictureCalendar from '../../../../../../atoms/Logo/PictureCalendar';
import PictureIntervention from '../../../../../../atoms/Logo/PictureIntervention';
import PictureDone from '../../../../../../atoms/Logo/PictureDone';

const InterventionAppointmentHeader: FC = () => {
  return (
    <$Row>
      <$Col xs={12} sm={4}>
        <PictureCalendar />
        <$Footer>
          <div>1</div>
          <div>
            <$Title>
              <SafeFormattedMessageWithoutSpread
                message={messages.takeAppointment}
              />
            </$Title>
            <div>
              <SafeFormattedMessageWithoutSpread
                message={messages.takeAppointmentDidactic}
              />
            </div>
          </div>
        </$Footer>
      </$Col>
      <$Col xs={12} sm={4}>
        <PictureIntervention />
        <$Footer>
          <div>2</div>
          <div>
            <$Title>
              <SafeFormattedMessageWithoutSpread
                message={messages.intervention}
              />
            </$Title>
            <div>
              <SafeFormattedMessageWithoutSpread
                message={messages.interventionDidactic}
              />
            </div>
          </div>
        </$Footer>
      </$Col>
      <$Col xs={12} sm={4}>
        <PictureDone />
        <$Footer>
          <div>3</div>
          <div>
            <$Title>
              <SafeFormattedMessageWithoutSpread
                message={messages.takeAppointmentDone}
              />
            </$Title>
            <div>
              <SafeFormattedMessageWithoutSpread
                message={messages.takeAppointmentDoneDidactic}
              />
            </div>
          </div>
        </$Footer>
      </$Col>
    </$Row>
  );
};

InterventionAppointmentHeader.displayName = 'InterventionAppointmentHeader';

export default InterventionAppointmentHeader;
