import styled from 'styled-components';

import { Checkbox } from '@savgroup-front-common/core/src/atoms/checkbox';

export const $AgreementText = styled.span`
  color: ${({ theme }) => theme.colors.mainTextColor};
`;

export const StyledCheckbox = styled(Checkbox)`
  margin: 0;
  margin-right: 8px;
`;

export const StyledLink = styled.a`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.mainTextColor};
`;
