import { Row, Col } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import { media } from '@savgroup-front-common/constants';

export const $ColContainer = styled(Col)`
  display: flex;
  flex-direction: column;
  @media ${media.maxWidth.sm} {
    flex-direction: row;
    margin-bottom: 1rem;
    margin-top: -1rem;
  }
`;

export const $StepNumber = styled(Row)`
  display: flex;
  justify-content: space-between;
  align-item: center;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primary};
  text-transform: uppercase;
  background: transparent;
  padding: 1rem;
  font-weight: 700;
  margin-left: unset;
  margin-right: unset;
  margin-bottom: 1rem;

  svg {
    margin-left: 10px;
    width: 18px;
    height: 18px;
  }

  @media ${media.maxWidth.sm} {
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
`;
