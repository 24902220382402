import React, { FC, Ref } from 'react';
import { useIsOrderLineEligible } from './OrderLine.hooks';
import { ReturnEligibility } from '../../../../../../api/Owner/startOrContinueBusinessReturnCommand';
import { Col } from 'react-styled-flexboxgrid';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { H3 } from '@savgroup-front-common/core/src/atoms/headings';
import { NumberInputHookForm } from '@savgroup-front-common/core/src/molecules/Form';
import { Tag } from '@savgroup-front-common/core/src/atoms/Tag';
import { Bold } from '@savgroup-front-common/core/src/atoms/Bold';
import { DeleteIcon } from '@savgroup-front-common/core/src/protons/icons';
import { BUTTON_TYPES, INPUT_TYPES } from '@savgroup-front-common/constants';
import { useFormContext, useWatch } from 'react-hook-form';
import { CartManagerForm } from '../CartManager.types';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import messages from './messages';
import { $Row } from './OrderLine.styles';
import { useRemoveProductFromBusinessReturn } from '../CartManager.hooks';
import AnimatedRenderer from '@savgroup-front-common/core/src/atoms/AnimatedRenderer/AnimatedRenderer';
import { useDebounce } from 'react-use';

interface OrderLineProps {
  product: {
    orderLineReference: string;
    modelId: string;
    modelName: string;
    batchQuantity?: number;
  };
  eligibility: ReturnEligibility;
  onUpdateQuantity: ({
    modelId,
    batchQuantity,
  }: {
    modelId: string;
    batchQuantity: number;
  }) => void;
  index: number;
  forwardedRef: Ref<HTMLInputElement>;
}

const OrderLine: FC<OrderLineProps> = ({
  product,
  eligibility,
  onUpdateQuantity,
  index,
  forwardedRef,
}) => {
  const isEligible = useIsOrderLineEligible({
    orderLineReference: product.orderLineReference,
    eligibility,
  });

  const formContext = useFormContext<CartManagerForm>();
  const {
    formState: { errors },
    register,
  } = formContext;

  const { handleRemoveProduct, isRemoveProductLoading } =
    useRemoveProductFromBusinessReturn({ formContext });

  const watchedValue = useWatch({
    name: `product.${product.modelId}.batchQuantity`,
  });

  useDebounce(
    () => {
      if (watchedValue !== undefined) {
        onUpdateQuantity({
          modelId: product.modelId,
          batchQuantity: parseInt(watchedValue, 10),
        });
      }
    },
    500,
    [watchedValue],
  );

  return (
    <AnimatedRenderer isOpen>
      <$Row key={product.orderLineReference}>
        <Col xs={8} sm={9}>
          <H3>
            <Bold>{product.modelName}</Bold>
          </H3>
          {isEligible && (
            <Tag success withoutMargin>
              <SafeFormattedMessageWithoutSpread
                message={messages.isEligible}
              />
            </Tag>
          )}
          {!isEligible && (
            <Tag danger withoutMargin>
              <SafeFormattedMessageWithoutSpread
                message={messages.isNotEligible}
              />
            </Tag>
          )}
        </Col>
        <Col xs={4} sm={2}>
          <NumberInputHookForm
            key={product.orderLineReference}
            name={`product.${product.modelId}.batchQuantity`}
            errors={errors}
            min={0}
            selectAllOnFocus
            register={register}
            defaultValue={product.batchQuantity || 1}
            type={INPUT_TYPES.NUMBER}
            tabIndex={index}
            ref={forwardedRef}
          />
        </Col>
        <Col xs={false} sm={1}>
          <Button
            secondary
            icon={<DeleteIcon />}
            type={BUTTON_TYPES.BUTTON}
            isLoading={isRemoveProductLoading}
            onClick={async () => {
              await handleRemoveProduct({ modelId: product.modelId });
            }}
          />
        </Col>
      </$Row>
    </AnimatedRenderer>
  );
};

OrderLine.displayName = 'OrderLine';

export default React.forwardRef<
  HTMLInputElement,
  Omit<OrderLineProps, 'forwardedRef'>
>((props, ref) => <OrderLine {...props} forwardedRef={ref} />);
