import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  CustomerFileSummary,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.workflow}files/customerfile/${COMMON_QUERY_PARAMS.FILE_ID}/summary`;

interface GetCustomerFileFullSummaryQueryPayload {
  fileId: string;
}

type GetCustomerFileFullSummaryQuery = (
  payload: GetCustomerFileFullSummaryQueryPayload,
) => Promise<BackResponse<CustomerFileSummary> | BackResponseFailure>;

const getCustomerFileFullSummaryQuery: GetCustomerFileFullSummaryQuery =
  async ({ fileId }) => {
    try {
      return await apiCall<BackResponse<CustomerFileSummary>>(
        ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId),
        {
          method: SUPPORTED_METHODS.GET,
        },
      );
    } catch (exception) {
      return prepareResponseFailure(exception);
    }
  };

export default getCustomerFileFullSummaryQuery;
