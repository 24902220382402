import styled from 'styled-components';

import { rem } from '../../helpers';
import { getThemeFromProps } from '../../theme/helpers';

const colorBasedOnProps = (props) => {
  const theme = getThemeFromProps(props);

  if (props.success) {return theme.colors.success;}
  if (props.danger) {return theme.colors.danger;}

  return theme.colors.paragraphTextColor;
};

const Paragraph = styled.p.attrs(() => ({
  role: 'paragraph',
}))`
  font-size: ${rem('14px')};
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  white-space: pre-line;
  padding-top: ${rem('6px')};
  padding-bottom: ${rem('6px')};
  color: ${colorBasedOnProps};
  text-align: ${(props) => props.align};
`;

export default Paragraph;
