import { fromJS } from 'immutable';
import get from 'lodash/get';
import reduce from 'lodash/reduce';

import * as ActionTypes from '../actionTypes';

function onFileExplodeLoading(state, { meta }) {
  const fileId = get(meta, ['fileId']);

  return state.setIn(
    [fileId],
    fromJS({ isLoaded: false, isDirty: false, value: fromJS({}) }),
  );
}

function onFileExplodeErrored(state, { meta }) {
  const fileId = get(meta, ['fileId']);

  return state.updateIn([fileId], (oldValue) =>
    oldValue.setIn(['isDirty'], true).setIn(['hasErrors'], true),
  );
}

function onFileExploded(state, { payload, meta }) {
  const fileId = get(meta, ['fileId']);
  const fileIds = get(payload, ['value'], []);
  const fileMap = reduce(
    fileIds,
    (acc, itemFileId) => acc.set(itemFileId, fromJS({ id: itemFileId })),
    fromJS({}),
  );

  return state.setIn(
    [fileId],
    fromJS({ isLoaded: true, isDirty: false }).setIn(['value'], fileMap),
  );
}

function onUpdateExplodeFileState(state, { payload }) {
  const explodedFileId = get(payload, ['explodedFileId']);
  const childFileId = get(payload, ['childFileId']);
  const updatedFileState = get(payload, ['updatedFileState']);

  return state.updateIn([explodedFileId, 'value', childFileId], (oldValue) =>
    oldValue.merge(updatedFileState),
  );
}

export default function reducer(state = fromJS({}), action) {
  switch (action.type) {
    case ActionTypes.EXPLODE_FILE.STARTED: {
      return onFileExplodeLoading(state, action);
    }

    case ActionTypes.EXPLODE_FILE.SUCCEEDED: {
      return onFileExploded(state, action);
    }

    case ActionTypes.EXPLODE_FILE.ERRORED: {
      return onFileExplodeErrored(state, action);
    }

    case ActionTypes.UPDATE_EXPLODE_FILE_STATE: {
      return onUpdateExplodeFileState(state, action);
    }
    default: {
      return state;
    }
  }
}
