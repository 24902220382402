import { OwnerProductSummary } from '@savgroup-front-common/types';
import { OwnerProductSummaryDto } from '../HomePage.types';

export const adaptCustomerProducts = (
  products: OwnerProductSummary[],
): OwnerProductSummaryDto[] => {
  return products.map((product) => ({
    ...product,
    buyDate: product.buyDate ? new Date(product.buyDate) : undefined,
  }));
};
