import { RecipientType } from '@savgroup-front-common/types';

import messages from './messages/recipientTypeMessages';

export const getRecipientTypeMessage = (recipientType?: RecipientType) => {
  if (!recipientType) {
    return messages.owner;
  }

  switch (recipientType) {
    case RecipientType.INSURER:
      return messages.insurer;
    case RecipientType.OWNER:
      return messages.owner;
    case RecipientType.SUPPLIER:
      return messages.supplier;
    case RecipientType.SELLER:
      return messages.reseller;
    case RecipientType.MANUFACTURER:
      return messages.manufacturer;
    case RecipientType.CARRIER:
      return messages.carrier;
    case RecipientType.FILE_ACTOR:
      return messages.fileActor;
    case RecipientType.PURCHASING_DEPARTMENT:
      return messages.purchasingDepartment;
    case RecipientType.STORE:
      return messages.store;
    case RecipientType.SITE:
      return messages.site;
    case RecipientType.WAREHOUSE:
      return messages.warehouse;
    case RecipientType.SERVICE_CENTER:
      return messages.serviceCenter;
    case RecipientType.UNDEFINED:
      return messages.undefined;
    default:
      throw new Error(`Insuported recipient type ${recipientType}`);
  }
};
