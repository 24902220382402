import { APIConfiguration } from '@savgroup-front-common/configuration';
import { ADDITIONAL_INFORMATION_TYPES } from '@savgroup-front-common/types';
import { AdditionalInformationExtended, Reason } from 'myaccount/types';

interface ReasonAdapterArgs {
  reasonSelected?: Reason;
  claimIds: string[];
}

export const buildFileUploadEndpoints = ({
  claimIds,
  id,
}: {
  claimIds: string[];
  id: string;
}): Record<string, { uploadEndpoint: string; deleteEndpoint: string }> => {
  return claimIds.reduce((acc, claimId) => {
    return {
      ...acc,
      [claimId]: {
        uploadEndpoint: `${APIConfiguration.claim}claims/${claimId}/additionalInformation/${id}/file`,
        deleteEndpoint: `${APIConfiguration.claim}claims/${claimId}/additionalInformation/${id}/file`,
      },
    };
  }, {});
};

export const reasonAdapter = ({
  reasonSelected,
  claimIds,
}: ReasonAdapterArgs) => {
  const newNeededInformation =
    reasonSelected?.neededInformation?.reduce<AdditionalInformationExtended[]>(
      (acc, curr) => {
        const { id, type } = curr;

        const isFileType =
          type === ADDITIONAL_INFORMATION_TYPES.FILE ||
          type === ADDITIONAL_INFORMATION_TYPES.MULTI_FILES;

        return [
          ...acc,
          {
            ...curr,
            internalId: id,
            fileUploadEndpoints: isFileType
              ? buildFileUploadEndpoints({
                  claimIds,
                  id,
                })
              : undefined,
            claimIds,
          },
        ];
      },
      [],
    ) || [];

  return {
    ...reasonSelected,
    neededInformation: newNeededInformation,
  };
};
