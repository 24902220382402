import { Link as ReactRouterLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { media } from '@savgroup-front-common/constants/src/shared';

import { transitionRightOrLeft } from '../../animations/complexeStateAnimation';
import { simpleScaleInAnimation } from '../../animations/simpleAnimation';
import { rem } from '../../helpers';

export const $TabViewTransitionContainer = styled.div<{ $isNewUi?: boolean }>`
  position: relative;
  padding-top: ${({ $isNewUi }) => ($isNewUi ? rem(24) : undefined)};
`;

export const $TabViewAnimation = styled.div`
  ${transitionRightOrLeft()};
`;
interface ItemAppliedProps {
  $count: number;
}

const itemApplied = css<ItemAppliedProps>`
  &::before {
    content: '${({ $count }) => $count}';
    position: absolute;
    top: -${rem(7)};
    right: -${rem(7)};
    display: inline-block;
    color: ${({ theme }) => theme.colors.primary};
    font-weight: ${({ theme }) => theme.fonts.weight.bold};
    font-size: ${rem(12)};
    ${simpleScaleInAnimation};
  }
`;

interface $RouteTabLinkProps {
  $isActive: boolean;
  $isHidden?: boolean;
  $count: number;
  $level: 1 | 2;
  $isNewUiEnabled?: boolean;
}
export const $RouteTabLink = styled(ReactRouterLink)<$RouteTabLinkProps>`
  @media ${media.maxWidth.xs} {
    &&& {
      font-size: 1rem;
    }
  }

  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  color: ${({ theme, $isNewUiEnabled }) =>
    $isNewUiEnabled
      ? theme.newUI.defaultColors.text
      : theme.colors.mainTextColor};
  font-size: ${({ $level }) => ($level === 1 ? rem(18) : rem(15))};
  font-weight: ${({ theme, $isActive }) =>
    $isActive ? theme.fonts.weight.bold : theme.fonts.weight.normal};
  line-height: ${rem(21)};
  position: relative;
  cursor: pointer;
  padding-bottom: 1rem;

  &:hover {
    outline: 0;
    color: ${({ theme, $isNewUiEnabled }) =>
      $isNewUiEnabled
        ? theme.newUI.defaultColors.primary
        : theme.colors.primary};
    text-decoration: unset;

    svg {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  ${({ $count, $isNewUiEnabled }) =>
    $count > 0 && !$isNewUiEnabled ? itemApplied : null}
`;

interface $MessageWithIconProps {
  $isActive?: boolean;
}

export const $MessageWithIcon = styled.div<$MessageWithIconProps>`
  align-items: center;
  display: flex;
  font-size: 14px;

  svg {
    width: 20px;
    height: 28px;
    margin-right: 7px;
    color: ${({ theme, $isActive }) =>
      $isActive ? theme.colors.primary : theme.colors.paragraphTextColor};
  }

  &:hover,
  &:focus {
    svg {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const $RouteTabSize = styled.div<{ $isNewUiEnabled?: boolean }>`
  padding-right: ${({ $isNewUiEnabled }) => ($isNewUiEnabled ? '16px' : null)};
  padding-left: ${({ $isNewUiEnabled }) => ($isNewUiEnabled ? '16px' : null)};
  display: flex;
`;

export const $Counter = styled.div`
  margin-left: 8px;
  border-radius: ${({ theme }) => theme.newUI.borders.smallRadius};
  background-color: ${({ theme }) => theme.newUI.variationColors?.light4};
  color: ${({ theme }) => theme.newUI.variationColors?.dark1};
  margin-bottom: 1rem;
  padding: 1px 6px 0px 6px;
  font-size: ${({ theme }) => theme.fonts.size.ultraMini};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  text-transform: uppercase;
`;
