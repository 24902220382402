import { useQuery } from 'react-query';

import { Product } from '@savgroup-front-common/types';
import { CatalogService } from 'myaccount/api';

interface UseChooseProductCardArgs {
  product: Product;
}

const GetModelDetail = 'getModelDetail';

const useGetModelDetailByIdQuery = ({ product }: UseChooseProductCardArgs) => {
  const { data: modelDetail, isLoading } = useQuery(
    [GetModelDetail, { product }],
    async () => {
      const { modelId } = product;

      if (!modelId) {
        return undefined;
      }

      const response = await CatalogService.getModelByIdQuery({
        modelId,
      });

      if (response.failure) {
        return undefined;
      }

      return response.value;
    },
    { staleTime: Infinity },
  );

  return {
    modelDetail,
    isLoading,
  };
};

export default useGetModelDetailByIdQuery;
