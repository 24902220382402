import React, { FunctionComponent } from 'react';

import { $ButtonGroup } from './ButtonGroup.styles';

interface ButtonGroupProps {
  isFluid?: boolean;
  withMargin?: boolean;
}

const ButtonGroup: FunctionComponent<
  React.PropsWithChildren<ButtonGroupProps>
> = ({ children, isFluid = false , withMargin= true}) => {
  return <$ButtonGroup $isFluid={isFluid} $withMargin={withMargin}>{children}</$ButtonGroup>;
};

ButtonGroup.displayName = 'ButtonGroup';

export default ButtonGroup;
