import { defineMessages } from 'react-intl';

export default defineMessages({
  serialNumber: {
    id: 'components.myaccount.resumeCard.serialNumber',
    defaultMessage: 'Serial number: {serialNumber}',
  },
  noSerialNumber: {
    id: 'components.myaccount.resumeCard.noSerialNumber',
    defaultMessage: 'serial number is empty',
  },
  resumeButton: {
    id: 'components.myaccount.resumeCardButton.button',
    defaultMessage: 'Order detail',
  },
});
