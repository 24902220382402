import { useGetSellerConfiguration } from 'myaccount/view/app/hooks';

export const usePaymentCard = ({ sellerId }: { sellerId: string }) => {
  const { sellerConfiguration } = useGetSellerConfiguration({ sellerId });

  return {
    isCashRegisterConnected: sellerConfiguration?.isCashRegisterConnected,
    paymentUrl: sellerConfiguration?.paymentUrl,
  };
};
