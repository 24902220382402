import { useQuery } from 'react-query';

import { CommonWorkflowService } from '@savgroup-front-common/core/src/api';

export const GetFileStatesQuery = 'getFileStatesQuery';

export const useGetFileStatesQuery = ({ fileId }: { fileId?: string }) => {
  const { data: histories, isLoading } = useQuery(
    [GetFileStatesQuery, { fileId }],
    async () => {
      if (!fileId) {
        return undefined;
      }

      const response = await CommonWorkflowService.getFileStatesQuery({
        fileId,
      });

      if (response.failure) {
        throw new Error(response.errors[0]?.message);
      }

      return response.value;
    },
    {
      staleTime: 500,
      refetchInterval: 2000,
      refetchIntervalInBackground: true,
      keepPreviousData: true,
    },
  );

  return {
    histories,
    isLoading,
  };
};
