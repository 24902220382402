import React, { FC, PropsWithChildren } from 'react';

import { RouteTabConfig } from './RouteTabs.types';
import RouteTabsBody from './RouteTabsBody';
import RouteTabsNav from './RouteTabsNav';

interface RouteTabsProps {
  config: RouteTabConfig[];
  dataTestId?: string;
  hideSingleTabs?: boolean;
  level?: 1 | 2;
  fluid?: boolean;
  hasBottomBorder?: boolean;
  hideButton?: boolean;
}

const RouteTabs: FC<PropsWithChildren<RouteTabsProps>> = ({
  config,
  dataTestId,
  hideSingleTabs = true,
  level = 1,
  fluid = false,
  hasBottomBorder = false,
  hideButton = false,
  children,
}) => {
  const showTabs =
    (hideSingleTabs === true && config.length > 1) || hideSingleTabs === false;

  return (
    <>
      {showTabs && (
        <RouteTabsNav
          level={level}
          config={config}
          dataTestId={dataTestId}
          fluid={fluid}
          hasBottomBorder={hasBottomBorder}
          hideButton={hideButton}
        />
      )}
      {children}
      <RouteTabsBody config={config} />
    </>
  );
};

RouteTabs.displayName = 'RouteTabs';

export default RouteTabs;
