import React, { FunctionComponent, useState } from 'react';

import { Collapse } from '@savgroup-front-common/core/src/atoms/Collapse';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import {
  ChevronDownIcon,
  ChevronUpIcon,
} from '@savgroup-front-common/core/src/protons/icons';
import { FileStateHistorySummary } from 'myaccount/api/Workflow/getFileStatesQuery';

import {
  $CollapsableButton,
  $Divider,
  $FileHeader,
  $Header,
  $ItemWrapper,
  $Wrapper,
} from './FileHistory.styles';
import FileHistoryCardRow from './FileHistoryItem/FileHistoryItem';
import messages from './messages';

const FileHistory: FunctionComponent<{
  histories: FileStateHistorySummary[] | undefined;
}> = ({ histories }) => {
  const [isCollapsed, setCollapsable] = useState(true);

  return (
    <$Wrapper>
      <$Header>
        <$FileHeader>
          <SafeFormattedMessageWithoutSpread message={messages.titleCard} />
        </$FileHeader>
        <$CollapsableButton onClick={() => setCollapsable(!isCollapsed)}>
          {!isCollapsed && <ChevronDownIcon />}
          {isCollapsed && <ChevronUpIcon />}
        </$CollapsableButton>
      </$Header>
      <$Divider />
      <Collapse isOpen={isCollapsed}>
        {histories?.map((history) => (
          <FileHistoryCardRow
            key={history.stateDate.toString()}
            history={history}
          />
        ))}
        {histories?.length === 0 && (
          <$ItemWrapper>
            <SafeFormattedMessageWithoutSpread
              message={messages.emptyHistory}
            />
          </$ItemWrapper>
        )}
      </Collapse>
    </$Wrapper>
  );
};

FileHistory.displayName = 'FileHistory';
export default FileHistory;
