import { Col } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

export const $Heading = styled.div`
  font-weight: 800;
  font-size: 24px;
  line-height: 28px;
  color: ${({ theme }) => theme.systemColors.defaultTextColor};
  margin-bottom: 3rem;
`;

export const $CardContainer = styled.div`
  margin-top: 2rem;
`;

export const $Col = styled(Col)`
  margin-top: 2rem;
`;
