import React, {
  createContext,
  FC,
  useCallback,
  PropsWithChildren,
  useReducer,
} from 'react';
import { PROFESSIONAL_STEP_CONFIG } from 'myaccount/view/app/NewLayout/ProfessionalRoutes.config';
import {
  ACTION_TYPES,
  initProfessionalPagesContextReducer,
  professionalPagesContextReducer,
} from './ProfessionalPages.reducer';

interface ProfessionalPagesContextState {
  skippedSteps: Record<string, boolean>;
}

type ProfessionalPagesContextProviderProps = ProfessionalPagesContextState & {
  onSkipStep: (step: PROFESSIONAL_STEP_CONFIG) => void;
  onSkipSteps: (steps: PROFESSIONAL_STEP_CONFIG[]) => void;
  onReset: () => void;
};

export const ProfessionalPagesContextProvider =
  createContext<ProfessionalPagesContextProviderProps>({
    skippedSteps: {},
    onSkipStep: () => undefined,
    onSkipSteps: () => undefined,
    onReset: () => undefined,
  });

export const ProfessionalPagesProvider: FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(
    professionalPagesContextReducer,
    initProfessionalPagesContextReducer(),
  );

  const onSkipStep = useCallback(
    (step: PROFESSIONAL_STEP_CONFIG) => {
      dispatch({
        type: ACTION_TYPES.ON_SKIP_STEP,
        payload: { step },
      });
    },
    [dispatch],
  );
  const onSkipSteps = useCallback(
    (steps: PROFESSIONAL_STEP_CONFIG[]) => {
      dispatch({
        type: ACTION_TYPES.ON_SKIP_STEPS,
        payload: { steps },
      });
    },
    [dispatch],
  );

  const onReset = useCallback(() => {
    dispatch({
      type: ACTION_TYPES.ON_RESET,
    });
  }, [dispatch]);

  return (
    <ProfessionalPagesContextProvider.Provider
      value={{ ...state, onReset, onSkipStep, onSkipSteps }}
    >
      {children}
    </ProfessionalPagesContextProvider.Provider>
  );
};

ProfessionalPagesProvider.displayName = 'ProfessionalPagesProvider';
