function setAuthority() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return this;
}

const AuthConfiguration = {
  authority: 'http://localhost:5000',
  clientId: import.meta.env.VITE_CLIENTID
    ? import.meta.env.VITE_CLIENTID
    : 'local-revers.io',
  scope: 'openid email api',
  setAuthority,
};

export default AuthConfiguration;
