import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  OwnerProductSummary,
} from '@savgroup-front-common/types';

 
const ENDPOINT = `${APIConfiguration.owner}owners/${COMMON_QUERY_PARAMS.OWNER_ID}/products`;

interface GetOrderByOwnerIdPayload {
  ownerId: string;
  orderId?: string;
}

const listOwnerProductsQuery = async ({
  ownerId,
  orderId,
}: GetOrderByOwnerIdPayload): Promise<
  BackResponse<OwnerProductSummary[]> | BackResponseFailure
> => {
  try {
    const url = buildUrl(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.OWNER_ID, ownerId),
      { orderId },
    );

    const response = await apiCall<BackResponse<OwnerProductSummary[]>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default listOwnerProductsQuery;
