import React, { FC } from 'react';

import { NOTIFICATION_TYPES } from '@savgroup-front-common/constants';
import { SALESFORCE_INTEGRATION_TYPE } from '@savgroup-front-common/types';

import { ActionRow } from '../../../atoms/ActionRow';
import { Banner } from '../../../atoms/Banners';
import { SafeFormattedMessageWithoutSpread } from '../../../formatters';
import { TakeInterventionAppointment } from '../components/TakeInterventionAppointment';
import { useGetSalesforceContextSummary } from '../hooks';
import { TakeInterventionAppointmentLegacy } from '../legacy/TakeInterventionAppointmentLegacy';

import messages from './messages';

interface TakeInterventionAppointmentContentProps {
  fileId: string;
  sellerId: string;
  onSubmit: () => void;
  isLoading?: boolean;
}

const TakeInterventionAppointmentContent: FC<
  React.PropsWithChildren<TakeInterventionAppointmentContentProps>
> = ({ children, fileId, sellerId, onSubmit, isLoading }) => {
  const { sellerConfiguration } = useGetSalesforceContextSummary({
    fileId,
    sellerId,
  });
  const isDeprecated =
    sellerConfiguration?.salesforceFieldServiceLightningIntegrationType ===
    SALESFORCE_INTEGRATION_TYPE.Deprecated;
  const isStandardOrDemoMode =
    sellerConfiguration?.salesforceFieldServiceLightningIntegrationType ===
      SALESFORCE_INTEGRATION_TYPE.Standard ||
    sellerConfiguration?.salesforceFieldServiceLightningIntegrationType ===
      SALESFORCE_INTEGRATION_TYPE.Integration;
  const isNone =
    sellerConfiguration?.salesforceFieldServiceLightningIntegrationType ===
      SALESFORCE_INTEGRATION_TYPE.None ||
    !sellerConfiguration?.salesforceFieldServiceLightningIntegrationType;

  return (
    <>
      {isNone && (
        <>
          <Banner isOpen notificationType={NOTIFICATION_TYPES.ERROR}>
            <SafeFormattedMessageWithoutSpread
              message={messages.integrationTypeIsNotConfigured}
            />
          </Banner>
          <ActionRow>{children}</ActionRow>
        </>
      )}

      {isDeprecated && (
        <TakeInterventionAppointmentLegacy
          fileId={fileId}
          onSubmit={onSubmit}
          isLoading={isLoading}
        >
          {children}
        </TakeInterventionAppointmentLegacy>
      )}

      {isStandardOrDemoMode && (
        <TakeInterventionAppointment
          fileId={fileId}
          sellerId={sellerId}
          onSubmit={onSubmit}
          isLoading={isLoading}
        >
          {children}
        </TakeInterventionAppointment>
      )}
    </>
  );
};

TakeInterventionAppointmentContent.displayName =
  'TakeInterventionAppointmentContent';

export default TakeInterventionAppointmentContent;
