import React from 'react';

import { useLogo } from './hooks/useLogo';
import { $LogoImg } from './SellerLogo.styles';

interface LogoProps {
  sellerId: string;
  clientId: string;
}

export const SellerLogo: React.FC<LogoProps> = ({ sellerId, clientId }) => {
  const { logoUrl, imgRef, onError } = useLogo({ sellerId, clientId });

  return <$LogoImg ref={imgRef} alt="logo" src={logoUrl} onError={onError} />;
};

SellerLogo.displayName = 'SellerLogo';
