import { logVerbose } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { HANDLING_UNIQUE_NAME } from '@savgroup-front-common/constants';
import { MessageType } from '@savgroup-front-common/types';
import messages from './messages';

const getHandlingData = ({
  carrierUniqueName,
}: {
  carrierUniqueName: HANDLING_UNIQUE_NAME;
}): {
  label: MessageType | string;
  highlighting: MessageType | string | undefined;
  description: MessageType | string;
} => {
  switch (carrierUniqueName) {
    case HANDLING_UNIQUE_NAME.RELAY_POINT_DEPOSIT: {
      return {
        label: messages.relayPointDeposit,
        highlighting: undefined,
        description: messages.relayPointDepositDescription,
      };
    }
    case HANDLING_UNIQUE_NAME.POST_OFFICE_DEPOT: {
      return {
        label: messages.postOfficeDepot,
        highlighting: undefined,
        description: messages.postOfficeDepotDescription,
      };
    }
    case HANDLING_UNIQUE_NAME.ON_SITE_COLLECTION: {
      return {
        label: messages.onSiteCollection,
        highlighting: undefined,
        description: messages.onSiteCollectionDescription,
      };
    }
    case HANDLING_UNIQUE_NAME.HOME_DELIVERY: {
      return {
        label: messages.homeDelivery,
        highlighting: undefined,
        description: messages.homeDeliveryDescription,
      };
    }
    case HANDLING_UNIQUE_NAME.PICKUP_COUNTER: {
      return {
        label: messages.pickupCounter,
        highlighting: undefined,
        description: messages.pickupCounterDescription,
      };
    }
    case HANDLING_UNIQUE_NAME.HOME_PICKUP: {
      return {
        label: messages.homePickup,
        highlighting: undefined,
        description: messages.homePickupDescription,
      };
    }
    case HANDLING_UNIQUE_NAME.POST_OFFICE_DELIVERY: {
      return {
        label: messages.postOfficeDelivery,
        highlighting: undefined,
        description: messages.postOfficeDeliveryDescription,
      };
    }
    case HANDLING_UNIQUE_NAME.PICKUP_STATION_DELIVERY: {
      return {
        label: messages.pickUpStationDelivery,
        highlighting: undefined,
        description: messages.pickUpStationDeliveryDescription,
      };
    }
    case HANDLING_UNIQUE_NAME.DROP_AT_STORE_DEPOSIT: {
      return {
        label: messages.dropAtStoreDeposit,
        highlighting: messages.dropAtStoreDepositHighlighting,
        description: messages.dropAtStoreDepositDescription,
      };
    }
    case HANDLING_UNIQUE_NAME.SELLER_PROVIDED_CARRIER: {
      return {
        label: messages.sellerProvidedCarrier,
        highlighting: undefined,
        description: messages.sellerProvidedCarrierDescription,
      };
    }
    case HANDLING_UNIQUE_NAME.SELLER_PROVIDED_CARRIER_2: {
      return {
        label: messages.sellerProvidedCarrier2,
        highlighting: undefined,
        description: messages.sellerProvidedCarrier2Description,
      };
    }
    case HANDLING_UNIQUE_NAME.SELLER_PROVIDED_CARRIER_3: {
      return {
        label: messages.sellerProvidedCarrier3,
        highlighting: undefined,
        description: messages.sellerProvidedCarrier3Description,
      };
    }
    case HANDLING_UNIQUE_NAME.EXTERNAL_HOME_PICKUP: {
      return {
        label: messages.externalHomePickup,
        highlighting: undefined,
        description: messages.externalHomePickupDescription,
      };
    }
    case HANDLING_UNIQUE_NAME.IMMEDIATE_DROP_AT_STORE_DEPOSIT: {
      return {
        label: messages.immediateDropAtStoreDeposit,
        highlighting: undefined,
        description: messages.immediateDropAtStoreDepositDescription,
      };
    }
    case HANDLING_UNIQUE_NAME.IMMEDIATE_PICKUP_AT_STORE_DELIVERY: {
      return {
        label: messages.immediatePickUpAtStoreDelivery,
        highlighting: messages.immediatePickUpAtStoreDeliveryHighlighting,
        description: messages.immediatePickUpAtStoreDeliveryDescription,
      };
    }
    case HANDLING_UNIQUE_NAME.SELF_DEPOSIT_CARRIER: {
      return {
        label: messages.selfDepositCarrier,
        highlighting: undefined,
        description: messages.selfDepositCarrierDescription,
      };
    }
    case HANDLING_UNIQUE_NAME.PICKUP_STORE_DELIVERY: {
      return {
        label: messages.pickUpStoreDelivery,
        highlighting: messages.pickUpStoreDeliveryHighlighting,
        description: messages.pickUpStoreDeliveryDescription,
      };
    }
    case HANDLING_UNIQUE_NAME.HOME_INTERVENTION: {
      return {
        label: messages.homeIntervention,
        highlighting: undefined,
        description: messages.homeInterventionDescription,
      };
    }

    default:
      logVerbose(`handlingCard: missing trad`, { carrierUniqueName });

      return {
        label: carrierUniqueName,
        highlighting: undefined,
        description: '',
      };
  }
};

export default getHandlingData;
