import React, { PropsWithChildren } from 'react';
import { Row } from 'react-styled-flexboxgrid';
import StepFooter from './StepFooter/StepFooter';
import StepHeader from './StepHeader/StepHeader';
import { $ColContainer, $StepContainer } from './StepLayout.styles';
import { PROFESSIONAL_STEP_CONFIG } from '../ProfessionalRoutes.config';

interface StepLayoutProfessionalProps {
  onSubmit?: () => void;
  handlePreviousStep?: () => void;
  stepName?: PROFESSIONAL_STEP_CONFIG;
  isDisabled?: boolean;
  isPreviousButtonDisabled?: boolean;
  customSteps?: Record<string, string[]>[];
  noConfirmButton?: boolean;
  isFinalStep?: boolean;
  hasFooter?: boolean;
  isLoading?: boolean;
  minHeight?: boolean;
}

const StepLayoutProfessional: React.FC<
  PropsWithChildren<StepLayoutProfessionalProps>
> = ({
  children,
  onSubmit = () => undefined,
  handlePreviousStep = () => undefined,
  stepName = undefined,
  isDisabled = false,
  isPreviousButtonDisabled = false,
  customSteps = undefined,
  noConfirmButton = false,
  isFinalStep = false,
  hasFooter = true,
  isLoading = false,
  minHeight = undefined,
}) => {
  const shouldDisplayFooter = hasFooter && !isFinalStep;

  return (
    <Row center="xs">
      <$ColContainer xs={12} sm={12} enableServicePortal>
        {stepName && (
          <StepHeader
            stepName={stepName}
            isFinalStep={isFinalStep}
            customSteps={customSteps}
          />
        )}
        <$StepContainer $minHeight={minHeight}>{children}</$StepContainer>
        {shouldDisplayFooter && (
          <StepFooter
            onSubmit={onSubmit}
            handlePreviousStep={handlePreviousStep}
            isDisabled={isDisabled}
            noConfirmButton={noConfirmButton}
            isLoading={isLoading}
            enableServicePortal
            isPreviousButtonDisabled={isPreviousButtonDisabled}
          >
            <div id="secondaryAction" />
          </StepFooter>
        )}
      </$ColContainer>
    </Row>
  );
};

StepLayoutProfessional.displayName = 'StepLayoutProfessional';

export default StepLayoutProfessional;
