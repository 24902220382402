import { defineMessages } from 'react-intl';

export default defineMessages({
  relayPointDeposit: {
    id: 'components.myaccount.claims.handling.cards.relayPointDeposit',
    defaultMessage: 'relayPointDeposit',
  },
  relayPointDepositDescription: {
    id: 'components.myaccount.claims.handling.cards.relayPointDepositDescription',
    defaultMessage: 'relayPointDepositDescription',
  },
  postOfficeDepot: {
    id: 'components.myaccount.claims.handling.cards.postOfficeDepot',
    defaultMessage: 'postOfficeDepot',
  },
  postOfficeDepotDescription: {
    id: 'components.myaccount.claims.handling.cards.postOfficeDepotDescription',
    defaultMessage: 'postOfficeDepotDescription',
  },
  onSiteCollection: {
    id: 'components.myaccount.claims.handling.cards.onSiteCollection',
    defaultMessage: 'onSiteCollection',
  },
  onSiteCollectionDescription: {
    id: 'components.myaccount.claims.handling.cards.onSiteCollectionDescription',
    defaultMessage: 'onSiteCollectionDescription',
  },
  homeDelivery: {
    id: 'components.myaccount.claims.handling.cards.homeDelivery',
    defaultMessage: 'homeDelivery',
  },
  homeDeliveryDescription: {
    id: 'components.myaccount.claims.handling.cards.homeDeliveryDescription',
    defaultMessage: 'homeDeliveryDescription',
  },
  pickupCounter: {
    id: 'components.myaccount.claims.handling.cards.pickupCounter',
    defaultMessage: 'pickupCounter',
  },
  pickupCounterDescription: {
    id: 'components.myaccount.claims.handling.cards.pickupCounterDescription',
    defaultMessage: 'pickupCounterDescription',
  },
  homePickup: {
    id: 'components.myaccount.claims.handling.cards.homePickup',
    defaultMessage: 'homePickup',
  },
  homePickupDescription: {
    id: 'components.myaccount.claims.handling.cards.homePickupDescription',
    defaultMessage: 'homePickupDescription',
  },
  postOfficeDelivery: {
    id: 'components.myaccount.claims.handling.cards.postOfficeDelivery',
    defaultMessage: 'postOfficeDelivery',
  },
  postOfficeDeliveryDescription: {
    id: 'components.myaccount.claims.handling.cards.postOfficeDeliveryDescription',
    defaultMessage: 'postOfficeDeliveryDescription',
  },
  pickUpStationDelivery: {
    id: 'components.myaccount.claims.handling.cards.pickUpStationDelivery',
    defaultMessage: 'pickUpStationDelivery',
  },
  pickUpStationDeliveryDescription: {
    id: 'components.myaccount.claims.handling.cards.pickUpStationDeliveryDescription',
    defaultMessage: 'pickUpStationDeliveryDescription',
  },
  dropAtStoreDeposit: {
    id: 'components.myaccount.claims.handling.cards.dropAtStoreDeposit',
    defaultMessage: 'dropAtStoreDeposit',
  },
  dropAtStoreDepositHighlighting: {
    id: 'components.myaccount.claims.handling.cards.dropAtStoreDepositHighlighting',
    defaultMessage: 'dropAtStoreDepositHighlighting',
  },
  dropAtStoreDepositDescription: {
    id: 'components.myaccount.claims.handling.cards.dropAtStoreDepositDescription',
    defaultMessage: 'dropAtStoreDepositDescription',
  },
  sellerProvidedCarrier: {
    id: 'components.myaccount.claims.handling.cards.sellerProvidedCarrier',
    defaultMessage: 'sellerProvidedCarrier',
  },
  sellerProvidedCarrierDescription: {
    id: 'components.myaccount.claims.handling.cards.sellerProvidedCarrierDescription',
    defaultMessage: 'sellerProvidedCarrierDescription',
  },
  sellerProvidedCarrier2: {
    id: 'components.myaccount.claims.handling.cards.sellerProvidedCarrier2',
    defaultMessage: 'sellerProvidedCarrier2',
  },
  sellerProvidedCarrier2Description: {
    id: 'components.myaccount.claims.handling.cards.sellerProvidedCarrier2Description',
    defaultMessage: 'sellerProvidedCarrier2Description',
  },
  sellerProvidedCarrier3: {
    id: 'components.myaccount.claims.handling.cards.sellerProvidedCarrier3',
    defaultMessage: 'sellerProvidedCarrier3',
  },
  sellerProvidedCarrier3Description: {
    id: 'components.myaccount.claims.handling.cards.sellerProvidedCarrier3Description',
    defaultMessage: 'sellerProvidedCarrier3Description',
  },
  externalHomePickup: {
    id: 'components.myaccount.claims.handling.cards.externalHomePickup',
    defaultMessage: 'externalHomePickup',
  },
  externalHomePickupDescription: {
    id: 'components.myaccount.claims.handling.cards.externalHomePickupDescription',
    defaultMessage: 'externalHomePickupDescription',
  },
  immediateDropAtStoreDeposit: {
    id: 'components.myaccount.claims.handling.cards.immediateDropAtStoreDeposit',
    defaultMessage: 'immediateDropAtStoreDeposit',
  },
  immediateDropAtStoreDepositDescription: {
    id: 'components.myaccount.claims.handling.cards.immediateDropAtStoreDepositDescription',
    defaultMessage: 'immediateDropAtStoreDepositDescription',
  },
  immediatePickUpAtStoreDelivery: {
    id: 'components.myaccount.claims.handling.cards.immediatePickUpAtStoreDelivery',
    defaultMessage: 'immediatePickUpAtStoreDelivery',
  },
  immediatePickUpAtStoreDeliveryHighlighting: {
    id: 'components.myaccount.claims.handling.cards.immediatePickUpAtStoreDeliveryHighlighting',
    defaultMessage: 'immediatePickUpAtStoreDeliveryHighlighting',
  },
  immediatePickUpAtStoreDeliveryDescription: {
    id: 'components.myaccount.claims.handling.cards.immediatePickUpAtStoreDeliveryDescription',
    defaultMessage: 'immediatePickUpAtStoreDeliveryDescription',
  },
  selfDepositCarrier: {
    id: 'components.myaccount.claims.handling.cards.selfDepositCarrier',
    defaultMessage: 'selfDepositCarrier',
  },
  selfDepositCarrierDescription: {
    id: 'components.myaccount.claims.handling.cards.selfDepositCarrierDescription',
    defaultMessage: 'selfDepositCarrierDescription',
  },
  pickUpStoreDelivery: {
    id: 'components.myaccount.claims.handling.cards.pickUpStoreDelivery',
    defaultMessage: 'pickUpStoreDelivery',
  },
  pickUpStoreDeliveryHighlighting: {
    id: 'components.myaccount.claims.handling.cards.pickUpStoreDeliveryHighlighting',
    defaultMessage: 'pickUpStoreDeliveryHighlighting',
  },
  pickUpStoreDeliveryDescription: {
    id: 'components.myaccount.claims.handling.cards.pickUpStoreDeliveryDescription',
    defaultMessage: 'pickUpStoreDeliveryDescription',
  },
  homeIntervention: {
    id: 'components.myaccount.claims.handling.cards.homeIntervention',
    defaultMessage: 'homeIntervention',
  },
  homeInterventionDescription: {
    id: 'components.myaccount.claims.handling.cards.homeInterventionDescription',
    defaultMessage: 'homeInterventionDescription',
  },
});
