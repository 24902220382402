import React, { FC } from 'react';
import useListOwnerProductsQuery from './HomePageProducts.hooks';
import { Pagination } from '@savgroup-front-common/core/src/atoms/Pagination';
import { AnimatedRenderer } from '@savgroup-front-common/core/src/atoms/AnimatedRenderer';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import HomePageProductItem from './HomePageProductItem/HomePageProductItem';
import {
  $HomeProductsListAddProduct,
  $HomeProductsListWrapper,
} from '../HomePage.styles';
import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import messages from './messages';
import AddProduct from './AddProduct/AddProduct';

const HomePageProducts: FC = () => {
  const {
    products,
    isLoading,
    refetchListProduct,
    isOpen,
    setOpen,
    pagination,
    handleChangePage,
    handleChangeInterval,
  } = useListOwnerProductsQuery();

  if (isLoading) {
    return <BaseLoader />;
  }

  return (
    <$HomeProductsListWrapper>
      <$HomeProductsListAddProduct>
        <Button
          type={BUTTON_TYPES.BUTTON}
          primary
          onClick={() => setOpen(true)}
        >
          <SafeFormattedMessageWithoutSpread message={messages.addProduct} />
        </Button>
      </$HomeProductsListAddProduct>
      {products
        ?.slice(
          (pagination.page - 1) * pagination.pageSize,
          pagination.page * pagination.pageSize,
        )
        .map((product) => (
          <AnimatedRenderer isOpen key={product.ownerProductId}>
            <HomePageProductItem product={product} />
          </AnimatedRenderer>
        ))}
      <Pagination
        onChangePage={handleChangePage}
        pagination={pagination}
        onSelectInterval={handleChangeInterval}
        paginationInterval={[10, 25, 50]}
      />

      <AddProduct
        isOpen={isOpen}
        setOpen={setOpen}
        onAddProduct={refetchListProduct}
      />
    </$HomeProductsListWrapper>
  );
};

HomePageProducts.displayName = 'HomePageProducts';
export default HomePageProducts;
