import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  Model,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.catalog}models/${COMMON_QUERY_PARAMS.MODEL_ID}`;

interface GetRepairableByModelIdPayload {
  modelId: string;
}

async function getModelByIdQuery({
  modelId,
}: GetRepairableByModelIdPayload): Promise<
  BackResponse<Model> | BackResponseFailure
> {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.MODEL_ID, modelId);

    const response = await apiCall<BackResponse<Model>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default getModelByIdQuery;
