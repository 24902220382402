import { createAction } from '@reduxjs/toolkit';

import * as storeActionTypes from './actionTypes';

export interface GetStoresPayload {
  storeId: string;
}

export const getStore = createAction<GetStoresPayload>(
  storeActionTypes.GET_STORE.BASE,
);

export interface GetStoresBySellerIdPayload {
  sellerId?: string;
}

export const getStoresBySellerId = createAction<GetStoresBySellerIdPayload>(
  storeActionTypes.GET_STORES_BY_SELLER_ID.BASE,
);
