import { useMutation, useQueryClient } from 'react-query';

import { QUOTATION_PAYMENT_TYPE } from '@savgroup-front-common/types';
import { QuoteClientAnswer } from '@savgroup-front-common/types/src/Quotation/QuoteClientAnswer';

import { CommonRepairerService } from '../../../../api/RepairerService';
import { useToasts } from '../../../../molecules/NotificationsProvider';
import { GetQuotationsByFileIdQuery } from '../../hooks/useGetQuotationsByFileIdQuery';

interface UseValidateQuotationArgs {
  quotationId: string;
  fileId: string;
  onAnswerSuccess?: () => void;
}

interface UseValidateQuotationReturnValues {
  handleValidate: (paymentType?: QUOTATION_PAYMENT_TYPE) => void;
  isValidateLoading: boolean;
}

export const useValidateQuotation = ({
  quotationId,
  fileId,
  onAnswerSuccess,
}: UseValidateQuotationArgs): UseValidateQuotationReturnValues => {
  const queryClient = useQueryClient();
  const { removeAllNotifications, pushErrors } = useToasts();

  const { mutateAsync: handleValidate, isLoading: isValidateLoading } =
    useMutation(
      [],
      async (
        paymentType: QUOTATION_PAYMENT_TYPE = QUOTATION_PAYMENT_TYPE.IMMEDIATE,
      ) => {
        removeAllNotifications();

        const response =
          await CommonRepairerService.setQuoteClientAnswerCommand({
            quoteId: quotationId,
            clientAnswer: QuoteClientAnswer.APPROVED,
            paymentType,
          });

        if (response.failure) {
          pushErrors(response.errors);

          return undefined;
        }

        if (onAnswerSuccess) {
          onAnswerSuccess();
        }

        await queryClient.invalidateQueries({
          queryKey: [GetQuotationsByFileIdQuery, { fileId }],
        });
        await queryClient.invalidateQueries({
          queryKey: ['getInvoicesQuery', { fileId }],
        });

        return undefined;
      },
    );

  return {
    handleValidate,
    isValidateLoading,
  };
};
