import { defineMessages } from 'react-intl';

export default defineMessages({
  withoutWarranty: {
    id: 'components.myaccount.order.withoutWarranty',
    defaultMessage: 'No warranty',
  },
  withWarranty: {
    id: 'components.myaccount.order.withWarranty',
    defaultMessage: 'Under warranty',
  },
  isInProgress: {
    id: 'components.myaccount.order.isInProgress',
    defaultMessage: 'In progress',
  },
  isRepaired: {
    id: 'components.myaccount.order.isRepaired',
    defaultMessage: 'Repaired',
  },
  isRefunded: {
    id: 'components.myaccount.order.isRefunded',
    defaultMessage: 'Refunded',
  },
  isSwapped: {
    id: 'components.myaccount.order.isSwapped',
    defaultMessage: 'Swapped',
  },
  notEligible: {
    id: 'components.myaccount.order.notEligible',
    defaultMessage: 'Not eligible',
  },
  fileOpened: {
    id: 'components.myaccount.order.fileOpened',
    defaultMessage: 'Dossier en cours',
  },
  fileOpenedLink: {
    id: 'components.myaccount.order.fileOpenedLink',
    defaultMessage: 'Link',
  },
});
