import styled, { css } from 'styled-components';

import { Button } from '../button';

export const $ButtonGroupWrapper = styled.div`
  display: flex;
  margin-left: 1rem;

  & > button:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 1px solid transparent;
  }

  & > button:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export const $ListPaginationButton = styled(Button)<{
  $isNewUiEnabled?: boolean;
}>`
  &,
  &:focus,
  &:active,
  &:visited {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2px;
    gap: 10px;
    background-color: transparent;
    border: 1px solid
      ${({ theme, $isNewUiEnabled }) =>
        $isNewUiEnabled ? '#CCD2F9' : theme.colors.default};
    height: auto;
    min-height: auto;
    width: auto;
    min-width: auto;

    & > span > svg {
      color: ${({ theme, $isNewUiEnabled }) =>
        $isNewUiEnabled ? '#5369EB' : theme.colors.black};
      margin-right: 0;
    }

    &[disabled] {
      background-color: transparent;
      & > span > svg {
        color: ${({ theme }) => theme.colors.default};
      }
    }
  }

  &:hover {
    background-color: ${({ theme, $isNewUiEnabled }) =>
      $isNewUiEnabled ? '#EEF0FD' : theme.colors.secondaryColor2};
    border: 1px solid ${({ theme }) => theme.colors.default};
    &:not(:first-child) {
      border-left: 1px solid transparent;
    }
    & > span > svg {
      color: ${({ $isNewUiEnabled }) => ($isNewUiEnabled ? '#5369EB' : null)};
    }
  }
`;

export const $ListPaginationInputText = styled.div<{
  $isNewUiEnabled?: boolean;
}>`
  display: flex;
  font-style: normal;
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  font-size: ${({ theme }) => theme.fonts.size.ultraSmall};
  text-align: right;
  color: ${({ theme }) => theme.colors.paragraphTextColor};
  white-space: nowrap;
  margin-left: 0.5rem;

  ${({ $isNewUiEnabled }) =>
    $isNewUiEnabled
      ? css`
          font-size: 12px;
          color: ${({ theme }) => theme.newUI.defaultColors.secondaryText};
          font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
        `
      : null}
`;

export const $ListPaginationInterval = styled.button<{
  $isActive: boolean;
  $isNewUiEnabled?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 8px;
  gap: 10px;
  border: 1px solid
    ${({ theme, $isNewUiEnabled }) =>
      $isNewUiEnabled ? '#CCD2F9' : theme.colors.default};
  border-right: none;
  border-radius: 0;
  cursor: pointer;
  color: ${({ $isActive, $isNewUiEnabled }) =>
    $isNewUiEnabled && $isActive ? '#465AD3' : null};
  background: ${({ theme, $isActive, $isNewUiEnabled }) => {
    if ($isNewUiEnabled && $isActive) {
      return '#EEF0FD';
    }

    return $isActive ? theme.colors.secondaryColor2 : theme.colors.white;
  }};
`;

export const $ListPaginationIntervalOnlyNumber = styled.span`
  margin-right: 4px;
`;

export const $ListPaginationIntervalWrapper = styled.div`
  display: flex;
  align-items: center;

  & button:first-child {
    border-top-left-radius: 4px !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 4px !important;
  }

  & button:last-child {
    border-top-left-radius: 0;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    border-bottom-left-radius: 0;

    border-right: 1px solid ${({ theme }) => theme.colors.default};
    margin-right: 0.5rem;
  }
`;

export const $ListPaginationPage = styled.div<{
  $isNewUiEnabled?: boolean;
}>`
  display: flex;
  align-items: center;

  && input {
    width: 70px;
    text-align: center;
    height: auto;
    padding: 4px 8px;
    border-color: ${({ $isNewUiEnabled }) =>
      $isNewUiEnabled ? '#CCD2F9 !important' : null};
  }

  && > div {
    margin-bottom: 0;
  }
`;

export const $ListPaginationWrapper = styled.div`
  display: flex;
  margin-top: 1rem;
  align-items: center;
  width: 100%;
`;

export const $Spacer = styled.div`
  flex: 1;
`;

export const $PaginationPerPage = styled.span<{ $isNewUiEnabled?: boolean }>`
  ${({ $isNewUiEnabled }) =>
    $isNewUiEnabled
      ? css`
          font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
          color: ${({ theme }) => theme.newUI.defaultColors.secondaryText};
          font-size: 12px;
        `
      : null}
`;
