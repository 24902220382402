import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  OrderDetails,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.owner}orders/${COMMON_QUERY_PARAMS.ORDER_ID}/details`;

interface GetOrderDetailsQueryPayload {
  orderId: string;
}

const getOrderDetailsQuery = async ({
  orderId,
}: GetOrderDetailsQueryPayload): Promise<
  BackResponse<OrderDetails> | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.ORDER_ID, orderId);

    const response = await apiCall<BackResponse<OrderDetails>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default getOrderDetailsQuery;
