import styled from 'styled-components';

import { Button } from '@savgroup-front-common/core/src/atoms/button';

export const $SubmitButton = styled(Button)`
  padding: 1rem;
  min-width: 172px;

  &[disabled] {
    opacity: 0.5;
  }
`;

export const $ReturnProductFormContainer = styled.div`
  width: 100%;
`;

export const $SubmitRow = styled('div')`
  padding: 12px;
  display: flex;
  flex-flow: row wrap;
  flex-direction: row-reverse;
`;
