import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'components.myaccount.sparepart.title',
    defaultMessage: 'Status of deliveries of spare parts',
  },
  description: {
    id: 'components.myaccount.sparepart.description',
    defaultMessage:
      'Please update the status of the spare parts and indicate when you received them, this is essential for the progress of your file',
  },
  emptyList: {
    id: 'components.myaccount.sparepart.emptyList',
    defaultMessage: 'No spare parts found',
  },
  ref: {
    id: 'components.myaccount.sparepart.reference',
    defaultMessage: 'Reference of spare parts',
  },
  supplier: {
    id: 'components.myaccount.sparepart.supplier',
    defaultMessage: "Supplier's name",
  },
  statusKey: {
    id: 'components.myaccount.sparepart.statusKey',
    defaultMessage: 'Status',
  },
  statusValueShipped: {
    id: 'components.myaccount.sparepart.statusValueShipped',
    defaultMessage: 'Order shipped',
  },
  statusValueSubmitted: {
    id: 'components.myaccount.sparepart.statusValueSubmitted',
    defaultMessage: 'Order submitted',
  },
  statusValueDelivered: {
    id: 'components.myaccount.sparepart.statusValueDelivered',
    defaultMessage: 'Order delivered',
  },
  statusValueToProcess: {
    id: 'components.myaccount.sparepart.statusValueToProcess',
    defaultMessage: 'Order to process',
  },
  address: {
    id: 'components.myaccount.sparepart.address',
    defaultMessage: "Delivery's adress",
  },
  submitted: {
    id: 'components.myaccount.sparepart.submittedText',
    defaultMessage: 'Delivered',
  },
  confirm: {
    id: 'components.myaccount.sparepart.submitButton',
    defaultMessage: 'Confirm',
  },
});
