import React, { FunctionComponent, useContext } from 'react';

import { ContentTemplateContext } from '../../MDXCustomComponents.context';
import getAdditionalInformationLabel from '../helpers/getAdditionalInformationLabel';

const ClaimAddInfo: FunctionComponent<React.PropsWithChildren<{ uniqueName: string }>> = ({
  uniqueName,
}) => {
  const {
    isLoading,
    additionalInformationEntitiesMap = {},
    customerFile,
  } = useContext(ContentTemplateContext);
  const fileProducts = customerFile?.fileProducts || [];

  if (isLoading) {
    return <></>;
  }

  const concernedAdditionalInformation = Object.values(
    additionalInformationEntitiesMap,
  ).find(
    (item) => item?.additionalInformationEntity?.uniqueName === uniqueName,
  );

  if (
    concernedAdditionalInformation &&
    !concernedAdditionalInformation.additionalInformationEntity
      .uniquenessByProduct
  ) {
    const claimAdditionalInformation =
      fileProducts[0].fileAdditionalInformation?.find(
        (item) =>
          item.additionalInformationId === concernedAdditionalInformation.id,
      );

    if (!claimAdditionalInformation) {
      return <></>;
    }

    const label = getAdditionalInformationLabel({
      informationType:
        concernedAdditionalInformation.additionalInformationEntity
          .informationType,
      additionalInformationEntitiesMap,
      additionalInformationEnumValue:
        claimAdditionalInformation.additionalInformationEnumValue,
      additionalInformationStringValue:
        claimAdditionalInformation.additionalInformationStringValue,
    });

    return <>{label}</>;
  }

  const value = concernedAdditionalInformation
    ? fileProducts.reduce<string[]>((acc, fileProduct) => {
        const claimAdditionalInformation =
          fileProduct.additionalInformation?.find(
            (item) =>
              item.additionalInformationId ===
              concernedAdditionalInformation.id,
          );

        if (!claimAdditionalInformation) {
          return acc;
        }

        const { informationType } =
          concernedAdditionalInformation.additionalInformationEntity;
        const {
          additionalInformationStringValue,
          additionalInformationEnumValue,
        } = claimAdditionalInformation;

        const label = getAdditionalInformationLabel({
          additionalInformationEntitiesMap,
          additionalInformationStringValue,
          additionalInformationEnumValue,
          informationType,
        });

        if (label) {
          return [...acc, label];
        }

        return acc;
      }, [])
    : [''];

  return <>{value.join(', ')}</>;
};

ClaimAddInfo.displayName = 'ClaimAddInfo';

export default ClaimAddInfo;
