import { UserManagerSettings, WebStorageStateStore } from 'oidc-client';
import { createUserManager } from 'redux-oidc';

import { AuthConfiguration } from '@savgroup-front-common/types';

import { lazy } from '../helpers';

import { UserManagerDataCache } from './cahedUserManagerData';

const buildUserManagerSettings = (
  authConfiguration: AuthConfiguration,
  getInitialLocale: () => string,
): UserManagerSettings => {
  const silent_redirect_uri = `${window.location.protocol}//${
    window.location.hostname
  }${window.location.port ? `:${window.location.port}` : ''}/silent-renew`;
  const post_logout_redirect_uri = `${window.location.protocol}//${
    window.location.hostname
  }${window.location.port ? `:${window.location.port}` : ''}/index`;

  return {
    client_id: authConfiguration.clientId,
    redirect_uri: `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? `:${window.location.port}` : ''
    }/callback`,
    response_type: 'id_token token',
    scope: authConfiguration.scope,
    authority: authConfiguration.authority,
    post_logout_redirect_uri,
    silent_redirect_uri,
    loadUserInfo: false,
    filterProtocolClaims: true,
    revokeAccessTokenOnSignout: true,
    automaticSilentRenew: true,
    accessTokenExpiringNotificationTime: 10 * 60, // renew the token 10 minutes before the end of its lifetime
    staleStateAge: 60, // consider any request older than 60 seconds to be stalled (this is way too long!)
    userStore: new WebStorageStateStore({
      store: localStorage,
    }),
    ui_locales: getInitialLocale(),
    monitorSession: false, // avoid redux-oidc/USER_SIGNED_OUT in localhost
  };
};

const changePassword =
  (
    authConfiguration: AuthConfiguration,
    getInitialLocale: () => string,
  ) =>
  () => {
    const conf = buildUserManagerSettings(authConfiguration, getInitialLocale);
    const authorizationResetPasswordEndpoint =
      conf.metadata?.authorization_endpoint;
    const clientId = conf.client_id;
    const redirectUri = conf.redirect_uri;

    // eslint-disable-next-line max-len
    window.location.href = `${authorizationResetPasswordEndpoint}&client_id=${clientId}&redirect_uri=${redirectUri}&nonce=defaultNonce&scope=openid&response_type=id_token&prompt=login`;
  };

const resetPassword =
  (
    authConfiguration: AuthConfiguration,
    getInitialLocale: () => string,
  ) =>
  () => {
    const conf = buildUserManagerSettings(authConfiguration, getInitialLocale);
    const authorizationResetPasswordEndpoint =
      conf.metadata?.authorization_endpoint;
    const clientId = conf.client_id;
    const redirectUri = conf.redirect_uri;

    // eslint-disable-next-line max-len
    window.location.href = `${authorizationResetPasswordEndpoint}&client_id=${clientId}&redirect_uri=${redirectUri}&nonce=defaultNonce&scope=openid&response_type=id_token`;
  };

const userManager = (
  authConfiguration: AuthConfiguration,
  getInitialLocale: () => string,
) => {
  return lazy(() => {
    return createUserManager(
      buildUserManagerSettings(authConfiguration, getInitialLocale),
    );
  });
};

export default (
  authConfiguration: AuthConfiguration,
  getInitialLocale: () => string,
): UserManagerDataCache => {
  return {
    userManagerConfig: () =>
      buildUserManagerSettings(authConfiguration, getInitialLocale),
    userManager: userManager(authConfiguration, getInitialLocale),
    changePassword: changePassword(authConfiguration, getInitialLocale),
    resetPassword: resetPassword(authConfiguration, getInitialLocale),
  };
};
