import { APIConfiguration } from '@savgroup-front-common/configuration/src';

interface PrintDocumentWithUrlArgs {
  documentUrl: string;
}

const printDocumentWithUrl = ({
  documentUrl,
}: PrintDocumentWithUrlArgs): void => {
  const downloadWindow = window.open();

  if (!downloadWindow) {
    return;
  }

  if (documentUrl) {
    const isUrlReady =
      documentUrl.includes('https://') || documentUrl.includes('//localhost/');
    const docUrl = isUrlReady
      ? documentUrl
      : APIConfiguration.base + documentUrl;

    downloadWindow.location.href = docUrl;
  } else {
    downloadWindow.close();
  }
};

export default printDocumentWithUrl;
