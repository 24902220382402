import { defineMessages } from 'react-intl';

export default defineMessages({
  responseIsRequired: {
    id: 'components.myaccount.diagnosticTree.responseIsRequired',
    defaultMessage: 'une response est requise',
  },
  nextButton: {
    id: 'components.myaccount.diagnosticTree.nextButton',
    defaultMessage: 'Next',
  },
  previousButton: {
    id: 'components.myaccount.diagnosticTree.previousButton',
    defaultMessage: 'Previous',
  },
  stepNumber: {
    id: 'components.myaccount.diagnosticTree.stepNumber',
    defaultMessage: 'Step nº {value}',
  },
  stepFinalRepairedBannerTitle: {
    id: 'components.myaccount.diagnosticTree.stepFinalRepairedBannerTitle',
    defaultMessage: 'Vous avez besoin de l’oeil d’un professionnel ?',
  },
  stepFinalRepairedBannerSubtitle: {
    id: 'components.myaccount.diagnosticTree.stepFinalRepairedBannerSubtitle',
    defaultMessage:
      'Effectuez votre demande de réparation en ligne, on se charge du reste',
  },
  stepFinalRepairedBannerButton: {
    id: 'components.myaccount.diagnosticTree.stepFinalRepairedBannerButton',
    defaultMessage: 'Continuer ma demande SAV',
  },
  stepFinalRepairedAnotherDemand: {
    id: 'components.myaccount.diagnosticTree.stepFinalRepairedAnotherDemand',
    defaultMessage: 'Faire une autre demande',
  },
});
