import styled from 'styled-components';

import { rem } from '../../../helpers';

export const $AmountCell = styled.div`
  text-align: right;
`;

export const $AmountHeaderCel = styled.div`
  text-align: right;
  min-width: ${rem(110)};
`;
