import { all, call, put, takeEvery } from 'redux-saga/effects';

import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import { callAndGetResponse } from '@savgroup-front-common/core/src/services';

import * as ActionCreators from './actionCreators';
import * as ActionTypes from './actionTypes';

function* loadClaimSummaryWorker({ payload }) {
  yield call(
    callAndGetResponse,
    ActionTypes.LOAD_CLAIM_SUMMARY,
    `${APIConfiguration.claim}claims/${payload}/summary`,
    {
      method: SUPPORTED_METHODS.GET,
    },
  );

  yield put(ActionCreators.loadClaimSummaryEnd());
}
function* loadClaimSummaryWatcher() {
  yield takeEvery(ActionTypes.LOAD_CLAIM_SUMMARY.BASE, loadClaimSummaryWorker);
}

function* getClaimWorker({ payload }) {
  yield call(
    callAndGetResponse,
    ActionTypes.GET_CLAIM,
    `${APIConfiguration.claim}claims/${payload}`,
    {
      method: SUPPORTED_METHODS.GET,
    },
  );

  yield put(ActionTypes.GET_CLAIM.end());
}
function* getClaimWatcher() {
  yield takeEvery(ActionTypes.GET_CLAIM.BASE, getClaimWorker);
}

export function* claimSaga() {
  try {
    yield all([loadClaimSummaryWatcher(), getClaimWatcher()]);
  } catch (error) {
    logCritical(error);
  }
}

export default claimSaga;
