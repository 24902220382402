import React, { Fragment, FunctionComponent } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';

import {
  CardSeparator,
  Card,
} from '@savgroup-front-common/core/src/atoms/Cards';
import { Heading } from '@savgroup-front-common/core/src/atoms/Heading';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import {
  FileSparePartRequestLine,
  RequestLineStatus,
} from '@savgroup-front-common/types';

import { DeliveredSpareParts } from './DeliveredSparePart';
import messages from './DeliveredSparePart/messages';
import { useGetRequestines } from './DeliveredSparePartsList.hooks';
import DeliveredSparePartsListModal from './DeliveredSparePartsListModal';

interface DeliveredSparePartsListType {
  fileId: string;
}

const DeliveredSparePartsList: FunctionComponent<
  DeliveredSparePartsListType
> = ({ fileId }) => {
  const {
    isOpenModal,
    handleCloseModal,
    requestLines,
    handleRequestLineStatusUpdate,
    handleSetCurrentRequestLine,
    currentRequestLine,
  } = useGetRequestines({ fileId });

  const isVisibleRequestLine =
    !!requestLines &&
    requestLines.some(
      (line: FileSparePartRequestLine) =>
        line.requestLineStatus === RequestLineStatus.TO_PROCESS ||
        line.requestLineStatus === RequestLineStatus.ORDER_DELIVERED ||
        line.requestLineStatus === RequestLineStatus.ORDER_SUBMITTED ||
        line.requestLineStatus === RequestLineStatus.ORDER_SHIPPED,
    );

  return (
    <>
      {isVisibleRequestLine && (
        <Card>
          <Heading level={2}>
            <SafeFormattedMessageWithoutSpread message={messages.title} />
          </Heading>
          <Row>
            <Col xs={12}>
              <SafeFormattedMessageWithoutSpread
                message={messages.description}
              />
            </Col>
          </Row>
          {requestLines.map((requestLine) => (
            <Fragment key={requestLine.sparePartReference}>
              <CardSeparator />

              <DeliveredSpareParts
                requestLine={requestLine}
                onChange={() => {
                  handleSetCurrentRequestLine(requestLine.requestLineId);
                }}
              />
            </Fragment>
          ))}

          <DeliveredSparePartsListModal
            isOpen={isOpenModal}
            onClose={handleCloseModal}
            currentRequestLine={currentRequestLine}
            onValidate={handleRequestLineStatusUpdate}
          />
        </Card>
      )}
    </>
  );
};

DeliveredSparePartsList.displayName = 'DeliveredSparePartsList';

export default DeliveredSparePartsList;
