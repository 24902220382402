export enum RecipientType {
  UNDEFINED = 'Undefined',
  OWNER = 'Owner',
  SELLER = 'Seller',
  INSURER = 'Insurer',

  SUPPLIER = 'Supplier',
  MANUFACTURER = 'Manufacturer',

  PURCHASING_DEPARTMENT = 'PurchasingDepartment',
  CARRIER = 'Carrier',
  STORE = 'Store',
  FILE_ACTOR = 'FileActor',
  SITE = 'Site',
  WAREHOUSE = 'Warehouse',
  SERVICE_CENTER = 'ServiceCenter',
}
