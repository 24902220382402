import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { Col, Row } from 'react-styled-flexboxgrid';

import { FormGroup } from '../../atoms/Form';
import { Paragraph } from '../../atoms/Paragraph';
import { Heading } from '../../atoms/Heading';
import { SafeFormattedMessageWithoutSpread } from '../../formatters';
import { InputHookForm } from '../../molecules/Form';
import { BannersConsumer } from '../../molecules/NotificationsProvider';

import { CollectBankAccountDetailsFormValues } from './CollectBankAccountDetailsForm.types';
import messages from './messages';

const CollectBankAccountDetailsForm: FC = () => {
  const formContext = useFormContext<CollectBankAccountDetailsFormValues>();
  const {
    register,
    formState: { errors },
  } = formContext;

  return (
    <>
      <Heading level={3}>
        <SafeFormattedMessageWithoutSpread message={messages.title} />
      </Heading>
      <Paragraph>
        <SafeFormattedMessageWithoutSpread message={messages.didactics} />
      </Paragraph>

      <BannersConsumer />

      <Row>
        <Col xs={12}>
          <FormGroup>
            <InputHookForm
              errors={errors}
              name="accountHolder"
              label={messages.accountHolder}
              register={register}
            />
          </FormGroup>
        </Col>

        <Col xs={12} sm={6}>
          <FormGroup>
            <InputHookForm
              errors={errors}
              name="bankIdentificationCode"
              label={messages.bankIdentificationCode}
              register={register}
            />
          </FormGroup>
        </Col>

        <Col xs={12} sm={6}>
          <FormGroup>
            <InputHookForm
              errors={errors}
              name="bankName"
              label={messages.bankName}
              register={register}
            />
          </FormGroup>
        </Col>

        <Col xs={12}>
          <FormGroup>
            <InputHookForm
              errors={errors}
              name="iban"
              label={messages.iban}
              register={register}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

CollectBankAccountDetailsForm.displayName = 'CollectBankAccountDetailsForm';

export default CollectBankAccountDetailsForm;
