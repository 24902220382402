import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'components.myaccount.claims.confirmation.unlinkDevice.title',
    defaultMessage: 'Action required',
  },
  description: {
    id: 'components.myaccount.claims.confirmation.unlinkDevice.description',
    defaultMessage:
      'Your device is currently blocked by an account which makes impossible further diagnostic. Could you please remove geolocalization services by connecting to your account online and hit "Confirm" once everything is ok?',
  },
  checkBox: {
    id: 'components.myaccount.claims.confirmation.unlinkDevice.checkBox',
    defaultMessage:
      'I confirm that the geolocalization services are now removed',
  },
  confirm: {
    id: 'components.myaccount.claims.appointmentCard.submitButton',
    defaultMessage: 'Confirm',
  },
});
