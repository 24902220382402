import { TransitionStatus } from 'react-transition-group/Transition';

import { ANIMATION_STATE } from '@savgroup-front-common/constants/src/shared';

interface ShouldMountArgs {
  animationState: TransitionStatus;
  isOpen: boolean;
  unmountOnExit: boolean;
}

export const collapseAnimationDuration = 400;

export const shouldMount = ({
  unmountOnExit,
  animationState,
  isOpen,
}: ShouldMountArgs): boolean => {
  if (unmountOnExit) {
    return !(
      isOpen === false &&
      [ANIMATION_STATE.EXITED, ANIMATION_STATE.UNMOUNTED].includes(
        animationState,
      )
    );
  }

  return true;
};
