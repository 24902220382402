import { defineMessages } from 'react-intl';

export default defineMessages({
  ShippingLabel: {
    id: 'view.myccount.shippingLabel',
    defaultMessage: 'Shipping label',
  },
  downloadDocument: {
    id: 'view.myccount.fileDocmuent.downloadDocument',
    defaultMessage: 'Download',
  },
});
