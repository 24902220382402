import React, { FC } from 'react';

import { CURRENCIES } from '@savgroup-front-common/constants';

import { CashRegisterPayment } from '../../../../../../../components/Payments/CashRegisterPayment';
import useGetPaymentStatus from '../hooks/useGetPaymentStatus';

interface FileFollowupsCashRegisterPaymentProps {
  fileId: string;
  claimGroupId: string;
  paymentUrl: string;
  invoice: { priceWithTax: number; currencyTrigram: string };
  isFormCompleted: boolean;
}

export const FileFollowupsCashRegisterPayment: FC<
  FileFollowupsCashRegisterPaymentProps
> = ({ fileId, claimGroupId, paymentUrl, invoice, isFormCompleted }) => {
  const paymentStatus = useGetPaymentStatus({
    fileId,
    claimGroupId,
    paymentUrl,
  });

  return (
    <CashRegisterPayment
      paymentStatus={paymentStatus}
      amount={invoice.priceWithTax}
      currency={invoice.currencyTrigram as CURRENCIES}
      isFormCompleted={isFormCompleted}
    />
  );
};

FileFollowupsCashRegisterPayment.displayName =
  'FileFollowupsCashRegisterPayment';
