import { useQuery } from 'react-query';

import { SALESFORCE_INTEGRATION_TYPE } from '@savgroup-front-common/types';

import { CommonWorkflowService } from '../../../api';
import { useGetSellerConfiguration } from '../../../hooks';
import { useBanners } from '../../../molecules/NotificationsProvider';

interface UseGetSalesforceContextSummaryArgs {
  fileId: string;
  sellerId: string;
}

const useGetSalesforceContextSummary = ({
  fileId,
  sellerId,
}: UseGetSalesforceContextSummaryArgs) => {
  const { pushErrors, removeAllNotifications } = useBanners();
  const { sellerConfiguration } = useGetSellerConfiguration({
    sellerId,
  });

  useQuery(
    [
      'getSalesforceContextQuery',
      {
        fileId,
        sellerId,
        integrationType:
          sellerConfiguration?.salesforceFieldServiceLightningIntegrationType,
      },
    ],
    async () => {
      removeAllNotifications();

      if (
        !sellerConfiguration ||
        sellerConfiguration?.salesforceFieldServiceLightningIntegrationType ===
          SALESFORCE_INTEGRATION_TYPE.None ||
        !sellerConfiguration?.salesforceFieldServiceLightningIntegrationType ||
        sellerConfiguration?.salesforceFieldServiceLightningIntegrationType ===
          SALESFORCE_INTEGRATION_TYPE.Integration
      ) {
        return undefined;
      }

      if (
        sellerConfiguration?.salesforceFieldServiceLightningIntegrationType ===
        SALESFORCE_INTEGRATION_TYPE.Standard
      ) {
        const [
          createContextIfNotExistCommandResponse,
          getDefaultWorkTypeQueryResponse,
        ] = await Promise.all([
          CommonWorkflowService.createContextIfNotExistCommand({ fileId }),
          CommonWorkflowService.getDefaultWorkTypeQuery({ fileId }),
        ]);

        if (createContextIfNotExistCommandResponse.failure) {
          pushErrors(createContextIfNotExistCommandResponse.errors);

          return undefined;
        }
        if (getDefaultWorkTypeQueryResponse.failure) {
          pushErrors(getDefaultWorkTypeQueryResponse.errors);

          return undefined;
        }

        const defaultWorkTypeId = getDefaultWorkTypeQueryResponse.value;

        await CommonWorkflowService.createWorkOrderCommand({
          fileId,
          workTypeId: defaultWorkTypeId,
        });
      }

      const getSalesforceContextQueryResponse =
        await CommonWorkflowService.getSalesforceContextQuery({ fileId });

      if (getSalesforceContextQueryResponse.failure) {
        pushErrors(getSalesforceContextQueryResponse.errors);

        return undefined;
      }

      return undefined;
    },
    {
      staleTime: Infinity,
      suspense: true,
    },
  );

  return {
    sellerConfiguration,
  };
};

export default useGetSalesforceContextSummary;
