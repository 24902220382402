import React, { FunctionComponent } from 'react';

import { Collapse } from '../../../atoms/Collapse';

import $Content from './AccordionContent.styles';

interface AccordionContentProps {
  naked: boolean;
  isOpen: boolean;
  unmountOnExit: boolean;
  componentThemeName?: string;
  isListTheme?: boolean;
}

const AccordionContent: FunctionComponent<
  React.PropsWithChildren<AccordionContentProps>
> = ({
  children,
  naked,
  isOpen,
  unmountOnExit,
  componentThemeName,
  isListTheme = false,
}) => {
  return (
    <Collapse isOpen={isOpen} unmountOnExit={unmountOnExit}>
      <$Content
        $naked={naked}
        $componentThemeName={componentThemeName}
        $isList={isListTheme}
      >
        {children}
      </$Content>
    </Collapse>
  );
};

AccordionContent.displayName = 'AccordionContent';

export default AccordionContent;
