import React, { FunctionComponent } from 'react';

import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { QuotationEnrichedDto } from '@savgroup-front-common/types';

import {
  AdministrativeFeesSummaryLine,
  DiscountsSummaryLine,
  ServiceSummaryLine,
  SparePartsSummaryLine,
  UnknownServiceSummaryLine,
  UnknownSparePartsSummaryLine,
} from './components';
import messages from './messages';
import {
  $QuoteItemSummariesCommments,
  $QuoteItemSummariesStyled,
  $QuoteItemSummariesTitle,
} from './QuotationValidationSummary.styles';

interface QuotationValidationSummaryProps {
  quotation: QuotationEnrichedDto;
}

const QuotationValidationSummary: FunctionComponent<
  React.PropsWithChildren<QuotationValidationSummaryProps>
> = ({ quotation }) => {
  const {
    unknownServices,
    unknownSpareParts,
    services,
    spareParts,
    discounts,
    administrativeFees,
  } = quotation;

  return (
    <$QuoteItemSummariesStyled>
      {quotation.description && (
        <>
          <$QuoteItemSummariesTitle>
            <SafeFormattedMessageWithoutSpread
              message={messages.quoteComment}
            />
          </$QuoteItemSummariesTitle>
          <$QuoteItemSummariesCommments>
            {quotation.description}
          </$QuoteItemSummariesCommments>
        </>
      )}

      <$QuoteItemSummariesTitle>
        <SafeFormattedMessageWithoutSpread message={messages.description} />
      </$QuoteItemSummariesTitle>

      <ServiceSummaryLine services={services} />
      <UnknownServiceSummaryLine unknownServices={unknownServices} />
      <SparePartsSummaryLine spareParts={spareParts} />
      <UnknownSparePartsSummaryLine unknownSpareParts={unknownSpareParts} />
      <AdministrativeFeesSummaryLine administrativeFees={administrativeFees} />

      <DiscountsSummaryLine discounts={discounts} />
    </$QuoteItemSummariesStyled>
  );
};

QuotationValidationSummary.displayName = 'QuotationValidationSummary';

export default QuotationValidationSummary;
