import React, { Fragment, FC } from 'react';
import { useMedia } from 'react-use';

import { media } from '@savgroup-front-common/constants';
import { CheckIcon } from '@savgroup-front-common/core/src/protons/icons';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';

import { STEP_CONFIG } from '../../../ClassiqueRoutes.config';

import { getLabel } from './StepProgress.helpers';
import {
  $StepProgressCircle,
  $StepProgressItem,
  $StepProgressLabel,
  $StepProgressSeparator,
  $StepProgressWrapper,
} from './StepProgress.styles';

interface StepProgressProps {
  stepName?: string;
  customSteps?: Record<string, string[]>[];
  isFluid?: boolean;
  withoutMarginBottom?: boolean;
}

const StepProgress: FC<StepProgressProps> = ({
  stepName,
  customSteps,
  isFluid = false,
  withoutMarginBottom = false,
}) => {
  const isMobileView = useMedia(media.maxWidth.xs);

  const steps = customSteps || [
    { informations: [] },
    {
      requests: [
        STEP_CONFIG.ISSUES.toString(),
        STEP_CONFIG.CHOOSE_PRODUCTS.toString(),
        STEP_CONFIG.CLAIM_GROUP_REASON.toString(),
        STEP_CONFIG.CLAIM_GROUP_DIAGNOSTIC_TREE.toString(),
        STEP_CONFIG.CLAIM_GROUP_PRODUCT_INFO.toString(),
        STEP_CONFIG.CLAIM_GROUP_DOCUMENT_RELATED_TO_PRODUCT.toString(),
        STEP_CONFIG.CLAIM_GROUP_DOCUMENT_RELATED_TO_CLAIM.toString(),
      ],
    },
    {
      solutions: [
        STEP_CONFIG.CLAIM_GROUP_SOLUTION.toString(),
        STEP_CONFIG.CLAIM_GROUP_DEPOSIT.toString(),
        STEP_CONFIG.CLAIM_GROUP_DELIVERY.toString(),
      ],
    },
    {
      confirmation: [STEP_CONFIG.CLAIM_GROUP_CONFIRMATION.toString()],
    },
  ];

  const currentStep = steps.find((stepValues) =>
    Object.values(stepValues).some((values) =>
      values.some((value) => value === stepName),
    ),
  );

  let hasChecked = false;

  const stateSteps = steps.map((step) => {
    const label = Object.keys(step)[0];
    const values = Object.values(step)[0];
    const isCurrent = values?.some((value) => value === stepName) || false;

    if (currentStep && isCurrent) {
      hasChecked = true;

      return { label, isCurrent: true, isChecked: false };
    }

    return { label, isCurrent: false, isChecked: !hasChecked };
  });

  return (
    <$StepProgressWrapper
      $isFluid={isFluid}
      $withoutMarginBottom={withoutMarginBottom}
    >
      {stateSteps.map((step, index) => {
        return (
          <Fragment key={step.label}>
            <$StepProgressItem>
              <$StepProgressCircle $active={step.isChecked || step.isCurrent}>
                {step.isChecked && <CheckIcon color="#fff" size="14px" />}
                {!step.isChecked && index + 1}
              </$StepProgressCircle>
              {!isMobileView && (
                <$StepProgressLabel $active={step.isChecked || step.isCurrent}>
                  <SafeFormattedMessageWithoutSpread
                    message={getLabel(step.label)}
                  />
                </$StepProgressLabel>
              )}
            </$StepProgressItem>
            <$StepProgressSeparator $active={step.isChecked} />
          </Fragment>
        );
      })}
    </$StepProgressWrapper>
  );
};

StepProgress.displayName = 'StepProgress';
export default StepProgress;
