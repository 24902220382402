import { defineMessages } from 'react-intl';

export default defineMessages({
  solutionTitle: {
    id: 'components.myaccount.claims.handling.cards.solutionTitle',
    defaultMessage: 'Solution : ',
  },
  deliveryTitle: {
    id: 'components.myaccount.claims.handling.cards.deliveryTitle',
    defaultMessage: 'Send product : ',
  },
  deliveryPickupPoint: {
    id: 'components.myaccount.claims.handling.cards.deliveryPickupPoint',
    defaultMessage: 'deliveryPickupPoint',
  },
  deliveryPickupStore: {
    id: 'components.myaccount.claims.handling.cards.deliveryPickupStore',
    defaultMessage: 'deliveryPickupStore',
  },
  deliveryHome: {
    id: 'components.myaccount.claims.handling.cards.deliveryHome',
    defaultMessage: 'Home delivery',
  },
  deliveryExternal: {
    id: 'components.myaccount.claims.handling.cards.deliveryExternal',
    defaultMessage: 'External delivery',
  },
  deliveryPickupStoreDelivery: {
    id: 'components.myaccount.claims.handling.cards.deliveryPickupStoreDelivery',
    defaultMessage: 'deliveryPickupStoreDelivery',
  },

  deliveryPickupPointDidactics: {
    id: 'components.myaccount.claims.handling.cards.deliveryPickupPointDescription',
    defaultMessage: 'deliveryPickupPointDidactics',
  },
  deliveryPickupStoreDidactics: {
    id: 'components.myaccount.claims.handling.cards.deliveryPickupStoreDescription',
    defaultMessage: 'deliveryPickupStoreDidactics',
  },
  deliveryHomeDidactics: {
    id: 'components.myaccount.claims.handling.cards.deliveryHomeDescription',
    defaultMessage: 'deliveryHomeDidactics',
  },
  deliveryExternalDidactics: {
    id: 'components.myaccount.claims.handling.cards.deliveryExternalDescription',
    defaultMessage: 'deliveryExternalDidactics',
  },
  deliveryPickupStoreDeliveryDidactics: {
    id: 'components.myaccount.claims.handling.cards.deliveryPickupStoreDeliveryDescription',
    defaultMessage: 'deliveryPickupStoreDeliveryDidactics',
  },
  free: {
    id: 'components.myaccount.claims.handling.cards.free',
    defaultMessage: 'Free',
  },
});
