import React, { FC, useCallback, useContext, useState } from 'react';

import {
  HANDLING_GROUPS,
  HANDLING_MODES,
} from '@savgroup-front-common/constants';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';

import { useHistory, useParams } from 'react-router-dom';
import { ClaimGroupDeposit } from './ClaimGroupDeposit/ClaimGroupDeposit';
import { ProfessionalPagesContextProvider } from '../ProfessionalPages.context';
import { buildProfessionalRoute } from 'myaccount/view/app/NewLayout/ProfessionalRoutes.helpers';
import { PROFESSIONAL_STEP_CONFIG } from 'myaccount/view/app/NewLayout/ProfessionalRoutes.config';
import { useGetGroupedCarriers } from './hooks/useGetGroupedCarriers';
import { Carrier } from '../../../../api/Claim/getClaimGroupCarrierQuery';
import { Dictionary } from 'lodash';
import { useSetClaimGroupHandling } from './hooks/useSetClaimGroupHandling';
import { useLayout } from '../../../app/NewLayout/NewLayout.hooks';

const ClaimGroupDepositPage: FC = () => {
  const { claimGroupId } = useParams<{ claimGroupId: string }>();
  const { login: owner } = useLayout();

  const history = useHistory();
  const { handleSetDepositHandling } = useSetClaimGroupHandling();
  const { skippedSteps, onSkipStep } = useContext(
    ProfessionalPagesContextProvider,
  );
  const isAlreadySkipped =
    skippedSteps[PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_DEPOSIT_PAGE] || false;
  const [isSkipLoading, setIsSkipLoading] = useState(false);

  const { ownerAddress } = owner;

  const onSuccess = useCallback(
    async (result: { groupedCarriers: Dictionary<Carrier[]> }) => {
      const groupedCarriers = result?.groupedCarriers;

      if (
        groupedCarriers &&
        Object.keys(groupedCarriers).length === 1 &&
        groupedCarriers[HANDLING_GROUPS.HOME] &&
        !isAlreadySkipped
      ) {
        setIsSkipLoading(true);

        const handlingSummary = await handleSetDepositHandling({
          handlingMode: HANDLING_MODES.DEPOSIT,
          address: ownerAddress,
          carrierSellerPriceId:
            groupedCarriers[HANDLING_MODES.HOME][0].carrierSellerPriceId,
          carrierCustomerPriceId:
            groupedCarriers[HANDLING_MODES.HOME][0].carrierCustomerPriceId,
        });

        if (!handlingSummary) {
          setIsSkipLoading(false);

          return undefined;
        }

        onSkipStep(PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_DEPOSIT_PAGE);

        if (handlingSummary.hasDelivery) {
          history.push(
            buildProfessionalRoute({
              route: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_DELIVERY_PAGE,
              payload: {
                claimGroupId,
              },
            }),
          );
        } else {
          history.push(
            buildProfessionalRoute({
              route: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_CONFIRMATION_PAGE,
              payload: {
                claimGroupId,
              },
            }),
          );
        }
      }

      return undefined;
    },
    [
      claimGroupId,
      history,
      isAlreadySkipped,
      onSkipStep,
      ownerAddress,
      handleSetDepositHandling,
    ],
  );

  const { groupedCarriers } = useGetGroupedCarriers({ onSuccess });

  if (
    !groupedCarriers ||
    (Object.keys(groupedCarriers).length === 1 && !isAlreadySkipped) ||
    isSkipLoading
  ) {
    return <BaseLoader />;
  }

  return <ClaimGroupDeposit />;
};

ClaimGroupDepositPage.displayName = 'ClaimGroupDepositPage';

export default ClaimGroupDepositPage;
