import { defineMessages } from 'react-intl';

export default defineMessages({
  solutionTitle: {
    id: 'components.myaccount.claims.handling.cards.solutionTitle',
    defaultMessage: 'Solution : ',
  },
  depositTitle: {
    id: 'components.myaccount.claims.handling.cards.depositTitle',
    defaultMessage: 'Send product : ',
  },
  repairProduct: {
    id: 'components.myaccount.claims.handling.cards.repairProduct',
    defaultMessage: 'Repair my product in the workshop',
  },
  estimatedTime: {
    id: 'components.myaccount.claims.handling.cards.estimatedTime',
    defaultMessage: 'estimated average time {estimatedTime}',
  },
  modifyButton: {
    id: 'components.myaccount.claims.handling.cards.modifyButton',
    defaultMessage: 'Modify',
  },
  handlingTitle: {
    id: 'components.myaccount.claims.handling.delivery.cards.handlingTitle',
    defaultMessage: 'Get my product',
  },
  free: {
    id: 'components.myaccount.claims.handling.cards.free',
    defaultMessage: 'Free',
  },
  deliveryPickupPoint: {
    id: 'components.myaccount.claims.handling.cards.deliveryPickupPoint',
    defaultMessage: 'Drop off point',
  },
  deliveryPickupStore: {
    id: 'components.myaccount.claims.handling.cards.deliveryPickupStore',
    defaultMessage: 'Drop off at store',
  },
  deliveryHome: {
    id: 'components.myaccount.claims.handling.cards.deliveryHome',
    defaultMessage: 'Home delivery',
  },
  deliveryExternal: {
    id: 'components.myaccount.claims.handling.cards.deliveryExternal',
    defaultMessage: 'External transport',
  },
  deliveryPickupStoreDelivery: {
    id: 'components.myaccount.claims.handling.cards.deliveryPickupStoreDelivery',
    defaultMessage: 'Delivery at store',
  },
  deliveryPickupPointDescription: {
    id: 'components.myaccount.claims.handling.cards.deliveryPickupPointDescription',
    defaultMessage: 'Récupérez votre produit dans le point relais sélectionné.',
  },
  deliveryPickupStoreDescription: {
    id: 'components.myaccount.claims.handling.cards.deliveryPickupStoreDescription',
    defaultMessage: 'Récupérez votre produit dans le magasin sélectionné.',
  },
  deliveryHomeDescription: {
    id: 'components.myaccount.claims.handling.cards.deliveryHomeDescription',
    defaultMessage: 'Un transporteur se déplace chez vous.',
  },
  deliveryExternalDescription: {
    id: 'components.myaccount.claims.handling.cards.deliveryExternalDescription',
    defaultMessage: '???',
  },
  deliveryPickupStoreDeliveryDescription: {
    id: 'components.myaccount.claims.handling.cards.deliveryPickupStoreDeliveryDescription',
    defaultMessage: 'Récupérez votre produit dans le magasin sélectionné.',
  },
  deliveryPickupPointHighlighting: {
    id: 'components.myaccount.claims.handling.cards.deliveryPickupPointHighlighting',
    defaultMessage: '',
  },
  deliveryPickupStoreHighlighting: {
    id: 'components.myaccount.claims.handling.cards.deliveryPickupStoreHighlighting',
    defaultMessage: 'Solution plus écologique',
  },
  deliveryHomeHighlighting: {
    id: 'components.myaccount.claims.handling.cards.deliveryHomeHighlighting',
    defaultMessage: '',
  },
  deliveryExternalHighlighting: {
    id: 'components.myaccount.claims.handling.cards.deliveryExternalHighlighting',
    defaultMessage: '',
  },
  deliveryPickupStoreDeliveryHighlighting: {
    id: 'components.myaccount.claims.handling.cards.deliveryPickupStoreDeliveryHighlighting',
    defaultMessage: 'Solution plus écologique',
  },
  deleteAddress: {
    id: 'components.common.addressManagement.deleteAddress',
    defaultMessage: 'Supprimer cette addresse',
  },
  editAddress: {
    id: 'components.common.addressManagement.editAddress',
    defaultMessage: 'Modifier cette addresse',
  },
  addAddress: {
    id: 'components.common.addressManagement.addAddress',
    defaultMessage: 'Ajouter une addresse',
  },
  areYouSure: {
    id: 'components.common.addressManagement.areYouSure',
    defaultMessage: 'Êtes vous certains ?',
  },
  youWillErase: {
    id: 'components.common.addressManagement.youWillErase',
    defaultMessage: 'Confirmer la suppression de cette addresse',
  },
  cancel: {
    id: 'components.common.addressManagement.cancel',
    defaultMessage: 'Annuler',
  },
  confirm: {
    id: 'components.common.addressManagement.confirm',
    defaultMessage: 'Confirmer',
  },
  back: {
    id: 'components.common.addressManagement.back',
    defaultMessage: 'Back',
  },
  requiredField: {
    id: 'components.common.breadCrumb.commentRequired',
    defaultMessage: 'This field is mandatory',
  },
  badFormatError: {
    id: 'components.common.addressManagement.addressForm.badFormatError',
    defaultMessage:
      'Ce champs ne peut pas contenir de caractères spéciaux (@,€,#,...)',
  },
  phoneFormatError: {
    id: 'components.common.addressManagement.addressForm.phoneFormatError',
    defaultMessage: 'Phone number formatted incorrectly',
  },
  maxLengthError: {
    id: 'components.common.addressManagement.addressForm.maxLengthError',
    defaultMessage: 'Ce champs est trop long :',
  },
});
