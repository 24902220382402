import styled from 'styled-components';

export const ActorInformationItem = styled.li<{ isFluid?: boolean }>`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
  width: ${({ isFluid }) => (isFluid ? '100%' : 'auto')};
`;

export const ActorInformationList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

export const $NoteContainer = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.small};
  line-height: 1rem;
`;
