import styled from 'styled-components';

import { AnimationStyles } from './AnimatedRenderer.types';
import {
  growHeight,
  scaleDownToScaleIn,
  scaleInToScaleDown,
  shrinkHeight,
  StateAnimationProps,
} from '../../animations/stateAnimation';

interface $UnmountBlockContainerProps extends StateAnimationProps {
  animationStyle: AnimationStyles;
}

export const $UnmountBlockContainer = styled.div<$UnmountBlockContainerProps>`
  ${({ animationStyle }) =>
    animationStyle === AnimationStyles.SCALE_IN_TO_SCALE_DOWN
      ? scaleDownToScaleIn
      : shrinkHeight};
`;

interface $MountBlockContainerProps extends StateAnimationProps {
  animationStyle: AnimationStyles;
}

export const $MountBlockContainer = styled.div<$MountBlockContainerProps>`
  ${scaleInToScaleDown};
  ${({ animationStyle }) =>
    animationStyle === AnimationStyles.SCALE_IN_TO_SCALE_DOWN
      ? scaleInToScaleDown
      : growHeight};
`;
