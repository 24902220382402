/* eslint-disable no-console */
import { fromJS } from 'immutable';
import get from 'lodash/get';

export function onClaimCreationForOwnerLoading(state, { meta }) {
  const ownerId = get(meta, ['ownerId']);
  const ownerProductId = get(meta, ['ownerProductId']);

  if (!ownerId || !ownerProductId) {
    console.warn(
      '[onClaimCreationForOwnerLoading] OwnerId/OwnerProductId is undefined',
    );

    return state;
  }

  return state.setIn(
    ['createdClaimsForOwner', ownerId, ownerProductId],
    fromJS({ isLoaded: false, isDirty: false, value: undefined }),
  );
}

export function onClaimCreationForOwnerLoadingErrored(state, { meta }) {
  const ownerId = get(meta, ['ownerId']);
  const ownerProductId = get(meta, ['ownerProductId']);

  if (!ownerId || !ownerProductId) {
    console.warn(
      '[onClaimCreationForOwnerLoadingErrored] OwnerId/OwnerProductId is undefined',
    );

    return state;
  }

  // TODO errors
  return state.updateIn(
    ['createdClaimsForOwner', ownerId, ownerProductId],
    (oldValue) => oldValue.setIn(['isDirty'], true).setIn(['hasErrors'], true),
  );
}

export function onClaimCreationForOwnerLoaded(state, { payload, meta }) {
  const ownerId = get(meta, ['ownerId']);
  const ownerProductId = get(meta, ['ownerProductId']);
  const claimId = get(payload, ['value']);

  if (!ownerId || !ownerProductId) {
    console.warn(
      '[onClaimCreationForOwnerLoaded] OwnerId/OwnerProductId is undefined.',
    );

    return state;
  }

  return state.setIn(
    ['createdClaimsForOwner', ownerId, ownerProductId],
    fromJS({ isLoaded: true, isDirty: false }).setIn(['value'], claimId),
  );
}
