import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { NeedHelp } from '@savgroup-front-common/core/src/components/NeedHelp';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { H1 } from '@savgroup-front-common/core/src/atoms/headings';
import {
  safeFormattedIntlString,
  SafeFormattedMessageWithoutSpread,
} from '@savgroup-front-common/core/src/formatters';
import { AuthConfiguration } from 'myaccount/configuration';

import { ROUTES } from '../../app/routes/Routes';

import messages from './messages';
import { $StyledDiv } from './PageNotFound.styles';

const PageNotFound: FunctionComponent = () => {
  const history = useHistory();

  return (
    <$StyledDiv>
      <NeedHelp clientId={AuthConfiguration.clientId} />
      <H1>
        <SafeFormattedMessageWithoutSpread message={messages.title} />
      </H1>
      <Button
        type={BUTTON_TYPES.BUTTON}
        primary
        onClick={() => history.push(ROUTES.PRODUCTS)}
      >
        <SafeFormattedMessageWithoutSpread
          message={messages.buttonLabel}
          values={{ page: safeFormattedIntlString(messages.myOrders) }}
        />
      </Button>
    </$StyledDiv>
  );
};

PageNotFound.displayName = 'PageNotFound';

export default PageNotFound;
