import find from 'lodash/find';
import get from 'lodash/get';
import head from 'lodash/head';
import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import {
  DOCUMENT_STATUS,
  DOCUMENT_TYPES,
} from '@savgroup-front-common/constants/src/shared';
import { ICONS_TYPE, SUPPORTED_COMPONENTS } from '@savgroup-front-common/types';

import { CommonAppState } from '../../../../domains';
import { labelsValue } from '../../../../domains/carriers/selectors';
import { Selectors as ClaimsSelectors } from '../../../../domains/claims';
import { SafeFormattedMessageWithoutSpread } from '../../../../formatters';
import { SUPPORTED_ICON_POSITIONS } from '../../../../helpers';
import printDocumentWithUrl from '../../../../helpers/printDocumentWithUrl';
import useGetShippingLabel from '../../../../hooks/useGetShippingLabel';
import { DownloadIcon } from '../../../../protons/icons';
import { MyAccountIcon } from '../../../../protons/IconsNewDesign';

import {
  $DocumentButton,
  $DocumentIcon,
  $DocumentName,
  $DocumentWrapper,
} from './FileDownloader.styles';
import documentNameByType from './helpers/documentNameByType';
import { useGetDocumentStatusById, useLoadFullClaimInfo } from './hooks';
import messages from './messages';

interface FileDownloaderProps {
  isLoading: boolean;
  documentName: string;
  getDocumentStatusByFileId: ({
    fileId,
    type,
  }: {
    fileId: string;
    type: string;
  }) => void;
  fileId: string;
  type: string;
  getDocumentStatusByFileIdValue?: Record<string, string>;
  claimId: string;
  claimGroupId?: string;
  partnerTransportDocumentUrl?: string;
}

const FileDownloader: FunctionComponent<
  React.PropsWithChildren<FileDownloaderProps>
> = ({
  isLoading,
  documentName,
  getDocumentStatusByFileId,
  fileId,
  type,
  getDocumentStatusByFileIdValue,
  claimId,
  partnerTransportDocumentUrl,
  claimGroupId,
}) => {
  useGetDocumentStatusById({ fileId, type, getDocumentStatusByFileId });
  useLoadFullClaimInfo({ getDocumentStatusByFileIdValue, type, claimGroupId });
  const fullClaim = useSelector((state: CommonAppState) =>
    ClaimsSelectors.getFullClaimById(state, { claimId }),
  );

  const document = find(
    get(fullClaim, 'documents'),
    (documents) => documents.name === type,
  );

  const documentStatus = get(getDocumentStatusByFileIdValue, type);
  const { value: shippinglabelValue } = useSelector((state: CommonAppState) =>
    labelsValue(state, { fileId }),
  );
  const shippingLabel = head(shippinglabelValue);

  const message = documentNameByType({ type });
  const getShippingLabel = useGetShippingLabel({
    fileId,
    partnerTransportDocumentUrl,
  });

  return (
    <$DocumentWrapper>
      <$DocumentIcon>
        <MyAccountIcon icon={ICONS_TYPE.NEWSPAPER_ICON} color="#8A94A6" />
      </$DocumentIcon>
      <$DocumentName>
        <SafeFormattedMessageWithoutSpread message={documentName || message} />
      </$DocumentName>
      <$DocumentButton
        onClick={() => {
          if (type === DOCUMENT_TYPES.SHIPPING_LABEL) {
            getShippingLabel();
          } else if (documentStatus === DOCUMENT_STATUS.CREATED) {
            printDocumentWithUrl({ documentUrl: get(document, 'url') });
          }
        }}
        icon={<DownloadIcon />}
        position={SUPPORTED_ICON_POSITIONS.LEFT}
        type={BUTTON_TYPES.BUTTON}
        naked
        small
        primary
        isLoading={
          isLoading ||
          documentStatus === DOCUMENT_STATUS.IN_PROGRESS ||
          documentStatus === DOCUMENT_STATUS.UNKNOWN ||
          (DOCUMENT_TYPES.SHIPPING_LABEL === type &&
            !shippingLabel &&
            !partnerTransportDocumentUrl)
        }
        disabled={documentStatus === DOCUMENT_STATUS.FAILURE}
        componentThemeName={SUPPORTED_COMPONENTS.FILE_DOWNLOADER_BUTTON}
      >
        <SafeFormattedMessageWithoutSpread
          message={messages.downloadDocument}
        />
      </$DocumentButton>
    </$DocumentWrapper>
  );
};

FileDownloader.displayName = 'FileDownloader';

export default FileDownloader;
