import { useQuery } from 'react-query';

import { ClaimService } from 'myaccount/api';

import { GetSolutionsByClaimGroupReturnValues } from '../../../api/Claim/getSolutionsByClaimGroup';

export const GetSolutionsByClaim = 'getSolutionsByClaim';

export const useGetSolutionsByClaim = ({
  claimGroupId,
  onSuccess,
}: {
  claimGroupId?: string;
  onSuccess?: (response?: GetSolutionsByClaimGroupReturnValues) => void;
}) => {
  const {
    data: solutions,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery(
    [GetSolutionsByClaim, { claimGroupId }],
    async () => {
      if (!claimGroupId) {
        return undefined;
      }

      const response = await ClaimService.getSolutionsByClaimGroup({
        claimGroupId,
      });

      if (response.failure) {
        return undefined;
      }

      return response.value;
    },
    {
      staleTime: Infinity,
      onSuccess,
    },
  );

  return {
    solutions: solutions?.solutions,
    solutionsCount: solutions?.solutions?.length || 0,
    isSolutionsLoading: isLoading || isRefetching,
    refetch,
  };
};
