import concat from 'lodash/concat';
import endsWith from 'lodash/endsWith';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import size from 'lodash/size';
import startsWith from 'lodash/startsWith';
import sum from 'lodash/sum';

const INVOICE_ITEMS_ORDER = [
  'Quote',
  'Solution',
  'HomePickup',
  'TransportHome',
  'TransportDeposit',
  'TransportDelivery',
  'TransportDepositInsurance',
  'TransportDeliveryInsurance',
];

const invoiceItemsDefaultComparator = (item1, item2) => {
  const kindIndex1 = INVOICE_ITEMS_ORDER.indexOf(item1.kind);
  const kindIndex2 = INVOICE_ITEMS_ORDER.indexOf(item2.kind);

  return kindIndex1 - kindIndex2;
};

export function formatInvoice(invoice) {
  const transformedInvoice = map(invoice.item, (item) => ({
    ...item,
    kind: startsWith(item.kind, 'HomePickup') ? 'TransportDeposit' : item.kind,
  })).sort(invoiceItemsDefaultComparator);
  const { transportItems, insuranceItems, otherItems } = groupBy(
    transformedInvoice,
    (item) => {
      if (endsWith(item.kind, 'Insurance')) {
        return 'insuranceItems';
      }
      if (startsWith(item.kind, 'Transport')) {
        return 'transportItems';
      }

      return 'otherItems';
    },
  );
  const formatItem = (item) => ({
    kind: item.kind,
    referenceId: item.referenceId,
    price: item.price / 100,
    priceWithTax: item.priceWithTax / 100,
    tax: item.tax / 100,
    ...(item.subItems && { subItems: map(item.subItems, formatItem) }),
  });
  const summarize = (items, kind) =>
    size(items) > 0
      ? {
          kind,
          referenceId: null,
          price: sum(map(items, (item) => item.price)),
          priceWithTax: sum(map(items, (item) => item.priceWithTax)),
          tax: sum(map(items, (item) => item.tax)),
          subItems: items,
        }
      : [];
  const transportTotal = summarize(transportItems, 'Transport');
  const insuranceTotal = summarize(insuranceItems, 'Insurance');

  return (
    invoice && {
      id: invoice.id,
      items: map(
        concat(otherItems || [], transportTotal, insuranceTotal),
        formatItem,
      ),
      priceWithTax: invoice.priceWithTax,
      totalPrice: invoice.price / 100,
      totalPriceWithTax: invoice.priceWithTax / 100,
      tax: invoice.tax / 100,
    }
  );
}
