import { uniq } from 'lodash';
import get from 'lodash/get';
import { Action } from 'redux-actions';

import { Store } from '@savgroup-front-common/types';

import * as types from './actionTypes';
import { STORE_DOMAIN, StoresDomainState } from './types';

const initialState: StoresDomainState = {
  [STORE_DOMAIN.LIST]: {},
  [STORE_DOMAIN.BY_SELLER_ID]: {},
};

export interface OnGetStoreSuccessPayload {
  value: Store[];
}

function onGetStoreSuccess(
  state: StoresDomainState,
  { payload }: { payload: OnGetStoreSuccessPayload },
): StoresDomainState {
  const { value } = payload;
  const stores = value;

  return {
    ...state,
    [STORE_DOMAIN.LIST]: stores.reduce((acc, store) => {
      return {
        ...acc,
        [store.id]: store,
      };
    }, state[STORE_DOMAIN.LIST]),
    [STORE_DOMAIN.BY_SELLER_ID]: stores.reduce((acc, store) => {
      return {
        ...acc,
        [store.sellerId]: uniq([...get(acc, store.sellerId, []), store.id]),
      };
    }, state[STORE_DOMAIN.BY_SELLER_ID]),
  };
}

export default function mainReducer(
  state: StoresDomainState = initialState,
  action: Action<OnGetStoreSuccessPayload>,
): StoresDomainState {
  switch (action.type) {
    case types.GET_STORES_BY_SELLER_ID.SUCCEEDED:
    case types.GET_STORE.SUCCEEDED:
      return onGetStoreSuccess(state, action);

    default:
      return state;
  }
}
