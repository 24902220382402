import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  AddressInfoDto,
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.owner}creation/byproductreference/orders`;

interface CreateOrderByProductReferenceCommandPayload {
  civility: string;
  customerLastName?: string;
  customerFirstName?: string;
  address?: AddressInfoDto;
  phoneNumber?: string;
  customerMail?: string;
  product: {
    id: string;
    sku?: string;
    price: {
      amount: number;
      currency: string;
    };
  };
  isOutOfWarranty: boolean;
  sellerId?: string;
  tenantId?: string;
}

interface CreateOrderByProductReferenceCommandResponse {
  orderId: string;
  orderReference: string;
  ownerId: string;
}

export const createOrderByProductReferenceCommand = async (
  payload: CreateOrderByProductReferenceCommandPayload,
): Promise<
  | BackResponse<CreateOrderByProductReferenceCommandResponse>
  | BackResponseFailure
> => {
  try {
    const response = await apiCall<
      BackResponse<CreateOrderByProductReferenceCommandResponse>
    >(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: payload,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};
