import React, { useContext } from 'react';

import { Field } from '../../../molecules/Field';
import { ActorInformationItem } from '../../ActorInformation/components/GenericActorInformation/GenericActorInformation.styles';
import messages from '../../ActorInformation/components/GenericActorInformation/messages';
import { ContentTemplateContext } from '../MDXCustomComponents.context';

const FloorCurrentActor = () => {
  const { actorSummary } = useContext(ContentTemplateContext);
  const address = actorSummary?.address;
  const floor = address?.floor;

  return (
    <>
      {floor && (
        <ActorInformationItem>
          <Field label={messages.floor} copy={floor}>
            {floor}
          </Field>
        </ActorInformationItem>
      )}
    </>
  );
};

FloorCurrentActor.displayName = 'FloorCurrentActor';

export default FloorCurrentActor;
