import { Timeslot } from '@savgroup-front-common/types';

export function byStartLocalTime(
  firstInterval: Timeslot,
  secondInterval: Timeslot,
): -1 | 1 | 0 {
  if (firstInterval.start < secondInterval.start) {
    return -1;
  }

  if (firstInterval.start > secondInterval.start) {
    return 1;
  }

  return 0;
}
