import { fromJS } from 'immutable';
import get from 'lodash/get';

import * as ActionTypes from '../actionTypes';

function onCommercialSolutionLoading(state, { meta }) {
  const fileId = get(meta, ['fileId']);

  return state.setIn([fileId], fromJS({ isLoaded: false, isDirty: true }));
}

function onCommercialSolutionLoaded(state, { meta }) {
  const fileId = get(meta, ['fileId']);

  return state.setIn([fileId], fromJS({ isLoaded: true, isDirty: true }));
}

function onCommercialSolutionErrored(state, { meta, errors }) {
  const fileId = get(meta, ['fileId']);

  return state.setIn(
    [fileId],
    fromJS({ isLoaded: true, isDirty: true, hasErrors: true }).updateIn(
      ['errors'],
      () => errors,
    ),
  );
}

export default function reducer(state = fromJS({}), action) {
  switch (action.type) {
    case ActionTypes.SET_COMMERCIAL_SOLUTION.STARTED: {
      return onCommercialSolutionLoading(state, action);
    }

    case ActionTypes.SET_COMMERCIAL_SOLUTION.SUCCEEDED: {
      return onCommercialSolutionLoaded(state, action);
    }

    case ActionTypes.SET_COMMERCIAL_SOLUTION.ERRORED: {
      return onCommercialSolutionErrored(state, action);
    }
    default: {
      return state;
    }
  }
}
