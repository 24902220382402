import { defineMessages } from 'react-intl';

export default defineMessages({
  new: {
    id: 'view.issueAQuotation.new',
    defaultMessage: 'New {amount}',
  },
  used: {
    id: 'view.issueAQuotation.used',
    defaultMessage: 'Used {amount}',
  },
});
