import styled from 'styled-components';

import { rem } from '../../helpers';
import { getTextColorBasedOnProps } from '../../theme/helpers';

const H1 = styled.h1.attrs({ role: 'presentation' })`
  font-size: 28px;
  line-height: ${rem('40px')};
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  margin: 0;
  padding-top: ${rem('32px')};
  padding-bottom: ${rem('32px')};
  color: ${(props) => getTextColorBasedOnProps(props)};
  text-align: ${(props) => props.align};
`;

export default H1;
