import { defineMessages } from 'react-intl';

export default defineMessages({
  confirmedTechnicalInterventionScheduleTitle: {
    id: 'components.myaccount.confirmedTechnicalInterventionSchedule.confirmedTechnicalInterventionScheduleTitle',
    defaultMessage: 'Confirmed technical intervention schedule',
  },
  cancelTechnicalInterventionSchedule: {
    id: 'components.myaccount.confirmedTechnicalInterventionSchedule.cancelTechnicalInterventionSchedule',
    defaultMessage: 'Cancel the schedule',
  },
  confirmedTechnicalInterventionScheduleLabel: {
    id: 'components.myaccount.confirmedTechnicalInterventionScheduleLabel',
    defaultMessage: 'Date and time',
  },
  confirmedTechnicalInterventionScheduleDescriptionLabel: {
    id: 'components.myaccount.confirmedTechnicalInterventionScheduleDescriptionLabel',
    defaultMessage: 'Details',
  },
  confirmedTechnicalInterventionScheduleDescription: {
    id: 'components.myaccount.confirmedTechnicalInterventionScheduleDescription',
    defaultMessage: 'Confirmed technical intervention schedule description',
  },
  technicalInterventionAppointmentValue: {
    id: 'view.folder.nav.fileSpareParts.technicalIntervention.Appointment.value',
    defaultMessage: '{startDate} from {startTime} to {endTime}',
  },
  technicalInterventionScheduleCancellationConfirmMessage: {
    id: 'components.myaccount.technicalInterventionScheduleCancellationConfirmMessage',
    defaultMessage: `Do you confirm to cancel the schedule "{confirmedScheduleText}" ?`,
  },
  'Reverse.Workflow.Errors.Files.TechnicalInterventionInProgress': {
    id: 'Reverse.Workflow.Errors.Files.TechnicalInterventionInProgress',
    defaultMessage: 'Unable to cancel a technical intervention in progress',
  },
  'Reverse.Workflow.Errors.Files.TechnicalInterventionFinished': {
    id: 'Reverse.Workflow.Errors.Files.TechnicalInterventionFinished',
    defaultMessage: 'Unable to cancel a finished technical intervention',
  },
  confirm: {
    id: 'components.myaccount.technicalInterventionScheduleCancellationConfirm',
    defaultMessage: 'Confirm technical intervention schedule cancellation',
  },
  cancel: {
    id: 'components.myaccount.technicalInterventionScheduleCancellationCancelMessage',
    defaultMessage: 'Cancel',
  },
  confirmModalTitle: {
    id: 'components.myaccount.technicalInterventionScheduleCancellation.confirmModalTitle',
    defaultMessage: 'Are you sure?',
  },
  confirmModalDidactic: {
    id: 'components.myaccount.technicalInterventionScheduleCancellation.confirmModalDidactic',
    defaultMessage: 'this action is irreversible.',
  },
  rescheduleTechnicalInterventionSchedule: {
    id: 'components.myaccount.confirmedTechnicalInterventionSchedule.rescheduleTechnicalInterventionSchedule',
    defaultMessage: 'Reschedule',
  },
});
