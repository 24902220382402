import { useQuery } from 'react-query';

import { SellerConfiguration } from '@savgroup-front-common/types';

import { CommonSellerService } from '../api';
import useToasts from '../molecules/NotificationsProvider/ToastsProvider/hooks';

interface UseGetSellerConfigurationByIdReturnValue {
  data?: SellerConfiguration;
  isLoading: boolean;
}

const useGetSellerConfigurationById = ({
  sellerId,
}: {
  sellerId: string;
}): UseGetSellerConfigurationByIdReturnValue => {
  const { pushErrors, removeAllNotifications } = useToasts();

  const { data, isLoading } = useQuery(
    ['useGetSellerConfigurationById', { sellerId }],
    async () => {
      if (!sellerId) {
        return undefined;
      }

      removeAllNotifications();

      const response = await CommonSellerService.getSellerConfigurationQuery({
        sellerId,
      });

      if (response.failure) {
        pushErrors(response.errors);

        return undefined;
      }

      return response.value;
    },
    {
      staleTime: Infinity,
    },
  );

  return { data, isLoading };
};

export default useGetSellerConfigurationById;
