import { useQuery } from 'react-query';

import { ClaimService } from 'myaccount/api';
import { AdditionalInformation } from 'myaccount/api/Claim/getAdditionalInformationEntitySummaryQuery';

export const GetAdditionalInformationEntitySummaryQuery =
  'getAdditionalInformationEntitySummaryQuery';

export const useGetMultiAdditionalInformationEntitySummaryQuery = ({
  additionalInformationIds,
  countryCode,
}: {
  additionalInformationIds?: string[];
  countryCode?: string;
}) => {
  const { data: additionalInformations } = useQuery(
    [GetAdditionalInformationEntitySummaryQuery, { additionalInformationIds }],
    async () => {
      if (!additionalInformationIds) {return undefined;}

      return Promise.all(
        additionalInformationIds?.map(async (additionalInformationId) => {
          const response =
            await ClaimService.getAdditionalInformationEntitySummaryQuery({
              additionalInformationId,
              countryCode,
            });

          if (response.failure) {
            return undefined;
          }

          return response.value;
        }),
      ).then(
        (value) =>
          value.filter((fileSummary) => fileSummary) as AdditionalInformation[],
      );
    },
  );

  return {
    additionalInformations,
  };
};
