import { DOCUMENT_TYPES } from '@savgroup-front-common/constants';
import { rmaSupplierInfosValue } from '@savgroup-front-common/core/src/domains/claims/selectors';
import { selectStoreById } from '@savgroup-front-common/core/src/domains/store/selectors';
import { CustomerFileSummary } from '@savgroup-front-common/types';
import { useMyAccountTypedSelector } from 'myaccount/hooks';

interface UseClaimsSummaryArgs {
  fileInfo: CustomerFileSummary;
}

export const useClaimsSummary = ({ fileInfo }: UseClaimsSummaryArgs) => {
  const {
    attachedDocuments,
    trackingNumbers,
    homePickupInfos,
    claimInfoSummary: { claimId },
    actorId: storeId,
    storeActorId,
  } = fileInfo;

  const partnerTransportDocumentUrl = attachedDocuments?.find(
    (document) => document.attachedDocumentType === DOCUMENT_TYPES.TRANSPORT,
  )?.documentUrl;

  const rmaSupplierInfos = useMyAccountTypedSelector((state) =>
    rmaSupplierInfosValue(state, claimId),
  );

  const rmaSupplier = rmaSupplierInfos?.value;
  const storeInfo = useMyAccountTypedSelector((state) =>
    selectStoreById(state, { storeId }),
  );
  const storeActor = useMyAccountTypedSelector((state) =>
    selectStoreById(state, {
      storeId: storeActorId,
    }),
  );

  return {
    homePickupInfos,
    rmaSupplier,
    storeInfo,
    storeActor,
    trackingNumbers,
    claimId,
    partnerTransportDocumentUrl,
  };
};
