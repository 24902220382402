import { defineMessages } from 'react-intl';

export default defineMessages({
  Repairer: { id: 'ActorTypes.Repairer', defaultMessage: 'Repairer' },
  Seller: { id: 'ActorTypes.Seller', defaultMessage: 'Seller' },
  Owner: { id: 'ActorTypes.Owner', defaultMessage: 'Owner' },
  RiskCarrier: { id: 'ActorTypes.RiskCarrier', defaultMessage: 'RiskCarrier' },
  Warehouse: { id: 'ActorTypes.Warehouse', defaultMessage: 'Warehouse' },
  Technical: { id: 'ActorTypes.Technical', defaultMessage: 'Technical' },
  Accountable: { id: 'ActorTypes.Accountable', defaultMessage: 'Accountable' },
  Data: { id: 'ActorTypes.Data', defaultMessage: 'Data' },
  Order: { id: 'ActorTypes.Order', defaultMessage: 'Order' },
  Supplier: { id: 'ActorTypes.Supplier', defaultMessage: 'Supplier' },
  Store: { id: 'ActorTypes.Store', defaultMessage: 'Store' },
  PurchaseStore: {
    id: 'ActorTypes.PurchaseStore',
    defaultMessage: 'PurchaseStore',
  },
  DepositStore: {
    id: 'ActorTypes.DepositStore',
    defaultMessage: 'DepositStore',
  },
  ImplicitSupplier: {
    id: 'ActorTypes.ImplicitSupplier',
    defaultMessage: 'ImplicitSupplier',
  },
  ImplicitStore: {
    id: 'ActorTypes.ImplicitStore',
    defaultMessage: 'ImplicitStore',
  },
  Site: { id: 'ActorTypes.Site', defaultMessage: 'Site' },
  DeliveryStore: {
    id: 'ActorTypes.DeliveryStore',
    defaultMessage: 'DeliveryStore',
  },
  Insurer: {
    id: 'ActorTypes.Insurer',
    defaultMessage: 'Insurer',
  },
});
