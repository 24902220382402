import React, { FunctionComponent, useContext } from 'react';

import { Field } from '../../../../molecules/Field';
import { ActorInformationItem } from '../../../ActorInformation/components/GenericActorInformation/GenericActorInformation.styles';
import messages from '../../../ActorInformation/components/GenericActorInformation/messages';
import { MDXContent } from '../../../MDXContent/MDXContent';
import { customComponents } from '../../MDXCustomComponents';
import { ContentTemplateContext } from '../../MDXCustomComponents.context';

import { $NoteContainer } from './NoteCurrentActor.styles';

const NoteCurrentActor: FunctionComponent = () => {
  const { actorSummary } = useContext(ContentTemplateContext);
  const note = actorSummary?.note;

  return (
    <>
      {note && (
        <ActorInformationItem>
          <Field label={messages.note}>
            <$NoteContainer>
              <MDXContent customComponents={customComponents}>
                {note}
              </MDXContent>
            </$NoteContainer>
          </Field>
        </ActorInformationItem>
      )}
    </>
  );
};

NoteCurrentActor.displayName = 'NoteCurrentActor';

export default NoteCurrentActor;
