import styled from 'styled-components';
import { Grid } from 'react-styled-flexboxgrid';
import { Card } from '@savgroup-front-common/core/src/atoms/Cards';
import { media } from '@savgroup-front-common/constants';

export const $LayoutGrid = styled(Grid)`
  width: 100%;
  padding: 1rem;

  @media ${media.maxWidth.sm} {
    padding: 0;
  }
`;

export const $LayoutGridDefault = styled(Grid)`
  padding: 1rem;
`;

export const $Card = styled(Card)<{ isCliquable?: boolean }>`
  @media ${media.maxWidth.sm} {
    padding: 1rem;
  }

  transition: 0.15s transform ease-in-out;
  backface-visibility: hidden;
  margin-bottom: 24px;
  -webkit-font-smoothing: antialiased;

  ${({ isCliquable }) =>
    isCliquable
      ? `
    &:hover {
      transform: scale3d(1.015, 1.015, 1);
    }
  `
      : ''}
`;

export const $CardContent = styled.div`
  display: flex;
  align-items: center;
  padding: 0 calc(1rem - 2px);

  @media ${media.maxWidth.sm} {
    padding: 0;
  }
`;
