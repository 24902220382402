import React, { FormEvent, FormEventHandler, ReactNode } from 'react';
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';

interface FormProps<T extends FieldValues> {
  onChange?: (event: FormEvent<EventTarget | HTMLFormElement>) => void;
  onSubmit?: FormEventHandler<HTMLFormElement>;
  formContext: UseFormReturn<T>;
  children: ReactNode;
}

function Form<T extends FieldValues>({
  children,
  onChange,
  onSubmit,
  formContext,
}: FormProps<T>) {
  return (
    <FormProvider {...formContext}>
      <form onChange={onChange} onSubmit={onSubmit}>
        {children}
      </form>
    </FormProvider>
  );
}

Form.displayName = 'Form';

export default Form;
