import React, { FC, useCallback, useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { SwipeableDrawer } from '@savgroup-front-common/core/src/atoms/SwipeableDrawer';
import {
  DIRECTION,
  DRAWER_SIZE,
} from '@savgroup-front-common/core/src/atoms/SwipeableDrawer/SwipeableDrawer';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { ScanBarcodeIcon } from '@savgroup-front-common/core/src/protons/icons';

import BarcodeReaderContent from './BarcodeReaderContent';
import { $BarCodeButtonContainer } from './BarcodeReaderContent.styles';
import messages from './messages';

interface BarcodeReaderProps {
  onProductScan: ({
    value,
    typeName,
  }: {
    value: string;
    typeName: string;
  }) => void;
  isAddProductLoading: boolean;
}

const BarcodeReader: FC<BarcodeReaderProps> = ({
  onProductScan,
  isAddProductLoading,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = useCallback(async () => {
    setIsModalOpen(true);
  }, []);

  const [element, setElement] = useState<HTMLElement>();

  useLayoutEffect(() => {
    const tmp = document.getElementById('secondaryAction');

    if (tmp) {
      setElement(tmp);
    }
  }, []);

  if (!element) {
    return <></>;
  }

  return (
    <$BarCodeButtonContainer>
      <SwipeableDrawer
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        hasBackdrop
        direction={DIRECTION.TOP}
        size={DRAWER_SIZE.FULL_SCREEN}
        hasCloseButton={false}
      >
        <BarcodeReaderContent
          onClose={() => setIsModalOpen(false)}
          onProductScan={onProductScan}
        />
      </SwipeableDrawer>

      {createPortal(
        <Button
          icon={<ScanBarcodeIcon />}
          primary
          fluid
          type={BUTTON_TYPES.BUTTON}
          onClick={handleOpenModal}
          isLoading={isAddProductLoading}
        >
          <SafeFormattedMessageWithoutSpread message={messages.scanButton} />
        </Button>,
        element,
      )}
    </$BarCodeButtonContainer>
  );
};

BarcodeReader.displayName = 'BarcodeReader';

export default BarcodeReader;
