import { defineMessages } from 'react-intl';

export default defineMessages({
  submitButton: {
    id: 'components.myaccount.claims.appointmentCard.submitButton',
    defaultMessage: 'Confirm',
  },
  returnModalText1: {
    id: 'components.myaccount.claims.appointmentCard.returnModalText1',
    defaultMessage:
      'You are about to cancel the home pickup option. If there was any amount paid previously, no refund is possible. You will be though able to drop your package in a pickup points of your choice.',
  },
  returnModalText2: {
    id: 'components.myaccount.claims.appointmentCard.returnModalText2',
    defaultMessage: 'Do you want to proceed?',
  },
  contactSupport: {
    id: 'components.myaccount.claims.appointmentCard.contactSupport',
    defaultMessage:
      'An error has occured. Please contact the support customer.',
  },
  needToSelectAnotherSchedule: {
    id: 'components.myaccount.claims.appointmentCard.warning.needToSelectAnotherSchedule',
    defaultMessage:
      'Please select another schedule, the one selected is not available anymore.',
  },
  previous: {
    id: 'view.claimModal.goBack',
    defaultMessage: 'Back to previous step',
  },
  createHomePickupError: {
    id: 'view.homePickup.createHomePickupError',
    defaultMessage: 'Error on chosen date',
  },
});
