import { useCallback, useLayoutEffect, useState } from 'react';

interface UseCollapseArgs {
  isOpen: boolean;
}

const useAsyncCollapse = ({ isOpen }: UseCollapseArgs): boolean => {
  const [innerIsOpen, setInnerIsOpen] = useState(isOpen);

  const [timeoutId, setTimeoutId] = useState<any | null>(null);

  const clear = useCallback(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setTimeoutId(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    if (isOpen !== innerIsOpen) {
      setTimeoutId(
        window.setTimeout(() => {
          setInnerIsOpen(isOpen);
          clear();
        }, 10),
      );
    }

    return () => {
      clear();
    };
  }, [clear, innerIsOpen, isOpen]);

  return innerIsOpen;
};

export default useAsyncCollapse;
