import React, { FC, useLayoutEffect, useState } from 'react';
import { Transition } from 'react-transition-group';

import {
  $MountBlockContainer,
  $UnmountBlockContainer,
} from './AnimatedRenderer.styles';
import { AnimationStyles } from './AnimatedRenderer.types';

const AnimatedRenderer: FC<
  React.PropsWithChildren<{
    isOpen: boolean;
    delay?: number;
    animationDuration?: number;
    animationStyle?: AnimationStyles;
  }>
> = ({
  isOpen,
  delay = 50,
  animationDuration = 100,
  children,
  animationStyle = AnimationStyles.SCALE_IN_TO_SCALE_DOWN,
}) => {
  const [isOpenDeferred, setIsOpenDeferred] = useState(false);
  const [mountDirection, setMountDirection] = useState<string | undefined>();

  useLayoutEffect(() => {
    const id = window.setTimeout(() => {
      setMountDirection(isOpen ? 'mounting' : 'unmounting');
      setIsOpenDeferred(isOpen);
    }, delay);

    return () => {
      clearTimeout(id);
    };
  }, [delay, isOpen]);

  return (
    <Transition
      in={isOpenDeferred}
      timeout={animationDuration}
      mountOnEnter
      unmountOnExit
    >
      {(animationState) => (
        <>
          {mountDirection === 'mounting' && (
            <$MountBlockContainer
              animationState={animationState}
              animationDuration={animationDuration}
              animationStyle={animationStyle}
            >
              {children}
            </$MountBlockContainer>
          )}
          {mountDirection === 'unmounting' && (
            <$UnmountBlockContainer
              animationState={animationState}
              animationDuration={animationDuration}
              animationStyle={animationStyle}
            >
              {children}
            </$UnmountBlockContainer>
          )}
        </>
      )}
    </Transition>
  );
};

AnimatedRenderer.displayName = 'AnimatedRenderer';

export default AnimatedRenderer;
