import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  InvoiceSummaryDto,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.payment}invoices/ClaimGroup/${COMMON_QUERY_PARAMS.CLAIM_GROUP_ID}/opened`;

interface GetOpenedInvoiceQueryArgs {
  claimGroupId: string;
}

const getOpenedInvoiceQuery = async ({
  claimGroupId,
}: GetOpenedInvoiceQueryArgs): Promise<
  BackResponse<InvoiceSummaryDto> | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(
      COMMON_QUERY_PARAMS.CLAIM_GROUP_ID,
      claimGroupId,
    );

    return await apiCall<BackResponse<InvoiceSummaryDto>>(url, {
      method: SUPPORTED_METHODS.GET,
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default getOpenedInvoiceQuery;
