/* eslint-disable no-console */
import { fromJS } from 'immutable';
import get from 'lodash/get';

export function onClaimConfirmationLoading(state, { meta }) {
  const claimId = get(meta, ['claimId']);

  if (!claimId) {
    console.warn('[onClaimConfirmationLoading] ClaimId is undefined');

    return state;
  }

  return state.setIn(
    ['claimsConfirmation', claimId],
    fromJS({ isLoaded: false, isDirty: false, value: undefined }),
  );
}

export function onClaimConfirmationLoadingErrored(state, { meta }) {
  const claimId = get(meta, ['claimId']);

  if (!claimId) {
    console.warn('[onClaimConfirmationLoadingErrored] ClaimId is undefined');

    return state;
  }

  // TODO errors
  return state.updateIn(['claimsConfirmation', claimId], (oldValue) =>
    oldValue.setIn(['isDirty'], true).setIn(['hasErrors'], true),
  );
}

export function onClaimConfirmationLoaded(state, { payload, meta }) {
  const claimId = get(meta, ['claimId']);
  const claimConfirmation = get(payload, ['value']);

  if (!claimId) {
    console.warn('[onClaimConfirmationLoaded] ClaimId is undefined.');

    return state;
  }

  return state.setIn(
    ['claimsConfirmation', claimId],
    fromJS({ isLoaded: true, isDirty: false }).setIn(
      ['value'],
      claimConfirmation,
    ),
  );
}
