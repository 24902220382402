import React, { FC, Suspense } from 'react';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';

import { Button } from '../../../../atoms/button';
import { SafeFormattedMessageWithoutSpread } from '../../../../formatters';
import { BaseLoader } from '../../../../molecules/BaseLoader';
import { CrossIcon } from '../../../../protons/icons';
import TakeInterventionAppointment from '../../components/TakeInterventionAppointment/TakeInterventionAppointment';

import useRescheduleTechnicalInterventionModalContent from './hooks/useRescheduleTechnicalInterventionModalContent';
import messages from './messages';

type RescheduleTechnicalInterventionModalContentProps = {
  fileId: string;
  sellerId: string;
  onRescheduleSuccess: () => void;
  onCancel: () => void;
};

const RescheduleTechnicalInterventionModalContent: FC<
  RescheduleTechnicalInterventionModalContentProps
> = ({ fileId, sellerId, onRescheduleSuccess, onCancel }) => {
  const { handleValidateNewSchedule, isRescheduleNewScheduleLoading } =
    useRescheduleTechnicalInterventionModalContent({
      fileId,
      onRescheduleSuccess,
    });

  return (
    <Suspense fallback={<BaseLoader />}>
      <TakeInterventionAppointment
        fileId={fileId}
        sellerId={sellerId}
        onSubmit={handleValidateNewSchedule}
        isLoading={isRescheduleNewScheduleLoading}
      >
        <Button
          dataTestId="cancelRescheduleButton"
          type={BUTTON_TYPES.BUTTON}
          secondary
          icon={<CrossIcon />}
          onClick={onCancel}
        >
          <SafeFormattedMessageWithoutSpread message={messages.buttonCancel} />
        </Button>
      </TakeInterventionAppointment>
    </Suspense>
  );
};

RescheduleTechnicalInterventionModalContent.displayName =
  'RescheduleTechnicalInterventionModalContent';

export default RescheduleTechnicalInterventionModalContent;
