import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import {
  BackResponse,
  BackResponseFailure,
  ReverseMoneyNumberAmount,
  SparePartCondition,
  SparePartStockAvailability,
  SpareProductSize,
} from '@savgroup-front-common/types';

import { buildUrl, prepareResponseFailure } from '../../helpers';
import { apiCall } from '../../services';

export interface SparePartSearchSummaryDto {
  sparePartId: string;
  sparePartSupplierAssociationId: string;
  manufacturerLabel: string;
  manufacturerName: string;
  supplierName: string;
  manufacturerId: string;
  manufacturerReference: string;
  supplierPartReference: string;
  sparePartSupplierId: string;
  condition: SparePartCondition;
  size: SpareProductSize;
  pretaxPurchasePrice: ReverseMoneyNumberAmount;
  recommendedSalePriceIncludingTaxes: ReverseMoneyNumberAmount;
  availableQuantity: number;
  stockAvailability: SparePartStockAvailability;
  category: string;
  subCategory: string;
  minDeliveryDays: number;
  maxDeliveryDays: number;
  stockName: string;
  stockOrigin: string;
  isOEM?: boolean;
  linkType?: string;
}

export interface SearchSparePartsByNamesArgs {
  sellerId: string;
  manufacturerReference: string;
  manufacturerName: string;
  supplierReference: string;
  supplierName: string;
  stockName: string;
}

export const searchSparePartsByNames = async ({
  manufacturerName,
  supplierName,
  supplierReference,
  manufacturerReference,
  sellerId,
  stockName,
}: SearchSparePartsByNamesArgs): Promise<
  BackResponse<SparePartSearchSummaryDto[]> | BackResponseFailure
> => {
  try {
    return await apiCall<BackResponse<SparePartSearchSummaryDto[]>>(
      buildUrl(`${APIConfiguration.sparePart}parts/searchbynames`, {
        manufacturerName,
        supplierName,
        supplierReference,
        manufacturerReference,
        sellerId,
        stockName,
      }),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};
