import { defineMessages } from 'react-intl';

export default defineMessages({
  totalVATExcluded: {
    id: 'components.myaccount.myReturn.quoteInformation.totalVATExcluded',
    defaultMessage: 'Total VAT excl.',
  },
  vat: {
    id: 'components.myaccount.myReturn.quoteInformation.vat',
    defaultMessage: 'VAT',
  },
  totalVATIncluded: {
    id: 'components.myaccount.myReturn.quoteInformation.totalVATIncluded',
    defaultMessage: 'Total VAT incl.',
  },
  totalDiscount: {
    id: 'components.myaccount.myReturn.quoteInformation.totalDiscount',
    defaultMessage: 'Total discount',
  },
});
