import React, { FC, useState } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';

import { BUTTON_TYPES } from '@savgroup-front-common/constants/src/shared';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { Card } from '@savgroup-front-common/core/src/atoms/Cards';
import {
  safeFormattedIntlString,
  SafeFormattedMessageWithoutSpread,
} from '@savgroup-front-common/core/src/formatters';

import messages from './messages';
import {
  CheckBoxPlacement,
  RowButtonContainer,
  UnlinkTitleH2,
  UnlinkWrapper,
} from './UnlinkDevice.styles';

interface UnlinkDeviceProps {
  renderSubmit?: boolean;
  onSubmit: () => void;
}

const UnlinkDevice: FC<UnlinkDeviceProps> = ({ renderSubmit, onSubmit }) => {
  const [disabled, setDisabled] = useState(true);

  const handleChange = () => {
    setDisabled(!disabled);
  };

  return (
    <>
      <Card>
        <Row between="md">
          <Col xs={12} md={5}>
            <UnlinkWrapper>
              <Col xs={11}>
                <UnlinkTitleH2 heading>
                  <SafeFormattedMessageWithoutSpread message={messages.title} />
                </UnlinkTitleH2>
              </Col>
            </UnlinkWrapper>
          </Col>
          <Col xs={12} md={7}>
            <SafeFormattedMessageWithoutSpread message={messages.description} />

            <CheckBoxPlacement
              name="disabled"
              label={safeFormattedIntlString(messages.checkBox)}
              onClick={handleChange}
            />

            {!renderSubmit && (
              <RowButtonContainer end="md">
                <Col xs={12} md={6}>
                  <Button
                    type={BUTTON_TYPES.BUTTON}
                    fluid
                    primary
                    disabled={disabled}
                    onClick={onSubmit}
                  >
                    <SafeFormattedMessageWithoutSpread
                      message={messages.confirm}
                    />
                  </Button>
                </Col>
              </RowButtonContainer>
            )}
          </Col>
        </Row>
      </Card>
    </>
  );
};

UnlinkDevice.displayName = 'UnlinkDevice';

export default UnlinkDevice;
