import React, { FunctionComponent } from 'react';

import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';

import messages from '../messages';

import {
  $CardContentDescription,
  $CardContentProductName,
  $CardDescriptionWrapper,
} from './MultiProductInfoDescription.styles';
import { FileShortSummary } from '@savgroup-front-common/types';
import { ModelsIcon } from './ModelsIcons/ModelsIcon';

interface MultiProductInfoDescriptionProps {
  file: FileShortSummary;
}

const MultiProductInfoDescription: FunctionComponent<
  MultiProductInfoDescriptionProps
> = ({ file }) => {
  const modelIdsSet = file.fileProducts.reduce((acc, fileProduct) => {
    return acc.add(fileProduct.modelId);
  }, new Set<string>());

  return (
    <$CardDescriptionWrapper>
      {Array.from(modelIdsSet).map((modelId) => (
        <ModelsIcon key={modelId} modelId={modelId} />
      ))}
      <$CardContentDescription>
        <$CardContentProductName>
          <SafeFormattedMessageWithoutSpread
            message={messages.multiproductLength}
            values={{ itemsLength: file.fileProducts.length }}
          />
        </$CardContentProductName>
      </$CardContentDescription>
    </$CardDescriptionWrapper>
  );
};

MultiProductInfoDescription.displayName = 'MultiProductInfoDescription';

export default MultiProductInfoDescription;
