import styled from 'styled-components';

export const $BarCodeButtonContainer = styled.div`
  & > button {
    width: 100%;
  }
`;

export const $ScannerContainer = styled.div`
  flex-grow: 1;
`;
export const $Scanner = styled.div<{ $height: number }>`
  position: relative;
  min-height: 300px;
  height: ${({ $height }) => `${$height}px`};
  width: 100%;
`;

export const $ActionRow = styled.div`
  display: flex;
  justify-content: end;
`;

export const $Footer = styled.div`
  margin-top: auto;
  padding: 16px 24px;
`;

export const $BarcodeReaderContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;
