import React, { FC, useCallback } from 'react';
import { useMedia } from 'react-use';

import { media } from '@savgroup-front-common/constants';
import {
  $CenteredText,
  $Container,
  $EmptyCartContainer,
  $OrderLines,
} from './CartManager.styles';
import { AnimatedRenderer } from '@savgroup-front-common/core/src/atoms/AnimatedRenderer';
import { Card } from '@savgroup-front-common/core/src/atoms/Cards';
import { Didactic } from '@savgroup-front-common/core/src/atoms/Didactic';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import messages from './messages';
import { buildProfessionalRoute } from 'myaccount/view/app/NewLayout/ProfessionalRoutes.helpers';
import {
  PROFESSIONAL_STEP_CONFIG,
  PROFESSIONAL_STEP_PROGRESS,
} from 'myaccount/view/app/NewLayout/ProfessionalRoutes.config';
import { useHistory, useParams } from 'react-router-dom';
import { useAddProductToBusinessReturn } from './CartManager.hooks';
import { useForm } from 'react-hook-form';
import { CartManagerForm } from './CartManager.types';
import { OrderLine } from './OrderLine';
import { Product } from '@savgroup-front-common/types';
import Form from '@savgroup-front-common/core/src/atoms/Form/Form/Form';
import { useGetBusinessReturn } from './hooks/useGetBusinessReturn';
import { useSetProductToBusinessReturn } from './hooks/useSetProductToBusinessReturn';
import { useCreateClaimGroupFromBusinessReturn } from './hooks/useCreateClaimGroupFromBusinessReturn';
import { BarcodeReader } from './BarcodeReader';
import SearchModel from '../SearchModel/SearchModel';
import StepLayoutProfessional from 'myaccount/view/app/NewLayout/StepLayout/StepLayoutProfessional';

const CartManager: FC = () => {
  const history = useHistory();
  const isMobileView = useMedia(media.maxWidth.sm);
  const { orderReference } = useParams<{ orderReference?: string }>();

  const { order, isLoading, eligibility } = useGetBusinessReturn();

  const formContext = useForm<CartManagerForm>({
    defaultValues: {
      product: {},
    },
    shouldUnregister: true,
  });
  const {
    handleProductScan,
    handleAddProductBySearchValue,
    isAddProductLoading,
  } = useAddProductToBusinessReturn({ formContext });
  const { handleSetProduct, isSetProductLoading } =
    useSetProductToBusinessReturn({ formContext });

  const { isCreateClaimGroupLoading, handleCreateClaimGroup } =
    useCreateClaimGroupFromBusinessReturn();

  const handlePreviousStep = useCallback(() => {
    history.push(
      buildProfessionalRoute({
        route: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_ISSUES_REASONS_PAGE,
        payload: orderReference ? { orderReference } : undefined,
      }),
    );
  }, [history, orderReference]);

  if (!eligibility || !order || isLoading) {
    return <BaseLoader />;
  }

  return (
    <Form<CartManagerForm>
      formContext={formContext}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <StepLayoutProfessional
        onSubmit={handleCreateClaimGroup}
        handlePreviousStep={handlePreviousStep}
        stepName={PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_PRODUCTS_TO_RETURN_PAGE}
        noConfirmButton={!order.products.length}
        customSteps={PROFESSIONAL_STEP_PROGRESS}
        isLoading={isSetProductLoading || isCreateClaimGroupLoading}
        minHeight
      >
        <$EmptyCartContainer>
          {!order?.products.length && (
            <AnimatedRenderer isOpen>
              <$CenteredText>
                <img
                  style={{ maxWidth: '224px' }}
                  src="/images/emptyOrder.svg"
                  alt=""
                />
                <Didactic withBottomMargin={false}>
                  <SafeFormattedMessageWithoutSpread
                    message={messages.startByScanProductToReturn}
                  />
                </Didactic>
              </$CenteredText>
              <SearchModel
                handleAddProduct={handleAddProductBySearchValue}
                isAddProductLoading={isAddProductLoading}
              />
            </AnimatedRenderer>
          )}
        </$EmptyCartContainer>

        <$Container>
          {order?.products.length > 0 && (
            <Card withoutMarginBottom isFluid minimal>
              <SearchModel
                handleAddProduct={handleAddProductBySearchValue}
                isAddProductLoading={isAddProductLoading}
              />
            </Card>
          )}
        </$Container>

        <$OrderLines>
          {order?.products.map((product: Product, index) => {
            return (
              <OrderLine
                key={product.orderLineReference}
                product={product}
                eligibility={eligibility}
                onUpdateQuantity={handleSetProduct}
                index={index}
              />
            );
          })}
        </$OrderLines>
        {isMobileView && (
          <BarcodeReader
            onProductScan={handleProductScan}
            isAddProductLoading={isAddProductLoading}
          />
        )}
      </StepLayoutProfessional>
    </Form>
  );
};

CartManager.displayName = 'CartManager';

export default CartManager;
