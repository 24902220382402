import { Attachment } from 'iconoir-react';
import React, { FunctionComponent } from 'react';

import { IconPropsInterface } from './Icon.types';

const AttachmentIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => <Attachment width={size} height={size} color={color} />;

AttachmentIcon.displayName = 'AttachmentIcon';

export default AttachmentIcon;
