import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'components.myaccount.sparepart.modal.title',
    defaultMessage: 'Confirmation of receipt',
  },
  warningMsg: {
    id: 'components.myaccount.sparepart.modal.warningMsg',
    defaultMessage:
      "Warning, the part is necessary for the intervention. Do not validate only if you don't have received the part.",
  },
  cancelButton: {
    id: 'components.myaccount.sparepart.modal.cancelButton',
    defaultMessage: 'Cancel',
  },
  validateButton: {
    id: 'components.myaccount.sparepart.modal.validateButton',
    defaultMessage: 'Confirm',
  },
});
