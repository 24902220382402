import { fromJS, Map } from 'immutable';
import get from 'lodash/get';
import map from 'lodash/map';
import result from 'lodash/result';
import { v4 } from 'uuid';

import { DOCUMENT_STATUS } from '@savgroup-front-common/constants/src/shared/document';

import {
  DELETE_ATTACHMENT_FROM_FILE,
  GET_ALL_DOCUMENTS_STATUS_BY_FILE_ID,
  GET_DOCUMENTS_BY_CLAIM_ID,
  GET_DOCUMENTS_STATUS_BY_FILE_ID,
  LOAD_DOCUMENT_BY_URL,
  LOAD_FILES_ATTACHMENT_LIST,
  RESET_UPLOAD_STATE,
  UPLOAD_FILE_ATTACHMENT_DATA,
} from './actionTypes';
import { ATTACHMENTS_DOMAIN } from './constants';

const initialState = fromJS({
  attachmentsFiles: fromJS({}),
  filesAttachmentsList: fromJS({
    myaccount: fromJS({}),
    control: fromJS({}),
  }),
  uploadAttachments: fromJS({}),
  documentsStatus: fromJS({}),
  documents: fromJS({}),
});

function onDocumentByUrlLoading(state, { meta }) {
  const attachmentId = get(meta, ['attachmentId']);
  const filename = get(meta, ['filename']);

  return state.updateIn(
    ['attachmentsFiles', attachmentId],
    Map({
      isLoaded: false,
      isDirty: false,
      value: {
        filename,
        url: attachmentId,
        attachmentId,
        internalId: v4(),
      },
    }),
    (oldValue) => oldValue.set('isDirty', true),
  );
}

function onDocumentByUrlErrored(state, { meta }) {
  const attachmentId = get(meta, ['attachmentId']);

  return state.updateIn(
    ['attachmentsFiles', attachmentId],
    fromJS({}),
    (oldValue) =>
      oldValue
        .set('isDirty', true)
        .set('hasErrors', true)
        .update('value', {}, (value) => ({
          ...value,
          attachmentId,
          url: attachmentId,
        })),
  );
}

function onDocumentLoadedByUrl(state, { payload: data, meta }) {
  const attachmentId = get(meta, ['attachmentId']);
  const url = get(meta, ['url']);
  const filename = get(meta, ['filename']);

  return state.updateIn(['attachmentsFiles', attachmentId], fromJS({}), (old) =>
    old
      .set('isLoaded', true)
      .set('isDirty', false)
      .update('value', (oldValue) => {
        return {
          ...oldValue,
          data,
          filename,
          url,
          attachmentId,
        };
      }),
  );
}

// List of attachments
function onAttachmentListLoading(state, { meta }) {
  const fileId = get(meta, ['fileId']);
  const type = get(meta, ['type']);

  if (!fileId) {
    return state;
  }

  return state.updateIn(
    ['filesAttachmentsList', type, fileId],
    fromJS({ isLoaded: false, isDirty: false }),
    (oldValue) => oldValue.set('isDirty', true),
  );
}

function onAttachmentListLoadingErrored(state, { meta }) {
  const fileId = get(meta, ['fileId']);
  const type = get(meta, ['type']);

  if (!fileId) {
    return state;
  }

  return state.updateIn(['filesAttachmentsList', type, fileId], (oldValue) =>
    oldValue.set('isDirty', true).set('hasErrors', true),
  );
}

function onAttachmentListLoaded(state, { payload, meta }) {
  const fileId = get(meta, ['fileId']);
  const type = get(meta, ['type']);

  if (!fileId) {
    return state;
  }

  return state.setIn(
    ['filesAttachmentsList', type, fileId],
    fromJS({ isLoaded: true, isDirty: false }).set(
      'value',
      map(get(payload, 'value'), (attachment) => ({
        ...attachment,
        origin: result(get(attachment, 'origin'), 'toLowerCase'),
      })),
    ),
  );
}

function deleteAttachmentSuccess(state, { meta }) {
  const { fileId, internalId } = meta;

  return state.removeIn(['uploadAttachments', fileId, internalId]);
}
function deleteAttachmentStarting(state, { meta }) {
  const { fileId, internalId } = meta;

  return state.updateIn(
    ['uploadAttachments', fileId, internalId, 'value'],
    (oldValue) => ({
      ...oldValue,
      isDeleting: true,
    }),
  );
}

function cancelAttachment(state, { meta }) {
  const fileId = get(meta, ['fileId']);
  const id = get(meta, ['id']);

  if (state.getIn(['uploadAttachments', fileId, id])) {
    return state.removeIn(['uploadAttachments', fileId, id]);
  }

  return state;
}

function attachmentUploaded(
  state,
  { payload: { value }, meta: { id, fileId } },
) {
  return state
    .updateIn(['uploadAttachments', fileId, id, 'value'], (oldValue) => ({
      ...oldValue,
      fileAttachmentId: value,
    }))
    .updateIn(['uploadAttachments', fileId, id, 'isLoaded'], () => true);
}

function attachmentUploadStarted(state, { meta }) {
  const fileId = get(meta, ['fileId']);
  const fileButtonOrigin = get(meta, ['fileButtonOrigin']);
  const id = get(meta, ['id']);
  const name = get(meta, ['fileName']);
  const extension = get(meta, ['extension']);
  const file = get(meta, ['file']);
  const type = get(meta, ['type']);
  const cancellationSignal = get(meta, ['cancellationSignal']);

  return state.updateIn(
    ['uploadAttachments', fileId, id, 'value'],
    (oldValue) => ({
      ...oldValue,
      id,
      file,
      internalId: v4(),
      progress: 0,
      extension,
      name,
      cancellationSignal,
      type,
      fileButtonOrigin,
      isDeleting: false,
    }),
  );
}

function attachmentUploadProgress(state, { payload, meta }) {
  const fileId = get(meta, ['fileId']);
  const id = get(meta, ['id']);

  return state.updateIn(
    ['uploadAttachments', fileId, id, 'value'],
    (oldValue) => ({ ...oldValue, progress: payload }),
  );
}

function resetUploadState(state) {
  return state.set('uploadAttachments', initialState.get('uploadAttachments'));
}

function getDocumentsStatusByFileId(state, { payload, meta }) {
  const fileId = get(meta, ['fileId'], null);
  const type = get(meta, ['type'], null);

  if (!fileId || !type) {
    return state;
  }

  return state.updateIn(['documentsStatus', fileId], (oldValue) => ({
    ...oldValue,
    [type]: get(payload, ['value']),
  }));
}

function setDocumentsStatusByFileIdCancelled(state, { meta }) {
  const fileId = get(meta, ['fileId'], null);
  const type = get(meta, ['type'], null);

  if (!fileId || !type) {
    return state;
  }

  return state.updateIn(['documentsStatus', fileId], (oldValue) => ({
    ...oldValue,
    [type]: DOCUMENT_STATUS.FAILURE,
  }));
}

function getAllDocumentsStatusByFileId(state, { payload, meta }) {
  const fileId = get(meta, ['fileId'], null);

  if (!fileId) {
    return state;
  }

  return state.updateIn(['documentsStatus', fileId], (oldValue) => ({
    ...oldValue,
    value: get(payload, ['value']),
  }));
}

export function onGetDocumentsByClaimIdSuccess(state, { payload, meta }) {
  const { value } = payload;
  const { claimId } = meta;

  return state.updateIn([ATTACHMENTS_DOMAIN.DOCUMENTS], (oldValue) => ({
    ...oldValue,
    [claimId]: value,
  }));
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_DOCUMENT_BY_URL.STARTED:
      return onDocumentByUrlLoading(state, action);
    case LOAD_DOCUMENT_BY_URL.ERRORED:
      return onDocumentByUrlErrored(state, action);
    case LOAD_DOCUMENT_BY_URL.SUCCEEDED:
      return onDocumentLoadedByUrl(state, action);

    case LOAD_FILES_ATTACHMENT_LIST.STARTED:
      return onAttachmentListLoading(state, action);
    case LOAD_FILES_ATTACHMENT_LIST.SUCCEEDED:
      return onAttachmentListLoaded(state, action);
    case LOAD_FILES_ATTACHMENT_LIST.ERRORED:
      return onAttachmentListLoadingErrored(state, action);

    case UPLOAD_FILE_ATTACHMENT_DATA.STARTED:
      return attachmentUploadStarted(state, action);
    case UPLOAD_FILE_ATTACHMENT_DATA.CANCELLED:
      return cancelAttachment(state, action);
    case UPLOAD_FILE_ATTACHMENT_DATA.PROGRESS:
      return attachmentUploadProgress(state, action);
    case UPLOAD_FILE_ATTACHMENT_DATA.SUCCEEDED:
      return attachmentUploaded(state, action);

    case DELETE_ATTACHMENT_FROM_FILE.STARTED:
      return deleteAttachmentStarting(state, action);
    case DELETE_ATTACHMENT_FROM_FILE.SUCCEEDED:
      return deleteAttachmentSuccess(state, action);
    case RESET_UPLOAD_STATE:
      return resetUploadState(state);

    case GET_DOCUMENTS_STATUS_BY_FILE_ID.SUCCEEDED:
      return getDocumentsStatusByFileId(state, action);
    case GET_DOCUMENTS_STATUS_BY_FILE_ID.CANCELLED:
      return setDocumentsStatusByFileIdCancelled(state, action);
    case GET_ALL_DOCUMENTS_STATUS_BY_FILE_ID.SUCCEEDED:
      return getAllDocumentsStatusByFileId(state, action);

    case GET_DOCUMENTS_BY_CLAIM_ID.SUCCEEDED:
      return onGetDocumentsByClaimIdSuccess(state, action);

    default:
      return state;
  }
}
