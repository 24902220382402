import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import {
  BaseBackResponse,
  RecipientType,
  ReverseMoneyNumberAmount,
  SparePartCondition,
} from '@savgroup-front-common/types';

import apiCall from '../../services/apiCall';

const ENDPOINT = `${APIConfiguration.repairer}quotations/selection/${COMMON_QUERY_PARAMS.QUOTE_ID}`;

export interface UpdateQuotationOptionalLineUnavailableSparePart {
  id: string;
  supplierId: string;
  description?: string;
  isRequiredForRepair?: boolean;
  isSelectedForRepair: boolean;
  recipient: {
    recipientType?: RecipientType;
  };
  sparePart: {
    requestLineId: string;
  };
}

export interface UpdateQuotationSparePartSelectionCommandArgs {
  quotationId: string;
  quotationSpareParts: (
    | UpdateQuotationOptionalLineUnavailableSparePart
    | {
        id: string;
        supplierId: string;
        description?: string;
        isRequiredForRepair?: boolean;
        isSelectedForRepair: boolean;
        recipient: {
          recipientType?: RecipientType;
        };
        sparePart: {
          sparePartSupplierAssociationId: string;
          supplierName: string;
          supplierReference: string;
          manufacturerName: string;
          manufacturerReference: string;
          supplierId: string;
          preTaxUnitBuyingPrice: ReverseMoneyNumberAmount;
          preTaxUnitPrice?: ReverseMoneyNumberAmount;
          quantity: number;
          vatRate?: number;
          preTaxAmount?: ReverseMoneyNumberAmount;
          amountIncludedTax: ReverseMoneyNumberAmount;
          requestLineId?: string;
          condition?: SparePartCondition;
        };
      }
  )[];
}

export async function updateQuotationSparePartSelectionCommand({
  quotationSpareParts,
  quotationId,
}: UpdateQuotationSparePartSelectionCommandArgs): Promise<BaseBackResponse> {
  try {
    const response = await apiCall(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.QUOTE_ID, quotationId),
      {
        method: SUPPORTED_METHODS.PUT,
        json: {
          quotationSpareParts,
        },
      },
    );

    return response;
  } catch (exception) {
    return { errors: (exception as any).errors, failure: true };
  }
}
