import React, { FunctionComponent, Suspense } from 'react';

import TakeInterventionAppointmentContent from './TakeInterventionAppointmentContent';
import BaseLoader from '../../../molecules/BaseLoader/BaseLoader';

type TakeInterventionAppointmentContainerProps = React.PropsWithChildren<{
  fileId: string;
  sellerId: string;
  onSubmit: () => void;
  isLoading?: boolean;
}>;

const TakeInterventionAppointmentContainer: FunctionComponent<
  TakeInterventionAppointmentContainerProps
> = ({ children, fileId, sellerId, onSubmit, isLoading = false }) => {
  return (
    <Suspense fallback={<BaseLoader />}>
      <TakeInterventionAppointmentContent
        fileId={fileId}
        sellerId={sellerId}
        onSubmit={onSubmit}
        isLoading={isLoading}
      >
        {children}
      </TakeInterventionAppointmentContent>
    </Suspense>
  );
};

TakeInterventionAppointmentContainer.displayName =
  'TakeInterventionAppointmentContainer';

export default TakeInterventionAppointmentContainer;
