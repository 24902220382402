import { add } from 'date-fns';

import { useGetPageSize } from '../../../components/InterventionAppointment/hooks/useGetPageSize';

export const useCalculateStartAndEndDate = ({
  pageNumber,
}: {
  pageNumber: number;
}) => {
  const pageSize = useGetPageSize();

  const startTime = add(new Date().setMinutes(0, 0, 0), {
    days: (pageNumber - 1) * pageSize,
  });

  const endTime = add(startTime, { days: pageSize - 1 });

  return { startTime, endTime };
};
