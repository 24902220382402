import { find } from 'lodash';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Selectors as CarriersSelectors } from '../domains/carriers';
import { downloadShippingLabel } from '../domains/carriers/actionCreators';

import printDocumentWithUrl from '../helpers/printDocumentWithUrl';

interface UseGetShippingLabelArgs {
  fileId: string;
  partnerTransportDocumentUrl?: string;
}

const useGetShippingLabel = ({
  fileId,
  partnerTransportDocumentUrl,
}: UseGetShippingLabelArgs): (() => void) => {
  const dispatch = useDispatch();
  const labels = useSelector(CarriersSelectors.labels);

  const label = find(labels.getIn([fileId, 'value']), {
    fileId,
  });

  const getShippingLabel = useCallback(() => {
    if (partnerTransportDocumentUrl) {
      printDocumentWithUrl({ documentUrl: partnerTransportDocumentUrl });
    } else {
      dispatch(
        downloadShippingLabel({
          fileId,
          label,
          downloadWindow: window.open(),
        }),
      );
    }
  }, [dispatch, fileId, label, partnerTransportDocumentUrl]);

  return getShippingLabel;
};

export default useGetShippingLabel;
