import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  Model,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.catalog}models/bySku`;

interface GetModelBySkuQueryPayload {
  sku: string;
  sellerId: string;
}

export async function getModelBySkuQuery({
  sku,
  sellerId,
}: GetModelBySkuQueryPayload): Promise<
  BackResponse<Model[]> | BackResponseFailure
> {
  try {
    return await apiCall<BackResponse<Model[]>>(
      buildUrl(ENDPOINT, { sku, sellerId }),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
