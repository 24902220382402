import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  Model,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.catalog}models/scanProduct`;

export enum ScanResultType {
  Ean = 'Ean',
  Sku = 'Sku',
}

export interface ScannedProductSummaryDto {
  parsedType: ScanResultType;
  parsedValue: string;
  parsedInformations?: { batchNumber: string; expirationDate: string };
  model: Model;
}

interface ScanProductCommandPayload {
  sellerId: string;
  scannedValue: string;
  scannedType: string;
}

export async function scanProductCommand({
  sellerId,
  scannedValue,
  scannedType,
}: ScanProductCommandPayload): Promise<
  BackResponse<ScannedProductSummaryDto> | BackResponseFailure
> {
  try {
    return await apiCall<BackResponse<ScannedProductSummaryDto>>(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: { sellerId, scannedValue, scannedType },
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
