import difference from 'lodash/difference';
import head from 'lodash/head';

import { ModuleStateSummary } from '@savgroup-front-common/types';

export const getTransitionWithExpect = (actions: ModuleStateSummary[]) => {
  const transitions = head(actions)?.transitions;
  const newTransitions = (transitions || []).map((transition) => ({
    ...transition,
    expect: head([
      ...(transition.expects.mandatories || []),
      ...(transition.expects.optionals || []),
    ]),
  }));
  const firstTransitionWithExpect = newTransitions.find(
    (transition) => transition.expect,
  );
  const otherTransitions = difference(newTransitions, [
    firstTransitionWithExpect,
  ]);

  return {
    firstTransitionWithExpect,
    otherTransitions,
  };
};
