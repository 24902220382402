import { useQuery } from 'react-query';

import { CommonWorkflowService } from '@savgroup-front-common/core/src/api';
import { FileStateHistorySummary } from 'myaccount/api/Workflow/getFileStatesQuery';
import { STALE_TIME } from '@savgroup-front-common/constants';

export const GetMultipleFileStatesQuery = 'getMultipleFileStatesQuery';

export const useGetMultipleFileStatesQuery = ({
  fileIds,
}: {
  fileIds?: string[];
}) => {
  const { data: multiHistories, refetch } = useQuery(
    [GetMultipleFileStatesQuery, { fileIds }],
    async () => {
      if (!fileIds) {
        return undefined;
      }

      return Promise.all(
        fileIds?.map(async (fileId) => {
          const response = await CommonWorkflowService.getFileStatesQuery({
            fileId,
          });

          if (response.failure) {
            return undefined;
          }

          return response.value;
        }),
      ).then(
        (value) =>
          value
            .filter((histories) => histories)
            .flat(1) as FileStateHistorySummary[],
      );
    },
    {
      staleTime: STALE_TIME.MINUTES_10,
      suspense: true,
    },
  );

  return {
    multiHistories,
    refetchHistories: refetch,
  };
};
