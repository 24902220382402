import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';

import AddressForm from './addressForm/AddressForm';
import useGetCountriesOptions from './hooks/useGetCountriesOptions/useGetCountriesOptions';

const AddressManagement: FunctionComponent = () => {
  const intl = useIntl();
  const countryOptions = useGetCountriesOptions(intl.locale);

  return <AddressForm countriesOptions={countryOptions} />;
};

AddressManagement.displayName = 'AddressManagement';

export default AddressManagement;
