import { Action } from 'redux-actions';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';

import { GetStoresBySellerIdPayload, GetStoresPayload } from './actionCreators';
import * as types from './actionTypes';
import {
  selectGetStoreBySellerIdIsLoading,
  selectGetStoreBySellerIdWasLoaded,
  selectGetStoreIsLoading,
  selectGetStoreWasLoaded,
} from './selectors';
import callAndGetResponse from '../../services/callAndGetResponse';
import { buildUrl } from '../../helpers';

function* getStoreWorker({
  payload,
}: {
  payload: GetStoresPayload;
}): Generator {
  const { storeId = null } = payload;

  if (!storeId) {
    return;
  }

  const isLoading = yield select(selectGetStoreIsLoading, {
    storeId,
  });
  const wasLoaded = yield select(selectGetStoreWasLoaded, { storeId });

  if (isLoading || wasLoaded) {
    return;
  }

  yield call(
    callAndGetResponse,
    types.GET_STORE,
    buildUrl(`${APIConfiguration.actor}sellers/stores`),
    {
      method: SUPPORTED_METHODS.POST,
      json: {
        storeIds: storeId,
      },
    },
    { indexer: storeId },
  );

  yield put(types.GET_STORE.end({ storeId }));
}
function* getStoreWatcher() {
  yield takeEvery<Action<GetStoresPayload>>(
    types.GET_STORE.BASE,
    getStoreWorker,
  );
}

function* getStoresBySellerIdWorker({
  payload,
}: {
  payload: GetStoresBySellerIdPayload;
}): Generator {
  const { sellerId = null } = payload;

  if (!sellerId) {
    return;
  }

  const isLoading = yield select(selectGetStoreBySellerIdIsLoading, {
    sellerId,
  });
  const wasLoaded = yield select(selectGetStoreBySellerIdWasLoaded, {
    sellerId,
  });

  if (isLoading || wasLoaded) {
    return;
  }

  yield call(
    callAndGetResponse,
    types.GET_STORES_BY_SELLER_ID,
    `${APIConfiguration.actor}sellers/${sellerId}/stores`,
    { method: SUPPORTED_METHODS.GET },
    {
      sellerId,
      indexer: sellerId,
    },
  );

  yield put(types.GET_STORES_BY_SELLER_ID.end({ sellerId }));
}
function* getStoresBySellerIdWatcher() {
  yield takeEvery<Action<GetStoresBySellerIdPayload>>(
    types.GET_STORES_BY_SELLER_ID.BASE,
    getStoresBySellerIdWorker,
  );
}

export default function* storeSaga(): Generator {
  try {
    yield all([getStoreWatcher(), getStoresBySellerIdWatcher()]);
  } catch (error) {
    logCritical(error);
  }
}
