import { useQuery } from 'react-query';

import { STALE_TIME } from '@savgroup-front-common/constants';

import { CommonRepairerService } from '../../../api/RepairerService';
import { useToasts } from '../../../molecules/NotificationsProvider';
import { adaptQuotationNewModelDto } from '../adapters/adaptQuotationNewModelDto';

interface UseGetQuotationsByFileIdQueryArgs {
  fileId?: string;
  suspense?: boolean;
  isEnabled?: boolean;
}

export const GetQuotationsByFileIdQuery = 'getQuotationsByFileIdQuery';

export const useGetQuotationsByFileIdQuery = ({
  fileId,
  suspense = false,
  isEnabled = true,
}: UseGetQuotationsByFileIdQueryArgs) => {
  const { pushErrors } = useToasts();

  const { isLoading, data: quotations } = useQuery(
    [GetQuotationsByFileIdQuery, { fileId }],
    async () => {
      if (!fileId) {
        return undefined;
      }

      const response = await CommonRepairerService.getQuotationsByFileIdQuery({
        fileId,
      });

      if (response.failure) {
        pushErrors(response.errors);

        return undefined;
      }

      return adaptQuotationNewModelDto(response.value);
    },
    {
      suspense,
      cacheTime: STALE_TIME.MINUTES_10,
      refetchOnMount: 'always',
      enabled: isEnabled,
    },
  );

  return { quotations, isLoading };
};
