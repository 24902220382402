import styled from 'styled-components';

export const $Question = styled.div`
  font-size: 18px;
  colors: ${({ theme }) => theme.colors.black};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  margin-bottom: 1rem;
`;

export const $Paragraphe = styled.p`
  font-size: 16px;
  colors: ${({ theme }) => theme.colors.black};
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  padding-bottom: 2rem;
  border-bottom: 1px solid #e1e4e8;
`;
