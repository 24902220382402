import { call, put, takeEvery } from 'redux-saga/effects';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import { callAndGetResponse } from '../../../services';

import { SET_DEAD_END_FOR_CLAIM_GROUP } from '../actionTypes/claimDeadEnd';

function* setDeadEndForClaimGroupWorker({
  payload: { groupId, claimIds, message, orderId },
}) {
  yield call(
    callAndGetResponse,
    SET_DEAD_END_FOR_CLAIM_GROUP,
    `${APIConfiguration.claim}claimGroups/${groupId}/deadEnd`,
    {
      method: SUPPORTED_METHODS.PUT,
      json: {
        claimIds,
        comment: message,
      },
    },
    {
      orderId,
      claimIds,
      message,
    },
  );
  yield put(SET_DEAD_END_FOR_CLAIM_GROUP.end());
}

export function* setDeadEndForClaimGroupWatcher() {
  yield takeEvery(
    SET_DEAD_END_FOR_CLAIM_GROUP.BASE,
    setDeadEndForClaimGroupWorker,
  );
}
