import { matchPath } from 'react-router-dom';

import { RouteTabConfig } from '../RouteTabs.types';

const findRoutesIndex = (config: RouteTabConfig[], url?: string): number => {
  const matchUrlIndex = config.findIndex((c) => {
    return c.url === url || c.secondaryPaths?.some((s) => url?.includes(s));
  });
  const matchPathIndex = config.findIndex(({ path, exact }) =>
    matchPath(url || '', { path, exact }),
  );

  return matchUrlIndex !== -1 ? matchUrlIndex : matchPathIndex;
};

export const findSubRoutesIndex = (
  config: RouteTabConfig[],
  url?: string,
): number =>
  config.reduce((acc, tab) => {
    const index = tab.subTab?.findIndex(({ path, exact }) => {
      return matchPath(url || '', { path, exact });
    });

    if (index !== undefined && index >= 0) {
      return index;
    }

    return acc;
  }, -1);

export default findRoutesIndex;
