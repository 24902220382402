import React from 'react';

import Icon from './Icon';

interface ScanBarcodeIconProps {
  color?: string;
  size?: string;
}

const ScanBarcodeIcon: React.FC<ScanBarcodeIconProps> = ({
  color = 'black',
  size = '24px',
}) => {
  return (
    <Icon size={size} color={color} viewBox="0 0 40 40">
      <path
        d="M11.6666 10V20M11.6666 25V30M23.3333 10V20M23.3333 25V30M28.3333 10V20M28.3333 25V30M9.99992 5H4.99992V10M3.33325 20H36.6666M29.9999 5H34.9999V10M9.99992 35H4.99992V30M29.9999 35H34.9999V30M16.6666 20V10H18.3333V20H16.6666ZM16.6666 30V25H18.3333V30H16.6666Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

ScanBarcodeIcon.displayName = 'ScanBarcodeIcon';

export default ScanBarcodeIcon;
