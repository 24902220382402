 
import React, { FunctionComponent } from 'react';

import Icon from '../Icon';
import { IconPropsInterface } from '../Icon.types';

const ChevronFastLeftIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => (
  <Icon color={color} size={size}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M11.5303 5.46967C11.8232 5.76256 11.8232 6.23744 11.5303 6.53033L6.06066 12L11.5303 17.4697C11.8232 17.7626 11.8232 18.2374 11.5303 18.5303C11.2374 18.8232 10.7626 18.8232 10.4697 18.5303L4.46967 12.5303C4.17678 12.2374 4.17678 11.7626 4.46967 11.4697L10.4697 5.46967C10.7626 5.17678 11.2374 5.17678 11.5303 5.46967ZM19.5303 5.46967C19.8232 5.76256 19.8232 6.23744 19.5303 6.53033L14.0607 12L19.5303 17.4697C19.8232 17.7626 19.8232 18.2374 19.5303 18.5303C19.2374 18.8232 18.7626 18.8232 18.4697 18.5303L12.4697 12.5303C12.1768 12.2374 12.1768 11.7626 12.4697 11.4697L18.4697 5.46967C18.7626 5.17678 19.2374 5.17678 19.5303 5.46967Z"
    />
  </Icon>
);

ChevronFastLeftIcon.displayName = 'ChevronFastLeftIcon';

export default ChevronFastLeftIcon;
