import React from 'react';

interface DropIconProps {
  color?: string;
}

/* eslint-disable max-len */
const DropIcon: React.FC<DropIconProps> = ({ color = '#C9CED6' }) => {
  return (
    <svg
      width="63"
      height="42"
      viewBox="0 0 63 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_683_11232)">
        <path
          d="M57.1607 11.0861L52.2432 30.6373C52.1967 30.8225 52.0784 30.9816 51.9145 31.0796C51.7507 31.1776 51.5545 31.2065 51.3694 31.1599L31.8182 26.2424C31.633 26.1959 31.4739 26.0776 31.3759 25.9137C31.2779 25.7499 31.249 25.5537 31.2956 25.3686L36.213 5.8174C36.2596 5.63221 36.3779 5.47311 36.5417 5.37509C36.7056 5.27708 36.9017 5.24819 37.0869 5.29476L56.6381 10.2122C56.8233 10.2588 56.9824 10.3771 57.0804 10.5409C57.1784 10.7048 57.2073 10.9009 57.1607 11.0861Z"
          fill="white"
          stroke={color}
          strokeWidth="1.80001"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32.5839 20.2479L41.6084 18.8056L52.9462 27.8442M49.469 17.0706C48.8517 16.9153 48.3214 16.5212 47.9947 15.9749C47.668 15.4286 47.5717 14.7749 47.7269 14.1576C47.8822 13.5404 48.2763 13.01 48.8226 12.6833C49.3689 12.3566 50.0226 12.2603 50.6399 12.4155C51.2572 12.5708 51.7875 12.9649 52.1142 13.5112C52.4409 14.0575 52.5372 14.7112 52.382 15.3285C52.2267 15.9458 51.8326 16.4761 51.2863 16.8028C50.74 17.1295 50.0863 17.2258 49.469 17.0706Z"
          fill="white"
        />
        <path
          d="M32.5839 20.2479L41.6084 18.8056L52.9462 27.8442M49.469 17.0706C48.8517 16.9153 48.3214 16.5212 47.9947 15.9749C47.668 15.4286 47.5717 14.7749 47.7269 14.1576C47.8822 13.5404 48.2763 13.01 48.8226 12.6833C49.3689 12.3566 50.0226 12.2603 50.6399 12.4155C51.2572 12.5708 51.7875 12.9649 52.1142 13.5112C52.4409 14.0575 52.5372 14.7112 52.382 15.3285C52.2267 15.9458 51.8326 16.4761 51.2863 16.8028C50.74 17.1295 50.0863 17.2258 49.469 17.0706V17.0706Z"
          stroke={color}
          strokeWidth="1.80001"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g filter="url(#filter1_d_683_11232)">
        <path
          d="M20.75 16H29.5H20.75ZM20.75 9H29.5H20.75ZM13.75 23V3.05C13.75 2.77152 13.8606 2.50445 14.0575 2.30754C14.2544 2.11062 14.5215 2 14.8 2H35.45C35.7285 2 35.9956 2.11062 36.1925 2.30754C36.3894 2.50445 36.5 2.77152 36.5 3.05V26.5C36.5 28.3565 35.7625 30.137 34.4497 31.4497C33.137 32.7625 31.3565 33.5 29.5 33.5"
          fill="white"
        />
        <path
          d="M8.5 23H21.45C22.0292 23 22.5052 23.4673 22.5525 24.0448C22.7677 26.7013 23.865 33.5 29.5 33.5H10.25C8.85761 33.5 7.52226 32.9469 6.53769 31.9623C5.55312 30.9777 5 29.6424 5 28.25V26.5C5 25.5717 5.36875 24.6815 6.02513 24.0251C6.6815 23.3687 7.57174 23 8.5 23Z"
          fill="#F7F7F7"
        />
        <path
          d="M20.75 16H29.5M20.75 9H29.5M13.75 23V3.05C13.75 2.77152 13.8606 2.50445 14.0575 2.30754C14.2545 2.11062 14.5215 2 14.8 2H35.45C35.7285 2 35.9955 2.11062 36.1925 2.30754C36.3894 2.50445 36.5 2.77152 36.5 3.05V26.5C36.5 28.3565 35.7625 30.137 34.4497 31.4497C33.137 32.7625 31.3565 33.5 29.5 33.5M29.5 33.5C23.865 33.5 22.7677 26.7013 22.5525 24.0448C22.5052 23.4673 22.0292 23 21.45 23H8.5C7.57174 23 6.6815 23.3688 6.02513 24.0251C5.36875 24.6815 5 25.5717 5 26.5V28.25C5 29.6424 5.55312 30.9777 6.53769 31.9623C7.52226 32.9469 8.85761 33.5 10.25 33.5H29.5Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_683_11232"
          x="26.3738"
          y="3.37305"
          width="35.7087"
          height="35.7085"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.110243 0 0 0 0 0.236339 0 0 0 0 0.529167 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_683_11232"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_683_11232"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_683_11232"
          x="0"
          y="0"
          width="41.5"
          height="41.5"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.110243 0 0 0 0 0.236339 0 0 0 0 0.529167 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_683_11232"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_683_11232"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

DropIcon.displayName = 'DropIcon';

export default DropIcon;
