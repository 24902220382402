import { logError } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');

    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch (error) {
    logError(error);

    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);

    localStorage.setItem('state', serializedState);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn('Unable to save current store in state', error);
  }
};
