import { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';

import { CommonAttachmentService } from '@savgroup-front-common/core/src/api';

import { getUrlsForPickupPoint } from './MapWithControlledZoom.helpers';
import { AdaptedPickupPoint, PickupPoint } from './MapWithControlledZoom.types';

interface UseMapWithControlledZoomArgs {
  pickupPoints: PickupPoint[];
  sellerId: string;
}

const useMapWithControlledZoom = ({
  pickupPoints,
  sellerId,
}: UseMapWithControlledZoomArgs): {
  isLoading: boolean;
  adaptedPickupPoints: AdaptedPickupPoint[];
} => {
  const [isLoading, setIsLoading] = useState(false);
  const [adaptedPickupPoints, setAdaptedPickupPoints] = useState(pickupPoints);

  useDeepCompareEffect(() => {
    const call = async () => {
      setIsLoading(true);

      const preparedResourceExistCache = pickupPoints.reduce<
        Record<string, undefined>
      >((acc, pickupPoint) => {
        const urls = getUrlsForPickupPoint({ pickupPoint, sellerId });

        return {
          ...acc,
          ...Object.values(urls).reduce((urlAcc, url) => {
            return { ...urlAcc, [url]: undefined };
          }, {}),
        };
      }, {});

      const resourceExistCache = (
        await Promise.all(
          Object.keys(preparedResourceExistCache).map((url) => {
            return CommonAttachmentService.urlExists({
              url,
            }).then((isExisting) => {
              return {
                url,
                isExisting,
              };
            });
          }),
        )
      ).reduce<Record<string, boolean>>((acc, item) => {
        return {
          ...acc,
          [item.url]: item.isExisting,
        };
      }, {});

      const result = await Promise.all(
        pickupPoints.map(async (pickupPoint) => {
          const urls = getUrlsForPickupPoint({ pickupPoint, sellerId });

          const defaultMarkerUrlFromPublicDocumentExist =
            resourceExistCache[urls.defaultMarkerUrl];
          const defaultSelectedMarkerUrlFromPublicDocumentsExist =
            resourceExistCache[urls.defaultSelectedMarkerUrl];
          const markerUrlFromPublicDocumentExist =
            resourceExistCache[urls.markerUrlFromPublicDocument];
          const selectedMarkerUrlFromPublicDocumentsExist =
            resourceExistCache[urls.selectedMarkerUrlFromPublicDocument];

          if (
            markerUrlFromPublicDocumentExist &&
            selectedMarkerUrlFromPublicDocumentsExist
          ) {
            return {
              ...pickupPoint,
              icon: urls.markerUrlFromPublicDocument,
              selectedIcon: urls.selectedMarkerUrlFromPublicDocument,
            };
          }

          if (markerUrlFromPublicDocumentExist) {
            return {
              ...pickupPoint,
              icon: urls.markerUrlFromPublicDocument,
              selectedIcon: defaultSelectedMarkerUrlFromPublicDocumentsExist
                ? urls.defaultSelectedMarkerUrl
                : undefined,
            };
          }

          if (selectedMarkerUrlFromPublicDocumentsExist) {
            return {
              ...pickupPoint,
              icon: defaultMarkerUrlFromPublicDocumentExist
                ? urls.defaultMarkerUrl
                : undefined,
              selectedIcon: urls.selectedMarkerUrlFromPublicDocument,
            };
          }

          return {
            ...pickupPoint,
            icon: defaultMarkerUrlFromPublicDocumentExist
              ? urls.defaultMarkerUrl
              : undefined,
            selectedIcon: defaultSelectedMarkerUrlFromPublicDocumentsExist
              ? urls.defaultSelectedMarkerUrl
              : undefined,
          };
        }),
      );

      setAdaptedPickupPoints(result);
      setIsLoading(false);
    };

    if (pickupPoints && pickupPoints.length > 0) {
      call();
    }
  }, [pickupPoints, sellerId]);

  return {
    isLoading,
    adaptedPickupPoints,
  };
};

export default useMapWithControlledZoom;
