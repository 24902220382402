import Immutable, { fromJS } from 'immutable';
import get from 'lodash/get';
import { createSelector } from 'reselect';

import { CommonAppState } from '../CommonAppState';
import { wasActionLoadedSelector } from '../sagaRequestMetadata/selectors';

import { LOAD_DOCUMENT_BY_URL } from './actionTypes';
import { ATTACHMENTS_DOMAIN } from './constants';

const attachmentsState = (state: CommonAppState) => state.attachments;

export const attachmentsSelector = createSelector(
  [attachmentsState],
  (attachments) => attachments.get('attachmentsFiles'),
);
export const getAttachmentsFilesValue = createSelector(
  [
    attachmentsState,
    (_state: CommonAppState, formattedUrl: string) => formattedUrl,
  ],
  (state, formattedUrl) => {
    return state.getIn(['attachmentsFiles', formattedUrl, 'value'], null);
  },
);

export const filesAttachmentsListSelector = createSelector(
  [attachmentsState],
  (attachments) => attachments.get('filesAttachmentsList'),
);

export const uploadAttachments = createSelector(
  [attachmentsState],
  (attachments) => attachments.get('uploadAttachments'),
);
export const getUploadedAttachmentsByFileId = createSelector(
  [uploadAttachments, (_state: CommonAppState, fileId: string) => fileId],
  (attachments, fileId) => {
    const immutableAttachments = attachments.get(fileId) || fromJS({}) || {};

    return immutableAttachments
      .toArray()
      .map((attachment: Immutable.Map<string, unknown>) => {
        return {
          isLoaded: attachment.get('isLoaded'),
          value: attachment.get('value'),
        };
      });
  },
);

export const getDocumentsStatusByFileIdValue = createSelector(
  [attachmentsState, (_state: CommonAppState, fileId: string) => fileId],
  (state, fileId) => state.getIn(['documentsStatus', fileId], null),
);

export const selectDocumentByClaimId = createSelector(
  [
    attachmentsState,
    (_: CommonAppState, { claimId }: { claimId: string }) => claimId,
  ],
  (state, claimId) => {
    return get(state.get(ATTACHMENTS_DOMAIN.DOCUMENTS), claimId);
  },
);

export const selectLoadDocumentByUrlWasLoaded = (
  state: CommonAppState,
  { indexer }: { indexer: string },
) => {
  return wasActionLoadedSelector(state, LOAD_DOCUMENT_BY_URL, indexer);
};
