import styled from 'styled-components';

import {
  SUPPORTED_COMPONENTS,
  SUPPORTED_PROPERTIES,
} from '@savgroup-front-common/types';

import { getCustomComponentProperty } from '../../../helpers';

export const $Description = styled.div`
  white-space: pre-wrap;
  margin-bottom: 1rem;
`;

export const $ActionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 1rem;
`;

export const $BonusLine = styled.div`
  display: flex;
  gap: 2rem;
  padding: 1rem;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  & a {
    padding-left: 10px;
  }

  background-color: ${({ theme }) => {
    return getCustomComponentProperty({
      theme,
      componentName: SUPPORTED_COMPONENTS.CARD,
      property: SUPPORTED_PROPERTIES.COLORED_BACKGROUND_COLOR,
      fallback: theme.colors.white,
    });
  }};
`;
