import { CommonAppState } from '..';
import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import { Selectors as requestSelectors } from '../sagaRequestMetadata';

import { SET_USER_ACTIONS_IS_EMPTY } from './actionTypes';

const workflowState = (state: CommonAppState) => state.workflow;

export const states = createSelector(
  [workflowState],
  (workflow) => workflow.states,
);

export const invoicePaymentStatusSelector = createSelector(
  [workflowState],
  (workflow) => workflow.invoicePaymentStatus,
);

export const handling = createSelector(
  [(state: CommonAppState) => state],
  (state: CommonAppState) => state.workflow.handling,
);

export const handlingByFileId = createSelector(
  [handling, (_: CommonAppState, fileId: string) => fileId],
  (handlingState, fileId) => {
    return { value: handlingState.getIn([fileId, 'value']) || {} };
  },
);

export const files = createSelector(
  [workflowState],
  (workflow) => workflow.files,
);

export const selectFileActionsState = createSelector(
  [workflowState],
  (workflow) => workflow.userActions,
);

export const selectSetFileActionsIsEmptyWasLoaded = (
  state: CommonAppState,
  { fileId }: { fileId: string },
) =>
  requestSelectors.wasActionLoadedSelector(
    state,
    SET_USER_ACTIONS_IS_EMPTY,
    fileId,
  );

export const appointmentsSelector = createSelector(
  [workflowState],
  (workflow) => workflow.appointments,
);

export const splitFileStateSelector = createSelector(
  [workflowState],
  (workflow) => workflow.splitFileState,
);

export const explodeFileStateSelector = createSelector(
  [workflowState],
  (workflow) => workflow.explodeFileState,
);

export const commercialSolutionSelector = createSelector(
  [workflowState],
  (workflow) => workflow.commercialSolution,
);

export const commercialSolutionByFileIdSelector = createSelector(
  [commercialSolutionSelector, (_: CommonAppState, fileId: string) => fileId],
  (commercialSolutionState, fileId) =>
    commercialSolutionState.get(fileId, fromJS({})),
);

export const commercialSolutionIsLoadingSelector = createSelector(
  [commercialSolutionByFileIdSelector],
  (commercialSolution) => {
    return (
      commercialSolution.get('isDirty', false) &&
      !commercialSolution.get('isLoaded', false)
    );
  },
);

export const commercialSolutionErrorsSelector = createSelector(
  [commercialSolutionByFileIdSelector],
  (commercialSolution) => {
    return commercialSolution.get('errors', []) || [];
  },
);
export const commercialSolutionHasErrorsSelector = createSelector(
  [commercialSolutionByFileIdSelector],
  (commercialSolution) => {
    return commercialSolution.get('hasErrors', false);
  },
);

export const loadOwnerProductFiles = createSelector(
  [workflowState],
  (workflow) => workflow.loadOwnerProductFiles,
);

export const loadOwnerProductFilesByOwnerProductId = createSelector(
  [
    loadOwnerProductFiles,
    (_: CommonAppState, { ownerProductId }: { ownerProductId: string }) =>
      ownerProductId,
  ],
  (state, ownerProductId) => {
    return state.getIn([ownerProductId, 'value'], []) || [];
  },
);

export const selectOwnerProductFiles = createSelector(
  [workflowState],
  (workflow) => workflow.loadOwnerProductFiles.toJS(),
);
