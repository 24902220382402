import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import { apiCall } from '../../services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

import { buildUrl, prepareResponseFailure } from '../../helpers';

type GetDefaultWorkTypeResponse = string;

export const getDefaultWorkTypeQuery = async ({
  fileId,
}: {
  fileId: string;
}): Promise<BackResponse<GetDefaultWorkTypeResponse> | BackResponseFailure> => {
  try {
    return await apiCall<BackResponse<GetDefaultWorkTypeResponse>>(
      buildUrl(
        `${APIConfiguration.workflow}salesForce/${fileId}/worktype/default`,
      ),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};
