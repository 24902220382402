import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { CommonWorkflowService } from '../../api';
import { useBanners } from '../../molecules/NotificationsProvider';

import { collectBankAccountDetailsFormSchema } from './CollectBankAccountDetailsForm.schema';
import { CollectBankAccountDetailsFormValues } from './CollectBankAccountDetailsForm.types';

interface UseCollectBankAccountDetailsFormArgs {
  fileId?: string;
  onUpsertSuccess: (values: CollectBankAccountDetailsFormValues) => void;
}

export const useCollectBankAccountDetailsForm = ({
  onUpsertSuccess,
  fileId,
}: UseCollectBankAccountDetailsFormArgs) => {
  const formContext = useForm<CollectBankAccountDetailsFormValues>({
    resolver: yupResolver(collectBankAccountDetailsFormSchema),
  });
  const { handleSubmit } = formContext;
  const { removeAllNotifications, pushErrors } = useBanners();

  const handleSubmitCollectBankAccountDetails = handleSubmit(async (values) => {
    removeAllNotifications();

    if (!fileId) {
      return undefined;
    }

    const response = await CommonWorkflowService.upsertBankAccountDetails({
      fileId,
      accountHolder: values.accountHolder,
      bankName: values.bankName,
      iban: values.iban,
      bankIdentificationCode: values.bankIdentificationCode,
    });

    if (response.failure) {
      pushErrors(response.errors);

      return undefined;
    }

    await onUpsertSuccess(values);

    return undefined;
  });

  return {
    formContext,
    handleSubmitCollectBankAccountDetails,
  };
};
