import { fromJS } from 'immutable';
import get from 'lodash/get';

import * as ActionTypes from '../actionTypes';

function onUserActionsLoading(state, { meta }) {
  const fileId = get(meta, ['fileId']);

  return state.updateIn([fileId], fromJS({ isDirty: false }), (oldValue) =>
    oldValue.setIn(['isLoaded'], false).setIn(['hasErrors'], false),
  );
}

function onUserActionsLoadingErrored(state, { meta }) {
  const fileId = get(meta, ['fileId']);

  return state.updateIn([fileId], (oldValue) =>
    oldValue
      .setIn(['isDirty'], true)
      .setIn(['hasErrors'], true)
      .setIn(['value'], undefined),
  );
}

function onUserActionsLoaded(state, { payload, meta }) {
  const fileId = get(meta, ['fileId']);
  const userActions = get(payload, ['value']);

  return state.updateIn([fileId], (oldValue) =>
    oldValue
      .setIn(['isLoaded'], true)
      .setIn(['isDirty'], true)
      .setIn(['value'], userActions),
  );
}

export default function reducer(state = fromJS({}), action) {
  switch (action.type) {
    case ActionTypes.GET_FILE_ACTIONS.STARTED:
      return onUserActionsLoading(state, action);
    case ActionTypes.GET_FILE_ACTIONS.SUCCEEDED:
      return onUserActionsLoaded(state, action);
    case ActionTypes.GET_FILE_ACTIONS.ERRORED:
      return onUserActionsLoadingErrored(state, action);

    default:
      return state;
  }
}
