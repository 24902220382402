import { BarcodeReader, CodeDetection } from '@pixelverse/strichjs-sdk';
import React, { FC, useEffect, useRef } from 'react';
import { useWindowSize } from 'react-use';

import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { CrossIcon } from '@savgroup-front-common/core/src/protons/icons';

import { config } from './BarcodeReaderContent.config';
import {
  $ActionRow,
  $BarcodeReaderContent,
  $Footer,
  $Scanner,
  $ScannerContainer,
} from './BarcodeReaderContent.styles';
import messages from './messages';

interface BarcodeReaderContentProps {
  onClose: () => void;
  onProductScan: ({
    value,
    typeName,
  }: {
    value: string;
    typeName: string;
  }) => void;
}

const BarcodeReaderContent: FC<BarcodeReaderContentProps> = ({
  onClose,
  onProductScan,
}) => {
  const reader = useRef<BarcodeReader>();
  const timer = useRef<number>();

  const { height: windowHeight } = useWindowSize();
  const footerHeight = 82;
  const headerHeight = 50;

  useEffect(() => {
    if (!reader.current) {
      timer.current = setTimeout(() => {
        try {
          new BarcodeReader(config)
            .initialize()
            .then((result) => {
              reader.current = result;

              reader.current.detected = async (detections: CodeDetection[]) => {
                const detection = detections[0];

                if (detection) {
                  onProductScan({
                    value: detection.data,
                    typeName: detection.typeName,
                  });
                }
              };

              reader.current.start();
            })
            .catch((error) => {
              logCritical(error.detailMessage);
            });
        } catch (error: any) {
          logCritical(error.detailMessage);
        }
      }, 500);
    }

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }

      if (reader.current) {
        reader.current.destroy();
      }
    };
  }, [onProductScan, timer]);

  return (
    <$BarcodeReaderContent>
      <$ScannerContainer>
        <$ActionRow>
          <Button
            onClick={onClose}
            type={BUTTON_TYPES.BUTTON}
            icon={<CrossIcon />}
            naked
            dataTestId="deleteAttachment"
          />
        </$ActionRow>

        <$Scanner
          $height={windowHeight - footerHeight - headerHeight}
          id="scanner"
        />

        <div className="results" />
        <div className="actions" />
      </$ScannerContainer>

      <$Footer>
        <Button
          primary
          type={BUTTON_TYPES.BUTTON}
          fluid
          onClick={() => {
            onClose();
          }}
        >
          <SafeFormattedMessageWithoutSpread message={messages.returnToCart} />
        </Button>
      </$Footer>
    </$BarcodeReaderContent>
  );
};

BarcodeReaderContent.displayName = 'BarcodeReaderContent';

export default BarcodeReaderContent;
