import { Product } from '@savgroup-front-common/types';
import { ClaimGroupConfirmation } from 'myaccount/api/Claim/getClaimGroupConfirmationQuery';
import useChooseProductCard from 'myaccount/view/app/hooks/useGetModelDetailByIdQuery';
import { useGetMultiAdditionalInformationEntitySummaryQuery } from 'myaccount/view/app/hooks/useGetMultiAdditionalInformationEntitySummaryQuery';
import { useGetOrderByOwnerId } from 'myaccount/view/app/hooks/useGetOrderByOwnerId';
import { useGetOrderDetailsQuery } from 'myaccount/view/app/hooks/useGetOrderDetailsQuery';
import { useLayout } from 'myaccount/view/app/NewLayout/NewLayout.hooks';

import { getMultiAdditionalInformationValue } from './ResumeCardButton.helpers';

export const useResumeCardButton = ({
  claimGroupConfirmation,
}: {
  claimGroupConfirmation?: ClaimGroupConfirmation;
}) => {
  const claim = claimGroupConfirmation?.claims[0];
  const additionalInformationIds = claim?.additionalClaimInformation?.map(
    (additionalInformation) => additionalInformation.additionalInformationId,
  );

  const { ownerId } = useLayout();
  const { orders } = useGetOrderByOwnerId({ ownerId });
  const { orderDetail } = useGetOrderDetailsQuery({
    orderId: orders ? orders[0].orderId : undefined,
  });
  const product = orderDetail?.products.find(
    (product) => product.claimId === claim?.claimId,
  ) as Product;
  const { modelDetail } = useChooseProductCard({ product });

  const { additionalInformations } =
    useGetMultiAdditionalInformationEntitySummaryQuery({
      additionalInformationIds,
    });

  const { SerialNumber } = getMultiAdditionalInformationValue({
    additionalInformations,
    additionalClaimInformation: claim?.additionalClaimInformation,
    comparaisonRule: 'informationType',
    compareValues: ['SerialNumber', 'Imei'],
  });

  return {
    modelDetail,
    serialNumber: SerialNumber,
  };
};
