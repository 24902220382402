import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  AssemblingModel,
  BackResponse,
  BackResponseFailure,
  SellerProductState,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.config}sellers/products/list`;

export interface SellerProductInfo {
  id: string;
  sellerId: string;
  internalReference?: string;
  modelId: string;
  state: SellerProductState;
  additionalReturnInfo: AssemblingModel['additionalInformation'];
  isLowValue?: boolean;
  supplierId?: string;
}

const listSellerProductInfoByIdsCommand = async ({
  ids,
}: {
  ids: string[];
}): Promise<BackResponseFailure | BackResponse<SellerProductInfo[]>> => {
  try {
    const response = await apiCall<BackResponse<SellerProductInfo[]>>(
      ENDPOINT,
      {
        method: SUPPORTED_METHODS.POST,
        json: {
          ids,
        },
      },
    );

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default listSellerProductInfoByIdsCommand;
