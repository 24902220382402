import styled from 'styled-components';

export const $CenteredHeading = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;

export const $BannerContent = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  & svg {
    color: ${({ theme }) => theme.colors.success};
  }
`;

export const $BonusLine = styled.div`
  display: flex;
  gap: 2rem;
  padding: 1rem;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  & a {
    padding-left: 10px;
  }
  color: ${({ theme }) => theme.colors.mainTextColor};
  background-color: ${({ theme }) => theme.colors.headerTitleColor};
`;
