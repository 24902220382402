import { defineMessages } from 'react-intl';

export default defineMessages({
  date: {
    id: 'components.myaccount.myReturn.quoteInformation.date',
    defaultMessage: 'Send the : ',
  },
  quoteReference: {
    id: 'components.myaccount.myReturn.quoteInformation.quoteNumber',
    defaultMessage: 'Validate the quote',
  },
});
