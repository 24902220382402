import { createSelector } from 'reselect';

const claimState = (state) => state.claim;

export const claimSummary = createSelector(
  [claimState],
  (claim) => claim.claimSummary,
);
export const isChecked = createSelector(
  [claimState],
  (claim) => claim.isChecked,
);
export const claimOwnerId = createSelector(
  [claimState],
  (claim) => claim.ownerId,
);
export const confirmation = createSelector(
  [claimState],
  (claim) => claim.confirmation,
);
