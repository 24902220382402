import { defineMessages } from 'react-intl';

export default defineMessages({
  'paymentFailure.card_declined.insufficient_funds': {
    id: 'paymentFailure.card_declined.insufficient_funds',
    defaultMessage: 'Your card has insufficient funds.',
  },

  pay: {
    id: 'components.myaccount.claims.confirmation.paymentForm.pay',
    defaultMessage: 'Pay {amount}',
  },
  creditCardHolder: {
    id: 'components.myaccount.claims.confirmation.paymentForm.creditCardHolder',
    defaultMessage: "Cardholder's name",
  },
});
