import { MessageDescriptor } from 'react-intl';

import { CURRENCIES, HANDLING_TYPES } from '@savgroup-front-common/constants';
import { formatAmountWithCurrency } from '@savgroup-front-common/core/src/formatters/intl';
import { MessageType } from '@savgroup-front-common/types';
import { AdditionalInformation } from 'myaccount/api/Claim/getAdditionalInformationEntitySummaryQuery';
import { AdditionalClaimInformation } from 'myaccount/api/Claim/getClaimGroupConfirmationQuery';

import messages from './messages';

export const getDisplayPrice = (
  price?: number,
  currency?: CURRENCIES,
  messages?: MessageType,
): string | number | boolean | MessageDescriptor | MessageType | undefined => {
  if (!price) {return undefined;}

  const formattedPrice =
    price > 0
      ? formatAmountWithCurrency(price, currency || CURRENCIES.EUR)?.toString()
      : messages;

  return formattedPrice || undefined;
};

export const getDepositTitle = (type: HANDLING_TYPES) => {
  switch (type) {
    case HANDLING_TYPES.HOME_DELIVERY:
      return messages.depositHome;
    case HANDLING_TYPES.PICKUP_POST_OFFICE:
    case HANDLING_TYPES.PICKUP:
    case HANDLING_TYPES.PICKUP_COUNTER:
      return messages.depositPickupPoint;
    case HANDLING_TYPES.DROP_AT_STORE:
      return messages.depositPickupStore;
    case HANDLING_TYPES.SELLER_PROVIDED_CARRIER:
      return messages.depositExternal;
    case HANDLING_TYPES.HOME_INTERVENTION:
      return messages.depositHomeIntervention;

    default:
      return 'Error carrierDepositProductType not found';
  }
};

export const getDeliveryTitle = (type: HANDLING_TYPES) => {
  switch (type) {
    case HANDLING_TYPES.HOME_DELIVERY:
      return messages.deliveryHome;
    case HANDLING_TYPES.PICKUP_POST_OFFICE:
    case HANDLING_TYPES.PICKUP:
    case HANDLING_TYPES.PICKUP_COUNTER:
      return messages.deliveryPickupPoint;
    case HANDLING_TYPES.DROP_AT_STORE:
      return messages.deliveryPickupStore;
    case HANDLING_TYPES.PICKUP_STORE_DELIVERY:
      return messages.deliveryPickupStoreDelivery;
    case HANDLING_TYPES.SELLER_PROVIDED_CARRIER:
      return messages.deliveryExternal;

    default:
      return 'Error carrierDepositProductType not found';
  }
};

export const getAdditionalInformationValue = ({
  additionalInformations,
  additionalClaimInformation,
  comparaisonRule,
  compareValue,
}: {
  additionalInformations?: AdditionalInformation[] | undefined;
  additionalClaimInformation?: AdditionalClaimInformation[];
  comparaisonRule: string;
  compareValue: string;
}) => {
  const additionalInformationId = additionalInformations?.find(
    (additionalInformation) => {
      const compareElement: Record<string, string | boolean> =
        additionalInformation.additionalInformationEntity;

      return compareElement[comparaisonRule] === compareValue;
    },
  )?.id;

  return additionalClaimInformation?.find(
    (additionalInformation) =>
      additionalInformation.additionalInformationId === additionalInformationId,
  )?.additionalInformationStringValue;
};

export const getMultiAdditionalInformationValue = ({
  additionalInformations,
  additionalClaimInformation,
  comparaisonRule,
  compareValues,
}: {
  additionalInformations?: AdditionalInformation[] | undefined;
  additionalClaimInformation?: AdditionalClaimInformation[];
  comparaisonRule: string;
  compareValues: string[];
}): Record<string, string | undefined> => {
  const returnValue: Record<string, string | undefined> = {};

  compareValues.forEach((compareValue) => {
    returnValue[compareValue] = getAdditionalInformationValue({
      additionalInformations,
      additionalClaimInformation,
      comparaisonRule,
      compareValue,
    });
  });

  return returnValue;
};

export const getAdditionalInformationStringValue = ({
  additionalInformation,
  additionalClaimInformation,
}: {
  additionalInformation?: AdditionalInformation;
  additionalClaimInformation?: AdditionalClaimInformation[];
}): { label?: string; value?: string } => {
  return {
    label: additionalInformation?.label,
    value: additionalClaimInformation?.find(
      (additionalClaimInformation) =>
        additionalClaimInformation.additionalInformationId ===
        additionalInformation?.id,
    )?.additionalInformationStringValue,
  };
};

export const getMultiAdditionalInformationStringValue = ({
  additionalInformations,
  additionalClaimInformation,
}: {
  additionalInformations?: AdditionalInformation[] | undefined;
  additionalClaimInformation?: AdditionalClaimInformation[];
}): Record<string, string>[] => {
  return additionalInformations
    ?.filter(
      (additionalInformation) =>
        additionalInformation.additionalInformationEntity.informationType ===
        'String',
    )
    .map((additionalInformation) =>
      getAdditionalInformationStringValue({
        additionalInformation,
        additionalClaimInformation,
      }),
    )
    .filter(
      (additionalInformation: { label?: string; value?: string }) =>
        additionalInformation.value,
    ) as Record<string, string>[];
};

export const getAdditionalInformationEnumValue = ({
  additionalInformation,
  additionalClaimInformation,
}: {
  additionalInformation?: AdditionalInformation;
  additionalClaimInformation?: AdditionalClaimInformation[];
}): { label?: string; enumValue?: string } => {
  return {
    label: additionalInformation?.label,
    enumValue: additionalClaimInformation?.find(
      (additionalClaimInformation) =>
        additionalClaimInformation.additionalInformationId ===
        additionalInformation?.id,
    )?.additionalInformationEnumValue,
  };
};

export const getMultiAdditionalInformationEnumValue = ({
  additionalInformations,
  additionalClaimInformation,
}: {
  additionalInformations?: AdditionalInformation[] | undefined;
  additionalClaimInformation?: AdditionalClaimInformation[];
}): Record<string, string>[] => {
  return additionalInformations
    ?.filter(
      (additionalInformation) =>
        additionalInformation.additionalInformationEntity.informationType ===
        'Enum',
    )
    .map((additionalInformation) =>
      getAdditionalInformationEnumValue({
        additionalInformation,
        additionalClaimInformation,
      }),
    )
    .filter(
      (additionalInformation: { label?: string; enumValue?: string }) =>
        additionalInformation.enumValue,
    ) as Record<string, string>[];
};
