/* eslint-disable max-len */
import React, { FC } from 'react';
import { useTheme } from 'styled-components';

const Workshop: FC = () => {
  const theme = useTheme();

  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={theme.colors.primary}
    >
      <path
        d="M40.114 44.5698L31.5711 53.1128C31.3464 53.3372 31.1681 53.6037 31.0465 53.8971C30.9249 54.1905 30.8623 54.505 30.8623 54.8225C30.8623 55.1401 30.9249 55.4546 31.0465 55.748C31.1681 56.0414 31.3464 56.3079 31.5711 56.5323V56.5323C32.0243 56.9854 32.6389 57.2399 33.2797 57.2399C33.9205 57.2399 34.5351 56.9854 34.9882 56.5323L43.5312 47.987M48.7451 48.428L53.431 53.114C53.8841 53.5672 54.1386 54.1817 54.1386 54.8225C54.1386 55.4634 53.8841 56.0779 53.431 56.5311V56.5311C52.9778 56.9842 52.3633 57.2387 51.7225 57.2387C51.0816 57.2387 50.4671 56.9842 50.0139 56.5311L42.5113 49.0298M36.106 38.887L39.5255 42.3066M36.106 38.887L33.5407 39.7401L30.979 35.4687L32.6876 33.7601L36.9602 36.323L36.106 38.887V38.887Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.1135 44.5698C39.0937 41.9683 39.2931 38.5547 41.3956 36.4522C43.4981 34.3497 47.3768 33.8894 49.512 35.1714L45.8386 38.8447L45.4967 42.6039L49.2558 42.2631L52.9291 38.5886C54.2124 40.7249 53.7508 44.6037 51.6483 46.705C49.5458 48.8075 46.1335 49.0068 43.5319 47.987"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.8623 22.7601L32.3623 12.7601L15.8623 22.7601C15.8623 22.7601 15.8623 43.1775 15.8623 56.2601H27.3623"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3623 55.2601V28.2601H40.3623"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="28.8623"
        y1="21.7601"
        x2="35.8623"
        y2="21.7601"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

Workshop.displayName = 'Workshop';

export default Workshop;
