import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  AdditionalInformationDto,
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.claim}claimGroups/${COMMON_QUERY_PARAMS.CLAIM_GROUP_ID}/reasons`;

enum REASON_KIND {
  NORMAL = 'Normal',
  OTHER_PROBLEM = 'OtherProblem',
}

export interface ReasonSummary {
  id: string;
  name: string;
  key: string;
  mandatoryComment: boolean;
  mandatoryDocument: boolean;
  kind: REASON_KIND;
  displayOrder: number;
  didactic: string;
  neededInformation: AdditionalInformationDto[];
}

async function getReasonsByClaimGroup({
  claimGroupId,
}: {
  claimGroupId: string;
}): Promise<BackResponse<ReasonSummary[]> | BackResponseFailure> {
  try {
    const response = await apiCall<BackResponse<ReasonSummary[]>>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.CLAIM_GROUP_ID, claimGroupId),
      { method: SUPPORTED_METHODS.GET },
    );

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

export default getReasonsByClaimGroup;
