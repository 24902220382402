import capitalize from 'lodash/capitalize';
import moment from 'moment';

export const parseDay = (day: number | string, locale: string) =>
  moment().locale('en').day(day).locale(locale);

export const formatTime = (time: moment.MomentInput, locale: string) =>
  moment(time, 'HH:mm').locale(locale).format('LT');

export const formatDate = (dateTime: moment.MomentInput, locale: string) =>
  moment(dateTime).locale(locale).format('LLL');

export const formatDay = (day: number | string, locale: string) =>
  capitalize(parseDay(day, locale).format('dddd'));

export const weekDay = (day: number | string, locale: string) =>
  parseDay(day, locale).weekday();
