import { createAction } from '@reduxjs/toolkit';

import { QUOTATION_CLIENT_ANSWER } from '@savgroup-front-common/constants';
import { ModuleSummary } from '@savgroup-front-common/types';

import * as FileTrackingActionTypes from './actionTypes';

export const approveOrRejectQuote = createAction<{
  answer: QUOTATION_CLIENT_ANSWER;
  targetStateName: string;
  fileId: string;
  comment?: string;
  quoteId?: string;
  fromState: string;
  module: ModuleSummary;
}>(FileTrackingActionTypes.APPROVE_REJECT_QUOTE.BASE);
export const loadFileTrackingItemData = createAction(
  FileTrackingActionTypes.LOAD_CURRENT_FILE_TRACKING_ITEM_DATA.BASE,
);
export const setFileTrackingPageLoadingStatus = createAction(
  FileTrackingActionTypes.FILE_TRACKING_PAGE_LOADING.BASE,
);
export const submitAdditionalInfo = createAction<{
  module: ModuleSummary;
  fileId: string;
  fromState: string;
  toState: string;
  comment?: string;
}>(FileTrackingActionTypes.SUBMIT_ADDITIONAL_INFO.BASE);
