import { format, getTime } from 'date-fns';
import React, { FC } from 'react';

import { Timeslot } from '@savgroup-front-common/types';

import { SafeFormattedMessage } from '../../../../formatters';
import { CalendarTimeBlock } from '../../AppointmentCalendar.styles';

import message from './messages';
import { $SingleSlotSelector } from './TimeSlotSelector.styles';

interface TimeSlotSelectorProps {
  timeslot: Timeslot;
  isActive: boolean;
  onTimeslotSelect: ({
    selectedTimeslot,
  }: {
    selectedTimeslot: Timeslot;
  }) => void;
  selectedTimeslot?: Timeslot;
}

const TimeSlotSelector: FC<TimeSlotSelectorProps> = ({
  timeslot,
  isActive = true,
  onTimeslotSelect,
  selectedTimeslot,
}) => {
  const { allDaySchedule, start, end } = timeslot;

  return (
    <CalendarTimeBlock
      $fullHeight={allDaySchedule}
      key={`${getTime(timeslot.start)}-${getTime(timeslot.end)}`}
      clickable={isActive}
      active={
        selectedTimeslot && timeslot
          ? selectedTimeslot.internalId === timeslot.internalId
          : false
      }
      onClick={() =>
        isActive ? onTimeslotSelect({ selectedTimeslot: timeslot }) : undefined
      }
      data-testid="schedule"
    >
      {allDaySchedule && (
        <$SingleSlotSelector>
          <SafeFormattedMessage {...message.allDaySlotLabel} />
        </$SingleSlotSelector>
      )}
      {!allDaySchedule && (
        <div>
          <div>{format(start, 'HH:mm')}</div>
          <div>{' - '}</div>
          <div>{format(end, 'HH:mm')}</div>
        </div>
      )}
    </CalendarTimeBlock>
  );
};

TimeSlotSelector.displayName = 'TimeSlotSelector';

export default TimeSlotSelector;
