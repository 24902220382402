import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';

export const $CardHeader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0 1rem;
  @media ${media.maxWidth.sm} {
    padding: unset;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const $CardFileHeader = styled.div`
  flex: 1;
  font-size: ${({ theme }) => theme.fonts.size.large};
  margin-bottom: 0.5rem;
  @media ${media.maxWidth.sm} {
    font-size: 14px;
  }
`;

export const $CardTitleContainer = styled.div`
  display: inline;
  @media ${media.maxWidth.sm} {
    display: block;
  }
`;

export const $CardFileHeaderDate = styled.span`
  flex: 1;
  font-size: ${({ theme }) => theme.fonts.size.small};
  color: ${({ theme }) => theme.colors.tertiary};
`;

export const $CardTagWrapperHeader = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  @media ${media.maxWidth.sm} {
    flex-direction: row;
    align-items: flex-end;
  }
`;

export const $CardTagHeader = styled.div`
  margin: 0 0.5rem;

  &:last-child {
    margin-right: 0;
  }
  @media ${media.maxWidth.sm} {
    margin-bottom: 0;
    margin-left: 0;
    margin-top: 0.5rem;
  }
`;
