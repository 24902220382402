import { Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import { media } from '@savgroup-front-common/constants';

export const $RowContainer = styled(Row)`
  flex-direction: column;
  align-items: center;
  margin-left: unset;
  margin-right: unset;
  h2 {
    text-align: center;
    font-weight: ${({ theme }) => theme.fonts.weight.bold};
    @media ${media.maxWidth.sm} {
      font-size: 20px;
    }
  }
  & > * {
    margin-top: 2rem;
  }
`;

export const $RowBannerContainer = styled(Row)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 2rem;
  border-radius: 8px;
  background: rgba(32, 93, 243, 0.05);
`;

export const $RowBannerContainerText = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  > * {
    &:first-child {
      font-weight: ${({ theme }) => theme.fonts.weight.bold};
    }
  }
`;

export const $RowBannerContent = styled(Row)`
  display: flex;
  flex-direction: row;
  margin-left: unset;
  margin-right: unset;
  @media ${media.maxWidth.sm} {
    text-align: center;
    justify-content: center;
    align-items: center;
  }
`;

export const $RowBannerContainerIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-self: center;
  margin-right: 1rem;
  @media ${media.maxWidth.sm} {
    margin-bottom: 1rem;
  }
`;

export const $RowBannerContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-self: flex-end;
  @media ${media.maxWidth.sm} {
    margin-top: 1rem;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`;

export const $TextContainer = styled('div')`
  text-align: center;
`;

export const $ButtonOtherDemand = styled('div')`
  margin-top: 3rem;
`;
