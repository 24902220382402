import get from 'lodash/get';

export const formatVerifiedAddress = (verifiedAddress, deliveryAddress) => ({
  ...deliveryAddress,
  city: get(verifiedAddress, 'city', get(deliveryAddress, 'city')),
  postalCode: get(
    verifiedAddress,
    'zipCode',
    get(deliveryAddress, 'postalCode'),
  ),
  civility: get(verifiedAddress, 'civility', get(deliveryAddress, 'civility')),
  countryCode: get(
    verifiedAddress,
    'countryCode',
    get(deliveryAddress, 'countryCode'),
  ),
  firstName: get(
    verifiedAddress,
    'contactFirstName',
    get(deliveryAddress, 'firstName'),
  ),
  lastName: get(
    verifiedAddress,
    'contactLastName',
    get(deliveryAddress, 'lastName'),
  ),
  label: get(verifiedAddress, 'name1', get(deliveryAddress, 'label')),
  address: get(verifiedAddress, 'street1', get(deliveryAddress, 'address')),
  phone: get(verifiedAddress, 'phone', get(deliveryAddress, 'phone')),
  additionalAddress: get(
    verifiedAddress,
    'street2',
    get(deliveryAddress, 'additionalAddress'),
  ),
});
