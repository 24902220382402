import React, { FunctionComponent, MutableRefObject } from 'react';
import { UseFormRegister } from 'react-hook-form';

import { NumberInput } from '../../../atoms/Form/Input';
import { InputProps } from '../../../atoms/Form/Input/Input.types';

function mergeRefs(
  refs: Array<
    | MutableRefObject<HTMLInputElement | null>
    | ((instance: HTMLInputElement | null) => void)
    | null
  >,
): React.RefCallback<HTMLInputElement | null> {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref !== null) {
         
        ref.current = value;
      }
    });
  };
}

interface NumberInputHookFormProps extends Exclude<InputProps, 'onChange'> {
  register: UseFormRegister<any>;
  name: string;
}
interface NumberInputHookFormPWithRef extends NumberInputHookFormProps {
  forwardedRef:
    | MutableRefObject<HTMLInputElement | null>
    | ((instance: HTMLInputElement | null) => void)
    | null;
}

const NumberInputHookForm: FunctionComponent<
  React.PropsWithChildren<NumberInputHookFormPWithRef>
> = ({ register, name, forwardedRef, onBlur, ...rest }) => {
  const { ref, ...resgisteredInput } = register(name);

  return (
    <NumberInput
      {...rest}
      {...resgisteredInput}
      ref={mergeRefs([ref, forwardedRef])}
      onBlur={onBlur}
    />
  );
};

NumberInputHookForm.displayName = 'InputHookForm';

export default React.forwardRef<HTMLInputElement, NumberInputHookFormProps>(
  (props, ref) => <NumberInputHookForm forwardedRef={ref} {...props} />,
);
