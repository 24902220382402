import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants/src/shared';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared/supportedMethods';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  ENTITY_TYPE,
  PAYMENT_METHOD,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.payment}payment/create/${COMMON_QUERY_PARAMS.ENTITY_TYPE}/${COMMON_QUERY_PARAMS.ENTITY_ID}`;

interface CreatePaymentCommandPayload {
  entityType: ENTITY_TYPE;
  entityId: string;
  paymentMethod: PAYMENT_METHOD;
}

async function createPaymentCommand({
  entityType,
  entityId,
  paymentMethod,
}: CreatePaymentCommandPayload): Promise<
  BackResponse<string> | BackResponseFailure
> {
  const url = ENDPOINT.replace(
    COMMON_QUERY_PARAMS.ENTITY_TYPE,
    entityType,
  ).replace(COMMON_QUERY_PARAMS.ENTITY_ID, entityId);

  try {
    const response = await apiCall<BackResponse<string>>(url, {
      method: SUPPORTED_METHODS.POST,
      json: { paymentMethod },
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default createPaymentCommand;
