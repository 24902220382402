import { fromJS } from 'immutable';
import get from 'lodash/get';

import * as ActionTypes from '../actionTypes';

function onPayInvoiceStarted(state, payload, meta) {
  const invoiceId = get(meta, 'invoiceId');

  if (invoiceId) {
    return state.setIn([invoiceId], {
      isPending: true,
      errors: [],
    });
  }

  return state;
}

function onPayInvoiceSuccess(state, payload, meta = {}) {
  const invoiceId = get(meta, 'invoiceId');

  if (invoiceId) {
    return state.setIn([invoiceId], {
      isPending: false,
      errors: [],
    });
  }

  return state;
}

function onResetPaymentFormErrors(state, invoiceId) {
  if (invoiceId) {
    return state.setIn([invoiceId], {
      isPending: false,
      errors: [],
    });
  }

  return state;
}

function onPayInvoiceError(state, errors = [], meta = {}) {
  const invoiceId = get(meta, 'invoiceId');

  if (invoiceId) {
    return state.setIn([invoiceId], {
      isPending: false,
      errors,
    });
  }

  return state;
}

export default function reducer(state = fromJS({}), action) {
  switch (action.type) {
    case ActionTypes.PAY_INVOICE.STARTED: {
      return onPayInvoiceStarted(state, action.payload, action.meta);
    }

    case ActionTypes.PAY_INVOICE.SUCCEEDED: {
      return onPayInvoiceSuccess(state, action.payload, action.meta);
    }

    case ActionTypes.PAY_INVOICE.ERRORED: {
      return onPayInvoiceError(state, action.errors, action.meta);
    }

    case ActionTypes.RESET_PAYMENT_FORM_ERRORS: {
      return onResetPaymentFormErrors(state, action.payload);
    }
    default: {
      return state;
    }
  }
}
