import { defineMessages } from 'react-intl';

export default defineMessages({
  serialNumber: {
    id: 'components.myaccount.resumeCard.serialNumber',
    defaultMessage: 'Serial number: {serialNumber}',
  },
  requestTitleResume: {
    id: 'components.myaccount.resumeCard.requestTitleResume',
    defaultMessage: 'Request',
  },
  modifyLink: {
    id: 'components.myaccount.resumeCard.modifyLink',
    defaultMessage: 'Modify',
  },
  charge: {
    id: 'components.myaccount.resumeCard.charge',
    defaultMessage: 'Charge : ',
  },
  imei: {
    id: 'components.myaccount.resumeCard.imei',
    defaultMessage: 'Imei : ',
  },
  frequency: {
    id: 'components.myaccount.resumeCard.frequency',
    defaultMessage: 'Frequency : ',
  },
  description: {
    id: 'components.myaccount.resumeCard.description',
    defaultMessage: 'Description : ',
  },
  documents: {
    id: 'components.myaccount.resumeCard.documents',
    defaultMessage: 'Uploaded documents : ',
  },
  priceTitleResume: {
    id: 'components.myaccount.resumeCard.priceTitleResume',
    defaultMessage: 'Costs',
  },
  total: {
    id: 'components.myaccount.resumeCard.total',
    defaultMessage: 'Total',
  },
  tax: {
    id: 'components.myaccount.resumeCard.tax',
    defaultMessage: 'included tax',
  },
  unknownMessage: {
    id: 'components.myaccount.resumeCard.unknownMessage',
    defaultMessage: 'Unknown',
  },
  free: {
    id: 'components.myaccount.claims.handling.cards.free',
    defaultMessage: 'Free',
  },
  depositTitle: {
    id: 'components.myaccount.claims.handling.cards.depositTitle',
    defaultMessage: 'Send product : ',
  },
  depositPickupPoint: {
    id: 'components.myaccount.claims.handling.cards.depositPickupPoint',
    defaultMessage: 'depositPickupPoint',
  },
  depositPickupStore: {
    id: 'components.myaccount.claims.handling.cards.depositPickupStore',
    defaultMessage: 'depositPickupStore',
  },
  depositHome: {
    id: 'components.myaccount.claims.handling.cards.depositHome',
    defaultMessage: 'depositHome',
  },
  depositExternal: {
    id: 'components.myaccount.claims.handling.cards.depositExternal',
    defaultMessage: 'depositExternal',
  },
  depositHomeIntervention: {
    id: 'components.myaccount.claims.handling.cards.depositHomeIntervention',
    defaultMessage: 'depositHomeIntervention',
  },
  depositImmediateDropAtStore: {
    id: 'components.myaccount.claims.handling.cards.depositImmediateDropAtStore',
    defaultMessage: 'depositImmediateDropAtStore',
  },

  deliveryTitle: {
    id: 'components.myaccount.claims.handling.cards.deliveryTitle',
    defaultMessage: 'Send product : ',
  },
  deliveryPickupPoint: {
    id: 'components.myaccount.claims.handling.cards.deliveryPickupPoint',
    defaultMessage: 'deliveryPickupPoint',
  },
  deliveryPickupStore: {
    id: 'components.myaccount.claims.handling.cards.deliveryPickupStore',
    defaultMessage: 'deliveryPickupStore',
  },
  deliveryHome: {
    id: 'components.myaccount.claims.handling.cards.deliveryHome',
    defaultMessage: 'Home delivery',
  },
  deliveryExternal: {
    id: 'components.myaccount.claims.handling.cards.deliveryExternal',
    defaultMessage: 'External delivery',
  },
  deliveryPickupStoreDelivery: {
    id: 'components.myaccount.claims.handling.cards.deliveryPickupStoreDelivery',
    defaultMessage: 'deliveryPickupStoreDelivery',
  },
});
