import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  SearchOrderDetails,
} from '@savgroup-front-common/types';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';

const ENDPOINT = `${APIConfiguration.owner}sellers/${COMMON_QUERY_PARAMS.SELLER_ID}/businessReturns/start`;

interface StartOrContinueBusinessReturnCommandPayload {
  ownerId: string;
  orderReference?: string;
  issueId?: string;
  reasonId?: string;
  sellerId: string;
}

export interface EligibilityItem {
  orderLineReference: string;
  message: string;
}

export interface ReturnEligibility {
  success: boolean;
  errors: EligibilityItem[];
}

export interface BusinessReturnEligibilityResultDto {
  order: SearchOrderDetails;
  eligibility: ReturnEligibility;
}

export const startOrContinueBusinessReturnCommand = async (
  payload: StartOrContinueBusinessReturnCommandPayload,
): Promise<
  BackResponse<BusinessReturnEligibilityResultDto> | BackResponseFailure
> => {
  try {
    return await apiCall<BackResponse<BusinessReturnEligibilityResultDto>>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.SELLER_ID, payload.sellerId),
      {
        method: SUPPORTED_METHODS.PUT,
        json: payload,
      },
    );
  } catch (error) {
    return prepareResponseFailure(error);
  }
};
