import { formatISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';

import { NOTIFICATION_TYPES } from '@savgroup-front-common/constants';
import { FileId, Timeslot } from '@savgroup-front-common/types';

import { CommonWorkflowService } from '../../../../../../api/Workflow/CommonWorkflowService';
import { buildNotification } from '../../../../../../helpers/errorsMessage';
import useBanners from '../../../../../../molecules/NotificationsProvider/BannersProvider/hooks';
import { useGetPageSize } from '../../../../hooks/useGetPageSize';
import { useTakeInterventionAppointmentLegacyContext } from '../../TakeInterventionAppointmentLegacy.context';

import { useGetTechnicalInterventionTimeSlots } from './hooks/useGetTechnicalInterventionTimeSlots';
import messages from './messages';

interface UseInterventionAppointmentProps {
  fileId: FileId;
  onSubmit: ({ selectedTimeslot }: { selectedTimeslot: Timeslot }) => void;
}

export const useLegacyInterventionAppointment = ({
  fileId,
  onSubmit,
}: UseInterventionAppointmentProps) => {
  const [enableGetSchedules, setEnableGetSchedules] = useState(true);
  const { removeAllNotifications, pushNotification } = useBanners({
    extendedMessages: messages,
  });
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const pageSize = useGetPageSize();

  const {
    pageNumber,
    selectedTimeslot,
    hasErrors,
    handleValidateTimeSlot,
    handleResetCalendar,
  } = useTakeInterventionAppointmentLegacyContext();

  const { isGetTimeslotsLoading, detailedSchedules, refetch } =
    useGetTechnicalInterventionTimeSlots({
      enableGetSchedules,
      fileId,
      pageNumber,
    });

  useEffect(() => {
    handleResetCalendar();
  }, [handleResetCalendar, pageSize]);

  const {
    isLoading: isCreateTechnicalInterventionLoading,
    mutateAsync: handleValidateSchedule,
  } = useMutation(['createTechnicalIntervention'], async () => {
    removeAllNotifications();
    handleValidateTimeSlot();

    setEnableGetSchedules(false);

    if (!selectedTimeslot) {
      throw new Error(`selectedTimeslot is undefined.`);
    }

    try {
      await CommonWorkflowService.createTechnicalIntervention({
        fileId,
        start: formatISO(selectedTimeslot.start),
        end: formatISO(selectedTimeslot.end),
      });

      onSubmit({ selectedTimeslot });

      setIsSubmitSuccess(true);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (_) {
      pushNotification(
        buildNotification({
          message: messages.createTechnicalInterventionError,
          notificationType: NOTIFICATION_TYPES.ALERT,
          options: {
            hollow: true,
          },
        }),
      );
      setEnableGetSchedules(true);
    }
  });

  return {
    detailedSchedules,
    refetch,
    isSubmitSuccess,
    isGetTimeslotsLoading,
    isCreateTechnicalInterventionLoading,
    hasErrors,
    handleValidateSchedule,
  };
};
