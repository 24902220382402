import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants/src/shared';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared/supportedMethods';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { RequiredPayloadFieldIsNotProvided } from '@savgroup-front-common/exceptions/src/client/errors/RequiredPayloadFieldIsNotProvided';
import {
  BackResponseFailure,
  BaseBackResponse,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.payment}invoices/${COMMON_QUERY_PARAMS.INVOICE_ID}/confirmPayment`;

interface ConfirmPaymentIntentCommandResponse {
  errors: [
    {
      subject: string;
      code: string;
      message: string;
      developerMessage: string;
      moreInfo: string;
      field: string;
      index: number;
      isWarning: boolean;
      extraData: string;
    },
  ];
  isHandledError: boolean;
  failure: boolean;
  hasWarnings: boolean;
  isNotFound: true;
}

interface ConfirmPaymentIntentCommandPayload {
  invoiceId: string;
}

async function confirmPaymentIntent({
  invoiceId,
}: ConfirmPaymentIntentCommandPayload): Promise<
  BaseBackResponse | BackResponseFailure
> {
  const functionName = 'confirmPaymentIntent';

  if (!invoiceId) {
    throw new RequiredPayloadFieldIsNotProvided(invoiceId, functionName);
  }

  const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.INVOICE_ID, invoiceId);

  try {
    const response: ConfirmPaymentIntentCommandResponse = await apiCall(url, {
      method: SUPPORTED_METHODS.POST,
      json: {},
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default confirmPaymentIntent;
