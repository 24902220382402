import toPairs from 'lodash/toPairs';

import dataDE from './country-de.json';
import dataEN from './country-en.json';
import dataES from './country-es.json';
import dataFR from './country-fr.json';

interface CountryOption {
  key: string;
  label: string;
  value: string;
}

function formatCountryData(
  _locale: string,
  data: Record<string, string>,
): CountryOption[] {
  return toPairs(data).map(([countryCode, text]) => ({
    key: countryCode,
    label: text,
    value: countryCode,
  }));
}

const countryCodes = {
  fr: formatCountryData('fr', dataFR),
  en: formatCountryData('en', dataEN),
  es: formatCountryData('es', dataES),
  de: formatCountryData('de', dataDE),
} as Record<string, CountryOption[]>;

const useGetCountriesOptions = (locale = 'en') => {
  return countryCodes[locale];
};

export default useGetCountriesOptions;
