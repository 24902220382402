import { transparentize } from 'polished';
import { Col } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { Input } from '@savgroup-front-common/core/src/atoms/Form';
import { mediaQuery } from '@savgroup-front-common/core/src/grid/helpers';
import { rem } from '@savgroup-front-common/core/src/helpers';

export const $Container = styled('div')`
  position: relative;
  width: 100%;
  padding: 5px 5px 5px 12px;

  ${mediaQuery.sm`padding: 12px 20px;`};
`;

export const $LocationField = styled(Input)`
  padding: 8px 40px 8px 8px;

  ${mediaQuery.sm`
    padding: 12px 8px;
  `};

  && {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const $DropdownSuggestions = styled('div')`
  background-color: ${({ theme }) => theme.colors.white};
  border-style: solid;
  border-radius: ${({ theme }) => theme.borders.radius};
  border-color: ${({ theme }) => theme.colors.default};
  border-width: ${({ theme }) => theme.borders.thickness};
  ${({ theme }) => theme.shadows.elevation__2};
  margin-top: ${rem('2px')};
  z-index: 2;
  position: absolute;
  width: 100%;
`;

export const $Suggestion = styled('div')<{ active: boolean }>`
  color: ${({ theme }) => theme.colors.mainTextColor};
  background-color: ${({ theme, active }) =>
    active ? transparentize(0.85, theme.colors.default) : theme.colors.white};
  padding: ${rem('14px')};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) =>
      transparentize(0.85, theme.colors.default)};
  }
`;

export const $RowStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #c9ced6;
  border-radius: ${({ theme }) => theme.borders.radius};
  &:hover {
    border-color: ${({ theme }) => theme.colors.primary};
  }

  && input {
    border: none;
    box-shadow: none;
  }

  && button {
    & svg {
      margin-right: 0.5rem;
    }

    &:hover {
      &,
      & svg {
        background: #fff;
        color: ${({ theme }) => theme.colors.primary};
        fill: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;

export const $ColWithoutPaddingRight = styled(Col)`
  padding-right: 0;
`;
