import React, { FC, useContext } from 'react';
import HomePageFileItem from './HomePageFileItem/HomePageFileItem';
import { $HomeProductsListWrapper } from '../HomePageProfessional.styles';
import { InitContext } from '../../../../app/NewLayout/InitProvider/InitProvider.context';

const HomePageFiles: FC = () => {
  const { openedFiles, closedFiles } = useContext(InitContext);

  return (
    <$HomeProductsListWrapper>
      {openedFiles?.map((file) => (
        <HomePageFileItem file={file} key={file.fileId} />
      ))}
      {closedFiles?.map((file) => (
        <HomePageFileItem file={file} key={file.fileId} />
      ))}
    </$HomeProductsListWrapper>
  );
};

HomePageFiles.displayName = 'HomePageFiles';
export default HomePageFiles;
