import { STEP_CONFIG } from '../../../ClassiqueRoutes.config';
import {
  PROFESSIONAL_STEP_CONFIG,
  PROFESSIONAL_HOME_CONFIG,
} from '../../../ProfessionalRoutes.config';

import messages from './messages';

export const getStepHeaderSubtitle = (
  stepName: STEP_CONFIG | PROFESSIONAL_STEP_CONFIG | PROFESSIONAL_HOME_CONFIG,
) => {
  let message;

  switch (stepName) {
    case STEP_CONFIG.ISSUES: {
      message = messages.issues;
      break;
    }
    case STEP_CONFIG.CHOOSE_PRODUCTS: {
      message = messages.chooseProduct;
      break;
    }
    case STEP_CONFIG.CLAIM_GROUP_REASON: {
      message = messages.claimGroupReason;
      break;
    }
    case STEP_CONFIG.CLAIM_GROUP_DIAGNOSTIC_TREE: {
      message = messages.claimGroupDiagnosticTree;
      break;
    }
    case STEP_CONFIG.CLAIM_GROUP_PRODUCT_INFO: {
      message = messages.claimGroupProductInfo;
      break;
    }
    case STEP_CONFIG.CLAIM_GROUP_DOCUMENT_RELATED_TO_PRODUCT: {
      message = messages.claimGroupDocumentRelatedToProduct;
      break;
    }
    case STEP_CONFIG.CLAIM_GROUP_DOCUMENT_RELATED_TO_CLAIM: {
      message = messages.claimGroupDocumentRelatedToClaim;
      break;
    }
    case STEP_CONFIG.CLAIM_GROUP_SOLUTION: {
      message = messages.claimGroupSolution;
      break;
    }
    case STEP_CONFIG.CLAIM_GROUP_DEPOSIT: {
      message = messages.claimGroupDeposit;
      break;
    }
    case STEP_CONFIG.CLAIM_GROUP_DELIVERY: {
      message = messages.claimGroupDelivery;
      break;
    }
    case STEP_CONFIG.CLAIM_GROUP_CONFIRMATION: {
      message = messages.claimGroupConfirmation;
      break;
    }

    case PROFESSIONAL_HOME_CONFIG.PROFESSIONAL_HOME_PAGE: {
      message = messages.professionalHomePage;
      break;
    }
    case PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_ISSUES_REASONS_PAGE: {
      message = messages.professionalIssuesAndReasonsPage;
      break;
    }
    case PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_CONFIRMATION_PAGE: {
      message = messages.professionalConfirmationPage;
      break;
    }
    case PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_PRODUCTS_TO_RETURN_PAGE: {
      message = messages.professionalProductsToReturnPage;
      break;
    }
    case PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_SOLUTION_PAGE: {
      message = messages.professionalSolutionPage;
      break;
    }
    case PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_DEPOSIT_PAGE: {
      message = messages.professionalHandlingPage;
      break;
    }
    case PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_DELIVERY_PAGE: {
      message = messages.professionalHandlingPage;
      break;
    }

    default:
      throw new Error('stepName scenario type does not exist');
      break;
  }

  return message;
};
