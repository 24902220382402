import { defineMessages } from 'react-intl';

export default defineMessages({
  titleCard: {
    id: 'components.myaccount.fileDocument.titleCard',
    defaultMessage: 'Documents',
  },
  noDocuments: {
    id: 'components.myaccount.fileDocument.noDocuments',
    defaultMessage: 'No documents available',
  },
});
