import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { BusinessReturnEligibilityResultDto } from './startOrContinueBusinessReturnCommand';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.owner}sellers/${COMMON_QUERY_PARAMS.SELLER_ID}/businessReturns/${COMMON_QUERY_PARAMS.ORDER_REFERENCE}/product`;

interface AddProductToBusinessReturnCommandPayload {
  sellerId: string;
  orderReference: string;
  modelId: string;
  batchQuantity: number;
}

export const addProductToBusinessReturnCommand = async ({
  modelId,
  batchQuantity,
  orderReference,
  sellerId,
}: AddProductToBusinessReturnCommandPayload): Promise<
  BackResponse<BusinessReturnEligibilityResultDto> | BackResponseFailure
> => {
  try {
    return await apiCall<BackResponse<BusinessReturnEligibilityResultDto>>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.SELLER_ID, sellerId).replace(
        COMMON_QUERY_PARAMS.ORDER_REFERENCE,
        orderReference,
      ),
      {
        method: SUPPORTED_METHODS.POST,
        json: {
          modelId,
          batchQuantity,
        },
      },
    );
  } catch (error) {
    return prepareResponseFailure(error);
  }
};
