import React, { useContext } from 'react';

import { Field } from '../../../molecules/Field';
import { ActorInformationItem } from '../../ActorInformation/components/GenericActorInformation/GenericActorInformation.styles';
import messages from '../../ActorInformation/components/GenericActorInformation/messages';
import { ContentTemplateContext } from '../MDXCustomComponents.context';

const DoorCodeCurrentActor = () => {
  const { actorSummary } = useContext(ContentTemplateContext);
  const address = actorSummary?.address;
  const doorCode = address?.doorCode;

  return (
    <>
      {doorCode && (
        <ActorInformationItem>
          <Field label={messages.doorCode} copy={doorCode}>
            {doorCode}
          </Field>
        </ActorInformationItem>
      )}
    </>
  );
};

DoorCodeCurrentActor.displayName = 'DoorCodeCurrentActor';

export default DoorCodeCurrentActor;
