import { defineMessages } from 'react-intl';

export default defineMessages({
  informations: {
    id: 'view.myaccount.component.stepProgress.informations',
    defaultMessage: 'informations',
  },
  requests: {
    id: 'view.myaccount.component.stepProgress.requests',
    defaultMessage: 'requests',
  },
  solutions: {
    id: 'view.myaccount.component.stepProgress.solutions',
    defaultMessage: 'solutions',
  },
  confirmation: {
    id: 'view.myaccount.component.stepProgress.confirmation',
    defaultMessage: 'confirmation',
  },
  issue: {
    id: 'view.myaccount.component.stepProgress.issue',
    defaultMessage: 'Issue',
  },
  scanProducts: {
    id: 'view.myaccount.component.stepProgress.scanProducts',
    defaultMessage: 'Scan products',
  },
  summary: {
    id: 'view.myaccount.component.stepProgress.summary',
    defaultMessage: 'Summary',
  },
  handling: {
    id: 'view.myaccount.component.stepProgress.handling',
    defaultMessage: 'Handling',
  },
});
