export const attachmentsOrigins = {
  MESSAGES_ATTACHMENT: 'MESSAGES_ATTACHMENT',
  FILES_MYACCOUNT_ATTACHMENT: 'FILES_MYACCOUNT_ATTACHMENT',
  FILES_CONTROL_ATTACHMENT: 'FILES_CONTROL_ATTACHMENT',
  SHIPPING_EXPORTS_ATTACHMENT: 'SHIPPING_EXPORTS_ATTACHMENT',
  EXTERNAL_CARRIER_LABEL_ATTACHMENT: 'EXTERNAL_CARRIER_LABEL_ATTACHMENT',
};

export const attachmentsListOrigins = {
  MYACCOUNT: 'myaccount',
  CONTROL: 'control',
};

export const ATTACHMENTS_DOMAIN = {
  DOCUMENTS: 'documents',
};
