import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';
import Origin from '@savgroup-front-common/constants/src/shared/origin';

const ENDPOINT = `${APIConfiguration.claim}claimGroups/businessReturn`;

interface CreateClaimGroupContextFromBusinessReturnCommandArgs {
  orderReference: string;
  sellerId: string;
  ownerId: string;
  origin: Origin;
}

export const createClaimGroupContextFromBusinessReturnCommand = async ({
  orderReference,
  ownerId,
  sellerId,
  origin,
}: CreateClaimGroupContextFromBusinessReturnCommandArgs): Promise<
  BackResponse<string> | BackResponseFailure
> => {
  try {
    return await apiCall<BackResponse<string>>(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: {
        orderReference,
        ownerId,
        sellerId,
        origin,
      },
    });
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};
