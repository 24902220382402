import { Reducer } from 'react';
import { DiagnosticTreeStep } from 'myaccount/types/DiagnosticTree';
import { DIAGNOSTIC_ACTION_TYPES } from './ClaimGroupDiagnosticTreePage.types';

export type ClaimGroupDiagnosticTreePageReducerAction =
  | {
      type: DIAGNOSTIC_ACTION_TYPES.START;
      payload: {
        currentNode: DiagnosticTreeStep;
        history: DiagnosticTreeStep[];
      };
    }
  | {
      type: DIAGNOSTIC_ACTION_TYPES.NEXT_STEP;
      payload: {
        currentNode: DiagnosticTreeStep;
        history: DiagnosticTreeStep[];
      };
    }
  | {
      type: DIAGNOSTIC_ACTION_TYPES.PREVIOUS_STEP;
    }
  | {
      type: DIAGNOSTIC_ACTION_TYPES.UPDATE_STEP;
      payload: {
        currentNode: DiagnosticTreeStep;
        history: DiagnosticTreeStep[];
      };
    };

export interface ClaimGroupDiagnosticTreePageReducerState {
  currentStepNumber: number;
  currentStep?: DiagnosticTreeStep;
  latestStep:
    | {
        currentStepNumber: number;
        node: DiagnosticTreeStep;
        selectedResponseUniqueName: string;
      }[]
    | DiagnosticTreeStep[];
  isPrevious: boolean;
}

export const initDiagnosticTreeState =
  (): ClaimGroupDiagnosticTreePageReducerState => {
    return {
      currentStepNumber: 0,
      currentStep: {
        uniqueName: '',
        sellerId: '',
        tenantId: '',
        type: '',
        title: '',
        instructions: '',
        image: '',
        question: '',
        responses: [],
        status: '',
      },
      latestStep: [],
      isPrevious: false,
    };
  };

const claimGroupDiagnosticTreePageReducer: Reducer<
  ClaimGroupDiagnosticTreePageReducerState,
  ClaimGroupDiagnosticTreePageReducerAction
> = (state, action) => {
  switch (action.type) {
    case DIAGNOSTIC_ACTION_TYPES.START: {
      const currentStartNumber = action.payload.history.length + 1;

      return {
        ...state,
        currentStepNumber: currentStartNumber,
        currentStep: action.payload.currentNode,
        latestStep: action.payload.history.map((step, index) => {
          return {
            ...step,
            currentStepNumber: index + 1,
          };
        }),
      };
    }
    case DIAGNOSTIC_ACTION_TYPES.NEXT_STEP: {
      return {
        ...state,
        currentStepNumber: state.currentStepNumber + 1,
        currentStep: action.payload.currentNode,
        latestStep: action.payload.history.map((step, index) => {
          return {
            ...step,
            currentStepNumber: index + 1,
          };
        }),
        isPrevious: false,
      };
    }
    case DIAGNOSTIC_ACTION_TYPES.PREVIOUS_STEP: {
      const previousStep = state.latestStep[state.latestStep.length - 1];

      return {
        ...state,
        currentStepNumber:
          'currentStepNumber' in previousStep
            ? previousStep.currentStepNumber
            : 1,
        currentStep: 'node' in previousStep ? previousStep.node : undefined,
        latestStep: state.latestStep.slice(0, -1),
        isPrevious: true,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default claimGroupDiagnosticTreePageReducer;
