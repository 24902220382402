import { createRequest } from '../../services/request';

export enum STORE_ACTION_TYPE {
  GET_STORE = 'STORE/GET_STORE',
  GET_STORES_BY_SELLER_ID = 'STORE/GET_STORES_BY_SELLER_ID',
}

export const GET_STORE = createRequest(STORE_ACTION_TYPE.GET_STORE);
export const GET_STORES_BY_SELLER_ID = createRequest(
  STORE_ACTION_TYPE.GET_STORES_BY_SELLER_ID,
);
