import {
  AddressInfoDto,
  RequestLineStatus,
} from '@savgroup-front-common/types';

import messages from './messages';

interface MessageType {
  id: string;
  defaultMessage: string;
}

export const getStatusText = (status: string): MessageType => {
  if (status === RequestLineStatus.ORDER_DELIVERED)
    {return messages.statusValueDelivered;}
  if (status === RequestLineStatus.ORDER_SHIPPED)
    {return messages.statusValueShipped;}
  if (status === RequestLineStatus.TO_PROCESS)
    {return messages.statusValueToProcess;}

  return messages.statusValueSubmitted;
};

export const getAdressText = (deliveryAddress: AddressInfoDto): string => {
  return `${deliveryAddress.address}, ${deliveryAddress.postalCode} ${deliveryAddress.city}`;
};
