export enum PAYMENT_STATUS {
  COMPLETED = 'Completed',
  CANCELLED = 'Cancelled',
  FAILED = 'Failed',
  ISSUED = 'Issued',
  PENDING = 'Pending',
  CREATED = 'Created',
}

export enum PAYMENT_TYPE {
  IMMEDIATE = 'Immediate',
  END_OF_MONTH = 'EndOfMonth',
}
