import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'view.pageNotFound.title',
    defaultMessage: 'Sorry, page not found',
  },
  buttonLabel: {
    id: 'view.pageNotFound.buttonLabel',
    defaultMessage: `Go to {page}`,
  },
  myOrders: {
    id: 'components.myaccount.layout.sidebar.myOrders',
    defaultMessage: 'Mes commandes',
  },
});
