import { Action } from 'redux-actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  logCritical,
  logError,
} from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import { callAndGetResponse } from '@savgroup-front-common/core/src/services';

import * as ActionTypes from './actionTypes';
import { GetProductFullInfoWorkerPayload } from './types';

function* getProductFullInfoWorker({
  payload,
}: {
  payload: GetProductFullInfoWorkerPayload;
}): Generator {
  const { ownerId, productId } = payload;

  if (!ownerId || !productId) {
    logError(`getProductFullInfoWorker with invalid payload`, {
      ownerId,
      productId,
    });

    yield put(ActionTypes.GET_PRODUCT_FULL_INFO.end({ ownerId, productId }));

    return;
  }

  yield call(
    callAndGetResponse,
    ActionTypes.GET_PRODUCT_FULL_INFO,
    `${APIConfiguration.owner}owners/${ownerId}/products/${productId}/full`,
    {
      method: SUPPORTED_METHODS.GET,
    },
    {
      productId,
    },
  );

  yield put(ActionTypes.GET_PRODUCT_FULL_INFO.end({ ownerId, productId }));
}
function* getProductFullInfoWatcher() {
  yield takeEvery<Action<GetProductFullInfoWorkerPayload>>(
    ActionTypes.GET_PRODUCT_FULL_INFO.BASE,
    getProductFullInfoWorker,
  );
}

export default function* ownerSaga() {
  try {
    yield all([getProductFullInfoWatcher()]);
  } catch (error) {
    logCritical(error);
  }
}
