import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

 
const ENDPOINT = `${APIConfiguration.claim}claimGroups/${COMMON_QUERY_PARAMS.CLAIM_GROUP_ID}/claimsSummary`;

interface GetClaimGroupSummaryReturnValues {
  state: string;
  claimId: string;
  sellerId: string;
  ownerId: string;
  ownerProductId: string;
  warrantyTypeId: string;
  issueId: string;
  symptomId: string;
  questionId: string;
  reasonId: string;
  reasonComment: string;
  solutionTypeId: string;
  ignoreDateRulesChecking: boolean;
  groupId: string;
  additionalClaimInformation: {
    additionalInformationId: string;
    additionalInformationEnumValue: string;
    additionalInformationStringValue: string;
    additionalInformationFilesValue?: {
      blobName: string;
      customerVisibility: boolean;
      isUploadedByOwner: boolean;
      uploadDateUtc: string;
      uploaderFullName: string;
      uploaderId: string;
      fileName: string;
      url: string;
    }[];
    additionalInformationFileValue: {
      fileName: string;
      url: string;
      uploaderId: string;
      uploaderFullName: string;
      isUploadedByOwner: boolean;
      uploadDateUtc: Date;
      customerVisibility: boolean;
    };
  }[];
  fileId: string;
  externalCaseId: string;
  safetyRiskDeclared: boolean;
}

interface GetClaimGroupSummaryArgs {
  claimGroupId: string;
}

const getClaimGroupSummary = async ({
  claimGroupId,
}: GetClaimGroupSummaryArgs): Promise<
  BackResponse<GetClaimGroupSummaryReturnValues[]> | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(
      COMMON_QUERY_PARAMS.CLAIM_GROUP_ID,
      claimGroupId,
    );

    const response = await apiCall<
      BackResponse<GetClaimGroupSummaryReturnValues[]>
    >(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default getClaimGroupSummary;
