import BanneredLayout from 'myaccount/view/app/NewLayout/BanneredLayout/BanneredLayout';
import React, { FC } from 'react';
import messages from './messages';
import { useLayout } from 'myaccount/view/app/NewLayout/NewLayout.hooks';
import { $HomeContentWrapper } from './HomePage.styles';
import { RouteTabs } from '@savgroup-front-common/core/src/atoms/RouteTabs';

import { Redirect, Route } from 'react-router-dom';
import useHomeTabs from './hooks/useHomeTabs';
import { BASE_ROUTES, HOME_ROUTES } from 'myaccount/view/app';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';
import { ICONS_TYPE } from '@savgroup-front-common/types';

const HomePage: FC = () => {
  const { login } = useLayout();
  const { tabsConfig } = useHomeTabs();

  return (
    <BanneredLayout isHomePage>
      <BanneredLayout.BannerTitle
        title={{
          ...messages.bannerTitle,
          values: { userName: login.firstName || '' },
        }}
        description={messages.bannerDescription}
      />
      <BanneredLayout.Content hasBannerUrl>
        <$HomeContentWrapper>
          <RouteTabs
            config={tabsConfig.map((tab) => ({
              ...tab,
              icon: <MyAccountIcon icon={tab.icon as ICONS_TYPE} />,
            }))}
            hasBottomBorder
            hideButton
            level={2}
          >
            <Route exact path={HOME_ROUTES.PRODUCTS}>
              <Redirect
                to={
                  tabsConfig?.at(0)?.url ||
                  `${BASE_ROUTES.NEW_DESIGN}${BASE_ROUTES.HOME}`
                }
              />
            </Route>
          </RouteTabs>
        </$HomeContentWrapper>
      </BanneredLayout.Content>
    </BanneredLayout>
  );
};

HomePage.displayName = 'HomePage';
export default HomePage;
