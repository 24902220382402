import { fromJS } from 'immutable';
import find from 'lodash/find';
import get from 'lodash/get';
import { createSelector } from 'reselect';

import { Selectors as WorkflowSelectors } from '@savgroup-front-common/core/src/domains/workflow';

import { currentFileIdSelector } from './currentFileId';

export const customerState = (state) => state.customer;

export const currentFileHandlingSelector = createSelector(
  [currentFileIdSelector, WorkflowSelectors.handling],
  (fileId, handling) => handling.getIn([fileId]) || fromJS({}),
);

export const handlingSummary = createSelector(
  [customerState, currentFileHandlingSelector],
  (customer, fileHandling) => {
    const trackingNumbers = get(
      customer,
      'customerFileSummary.trackingNumbers',
      [],
    );
    const handlingData =
      fileHandling && fileHandling.get('isLoaded') && fileHandling.get('value');
    const depositAddress =
      get(handlingData, 'deposit.pickupPointAddress') ||
      get(handlingData, 'deposit.address');
    const deliveryAddress =
      get(handlingData, 'delivery.pickupPointAddress') ||
      get(handlingData, 'delivery.address');
    const depositCarrier = get(
      find(trackingNumbers, { handlingDirection: 'FromOwner' }),
      'carrier',
    );
    const deliveryCarrier = get(
      find(trackingNumbers, { handlingDirection: 'ToOwner' }),
      'carrier',
    );
    const trackingUrlDepositCarrier = get(
      find(trackingNumbers, { handlingDirection: 'FromOwner' }),
      'trackingUrl',
    );
    const trackingUrlDeliveryCarrier = get(
      find(trackingNumbers, { handlingDirection: 'ToOwner' }),
      'trackingUrl',
    );

    return {
      handlingStarted: Boolean(depositAddress),
      deposit: depositAddress && {
        ...depositAddress,
        address: `${
          depositAddress.address || depositAddress.additionalAddress
        }`,
        carrier: depositCarrier,
      },
      trackingUrlDepositCarrier,
      trackingUrlDeliveryCarrier,
      delivery: deliveryAddress && {
        ...deliveryAddress,
        address: `${
          deliveryAddress.address || deliveryAddress.additionalAddress
        }`,
        carrier: deliveryCarrier,
      },
    };
  },
);
