import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from 'react-styled-flexboxgrid';

import { BUTTON_TYPES, INPUT_TYPES } from '@savgroup-front-common/constants';
import { Card } from '@savgroup-front-common/core/src/atoms/Cards';
import { Paragraph } from '@savgroup-front-common/core/src/atoms/Paragraph';
import { safeFormattedIntlString } from '@savgroup-front-common/core/src/formatters';
import { InputHookForm } from '@savgroup-front-common/core/src/molecules/Form';

import devicePasswordFormSchema from './DevicePasswordForm.schema';
import {
  $DeviceFormContainer,
  $DevicePasswordButton,
  $DevicePasswordTitle,
} from './DevicePasswordForm.styles';
import { DEVICE_PASSWORD_FORM_FIELDS } from './DevicePasswordForm.types';
import messages from './messages';

const DevicePasswordForm = ({ onSubmit }) => {
  const formContext = useForm({
    shouldUnregister: true,

    resolver: yupResolver(devicePasswordFormSchema()),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = formContext;

  const handleDevicePasswordSubmit = (data) => {
    onSubmit(data);
  };

  return (
    <>
      <Row>
        <Col xs={12}>
          <Card>
            <form onSubmit={handleSubmit(handleDevicePasswordSubmit)}>
              <Row between="sm">
                <Col xs={12} md={4}>
                  <$DevicePasswordTitle heading>
                    {safeFormattedIntlString(messages.devicePasswordTitle)}
                  </$DevicePasswordTitle>
                  <Paragraph>
                    {safeFormattedIntlString(messages.devicePasswordSubTitle)}
                  </Paragraph>
                </Col>
                <Col xs={12} mdOffset={2} md>
                  <$DeviceFormContainer>
                    <Col xs={12}>
                      <InputHookForm
                        register={register}
                        label={messages.enterDevicePassword}
                        name={DEVICE_PASSWORD_FORM_FIELDS.devicePassword}
                        type={INPUT_TYPES.TEXT}
                        errors={errors}
                        isRequired
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <$DevicePasswordButton
                        type={BUTTON_TYPES.SUBMIT}
                        primary
                        fluid
                      >
                        <FormattedMessage {...messages.submit} />
                      </$DevicePasswordButton>
                    </Col>
                  </$DeviceFormContainer>
                </Col>
              </Row>
            </form>
          </Card>
        </Col>
      </Row>
    </>
  );
};

DevicePasswordForm.displayName = 'DevicePasswordForm';

export default DevicePasswordForm;
