export const onDeadEndForClaimGroupStarted = (state, { meta }) => {
  const { orderId } = meta;

  return state.setIn(['deadEnd', orderId], { isLoading: true });
};
export const onDeadEndForClaimGroupErrored = (state, { meta }) => {
  const { orderId } = meta;

  return state.setIn(['deadEnd', orderId], {
    isLoading: false,
    isReportSent: false,
  });
};
export const onDeadEndForClaimGroupSucceeded = (state, { meta }) => {
  const { orderId } = meta;

  return state.setIn(['deadEnd', orderId], {
    isLoading: true,
    isReportSent: true,
  });
};

export function onOrdersLoaded(state, { items }) {
  return items.reduce(
    (acc, { orderId, noSolution }) =>
      acc.setIn(['deadEnd', orderId], {
        isLoaded: true,
        isDirty: false,
        isReportSent: noSolution,
      }),
    state,
  );
}
