import React, { FC, Suspense } from 'react';

import { CURRENCIES } from '@savgroup-front-common/constants';
import { Skeleton } from '@savgroup-front-common/core/src/atoms/Skeleton';

import { PaymentContent } from './PaymentContent';

interface PaymentProps {
  invoice: { id: string; priceWithTax: number; currencyTrigram: CURRENCIES };
  onSubmitSuccess: () => void;
  isFormCompleted: boolean;
  claimGroupId: string;
}

export const Payment: FC<PaymentProps> = ({
  invoice,
  isFormCompleted,
  onSubmitSuccess,
  claimGroupId,
}) => {
  return (
    <Suspense fallback={<Skeleton width="100%" height="20rem" />}>
      <PaymentContent
        invoice={invoice}
        isFormCompleted={isFormCompleted}
        onSubmitSuccess={onSubmitSuccess}
        claimGroupId={claimGroupId}
      />
    </Suspense>
  );
};

Payment.displayName = 'Payment';
