import { useQuery } from 'react-query';

import { ClaimService } from 'myaccount/api';

export const GetClaim = 'getClaim';

export const useGetClaimGroupQuery = ({
  claimGroupId,
}: {
  claimGroupId: string;
}) => {
  const { data: claimId } = useQuery([GetClaim, { claimGroupId }], async () => {
    if (!claimGroupId) {return undefined;}

    const response = await ClaimService.getClaimGroupQuery({
      claimGroupId,
    });

    if (response.failure) {
      return undefined;
    }

    return response.value;
  });

  return {
    claimId,
  };
};
