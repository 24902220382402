import React, { FC, ReactElement } from 'react';

import { MessageType } from '@savgroup-front-common/types';

import {
  BASE_LOADER_DIRECTION,
  BaseLoader,
} from '../../../molecules/BaseLoader';

interface AppointmentCalendarLoaderProps {
  customLoader?: ReactElement;
  messageList?: MessageType[];
}

const AppointmentCalendarLoader: FC<AppointmentCalendarLoaderProps> = ({
  customLoader,
  messageList = [],
}) => {
  return (
    <BaseLoader
      size="56px"
      customLoader={customLoader}
      hasMargin={false}
      direction={BASE_LOADER_DIRECTION.COLUMN}
      messageList={messageList}
    />
  );
};

AppointmentCalendarLoader.displayName = 'AppointmentCalendarLoader';

export default AppointmentCalendarLoader;
