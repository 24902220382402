import styled from 'styled-components';

export const $QuotationLine = styled.div<{
  $isActive: boolean;
  $isSelected?: boolean;
  $isFluid: boolean;
}>`
  text-align: left;
  opacity: ${({ $isSelected = true }) => ($isSelected ? undefined : 0.4)};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${({ $isFluid }) => ($isFluid ? '100%' : null)};

  padding: 1rem 0;
  color: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.mainTextColor : theme.colors.paragraphTextColor};

  & > div:first-child {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }
`;

export const $AmountCell = styled.div`
  text-align: right;
`;
