import styled from 'styled-components';

export const $HistoryLineCustomerComment = styled.span`
  color: ${({ theme }) => theme.colors.paragraphTextColor};
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  margin-left: 2rem;

  white-space: break-spaces;
`;

export const $Column = styled.div`
  display: flex;
  flex-direction: column;
`;
