import mapValues from 'lodash/mapValues';
import * as Yup from 'yup';

import messages from './messages';

const schema = Yup.lazy((data) =>
  Yup.object().shape({
    sparePartLines: Yup.object(
      mapValues(data.sparePartLines, () =>
        Yup.object({
          condition: Yup.string()
            .nullable()
            .test(
              'validCondition',
              messages.sparePartConditionNoMoreAvailable,
              (_value, context) => {
                const { isSelectedForRepair, condition } = context.parent;

                if (!isSelectedForRepair) {
                  return true;
                }

                return !!condition;
              },
            ),
        }),
      ),
    ),
    paymentType: Yup.object().nullable(),
  }),
);

export default schema;
