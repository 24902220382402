import { defineMessages } from 'react-intl';

export default defineMessages({
  downloadDocument: {
    id: 'view.myccount.fileDocmuent.downloadDocument',
    defaultMessage: 'Download',
  },
  dateDocument: {
    id: 'view.myccount.fileDocmuent.dateDocument',
    defaultMessage: 'From {date}',
  },
  ShippingLabel: {
    id: 'view.myccount.fileDocmuent.shippingLabel',
    defaultMessage: 'Shipping label',
  },
  ShippingLabelDescription: {
    id: 'view.myccount.fileDocmuent.ShippingLabelDescription',
    defaultMessage: 'To stick on your package',
  },
  ReturnVoucherDescription: {
    id: 'view.myccount.fileDocmuent.ReturnVoucherDescription',
    defaultMessage: 'To slip into your package',
  },
});
