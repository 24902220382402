import get from 'lodash/get';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import React, { FC, Suspense } from 'react';
import { useDispatch } from 'react-redux';

import { EXPECTS, FILE_STATES } from '@savgroup-front-common/constants';
import { AnimatedRenderer } from '@savgroup-front-common/core/src/atoms/AnimatedRenderer';
import { Card } from '@savgroup-front-common/core/src/atoms/Cards';
import { Skeleton } from '@savgroup-front-common/core/src/atoms/Skeleton';
import { TakeInterventionAppointmentContainer } from '@savgroup-front-common/core/src/components/InterventionAppointment';
import { ActionCreators as fileAttachmentsActionCreators } from '@savgroup-front-common/core/src/domains/attachments';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { BannersProvider } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { ModuleSummary } from '@savgroup-front-common/types';

import { submitAdditionalInfo } from '../../../../../../domains/FileTrackingPage/actionCreators';
import { useGetSellerConfiguration } from '../../../../../app/hooks';
import DevicePasswordForm from '../../../../../components/DevicePasswordForm';
import AdditionalInfo from '../AdditionalInfo/AdditionalInfo';
import AdditionalInformationExpect from '../AdditionalInformationExpect/AdditionalInformationExpect';
import { useClientAction } from '../ClientAction.hooks';
import CollectBankAccountDetails from '../CollectBankAccountDetails/CollectBankAccountDetails';
import { ConfirmedTechnicalInterventionSchedule } from '../ConfirmedTechnicalInterventionSchedule';
import { shouldDisplayConfirmedTechnicalInterventionSchedule } from '../ConfirmedTechnicalInterventionSchedule/ConfirmedTechnicalInterventionSchedule.helpers';
import DeliveredSparePartsList from '../DeliveredSparePartsList/DeliveredSparePartsList';
import useGetClientData from '../hooks/useGetClientData';
import { QuotationValidation } from '../QuotationValidation';
import { ReturnProductForm } from '../ReturnProductForm';
import { StatusWarningDisplay } from '../StatusWarningDisplay';
import { UnlinkDevice } from '../UnlinkDevice';

import AnswerQuotation from './AnswerQuotation/AnswerQuotation';
import { $ExpectContainer } from './ExpectsContainer.styles';
import { PaymentCard } from './PaymentCard/PaymentCard';
import { TakeAppointment } from './TakeAppointment/TakeAppointment';

interface ExpectsContainerProps {
  isNewDesign?: boolean;
}

const ExpectsContainer: FC<ExpectsContainerProps> = () => {
  const dispatch = useDispatch();

  const {
    loadingStatuses,
    workflowInfos,
    fileSummary,
    invoice,
    claimConfirmationData,
    isStateUpdatePending,
    fileId,
    module,
    transitions,
    fileStatusName,
    invoiceId,
  } = useGetClientData();

  const {
    firstAction,
    currentActions,
    firstTransitionWithExpect,
    otherTransitions,
    isSubmitWorkflowLoading,
    warning,
    currentStatusName,
    adaptedFirstAction,
    handleSubmit,
    handleAwaitingPaymentSubmit,
    handleInterventionAppointmentSubmit,
    targetStateName,
  } = useClientAction({
    fileSummary,
    claimConfirmationData,
    workflowInfos,
    fileStatusName,
    invoiceId,
    module,
  });
  const { sellerConfiguration } = useGetSellerConfiguration({
    sellerId: fileSummary?.sellerId,
  });

  if (!workflowInfos?.file?.currentActions) {
    return <></>;
  }

  if (warning) {
    return <StatusWarningDisplay adaptedFirstAction={adaptedFirstAction} />;
  }

  if (loadingStatuses.isLoading) {
    return <BaseLoader />;
  }

  if (
    (firstTransitionWithExpect && !loadingStatuses.isLoading) ||
    currentStatusName === FILE_STATES.AWAITING_ADDITINAL_INFO
  ) {
    const selectedExpect = firstTransitionWithExpect?.expect;
    const supportedExpects = [
      EXPECTS.EXPECT_AWAITING_PAYMENT,
      EXPECTS.EXPECT_UNLOCK_CODE_REQUESTED,
      EXPECTS.EXPECT_AWAITING_ACCOUNT_DESACTIVATION,
      EXPECTS.EXPECT_DOCUMENTS_NEEDED,
      EXPECTS.EXPECT_TAKE_APPOINTMENT,
      EXPECTS.EXPECT_TAKE_INTERVENTION_APPOINTMENT,
      EXPECTS.MARK_SPARE_PARTS_REQUEST_AS_DELIVERED,
      EXPECTS.ADDITIONAL_INFORMATION_NEEDED,
      EXPECTS.COLLECT_BANK_ACCOUNT_DETAILS,
    ];

    if (
      selectedExpect &&
      selectedExpect === EXPECTS.ADDITIONAL_INFORMATION_NEEDED
    ) {
      return (
        <BannersProvider>
          <$ExpectContainer>
            <AdditionalInformationExpect
              adaptedFirstAction={adaptedFirstAction}
            />
          </$ExpectContainer>
        </BannersProvider>
      );
    }

    if (
      selectedExpect === EXPECTS.EXPECT_DOCUMENTS_NEEDED ||
      currentStatusName === FILE_STATES.AWAITING_ADDITINAL_INFO
    ) {
      return (
        <BannersProvider>
          <$ExpectContainer>
            <AdditionalInfo
              adaptedFirstAction={adaptedFirstAction}
              onSubmit={(comment: string) => {
                const toState = head(transitions)?.targetState.name;

                if (module && fileStatusName && toState) {
                  dispatch(
                    submitAdditionalInfo({
                      module,
                      fileId,
                      fromState: fileStatusName,
                      toState,
                      comment,
                    }),
                  );
                }
              }}
              addAttachmentToFile={({
                file,
                fileId,
                service,
              }: {
                file: Blob;
                fileId: string;
                service: string;
              }) =>
                dispatch(
                  fileAttachmentsActionCreators.uploadFileAttachment({
                    file,
                    fileId,
                    service,
                  }),
                )
              }
              deleteAttachmentFromFile={({
                fileId,
                fileAttachmentId,
                internalId,
              }: {
                fileId: string;
                fileAttachmentId: string;
                internalId: string;
              }) =>
                dispatch(
                  fileAttachmentsActionCreators.deleteAttachmentFromFile({
                    fileId,
                    fileAttachmentId,
                    internalId,
                  }),
                )
              }
              fileId={fileId}
              isLoading={isStateUpdatePending || isSubmitWorkflowLoading}
            />
          </$ExpectContainer>
        </BannersProvider>
      );
    }

    if (selectedExpect && supportedExpects.includes(selectedExpect)) {
      return (
        <BannersProvider>
          {selectedExpect === EXPECTS.COLLECT_BANK_ACCOUNT_DETAILS && (
            <$ExpectContainer>
              <CollectBankAccountDetails
                fileId={fileId}
                onSubmit={() =>
                  handleSubmit({
                    module,
                    fromState: fileStatusName,
                    toState: targetStateName,
                  })
                }
              />
            </$ExpectContainer>
          )}

          {selectedExpect === EXPECTS.EXPECT_AWAITING_PAYMENT &&
            fileSummary && (
              <$ExpectContainer>
                <PaymentCard
                  invoice={invoice}
                  sellerId={fileSummary?.sellerId}
                  claimGroupId={fileSummary?.claimInfoSummary.claimGroupId}
                  fileId={fileId}
                  onSubmitSuccess={handleAwaitingPaymentSubmit}
                />
              </$ExpectContainer>
            )}

          {selectedExpect === EXPECTS.EXPECT_UNLOCK_CODE_REQUESTED && (
            <$ExpectContainer>
              <DevicePasswordForm
                onSubmit={({ devicePassword }: { devicePassword: unknown }) =>
                  handleSubmit({
                    module,
                    fromState: fileStatusName,
                    toState: targetStateName,
                    expectValues: {
                      DevicePassword: devicePassword,
                    },
                  })
                }
              />
            </$ExpectContainer>
          )}

          {selectedExpect === EXPECTS.EXPECT_AWAITING_ACCOUNT_DESACTIVATION && (
            <$ExpectContainer>
              <UnlinkDevice
                onSubmit={() =>
                  handleSubmit({
                    module,
                    fromState: fileStatusName,
                    toState: targetStateName,
                  })
                }
              />
            </$ExpectContainer>
          )}

          {selectedExpect === EXPECTS.MARK_SPARE_PARTS_REQUEST_AS_DELIVERED && (
            <$ExpectContainer>
              <DeliveredSparePartsList fileId={fileId} />
            </$ExpectContainer>
          )}

          {selectedExpect === EXPECTS.EXPECT_TAKE_APPOINTMENT &&
            fileStatusName &&
            targetStateName &&
            module && (
              <TakeAppointment
                cancelToState={get(head(otherTransitions), [
                  'targetState',
                  'name',
                ])}
                fileSummary={fileSummary}
                fileStatusName={fileStatusName}
                targetStateName={targetStateName}
                module={module}
              />
            )}

          <AnimatedRenderer
            isOpen={
              firstTransitionWithExpect?.expect ===
              EXPECTS.EXPECT_TAKE_INTERVENTION_APPOINTMENT
            }
            delay={500}
            animationDuration={300}
          >
            <TakeInterventionAppointmentContainer
              fileId={fileId}
              sellerId={fileSummary?.sellerId}
              onSubmit={handleInterventionAppointmentSubmit}
              isLoading={isStateUpdatePending || isSubmitWorkflowLoading}
            />
          </AnimatedRenderer>
        </BannersProvider>
      );
    }
  }

  const rejectTransition = (adaptedFirstAction?.transitions || []).filter(
    (action) =>
      !get(action, 'targetState.name', '').includes(
        FILE_STATES.QUOTE_VALIDATED,
      ),
  );

  return (
    <BannersProvider>
      {shouldDisplayConfirmedTechnicalInterventionSchedule({
        action: firstAction,
        workflowInfos,
        holderType: fileSummary.fileProducts[0].holder?.holderType,
      }) && (
        <$ExpectContainer>
          <Suspense fallback={<Skeleton height="15rem" />}>
            <ConfirmedTechnicalInterventionSchedule fileId={fileId} />
          </Suspense>
        </$ExpectContainer>
      )}

      {sellerConfiguration?.isSparePartNewModel === false &&
        currentStatusName &&
        module &&
        fileStatusName &&
        currentStatusName.includes(FILE_STATES.QUOTE_TO_VALIDATE) && (
          <$ExpectContainer>
            <QuotationValidation
              rejectTransitions={rejectTransition}
              file={workflowInfos?.file}
              currentModule={module}
              isLoading={isStateUpdatePending || isSubmitWorkflowLoading}
              fromState={fileStatusName}
            />
          </$ExpectContainer>
        )}

      {sellerConfiguration?.isSparePartNewModel &&
        firstTransitionWithExpect?.expect === EXPECTS.ANSWER_QUOTATION && (
          <$ExpectContainer>
            <AnswerQuotation
              file={workflowInfos?.file}
              isLoading={isStateUpdatePending || isSubmitWorkflowLoading}
            />
          </$ExpectContainer>
        )}

      {currentStatusName === FILE_STATES.PRODUCT_RETURN_CHOICE &&
        !isEmpty(currentActions) && (
          <$ExpectContainer>
            <Card>
              <ReturnProductForm
                actions={currentActions}
                onSubmit={({
                  module,
                  fromState,
                  targetState,
                }: {
                  module?: ModuleSummary;
                  fromState?: string;
                  toState?: string;
                  toCustomerComment?: string;
                  comment?: string;
                  expectValues?: Record<string, any>;
                  targetState: string;
                }) => handleSubmit({ module, fromState, toState: targetState })}
                isStateUpdatePending={isStateUpdatePending}
              />
            </Card>
          </$ExpectContainer>
        )}
    </BannersProvider>
  );
};

ExpectsContainer.displayName = 'ExpectsContainer';

export default ExpectsContainer;
