import { useQuery } from 'react-query';
import { v4 } from 'uuid';

import {
  COUNTRY_CODES,
  FULL_STANDARD_DATE_TIME_FORMAT_DATE_FNS,
  STALE_TIME,
  STANDARD_DATE_FORMAT_DATE_FNS,
  STANDARD_DATE_TIME_FORMAT_DATE_FNS,
} from '@savgroup-front-common/constants';
import { Timeslot } from '@savgroup-front-common/types';

import { CommonCarrierService } from '../api';
import { HomePickupPickupTimeRange } from '../api/Carrier/getHomePickupScheduleQuery';
import { DetailedSchedule } from '../components/AppointmentCalendar/types/DetailedSchedule';
import { tryToParse } from '../helpers';
import { useBanners } from '../molecules/NotificationsProvider';

const GetPickupSchedule = 'getPickupSchedule';

const parseDate = (value: string): Date => {
  const response = tryToParse({
    value,
    patterns: [
      STANDARD_DATE_FORMAT_DATE_FNS,
      STANDARD_DATE_TIME_FORMAT_DATE_FNS,
      FULL_STANDARD_DATE_TIME_FORMAT_DATE_FNS,
    ],
  });

  if (response.failure) {
    throw new Error(`Failed to parse date ${value}`);
  }

  return response.value;
};

const adaptPickupSchedule = (
  timeRanges: HomePickupPickupTimeRange[],
): DetailedSchedule[] => {
  const pickupSchedules = timeRanges.map((appointment) => {
    const startLocalTime = appointment.startTimeInLocalRecipientTimezone;
    const endLocalTime = appointment.endTimeInLocalRecipientTimezone;
    const date = new Date(parseDate(startLocalTime).setHours(0, 0, 0, 0));

    const startDate = parseDate(startLocalTime);
    const endDate = parseDate(endLocalTime);

    return {
      internalId: v4(),
      date,
      start: new Date(
        startDate.getTime() + startDate.getTimezoneOffset() * 60_000,
      ),
      end: new Date(endDate.getTime() + endDate.getTimezoneOffset() * 60_000),
    };
  });

  const groupedByDate = pickupSchedules.reduce<Record<string, Timeslot[]>>(
    (acc, pickupSchedule) => {
      if (acc[pickupSchedule.date.toISOString()]) {
        return {
          ...acc,
          [pickupSchedule.date.toISOString()]: [
            ...acc[pickupSchedule.date.toISOString()],
            pickupSchedule,
          ],
        };
      }

      return {
        ...acc,
        [pickupSchedule.date.toISOString()]: [pickupSchedule],
      };
    },
    {},
  );

  return Object.entries(groupedByDate).map(([date, timeslots]) => {
    return {
      date: parseDate(date),
      timeslots,
    };
  });
};

const useGetHomePickupSchedule = ({
  carrier,
  startTimeInLocalRecipientTimezone,
  endTimeInLocalRecipientTimezone,
  countryCode,
  postalCode,
  fileId,
  isFileHandlingLoading,
}: {
  carrier: string;
  startTimeInLocalRecipientTimezone: Date;
  endTimeInLocalRecipientTimezone?: Date;
  countryCode: COUNTRY_CODES;
  postalCode?: string;
  fileId: string;
  isFileHandlingLoading?: boolean;
}) => {
  const { pushErrors } = useBanners();

  const { data: detailedSchedule } = useQuery(
    [
      GetPickupSchedule,
      {
        carrier,
        startTimeInLocalRecipientTimezone,
        postalCode,
        endTimeInLocalRecipientTimezone,
      },
    ],
    async () => {
      if (
        !carrier ||
        !startTimeInLocalRecipientTimezone ||
        !countryCode ||
        isFileHandlingLoading ||
        !postalCode
      ) {
        return undefined;
      }

      const response = await CommonCarrierService.getHomePickupScheduleQuery({
        carrier,
        startTimeInLocalRecipientTimezone: startTimeInLocalRecipientTimezone.toISOString(),
        endTimeInLocalRecipientTimezone: endTimeInLocalRecipientTimezone?.toISOString(),
        countryCode,
        postalCode,
        fileId,
      });

      if (response.failure) {
        pushErrors(response.errors);

        throw new Error(response.errors[0].message);
      }

      return adaptPickupSchedule(response.value);
    },
    {
      staleTime: STALE_TIME.MINUTES_10,
      suspense: true,
    },
  );

  return {
    detailedSchedule,
  };
};

export default useGetHomePickupSchedule;
