import React, { FunctionComponent } from 'react';

import { FileSummary } from '@savgroup-front-common/types';
import { FileStateHistorySummary } from 'myaccount/api/Workflow/getFileStatesQuery';

import { MonoProductInfo } from './MonoProductInfo';
import { MultiProductInfo } from './MultiProductInfo';
import { ProductsInfoMinimal } from './ProductsInfo.types';

const ProductsInfo: FunctionComponent<{
  fileInfo?: FileSummary;
  productsInfo?: ProductsInfoMinimal[];
  histories?: FileStateHistorySummary[];
  hasHistory?: boolean;
  onClick?: () => void;
}> = ({ fileInfo, productsInfo, histories, hasHistory = false, onClick }) => {
  return (
    <>
      {productsInfo && productsInfo.length === 1 && (
        <MonoProductInfo
          fileInfo={fileInfo}
          productInfo={productsInfo[0]}
          histories={histories}
          hasCreatedDate
          hasHistory={hasHistory}
          onClick={onClick}
        />
      )}
      {productsInfo && productsInfo.length > 1 && (
        <MultiProductInfo
          fileInfo={fileInfo}
          productsInfo={productsInfo}
          histories={histories}
          hasCreatedDate
          hasHistory={hasHistory}
          onClick={onClick}
        />
      )}
    </>
  );
};

ProductsInfo.displayName = 'ProductsInfo';
export default ProductsInfo;
