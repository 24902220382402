import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useMutation } from 'react-query';

import {
  NOTIFICATION_TYPES,
  REVALIDATE_MODES,
} from '@savgroup-front-common/constants';
import { buildNotification } from '@savgroup-front-common/core/src/helpers';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import {
  ADDITIONAL_INFORMATION_TYPES,
  FullClaim,
  Product,
} from '@savgroup-front-common/types';
import {
  AdditionalInformation,
  AdditionalInformationExtended,
  RELATED_TO,
} from 'myaccount/types';
import { STEP_CONFIG } from 'myaccount/view/app/NewLayout/ClassiqueRoutes.config';
import {
  StepContext,
  StepContextValues,
} from 'myaccount/view/app/NewLayout/StepsProvider/StepsProvider.context';

import useGetClaimReasons from '../../app/hooks/useGetClaimReasons';
import { Claim } from '../../app/NewLayout/StepsProvider/StepsProvider.types';

import claimGroupDocumentPageRelatedToProductSchema from './ClaimGroupDocumentPageRelatedToProduct.schema';
import { ClaimGroupDocumentRelatedToProductValues } from './ClaimGroupDocumentPageRelatedToProduct.types';
import { uploadAdditionalInformationFilesToClaim } from './helpers/document.adapters';
import { reasonAdapter } from './helpers/reason.adapters';
import messages from './messages';

interface UseClaimGroupReasonReturnValues {
  claims: Claim[];
  products: Record<string, Product> | undefined;
  isLoading: boolean;
  neededInformationDocumentOnlyRelatedToProduct: AdditionalInformationExtended[];
  formContext: UseFormReturn<ClaimGroupDocumentRelatedToProductValues>;
  onSubmit: () => void;
  isLoadingSubmit: boolean;
}

const useClaimGroupDocumentRelatedToProduct =
  (): UseClaimGroupReasonReturnValues => {
    const { pushNotification, removeAllNotifications, pushErrors } =
      useToasts();
    const { values, handleNextStep, handleValidateStep } =
      useContext<StepContextValues>(StepContext);

    const claims = values.claimGroup?.claims || [];
    const claimGroupId = values.claimGroup?.claimGroupId || '';

    const ownerProductIds = claims.map((claim) => claim.ownerProductId);
    const products = values.orders
      ?.at(0)
      ?.products.filter((product) =>
        ownerProductIds.includes(product.ownerProductId),
      )
      .reduce((acc, curr) => {
        if (curr) {
          return {
            ...acc,
            [curr.ownerProductId]: curr,
          };
        }

        return acc;
      }, {});

    const { reasons, reasonsIsLoading } = useGetClaimReasons({
      claimGroupId,
    });

    const claimIds = claims.map((claim) => claim.claimId);

    const neededInformationDocumentOnlyRelatedToProduct = claims.reduce(
      (acc: AdditionalInformationExtended[], curr) => {
        if (curr) {
          const { reasonId } = curr;
          const reasonSelected: any = reasons.find(
            (reason) => reason.id === reasonId,
          );

          if (reasonSelected) {
            const reason = reasonAdapter({
              reasonSelected,
              claimIds,
              currentClaimId: curr.claimId,
            });

            const neededInformation = reason?.neededInformation
              .filter(
                (item) =>
                  item.type === ADDITIONAL_INFORMATION_TYPES.FILE ||
                  item.type === ADDITIONAL_INFORMATION_TYPES.MULTI_FILES,
              )
              .filter((item) => item.relatedTo === RELATED_TO.PRODUCT);

            return [...acc, ...neededInformation];
          }

          return acc;
        }

        return acc;
      },
      [],
    );

    const adaptAdditionalInformation = (claim: FullClaim) => {
      const { claimId } = claim;

      return claim.additionalClaimInformation?.reduce((acc, curr) => {
        if (curr.additionalInformationFileValue) {
          return {
            ...acc,
            [`${curr.additionalInformationId}_${claimId}`]: {
              value: {
                name: curr.additionalInformationFileValue.fileName,
              },
            },
          };
        }

        return acc;
      }, {});
    };

    const formContext = useForm<ClaimGroupDocumentRelatedToProductValues>({
      resolver: yupResolver(
        claimGroupDocumentPageRelatedToProductSchema({
          claims: claims as any,
          neededInformationDocumentOnlyRelatedToProduct,
        }),
      ),
      defaultValues: claims.reduce((acc, curr: any) => {
        if (curr) {
          return {
            ...acc,
            [curr.claimId]: {
              reasonAdditionalInformationDocumentRelatedToProduct:
                adaptAdditionalInformation(curr),
            },
          };
        }

        return acc;
      }, {}),
      mode: REVALIDATE_MODES.ON_CHANGE,
    });

    const {
      handleSubmit,
      formState: { isValid },
    } = formContext;

    const {
      mutateAsync: handleUploadDocument,
      isLoading: isLoadingUploadDocument,
    } = useMutation(
      ['setUploadDocument'],
      async ({
        neededInformations,
        reasonAdditionalInformationDocument,
        claimId,
      }: {
        neededInformations?: ({
          internalId: string;
          fileUploadEndpoints: Record<string, { uploadEndpoint: string }>;
        } & AdditionalInformation)[];
        reasonAdditionalInformationDocument?: Record<string, unknown>;
        claimId: string;
      }) => {
        removeAllNotifications();
        const responseAdditionalInformationDocument =
          await uploadAdditionalInformationFilesToClaim({
            neededInformations,
            reasonAdditionalInformationDocument,
            claimId,
          });

        if (responseAdditionalInformationDocument?.some((res) => res.failure)) {
          pushNotification(
            buildNotification({
              message: messages.uploadDocumentError,
              notificationType: NOTIFICATION_TYPES.ERROR,
            }),
          );

          return undefined;
        }

        return responseAdditionalInformationDocument;
      },
    );

    const onSubmit = handleSubmit(async (reasonsResult) => {
      const hasAddictionnalInformationFileClaim = reasons
        .map((reason) =>
          reason.neededInformation.some(
            (item) =>
              item.type === ADDITIONAL_INFORMATION_TYPES.FILE &&
              item.relatedTo === RELATED_TO.CLAIM,
          ),
        )
        .some((x) => x);

      return Promise.all(
        claims.map(async (claim) => {
          const neededInformationOnlyRelatedToProductWithFile =
            neededInformationDocumentOnlyRelatedToProduct.filter(
              (item) =>
                item.type === ADDITIONAL_INFORMATION_TYPES.FILE &&
                item.relatedTo === RELATED_TO.PRODUCT,
            );

          const responseAdditionalInformationDocument =
            await handleUploadDocument({
              neededInformations:
                neededInformationOnlyRelatedToProductWithFile as any,
              reasonAdditionalInformationDocument: reasonsResult[claim.claimId]
                .reasonAdditionalInformationDocumentRelatedToProduct as any,
              claimId: claim.claimId,
            });

          if (!responseAdditionalInformationDocument) {
            return undefined;
          }

          return responseAdditionalInformationDocument;
        }),
      )
        .then(() => {
          if (hasAddictionnalInformationFileClaim) {
            return handleNextStep(
              STEP_CONFIG.CLAIM_GROUP_DOCUMENT_RELATED_TO_CLAIM,
              claimGroupId,
            );
          }

          return handleNextStep(STEP_CONFIG.CLAIM_GROUP_SOLUTION, claimGroupId);
        })
        .catch((err) => {
          pushErrors(err);

          return undefined;
        });
    });

    useEffect(() => {
      handleValidateStep(isValid);
    }, [handleValidateStep, isValid]);

    return {
      claims,
      products,
      isLoading: reasonsIsLoading,
      neededInformationDocumentOnlyRelatedToProduct,
      formContext,
      onSubmit,
      isLoadingSubmit: isLoadingUploadDocument,
    };
  };

export default useClaimGroupDocumentRelatedToProduct;
