import React, { FC } from 'react';
import { StepsOrchestrator } from '@savgroup-front-common/core/src/molecules/StepsOrchestrator';
import {
  ISSUES_REASONS_STEP_NAMES,
  ISSUES_REASONS_STEPS,
} from './IssuesReasonsPage.steps';
import { IssuesReasonsPageSubmitValues } from './IssuesReasonsPage.types';
import { useIssuesReasonsPage } from './IssuesReasonsPage.hooks';

const IssuesReasonsPage: FC = () => {
  const { handleSubmit, order, isLoading } = useIssuesReasonsPage();

  if (isLoading) {
    return <></>;
  }

  return (
    <StepsOrchestrator<IssuesReasonsPageSubmitValues>
      initialStep={ISSUES_REASONS_STEP_NAMES.SELECT_ISSUE}
      config={ISSUES_REASONS_STEPS}
      onSubmit={handleSubmit}
      withWizard={false}
      initialValues={{
        order,
      }}
    />
  );
};

IssuesReasonsPage.displayName = 'IssuesReasonsPage';

export default IssuesReasonsPage;
