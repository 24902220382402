import React, { FC } from 'react';

import {
  $AmountCell,
  QuotationLine,
} from '@savgroup-front-common/core/src/components/Quotation/QuotationLines';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import getSparePartConditionMessage from '@savgroup-front-common/core/src/formatters/getSparePartConditionMessage';
import { formatReverseMoneyToString } from '@savgroup-front-common/core/src/formatters/intl';

interface NonOwnerRecipientSparePartQuotationLineProps {
  sparePartQuotationLine: any;
}

const NonOwnerRecipientSparePartQuotationLine: FC<
  NonOwnerRecipientSparePartQuotationLineProps
> = ({ sparePartQuotationLine }) => {
  return (
    <QuotationLine
      key={sparePartQuotationLine.id}
      isActive
      gridTemplateColumns={
        sparePartQuotationLine.recommendedSalePriceIncludingTaxes
          ? '8fr 2fr 2fr'
          : '10fr 2fr'
      }
    >
      <div>
        {sparePartQuotationLine.subCategory}
        {' - '}
        {sparePartQuotationLine.supplierReference}
      </div>
      <div>
        {sparePartQuotationLine.condition && (
          <SafeFormattedMessageWithoutSpread
            message={getSparePartConditionMessage(
              sparePartQuotationLine.condition,
            )}
          />
        )}
      </div>
      {sparePartQuotationLine.recommendedSalePriceIncludingTaxes && (
        <$AmountCell>
          {formatReverseMoneyToString({
            amount: 0,
            currency:
              sparePartQuotationLine.recommendedSalePriceIncludingTaxes
                .currency,
          })}
        </$AmountCell>
      )}
    </QuotationLine>
  );
};

NonOwnerRecipientSparePartQuotationLine.displayName =
  'NonOwnerRecipientSparePartQuotationLine';

export default NonOwnerRecipientSparePartQuotationLine;
