import getOrderByOwnerId from './getOrderByOwnerId';
import getOrderDetailsQuery from './getOrderDetailsQuery';
import getProductFullInformations from './getProductFullInformations';
import getProductsByOwnerId from './getProductsByOwnerId';
import listOwnerProductsQuery from './listOwnerProductsQuery';
import createOrderAndShareLinkByProductReferenceCommand from './createOrderAndShareLinkByProductReferenceCommand';
import { startOrContinueBusinessReturnCommand } from './startOrContinueBusinessReturnCommand';
import { removeProductToBusinessReturnCommand } from './removeProductToBusinessReturnCommand';
import { addProductToBusinessReturnCommand } from './addProductToBusinessReturnCommand';
import { setProductToBusinessReturnCommand } from './setProductToBusinessReturnCommand';
import { getVideoSessionPublisherTokenQuery } from './getVideoSessionPublisherTokenQuery';
import { createOrderByProductReferenceCommand } from './createOrderByProductReferenceCommand';

export const OwnerService = {
  getProductFullInformations,
  getOrderByOwnerId,
  getOrderDetailsQuery,
  getProductsByOwnerId,
  listOwnerProductsQuery,
  createOrderAndShareLinkByProductReferenceCommand,
  startOrContinueBusinessReturnCommand,
  setProductToBusinessReturnCommand,
  addProductToBusinessReturnCommand,
  removeProductToBusinessReturnCommand,
  getVideoSessionPublisherTokenQuery,
  createOrderByProductReferenceCommand,
};
