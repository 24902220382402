import React from 'react';
import { useMedia } from 'react-use';
import { useTheme } from 'styled-components';

import { CURRENCIES, media } from '@savgroup-front-common/constants';
import { ClaimGroupConfirmation } from 'myaccount/api/Claim/getClaimGroupConfirmationQuery';
import { buildClaimSolutionUrl } from 'myaccount/view/app/NewLayout/ClassiqueLayout.helpers';
import {
  SimpleItemCard,
  SimpleItemCardMobile,
} from 'myaccount/view/atoms/SimpleItemCard';

import messages from './messages';
import { useResumeSolution } from './ResumeSolution.hooks';

interface ResumeSolutionProps {
  claimGroupId?: string;
  claimGroupConfirmation?: ClaimGroupConfirmation;
  customBuildUpdateSolutionUrl?: string;
}

const ResumeSolution: React.FC<ResumeSolutionProps> = ({
  claimGroupId,
  claimGroupConfirmation,
  customBuildUpdateSolutionUrl,
}) => {
  const isMobileView = useMedia(media.maxWidth.xs);
  const theme = useTheme();
  const { solution } = useResumeSolution({
    claimGroupConfirmation,
  });

  const linkUrl =
    customBuildUpdateSolutionUrl ||
    (claimGroupId && buildClaimSolutionUrl({ claimGroupId }));

  return isMobileView ? (
    <SimpleItemCardMobile
      icon={solution?.iconKey}
      iconColor={theme.colors.primary}
      prefixTitle={messages.solutionTitle}
      title={solution?.name}
      didactic={solution?.didactic}
      link={messages.modifyButton}
      linkUrl={linkUrl}
      price={{
        priceValue: solution?.priceWithTax,
        priceCurrencyCode: solution?.priceCurrencyCode as CURRENCIES,
      }}
      hasDisplayFreeAmount={false}
    />
  ) : (
    <SimpleItemCard
      icon={solution?.iconKey}
      iconColor={theme.colors.primary}
      prefixTitle={messages.solutionTitle}
      title={solution?.name}
      didactic={solution?.didactic}
      link={messages.modifyButton}
      linkUrl={linkUrl}
      price={{
        priceValue: solution?.priceWithTax,
        priceCurrencyCode: solution?.priceCurrencyCode as CURRENCIES,
      }}
      hasDisplayFreeAmount={false}
    />
  );
};

ResumeSolution.displayName = 'ResumeSolution';

export default ResumeSolution;
