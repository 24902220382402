import { defineMessages } from 'react-intl';

export default defineMessages({
  issues: {
    id: 'view.myaccount.step.issues.subtitle',
    defaultMessage: 'Issue subTitle',
  },
  chooseProduct: {
    id: 'view.myaccount.step.chooseProduct.subtitle',
    defaultMessage: 'chooseProduct subsubtitle',
  },
  claimGroupReason: {
    id: 'view.myaccount.step.claimGroupReason.subtitle',
    defaultMessage: 'claimGroupReason subsubtitle',
  },
  claimGroupDiagnosticTree: {
    id: 'view.myaccount.step.claimGroupDiagnosticTree.subtitle',
    defaultMessage: 'claimGroupDiagnosticTree subtitle',
  },
  claimGroupProductInfo: {
    id: 'view.myaccount.step.claimGroupProductInfo.subtitle',
    defaultMessage: 'claimGroupProductInfo subsubtitle',
  },
  claimGroupDocumentRelatedToProduct: {
    id: 'view.myaccount.step.claimGroupDocumentRelatedToProduct.subtitle',
    defaultMessage: 'claimGroupDocument Related to Product subsubtitle',
  },
  claimGroupDocumentRelatedToClaim: {
    id: 'view.myaccount.step.claimGroupDocumentRelatedToClaim.subtitle',
    defaultMessage: 'claimGroupDocument Related to Claim subsubtitle',
  },
  claimGroupSolution: {
    id: 'view.myaccount.step.claimGroupSolution.subtitle',
    defaultMessage: 'claimGroupSolution subsubtitle',
  },
  claimGroupDeposit: {
    id: 'view.myaccount.step.claimGroupDeposit.subtitle',
    defaultMessage: 'claimGroupDeposit subsubtitle',
  },
  claimGroupDelivery: {
    id: 'view.myaccount.step.claimGroupDelivery.subtitle',
    defaultMessage: 'claimGroupDelivery subsubtitle',
  },
  claimGroupConfirmation: {
    id: 'view.myaccount.step.claimGroupConfirmation.subtitle',
    defaultMessage: 'claimGroupConfirmation subsubtitle',
  },

  professionalHomePage: {
    id: 'view.b2b.professional.subtitle.homePage',
    defaultMessage: 'Home',
  },
  professionalIssuesAndReasonsPage: {
    id: 'view.b2b.professional.subtitle.issuesAndReasonsPage',
    defaultMessage: 'Issues and reasons',
  },
  professionalConfirmationPage: {
    id: 'view.b2b.professional.subtitle.confirmationPage',
    defaultMessage: 'Confirmation',
  },
  professionalProductsToReturnPage: {
    id: 'view.b2b.professional.subtitle.productsToReturnPage',
    defaultMessage: 'Products to return',
  },
  professionalSolutionPage: {
    id: 'view.b2b.professional.subtitle.solutionPage',
    defaultMessage: 'Solution',
  },
  professionalHandlingPage: {
    id: 'view.b2b.professional.subtitle.handlingPage',
    defaultMessage: 'Handling',
  },
});
