import {
  SparePartCondition,
  SparePartQuotationLine,
} from '@savgroup-front-common/types';

import { sortBy } from '../../../../../../helpers';
import { useQuotationPendingCustomerValidationContext } from '../../../QuotationPendingCustomerValidation.context';

export const useGetSparePartChoice = ({
  line,
}: {
  line: SparePartQuotationLine;
}) => {
  const { alternativeSpareParts } =
    useQuotationPendingCustomerValidationContext();
  const concernedAlternativeSpareParts = Object.values(
    alternativeSpareParts[line.sparePartId] || {},
  );

  const potentialNewSparePart = concernedAlternativeSpareParts
    .sort(
      sortBy({
        fieldName: 'recommendedSalePriceIncludingTaxes.amount' as any,
        ascending: true,
      }),
    )
    .find((item) => {
      return (
        item.condition === SparePartCondition.NEW &&
        item.sparePartId === line.sparePartId
      );
    });

  const potentialUsedSparePart = concernedAlternativeSpareParts
    .sort(
      sortBy({
        fieldName: 'recommendedSalePriceIncludingTaxes.amount' as any,
        ascending: true,
      }),
    )
    .find((item) => {
      return (
        (item.condition === SparePartCondition.USED ||
          item.condition === SparePartCondition.RECONDITIONED) &&
        item.sparePartId === line.sparePartId
      );
    });

  return {
    potentialNewSparePart,
    potentialUsedSparePart,
  };
};
