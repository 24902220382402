import { fromJS } from 'immutable';

import * as ActionTypes from './actionTypes';

export const initialState = fromJS({
  actions: {
    isLoaded: false,
    items: [],
  },
});

function onLoadFileActionsStart(state) {
  return state.set('actions', initialState.get('actions'));
}

function onLoadFileActionsSuccess(state, payload) {
  return state
    .setIn(['actions', 'isLoaded'], true)
    .setIn(['actions', 'items'], fromJS(payload.value));
}

export default function actionsReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.LOAD_FILE_ACTIONS.STARTED: {
      return onLoadFileActionsStart(state);
    }

    case ActionTypes.LOAD_FILE_ACTIONS.SUCCEEDED: {
      return onLoadFileActionsSuccess(state, action.payload);
    }

    default: {
      return state;
    }
  }
}
