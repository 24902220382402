import { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  SparePartCondition,
  SparePartQuotationLine,
  SparePartSearchSummary,
} from '@savgroup-front-common/types';

import { useQuotationPendingCustomerValidationContext } from '../../QuotationPendingCustomerValidation.context';
import { QuotationPendingCustomerValidationForm } from '../../QuotationPendingCustomerValidation.types';

import { useGetSparePartChoice } from './hooks/useGetSparePartChoice';

interface UseConditionSelectionArgs {
  sparePartQuotationLine: SparePartQuotationLine;
  onConditionChange: ({
    values,
    alternativeSpareParts,
  }: {
    values: QuotationPendingCustomerValidationForm;
    alternativeSpareParts: Record<
      string,
      Record<string, SparePartSearchSummary>
    >;
  }) => Promise<{ failure: boolean }>;
}

export const useConditionSelection = ({
  sparePartQuotationLine,
  onConditionChange,
}: UseConditionSelectionArgs) => {
  const { alternativeSpareParts } =
    useQuotationPendingCustomerValidationContext();

  const { potentialUsedSparePart, potentialNewSparePart } =
    useGetSparePartChoice({
      line: sparePartQuotationLine,
    });

  const formContext = useFormContext<QuotationPendingCustomerValidationForm>();
  const { getValues, setValue, watch } = formContext;

  const condition = watch(
    `sparePartLines.${sparePartQuotationLine.id}.condition`,
  );

  const isUsedSparePartAvailable = !!potentialUsedSparePart;
  const isNewSparePartAvailable = !!potentialNewSparePart;

  useEffect(() => {
    setValue(
      `sparePartLines.${sparePartQuotationLine.id}.condition`,
      sparePartQuotationLine.condition,
      { shouldDirty: true },
    );
    setValue(
      `sparePartLines.${sparePartQuotationLine.id}.sparePartSupplierId`,
      sparePartQuotationLine.sparePartSupplierId,
      { shouldDirty: true },
    );
    setValue(
      `sparePartLines.${sparePartQuotationLine.id}.stockName`,
      sparePartQuotationLine.stockName,
      { shouldDirty: true },
    );
  }, [
    setValue,
    sparePartQuotationLine.condition,
    sparePartQuotationLine.id,
    sparePartQuotationLine.sparePartSupplierId,
    sparePartQuotationLine.stockName,
  ]);

  useEffect(() => {
    if (
      !isUsedSparePartAvailable &&
      (condition === SparePartCondition.USED ||
        condition === SparePartCondition.RECONDITIONED)
    ) {
      setValue(
        `sparePartLines.${sparePartQuotationLine.id}.condition`,
        undefined,
        { shouldDirty: true },
      );
      setValue(
        `sparePartLines.${sparePartQuotationLine.id}.sparePartSupplierId`,
        undefined,
        { shouldDirty: true },
      );
      setValue(
        `sparePartLines.${sparePartQuotationLine.id}.stockName`,
        undefined,
        { shouldDirty: true },
      );
    }
    if (!isNewSparePartAvailable && condition === SparePartCondition.NEW) {
      setValue(
        `sparePartLines.${sparePartQuotationLine.id}.condition`,
        undefined,
        { shouldDirty: true },
      );
      setValue(
        `sparePartLines.${sparePartQuotationLine.id}.sparePartSupplierId`,
        undefined,
        { shouldDirty: true },
      );
      setValue(
        `sparePartLines.${sparePartQuotationLine.id}.stockName`,
        undefined,
        { shouldDirty: true },
      );
    }
  }, [
    isUsedSparePartAvailable,
    isNewSparePartAvailable,
    setValue,
    sparePartQuotationLine.id,
    sparePartQuotationLine.condition,
    condition,
  ]);

  const handleSetUsedSparePart = useCallback(async () => {
    if (potentialUsedSparePart) {
      const oldValue = getValues(`sparePartLines.${sparePartQuotationLine.id}`);

      setValue(
        `sparePartLines.${sparePartQuotationLine.id}.condition`,
        potentialUsedSparePart.condition,
        { shouldDirty: true },
      );
      setValue(
        `sparePartLines.${sparePartQuotationLine.id}.sparePartSupplierId`,
        potentialUsedSparePart.sparePartSupplierId,
        { shouldDirty: true },
      );
      setValue(
        `sparePartLines.${sparePartQuotationLine.id}.stockName`,
        potentialUsedSparePart.stockName,
        { shouldDirty: true },
      );

      const values = getValues();

      const response = await onConditionChange({
        values,
        alternativeSpareParts,
      });

      if (response.failure) {
        setValue(
          `sparePartLines.${sparePartQuotationLine.id}.condition`,
          oldValue.condition,
          { shouldDirty: true },
        );
        setValue(
          `sparePartLines.${sparePartQuotationLine.id}.sparePartSupplierId`,
          oldValue.sparePartSupplierId,
          { shouldDirty: true },
        );
        setValue(
          `sparePartLines.${sparePartQuotationLine.id}.stockName`,
          oldValue.stockName,
          { shouldDirty: true },
        );
      }
    }
  }, [
    alternativeSpareParts,
    getValues,
    onConditionChange,
    potentialUsedSparePart,
    setValue,
    sparePartQuotationLine.id,
  ]);

  const handleSetNewSparePart = useCallback(async () => {
    if (potentialNewSparePart) {
      const oldValue = getValues(`sparePartLines.${sparePartQuotationLine.id}`);

      setValue(
        `sparePartLines.${sparePartQuotationLine.id}.condition`,
        potentialNewSparePart.condition,
        { shouldDirty: true },
      );
      setValue(
        `sparePartLines.${sparePartQuotationLine.id}.sparePartSupplierId`,
        potentialNewSparePart.sparePartSupplierId,
        { shouldDirty: true },
      );
      setValue(
        `sparePartLines.${sparePartQuotationLine.id}.stockName`,
        potentialNewSparePart.stockName,
        { shouldDirty: true },
      );

      const values = getValues();

      const response = await onConditionChange({
        values,
        alternativeSpareParts,
      });

      if (response.failure) {
        setValue(
          `sparePartLines.${sparePartQuotationLine.id}.condition`,
          oldValue.condition,
          { shouldDirty: true },
        );
        setValue(
          `sparePartLines.${sparePartQuotationLine.id}.sparePartSupplierId`,
          oldValue.sparePartSupplierId,
          { shouldDirty: true },
        );
        setValue(
          `sparePartLines.${sparePartQuotationLine.id}.stockName`,
          oldValue.stockName,
          { shouldDirty: true },
        );
      }
    }
  }, [
    alternativeSpareParts,
    getValues,
    onConditionChange,
    potentialNewSparePart,
    setValue,
    sparePartQuotationLine.id,
  ]);

  return {
    formContext,
    handleSetUsedSparePart,
    handleSetNewSparePart,
    potentialNewSparePart,
    isNewSparePartAvailable,
    isUsedSparePartAvailable,
    potentialUsedSparePart,
  };
};
