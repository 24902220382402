import React, { FC, useState } from 'react';
import useClaimGroupSolutionPage from '../ClaimGroupSolutionPage.hooks';
import { buildProfessionalRoute } from 'myaccount/view/app/NewLayout/ProfessionalRoutes.helpers';
import {
  PROFESSIONAL_STEP_CONFIG,
  PROFESSIONAL_STEP_PROGRESS,
} from 'myaccount/view/app/NewLayout/ProfessionalRoutes.config';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import StepLayoutProfessional from '../../../../app/NewLayout/StepLayout/StepLayoutProfessional';
import {
  $Heading,
  $HeadingIcon,
  $LeftColumn,
  $SolutionItemWrapper,
  $SolutionResultWrapper,
  $SolutionWrapper,
} from '../ClaimGroupSolutionPage.styles';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';
import { ICONS_TYPE } from '@savgroup-front-common/types';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import messages from '../messages';
import { SolutionItem } from '../SolutionItem';
import { useHistory, useParams } from 'react-router-dom';
import { useGetClaimGroupConfirmation } from '../../../../app/hooks/useGetClaimGroupConfirmation';
import { ResumeCard } from '../../components/ResumeCard';
import { FormProvider } from 'react-hook-form';
import { useGetSolutionsByClaim } from '../../../../app/hooks/useGetSolutionsByClaim';
import { Modal } from '@savgroup-front-common/core/src/atoms/modal';

export const ClaimGroupSolution: FC = () => {
  const history = useHistory();
  const { claimGroupId } = useParams<{ claimGroupId: string }>();

  const [openResumeModal, setOpenResumeModal] = useState(false);
  const { onSubmit, formContext } = useClaimGroupSolutionPage();
  const { solutions, isSolutionsLoading } = useGetSolutionsByClaim({
    claimGroupId,
  });

  const { claimGroupConfirmation, isLoading } = useGetClaimGroupConfirmation({
    claimGroupId,
  });

  const handleOpenModal = () => setOpenResumeModal((prev) => !prev);

  const handlePreviousStep = () => {
    if (claimGroupConfirmation?.orderReference) {
      history.push(
        buildProfessionalRoute({
          route: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_PRODUCTS_TO_RETURN_PAGE,
          payload: { orderReference: claimGroupConfirmation.orderReference },
        }),
      );
    }
  };

  if (isLoading || isSolutionsLoading) {
    return <BaseLoader />;
  }

  return (
    <>
      <FormProvider {...formContext}>
        <form
          onSubmit={(event) => {
            event.preventDefault();

            return undefined;
          }}
        >
          <StepLayoutProfessional
            stepName={PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_SOLUTION_PAGE}
            customSteps={PROFESSIONAL_STEP_PROGRESS}
            handlePreviousStep={handlePreviousStep}
            isPreviousButtonDisabled={!claimGroupConfirmation?.orderReference}
            noConfirmButton
          >
            <$SolutionWrapper>
              <$LeftColumn>
                <$Heading>
                  <$HeadingIcon>
                    <MyAccountIcon icon={ICONS_TYPE.LIGHT_BULB_ON_ICON} />
                  </$HeadingIcon>
                  <SafeFormattedMessageWithoutSpread
                    message={messages.solutionTitle}
                  />
                </$Heading>
                <$SolutionItemWrapper>
                  {solutions?.map((solution) => (
                    <SolutionItem
                      key={solution.key}
                      solution={solution}
                      onClick={async () => {
                        await onSubmit({
                          chooseSolution: solution.solutionTypeId,
                        });
                      }}
                    />
                  ))}
                </$SolutionItemWrapper>
              </$LeftColumn>

              <$SolutionResultWrapper>
                <ResumeCard />
              </$SolutionResultWrapper>
            </$SolutionWrapper>
          </StepLayoutProfessional>
        </form>
      </FormProvider>
      <Modal
        isOpen={openResumeModal}
        onClose={handleOpenModal}
        shouldShowPadding={false}
      >
        <ResumeCard />
      </Modal>
    </>
  );
};

ClaimGroupSolution.displayName = 'ClaimGroupSolution';
