import { Download } from 'iconoir-react';
import React, { FunctionComponent } from 'react';

import { IconPropsInterface } from './Icon.types';

const DownloadIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => <Download width={size} height={size} color={color} />;

DownloadIcon.displayName = 'DownloadIcon';

export default DownloadIcon;
