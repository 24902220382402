import { darken, transparentize } from 'polished';
import styled from 'styled-components';

import { media, Z_INDEX_MAX } from '@savgroup-front-common/constants';
import { ButtonLink } from '@savgroup-front-common/core/src/atoms/button';
import { ButtonLinkProps } from '@savgroup-front-common/core/src/atoms/button/ButtonLink/ButtonLink';
import { Link } from '@savgroup-front-common/core/src/atoms/link';
import { getCustomComponentProperty } from '@savgroup-front-common/core/src/helpers';
import {
  SUPPORTED_COMPONENTS,
  SUPPORTED_PROPERTIES,
} from '@savgroup-front-common/types';

export const $BanneredLayout = styled.div<{
  $isNeutralBackground?: boolean;
}>`
  padding-bottom: 1rem;
  background-color: ${({ theme, $isNeutralBackground }) =>
    $isNeutralBackground ? theme.colors.neutralBackground : undefined};
  min-height: ${({ $isNeutralBackground }) =>
    $isNeutralBackground ? 'calc(100vh - 64px)' : undefined};
`;

export const $Bannered = styled.div<{
  $hasBannerUrl?: boolean;
  $bannerUrl?: string;
}>`
  padding: 1.75rem 1rem
    ${({ $hasBannerUrl }) => ($hasBannerUrl ? '9rem' : '4.5rem')};
  background-color: ${({ theme }) =>
    transparentize(0.95, theme.colors.primary)};
  min-height: 200px;
  @media ${media.maxWidth.xs} {
    min-height: 100px;
    padding: ${({ $hasBannerUrl }) => ($hasBannerUrl ? '3rem' : undefined)};
  }

  ${({ $hasBannerUrl, $bannerUrl, theme }) =>
    $hasBannerUrl
      ? `
    background-color: ${theme.colors.primary};
    background-image: url(${$bannerUrl});
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: center;
    `
      : undefined}
`;

export const $BreadcrumbWrapper = styled.div`
  margin-bottom: 1rem;
  display: flex;
`;

export const $Breadcrumb = styled(ButtonLink)<
  { active?: boolean } & ButtonLinkProps
>`
  background-color: ${({ theme, active }) =>
    active ? transparentize(0.95, theme.colors.primary) : theme.colors.primary};
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.25rem 1rem 0.25rem;
  color: ${({ theme, active }) =>
    active ? theme.colors.primary : theme.colors.white};
  font-size: 10px;
  line-height: ${({ theme }) => theme.fonts.size.small};
  height: 24px;
  overflow: visible;
  border-radius: 0;
  margin-left: 1.25rem;
  transition: none;
  cursor: ${({ active }) => (active ? 'default' : 'pointer')};
  text-decoration: none;

  &:active,
  &:focus,
  &:visited {
    background-color: ${({ theme, active }) =>
      active
        ? transparentize(0.95, theme.colors.primary)
        : theme.colors.primary};
    color: ${({ theme, active }) =>
      active ? theme.colors.primary : theme.colors.white};
  }

  &:before {
    content: '';
    border: 12px solid
      ${({ theme, active }) =>
        active
          ? transparentize(0.95, theme.colors.primary)
          : theme.colors.primary};
    border-left: 1rem solid transparent;
    display: block;
    border-right: none;
    height: 0;
    margin-left: 0.5rem;
    position: absolute;
    left: -22px;
    transition: none;
  }

  &:after {
    content: '';
    border: 12px solid transparent;
    border-left: 1rem solid
      ${({ theme, active }) =>
        active
          ? transparentize(0.95, theme.colors.primary)
          : theme.colors.primary};
    display: block;
    border-right: none;
    height: 0;
    margin-left: 0.5rem;
    position: absolute;
    right: -15px;
    transition: none;
  }

  &:first-child {
    margin-left: 0;
    padding: 0.25rem 1rem;
    border-top-left-radius: ${({ theme }) => theme.borders.radius};
    border-bottom-left-radius: ${({ theme }) => theme.borders.radius};
    &:before {
      content: none;
    }
  }

  &&:hover {
    ${({ theme, active }) =>
      active
        ? `
        background: ${transparentize(0.95, theme.colors.primary)};
        color: ${theme.colors.primary};
      `
        : `color: ${theme.colors.white};
      background: ${darken(0.05, theme.colors.primary)};
      &:before {
        border: 12px solid ${darken(0.05, theme.colors.primary)};
        border-left: 1rem solid transparent;
      }
      &:after {
        border-left: 1rem solid ${darken(0.05, theme.colors.primary)};
      }
      `}
  }
`;

export const $HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const $HeadingTitle = styled.div`
  display: block;
`;

export const $HeadingAction = styled.div`
  display: flex;
  @media ${media.maxWidth.xs} {
    position: fixed;
    width: 100%;
    left: 0px;
    bottom: 0px;
    background: white;
    display: block;
    z-index: ${Z_INDEX_MAX};
    padding: 1rem;
    button {
      width: 100%;
      padding-top: 1rem;
      padding-bottom: 1rem;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.06);
      span {
        font-size: ${({ theme }) => theme.fonts.size.medium};
      }
    }
  }
`;

export const $ActionButton = styled(ButtonLink)<{
  color?: string;
  backgroundColor?: string;
}>`
  color: ${({ theme, color }) => color || theme.colors.white};
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor || theme.colors.primary};
  font-size: ${({ theme }) => theme.fonts.size.ultraSmall};
  line-height: ${({ theme }) => theme.fonts.size.small};
  min-width: 0;
  width: fit-content;
  height: fit-content;
  padding: ${({ theme }) =>
    getCustomComponentProperty({
      theme,
      componentName: SUPPORTED_COMPONENTS.SMALL_DEFAULT_BUTTONS,
      property: SUPPORTED_PROPERTIES.PADDING,
    })};

  &:active,
  &:focus,
  &:visited {
    background-color: ${({ theme, backgroundColor }) =>
      backgroundColor || theme.colors.primary};
    color: ${({ theme, color }) => color || theme.colors.white};
  }

  && svg {
    fill: ${({ theme, color }) => color || theme.colors.white};
    margin-right: 0.5rem;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => darken(0.05, theme.colors.primary)};

    & svg {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const $Link = styled(Link)`
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;

export const $Title = styled.div<{ $hasBannerUrl?: boolean }>`
  margin-top: 2rem;
  color: ${({ theme, $hasBannerUrl }) =>
    $hasBannerUrl ? 'white' : theme.systemColors.defaultTextColor};
  font-size: ${({ theme }) => theme.fonts.size.heading.h1};
  font-weight: 800;
  margin-bottom: 0.75rem;

  @media ${media.maxWidth.xs} {
    display: ${({ $hasBannerUrl }) => ($hasBannerUrl ? 'none' : undefined)};
  }
  @media ${media.maxWidth.sm} {
    margin-top: 1rem;
    font-size: ${({ theme }) => theme.fonts.size.large};
  }
`;

export const $Description = styled.div<{ $hasBannerUrl?: boolean }>`
  color: ${({ $hasBannerUrl }) => ($hasBannerUrl ? 'white' : '#8a94a6')};
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  font-size: ${({ theme }) => theme.fonts.size.heading.h6};
  margin-bottom: 0.25rem;
  @media ${media.maxWidth.sm} {
    margin-bottom: unset;
  }

  @media ${media.maxWidth.xs} {
    display: ${({ $hasBannerUrl }) => ($hasBannerUrl ? 'none' : undefined)};
  }
`;

export const $ContentWrapper = styled.div``;

export const $Content = styled.div<{
  $hasBannerUrl?: boolean;
  $enableServicePortal?: boolean;
}>`
  margin-top: ${({ $hasBannerUrl, $enableServicePortal }) =>
    $hasBannerUrl && $enableServicePortal ? '-6rem' : '-3rem'};
  box-shadow: ${({ $hasBannerUrl }) =>
    $hasBannerUrl ? '0 0 15px 0px rgba(0,0,0,.05)' : undefined};

  @media ${media.maxWidth.md} {
    padding-left: 10px;
    padding-right: 10px;
  }

  @media ${media.maxWidth.xs} {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: ${({ $hasBannerUrl, $enableServicePortal }) =>
      $hasBannerUrl && $enableServicePortal ? '-1rem' : undefined};
  }
`;
