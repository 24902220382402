import { UserManager } from 'oidc-client';
import React, { createContext, FunctionComponent, useContext } from 'react';
import { Store } from 'redux';
import {
  OidcProvider as ReduxOidcProvider,
  OidcProviderProps as ReduxOidcProviderProps,
} from 'redux-oidc';

import { MyAccountRootState } from 'myaccount/domains/MyAccountRootState';

function CustomReduxOidcProvider<T>(
  props: React.PropsWithChildren<ReduxOidcProviderProps<T>>,
) {
  return <ReduxOidcProvider {...props} />;
}
CustomReduxOidcProvider.displayName = 'CustomReduxOidcProvider';

interface OidcProviderProps {
  store: Store<MyAccountRootState>;
  userManager: { current: UserManager };
}

const OidcContext = createContext<{
  userManager: { current: UserManager };
}>({
  userManager: {
    current: new UserManager({}),
  },
});

export const useOidcContext = () => {
  return useContext(OidcContext);
};

const OidcProvider: FunctionComponent<
  React.PropsWithChildren<OidcProviderProps>
> = ({ store, children, userManager }) => {
  return (
    <CustomReduxOidcProvider store={store} userManager={userManager.current}>
      <OidcContext.Provider value={{ userManager }}>
        {children}
      </OidcContext.Provider>
    </CustomReduxOidcProvider>
  );
};

OidcProvider.displayName = 'OidcProvider';

export default OidcProvider;
