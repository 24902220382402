import md5 from 'md5';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col } from 'react-styled-flexboxgrid';

import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { formatReverseMoneyToString } from '@savgroup-front-common/core/src/formatters/intl';
import { RawQuotationDiscountEnriched } from '@savgroup-front-common/types';

import messages from '../messages';
import {
  $InlinePriceCol,
  $QuoteItemSummaryRow,
} from '../QuotationValidationSummary.styles';

interface DiscountsSummaryLineProps {
  discounts?: RawQuotationDiscountEnriched[];
}

const DiscountsSummaryLine: FunctionComponent<
  React.PropsWithChildren<DiscountsSummaryLineProps>
> = ({ discounts }) => {
  if (!discounts) {
    return <></>;
  }

  return (
    <>
      {discounts.map(({ description, quantity, amountIncludedTax }) => {
        return (
          <$QuoteItemSummaryRow
            key={md5(formatReverseMoneyToString(amountIncludedTax))}
            between="xs"
          >
            <Col xs={7} sm={8}>
              <SafeFormattedMessageWithoutSpread message={messages.discount} />{' '}
              - {quantity > 1 ? `${quantity}x` : null}
              {description}
            </Col>
            <$InlinePriceCol xs={5} sm={4}>
              {formatReverseMoneyToString(amountIncludedTax)}
              <FormattedMessage {...messages.vatIncluded} />
            </$InlinePriceCol>
          </$QuoteItemSummaryRow>
        );
      })}
    </>
  );
};

DiscountsSummaryLine.displayName = 'DiscountsSummaryLine';

export default DiscountsSummaryLine;
