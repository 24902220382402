import { configureStore } from '@reduxjs/toolkit';
import { createReduxHistoryContext } from 'redux-first-history';
import logger from 'redux-logger';
import createOidcMiddleware from 'redux-oidc';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';

import { history } from '@savgroup-front-common/configuration';
import {
  APP_ENVS,
  currentAppEnvironment,
} from '@savgroup-front-common/constants/src/shared';
import rootReducer from 'myaccount/domains/rootReducer';
import rootSaga from 'myaccount/domains/rootSaga';

import { loadState } from './localStorage';

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history,
  });

export default function configureStoreAndHistory({ userManager }) {
  const oidcMiddleware = createOidcMiddleware(userManager.current);
  const sagaMiddleware = createSagaMiddleware();
  const defaultMiddleware = [
    oidcMiddleware,
    sagaMiddleware,
    routerMiddleware,
    thunk,
  ];

  const middlewares =
    currentAppEnvironment === APP_ENVS.PROD ||
    import.meta.env.VITE_ENABLE_REDUX_LOGGER === 'false'
      ? defaultMiddleware
      : [...defaultMiddleware, logger];

  const persistedStore = loadState();

  const store = configureStore({
    reducer: rootReducer(routerReducer),
    middleware: () => middlewares,
    devTools: currentAppEnvironment !== APP_ENVS.PROD,
    preloadedState: persistedStore,
  });

  sagaMiddleware.run(rootSaga);

  return { store, history: createReduxHistory(store) };
}
