import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col } from 'react-styled-flexboxgrid';

import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { formatReverseMoneyToString } from '@savgroup-front-common/core/src/formatters/intl';
import { RawQuotationSparePartEnriched } from '@savgroup-front-common/types';

import messages from '../messages';
import {
  $InlinePriceCol,
  $QuoteItemSummaryRow,
} from '../QuotationValidationSummary.styles';

interface SparePartsSummaryLineProps {
  spareParts?: RawQuotationSparePartEnriched[];
}

const SparePartsSummaryLine: FunctionComponent<
  React.PropsWithChildren<SparePartsSummaryLineProps>
> = ({ spareParts }) => {
  if (!spareParts) {
    return <></>;
  }

  return (
    <>
      {spareParts.map(
        ({ sparePartId, description, quantity, amountIncludedTax }) => {
          return (
            <$QuoteItemSummaryRow
              key={`${sparePartId}${amountIncludedTax.amount}`}
              between="xs"
            >
              <Col xs={7} sm={8}>
                <SafeFormattedMessageWithoutSpread
                  message={messages.spareParts}
                />{' '}
                - {quantity > 1 ? `${quantity}x` : null}
                {description}
              </Col>
              <$InlinePriceCol xs={5} sm={4}>
                {formatReverseMoneyToString(amountIncludedTax)}
                <FormattedMessage {...messages.vatIncluded} />
              </$InlinePriceCol>
            </$QuoteItemSummaryRow>
          );
        },
      )}
    </>
  );
};

SparePartsSummaryLine.displayName = 'SparePartsSummaryLine';

export default SparePartsSummaryLine;
