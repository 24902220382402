import React from 'react';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';
import { MessageType } from '@savgroup-front-common/types';
import { ICONS_TYPE } from '@savgroup-front-common/types/src/Icon';

import {
  $SimpleCardAddressName,
  $SimpleCardAddressNameContainer,
  $SimpleCardContainer,
  $SimpleCardDetail,
  $SimpleCardDidactic,
  $SimpleCardIcon,
  $SimpleCardInfoContainer,
  $SimpleCardLink,
  $SimpleCardPrefixTitle,
  $SimpleCardTitle,
} from './SimpleItemCard.styles';
import { CURRENCIES } from '@savgroup-front-common/constants';
import { formatAmountWithCurrency } from '@savgroup-front-common/core/src/formatters/intl';
import messages from './messages';

interface SimpleItemCardProps {
  icon?: ICONS_TYPE;
  iconColor?: string;
  prefixTitle: MessageType;
  title?: string | MessageType;
  didactic?: string | MessageType;
  address?: {
    addressName?: string;
    addressStreet: string;
  };
  link?: string | MessageType;
  linkUrl?: string;
  price?: {
    priceValue?: number;
    priceCurrencyCode?: CURRENCIES;
  };
  hasDisplayFreeAmount?: boolean;
}

const SimpleItemCard: React.FC<SimpleItemCardProps> = ({
  icon,
  iconColor,
  prefixTitle,
  title,
  didactic,
  address,
  link,
  linkUrl,
  price,
  hasDisplayFreeAmount = true,
}) => {
  return (
    <$SimpleCardContainer>
      <$SimpleCardIcon>
        <MyAccountIcon icon={icon} color={iconColor} />
      </$SimpleCardIcon>
      <div style={{ flex: 1 }}>
        <$SimpleCardInfoContainer>
          <div>
            {prefixTitle && (
              <$SimpleCardPrefixTitle>
                <SafeFormattedMessageWithoutSpread message={prefixTitle} />
              </$SimpleCardPrefixTitle>
            )}
            {title && (
              <$SimpleCardTitle>
                <SafeFormattedMessageWithoutSpread message={title} />
              </$SimpleCardTitle>
            )}
            {didactic && (
              <$SimpleCardDidactic>
                <SafeFormattedMessageWithoutSpread message={didactic} />
              </$SimpleCardDidactic>
            )}
          </div>
          {!!price?.priceValue && (
            <$SimpleCardDetail>
              {formatAmountWithCurrency(
                price?.priceValue,
                price.priceCurrencyCode || CURRENCIES.EUR,
              )?.toString()}
            </$SimpleCardDetail>
          )}
          {!price?.priceValue && hasDisplayFreeAmount && (
            <$SimpleCardDetail>
              <SafeFormattedMessageWithoutSpread message={messages.free} />
            </$SimpleCardDetail>
          )}
        </$SimpleCardInfoContainer>

        {address && (
          <$SimpleCardAddressNameContainer>
            {address.addressName && (
              <$SimpleCardAddressName>
                <span>{address.addressName}</span>
              </$SimpleCardAddressName>
            )}
            <span>{address.addressStreet}</span>
          </$SimpleCardAddressNameContainer>
        )}
        {link && (
          <$SimpleCardLink href={linkUrl}>
            <SafeFormattedMessageWithoutSpread message={link} />
          </$SimpleCardLink>
        )}
      </div>
    </$SimpleCardContainer>
  );
};

SimpleItemCard.displayName = 'SimpleItemCard';

export default SimpleItemCard;
