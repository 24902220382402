import styled from 'styled-components';

import { mediaQuery } from '@savgroup-front-common/core/src/grid/helpers';

export const $Heading = styled.div`
  font-weight: 800;
  font-size: 16px;
  line-height: 23px;
  ${mediaQuery.sm`
    font-size: 20px;
  `}

  color: ${({ theme }) => theme.systemColors.defaultTextColor};
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
`;

export const $HeadingIcon = styled.span`
  margin-right: 1rem;
  display: flex;
  align-items: baseline;
  & svg {
    width: 24px;
    height: 24px;
  }
`;
