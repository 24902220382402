import {
  FULL_STANDARD_DATE_TIME_FORMAT_DATE_FNS,
  STANDARD_DATE_FORMAT_DATE_FNS,
  STANDARD_DATE_TIME_FORMAT_DATE_FNS,
} from '@savgroup-front-common/constants';
import {
  QuotationNewModelDto,
  RecipientType,
} from '@savgroup-front-common/types';

import { tryToParse } from '../../../helpers';
import { AdaptedQuotationNewModel } from '../types/AdaptedQuotationNewModel';

import { adaptSparePartLine } from './adaptSparePartLine';

export const adaptQuotationNewModelDto = (
  quotationDtos: QuotationNewModelDto[],
): AdaptedQuotationNewModel[] => {
  return quotationDtos.map((quotationDto) => {
    const response = tryToParse({
      value: quotationDto.createdDate,
      patterns: [
        STANDARD_DATE_FORMAT_DATE_FNS,
        STANDARD_DATE_TIME_FORMAT_DATE_FNS,
        FULL_STANDARD_DATE_TIME_FORMAT_DATE_FNS,
      ],
    });

    if (response.failure) {
      throw new Error('Failed to parse quotation creation date.');
    }

    const {
      spareParts,
      id,
      unknownSpareParts,
      recipient,
      administrativeFees,
      unknownServices,
      services,
      author,
      fileId,
      issuer,
      destructionFee,
      returnFee,
      subcontractorQuoteNumber,
      preTaxTotalAmount,
      totalAmountIncludedTax,
      totalDiscountIncludedTax,
      discounts,
      totalAmountToBePaid,
      totalTax,
      state,
      sellerId,
      description,
      alreadyPaidAmountOnApproval,
      totalAmountToBePaidByClient,
      isBonusRemoved,
      bonus,
    } = quotationDto;

    return {
      id,
      recipient,
      unknownSpareParts: (unknownSpareParts || []).map((unknownSparePart) => {
        return {
          ...unknownSparePart,
          recipient: unknownSparePart.recipient
            ? unknownSparePart.recipient
            : { recipientType: RecipientType.OWNER },
        };
      }),
      administrativeFees: (administrativeFees || []).map(
        (administrativeFee) => {
          return {
            ...administrativeFee,
            recipient: administrativeFee.recipient
              ? administrativeFee.recipient
              : { recipientType: RecipientType.OWNER },
          };
        },
      ),
      unknownServices: (unknownServices || []).map((unknownService) => {
        return {
          ...unknownService,
          recipient: unknownService.recipient
            ? unknownService.recipient
            : { recipientType: RecipientType.OWNER },
        };
      }),
      services: (services || []).map((service) => {
        return {
          ...service,
          recipient: service.recipient
            ? service.recipient
            : { recipientType: RecipientType.OWNER },
        };
      }),
      sparePartLines: (spareParts || []).map((sparePart) => {
        return adaptSparePartLine({ sparePart });
      }),
      author,
      fileId,
      issuer,
      destructionFee,
      returnFee,
      subcontractorQuoteNumber,
      preTaxTotalAmount,
      totalAmountIncludedTax,
      totalDiscountIncludedTax,
      discounts,
      totalAmountToBePaid,
      totalAmountToBePaidByClient,
      totalTax,
      state,
      sellerId,
      description,
      createdDate: response.value,
      alreadyPaidAmountOnApproval,
      bonus,
      isBonusRemoved,
    };
  });
};
