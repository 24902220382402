import * as Yup from 'yup';

import { AdditionalInformationSchema } from '@savgroup-front-common/core/src/components/AdditionalInformationWithReactHookForm';
import { AdditionalInformationExtended } from 'myaccount/types';

import { Claim } from '../../app/NewLayout/StepsProvider/StepsProvider.types';

const ClaimGroupProductInfoPageSchema = ({
  claims,
  neededInformationOnlyRelatedToProduct,
}: {
  claims: Claim[];
  neededInformationOnlyRelatedToProduct: AdditionalInformationExtended[];
}) => {
  const schema = claims?.reduce((acc, curr) => {
    if (curr) {
      const neededInformationOnlyRelatedToProductFiltered =
        neededInformationOnlyRelatedToProduct.filter(
          (item) =>
            item.currentClaimId === curr.claimId &&
            item.claimIds.includes(curr.claimId),
        );

      const reasonAdditionalInformationSchema = AdditionalInformationSchema({
        neededInformationList: neededInformationOnlyRelatedToProductFiltered,
      });

      return {
        ...acc,
        [curr.claimId]: Yup.object().shape({
          reasonAdditionalInformationProductInfo:
            reasonAdditionalInformationSchema,
        }),
      };
    }

    return acc;
  }, {});

  return Yup.object().shape({
    ...schema,
  });
};

export default ClaimGroupProductInfoPageSchema;
