import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  FILE_STATES,
  NOTIFICATION_TYPES,
} from '@savgroup-front-common/constants';
import { Banner } from '@savgroup-front-common/core/src/atoms/Banners';
import {
  RadioButton,
  RadioButtonGroup,
} from '@savgroup-front-common/core/src/atoms/Form';
import { Heading } from '@savgroup-front-common/core/src/atoms/Heading';
import { Paragraph } from '@savgroup-front-common/core/src/atoms/Paragraph';
import { SafeFormattedMessage } from '@savgroup-front-common/core/src/formatters';

import transitionFormMessages from '../../../../../components/DevicePasswordForm/messages';

import messages from './messages';
import {
  $ReturnProductFormContainer,
  $SubmitButton,
  $SubmitRow,
} from './ReturnProductForm.styles';

const warningOptions = [
  FILE_STATES.REQUESTED_RETURN_QUOTE_REJECTED,
  FILE_STATES.REQUEST_TO_RETURN_IN_THE_STATE,
];

const FIELDS = {
  SELECTED_TRANSITION: 'selectedTransition',
};

const ReturnProductForm = ({
  actions,
  onSubmit,
  isModal,
  closeModal,
  isStateUpdatePending,
}) => {
  const [selectedTransition, setSelectedTransition] = useState(null);

  const hasTransitions = actions.some(
    ({ transitions = [] }) => transitions.length > 0,
  );

  return (
    <$ReturnProductFormContainer>
      <Heading level={2}>
        <FormattedMessage {...messages.header} />
      </Heading>
      <Banner
        isOpen={!hasTransitions}
        notificationType={NOTIFICATION_TYPES.ERROR}
      >
        <SafeFormattedMessage
          id={messages.noOptions.id}
          defaultMessage={messages.noOptions.defaultMessage}
        />
      </Banner>
      <RadioButtonGroup name={FIELDS.SELECTED_TRANSITION}>
        {actions.map((action) => {
          const { transitions = [] } = action || {};

          return transitions.map((transition) => {
            const { label, targetState } = transition;

            const checked =
              targetState.name ===
              get(selectedTransition, ['targetState', 'name']);

            return (
              <RadioButton
                key={label}
                name={FIELDS.SELECTED_TRANSITION}
                disabled={isStateUpdatePending}
                label={label}
                checked={checked}
                onClick={() => {
                  setSelectedTransition({ ...transition, ...action });
                }}
              />
            );
          });
        })}
      </RadioButtonGroup>

      {warningOptions.includes(selectedTransition) && (
        <Paragraph danger>
          <FormattedMessage {...messages.warning} />
        </Paragraph>
      )}

      <$SubmitRow>
        <$SubmitButton
          primary
          disabled={!selectedTransition || isStateUpdatePending}
          onClick={() => {
            onSubmit({
              selectedTransition,
            });
            if (isModal && closeModal) {
              closeModal();
            }
          }}
          isLoading={isStateUpdatePending}
        >
          <FormattedMessage {...transitionFormMessages.submit} />
        </$SubmitButton>
      </$SubmitRow>
    </$ReturnProductFormContainer>
  );
};

ReturnProductForm.propTypes = {
  isModal: PropTypes.bool,
  closeModal: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape({})),
  isStateUpdatePending: PropTypes.bool,
};

ReturnProductForm.defaultProps = {
  isModal: false,
  closeModal: null,
  actions: [],
  isStateUpdatePending: false,
};

ReturnProductForm.displayName = 'ReturnProductForm';

export default ReturnProductForm;
