import defaultComponentDecorator from './defaultComponentDecorator';

export default (decoratedHref, decoratedText, key, incomingProps) => {
  const props = {
    target: '_blank',
    rel: 'noopener noreferrer',
    ...incomingProps,
  };

  return defaultComponentDecorator(decoratedHref, decoratedText, key, props);
};
