import { useParams } from 'react-router-dom';

import { safeFormattedIntlString } from '@savgroup-front-common/core/src/formatters';
import { useGetFullClaimContextQuery } from 'myaccount/view/app/hooks/useGetFullClaimContextQuery';
import { useGetModelByIdQuery } from 'myaccount/view/app/hooks/useGetModelByIdQuery';
import { useGetSolutionTypeQuery } from 'myaccount/view/app/hooks/useGetSolutionTypeQuery';

import { BASE_ROUTES, HOME_ROUTES, ROUTES_NEW_DESIGN } from '../../app';
import { buildFileFollowupUrl } from 'myaccount/view/app/NewLayout/ClassiqueLayout.helpers';

import {
  adaptCustomerFileFullSummary,
  adaptHistory,
  adaptProductsFromFileFollowupsPage,
} from './FileFollowupsPage.adapters';
import { useGetCustomerFileFullSummaryQuery } from './hooks/useGetCustomerFileFullSummaryQuery';
import { useGetFileStatesQuery } from 'myaccount/view/app/hooks/Workflow/useGetFileStatesQuery';
import messages from './messages';
import { useLayout } from 'myaccount/view/app/NewLayout/NewLayout.hooks';

export const useGetProductsDetails = () => {
  const { fileId } = useParams<{ fileId: string }>();
  const { fileInfo, isLoading: isLoadingFileSummary } =
    useGetCustomerFileFullSummaryQuery({ fileId });

  const productsInfoAdapted = adaptCustomerFileFullSummary(fileInfo);
  const { models, isLoading: isLoadingModel } = useGetModelByIdQuery({
    productsInfo: productsInfoAdapted,
  });
  const { solution, isLoading: isLoadingSolution } = useGetSolutionTypeQuery({
    solutionTypeId: fileInfo?.solutionId,
  });

  return {
    productsDetails: adaptProductsFromFileFollowupsPage({
      models,
      productsInfoAdapted,
      solution,
    }),
    fileId,
    isLoadingModel,
    isLoadingSolution,
    isLoadingFileSummary,
  };
};

export const useFileFollowupsPage = () => {
  const { fileId } = useParams<{ fileId: string }>();
  const { fileInfo, isLoading: isLoadingFileSummary } =
    useGetCustomerFileFullSummaryQuery({ fileId });

  const { histories, isLoading: isLoadingHistories } = useGetFileStatesQuery({
    fileId,
  });

  const claimId = fileInfo?.claimInfoSummary.claimId;

  const { fullClaim, isLoading: isLoadingFullClaim } =
    useGetFullClaimContextQuery({
      claimId,
    });

  return {
    histories: adaptHistory(histories),
    fullClaim,
    fileInfo,
    isLoadingFileSummary,
    isLoadingHistories,
    isLoadingFullClaim,
  };
};

export const useBreadCrumbs = ({
  isLoading,
  productsDetails,
  fileId,
}: {
  isLoading: boolean;
  productsDetails: ReturnType<typeof adaptProductsFromFileFollowupsPage>;
  fileId: string;
}) => {
  const { enableServicePortal } = useLayout();

  return [
    {
      label: messages.breadcrumbMyRequests,
      route: enableServicePortal
        ? HOME_ROUTES.PRODUCTS
        : `${BASE_ROUTES.NEW_DESIGN}${ROUTES_NEW_DESIGN.PRODUCTS}`,
    },
    {
      label: productsDetails
        ? safeFormattedIntlString(messages.bannerTitle, {
            reference: isLoading ? '...' : productsDetails[0]?.fileReference,
          })
        : messages.bannerTitleUnknown,
      route: buildFileFollowupUrl({ fileId }),
    },
  ];
};
