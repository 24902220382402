import React, { FC } from 'react';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';

import ActionRow from '../../../../../atoms/ActionRow/ActionRow';
import Button from '../../../../../atoms/button/Button';
import Didactic from '../../../../../atoms/Didactic/Didactic';
import FormGroup from '../../../../../atoms/Form/FormGroup';
import Heading from '../../../../../atoms/Heading/Heading';
import { SafeFormattedMessageWithoutSpread } from '../../../../../formatters';
import { useIsSuperAdmin } from '../../../../../hooks';

import messages from './messages';

interface QrCodeImportProps {
  uploadQrCode: string;
  uploadUrl?: string;
  onUploadFinishClick: () => void;
  isSubmitLoading: boolean;
}

export const QrCodeImportModalContent: FC<QrCodeImportProps> = ({
  uploadQrCode,
  uploadUrl,
  onUploadFinishClick,
  isSubmitLoading,
}) => {
  const isSuperAdmin = useIsSuperAdmin();

  return (
    <>
      <Heading level={3}>
        <SafeFormattedMessageWithoutSpread message={messages.title} />
      </Heading>
      <Didactic>
        <SafeFormattedMessageWithoutSpread message={messages.didactic} />
      </Didactic>

      <FormGroup>
        <img alt="qrcode" src={`data:${uploadQrCode}`} />
      </FormGroup>

      {isSuperAdmin && uploadUrl && (
        <FormGroup>
          <a href={uploadUrl} target="_blank" rel="noreferrer">
            {uploadUrl}
          </a>
        </FormGroup>
      )}

      <ActionRow>
        <Button
          success
          onClick={onUploadFinishClick}
          type={BUTTON_TYPES.BUTTON}
          isLoading={isSubmitLoading}
          dataTestId="uploadFinishButton"
        >
          <SafeFormattedMessageWithoutSpread message={messages.uploadFinish} />
        </Button>
      </ActionRow>
    </>
  );
};

QrCodeImportModalContent.displayName = 'QrCodeImportModalContent';
