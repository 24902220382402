import { CommonWorkflowService } from '@savgroup-front-common/core/src/api';
import getTechnicalInterventionQuery from '@savgroup-front-common/core/src/api/Workflow/getTechnicalInterventionQuery';

import cancelTechnicalIntervention from './cancelTechnicalIntervention';
import createTechnicalIntervention from './createTechnicalIntervention';
import getClosedFilesQuery from './getClosedFilesQuery';
import getCustomerFileFullSummaryQuery from './getCustomerFileFullSummaryQuery';
import getFilesByOrderQuery from './getFilesByOrderQuery';
import getFileStatesQuery from './getFileStatesQuery';
import getOpenedFilesQuery from './getOpenedFilesQuery';
import getTechnicalInterventionTimeSlots from './getTechnicalInterventionTimeSlots';

export const WorkflowService = {
  getOpenedFilesQuery,
  getClosedFilesQuery,
  getCustomerFileFullSummaryQuery,
  getFileStatesQuery,
  getFilesByOrderQuery,
  getTechnicalInterventionQuery,
  getTechnicalInterventionTimeSlots,
  createTechnicalIntervention,
  cancelTechnicalIntervention,
  getShortFileInfoQuery: CommonWorkflowService.getShortFileInfoQuery,
};
