import React, { useContext } from 'react';

import { Field } from '../../../molecules/Field';
import { ActorInformationItem } from '../../ActorInformation/components/GenericActorInformation/GenericActorInformation.styles';
import messages from '../../ActorInformation/components/GenericActorInformation/messages';
import { ContentTemplateContext } from '../MDXCustomComponents.context';

const FullNameCurrentActor = () => {
  const { actorSummary } = useContext(ContentTemplateContext);
  const fullName = actorSummary?.fullName;

  return (
    <>
      {fullName && (
        <ActorInformationItem>
          <Field label={messages.fullName} copy={fullName}>
            {fullName}
          </Field>
        </ActorInformationItem>
      )}
    </>
  );
};

FullNameCurrentActor.displayName = 'FullNameCurrentActor';

export default FullNameCurrentActor;
