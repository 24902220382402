import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

 
const ENDPOINT = `${APIConfiguration.claim}claims/${COMMON_QUERY_PARAMS.CLAIM_ID}/issues`;

interface GetIssuesByClaimReturnValues {
  id: string;
  name: string;
  uniqueName: string;
  key: string;
  displayOrder: number;
  didactic: string;
  fromProductWarranty: boolean;
  winningWarrantyTypeId: string;
}

interface GetIssuesByClaimArgs {
  claimId: string;
}

const getIssuesByClaim = async ({
  claimId,
}: GetIssuesByClaimArgs): Promise<
  BackResponse<GetIssuesByClaimReturnValues[]> | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.CLAIM_ID, claimId);

    const response = await apiCall<
      BackResponse<GetIssuesByClaimReturnValues[]>
    >(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default getIssuesByClaim;
