import styled from 'styled-components';

import { mediaQuery } from '@savgroup-front-common/core/src/grid/helpers';

export const $MapContainer = styled('div')`
  width: 100%;
  height: 42vh;
  ${mediaQuery.sm`height: 100%;`};

  .cluster {
    z-index: 50;
  }
`;
