import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';

export const $ProductContainer = styled.div`
  display: flex;
  flex-direction: row;
  background: #f7f7f7;
  border-radius: 8px;
  padding: 16px 16px;
  align-items: center;
  @media ${media.minWidth.sm} {
    padding: 24px 32px;
  }
`;

export const $ProductCardImageContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border: 1px solid #e1e4e8;
  border-radius: 4px;
  margin-right: 16px;
  margin-left: '40px';
  padding: 2px;
  img {
    width: -webkit-fill-available;
    max-width: 70px;
  }
  @media ${media.minWidth.sm} {
    width: 70px;
    height: 70px;
  }
`;

export const $ProductInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  & > span:first-child {
    font-weight: ${({ theme }) => theme.fonts.weight.bold};
  }
`;
