import { useMutation } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { useGetClientInformation } from '../../../../../app/hooks';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { useGetBusinessReturn } from './useGetBusinessReturn';
import { ClaimService } from '../../../../../../api';
import Origin from '@savgroup-front-common/constants/src/shared/origin';
import { useSelector } from 'react-redux';
import { ownerIdSelector } from '../../../../../../domains/Customer/selectors';
import { buildProfessionalRoute } from 'myaccount/view/app/NewLayout/ProfessionalRoutes.helpers';
import { PROFESSIONAL_STEP_CONFIG } from 'myaccount/view/app/NewLayout/ProfessionalRoutes.config';

export const useCreateClaimGroupFromBusinessReturn = () => {
  const { orderReference } = useParams<{ orderReference: string }>();
  const { clientInformation } = useGetClientInformation();
  const ownerId = useSelector(ownerIdSelector);
  const { pushErrors } = useToasts();
  const history = useHistory();
  const { order } = useGetBusinessReturn();

  const {
    mutateAsync: handleCreateClaimGroup,
    isLoading: isCreateClaimGroupLoading,
  } = useMutation(['useCreateClaimGroupFromBusinessReturn'], async () => {
    if (!clientInformation?.sellerId || !order) {
      return undefined;
    }

    const response =
      await ClaimService.createClaimGroupContextFromBusinessReturnCommand({
        sellerId: clientInformation?.sellerId,
        orderReference,
        ownerId,
        origin: Origin.MY_ACCOUNT,
      });

    if (response.failure) {
      pushErrors(response.errors);

      return undefined;
    }

    const claimGroupId = response.value;

    history.push(
      buildProfessionalRoute({
        route: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_SOLUTION_PAGE,
        payload: {
          claimGroupId,
        },
      }),
    );

    return undefined;
  });

  return {
    handleCreateClaimGroup,
    isCreateClaimGroupLoading,
  };
};
