enum HANDLING_GROUPS {
  HOME = 'Home',
  PICKUP_POINT = 'PickupPoint',
  PICKUP_STORE = 'PickupStore',
  PICKUP_STORE_DELIVERY = 'PickupStoreDelivery',
  EXTERNAL = 'External',
  IMMEDIATE_DROP_AT_STORE = 'ImmediateDropAtStore',
  HOME_INTERVENTION = 'HomeIntervention',
}

export default HANDLING_GROUPS;
