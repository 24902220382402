import { MutableRefObject, useEffect, useMemo, useState } from 'react';

export function useOnScreen<T extends Element>(
  ref: MutableRefObject<T | null | undefined>,
): boolean {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIntersecting(entry.isIntersecting),
      ),
    [],
  );

  useEffect(() => {
    if (ref && ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [observer, ref]);

  return isIntersecting;
}
