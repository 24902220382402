import getCarrierLabelDocument from './getCarrierLabelDocument';
import getCarrierLabelsInfosQuery from './getCarrierLabelsInfosQuery';
import getCarrierLabelUrlQuery from './getCarrierLabelUrlQuery';
import getPickupPoints from './getPickupPoints';
import getVerifiedAddress from './getVerifiedAddress';

export const CarrierService = {
  getVerifiedAddress,
  getCarrierLabelsInfosQuery,
  getCarrierLabelUrlQuery,
  getCarrierLabelDocument,
  getPickupPoints,
};
