import React, { FunctionComponent } from 'react';

import { $CardContentHistoryDashedLine } from './ProductInfoHistory.styles';

const ProductInfoHistoryDashedSmallLine: FunctionComponent<{
  disabled?: boolean;
  isDashed?: boolean;
  isFirst?: boolean;
}> = ({ disabled, isFirst = false, isDashed = true }) => {
  return (
    <$CardContentHistoryDashedLine
      disabled={disabled}
      isFirst={isFirst}
      isDashed={isDashed}
      viewBox="0 0 36 10"
    >
      <line x1="0" y1="5" x2="100%" y2="5" />
    </$CardContentHistoryDashedLine>
  );
};

ProductInfoHistoryDashedSmallLine.displayName =
  'ProductInfoHistoryDashedSmallLine';
export default ProductInfoHistoryDashedSmallLine;
