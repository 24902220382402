/* eslint-disable max-len */
import React, { FC } from 'react';
import { useTheme } from 'styled-components';

const PictureDone: FC = () => {
  const theme = useTheme();

  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.5764 35.559L30.4305 42.4132L46.4236 26.4201"
        stroke={theme.colors.primary}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35 57.2639C47.6185 57.2639 57.8472 47.0352 57.8472 34.4167C57.8472 21.7981 47.6185 11.5694 35 11.5694C22.3815 11.5694 12.1528 21.7981 12.1528 34.4167C12.1528 47.0352 22.3815 57.2639 35 57.2639Z"
        stroke={theme.colors.mainTextColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

PictureDone.displayName = 'PictureDone';

export default PictureDone;
