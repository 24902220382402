import { media } from '@savgroup-front-common/constants';
import styled, { keyframes } from 'styled-components';

export const growUpKeyFrame = keyframes`
  0% { transform: scale(.8); }
  80% { transform: scale(1.25);}
  100% {transform: scale(1);}
`;

export const $CardContentHistoryWrapper = styled.div`
  flex: 0;
  display: flex;
  flex-direction: row;
  flex-flow: column;
  margin-top: 0.125rem;

  @media ${media.maxWidth.xs} {
    width: 100%;
  }
`;

export const $CardContentHistory = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

export const $CardContentHistoryBlankSpace = styled.div`
  width: 3rem;
`;

export const $CardContentHistorySmallLine = styled.div`
  width: 2rem;
`;

export const $CardContentHistoryPoint = styled.div<{
  active?: boolean;
  disabled?: boolean;
}>`
  z-index: 1;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  animation: ${growUpKeyFrame} 0.5s linear;
  animation-delay: 0.5s;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.tertiary : theme.colors.primary};
  ${({ active, theme }) =>
    active
      ? `box-shadow: 0 0 0.5rem ${theme.colors.primary};`
      : 'animation: none;'}
`;

export const $CardContentHistoryLine = styled.div<{ disabled?: boolean }>`
  flex: 4;
`;

export const $CardContentHistoryDashedLine = styled.svg<{
  disabled?: boolean;
  isDashed?: boolean;
  isFirst?: boolean;
}>`
  stroke-width: 2px;

  & line {
    stroke: ${({ theme, isFirst, disabled }) => {
      if (isFirst) {
        return 'transparent';
      }
      if (disabled) {
        return theme.colors.tertiary;
      }

      return theme.colors.primary;
    }};
    stroke-dasharray: ${({ isDashed }) => (isDashed ? '10 4' : '1 0')};
  }
`;

export const $CardContentHistoryLegendWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
`;

export const $CardContentHistoryLegend = styled.div<{
  disabled?: boolean;
  active?: boolean;
}>`
  width: 11rem;
  margin: 0.25rem 0;
  display: flex;
  flex-direction: row;
  flex-flow: column;
  align-items: center;
  text-align: center;
  padding: 0 0.25rem;
  font-size: 11px;
  line-height: ${({ theme }) => theme.fonts.size.small};
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.tertiary : theme.colors.primary};
`;

export const $Divider = styled.div`
  width: calc(100% + 2rem);
  height: 1px;
  background: #f7f7f7;
  margin: 1rem -1rem 1.25rem;
`;
