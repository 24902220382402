import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';

import { REVALIDATE_MODES } from '@savgroup-front-common/constants';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import {
  ADDITIONAL_INFORMATION_TYPES,
  FullClaim,
} from '@savgroup-front-common/types';
import { ClaimService } from 'myaccount/api';
import { Reason, RELATED_TO } from 'myaccount/types';
import { GetSolutionsByClaim } from 'myaccount/view/app/hooks/useGetSolutionsByClaim';
import { STEP_CONFIG } from 'myaccount/view/app/NewLayout/ClassiqueRoutes.config';
import {
  StepContext,
  StepContextValues,
} from 'myaccount/view/app/NewLayout/StepsProvider/StepsProvider.context';

import useGetClaimReasons from '../../app/hooks/useGetClaimReasons';

import claimGroupReasonSchema from './ClaimGroupReasonPage.schema';
import { ClaimGroupReasonValues } from './ClaimGroupReasonPage.types';
import {
  prepareReasonAdditionalInformation,
  reasonAdapter,
  ReasonAdapterReturnValues,
} from './helpers/reason.adapters';

const SET_REASON_FOR_CLAIM = 'setReasonForClaim';
const SET_ADDITIONNAL_INFORMATION = 'setAdditionalInformation';

interface UseClaimGroupReasonReturnValues {
  claims: FullClaim[];
  reasons: Reason[];
  reasonAdapted?: ReasonAdapterReturnValues;
  isMultiProduct: boolean;
  isLoading: boolean;
  formContext: UseFormReturn<ClaimGroupReasonValues>;
  onSubmit: () => void;
  isLoadingSubmit: boolean;
  claimGroupId: string;
  diagnosticIsAlreadyMade: boolean;
}

const useClaimGroupReason = (): UseClaimGroupReasonReturnValues => {
  const {
    values,
    handleUpdateValue,
    handleNextStep,
    handleValidateStep,
    isMultiProduct,
  } = useContext<StepContextValues>(StepContext);
  const queryClient = useQueryClient();
  const { removeAllNotifications, pushErrors } = useToasts();

  const claims = useMemo(
    () => values.claimGroup?.claims || [],
    [values.claimGroup?.claims],
  );
  const claimGroupId = values.claimGroup?.claimGroupId || '';
  const claimIds = claims?.map((claim) => claim.claimId) || [];

  const { reasons, reasonsIsLoading } = useGetClaimReasons({
    claimGroupId,
  });

  const adaptAdditionalInformation = useCallback(() => {
    const claimId = claims[0]?.claimId || '';

    return claims[0]?.additionalClaimInformation?.reduce(
      (acc: any, curr: any) => {
        if (curr.additionalInformationEnumValue) {
          return {
            ...acc,
            [curr.additionalInformationId]: {
              value: curr.additionalInformationEnumValue,
            },
            [`${curr.additionalInformationId}_${claimId}`]: {
              value: curr.additionalInformationEnumValue,
            },
          };
        }
        if (curr.additionalInformationStringValue) {
          return {
            ...acc,
            [curr.additionalInformationId]:
              curr.additionalInformationStringValue,
            [`${curr.additionalInformationId}_${claimId}`]:
              curr.additionalInformationStringValue,
          };
        }

        return acc;
      },
      {},
    );
  }, [claims]);

  const formContext = useForm<ClaimGroupReasonValues>({
    resolver: yupResolver(
      claimGroupReasonSchema({
        reasonSelected: values?.reason,
        isMultiProduct,
      }),
    ),
    defaultValues: {
      reason: {
        label: '',
        value: claims?.at(0)?.reasonId,
      },
      reasonComment: claims?.at(0)?.reasonComment,
      reasonAdditionalInformation: adaptAdditionalInformation(),
    },
    mode: REVALIDATE_MODES.ON_CHANGE,
    reValidateMode: REVALIDATE_MODES.ON_CHANGE,
  });

  const {
    handleSubmit,
    formState: { errors },
    watch,
    resetField,
  } = formContext;

  const watchedReasonSelected = watch('reason');
  const reasonSelected = reasons.find(
    (reason) => reason.id === watchedReasonSelected.value,
  );
  const reasonAdapted =
    reasonSelected && reasonAdapter({ reasonSelected, claimIds });

  const onReasonSelected = useCallback(
    (reasonAdapted: ReasonAdapterReturnValues) => {
      if (reasonAdapted) {
        handleUpdateValue({
          value: {
            reason: reasonAdapted,
          },
        });
        resetField('reasonAdditionalInformation');
        resetField('reasonComment');
      }
    },
    [handleUpdateValue, resetField],
  );

  const {
    mutateAsync: handleReasonForClaim,
    isLoading: isLoadingSubmitReasonForClaim,
  } = useMutation(
    [SET_REASON_FOR_CLAIM],
    async ({
      claimId,
      reasonId,
      reasonComment,
    }: {
      claimId: string;
      reasonId: string;
      reasonComment?: string;
    }) => {
      removeAllNotifications();
      const responseReason = await ClaimService.setReasonForClaim({
        claimId,
        reasonId,
        reasonComment,
      });

      if (responseReason.failure) {
        pushErrors(responseReason.errors);

        return undefined;
      }

      return responseReason;
    },
  );

  const {
    mutateAsync: handleAdditionalInformation,
    isLoading: isLoadingAdditionalInformation,
  } = useMutation(
    [SET_ADDITIONNAL_INFORMATION],
    async ({
      claimId,
      additionalClaimInformationValues,
    }: {
      claimId: string;
      additionalClaimInformationValues: { id: string; value: string }[];
    }) => {
      removeAllNotifications();
      const responseAdditionalInformation =
        await ClaimService.setClaimAdditionalInformation({
          claimId,
          additionalClaimInformationValues,
        });

      if (responseAdditionalInformation.failure) {
        pushErrors(responseAdditionalInformation.errors);

        return undefined;
      }

      return responseAdditionalInformation;
    },
  );

  const onSubmit = handleSubmit(async (reasonsResult) => {
    const reasonSelected = reasons.find(
      (reason) => reason.id === reasonsResult.reason.value,
    );

    const hasAdditionnalInformationFileRelatedToProduct =
      reasonSelected?.neededInformation.some(
        (item) =>
          (item.type === ADDITIONAL_INFORMATION_TYPES.FILE ||
            item.type === ADDITIONAL_INFORMATION_TYPES.MULTI_FILES) &&
          item.relatedTo === RELATED_TO.PRODUCT &&
          isMultiProduct,
      );

    const hasAdditionnalInformationProductRelatedToProduct =
      reasonSelected?.neededInformation.some(
        (item) =>
          item.type !== ADDITIONAL_INFORMATION_TYPES.FILE &&
          item.type !== ADDITIONAL_INFORMATION_TYPES.MULTI_FILES &&
          item.relatedTo === RELATED_TO.PRODUCT &&
          isMultiProduct,
      );

    const hasAdditionnalInformationFileRelatedToClaim =
      reasonSelected?.neededInformation.some(
        (item) =>
          (item.type === ADDITIONAL_INFORMATION_TYPES.FILE ||
            item.type === ADDITIONAL_INFORMATION_TYPES.MULTI_FILES) &&
          item.relatedTo === RELATED_TO.CLAIM &&
          isMultiProduct,
      );

    const hasAdditionnalInformationFile =
      reasonSelected?.neededInformation.some(
        (item) =>
          (item.type === ADDITIONAL_INFORMATION_TYPES.FILE ||
            item.type === ADDITIONAL_INFORMATION_TYPES.MULTI_FILES) &&
          !isMultiProduct,
      );

    return Promise.all(
      claims.map(async (claim) => {
        const responseReason = await handleReasonForClaim({
          claimId: claim.claimId,
          reasonId: reasonsResult?.reason?.value,
          reasonComment: reasonsResult?.reasonComment,
        });

        if (!responseReason) {
          return undefined;
        }

        const prepareReasonAdditionalInformationFormated =
          prepareReasonAdditionalInformation(
            claim.claimId,
            reasonsResult?.reasonAdditionalInformation as any,
            values?.reason?.neededInformation as any,
          );

        const responseAdditionalInformation = await handleAdditionalInformation(
          {
            claimId: claim.claimId,
            additionalClaimInformationValues:
              prepareReasonAdditionalInformationFormated as any,
          },
        );

        if (!responseAdditionalInformation) {
          return undefined;
        }

        return Promise.all([responseReason, responseAdditionalInformation]);
      }),
    )
      .then(() => {
        queryClient.invalidateQueries([GetSolutionsByClaim]);

        if (hasAdditionnalInformationProductRelatedToProduct) {
          return handleNextStep(
            STEP_CONFIG.CLAIM_GROUP_PRODUCT_INFO,
            claimGroupId,
          );
        }

        if (hasAdditionnalInformationFileRelatedToProduct) {
          return handleNextStep(
            STEP_CONFIG.CLAIM_GROUP_DOCUMENT_RELATED_TO_PRODUCT,
            claimGroupId,
          );
        }

        if (
          hasAdditionnalInformationFileRelatedToClaim ||
          hasAdditionnalInformationFile
        ) {
          return handleNextStep(
            STEP_CONFIG.CLAIM_GROUP_DOCUMENT_RELATED_TO_CLAIM,
            claimGroupId,
          );
        }

        return handleNextStep(STEP_CONFIG.CLAIM_GROUP_SOLUTION, claimGroupId);
      })
      .catch((err) => {
        pushErrors(err);

        return undefined;
      });
  });

  useEffect(() => {
    if (reasonAdapted && reasonAdapted?.id !== values?.reason?.id) {
      onReasonSelected(reasonAdapted);
    }
  }, [
    watchedReasonSelected,
    onReasonSelected,
    values?.reason?.id,
    reasonAdapted,
  ]);

  useEffect(() => {
    if (values.reason) {
      const hasError = Object.values(errors).length;

      handleValidateStep(!hasError);
    }
  });

  return {
    claims: claims as any,
    reasons: reasons || [],
    reasonAdapted,
    isMultiProduct,
    isLoading: reasonsIsLoading,
    formContext,
    onSubmit,
    isLoadingSubmit:
      isLoadingSubmitReasonForClaim || isLoadingAdditionalInformation,
    claimGroupId,
    diagnosticIsAlreadyMade: values?.diagnosticIsAlreadyMade || false,
  };
};

export default useClaimGroupReason;
