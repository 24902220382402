import styled from 'styled-components';

export const $Wrapper = styled.div`
  margin-top: 3.25rem;
`;

export const $Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.4rem;
`;

export const $FileHeader = styled.div`
  flex: 1;
  font-size: ${({ theme }) => theme.fonts.size.large};
  margin-bottom: 0.5rem;
`;

export const $Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #e6eaee;
  margin: 0.25rem 0 1.25rem;
`;

export const $CollapsableButton = styled.div`
  display: flex;
  align-items: center;
  z-index: 1;
  cursor: pointer;
`;

export const $ItemWrapper = styled.div`
  display: flex;
  padding: 0.4rem 0.4rem;
`;

export const $ItemDate = styled.div`
  color: ${({ theme }) => theme.colors.paragraphTextColor};
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
`;

export const $ItemLabel = styled.span`
  color: ${({ theme }) => theme.systemColors.defaultTextColor};
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
  margin-left: 2rem;
`;
