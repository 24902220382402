import { Reducer } from 'react';

import { OrderDetailsProduct } from '@savgroup-front-common/types';
import { PRODUCT_STATE } from './ChooseProductPage.types';

export type ChooseProductPageContentReducerAction = {
  type: PRODUCT_STATE.UPDATE;
  payload: {
    eligibleProducts: OrderDetailsProduct[];
    notEligibleProducts: OrderDetailsProduct[];
    notEligibleProductsFollowUpClaim: OrderDetailsProduct[];
  };
};

export interface ChooseProductPageContentReducerState {
  eligibleProducts: OrderDetailsProduct[];
  notEligibleProducts: OrderDetailsProduct[];
  notEligibleProductsFollowUpClaim: OrderDetailsProduct[];
}

export const initDiagnosticTreeState =
  (): ChooseProductPageContentReducerState => {
    return {
      eligibleProducts: [],
      notEligibleProducts: [],
      notEligibleProductsFollowUpClaim: [],
    };
  };

const ChooseProductPageContentReducer: Reducer<
  ChooseProductPageContentReducerState,
  ChooseProductPageContentReducerAction
> = (state, action) => {
  switch (action.type) {
    case PRODUCT_STATE.UPDATE: {
      return {
        eligibleProducts: action.payload.eligibleProducts,
        notEligibleProducts: action.payload.notEligibleProducts,
        notEligibleProductsFollowUpClaim:
          action.payload.notEligibleProductsFollowUpClaim,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default ChooseProductPageContentReducer;
