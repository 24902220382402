import React, { FunctionComponent, ReactElement } from 'react';
import { Grid } from 'react-styled-flexboxgrid';
import { useMedia } from 'react-use';

import { media } from '@savgroup-front-common/constants';

import { $Bannered } from './BanneredLayout.styles';
import { useLayout } from '../NewLayout.hooks';

const Banner: FunctionComponent<{
  children?: ReactElement | ReactElement[];
}> = ({ children }) => {
  const isGridFluidActive = useMedia(media.maxWidth.md);
  const { bannerUrl, enableServicePortal } = useLayout();

  return (
    <$Bannered $hasBannerUrl={enableServicePortal} $bannerUrl={bannerUrl}>
      <Grid fluid={isGridFluidActive}>{children}</Grid>
    </$Bannered>
  );
};

Banner.displayName = 'Banner';
export default Banner;
