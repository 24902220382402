import React, { Fragment, useContext } from 'react';

import { Link } from '../../../atoms/link';
import { Field } from '../../../molecules/Field';
import { ActorInformationItem } from '../../ActorInformation/components/GenericActorInformation/GenericActorInformation.styles';
import messages from '../../ActorInformation/components/GenericActorInformation/messages';
import { ContentTemplateContext } from '../MDXCustomComponents.context';

const EmailAddressesCurrentActor = () => {
  const { actorSummary } = useContext(ContentTemplateContext);
  const emailAddresses = (actorSummary?.mailAddresses || []).filter((v) => v);

  return (
    <>
      {emailAddresses.length > 0 && (
        <ActorInformationItem>
          <Field label={messages.email}>
            {emailAddresses.map((emailAddress) => (
              <Fragment key={emailAddress}>
                <Link isExternal href={`mailto:${emailAddress}`}>
                  {emailAddress}
                </Link>
                <br />
              </Fragment>
            ))}
          </Field>
        </ActorInformationItem>
      )}
    </>
  );
};

EmailAddressesCurrentActor.displayName = 'EmailAddressesCurrentActor';

export default EmailAddressesCurrentActor;
