import React from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { Heading } from '@savgroup-front-common/core/src/atoms/Heading';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { SUPPORTED_ICON_POSITIONS } from '@savgroup-front-common/core/src/helpers';
import { ArrowNakedRightIcon } from '@savgroup-front-common/core/src/protons/icons/ArrowNaked/ArrowNakedRight.icon';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';
import { FLEX_ALIGN, ICONS_TYPE } from '@savgroup-front-common/types';
import { BASE_ROUTES, ROUTES_NEW_DESIGN } from 'myaccount/view/app';
import IconDiagnosticTreeEntry from 'myaccount/view/components/IconDiagnosticTreeEntry';

import messages from '../messages';

import {
  $RowButtonContainer,
  $RowContainer,
  $SubtitleContainer,
} from './ClaimGroupReasonPageDiagnosticTreeEntry.styles';

interface ClaimGroupReasonPageDiagnosticTreeEntryProps {
  claimGroupId: string;
  diagnosticTreeUniqueName: string;
  setIsRefuseDiagnosticTree: (value: boolean) => void;
}

const ClaimGroupReasonPageDiagnosticTreeEntry: React.FC<
  ClaimGroupReasonPageDiagnosticTreeEntryProps
> = ({ claimGroupId, diagnosticTreeUniqueName, setIsRefuseDiagnosticTree }) => {
  const history = useHistory();
  const theme = useTheme();

  const path = generatePath(
    `${BASE_ROUTES.NEW_DESIGN}${ROUTES_NEW_DESIGN.CLAIM_GROUP_DIAGNOSTIC_TREE}`,
    {
      claimGroupId,
      questionId: diagnosticTreeUniqueName,
      step: `step1`,
    },
  );

  return (
    <>
      <$RowContainer center="xs">
        <IconDiagnosticTreeEntry />
        <Heading level={2} align={FLEX_ALIGN.CENTER} color={theme.colors.black}>
          <SafeFormattedMessageWithoutSpread
            message={messages.diagnosticTreeTitle}
          />
        </Heading>
        <$SubtitleContainer>
          <MyAccountIcon
            icon={ICONS_TYPE.VEGAN_CIRCLE_ICON}
            color={theme.colors.success}
          />
          <SafeFormattedMessageWithoutSpread
            message={messages.diagnosticTreeSubtitle}
          />
        </$SubtitleContainer>
        <$RowButtonContainer>
          <Button
            type={BUTTON_TYPES.BUTTON}
            naked
            underline
            onClick={() => setIsRefuseDiagnosticTree(true)}
            dataTestId="refuse-diagnostic-tree"
          >
            <SafeFormattedMessageWithoutSpread
              message={messages.diagnosticTreeButtonBack}
            />
          </Button>
          <Button
            type={BUTTON_TYPES.BUTTON}
            primary
            icon={<ArrowNakedRightIcon />}
            position={SUPPORTED_ICON_POSITIONS.RIGHT}
            onClick={() => history.push(path)}
            dataTestId="go-diagnostic-tree"
          >
            <SafeFormattedMessageWithoutSpread
              message={messages.diagnosticTreeButtonGo}
            />
          </Button>
        </$RowButtonContainer>
      </$RowContainer>
    </>
  );
};

ClaimGroupReasonPageDiagnosticTreeEntry.displayName =
  'ClaimGroupReasonPageDiagnosticTreeEntry';

export default ClaimGroupReasonPageDiagnosticTreeEntry;
