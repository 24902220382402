import { createSelector } from 'reselect';

import { CommonAppState } from '../CommonAppState';
import {
  isActionLoadingSelector,
  wasActionLoadedSelector,
} from '../sagaRequestMetadata/selectors';

import * as types from './actionTypes';
import { STORE_DOMAIN_KEY } from './constants';
import { SellerId, STORE_DOMAIN, StoreId } from './types';

const selectStoreDomain = (state: CommonAppState) => state[STORE_DOMAIN_KEY];

const selectStoreList = createSelector(
  [selectStoreDomain],
  (state) => state[STORE_DOMAIN.LIST],
);

const selectBySellerId = createSelector(
  [selectStoreDomain],
  (state) => state[STORE_DOMAIN.BY_SELLER_ID],
);

export const selectStoreById = createSelector(
  [selectStoreList, (_: any, { storeId }: { storeId?: StoreId }) => storeId],
  (stores, storeId?: StoreId) =>
    storeId !== undefined ? stores[storeId] || null : undefined,
);
export const selectStoreByIds = createSelector(
  [
    selectStoreList,
    (_: unknown, { storeIds }: { storeIds: StoreId[] }) => storeIds,
  ],
  (stores, storeIds) =>
    storeIds
      .map((storeId) => stores[storeId] || null)
      .filter((store) => store !== null),
);

export const selectStoreIdsBySellerId = createSelector(
  [
    selectBySellerId,
    (_: unknown, { sellerId }: { sellerId: SellerId }) => sellerId,
  ],
  (storeIdsBySellerId, sellerId: SellerId) =>
    storeIdsBySellerId[sellerId] || [],
);
export const selectStoresBySellerId = createSelector(
  [selectStoreList, selectStoreIdsBySellerId],
  (stores, storeIds) =>
    storeIds
      .map((storeId) => stores[storeId] || null)
      .filter((store) => store !== null),
);

export const selectGetStoreIsLoading = (
  state: CommonAppState,
  { storeId }: { storeId: StoreId },
): boolean => isActionLoadingSelector(state, types.GET_STORE, storeId);

export const selectGetStoreWasLoaded = (
  state: CommonAppState,
  { storeId }: { storeId: StoreId },
): boolean => wasActionLoadedSelector(state, types.GET_STORE, storeId);

export const selectGetStoreBySellerIdIsLoading = (
  state: CommonAppState,
  { sellerId }: { sellerId: SellerId },
): boolean =>
  isActionLoadingSelector(state, types.GET_STORES_BY_SELLER_ID, sellerId);

export const selectGetStoreBySellerIdWasLoaded = (
  state: CommonAppState,
  { sellerId }: { sellerId: SellerId },
): boolean =>
  wasActionLoadedSelector(state, types.GET_STORES_BY_SELLER_ID, sellerId);

export const selectGetStoreBySellerIdIsReadyToCall = createSelector(
  [selectGetStoreBySellerIdIsLoading, selectGetStoreBySellerIdWasLoaded],
  (isLoading, wasLoaded): boolean => {
    return !(isLoading || wasLoaded);
  },
);
