import React, {
  createContext,
  PropsWithChildren,
  Reducer,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import { matchPath, useHistory, useLocation } from 'react-router-dom';

import {
  RoutesConfig,
  STEP_CONFIG,
  stepsConfig,
} from '../ClassiqueRoutes.config';

import { buildPath } from './StepContext.helpers';
import {
  FORM_ACTION_TYPES,
  FormState,
  VALUE_ACTION_TYPES,
  ValuesState,
} from './StepsProvider.types';
import formReducer, {
  FormAction,
  initFormState,
} from './StepsProviderForm.reducer';
import isMultiProductReducer, {
  isMultiProductAction,
  isMultiProductState,
} from './StepsProviderIsMultiProduct.reducer';
import isValidatedStepReducer, {
  isValidatedStepAction,
  isValidatedStepState,
} from './StepsProviderIsValidatedStep.reducer';
import valueReducer, {
  initValueState,
  ValueAction,
} from './StepsProviderValues.reducer';
import { InitContext } from '../InitProvider/InitProvider.context';

export type StepContextValues = {
  form: FormState | undefined;
  values: ValuesState;
  isValidatedStep?: boolean;
  isMultiProduct: boolean;
  handleValidateStep: (state: boolean) => void;
  checkIfIsMultiProduct: (state: boolean) => void;
  handleNextStep: (
    stepName: STEP_CONFIG,
    param?: string,
    form?: {
      issuePageIsSkipped?: boolean;
      solutionPageIsSkipped?: boolean;
    },
  ) => void;
  handlePreviousStep: () => void;
  handleJumpStep: (stepName: STEP_CONFIG, param: string) => void;
  handleUpdateValue: ({ value }: { value: Partial<ValuesState> }) => void;
  enableServicePortal: boolean;
};

export const StepContext = createContext<StepContextValues>({
  form: undefined,
  values: {},
  isValidatedStep: false,
  isMultiProduct: false,
  handleValidateStep: () => undefined,
  checkIfIsMultiProduct: () => undefined,
  handleNextStep: () => undefined,
  handlePreviousStep: () => undefined,
  handleJumpStep: () => undefined,
  handleUpdateValue: () => undefined,
  enableServicePortal: false,
});

const StepProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const {
    ownerId,
    login,
    orders,
    openedFiles,
    closedFiles,
    enableServicePortal = false,
  } = useContext(InitContext);
  const location = useLocation();
  const history = useHistory();

  const matchStep: RoutesConfig | void = stepsConfig.find((page) => {
    return matchPath(location.pathname, {
      path: page.path,
      exact: true,
      strict: true,
    });
  });

  const [formState, formDispatch] = useReducer<Reducer<FormState, FormAction>>(
    formReducer,
    initFormState(matchStep),
  );

  const [valuesState, valuesDispatch] = useReducer<
    Reducer<ValuesState, ValueAction>
  >(
    valueReducer,
    initValueState({ ownerId, login, orders, openedFiles, closedFiles }),
  );

  const [isValidatedStep, isValidatedDispatch] = useReducer<
    Reducer<boolean, isValidatedStepAction>
  >(isValidatedStepReducer, isValidatedStepState({ state: false }));

  const handleValidateStep = useCallback((state?: boolean) => {
    isValidatedDispatch({ payload: !!state });
  }, []);

  const [isMultiProduct, isMultiProductDispatch] = useReducer<
    Reducer<boolean, isMultiProductAction>
  >(isMultiProductReducer, isMultiProductState({ state: false }));

  const checkIfIsMultiProduct = useCallback((state?: boolean) => {
    isMultiProductDispatch({ payload: !!state });
  }, []);

  const handleNextStep = useCallback(
    (
      stepName: STEP_CONFIG,
      param?: string,
      form?: {
        issuePageIsSkipped?: boolean;
        solutionPageIsSkipped?: boolean;
      },
    ) => {
      formDispatch({
        type: FORM_ACTION_TYPES.NEXT_STEP,
        payload: {
          nextStep: stepName,
          issuePageIsSkipped: form?.issuePageIsSkipped,
          solutionPageIsSkipped: form?.solutionPageIsSkipped,
        },
      });
      const path = buildPath(stepName, param);

      if (path) {
        return history.push(path);
      }

      return undefined;
    },
    [history],
  );

  const handlePreviousStep = useCallback(() => {
    if (
      formState.currentStep.stepName === STEP_CONFIG.CLAIM_GROUP_REASON &&
      !enableServicePortal
    ) {
      const path = buildPath(
        STEP_CONFIG.CHOOSE_PRODUCTS,
        valuesState.issue?.id,
      );

      if (path) {
        return history.push(path);
      }
    }

    return history.goBack();
  }, [
    history,
    formState?.currentStep?.stepName,
    valuesState.issue?.id,
    enableServicePortal,
  ]);

  const handleJumpStep = useCallback(
    (stepName: STEP_CONFIG, param?: string) => {
      formDispatch({
        type: FORM_ACTION_TYPES.JUMP_TO_STEP,
        payload: { jumpStep: stepName },
      });
      const path = buildPath(stepName, param);

      if (path) {
        return history.push(path);
      }

      return undefined;
    },
    [history],
  );

  const handleUpdateValue = useCallback(
    ({ value }: { value: Partial<ValuesState> }) => {
      valuesDispatch({
        type: VALUE_ACTION_TYPES.UPDATE_VALUE,
        payload: { ...value },
      });
    },
    [],
  );

  useEffect(() => {
    if (matchStep?.name) {
      formDispatch({
        type: FORM_ACTION_TYPES.UPDATE_STEP,
        payload: {
          stepName: matchStep.name,
        },
      });
    }
  }, [matchStep?.name]);

  return (
    <StepContext.Provider
      value={{
        form: formState,
        values: valuesState,
        isValidatedStep,
        handleValidateStep,
        isMultiProduct,
        checkIfIsMultiProduct,
        handleNextStep,
        handlePreviousStep,
        handleJumpStep,
        handleUpdateValue,
        enableServicePortal,
      }}
    >
      {children}
    </StepContext.Provider>
  );
};

StepProvider.displayName = 'StepProvider';

export default StepProvider;
