import React, { FC } from 'react';

import { FileAction } from '../../../../../../domains/Customer/Customer.types';
import { ActionLabel } from '../ActionLabel';
import { CustomerCommentDisplay } from '../../../../../components/CustomerCommentDisplay';

import { useAdditionalInformationExpect } from './AdditionalInformationExpect.hooks';
import AdditionalInformationExpectContent from './AdditionalInformationExpectContent';

interface AdditionalInformationExpectProps {
  adaptedFirstAction?: FileAction;
}

const AdditionalInformationExpect: FC<AdditionalInformationExpectProps> = ({
  adaptedFirstAction,
}) => {
  const { additionalInformations } = useAdditionalInformationExpect();

  return (
    <>
      <ActionLabel action={adaptedFirstAction} index={0} />

      <CustomerCommentDisplay action={adaptedFirstAction} />

      {additionalInformations && (
        <AdditionalInformationExpectContent
          additionalInformations={additionalInformations}
        />
      )}
    </>
  );
};

AdditionalInformationExpect.displayName = 'AdditionalInformationExpect';

export default AdditionalInformationExpect;
