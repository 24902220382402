import React from 'react';
import { FormProvider } from 'react-hook-form';

import { AdditionalInformation } from '@savgroup-front-common/core/src/components/AdditionalInformationWithReactHookForm';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import StepLayout from 'myaccount/view/app/NewLayout/StepLayout/StepLayout';

import useClaimGroupDocumentRelatedToProduct from './ClaimGroupDocumentPageRelatedToProduct.hooks';
import ProductHeader from './ProductHeader/ProductHeader';

const ClaimGroupDocumentPageRelatedToProduct: React.FC = () => {
  const {
    claims,
    products,
    isLoading,
    neededInformationDocumentOnlyRelatedToProduct,
    onSubmit,
    formContext,
    isLoadingSubmit,
  } = useClaimGroupDocumentRelatedToProduct();

  if (isLoading) {
    return <BaseLoader />;
  }

  return (
    <FormProvider {...formContext}>
      <form onSubmit={onSubmit}>
        <StepLayout isLoading={isLoadingSubmit}>
          {claims.map((claim) => {
            const neededInformationOnlyRelatedToProductFiltered =
              neededInformationDocumentOnlyRelatedToProduct.filter(
                (item) =>
                  item.currentClaimId === claim.claimId &&
                  item.claimIds.includes(claim.claimId),
              );

            return (
              <div key={claim.claimId}>
                {products && products[claim.ownerProductId] && (
                  <ProductHeader product={products[claim.ownerProductId]} />
                )}
                <AdditionalInformation
                  neededInformation={
                    neededInformationOnlyRelatedToProductFiltered
                  }
                  formContext={formContext}
                  prefixFormName={`${claim.claimId}.reasonAdditionalInformationDocumentRelatedToProduct`}
                  ignoreUniquenessByProduct
                  fullSizeItem
                  isScrollIntoView
                  isNewDesign
                  shouldAllowQrCode={false}
                />
              </div>
            );
          })}
        </StepLayout>
      </form>
    </FormProvider>
  );
};

ClaimGroupDocumentPageRelatedToProduct.displayName =
  'ClaimGroupDocumentPageRelatedToProduct';

export default ClaimGroupDocumentPageRelatedToProduct;
