import React, { FunctionComponent } from 'react';
import { FormattedDate } from 'react-intl';

import { FileStateHistorySummary } from 'myaccount/api/Workflow/getFileStatesQuery';

import { $ItemDate, $ItemLabel, $ItemWrapper } from '../FileHistory.styles';

import { $Column, $HistoryLineCustomerComment } from './FileHistoryItem.styles';

const FileHistoryItem: FunctionComponent<{
  history: FileStateHistorySummary;
}> = ({ history }) => {
  return (
    <$ItemWrapper>
      <$ItemDate>
        <FormattedDate
          year="numeric"
          month="2-digit"
          day="2-digit"
          value={history.stateDate}
        />
      </$ItemDate>
      <$Column>
        <$ItemLabel>{history.state.label}</$ItemLabel>
        {history.toCustomerComment && (
          <$HistoryLineCustomerComment>
            {history.toCustomerComment}
          </$HistoryLineCustomerComment>
        )}
      </$Column>
    </$ItemWrapper>
  );
};

FileHistoryItem.displayName = 'FileHistoryItem';
export default FileHistoryItem;
