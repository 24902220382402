import { createRequest } from '../../services/request';

export const LOAD_DOCUMENT_BY_URL = createRequest(
  'ATTACHMENTS/LOAD_DOCUMENT_BY_URL',
);

export const SET_DOCUMENT_BY_URL = createRequest(
  'ATTACHMENTS/SET_DOCUMENT_BY_URL',
);
export const LOAD_FILES_ATTACHMENT_LIST = createRequest(
  'ATTACHMENTS/LOAD_ATTACHMENT_LIST',
);

export const UPLOAD_FILE_ATTACHMENT_DATA = createRequest(
  'FILE_ATTACHMENTS/UPLOAD_FILE_ATTACHMENT_DATA',
);
export const SET_FILE_ATTACHMENT_INFO = createRequest(
  'FILE_ATTACHMENTS/SET_FILE_ATTACHMENT_INFO',
);
export const DELETE_ATTACHMENT_FROM_FILE = createRequest(
  'FILE_ATTACHMENTS/DELETE_ATTACHMENT_FROM_FILE',
);

export const RESET_UPLOAD_STATE = 'FILE_ATTACHMENTS/RESET_UPLOAD_STATE';

export const GET_DOCUMENTS_STATUS_BY_FILE_ID = createRequest(
  'FILE_ATTACHMENTS/GET_DOCUMENTS_STATUS_BY_FILE_ID',
);
export const GET_ALL_DOCUMENTS_STATUS_BY_FILE_ID = createRequest(
  'FILE_ATTACHMENTS/GET_ALL_DOCUMENTS_STATUS_BY_FILE_ID',
);

export const GET_DOCUMENTS_BY_CLAIM_ID = createRequest(
  'CLAIMS/GET_DOCUMENTS_BY_CLAIM_ID',
);
