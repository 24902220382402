import { useQuery } from 'react-query';

import { WorkflowService } from '../../../api';

export const GetCustomerFileFullSummaryQuery =
  'getCustomerFileFullSummaryQuery';

export const useGetCustomerFileFullSummaryQuery = ({
  fileId,
}: {
  fileId?: string;
}) => {
  const { data: productsInfo, refetch } = useQuery(
    [GetCustomerFileFullSummaryQuery, { fileId }],
    async () => {
      if (!fileId) {
        return undefined;
      }

      const response = await WorkflowService.getCustomerFileFullSummaryQuery({
        fileId,
      });

      if (response.failure) {
        return undefined;
      }

      return response.value;
    },
    {
      staleTime: Infinity,
      suspense: true,
    },
  );

  return {
    productsInfo,
    refetchFileSummary: refetch,
  };
};
