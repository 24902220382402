import { add } from 'date-fns';
import React, { FunctionComponent, useContext } from 'react';
import { FormattedDate } from 'react-intl';

import { Timeslot } from '@savgroup-front-common/types';

import {
  BannersConsumer,
  BannersProviderContext,
} from '../../../molecules/NotificationsProvider';
import { useGetPageSize } from '../../InterventionAppointment/hooks/useGetPageSize';
import { byStartLocalTime } from '../AppointmentCalendar.helpers';
import {
  CalendarDayBlock,
  CalendarDayDate,
  CalendarDayName,
  TimeSlotSelectorContainer,
} from '../AppointmentCalendar.styles';
import { DetailedSchedule } from '../types/DetailedSchedule';

import {
  $AppointmentCalendarScreen,
  $EmptyDay,
  $TimeSlotSelectorContainer,
  $UnderlinedRow,
} from './AppointmentCalendarScreen.styles';
import TimeSlotSelector from './TimeSlotSelector';

interface AppointmentCalendarScreenProps {
  isActive: boolean;
  selectedTimeslot?: Timeslot;
  schedules: DetailedSchedule[];
  onTimeslotSelect: ({
    selectedTimeslot,
  }: {
    selectedTimeslot?: Timeslot;
  }) => void;
}

const AppointmentCalendarScreen: FunctionComponent<
  AppointmentCalendarScreenProps
> = ({ isActive, selectedTimeslot, schedules, onTimeslotSelect }) => {
  const pageSize = useGetPageSize();

  const { notifications } = useContext(BannersProviderContext);

  const isAllDetailedScheduleEmpty = (schedules || []).every(
    ({ timeslots = [] }) =>
      timeslots.filter((timeslot) => !!timeslot).length === 0,
  );

  const firstDate = schedules[0]?.date;

  const dateList: Date[] = [];

  if (firstDate) {
    for (let i = 0; i < pageSize; i += 1) {
      dateList.push(add(firstDate, { days: i }));
    }
  }

  return (
    <$AppointmentCalendarScreen>
      <$UnderlinedRow $columnCount={pageSize}>
        {dateList.map((date) => (
          <div key={date.toISOString()}>
            <CalendarDayBlock>
              <CalendarDayName>
                <FormattedDate value={date} weekday="long" />
              </CalendarDayName>
              <CalendarDayDate>
                <FormattedDate value={date} day="2-digit" month="2-digit" />
              </CalendarDayDate>
            </CalendarDayBlock>
          </div>
        ))}
      </$UnderlinedRow>

      <BannersConsumer />

      {!isAllDetailedScheduleEmpty && notifications.length === 0 && (
        <$TimeSlotSelectorContainer $columnCount={pageSize}>
          {dateList.map((date) => {
            const detailedSchedule = schedules.find(
              (schedule) => schedule.date.getTime() === date.getTime(),
            );
            const timeslots = detailedSchedule?.timeslots || [];

            if (timeslots?.length === 0) {
              return <$EmptyDay key={date.toISOString()}>-</$EmptyDay>;
            }

            const allDaySchedule = timeslots.find(
              (timeslot) => timeslot.allDaySchedule,
            );

            if (allDaySchedule) {
              return (
                <div key={date.toISOString()}>
                  <TimeSlotSelector
                    isActive={isActive}
                    timeslot={allDaySchedule}
                    onTimeslotSelect={onTimeslotSelect}
                    selectedTimeslot={selectedTimeslot}
                  />
                </div>
              );
            }

            return (
              <div key={date.toISOString()}>
                <TimeSlotSelectorContainer>
                  {timeslots.sort(byStartLocalTime).map((timeslot) => {
                    return (
                      <TimeSlotSelector
                        key={timeslot.internalId}
                        isActive={isActive}
                        timeslot={timeslot}
                        onTimeslotSelect={onTimeslotSelect}
                        selectedTimeslot={selectedTimeslot}
                      />
                    );
                  })}
                </TimeSlotSelectorContainer>
              </div>
            );
          })}
        </$TimeSlotSelectorContainer>
      )}
    </$AppointmentCalendarScreen>
  );
};

AppointmentCalendarScreen.displayName = 'AppointmentCalendarScreen';

export default AppointmentCalendarScreen;
