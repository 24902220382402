import { SearchOrderDetails } from '@savgroup-front-common/types';
import { sortBy } from '@savgroup-front-common/core/src/helpers';

export const adaptSearchOrderDetails = (
  order?: SearchOrderDetails,
): SearchOrderDetails | undefined => {
  if (!order) {
    return order;
  }

  return {
    ...order,
    products: order.products.sort(sortBy({ fieldName: 'orderLineReference' })),
  };
};
