import { createReducer } from 'myaccount/helpers/reducerUtils';

import { CLAIM_CANCELLED } from '../Claim/actionTypes';

const initialState = {};

function onClaimCancelled() {
  return {
    ...initialState,
  };
}

const fileUploadReducer = createReducer(initialState, {
  [CLAIM_CANCELLED]: onClaimCancelled,
});

export default fileUploadReducer;
