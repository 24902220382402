import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  FileShortSummary,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.workflow}files/${COMMON_QUERY_PARAMS.ORDER_ID}`;

interface GetFilesByOrderQueryPayload {
  orderId: string;
}

type GetFilesByOrderQueryResponse = FileShortSummary[];

type GetFilesByOrderQuery = (
  payload: GetFilesByOrderQueryPayload,
) => Promise<BackResponse<GetFilesByOrderQueryResponse> | BackResponseFailure>;

const getFilesByOrderQuery: GetFilesByOrderQuery = async ({ orderId }) => {
  try {
    return await apiCall<BackResponse<GetFilesByOrderQueryResponse>>(
      buildUrl(ENDPOINT.replace(COMMON_QUERY_PARAMS.ORDER_ID, orderId), {
        orderId,
      }),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default getFilesByOrderQuery;
