import { createSelector } from 'reselect';

import { wasActionLoadedSelector } from '@savgroup-front-common/core/src/domains/sagaRequestMetadata/selectors';

import { LOAD_ORDERS } from './actionTypes';

const ordersSelector = ({ orders = {} }) => orders;

export const ordersSelectorItems = createSelector(
  [ordersSelector],
  ({ items = [] }) => items,
);

export const selectLoadOwnerOrdersWasLoaded = (state, { ownerId }) => {
  return wasActionLoadedSelector(state, LOAD_ORDERS, ownerId);
};
