import { DefaultTheme } from 'styled-components';

interface GetColorFromProps {
  theme: DefaultTheme;
  isActive: boolean;
  isDisabled: boolean;
  isErrored: boolean;
  isNewUiEnabled: boolean;
}

export const getColorFromProps = ({
  theme,
  isActive,
  isDisabled,
  isErrored,
  isNewUiEnabled,
}: GetColorFromProps) => {
  if (isNewUiEnabled) {
    return theme.newUI.defaultColors.text;
  }

  if (isActive) {
    return theme.colors.mainTextColor;
  }

  if (isDisabled) {
    return theme.colors.mainTextColor;
  }

  return isErrored ? theme.colors.danger : theme.colors.mainTextColor;
};
