import { defineMessages } from 'react-intl';

export default defineMessages({
  solutionTitle: {
    id: 'components.myaccount.claims.handling.cards.solutionTitle',
    defaultMessage: 'Solution : ',
  },
  repairProduct: {
    id: 'components.myaccount.claims.handling.cards.repairProduct',
    defaultMessage: 'Repair my product in the workshop',
  },
  estimatedTime: {
    id: 'components.myaccount.claims.handling.cards.estimatedTime',
    defaultMessage: 'estimated average time {estimatedTime}',
  },
  modifyButton: {
    id: 'components.myaccount.claims.handling.cards.modifyButton',
    defaultMessage: 'Modify',
  },
  handlingTitle: {
    id: 'components.myaccount.claims.handling.cards.handlingTitle',
    defaultMessage: 'Send my product',
  },
  handlingTitleOnlyHome: {
    id: 'components.myaccount.claims.handling.cards.handlingTitleOnlyHome',
    defaultMessage: 'Home intervention',
  },
  free: {
    id: 'components.myaccount.claims.handling.cards.free',
    defaultMessage: 'Free',
  },

  deleteAddress: {
    id: 'components.common.addressManagement.deleteAddress',
    defaultMessage: 'Supprimer cette addresse',
  },
  editAddress: {
    id: 'components.common.addressManagement.editAddress',
    defaultMessage: 'Modifier cette addresse',
  },
  addAddress: {
    id: 'components.common.addressManagement.addAddress',
    defaultMessage: 'Ajouter une addresse',
  },
  areYouSure: {
    id: 'components.common.addressManagement.areYouSure',
    defaultMessage: 'Êtes vous certains ?',
  },
  youWillErase: {
    id: 'components.common.addressManagement.youWillErase',
    defaultMessage: 'Confirmer la suppression de cette addresse',
  },
  cancel: {
    id: 'components.common.addressManagement.cancel',
    defaultMessage: 'Annuler',
  },
  confirm: {
    id: 'components.common.addressManagement.confirm',
    defaultMessage: 'Confirmer',
  },
  back: {
    id: 'components.common.addressManagement.back',
    defaultMessage: 'Back',
  },
  requiredField: {
    id: 'components.common.breadCrumb.commentRequired',
    defaultMessage: 'This field is mandatory',
  },
  badFormatError: {
    id: 'components.common.addressManagement.addressForm.badFormatError',
    defaultMessage:
      'Ce champs ne peut pas contenir de caractères spéciaux (@,€,#,...)',
  },
  phoneFormatError: {
    id: 'components.common.addressManagement.addressForm.phoneFormatError',
    defaultMessage: 'Phone number formatted incorrectly',
  },
  maxLengthError: {
    id: 'components.common.addressManagement.addressForm.maxLengthError',
    defaultMessage: 'Ce champs est trop long :',
  },
});
