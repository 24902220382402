import { rgba } from 'polished';
import { Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import { media } from '@savgroup-front-common/constants';

export const $RowContainer = styled(Row)`
  flex-direction: column;
  align-items: center;
  margin-left: unset;
  margin-right: unset;
  h2 {
    text-align: center;
    font-weight: ${({ theme }) => theme.fonts.weight.bold};
    @media ${media.maxWidth.sm} {
      font-size: 20px;
    }
  }
  & > * {
    margin-top: 2rem;
  }
`;

export const $SubtitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 8px;
  background-color: ${({ theme }) => rgba(theme.colors.success, 0.1)};
  padding: 1rem;
  span {
    color: ${({ theme }) => theme.colors.success};
    font-size: 16px;
    font-weight: 700;
  }
  svg {
    margin-right: 1rem;
  }
`;

export const $RowButtonContainer = styled(Row)`
  width: 100%;
  justify-content: space-between;
  @media ${media.maxWidth.sm} {
    flex-direction: column-reverse;
  }
`;
