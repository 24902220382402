import createContextIfNotExistCommand from './createContextIfNotExistCommand';
import createTechnicalIntervention from './createTechnicalIntervention';
import createWorkOrderCommand from './createWorkOrderCommand';
import { getDefaultWorkTypeQuery } from './getDefaultWorkTypeQuery';
import { getFileAdditionalInformationQuery } from './getFileAdditionalInformationQuery';
import { getFileAttachmentUploadLinkFromControlCommand } from './getFileAttachmentUploadLinkFromControlCommand';
import getFileHandling from './getFileHandling';
import { getFileStatesQuery } from './getFileStatesQuery';
import getSalesforceContextQuery from './getSalesforceContextQuery';
import { getShortFileInfoQuery } from './getShortFileInfoQuery';
import getTechnicalInterventionQuery from './getTechnicalInterventionQuery';
import getTechnicalInterventionTimeSlots from './getTechnicalInterventionTimeSlots';
import { getUserActionsQuery } from './getUserActionsQuery';
import getWorkTypeBySellerQuery from './getWorkTypeBySellerQuery';
import oldGetTechnicalInterventionTimeSlots from './oldGetTechnicalInterventionTimeSlots';
import { setFileAdditionalInformationWithFilesAndTokenCommand } from './setFileAdditionalInformationWithFilesAndTokenCommand';
import setUserActionAsyncCommand from './setUserActionAsyncCommand';
import { setUserActionOldCommand } from './setUserActionOldCommand';
import { upsertBankAccountDetails } from './upsertBankAccountDetails';

export const CommonWorkflowService = {
  getDefaultWorkTypeQuery,
  getTechnicalInterventionQuery,
  createContextIfNotExistCommand,
  createTechnicalIntervention,
  createWorkOrderCommand,
  getSalesforceContextQuery,

  getTechnicalInterventionTimeSlots,
  oldGetTechnicalInterventionTimeSlots,

  getWorkTypeBySellerQuery,
  getFileAdditionalInformationQuery,
  setUserActionAsyncCommand,
  upsertBankAccountDetails,
  getUserActionsQuery,
  setUserActionOldCommand,
  getFileStatesQuery,

  getFileAttachmentUploadLinkFromControlCommand,
  getShortFileInfoQuery,
  setFileAdditionalInformationWithFilesAndTokenCommand,
  getFileHandling,
};
