import { useQuery } from 'react-query';

import { STALE_TIME } from '@savgroup-front-common/constants';

import { ClaimService } from '../../../api';

export const useGetClaimContextsByGroupIdQuery = ({
  claimGroupId,
}: {
  claimGroupId?: string;
}) => {
  const { data: claimContexts, isLoading } = useQuery(
    ['useGetClaimContextsByGroupIdQuery', { claimGroupId }],
    async () => {
      if (!claimGroupId) {
        return undefined;
      }

      const response = await ClaimService.getClaimContextsByGroupIdQuery({
        claimGroupId,
      });

      if (response.failure) {
        return undefined;
      }

      return response.value;
    },
    { staleTime: STALE_TIME.MINUTES_1 },
  );

  return {
    claimContexts,
    isLoading,
  };
};
