import styled from 'styled-components';

export const $ActionRow = styled.div`
  display: flex;
  justify-content: end;
  gap: 0.5rem;
`;

export const $Didactic = styled.div`
  margin-bottom: 1rem;
`;
