import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from 'react-styled-flexboxgrid';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';

import { Button } from '../../../atoms/button';
import { Modal, MODAL_SIZES } from '../../../atoms/modal';
import { CrossIcon } from '../../../protons/icons';

import {
  $CloseModalBlock,
  $ConfirmCancelModalRow,
} from './ConfirmCancelModal.styles';
import messages from './messages';

interface ConfirmCancelModalProps {
  isConfirmCancelOpen?: boolean;
  onCloseModal?: () => void;
  onSubmit?: () => void;
}

const ConfirmCancelModal: FunctionComponent<
  React.PropsWithChildren<ConfirmCancelModalProps>
> = ({
  isConfirmCancelOpen = false,
  onCloseModal = () => undefined,
  onSubmit = () => undefined,
}) => (
  <Modal
    size={MODAL_SIZES.SMALL}
    isOpen={isConfirmCancelOpen}
    onClose={onCloseModal}
  >
    <Row>
      <Col xs={12}>
        <$CloseModalBlock onClick={onCloseModal}>
          <CrossIcon />
        </$CloseModalBlock>

        <$ConfirmCancelModalRow>
          <Col xs={12}>
            <p>
              <FormattedMessage {...messages.returnModalText1} />
            </p>
            <p>
              <FormattedMessage {...messages.returnModalText2} />
            </p>
          </Col>
        </$ConfirmCancelModalRow>
        <Row>
          <Col xs={6}>
            <Button
              type={BUTTON_TYPES.BUTTON}
              secondary
              fluid
              onClick={onCloseModal}
            >
              <FormattedMessage {...messages.cancel} />
            </Button>
          </Col>
          <Col xs={6}>
            <Button type={BUTTON_TYPES.BUTTON} fluid primary onClick={onSubmit}>
              <FormattedMessage {...messages.submitButton} />
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
);

ConfirmCancelModal.displayName = 'ConfirmCancelModal';

export default ConfirmCancelModal;
