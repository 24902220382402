import get from 'lodash/get';

import * as ActionTypes from './actionTypes';
import * as Formatters from './formaters';
import { formatInvoice } from './formatInvoice';
import { sortByDateAscending } from './helpers';

export const initialState = {
  isLoading: false,
  fileDownloadInProgress: false,
  isStateUpdatePending: false,
  file: {
    isLoading: false,
    fileId: null,
    issueId: null,
    modelId: null,
    reasonId: null,
    solutionId: null,
    ownerProductId: null,
    fileReference: null,
    currentStatus: {},
    brandName: null,
    modelTypeName: null,
    modelName: null,
    issueName: null,
    modelTypeId: null,
    reasonName: null,
    solutionName: null,
    sellerId: null,
  },
  documents: {
    returnVoucherUrl: null,
    shippingLabelUrl: null,
    quotes: [],
    invoiceToPay: null,
  },
  claimConfirmationData: {},
  availableFileActions: [],
  fileStates: [],
  customerFileSummary: null,
  errors: {
    shippingLabelDownloadErrored: false,
    returnVoucherDownloadErrored: false,
    failToFetchAvailableFileAction: false,
  },
};

function isLoading(state) {
  const newState = {
    ...state,
    isLoading: true,
  };

  return newState;
}

function onStateUpdatePending(state) {
  return {
    ...state,
    isStateUpdatePending: true,
  };
}

function onStateUpdated(state) {
  return {
    ...state,
    isStateUpdatePending: false,
  };
}

function onLoadCustomerFileLoaded(state, action) {
  const {
    currentState,
    ownerProductId,
    fileReference,
    modelId,
    modelTypeId,
    soldWarrantyId,
    issueId,
    claimInfoSummary,
    sellerId,
    fileProducts,
    trackingNumbers,
  } = action.payload.value;

  const workingSellerId = sellerId || state.sellerId;
  const workingCurrentState = currentState?.currentState || currentState;

  return {
    ...state,
    isLoading: false,
    file: {
      ...state.file,
      ...(issueId && { issueId }), // Only add it if it's truthy
      isLoading: false,
      currentStatus: workingCurrentState,
      ownerProductId,
      fileReference,
      modelId,
      sellerId: workingSellerId,
      soldWarrantyId,
      modelTypeId,
      fileProducts,
      trackingNumbers,
      ...claimInfoSummary,
    },
  };
}

function onErroredCustomerFile(state) {
  return {
    ...state,
    isLoading: false,
    file: {
      ...state.file,
      isLoading: false,
      hasErrors: true,
    },
  };
}

function onCustomerFileSummaryLoaded(state, action) {
  const { value } = action.payload;

  return {
    ...state,
    customerFileSummary: {
      ...value,
    },
  };
}

function onModelInformationsLoaded(state, action) {
  const { id, brandName, modelTypeName, name } = action.payload.value;

  return {
    ...state,
    isLoading: false,
    file: {
      ...state.file,
      models: {
        ...get(state, ['file', 'models']),
        [id]: {
          brandName,
          modelTypeName,
          modelName: name,
        },
      },
      brandName,
      modelTypeName,
      modelName: name,
    },
  };
}

function onIrshTranslationsRecieved(state, action) {
  const { issueId, reasonId, solutionId, warrantyTypeId } = action.meta;
  const { issueName, reasonName, solutionName, warrantyTypeName } =
    action.payload.value;

  return {
    ...state,
    isLoading: false,
    file: {
      ...state.file,
      irshNames: {
        ...get(state, ['file', 'irshNames']),
        [issueId]: issueName,
        [reasonId]: reasonName,
        [solutionId]: solutionName,
        [warrantyTypeId]: warrantyTypeName,
      },
      issueName,
      solutionName,
      reasonName,
      warrantyTypeName,
    },
  };
}

function onGetFileStateHistoryLinesSuccess(state, action) {
  const fileStatusHistory = action.payload.value;

  const adaptedFileStatusHistoryLines = fileStatusHistory.map(
    (fileStatusHistoryLine) => {
      const { fileId, module, stateDate, state, comment, toCustomerComment } =
        fileStatusHistoryLine;

      return {
        fileId,
        module,
        fileStatusDate: stateDate,
        fileStatus: { ...state },
        comment,
        toCustomerComment,
      };
    },
  );

  const sortedActions = sortByDateAscending(adaptedFileStatusHistoryLines);

  const lastWorkflowStatus = sortedActions.find(
    (fileAction) =>
      fileAction.fileStatus.name === state.file.currentStatus.name,
  );

  return {
    ...state,
    isLoading: false,
    availableFileActions: sortedActions,
    errors: {
      ...state.errors,
      failToFetchAvailableFileAction: sortedActions.length === 0,
    },
    currentWorkflowModule: lastWorkflowStatus
      ? lastWorkflowStatus.module
      : null,
  };
}

function onClaimConfirmationScreenDataLoaded(state, action) {
  return {
    ...state,
    isLoading: false,
    claimConfirmationData: { ...action.payload.value },
  };
}

function onOpenFilesLoaded(state, action) {
  const { files } = action.payload.value;
  const openFiles = files.map((file) => file.currentState);

  return {
    ...state,
    isLoading: false,
    fileHistoric: {
      ...state.fileHistoric,
      openFiles,
    },
  };
}

function onLoadCustomerFile(state, action) {
  return {
    ...state,
    isLoading: true,
    file: {
      ...state.file,
      isLoading: true,
      fileId: action.payload.fileId,
    },
  };
}

function onDocumentLoading(state, value) {
  return {
    ...state,
    fileDownloadInProgress: value,
  };
}

function onGetShippingErrored(state) {
  return {
    ...state,
    errors: {
      ...state.errors,
      shippingLabelDownloadErrored: true,
    },
  };
}

function onGetShippingLabelSucceed(state, action) {
  const { url } = action.payload.value;

  return {
    ...state,
    documents: {
      ...state.documents,
      shippingLabelUrl: url,
    },
  };
}

function onGetQuoteDetailsRecieved(state, action) {
  const quotes = Formatters.mapQuotes(action.payload.value);

  return {
    ...state,
    isLoading: false,
    documents: {
      ...state.documents,
      quotes,
    },
  };
}

function onGetInvoiceToPayReceived(state, action) {
  const invoice = get(action, 'payload.value', null);

  return {
    ...state,
    isLoading: false,
    documents: {
      ...state.documents,
      invoiceToPay: formatInvoice(invoice),
    },
  };
}

function OnGetAvailableActionsErrored(state) {
  return {
    ...state,
    errors: {
      ...state.errors,
      failToFetchAvailableFileAction: true,
    },
  };
}

function onLoadWorkflowActionSuccess(state, action) {
  const availableWorkflowActions = action.payload.value || [];
  const oldAvailableFileActions = state.availableFileActions;

  const mappedFileActions = oldAvailableFileActions.map((action) => {
    const { definitionId, id, wave } = action.module;

    const workingWorkflowAction = availableWorkflowActions.find(
      (availableWorkflowAction) =>
        availableWorkflowAction.module.id === id &&
        availableWorkflowAction.module.definitionId === definitionId &&
        availableWorkflowAction.module.wave === wave,
    );

    if (workingWorkflowAction) {
      return {
        ...action,
        transitions: workingWorkflowAction.transitions,
      };
    }

    return { ...action, transitions: [] };
  });

  return {
    ...state,
    availableFileActions: mappedFileActions,
    file: {
      ...state.file,
      currentActions: availableWorkflowActions,
    },
  };
}

function onFileStatesLoaded(state, action) {
  return {
    ...state,
    fileStates: get(action, ['payload', 'value']) || [],
  };
}

function customerReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.LOAD_MODEL_INFORMATIONS.BASE:
    case ActionTypes.LOAD_IRSH_TRANSLATIONS.BASE:
    case ActionTypes.LOAD_FILE_STATE_HISTORY_LINES.BASE:
    case ActionTypes.LOAD_CLAIM_CONFIRMATION_SCREEN_DATA.BASE:
    case ActionTypes.LOAD_QUOTES_DATA.BASE:
    case ActionTypes.LOAD_INVOICE_DATA.BASE:
    case ActionTypes.LOAD_OPEN_FILES.BASE:
    case ActionTypes.LOAD_WORKFLOW_ACTIONS.BASE:
      return isLoading(state, action);

    case ActionTypes.GET_SHIPPING_LABEL.BASE:
      return onDocumentLoading(state, true);

    case ActionTypes.GET_SHIPPING_LABEL.END:
      return onDocumentLoading(state, false);

    case ActionTypes.LOAD_CUSTOMER_FILE.BASE:
      return onLoadCustomerFile(state, action);

    case ActionTypes.LOAD_CUSTOMER_FILE.ERRORED:
      return onErroredCustomerFile(state, action);

    case ActionTypes.LOAD_CUSTOMER_FILE.SUCCEEDED:
      return onLoadCustomerFileLoaded(state, action);

    case ActionTypes.LOAD_CUSTOMER_FILE_SUMMARY.SUCCEEDED:
      return onCustomerFileSummaryLoaded(state, action);

    case ActionTypes.LOAD_MODEL_INFORMATIONS.SUCCEEDED:
      return onModelInformationsLoaded(state, action);

    case ActionTypes.LOAD_IRSH_TRANSLATIONS.SUCCEEDED:
      return onIrshTranslationsRecieved(state, action);

    case ActionTypes.LOAD_FILE_STATE_HISTORY_LINES.SUCCEEDED:
      return onGetFileStateHistoryLinesSuccess(state, action);

    case ActionTypes.LOAD_CLAIM_CONFIRMATION_SCREEN_DATA.SUCCEEDED:
      return onClaimConfirmationScreenDataLoaded(state, action);

    case ActionTypes.LOAD_OPEN_FILES.SUCCEEDED:
      return onOpenFilesLoaded(state, action);
    case ActionTypes.GET_SHIPPING_LABEL.SUCCEEDED:
      return onGetShippingLabelSucceed(state, action);

    case ActionTypes.GET_SHIPPING_LABEL.ERRORED:
      return onGetShippingErrored(state, action);

    case ActionTypes.LOAD_QUOTES_DATA.SUCCEEDED:
      return onGetQuoteDetailsRecieved(state, action);

    case ActionTypes.LOAD_INVOICE_DATA.SUCCEEDED:
      return onGetInvoiceToPayReceived(state, action);

    case ActionTypes.LOAD_FILE_STATE_HISTORY_LINES.ERRORED:
      return OnGetAvailableActionsErrored(state, action);

    case ActionTypes.LOAD_WORKFLOW_ACTIONS.SUCCEEDED:
      return onLoadWorkflowActionSuccess(state, action);

    case ActionTypes.LOAD_FILE_STATES.SUCCEEDED:
      return onFileStatesLoaded(state, action);

    case ActionTypes.SUBMIT_WORKFLOW_ACTION.STARTED:
      return onStateUpdatePending(state);

    case ActionTypes.STATE_UPDATE_SUCCEEDED:
      return onStateUpdated(state);

    default:
      return state;
  }
}

export default customerReducer;
