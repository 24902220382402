import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect, useCallback, useState } from 'react';
import { useMutation } from 'react-query';
import { useForm } from 'react-hook-form';

import { CURRENCIES, REVALIDATE_MODES } from '@savgroup-front-common/constants';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { ClaimService } from 'myaccount/api';
import { useGetClaimGroupConfirmation } from 'myaccount/view/app/hooks/useGetClaimGroupConfirmation';
import { useGetSolutionsByClaim } from 'myaccount/view/app/hooks/useGetSolutionsByClaim';
import { STEP_CONFIG } from 'myaccount/view/app/NewLayout/ClassiqueRoutes.config';
import {
  StepContext,
  StepContextValues,
} from 'myaccount/view/app/NewLayout/StepsProvider/StepsProvider.context';

import ClaimGroupSolutionSchema from './ClaimGroupSolutionPage.schema';
import { ClaimGroupSolutionValues } from './ClaimGroupSolutionPage.types';
import { HandlingSummary } from '@savgroup-front-common/types';
import { ErrorFromBack } from '@savgroup-front-common/core/src/helpers';
import { SolutionSummaryDto } from 'myaccount/types/SolutionSummaryDto';
import { GetSolutionsByClaimGroupReturnValues } from '../../../api/Claim/getSolutionsByClaimGroup';

const useClaimGroupSolutionPage = () => {
  const { pushErrors, removeAllNotifications } = useToasts();
  const {
    values,
    handleUpdateValue,
    handleNextStep,
    handleValidateStep,
    form,
  } = useContext<StepContextValues>(StepContext);
  const [isSkipLoading, setIsSkipLoading] = useState(false);

  const claims = values?.claimGroup?.claims;
  const claimGroupId = values.claimGroup?.claimGroupId || '';

  const {
    mutateAsync: handleSolutionForClaim,
    isLoading: isLoadingSolutionForClaim,
  } = useMutation(
    ['setSolutionForClaim'],
    async ({
      claimId,
      solutionTypeId,
      solutionPrice,
    }: {
      claimId: string;
      solutionTypeId: string;
      solutionPrice?: { amount: string; currency: CURRENCIES };
    }) => {
      removeAllNotifications();
      const responseSetSolution = await ClaimService.setClaimSolutionCommand({
        claimId,
        solutionTypeId,
        solutionPrice,
      });

      if (responseSetSolution.failure) {
        pushErrors(responseSetSolution?.errors);

        return undefined;
      }

      return responseSetSolution;
    },
  );

  const { mutateAsync: handleGetHandling, isLoading: isLoadingGetHandling } =
    useMutation(
      ['getHandling'],
      async ({ claimGroupId }: { claimGroupId: string }) => {
        if (!claimGroupId) {
          return undefined;
        }

        removeAllNotifications();
        const responseHandling = await ClaimService.getHandlingByClaimGroup({
          claimGroupId,
        });

        if (!responseHandling || responseHandling.failure) {
          pushErrors(responseHandling?.errors as ErrorFromBack[]);

          return undefined;
        }

        return responseHandling.value;
      },
    );

  const handleSubmitToNextStep = useCallback(
    (solutionValue: SolutionSummaryDto, isSkipped?: boolean) => {
      if (solutionValue && claims) {
        return Promise.all(
          claims.map(async (claim) => {
            const responseSetSolution = await handleSolutionForClaim({
              claimId: claim.claimId,
              solutionTypeId: solutionValue?.solutionTypeId,
              solutionPrice: solutionValue?.price
                ? {
                    amount: solutionValue.price.toString(),
                    currency: solutionValue?.priceCurrencyCode as CURRENCIES,
                  }
                : undefined,
            });

            if (!responseSetSolution) {
              return undefined;
            }

            return responseSetSolution;
          }),
        )
          .then(async () => {
            const responseHandling = await handleGetHandling({
              claimGroupId,
            });

            if (!responseHandling) {
              return undefined;
            }

            const { hasHome, hasDeposit, hasDelivery } = <HandlingSummary>(
              responseHandling
            );

            handleUpdateValue({
              value: {
                handlingStatus: {
                  hasHome,
                  hasDeposit,
                  hasDelivery,
                },
              },
            });

            return responseHandling;
          })
          .then((responseHandling) => {
            const { hasDelivery, hasDeposit, hasHome } = responseHandling || {};

            if (hasHome || hasDeposit) {
              return handleNextStep(
                STEP_CONFIG.CLAIM_GROUP_DEPOSIT,
                claimGroupId,
                {
                  solutionPageIsSkipped: isSkipped,
                },
              );
            }
            if (hasDelivery) {
              return handleNextStep(
                STEP_CONFIG.CLAIM_GROUP_DELIVERY,
                claimGroupId,
                {
                  solutionPageIsSkipped: isSkipped,
                },
              );
            }

            return handleNextStep(
              STEP_CONFIG.CLAIM_GROUP_CONFIRMATION,
              claimGroupId,
              {
                solutionPageIsSkipped: isSkipped,
              },
            );
          })
          .catch((err) => {
            pushErrors(err);

            return undefined;
          });
      }

      return undefined;
    },
    [
      claimGroupId,
      claims,
      handleGetHandling,
      handleNextStep,
      handleSolutionForClaim,
      handleUpdateValue,
      pushErrors,
    ],
  );

  const handleSuccessGetSolutions = async (
    response?: GetSolutionsByClaimGroupReturnValues,
  ) => {
    const solutions = response?.solutions;

    if (solutions && solutions.length <= 1 && !form?.solutionPageIsSkipped) {
      const solutionValue = solutions.at(0);

      setIsSkipLoading(true);

      if (solutionValue && claims) {
        return Promise.all(
          claims.map(async (claim) => {
            const responseSetSolution = await handleSolutionForClaim({
              claimId: claim.claimId,
              solutionTypeId: solutionValue?.solutionTypeId,
              solutionPrice: solutionValue?.price
                ? {
                    amount: solutionValue.price.toString(),
                    currency: solutionValue?.priceCurrencyCode as CURRENCIES,
                  }
                : undefined,
            });

            if (!responseSetSolution) {
              return undefined;
            }

            return responseSetSolution;
          }),
        )
          .then(async () => {
            const responseHandling = await handleGetHandling({
              claimGroupId,
            });

            if (!responseHandling) {
              return undefined;
            }

            const { hasHome, hasDeposit, hasDelivery } = <HandlingSummary>(
              responseHandling
            );

            handleUpdateValue({
              value: {
                handlingStatus: {
                  hasHome,
                  hasDeposit,
                  hasDelivery,
                },
              },
            });

            return responseHandling;
          })
          .then((responseHandling) => {
            const { hasDelivery, hasDeposit, hasHome } = responseHandling || {};

            if (hasHome || hasDeposit) {
              return handleNextStep(
                STEP_CONFIG.CLAIM_GROUP_DEPOSIT,
                claimGroupId,
                {
                  solutionPageIsSkipped: true,
                },
              );
            }
            if (hasDelivery) {
              return handleNextStep(
                STEP_CONFIG.CLAIM_GROUP_DELIVERY,
                claimGroupId,
                {
                  solutionPageIsSkipped: true,
                },
              );
            }

            return handleNextStep(
              STEP_CONFIG.CLAIM_GROUP_CONFIRMATION,
              claimGroupId,
              {
                solutionPageIsSkipped: true,
              },
            );
          })
          .catch((err) => {
            pushErrors(err);

            setIsSkipLoading(false);

            return undefined;
          });
      }

      return undefined;
    }

    return undefined;
  };

  const { solutions, solutionsCount, isSolutionsLoading } =
    useGetSolutionsByClaim({
      claimGroupId,
      onSuccess: handleSuccessGetSolutions,
    });

  const { claimGroupConfirmation } = useGetClaimGroupConfirmation({
    claimGroupId,
  });

  const formContext = useForm<ClaimGroupSolutionValues>({
    resolver: yupResolver(ClaimGroupSolutionSchema),
    defaultValues: {
      chooseSolution: claims?.at(0)?.solutionTypeId,
    },
    mode: REVALIDATE_MODES.ON_CHANGE,
  });

  const {
    formState: { isValid },
  } = formContext;

  const onSubmit = useCallback(
    async ({ chooseSolution }: ClaimGroupSolutionValues) => {
      const solutionValue = solutions?.find(
        (solution) => solution.solutionTypeId === chooseSolution,
      );

      if (solutionValue && claims) {
        removeAllNotifications();

        return handleSubmitToNextStep(solutionValue);
      }

      return undefined;
    },
    [claims, handleSubmitToNextStep, removeAllNotifications, solutions],
  );

  useEffect(() => {
    handleValidateStep(!!isValid);
  }, [handleValidateStep, isValid]);

  return {
    solutions,
    solutionsCount,
    isSolutionsLoading,
    claimGroupConfirmation,
    formContext,
    onSubmit,
    isLoadingSubmit: isLoadingSolutionForClaim || isLoadingGetHandling,
    isSkipLoading,
  };
};

export default useClaimGroupSolutionPage;
