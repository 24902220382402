import {
  APP_ENVS,
  currentAppEnvironment,
} from '@savgroup-front-common/constants/src/shared';

import hotjarConfigDEFAULT from './hotjar.config.DEFAULT';
import hotjarConfigDEMO from './hotjar.config.DEMO';
import hotjarConfigDEV from './hotjar.config.DEV';
import hotjarConfigLOCAL from './hotjar.config.LOCAL';
import hotjarConfigPROD from './hotjar.config.PROD';
import {
  HotjarConfigurationDisabled,
  HotjarConfigurationEnabled,
} from '@savgroup-front-common/core/src/components/HotjarProvider/HotjarProvider';

const HOTJAR_CONFIG = (): HotjarConfigurationEnabled | HotjarConfigurationDisabled => {
  switch (currentAppEnvironment) {
    case APP_ENVS.DEMO:
      return hotjarConfigDEMO;
    case APP_ENVS.DEV:
      return hotjarConfigDEV;
    case APP_ENVS.LOCAL:
      return hotjarConfigLOCAL;
    case APP_ENVS.PROD:
      return hotjarConfigPROD;
    case APP_ENVS.DEFAULT:
    default:
      return hotjarConfigDEFAULT;
  }
};

export default HOTJAR_CONFIG();
