import { ClaimGroupConfirmation } from 'myaccount/api/Claim/getClaimGroupConfirmationQuery';
import { useGetOrderByOwnerId } from 'myaccount/view/app/hooks/useGetOrderByOwnerId';
import { useGetOrderDetailsQuery } from 'myaccount/view/app/hooks/useGetOrderDetailsQuery';
import { useGetSolutionsByClaim } from 'myaccount/view/app/hooks/useGetSolutionsByClaim';
import { useLayout } from 'myaccount/view/app/NewLayout/NewLayout.hooks';

export const useResumeCard = ({
  claimGroupConfirmation,
}: {
  claimGroupConfirmation?: ClaimGroupConfirmation;
}) => {
  const claims = claimGroupConfirmation?.claims;

  const { ownerId } = useLayout();
  const { orders } = useGetOrderByOwnerId({ ownerId });

  const orderSummary = orders?.find(
    (order) => order.reference === claimGroupConfirmation?.orderReference,
  );

  const { orderDetail } = useGetOrderDetailsQuery({
    orderId: orderSummary?.orderId,
  });

  const products = orderDetail?.products;

  const { solutions } = useGetSolutionsByClaim({
    claimGroupId: claimGroupConfirmation?.claimGroupId,
  });

  return {
    products,
    claims: claims || [],
    solution: solutions?.find(
      (solution) =>
        solution.solutionGroupId === claimGroupConfirmation?.solutionGroupId,
    ),
  };
};
