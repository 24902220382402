import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'components.myaccount.myReturn.additionalInfo.header',
    defaultMessage: 'Additional info',
  },
  subHeader: {
    id: 'components.myaccount.myReturn.additionalInfo.subHeader',
    defaultMessage:
      'In order to validate your request we need additional images or video of your device.',
  },
  comment: {
    id: 'components.myaccount.claims.reasons.choice.comment',
    defaultMessage: 'Comment',
  },
  fileRequired: {
    id: 'global.form.invalid.fileRequired',
    defaultMessage: 'File is required',
  },
  documentNeededCommentTitle: {
    id: 'components.myaccount.myReturn.DocumentsNeeded.documentNeededCommentTitle',
    defaultMessage: 'Document needed comment title',
  },
});
