import { useQuery } from 'react-query';

import { CustomerFileSummary } from '@savgroup-front-common/types';
import { WorkflowService } from 'myaccount/api';

export const GetMultipleCustomerFileFullSummaryQuery =
  'getMultipleCustomerFileFullSummaryQuery';

export const useGetMultipleCustomerFileFullSummaryQuery = ({
  fileIds,
}: {
  fileIds?: string[];
}) => {
  const { data: multiProductsInfo, refetch } = useQuery(
    [GetMultipleCustomerFileFullSummaryQuery],
    async () => {
      if (!fileIds || fileIds.length === 0) {
        return undefined;
      }

      return Promise.all(
        fileIds?.map(async (fileId) => {
          const response =
            await WorkflowService.getCustomerFileFullSummaryQuery({
              fileId,
            });

          if (response.failure) {
            return undefined;
          }

          return response.value;
        }),
      ).then(
        (value) =>
          value.filter((fileSummary) => fileSummary) as CustomerFileSummary[],
      );
    },
    {
      staleTime: Infinity,
      suspense: true,
    },
  );

  return {
    multiProductsInfo: multiProductsInfo?.filter(
      (productsInfo) => productsInfo !== undefined,
    ),
    refetchFileSummary: refetch,
  };
};
