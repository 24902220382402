import { useQuery } from 'react-query';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { ClaimService } from 'myaccount/api';
import { IssueSummaryDto } from 'myaccount/types/IssueSummaryDto';

const GetIssues = 'getIssues';

interface UseGetIssuesByOrderArgs {
  orderId?: string;
  onSuccess?: (values: IssueSummaryDto[]) => void;
}

const useGetIssuesByOrder = ({
  orderId,
  onSuccess = undefined,
}: UseGetIssuesByOrderArgs) => {
  const { pushErrors, removeAllNotifications } = useToasts();

  const { data: issues, isLoading } = useQuery(
    [GetIssues, { orderId }],
    async () => {
      if (!orderId) {
        return undefined;
      }
      removeAllNotifications();

      const response = await ClaimService.getIssuesByOrder({
        orderId,
      });

      if (response.failure) {
        pushErrors(response.errors);

        return undefined;
      }

      return response.value;
    },
    {
      enabled: !!orderId,
      onSuccess,
    },
  );

  return {
    issues,
    issuesCount: issues?.length || 0,
    isIssuesLoading: isLoading,
  };
};

export default useGetIssuesByOrder;
