import { add, format } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getTechnicalInterventionsByFileId } from '@savgroup-front-common/core/src/domains/technicalInterventions/actionCreators';
import { selectTechnicalInterventionsByFileId } from '@savgroup-front-common/core/src/domains/technicalInterventions/selectors';
import { safeFormattedIntlString } from '@savgroup-front-common/core/src/formatters';
import { useGetSellerConfiguration } from '@savgroup-front-common/core/src/hooks';
import { useBanners } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import {
  SALESFORCE_INTEGRATION_TYPE,
  TechnicalInterventionStatus,
} from '@savgroup-front-common/types';

import { WorkflowService } from '../../../../../../api';
import { useMyAccountTypedSelector } from '../../../../../../hooks';
import { useFileFollowupsContext } from '../../FileFollowups.context';

import { reformatDate } from './ConfirmedTechnicalInterventionSchedule.formatters';
import messages from './messages';

export const useConfirmedTechnicalInterventionSchedule = ({
  fileId,
}: {
  fileId: string;
}) => {
  const { fileSummary } = useFileFollowupsContext();

  const dispatch = useDispatch();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isRescheduleModalOpen, setIsRescheduleModalOpen] = useState(false);
  const [
    isCancelTechnicalInterventionLoading,
    setIsCancelTechnicalInterventionLoading,
  ] = useState(false);

  const { pushErrors, removeAllNotifications } = useBanners({
    extendedMessages: messages,
  });

  useEffect(() => {
    if (fileId) {
      dispatch(getTechnicalInterventionsByFileId({ fileId }));
    }
  }, [dispatch, fileId]);

  const latestTechnicalIntervention = useMyAccountTypedSelector((store) =>
    selectTechnicalInterventionsByFileId(store, { fileId }),
  );
  const handleCancelTechnicalInterventionSchedule = useCallback(async () => {
    removeAllNotifications();
    setIsCancelTechnicalInterventionLoading(true);

    const response = await WorkflowService.cancelTechnicalIntervention({
      fileId,
    });

    if (response.failure) {
      setIsCancelTechnicalInterventionLoading(false);
      pushErrors(response.errors);
    }

    setIsConfirmModalOpen(false);
  }, [fileId, pushErrors, removeAllNotifications]);

  const { sellerConfiguration } = useGetSellerConfiguration({
    sellerId: fileSummary?.sellerId,
  });

  if (
    fileSummary &&
    sellerConfiguration?.salesforceFieldServiceLightningIntegrationType ===
      SALESFORCE_INTEGRATION_TYPE.Integration
  ) {
    const fakeStartDate = add(new Date(), { days: 1 });
    const fakeEndDate = add(new Date(), { days: 1 });

    fakeStartDate.setHours(10);
    fakeStartDate.setMinutes(0);

    fakeEndDate.setHours(11);
    fakeEndDate.setMinutes(0);

    return {
      setIsRescheduleModalOpen,
      isConfirmModalOpen,
      isRescheduleModalOpen,
      confirmedScheduleText: safeFormattedIntlString(
        messages.technicalInterventionAppointmentValue,
        {
          startDate: format(fakeStartDate, 'dd/MM/yyyy'),
          startTime: format(fakeStartDate, 'HH:mm'),
          endTime: format(fakeEndDate, 'HH:mm'),
        },
      ),
      latestTechnicalIntervention: {
        startTimeUtc: format(fakeStartDate, 'HH:mm'),
        endTimeUtc: format(fakeEndDate, 'HH:mm'),
        status: TechnicalInterventionStatus.SCHEDULED,
        createdDate: '',
        isReSchedulable: true,
      },
      handleCancelTechnicalInterventionSchedule,
      isCancelTechnicalInterventionLoading,
      setIsConfirmModalOpen,
    };
  }

  if (!latestTechnicalIntervention) {
    return {
      setIsRescheduleModalOpen,
      isConfirmModalOpen,
      isRescheduleModalOpen,
      latestTechnicalIntervention,
      handleCancelTechnicalInterventionSchedule,
      isCancelTechnicalInterventionLoading,
      setIsConfirmModalOpen,
    };
  }

  const startDate = reformatDate({
    date: latestTechnicalIntervention.startTimeUtc,
    integrationType:
      sellerConfiguration?.salesforceFieldServiceLightningIntegrationType,
    destinationFormat: 'dd/MM/yyyy',
  });
  const startTimeUtc = reformatDate({
    date: latestTechnicalIntervention.startTimeUtc,
    integrationType:
      sellerConfiguration?.salesforceFieldServiceLightningIntegrationType,
    destinationFormat: 'HH:mm',
  });

  const endTimeUtc = reformatDate({
    date: latestTechnicalIntervention.endTimeUtc,
    integrationType:
      sellerConfiguration?.salesforceFieldServiceLightningIntegrationType,
    destinationFormat: 'HH:mm',
  });

  const confirmedScheduleText = safeFormattedIntlString(
    messages.technicalInterventionAppointmentValue,
    {
      startDate,
      startTime: startTimeUtc,
      endTime: endTimeUtc,
    },
  );

  return {
    setIsRescheduleModalOpen,
    isConfirmModalOpen,
    isRescheduleModalOpen,
    confirmedScheduleText,
    latestTechnicalIntervention,
    handleCancelTechnicalInterventionSchedule,
    isCancelTechnicalInterventionLoading,
    setIsConfirmModalOpen,
  };
};
