import { currentAppEnvironment } from '@savgroup-front-common/constants/src/shared';

import { delay } from './delay';

function clearOidcStaleState(userManager) {
  return (
    userManager.current
      .clearStaleState()
      // eslint-disable-next-line no-console
      .catch((err) => console.log('clearOidcStaleState', err))
  );
}

function css(color) {
  return `background: ${color}; border-radius: 2px; padding: 3px 8px; color: white;`;
}

function setupRetryStrategyForSilentRenewal(userManager) {
  const RETRY_DELAY = 60 * 1000;

  let retryTask;

  userManager.current.events.addSilentRenewError((e) => {
    // eslint-disable-next-line no-console
    console.warn('silent renew error', e.message);
    if (retryTask !== undefined) {
      clearTimeout(retryTask);
    }
    userManager.current.stopSilentRenew();
    retryTask = window.setTimeout(
      () => userManager.current.startSilentRenew(),
      RETRY_DELAY,
    );
  });
}

export const appShell = async ({ userManager, render }) => {
  if (window.location.pathname === '/silent-renew') {
    await userManager.current.getUser();
    userManager.current.signinSilentCallback();

    return;
    // const silentRenew = getQueryValue(window.location.search, 'silentRenew');
  }
  setupRetryStrategyForSilentRenewal(userManager);
  await clearOidcStaleState(userManager);
  await Promise.all([
    (async function frequentlyClearStaleState() {
       
      while (true) {
        await delay(30 * 1000);
        await clearOidcStaleState(userManager);
      }
    })(),
    (async function app() {
      // eslint-disable-next-line no-console
      if (console.groupCollapsed) {
        // eslint-disable-next-line no-console
        console.groupCollapsed('App info');
      }
      // eslint-disable-next-line no-console
      console.info(
        '%cBuild mode',
        css('green'),
        `${currentAppEnvironment} with ${
          import.meta.env.VITE_API_MODE || 'direct access to apis'
        }`,
      );
      // eslint-disable-next-line no-console
      console.info(
        '%cBuild Id',
        css('blue'),
        `${import.meta.env.VITE_BUILD_ID}`,
      );
       
      if (import.meta.env.VITE_BUILD_NUMBER) {
        // eslint-disable-next-line no-console
        console.info(
          '%cBuild Number',
          css('red'),
          `${import.meta.env.VITE_BUILD_NUMBER}`,
        );
      }

      if (import.meta.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.warn(
          "Messages that start with '[React Intl] Missing message:' have been disabled. See index.js to enable them.",
        );
        // eslint-disable-next-line no-console
        const originalConsoleError = console.error;

        // eslint-disable-next-line no-console
        if (console.error === originalConsoleError) {
          // eslint-disable-next-line no-console
          console.error = (...args) => {
            if (args && args.length && typeof args[0].indexOf !== 'function') {
              originalConsoleError.call(console, ...args);

              return;
            }

            if (args[0].indexOf('[React Intl] Missing message:') === 0) {
              return;
            }
            if (
              args[0].indexOf('[@formatjs/intl Error MISSING_TRANSLATION]') ===
              0
            ) {
              return;
            }
            if (
              args[0].indexOf(
                'Warning: Unknown event handler property `onHide`. It will be ignored.',
              ) === 0
            ) {
              return;
            }
            originalConsoleError.call(console, ...args);
          };
        }
      }

      // eslint-disable-next-line no-console
      if (console.group) {
        // eslint-disable-next-line no-console
        console.groupEnd();
      }

      await render();
    })(),
  ]);
};
