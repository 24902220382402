import { Interval } from '../AppointmentCard.types';

export const prepareAppointmentTimeToCreateHomePickupCommand = ({
  appointmentTime,
}: {
  appointmentTime: Interval;
}) => {
  return {
    endTimeInLocalRecipientTimezone: new Date(
      appointmentTime.endLocalTime.getTime() -
        appointmentTime.endLocalTime.getTimezoneOffset() * 60_000,
    ).toISOString(),
    startTimeInLocalRecipientTimezone: new Date(
      appointmentTime.startLocalTime.getTime() -
        appointmentTime.startLocalTime.getTimezoneOffset() * 60_000,
    ).toISOString(),
  };
};
