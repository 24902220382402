import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { BackResponseFailure } from '@savgroup-front-common/types';

import { prepareResponseFailure } from '../../helpers';
import { apiCall } from '../../services';

export interface DownloadAttachmentArgs {
  endpoint: string;
}

async function downloadAttachment({
  endpoint,
}: DownloadAttachmentArgs): Promise<File | BackResponseFailure> {
  try {
    return apiCall<File>(endpoint, {
      responseType: 'blob',
      method: SUPPORTED_METHODS.GET,
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default downloadAttachment;
