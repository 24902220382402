import { defineMessages } from 'react-intl';

export default defineMessages({
  information: {
    id: 'components.common.addressManagement.addressForm.information',
    defaultMessage: 'Information',
  },
  label: {
    id: 'components.common.addressManagement.addressForm.label',
    defaultMessage: 'Donner un nom à cette adresse',
  },
  labelPlaceHolder: {
    id: 'components.common.addressManagement.addressForm.labelPlaceHolder',
    defaultMessage: 'Ici vous pouvez donner un nom à votre adresse',
  },
  firstName: {
    id: 'components.common.addressManagement.addressForm.firstName',
    defaultMessage: 'Prénom',
  },
  lastName: {
    id: 'components.common.addressManagement.addressForm.lastName',
    defaultMessage: 'Nom',
  },
  company: {
    id: 'components.common.addressManagement.addressForm.company',
    defaultMessage: 'Société',
  },
  address: {
    id: 'components.common.addressManagement.addressForm.address',
    defaultMessage: 'Address',
  },
  additionalAddress: {
    id: 'components.common.addressManagement.addressForm.additionalAddress',
    defaultMessage: "Complément d'adresse",
  },
  postalCode: {
    id: 'components.common.addressManagement.addressForm.postalCode',
    defaultMessage: 'Code Postal',
  },
  city: {
    id: 'components.common.addressManagement.addressForm.city',
    defaultMessage: 'Ville',
  },
  country: {
    id: 'components.common.addressManagement.addressForm.country',
    defaultMessage: 'Pays',
  },
  complementary: {
    id: 'components.common.addressManagement.addressForm.complementary',
    defaultMessage: 'Complementary',
  },
  additionalInformation: {
    id: 'components.common.addressManagement.addressForm.additionalInformation',
    defaultMessage: 'Informations pour la livraison',
  },
  additionalInformationsPlaceHolder: {
    id: 'components.common.addressManagement.addressForm.additionalInformationsPlaceHolder',
    defaultMessage:
      'Toute information utilie pour la livraison : étage, code de la porte, ...',
  },
  save: {
    id: 'components.common.addressManagement.addressForm.save',
    defaultMessage: 'Sauvegarder',
  },
  civility: {
    id: 'components.common.addressManagement.addressForm.civility',
    defaultMessage: 'Civilité',
  },
  mister: {
    id: 'components.common.addressManagement.addressForm.mister',
    defaultMessage: 'M.',
  },
  madam: {
    id: 'components.common.addressManagement.addressForm.madam',
    defaultMessage: 'Mme',
  },
  other: {
    id: 'components.common.addressManagement.addressForm.other',
    defaultMessage: 'Autre',
  },
  phone: {
    id: 'components.common.addressManagement.addressForm.phone',
    defaultMessage: 'Téléphone',
  },
  mail: {
    id: 'components.common.addressManagement.addressForm.mail',
    defaultMessage: 'Mail',
  },
  companyName: {
    id: 'components.common.addressManagement.addressForm.companyName',
    defaultMessage: 'Raison sociale',
  },
  setAsDefaultAddress: {
    id: 'components.common.addressManagement.addressForm.setAsDefaultAddress',
    defaultMessage: 'adresse par défault',
  },
  requiredField: {
    id: 'components.common.addressManagement.addressForm.requiredField',
    defaultMessage: 'Ce champs est requis',
  },
  succeed: {
    id: 'components.common.addressManagement.addressForm.succeed',
    defaultMessage: "L'adresse a bien été enregistrée",
  },
  somethingWrong: {
    id: 'components.common.addressManagement.addressForm.somethingWrong',
    defaultMessage: "Quelque chose s'est mal passé. Veuillez réessayer",
  },
  perfect: {
    id: 'components.common.addressManagement.addressForm.perfect',
    defaultMessage: 'Parfait! ',
  },
  addressUpdated: {
    id: 'components.common.addressManagement.addressForm.addressUpdated',
    defaultMessage: "L'adresse à bien été mise à jour",
  },
  addressCreated: {
    id: 'components.common.addressManagement.addressForm.addressCreated',
    defaultMessage: "L'adresse à bien été crée",
  },
  badFormatError: {
    id: 'components.common.addressManagement.addressForm.badFormatError',
    defaultMessage:
      'Ce champs ne peut pas contenir de caractères spéciaux (@,€,#,...)',
  },
  phoneFormatError: {
    id: 'components.common.addressManagement.addressForm.phoneFormatError',
    defaultMessage: 'Phone number formatted incorrectly',
  },
  canOnlyContainLetters: {
    id: 'components.common.addressManagement.addressForm.canOnlyContainLetters',
    defaultMessage: 'Ce champs ne peut que contenir des lettres [Aa-Zz]',
  },
  maxLengthError: {
    id: 'components.common.addressManagement.addressForm.maxLengthError',
    defaultMessage: 'Ce champs est trop long :',
  },
  remainingChars: {
    id: 'components.common.addressManagement.addressForm.remainingChars',
    defaultMessage: 'caractères restants :',
  },
  cancel: {
    id: 'components.common.addressManagement.cancel',
    defaultMessage: 'Annuler',
  },
  back: {
    id: 'components.common.addressManagement.addressForm.back',
    defaultMessage: 'Back',
  },
  chooseOption: {
    id: 'components.common.addressManagement.addressForm.chooseAnOption',
    defaultMessage: 'Choose an option',
  },
  next: {
    id: 'components.myaccount.claims.handling.next',
    defaultMessage: 'Suivant',
  },
  proceedAnyway: {
    id: 'components.myaccount.claims.handling.proceedAnyway',
    defaultMessage: 'Proceed anyway',
  },
  addressHasErrors: {
    id: 'components.myaccount.claims.handling.addressHasErrors',
    defaultMessage:
      'Addres validation has failed. Please check it again. If everything is correct press "Proceed anyway"',
  },
  'E.ADDRESS.NOT_FOUND': {
    id: 'components.myaccount.claims.handling.addressNotFound',
    defaultMessage: 'Address not found',
  },
  'E.HOUSE_NUMBER.INVALID': {
    id: 'components.myaccount.claims.handling.houseNumberInvalid',
    defaultMessage: 'House number is invalid',
  },
  genericVerificationError: {
    id: 'components.myaccount.claims.handling.genericVerificationError',
    defaultMessage:
      'Failed to validate the address. Please contact your customer support or try again later',
  },
  'E.HOUSE_NUMBER.MISSING': {
    id: 'components.myaccount.claims.handling.missingHouseNumber',
    defaultMessage: 'House number is missing',
  },
  'E.STREET.MISSING': {
    id: 'components.myaccount.claims.handling.streetMissing',
    defaultMessage: 'Street is missing',
  },
  'E.STREET.INVALID': {
    id: 'components.myaccount.claims.handling.streetInvalid',
    defaultMessage: 'Street is invalid',
  },
  'E.ADDRESS.INVALID': {
    id: 'components.myaccount.claims.handling.addressInvalid',
    defaultMessage: 'Invalid city/state/ZIP',
  },
  'E.ZIP.NOT_FOUND': {
    id: 'components.myaccount.claims.handling.zipNotFound',
    defaultMessage: 'Zip not found',
  },
  'E.ZIP.INVALID': {
    id: 'components.myaccount.claims.handling.zipInvalid',
    defaultMessage: 'Zip invalid',
  },
  'E.ADDRESS.MULTIPLE': {
    id: 'components.myaccount.claims.handling.addressMultiple',
    defaultMessage: 'Multiple addresses were returned with the same zip',
  },
  'E.ADDRESS.INSUFFICIENT': {
    id: 'components.myaccount.claims.handling.addressInsufficent',
    defaultMessage: 'Insufficient/incorrect address data',
  },
  'E.CITY_STATE.INVALID': {
    id: 'components.myaccount.claims.handling.cityStateInvalid',
    defaultMessage: 'Unverifiable city / state',
  },
  'E.ADDRESS.DELIVERY.INVALID': {
    id: 'components.myaccount.claims.handling.addressDeliveryInvalid',
    defaultMessage: 'Invalid delivery address',
  },
  'E.STATE.INVALID': {
    id: 'components.myaccount.claims.handling.stateInvalid',
    defaultMessage: 'Invalid State',
  },
  suggestionNotification: {
    id: 'components.myaccount.claims.handling.suggestionNotification',
    defaultMessage:
      'Entered address may be incorrect. You can set suggested address.',
  },
  setSuggestedAddress: {
    id: 'components.myaccount.claims.handling.setSuggestedAddress',
    defaultMessage: 'Set suggested address',
  },
  suggestedAddress: {
    id: 'components.myaccount.claims.handling.suggestedAddress',
    defaultMessage: 'Suggested address',
  },
  deliveryAddress: {
    id: 'components.myaccount.claims.handling.addressEntered',
    defaultMessage: 'Address entered',
  },
  editAddress: {
    id: 'components.myaccount.claims.confirmation.edit',
    defaultMessage: 'Edit',
  },
  elevator: {
    id: 'components.myaccount.claims.handling.elevator',
    defaultMessage: 'Elevator',
  },
  parking: {
    id: 'components.myaccount.claims.handling.parking',
    defaultMessage: 'Parking',
  },
  habitation: {
    id: 'components.myaccount.claims.handling.habitation',
    defaultMessage: 'Habitation',
  },
  housing: {
    id: 'components.myaccount.claims.handling.housing',
    defaultMessage: 'Housing',
  },
  floor: {
    id: 'components.myaccount.claims.handling.floor',
    defaultMessage: 'Floor',
  },
  intercom: {
    id: 'components.myaccount.claims.handling.intercom',
    defaultMessage: 'Intercom',
  },
  flat: {
    id: 'components.myaccount.claims.handling.flat',
    defaultMessage: 'Flat',
  },
  house: {
    id: 'components.myaccount.claims.handling.house',
    defaultMessage: 'House',
  },
  additionalInfo: {
    id: 'view.DeliveryManagement.additionalInfo',
    defaultMessage: 'Additional info',
  },
  addressEntered: {
    id: 'components.myaccount.claims.handling.addressEntered',
    defaultMessage: 'Address entered',
  },
});
