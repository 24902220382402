import React, { FC, Suspense } from 'react';

import { COUNTRY_CODES } from '@savgroup-front-common/constants/src/shared';
import { CARRIERS } from '@savgroup-front-common/types';

import { BaseLoader } from '../BaseLoader';

import { AppointmentCardContent } from './AppointmentCardContent';

interface AppointmentCardProps {
  fileId: string;
  productLocationCountryCode: COUNTRY_CODES;
  carrier: CARRIERS;
  onSubmit?: () => void;
  onCancel?: () => void;
  onPreviousStepClick?: () => void;
}

const AppointmentCard: FC<AppointmentCardProps> = ({
  fileId,
  productLocationCountryCode,
  carrier,
  onSubmit,
                                                     onPreviousStepClick,
  onCancel,
}) => {
  return (
    <Suspense fallback={<BaseLoader />}>
      <AppointmentCardContent
        carrier={carrier}
        fileId={fileId}
        countryCode={productLocationCountryCode}
        onSubmit={onSubmit}
        onPreviousStepClick={onPreviousStepClick}
        onCancel={onCancel}
      />
    </Suspense>
  );
};

AppointmentCard.displayName = 'AppointmentCard';

export default AppointmentCard;
