import { ActionTypeIsNotSupportedError } from '@savgroup-front-common/exceptions';
import { Timeslot } from '@savgroup-front-common/types';

import { ErrorFromBack } from '../../../../helpers';

import { INTERVENTION_APPOINTMENT_ACTION_TYPES } from './TakeInterventionAppointmentLegacy.types';

export interface InterventionAppointmentState {
  hasErrors: boolean;
  errors: ErrorFromBack[] | Error[];
  selectedTimeslot?: Timeslot;
  pageNumber: number;
}

export const interventionAppointmentInit =
  (): InterventionAppointmentState => ({
    pageNumber: 1,
    hasErrors: false,
    errors: [],
  });

export type InterventionAppointmentAction =
  | {
      type: INTERVENTION_APPOINTMENT_ACTION_TYPES.VALIDATE_TIMESLOT;
    }
  | {
      type: INTERVENTION_APPOINTMENT_ACTION_TYPES.RESET_CALENDAR;
    }
  | {
      type: INTERVENTION_APPOINTMENT_ACTION_TYPES.SET_ERRORS;
      payload: { errors: ErrorFromBack[] | Error[] };
    }
  | {
      type: INTERVENTION_APPOINTMENT_ACTION_TYPES.SELECT_TIMESLOT;
      payload: { selectedTimeslot: Timeslot };
    }
  | {
      type: INTERVENTION_APPOINTMENT_ACTION_TYPES.PAGE_NUMBER_CHANGE;
      payload: { newPageNumber: number };
    };

export const takeInterventionAppointmentLegacyReducer = (
  state: InterventionAppointmentState,
  action: InterventionAppointmentAction,
): InterventionAppointmentState => {
  switch (action.type) {
    case INTERVENTION_APPOINTMENT_ACTION_TYPES.SET_ERRORS: {
      return {
        ...state,
        hasErrors: true,
        errors: action.payload.errors,
      };
    }
    case INTERVENTION_APPOINTMENT_ACTION_TYPES.VALIDATE_TIMESLOT: {
      return {
        ...state,
        hasErrors: false,
        errors: [],
      };
    }
    case INTERVENTION_APPOINTMENT_ACTION_TYPES.SELECT_TIMESLOT: {
      return {
        ...state,
        selectedTimeslot: action.payload.selectedTimeslot,
      };
    }
    case INTERVENTION_APPOINTMENT_ACTION_TYPES.PAGE_NUMBER_CHANGE: {
      return {
        ...state,
        selectedTimeslot: undefined,
        pageNumber: action.payload.newPageNumber,
      };
    }
    case INTERVENTION_APPOINTMENT_ACTION_TYPES.RESET_CALENDAR: {
      return interventionAppointmentInit();
    }

    default: {
      throw new ActionTypeIsNotSupportedError();
    }
  }
};
