import get from 'lodash/get';
import { useDispatch } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';

import { DOCUMENT_STATUS } from '@savgroup-front-common/constants/src/shared';

import { loadFullClaimsByGroupId } from '../../../../../domains/claims/actionCreators';

interface UseLoadFullClaimInfoArgs {
  claimGroupId?: string;
  type: string;
  getDocumentStatusByFileIdValue?: Record<string, string>;
}

const useLoadFullClaimInfo = ({
  getDocumentStatusByFileIdValue,
  type,
  claimGroupId,
}: UseLoadFullClaimInfoArgs): void => {
  const dispatch = useDispatch();
  const documentStatus = get(getDocumentStatusByFileIdValue, type);

  useDeepCompareEffect(() => {
    if (documentStatus === DOCUMENT_STATUS.CREATED && claimGroupId) {
      dispatch(
        loadFullClaimsByGroupId({
          claimGroupId,
          forceRefresh: true,
        }),
      );
    }
  }, [documentStatus, dispatch, claimGroupId]);
};

export default useLoadFullClaimInfo;
