import validator from 'validator';
import * as Yup from 'yup';

import { globalMessages } from '../../helpers';

import messages from './messages';

export const collectBankAccountDetailsFormSchema = Yup.object().shape({
  accountHolder: Yup.string().required(globalMessages.form.required),
  bankIdentificationCode: Yup.string()
    .required(globalMessages.form.required)
    .test(
      'validBankIdentificationCode',
      messages.bankIdentificationCodeInvalid,
      function test(value) {
        if (value) {
          return validator.isBIC(value);
        }

        return true;
      },
    ),
  iban: Yup.string()
    .required(globalMessages.form.required)
    .test('validIban', messages.ibanInvalid, function test(value) {
      if (value) {
        return validator.isIBAN(value);
      }

      return true;
    }),
  bankName: Yup.string().required(globalMessages.form.required),
});
