import React, { FC } from 'react';

import Card from '../../../atoms/Cards/Card';
import { SafeFormattedMessageWithoutSpread } from '../../../formatters/SafeFormattedMessageWithoutSpread';
import AppointmentCalendar from '../../AppointmentCalendar/AppointmentCalendar';
import LoaderTools from '../components/LoaderTools';
import {
  $AppointmentCalendarContainer,
  $CalendarCard,
} from '../components/TakeInterventionAppointment/AppointmentCalendarContainer/AppointmentCalendarContainer.styles';
import { InterventionAppointmentHeader } from '../components/TakeInterventionAppointment/AppointmentCalendarContainer/InterventionAppointmentHeader';

import messages from './messages';
import { $Title } from './TakeInterventionAppointmentContainer.styles';

const AppointmentCalendarContainerSkeleton: FC = () => {
  return (
    <Card colored>
      <$Title>
        <SafeFormattedMessageWithoutSpread message={messages.whatINeedToDo} />
      </$Title>

      <InterventionAppointmentHeader />

      <$Title>
        <SafeFormattedMessageWithoutSpread message={messages.title} />
      </$Title>

      <$CalendarCard>
        <$AppointmentCalendarContainer>
          <AppointmentCalendar
            customLoader={<LoaderTools />}
            schedules={[]}
            messageList={[
              messages.searchingForTechnician,
              messages.searchingAvailability,
              messages.synchronizeAgendas,
              messages.regroupingTimeslots,
              messages.optimizingTechnicianItinerary,
            ]}
            isGetTimeslotsLoading
            isSubmitLoading={false}
            isNextPageDisabled={false}
            isLoading
            isActive
            onValidateSchedule={async () => {
              return undefined;
            }}
          >
            <></>
          </AppointmentCalendar>
        </$AppointmentCalendarContainer>
      </$CalendarCard>
    </Card>
  );
};

AppointmentCalendarContainerSkeleton.displayName =
  'AppointmentCalendarContainerSkeleton';

export default AppointmentCalendarContainerSkeleton;
