import { createAction } from '@reduxjs/toolkit';

import {
  LOAD_STORE_ACTOR_BY_CLAIM_ID,
  SET_STORE_ACTOR,
} from '../actionTypes/storeActor';

export const loadStoreActorByClaimId = createAction(
  LOAD_STORE_ACTOR_BY_CLAIM_ID.BASE,
);
export const setStoreActor = createAction<{
  claimId: string;
  storeActorId: string;
}>(SET_STORE_ACTOR.BASE);
