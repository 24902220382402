import React, { FC, Fragment } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Col, Row } from 'react-styled-flexboxgrid';

import { AdditionalInformationDto } from '@savgroup-front-common/types';

import { safeFormattedIntlString } from '../../formatters';

import {
  checkIfNextInputIsFull,
  checkIfShouldRenderWithFullWith,
} from './AdditionalInformation.helpers';
import { AdditionalInformationInput } from './AdditionalInformationInput';
import useAdditionalInformation from './hooks/useAdditionalInformation';
import messages from './messages';

interface NeededInformation extends AdditionalInformationDto {
  internalId: string;
  claimIds: string[];
  fileUploadEndpoints?: Record<
    string,
    {
      uploadEndpoint: string;
      deleteEndpoint: string;
    }
  >;
}

interface AdditionalInformationProps {
  neededInformation?: NeededInformation[];
  formContext?: UseFormReturn;
  ignoreUniquenessByProduct?: boolean;
  prefixFormName?: string;
  onAdditionalInformationSave?: () => Promise<void>;
  isLiveUpload?: boolean;
  isNewDesign?: boolean;
  fullSizeItem?: boolean;
  isScrollIntoView?: boolean;
  shouldAllowQrCode?: boolean;
}

const AdditionalInformation: FC<AdditionalInformationProps> = ({
  neededInformation = [],
  formContext,
  ignoreUniquenessByProduct = false,
  prefixFormName,
  onAdditionalInformationSave = () => Promise.resolve(),
  isLiveUpload = false,
  isNewDesign = false,
  fullSizeItem = false,
  isScrollIntoView = false,
  shouldAllowQrCode = true,
}) => {
  const { neededInformationGroupedByIdValues } = useAdditionalInformation({
    neededInformation,
    ignoreUniquenessByProduct,
  });

  return (
    <Row>
      {neededInformationGroupedByIdValues.map(
        ([key, neededInformationGroup], index) => {
          return (
            <Fragment key={key}>
              {neededInformationGroup.map((neededInformationItem, subIndex) => {
                const {
                  uniquenessByProduct = false,
                  internalId,
                  name = undefined,
                  type,
                  description = undefined,
                  isRequired = true,
                  possibleValues = [],
                  isEven,
                  fileUploadEndpoints,
                  validatingRegex,
                  importByQrCode,
                  claimIds,
                  documentType,
                } = neededInformationItem;

                const nextGroup = neededInformationGroupedByIdValues[index + 1];

                const nextInputIsFull = checkIfNextInputIsFull({
                  ignoreUniquenessByProduct,
                  nextGroup,
                });

                const isFull = checkIfShouldRenderWithFullWith({
                  ignoreUniquenessByProduct,
                  isEven,
                  nextInputIsFull,
                  neededInformationGroup,
                  nextGroup,
                  neededInformationItem,
                  fullSizeItem,
                });

                const colProps = { xs: 12, sm: isFull ? 12 : 6 };

                return (
                  <Col
                    key={internalId}
                    {...colProps}
                    data-testid={`additionalInformation_${internalId}`}
                  >
                    <AdditionalInformationInput
                      description={description}
                      id={internalId}
                      type={type}
                      possibleValues={possibleValues}
                      isRequired={isRequired}
                      formContext={formContext}
                      label={subIndex === 0 ? name : undefined}
                      placeholder={
                        uniquenessByProduct && neededInformationGroup.length > 1
                          ? safeFormattedIntlString(messages.productNumber, {
                              num: subIndex + 1,
                            })
                          : undefined
                      }
                      fileUploadEndpoints={fileUploadEndpoints}
                      prefixFormName={prefixFormName}
                      onAdditionalInformationSave={onAdditionalInformationSave}
                      isLiveUpload={isLiveUpload}
                      isNewDesign={isNewDesign}
                      isScrollIntoView={isScrollIntoView}
                      validatingRegex={validatingRegex}
                      importByQrCode={importByQrCode}
                      claimIds={claimIds}
                      documentType={documentType}
                      shouldAllowQrCode={shouldAllowQrCode}
                    />
                  </Col>
                );
              })}
            </Fragment>
          );
        },
      )}
    </Row>
  );
};

AdditionalInformation.displayName = 'AdditionalInformation';

export default AdditionalInformation;
