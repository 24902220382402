import styled from 'styled-components';
import { media } from '@savgroup-front-common/constants';

export const $Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
`;

export const $EmptyCartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;

  & > div {
    width: 50%;
    @media ${media.maxWidth.sm} {
      width: 100%;
    }
  }
`;

export const $InputGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.5rem;
`;

export const $CenteredText = styled.div`
  text-align: center;
  & > * {
    margin-bottom: 2.2rem;
  }
`;

export const $OrderLines = styled.div`
  max-height: 610px;
  overflow-y: auto;
  overflow-x: hidden;
`;
