import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';
import { ICONS_TYPE } from '@savgroup-front-common/types/src/Icon';

const ENDPOINT = `${APIConfiguration.claim}solutions/${COMMON_QUERY_PARAMS.ID}/summary`;

export interface SolutionType {
  id: string;
  name: string;
  uniqueName: string;
  iconKey?: ICONS_TYPE;
  solutionGroupId: string;
}

async function getSolutionTypeQuery({
  solutionTypeId,
}: {
  solutionTypeId: string;
}): Promise<BackResponse<SolutionType> | BackResponseFailure> {
  try {
    const response = await apiCall<BackResponse<SolutionType>>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.ID, solutionTypeId),
      { method: SUPPORTED_METHODS.GET },
    );

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

export default getSolutionTypeQuery;
