import PropTypes from 'prop-types';

import { refPropsTypes } from './refProps';

export const formContextProps = PropTypes.shape({
  formState: PropTypes.shape({
    errors: PropTypes.shape({}).isRequired,
  }).isRequired,
  control: refPropsTypes(PropTypes.shape({})).isRequired,
  register: PropTypes.func.isRequired,
  unregister: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  trigger: PropTypes.func.isRequired,
});
