import React, { FunctionComponent } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Row } from 'react-styled-flexboxgrid';

import messages from './messages';
import {
  $ImportantHighlight,
  AlignedCol,
  Date,
  PaddedCol,
} from './QuotationValidationHeader.styles';

interface QuotationValidationHeaderProps {
  createdDate: string;
}

const QuotationValidationHeader: FunctionComponent<React.PropsWithChildren<QuotationValidationHeaderProps>> =
  ({ createdDate }) => (
    <Row>
      <PaddedCol xs={12}>
        <Row between="xs">
          <$ImportantHighlight>
            <FormattedMessage {...messages.quoteReference} />
          </$ImportantHighlight>
          <AlignedCol xs>
            <FormattedMessage {...messages.date} />
            <Date>
              <FormattedDate value={createdDate} />
            </Date>
          </AlignedCol>
        </Row>
      </PaddedCol>
    </Row>
  );

QuotationValidationHeader.displayName = 'QuotationValidationHeader';

export default QuotationValidationHeader;
