import React, { FC } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';

import { H2 } from '@savgroup-front-common/core/src/atoms/headings';
import { Card } from '@savgroup-front-common/core/src/atoms/Cards';
import { Paragraph } from '@savgroup-front-common/core/src/atoms/Paragraph';
import {
  customComponentDecorator,
  Linkify,
} from '@savgroup-front-common/core/src/components/Linkify';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { MessageType } from '@savgroup-front-common/types';

import messages from './messages';

interface CustomerCommentDisplayProps {
  action?: {
    toCustomerComment?: string;
  };
  title?: MessageType;
}

const CustomerCommentDisplay: FC<CustomerCommentDisplayProps> = ({
  action,
  title = messages.sellerComment,
}) => {
  const toCustomerComment = action?.toCustomerComment;

  if (!toCustomerComment) {
    return <></>;
  }

  return (
    <Card>
      <Row data-testid="customer-block">
        <Col xs={12}>
          <H2>
            <SafeFormattedMessageWithoutSpread message={title} />
          </H2>
          <Paragraph />
        </Col>
        <Col xs={12}>
          <Linkify componentDecorator={customComponentDecorator}>
            {toCustomerComment}
          </Linkify>
        </Col>
      </Row>
    </Card>
  );
};

CustomerCommentDisplay.displayName = 'CustomerCommentDisplay';

export default CustomerCommentDisplay;
