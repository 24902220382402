import React, { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';

import { Modal } from '@savgroup-front-common/core/src/atoms/modal';
import SwipeableDrawer, {
  DRAWER_SIZE,
} from '@savgroup-front-common/core/src/atoms/SwipeableDrawer/SwipeableDrawer';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';
import { PickupPoint } from '@savgroup-front-common/types';
import { ICONS_TYPE } from '@savgroup-front-common/types/src/Icon';
import {
  PROFESSIONAL_STEP_CONFIG,
  PROFESSIONAL_STEP_PROGRESS,
} from 'myaccount/view/app/NewLayout/ProfessionalRoutes.config';
import { buildProfessionalRoute } from 'myaccount/view/app/NewLayout/ProfessionalRoutes.helpers';
import StepLayoutProfessional from 'myaccount/view/app/NewLayout/StepLayout/StepLayoutProfessional';

import { useGetHandlingByClaimGroup } from '../../../app/hooks/useGetHandlingByClaimGroup';
import useGetPickupPoints from '../../../app/hooks/useGetPickupPoints';
import { AddressManagement } from '../../../components/AddressManagement';
import HandlingCard from '../../../components/HandlingCard';
import PickupPointSelector from '../../../components/PickupPointSelector';
import { ResumeDeposit } from '../../../components/ResumeDeposit';
import { ResumeSolution } from '../../../components/ResumeSolution';
import { ResumeCard } from '../components/ResumeCard';

import useClaimGroupDeliveryPage from './ClaimGroupDeliveryPage.hooks';
import {
  $DeliveryItemWrapper,
  $DeliveryResultWrapper,
  $DeliveryWrapper,
  $Heading,
  $HeadingIcon,
  $LeftColumn,
} from './ClaimGroupDeliveryPage.styles';
import messages from './messages';

const ClaimGroupDeliveryPage: React.FC = () => {
  const history = useHistory();
  const { claimGroupId } = useParams<{ claimGroupId: string }>();
  const [openResumeModal, setOpenResumeModal] = useState(false);
  const {
    claimGroupConfirmation,
    groupedCarriers,
    formContext,
    changeSelection,
    onSubmit,
    isDeliveryHandlingLoading,
    chooseHandlingSelected,
    handlingSelected,
    homeSelectedAddressChanged,
  } = useClaimGroupDeliveryPage();

  const { setValue } = formContext;

  const {
    sellerId,
    carriers,
    addressSelected,
    pickupPoints,
    noPickupPoints,
    addresses,
    handleSetAddressSelected,
    selectedPickupPoint,
    pickupPointsIsLoading,
  } = useGetPickupPoints({
    selectedCarrierType: chooseHandlingSelected,
    groupedCarriers,
    claimGroupId,
  });

  const { handling } = useGetHandlingByClaimGroup({ claimGroupId });
  const hasDeposit = handling?.hasDeposit;

  const customBuildUpdateSolutionUrl = claimGroupId
    ? buildProfessionalRoute({
        route: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_SOLUTION_PAGE,
        payload: { claimGroupId },
      })
    : undefined;

  const handleSetSelectedPickupPoint = (pickupPointSelected: PickupPoint) => {
    Promise.resolve(setValue('pickupPointSelected', pickupPointSelected)).then(
      () => onSubmit(),
    );
  };

  const handleOpenModal = () => setOpenResumeModal((prev) => !prev);

  const handlePreviousStep = () => {
    if (hasDeposit) {
      history.push(
        buildProfessionalRoute({
          route: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_DEPOSIT_PAGE,
          payload: { claimGroupId },
        }),
      );

      return undefined;
    }

    history.push(
      buildProfessionalRoute({
        route: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_SOLUTION_PAGE,
        payload: { claimGroupId },
      }),
    );

    return undefined;
  };

  return (
    <StepLayoutProfessional
      stepName={PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_DEPOSIT_PAGE}
      customSteps={PROFESSIONAL_STEP_PROGRESS}
      handlePreviousStep={handlePreviousStep}
    >
      <FormProvider {...formContext}>
        <form onSubmit={onSubmit}>
          <$DeliveryWrapper>
            <$LeftColumn>
              {claimGroupId && (
                <ResumeSolution
                  claimGroupId={claimGroupId}
                  claimGroupConfirmation={claimGroupConfirmation}
                  customBuildUpdateSolutionUrl={customBuildUpdateSolutionUrl}
                />
              )}
              <ResumeDeposit claimGroupConfirmation={claimGroupConfirmation} />
              <$Heading>
                <$HeadingIcon>
                  <MyAccountIcon icon={ICONS_TYPE.DELIVERY_ICON} />
                </$HeadingIcon>
                <SafeFormattedMessageWithoutSpread
                  message={messages.handlingTitle}
                />
              </$Heading>
              <$DeliveryItemWrapper>
                {isDeliveryHandlingLoading ? (
                  <BaseLoader />
                ) : (
                  <HandlingCard groupedCarriers={groupedCarriers} />
                )}
              </$DeliveryItemWrapper>
            </$LeftColumn>
            <$DeliveryResultWrapper>
              <ResumeCard />
            </$DeliveryResultWrapper>
          </$DeliveryWrapper>

          <SwipeableDrawer
            isOpen={handlingSelected}
            onClose={changeSelection}
            hasBackdrop
            hasPadding={false}
            hasBackdropScroll={false}
            hasCloseButton={false}
          >
            {handlingSelected && (
              <PickupPointSelector
                maxDistance={200}
                pickupPoints={pickupPoints}
                selectedPickupPointId={
                  selectedPickupPoint ? selectedPickupPoint.id : undefined
                }
                onSelectPickupPoint={handleSetSelectedPickupPoint}
                searchAddress={addressSelected}
                userAddresses={addresses}
                onAddressSelected={handleSetAddressSelected}
                carriers={carriers}
                onClose={changeSelection}
                pickupPointsIsLoading={pickupPointsIsLoading}
                sellerId={sellerId}
                noPickupPoints={noPickupPoints}
              />
            )}
          </SwipeableDrawer>
          <SwipeableDrawer
            isOpen={homeSelectedAddressChanged}
            size={DRAWER_SIZE.SMALL}
            onClose={changeSelection}
            hasBackdrop
            hasBackdropScroll={false}
          >
            <AddressManagement />
          </SwipeableDrawer>
        </form>
      </FormProvider>
      <Modal
        isOpen={openResumeModal}
        onClose={handleOpenModal}
        shouldShowPadding={false}
      >
        <ResumeCard />
      </Modal>
    </StepLayoutProfessional>
  );
};

ClaimGroupDeliveryPage.displayName = 'ClaimGroupDeliveryPage';

export default ClaimGroupDeliveryPage;
