import styled from 'styled-components';

export const $ProductImage = styled.img`
  padding: 0;
`;

export const $ImageCardStyled = styled.div<{
  $imgErrorUrl: boolean;
  $minWidth: string;
}>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  border: 0;
  overflow: hidden;
  box-shadow: unset;
  min-width: ${({ $minWidth }) => $minWidth};

  transition: opacity 0.3s ease-in-out;

  img {
    object-fit: contain;
    width: 100%;
  }

  &:hover * {
    opacity: 0.8;
  }

  & svg {
    width: ${({ $minWidth }) => $minWidth};
    height: ${({ $minWidth }) => $minWidth};
  }
`;
