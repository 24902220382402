import React, { FunctionComponent } from 'react';

import { ArrowUpIcon } from '../Arrow/ArrowUp.icon';
import { IconPropsInterface } from '../Icon.types';

const ChevronUpIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => <ArrowUpIcon size={size} color={color} />;

ChevronUpIcon.displayName = 'ChevronUpIcon';

export default ChevronUpIcon;
