import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.carrier}carriers/files/${COMMON_QUERY_PARAMS.FILE_ID}/urls/${COMMON_QUERY_PARAMS.MODULE_DEFINITION_ID}/${COMMON_QUERY_PARAMS.MODULE_INSTANCE_ID}`;

interface GetCarrierLabelUrlQueryArgs {
  fileId: string;
  moduleDefinitionId: string;
  moduleInstanceId: string;
}

const getCarrierLabelUrlQuery = async ({
  fileId,
  moduleDefinitionId,
  moduleInstanceId,
}: GetCarrierLabelUrlQueryArgs): Promise<
  BackResponse<string> | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId)
      .replace(COMMON_QUERY_PARAMS.MODULE_DEFINITION_ID, moduleDefinitionId)
      .replace(COMMON_QUERY_PARAMS.MODULE_INSTANCE_ID, moduleInstanceId);

    const response = await apiCall<BackResponse<string>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default getCarrierLabelUrlQuery;
