import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  AddressInfoDto,
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.owner}sharelink/createorder/byproductreference`;

interface CreateOrderAndShareLinkByProductReferenceCommandPayload {
  payload: {
    civility: string;
    customerLastName?: string;
    customerFirstName?: string;
    address?: AddressInfoDto;
    phoneNumber?: string;
    customerMail?: string;
    product: {
      id: string;
      sku?: string;
      price: {
        amount: number;
        currency: string;
      };
    };
    isOutOfWarranty: boolean;
    sellerId?: string;
  };
  tenantId?: string;
}

const createOrderAndShareLinkByProductReferenceCommand = async (
  payload: CreateOrderAndShareLinkByProductReferenceCommandPayload,
): Promise<BackResponse<string> | BackResponseFailure> => {
  try {
    const response = await apiCall<BackResponse<string>>(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: payload,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default createOrderAndShareLinkByProductReferenceCommand;
