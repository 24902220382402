import filter from 'lodash/filter';
import trim from 'lodash/trim';

import { CIVILITY } from '@savgroup-front-common/constants';
import { AddressInfoDto } from '@savgroup-front-common/types';

export const formattedCivility = ({ civility }: { civility: CIVILITY }) => {
  if (!civility || civility === 'NotSet') {
    return '';
  }

  return `${civility}. `;
};

export const formatCompanyAddressLine = (
  datas: Partial<AddressInfoDto> | undefined,
  getCountryTranslation: (country: string) => string | undefined,
): string => {
  if (!datas) {
    return '';
  }

  const {
    address = '',
    additionalAddress = '',
    postalCode = '',
    city = '',
    countryCode = '',
    companyName = '',
  } = datas;

  const firstLine = filter([trim(address), trim(additionalAddress)]).join(', ');
  const secondLine = filter([
    trim(postalCode),
    trim(city),
    countryCode ? getCountryTranslation(countryCode) : '',
  ]).join(' ');

  return filter([trim(companyName), trim(firstLine), trim(secondLine)]).join(
    ` \n`,
  );
};
export const formatCustomerAddressLine = (
  datas: AddressInfoDto | undefined,
  getCountryTranslation: (country: string) => string | undefined,
): string => {
  if (!datas) {
    return '';
  }

  const {
    address = '',
    additionalAddress = '',
    postalCode = '',
    city = '',
    countryCode = '',
    companyName = '',
    firstname,
    lastname,
  } = datas;

  const firstLine = filter([trim(address), trim(additionalAddress)]).join(', ');
  const secondLine = filter([
    trim(postalCode),
    trim(city),
    countryCode ? getCountryTranslation(countryCode) : '',
  ]).join(' ');
  const fullname = filter([trim(firstname), trim(lastname)]).join(' ');

  return filter([
    trim(fullname),
    trim(companyName),
    trim(firstLine),
    trim(secondLine),
  ]).join(`\n`);
};

export const formatCustomerAddressLineWithoutFullname = (
  datas: AddressInfoDto | undefined,
  getCountryTranslation: (country: string) => string,
): string => {
  if (!datas) {
    return '';
  }

  const {
    address = '',
    additionalAddress = '',
    postalCode = '',
    city = '',
    countryCode = '',
    companyName = '',
  } = datas;

  const firstLine = filter([trim(address), trim(additionalAddress)]).join(', ');
  const secondLine = filter([
    trim(postalCode),
    trim(city),
    countryCode ? getCountryTranslation(countryCode) : '',
  ]).join(' ');

  return filter([trim(companyName), trim(firstLine), trim(secondLine)]).join(
    `\n`,
  );
};

export const formatSimpleAddressLine = (
  datas: Partial<AddressInfoDto> | undefined,
  getCountryTranslation: (country: string) => string,
): string => {
  if (!datas) {
    return '';
  }

  const {
    address = '',
    additionalAddress = '',
    postalCode = '',
    city = '',
    countryCode = '',
  } = datas;

  const firstLine = filter([trim(address), trim(additionalAddress)]).join(', ');
  const secondLine = filter([
    trim(postalCode),
    trim(city),
    countryCode ? getCountryTranslation(countryCode) : '',
  ]).join(' ');

  return filter([trim(firstLine), trim(secondLine)]).join(`\n`);
};
