const { hostname } = window.location;

const authorityByClientId: Record<string, string> = {
  'preprod-selfcare-revers.io': 'https://preprod-selfcare-login.revers.io',
};

function setAuthority(authority: string | undefined = undefined) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { clientId } = this;

  const defaultAuthority = authorityByClientId[clientId];

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  this.authority = authority || defaultAuthority;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return this;
}

const defaultClientId = 'preprod-selfcare-revers.io';

function computeClientId() {
  if (import.meta.env.VITE_CLIENTID) {
    return import.meta.env.VITE_CLIENTID;
  }
  if (hostname !== 'localhost') {
    return hostname;
  }

  return defaultClientId;
}

const clientId = computeClientId();

const AuthConfiguration = {
  clientId,
  authority: authorityByClientId[clientId],
  scope: 'openid email api',
  setAuthority,
};

export default AuthConfiguration;
