import React from 'react';
import styled from 'styled-components';

const Link = styled('a')`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: bold;
  &:visited {
    color: ${({ theme }) => theme.colors.default};
  }
  &:hover {
    color: ${({ theme }) => theme.colors.headingTextColor};
  }
`;

export default (decoratedHref, decoratedText, key, props = {}) => {
  return (
    <Link href={decoratedHref} key={key} {...props}>
      {decoratedText}
    </Link>
  );
};
