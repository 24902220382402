import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  ACTOR_TYPES,
  COMMON_QUERY_PARAMS,
  PAYMENT_BEHAVIOUR,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  AddressInfoDto,
  BackResponse,
  BackResponseFailure,
  Document,
} from '@savgroup-front-common/types';

 
const ENDPOINT = `${APIConfiguration.claim}claimGroups/${COMMON_QUERY_PARAMS.CLAIM_GROUP_ID}/confirmation`;

export interface AdditionalClaimInformation {
  additionalInformationId: string;
  additionalInformationEnumValue?: string;
  additionalInformationStringValue?: string;
  additionalInformationFileValue: {
    fileName?: string;
    url?: string;
    uploaderId?: string;
    uploaderFullName?: string;
    isUploadedByOwner?: boolean;
    uploadDateUtc?: string;
    customerVisibility: boolean;
  };
}

export interface ClaimGroupConfirmation {
  fileId?: string;
  claimGroupId: string;
  solutionTypeName?: string;
  solutionPrice?: number;
  solutionPriceCurrencyCode?: string;
  solutionPriceWithTax?: number;
  solutionPriceWithTaxCurrencyCode?: string;
  solutionGroupId?: string;
  solutionTypeId?: string;
  depositStoreId?: string;
  purchaseStoreId?: string;
  storeActorId?: string;
  actorId?: string;
  actorType: ACTOR_TYPES;
  depositAddress: AddressInfoDto;
  deliveryAddress: AddressInfoDto;
  carrierDepositName?: string;
  carrierDeliveryName?: string;
  carrierDeliveryPrice?: number;
  carrierDeliveryPriceCurrencyCode?: string;
  carrierDepositPrice?: number;
  carrierDepositPriceCurrencyCode?: string;
  carrierDeliveryPriceWithTax?: number;
  carrierDeliveryPriceWithTaxCurrencyCode?: string;
  carrierDepositPriceWithTax?: number;
  carrierDepositPriceWithTaxCurrencyCode?: string;
  claims: {
    claimId: string;
    issueName?: string;
    reasonName?: string;
    comments: {
      type: string;
      content?: string;
    }[];
    documents: Document[];
    solutionTypeName?: string;
    solutionTypeKey?: string;
    solutionPrice?: number;
    solutionPriceCurrency?: string;
    solutionGroupId?: string;
    additionalClaimInformation: AdditionalClaimInformation[];
  }[];
  shouldGenerateAndPayInvoice: boolean;
  paymentBehavior: PAYMENT_BEHAVIOUR;
  carrierDepositReach: string;
  carrierDeliveryReach: string;
  carrierDepositProductType: string;
  carrierDeliveryProductType: string;
  carrierDepositService?: string;
  carrierDepositOperator?: string;
  carrierDeliveryService?: string;
  carrierDeliveryOperator?: string;
  carrierDepositTransportMethodUniqueName?: string;
  carrierDeliveryTransportMethodUniqueName?: string;
  orderReference: string;
}

const getClaimGroupConfirmationQuery = async ({
  claimGroupId,
}: {
  claimGroupId: string;
}): Promise<BackResponse<ClaimGroupConfirmation> | BackResponseFailure> => {
  try {
    const url = ENDPOINT.replace(
      COMMON_QUERY_PARAMS.CLAIM_GROUP_ID,
      claimGroupId,
    );

    return await apiCall<BackResponse<ClaimGroupConfirmation>>(url, {
      method: SUPPORTED_METHODS.GET,
    });
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default getClaimGroupConfirmationQuery;
