import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Col } from 'react-styled-flexboxgrid';

import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import StepLayout from 'myaccount/view/app/NewLayout/StepLayout/StepLayout';

import useClaimGroupDiagnosticTreePage from './ClaimGroupDiagnosticTreePage.hooks';
import { $RowContent } from './ClaimGroupDiagnosticTreePage.styles';
import { DIAGNOSTIC_STATE_TYPE } from './ClaimGroupDiagnosticTreePage.types';
import StepAnswers from './components/stepAnswers/StepAnswers';
import StepFinal from './components/stepFinal/StepFinal';
import StepFooter from './components/stepFooter/StepFooter';
import StepInformations from './components/stepInformations/StepInformations';
import StepNumber from './components/stepNumber/StepNumber';

const ClaimGroupDiagnosticTreePage = () => {
  const { questionId } = useParams<{ questionId: string }>();

  const {
    diagnosticTreeState,
    isLoading,
    formContext,
    claimGroupId,
    handleSubmitResponse,
    handlePreviousStep,
    handleUpdateValue,
    enableServicePortal,
  } = useClaimGroupDiagnosticTreePage({
    diagnosticTreeUniqueName: questionId,
  });

  const hasQuestionType =
    diagnosticTreeState?.currentStep?.type === DIAGNOSTIC_STATE_TYPE.QUESTION ||
    diagnosticTreeState?.currentStep?.type === DIAGNOSTIC_STATE_TYPE.ACTION;

  if (!diagnosticTreeState) {
    return <></>;
  }

  if (isLoading) {
    return <BaseLoader />;
  }

  const stepNumberLength = diagnosticTreeState.latestStep.length + 1;

  return (
    <StepLayout hasFooter={false} noConfirmButton>
      {hasQuestionType && (
        <FormProvider {...formContext}>
          <form
            onSubmit={(event) => {
              event.preventDefault();

              return undefined;
            }}
          >
            <$RowContent $stepNumberLength={stepNumberLength}>
              <StepNumber currentDiagnosticTreeStep={diagnosticTreeState} />
              <Col xs={12} sm={12} md={9}>
                <StepInformations
                  currentDiagnosticTreeStep={diagnosticTreeState}
                />

                <StepAnswers
                  currentDiagnosticTreeStep={diagnosticTreeState}
                  onSubmit={handleSubmitResponse}
                />
              </Col>
            </$RowContent>
            <StepFooter
              handlePreviousStep={handlePreviousStep}
              enableServicePortal={enableServicePortal}
            />
          </form>
        </FormProvider>
      )}
      {diagnosticTreeState?.currentStep?.type ===
        DIAGNOSTIC_STATE_TYPE.FINAL_STATE && (
        <StepFinal
          currentDiagnosticTreeStep={diagnosticTreeState}
          claimGroupId={claimGroupId}
          handleUpdateValue={handleUpdateValue}
        />
      )}
    </StepLayout>
  );
};

ClaimGroupDiagnosticTreePage.displayName = 'ClaimGroupDiagnosticTreePage';

export default ClaimGroupDiagnosticTreePage;
