import { MessageType, SparePartCondition } from '@savgroup-front-common/types';

import sparePartConditionMessages from '../helpers/i18n/messages/sparePartConditionMessages';

const getSparePartConditionMessage = (
  value: SparePartCondition,
): MessageType => {
  switch (value) {
    case SparePartCondition.NEW: {
      return sparePartConditionMessages.New;
    }
    case SparePartCondition.USED: {
      return sparePartConditionMessages.Used;
    }

    case SparePartCondition.RECONDITIONED: {
      return sparePartConditionMessages.Reconditioned;
    }

    default:
      throw new Error(`Spare parts condition ${value} does not exist`);
      break;
  }
};

export default getSparePartConditionMessage;
