import React, { FC } from 'react';

import { Tag } from '@savgroup-front-common/core/src/atoms/Tag';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { intl } from '@savgroup-front-common/core/src/helpers';
import { FileShortSummary } from '@savgroup-front-common/types';

import messages from './messages';

import {
  $CardFileHeader,
  $CardFileHeaderDate,
  $CardHeader,
  $CardTagHeader,
  $CardTagWrapperHeader,
  $CardTitleContainer,
} from './ProductInfoHeader.styles';

const ProductInfoHeader: FC<{
  file: FileShortSummary;
}> = ({ file }) => {
  return (
    <$CardHeader>
      <$CardFileHeader>
        <$CardTitleContainer>
          <SafeFormattedMessageWithoutSpread
            message={messages.titleCard}
            values={{
              fileReference: file.fileReference,
            }}
          />
        </$CardTitleContainer>{' '}
        <$CardFileHeaderDate>
          <SafeFormattedMessageWithoutSpread
            message={messages.titleCardDate}
            values={{
              date: intl?.formatDate(file.createdDate, {
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
              }),
            }}
          />
        </$CardFileHeaderDate>
      </$CardFileHeader>
      <$CardTagWrapperHeader>
        {file.solutionName && (
          <$CardTagHeader>
            <Tag isSmall neutral>
              {file.solutionName}
            </Tag>
          </$CardTagHeader>
        )}
        <$CardTagHeader>
          {file.isClosed ? (
            <Tag isSmall danger>
              <SafeFormattedMessageWithoutSpread message={messages.isClosed} />
            </Tag>
          ) : (
            <Tag isSmall success>
              <SafeFormattedMessageWithoutSpread message={messages.isOpen} />
            </Tag>
          )}
        </$CardTagHeader>
      </$CardTagWrapperHeader>
    </$CardHeader>
  );
};

ProductInfoHeader.displayName = 'ProductInfoHeader';
export default ProductInfoHeader;
