import { defineMessages } from 'react-intl';

export default defineMessages({
  fileOpenedLink: {
    id: 'components.myaccount.order.fileOpenedLink',
    defaultMessage: 'Link',
  },
  followClaimGroupLink: {
    id: 'components.myaccount.order.followClaimGroupLink',
    defaultMessage: 'Follow claimGroup',
  },
});
