import React, { useContext } from 'react';

import { Field } from '../../../molecules/Field';
import { ActorInformationItem } from '../../ActorInformation/components/GenericActorInformation/GenericActorInformation.styles';
import messages from '../../ActorInformation/components/GenericActorInformation/messages';
import { ContentTemplateContext } from '../MDXCustomComponents.context';

const AdditionalInformationCurrentActor = () => {
  const { actorSummary } = useContext(ContentTemplateContext);
  const address = actorSummary?.address;
  const additionalInformation = address?.additionalInformation;

  return (
    <>
      {additionalInformation && (
        <ActorInformationItem>
          <Field
            label={messages.additionalInformation}
            copy={additionalInformation}
          >
            {additionalInformation}
          </Field>
        </ActorInformationItem>
      )}
    </>
  );
};

AdditionalInformationCurrentActor.displayName =
  'AdditionalInformationCurrentActor';

export default AdditionalInformationCurrentActor;
