import React from 'react';
import { useMedia } from 'react-use';
import { useTheme } from 'styled-components';

import {
  CURRENCIES,
  media,
  HANDLING_UNIQUE_NAME,
} from '@savgroup-front-common/constants';
import { ICONS_TYPE } from '@savgroup-front-common/types/src/Icon';
import { ClaimGroupConfirmation } from 'myaccount/api/Claim/getClaimGroupConfirmationQuery';
import {
  SimpleItemCard,
  SimpleItemCardMobile,
} from 'myaccount/view/atoms/SimpleItemCard';

import messages from './messages';
import { getHandlingData } from '../../../helpers';

interface ResumeDepositProps {
  claimGroupConfirmation?: ClaimGroupConfirmation;
}

const ResumeDelivery: React.FC<ResumeDepositProps> = ({
  claimGroupConfirmation,
}) => {
  const isMobileView = useMedia(media.maxWidth.xs);
  const theme = useTheme();

  const addressName = claimGroupConfirmation?.deliveryAddress?.firstname;
  const address = claimGroupConfirmation?.deliveryAddress?.address;
  const postalCode = claimGroupConfirmation?.deliveryAddress?.postalCode;
  const city = claimGroupConfirmation?.deliveryAddress?.city;
  const addressFormated = `${address} ${postalCode} ${city}`;

  const { label, description } = getHandlingData({
    carrierUniqueName:
      claimGroupConfirmation?.carrierDeliveryTransportMethodUniqueName as HANDLING_UNIQUE_NAME,
  });

  return isMobileView ? (
    <SimpleItemCardMobile
      icon={ICONS_TYPE.DELIVERY_ICON}
      iconColor={theme.colors.primary}
      prefixTitle={messages.deliveryTitle}
      title={label}
      didactic={description}
      address={{
        addressName,
        addressStreet: addressFormated,
      }}
      price={{
        priceValue: claimGroupConfirmation?.carrierDeliveryPriceWithTax,
        priceCurrencyCode:
          claimGroupConfirmation?.carrierDeliveryPriceWithTaxCurrencyCode as CURRENCIES,
      }}
    />
  ) : (
    <SimpleItemCard
      icon={ICONS_TYPE.DELIVERY_ICON}
      iconColor={theme.colors.primary}
      prefixTitle={messages.deliveryTitle}
      title={label}
      didactic={description}
      address={{
        addressName,
        addressStreet: addressFormated,
      }}
      price={{
        priceValue: claimGroupConfirmation?.carrierDeliveryPriceWithTax,
        priceCurrencyCode:
          claimGroupConfirmation?.carrierDeliveryPriceWithTaxCurrencyCode as CURRENCIES,
      }}
    />
  );
};

ResumeDelivery.displayName = 'ResumeDelivery';

export default ResumeDelivery;
