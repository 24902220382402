import { fromJS } from 'immutable';
import get from 'lodash/get';

import * as ActionTypes from '../actionTypes';

function onFileSplitLoading(state, { meta }) {
  const fileId = get(meta, ['fileId']);

  return state.setIn(
    [fileId],
    fromJS({ isLoaded: false, isDirty: false, value: null }),
  );
}

function onFileSplitLoadingErrored(state, { meta }) {
  const fileId = get(meta, ['fileId']);

  return state.updateIn([fileId], (oldValue) =>
    oldValue.setIn(['isDirty'], true).setIn(['hasErrors'], true),
  );
}

function onFileSplitLoaded(state, { payload, meta }) {
  const fileId = get(meta, ['fileId']);
  const newFileId = get(payload, ['value']);

  return state.setIn(
    [fileId],
    fromJS({ isLoaded: true, isDirty: false }).setIn(['value'], newFileId),
  );
}

function onClearSplitFileState(state, { payload }) {
  const fileId = get(payload, ['fileId']);

  return state.setIn(
    [fileId],
    fromJS({ isLoaded: true, isDirty: false, value: null }),
  );
}

export default function reducer(state = fromJS({}), action) {
  switch (action.type) {
    case ActionTypes.SPLIT_FILE.STARTED:
      return onFileSplitLoading(state, action);
    case ActionTypes.SPLIT_FILE.SUCCEEDED:
      return onFileSplitLoaded(state, action);
    case ActionTypes.SPLIT_FILE.ERRORED:
      return onFileSplitLoadingErrored(state, action);

    case ActionTypes.CLEAR_SPLIT_FILE_STATE:
      return onClearSplitFileState(state, action);

    default:
      return state;
  }
}
