import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';

export const $AppointmentCardContainer = styled('div')`
  margin-top: 2rem;
`;

export const $ExpectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 72px;
  background: ${({ theme }) => theme.colors.secondaryColor2};
  border-radius: 8px;

  & > * {
    width: 100%;
  }
  @media ${media.maxWidth.sm} {
    padding: 40px 20px;
  }
`;
