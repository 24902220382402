import head from 'lodash/head';
import omit from 'lodash/omit';
import remove from 'lodash/remove';

import { createReducer } from 'myaccount/helpers/reducerUtils';

import { LOGIN_SIGNEDIN } from '../Login/loginConst';

import * as constants from './actionTypes';

const initialState = {
  failure: false,
  errors: [],
  isRunning: false,
  addresses: [],
};

function profileUpdatedApply(state, payload) {
  const { error, failure } = payload;

  if (failure) {
    return {
      ...state,
      failure,
      error,
    };
  }

  return {
    ...state,
    failure,
    isRunning: false,
  };
}

function profileLoadingApply(state, isRunning) {
  return {
    ...state,
    isRunning,
  };
}

function addressLoadedApply(state, payload) {
  const { addresses, errors, failure } = payload;

  if (failure) {
    return {
      ...state,
      failure,
      errors,
    };
  }

  const address = head(addresses);

  return {
    ...state,
    errors,
    failure,
    isRunning: false,
    addresses: address
      ? [
          {
            ...omit(address, ['firstname', 'lastname']),
            firstName: address.firstname,
            lastName: address.lastname,
            isDefault: true,
          },
        ]
      : [],
  };
}

function addressCreatedApply(state, { failure, address, errors }) {
  if (failure) {
    return {
      ...state,
      failure,
      errors,
    };
  }

  return {
    ...state,
    failure,
    isRunning: false,
    addresses: [...state.addresses, address],
  };
}

function addressUpdateddApply(state, { failure, address, errors }) {
  const index = state.addresses.findIndex(
    (x) => x.addressId === address.addressId,
  );
  const { addresses } = state.addresses;

  addresses[index] = address;

  if (failure) {
    return {
      failure,
      errors,
    };
  }

  return {
    ...state,
    errors,
    failure,
    isRunning: false,
    addresses: [...addresses],
  };
}

function addressDeletedApply(state, { failure, addressId, errors }) {
  const { addresses } = state.addresses;

  if (failure) {
    return {
      failure,
      errors,
    };
  }

  if (addresses) {
    remove(addresses, (address) => address.addressId === addressId);
  }

  return {
    ...state,
    errors,
    failure,
    isRunning: false,
    addresses: [...addresses],
  };
}

const profileManagementReducer = createReducer(initialState, {
  [constants.PROFILEMGMT_PROFILE_UPDATED]: profileUpdatedApply,
  [LOGIN_SIGNEDIN]: addressLoadedApply,
  [constants.PROFILEMGMT_ADDRESS_CREATED]: addressCreatedApply,
  [constants.PROFILEMGMT_ADDRESS_UPDATED]: addressUpdateddApply,
  [constants.PROFILEMGMT_ADDRESS_DELTETED]: addressDeletedApply,
  [constants.PROFILEMGMT_ADDRESS_RUNNING]: profileLoadingApply,
});

export default profileManagementReducer;
