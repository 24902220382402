import React, { FunctionComponent } from 'react';

import ArrowDownIcon from '../Arrow/ArrowDown.icon';
import { IconPropsInterface } from '../Icon.types';

const ChevronDownIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => <ArrowDownIcon size={size} color={color} />;

ChevronDownIcon.displayName = 'ChevronDownIcon';

export default ChevronDownIcon;
