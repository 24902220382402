import { DOCUMENT_TYPES } from '@savgroup-front-common/constants';
import { MessageType } from '@savgroup-front-common/types';

import { globalMessages } from '../../../../../helpers';
import messages from '../messages';

const documentNameByType = ({ type }: { type: string }): MessageType => {
  const { documentMessages } = globalMessages;

  switch (type) {
    case DOCUMENT_TYPES.SHIPPING_LABEL:
      return messages.ShippingLabel;

    case DOCUMENT_TYPES.RETURN_VOUCHER:
    default:
      return documentMessages.ReturnVoucher;
  }
};

export default documentNameByType;
