import { defineMessages } from 'react-intl';

export default defineMessages({
  addProduct: {
    id: 'components.myaccount.homePage.productItem.addProduct',
    defaultMessage: 'Add a product',
  },
  searchProductPlaceholder: {
    id: 'components.myaccount.homePage.productItem.searchProductPlaceholder',
    defaultMessage: "Product's name, reference, ...",
  },
  addProductSubmit: {
    id: 'components.myaccount.homePage.productItem.addProductSubmit',
    defaultMessage: 'Add',
  },

  createFile: {
    id: 'components.professional.createFile',
    defaultMessage: 'Create a first return file.',
    description: 'Message used when there is no created claim on B2B portal.',
  },
  createFileButton: {
    id: 'components.professional.createFileButton',
    defaultMessage: 'New claim',
    description: 'Message used for create claim button.',
  },
});
