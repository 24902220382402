import PropTypes from 'prop-types';

import { modelProps } from './modelProps';

export const productProps = PropTypes.shape({
  isMutable: PropTypes.arrayOf(PropTypes.string),
  maxMutable: PropTypes.number,
  model: modelProps,
  orderLineReferences: PropTypes.arrayOf(PropTypes.string),
  quantity: PropTypes.number,
  sellerProductId: PropTypes.string,
  totalPrice: PropTypes.number,
  unitPrice: PropTypes.number,
});
