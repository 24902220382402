import { fromJS } from 'immutable';

import * as ActionTypes from '../actionTypes';

function onPickupAppointmentLoading(state, { meta }) {
  const { fileId } = meta;

  return state.setIn([fileId], fromJS({ isLoaded: false, isDirty: false }));
}

function onPickupAppointmentLoaded(state, { meta }) {
  const { fileId } = meta;

  return state.setIn([fileId], fromJS({ isLoaded: true, isDirty: false }));
}

function onPickupAppointmentErrored(state, { meta, errors }) {
  const { fileId } = meta;

  return state.setIn(
    [fileId],
    fromJS({ isLoaded: true, isDirty: true, hasErrors: true }).set(
      'errors',
      errors,
    ),
  );
}

export default function reducer(state = fromJS({}), action) {
  switch (action.type) {
    case ActionTypes.SET_PICKUP_APPOINTMENT.STARTED: {
      return onPickupAppointmentLoading(state, action);
    }

    case ActionTypes.SET_PICKUP_APPOINTMENT.SUCCEEDED: {
      return onPickupAppointmentLoaded(state, action);
    }

    case ActionTypes.SET_PICKUP_APPOINTMENT.ERRORED: {
      return onPickupAppointmentErrored(state, action);
    }

    default: {
      return state;
    }
  }
}
