import React, { useContext } from 'react';

import useAddressCompanyFormat from '../../../hooks/useAddressFormatter';
import { Field } from '../../../molecules/Field';
import { ActorInformationItem } from '../../ActorInformation/components/GenericActorInformation/GenericActorInformation.styles';
import messages from '../../ActorInformation/components/GenericActorInformation/messages';
import { ContentTemplateContext } from '../MDXCustomComponents.context';

const FullAddressCurrentActor = () => {
  const { actorSummary } = useContext(ContentTemplateContext);
  const address = actorSummary?.address;
  const addressLine = address?.address || '';
  const city = address?.city || '';
  const postalCode = address?.postalCode || '';
  const countryCode = address?.countryCode || '';

  const fullAddress = useAddressCompanyFormat({
    address: addressLine,
    city,
    postalCode,
    countryCode,
  });

  return (
    <>
      {fullAddress && (
        <ActorInformationItem>
          <Field label={messages.address} copy={fullAddress}>
            {fullAddress}
          </Field>
        </ActorInformationItem>
      )}
    </>
  );
};

FullAddressCurrentActor.displayName = 'FullAddressCurrentActor';

export default FullAddressCurrentActor;
