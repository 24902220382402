import { useMutation } from 'react-query';

import { logError } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';

import { CommonSparePartsService } from '../api/SpareParts';

export const useGetSellingPriceForSellerQuery = ({
  sellerId,
}: {
  sellerId?: string;
}) => {
  const { mutateAsync: getSellingPrice } = useMutation(
    ['useGetQuotationsByFileIdQuery', { sellerId }],
    async ({ preTaxBuyingPrice }: { preTaxBuyingPrice: number }) => {
      if (!sellerId) {
        return undefined;
      }

      const response =
        await CommonSparePartsService.getSellingPriceForSellerQuery({
          sellerId,
          preTaxBuyingPrice,
        });

      if (response.failure) {
        logError(response.errors);

        return undefined;
      }

      return response.value;
    },
  );

  return {
    getSellingPrice,
  };
};
