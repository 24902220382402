import React from 'react';
import { Grid } from 'react-styled-flexboxgrid';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { SUPPORTED_ICON_POSITIONS } from '@savgroup-front-common/core/src/helpers';
import ArrowNakedLeftIcon from '@savgroup-front-common/core/src/protons/icons/ArrowNaked/ArrowNakedLeft.icon';

import { $Footer, $RowFooter } from '../../../../components/common/Container';
import messages from '../../messages';

interface StepFooterProps {
  handlePreviousStep: () => void;
  enableServicePortal: boolean;
}

const StepFooter: React.FC<StepFooterProps> = ({
  handlePreviousStep,
  enableServicePortal,
}) => {
  return (
    <$Footer $enableServicePortal={enableServicePortal}>
      <Grid fluid={enableServicePortal}>
        <$RowFooter>
          <Button
            type={BUTTON_TYPES.BUTTON}
            hollow
            naked
            onClick={() => handlePreviousStep()}
            icon={<ArrowNakedLeftIcon />}
            position={SUPPORTED_ICON_POSITIONS.LEFT}
          >
            <SafeFormattedMessageWithoutSpread
              message={messages.previousButton}
            />
          </Button>
        </$RowFooter>
      </Grid>
    </$Footer>
  );
};

StepFooter.displayName = 'StepFooter';

export default StepFooter;
