import { fromJS } from 'immutable';
import filter from 'lodash/filter';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

export function verifiedAddressStart(state) {
  return state
    .setIn(['verifiedAddress', 'isLoaded'], false)
    .setIn(['verifiedAddress', 'errorFields'], null)
    .setIn(['verifiedAddress', 'value'], null);
}

export function verifiedAddressErrored(state, action) {
  const fieldErrors = filter(get(action, 'errors'), (error) =>
    get(error, 'field'),
  );

  return state
    .setIn(['verifiedAddress', 'isLoaded'], true)
    .setIn(['verifiedAddress', 'value'], null)
    .setIn(
      ['verifiedAddress', 'errorFields'],
      isEmpty(fieldErrors) ? null : fieldErrors,
    );
}

export function verifiedAddressSuccess(state, { payload }) {
  const fieldErrors = filter(get(payload, 'errors'), (error) =>
    get(error, 'field'),
  );

  return state
    .setIn(['verifiedAddress', 'isLoaded'], true)
    .setIn(
      ['verifiedAddress', 'errorFields'],
      isEmpty(fieldErrors) ? null : fieldErrors,
    )
    .setIn(['verifiedAddress', 'value'], fromJS(payload.value));
}
