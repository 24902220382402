import { useQuery } from 'react-query';

import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { ClaimService } from 'myaccount/api';

const GetClaimGroupSummary = 'getClaimGroupSummary';

const useGetClaimGroupSummary = ({
  claimGroupId,
}: {
  claimGroupId?: string;
}) => {
  const { pushErrors, removeAllNotifications } = useToasts();

  const { data: claims, isLoading } = useQuery(
    [GetClaimGroupSummary, { claimGroupId }],
    async () => {
      removeAllNotifications();

      if (!claimGroupId) {
        return undefined;
      }

      const response = await ClaimService.getClaimGroupSummary({
        claimGroupId,
      });

      if (response.failure) {
        pushErrors(response.errors);

        return undefined;
      }

      return response.value;
    },
    {
      enabled: !!claimGroupId,
    },
  );

  return {
    claims,
    claimsIsLoading: isLoading,
  };
};

export default useGetClaimGroupSummary;
