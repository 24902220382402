import styled from 'styled-components';

import { Button } from '@savgroup-front-common/core/src/atoms/button';

export const $DocumentWrapper = styled.div`
  border: 1px solid #c9ced6;
  padding: 16px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: ${({ theme }) => theme.borders.radius};
  flex: 1;
  color: ${({ theme }) => theme.systemColors.defaultTextColor};
  font-size: ${({ theme }) => theme.fonts.size.large};
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
  display: flex;
  align-items: center;
`;

export const $DocumentIcon = styled.div`
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;

  && svg {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const $DocumentName = styled.div`
  display: block;
  flex: 2;
  margin-right: 1.25rem;
  font-size: 16px;
`;

export const $DocumentNameDescription = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  color: #8a94a6;
`;

export const $DocumentButton = styled(Button)`
  && {
    flex: 1;
    text-align: left;
    line-height: 1.1rem;
    min-width: unset;
    max-width: fit-content;

    & span {
      align-items: flex-end;
      width: auto;
    }

    && svg {
      margin-right: 0.3rem;
      width: 1.2rem;
    }
  }
`;
