import { API_COMMON_ERROR } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

import { prepareResponseFailure } from '../../helpers';
import { apiCall } from '../../services';

export interface UploadAttachmentArgs {
  files?: File[];
  endpoint: string;
}

async function uploadAttachment<T>({
  files = [],
  endpoint,
}: UploadAttachmentArgs): Promise<BackResponse<T> | BackResponseFailure> {
  const body = new FormData();

  const someFileHasUndefinedName = files.some(
    (file) => file.name === 'undefined' || file.name === undefined,
  );

  if (someFileHasUndefinedName) {
    return prepareResponseFailure({
      errors: [{ code: API_COMMON_ERROR.UNHANDLED }],
    });
  }

  if (files) {
    files.forEach((file) => {
      body.append('fileBinary', file);
    });
  }

  try {
    const response = await apiCall<BackResponse<T>>(endpoint, {
      method: SUPPORTED_METHODS.POST,
      body,
    });

    if (response.statusCode === 401) {
      return prepareResponseFailure({
        errors: [{ code: API_COMMON_ERROR.UNHANDLED }],
      });
    }

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default uploadAttachment;
