import { AdditionalInformation } from 'myaccount/api/Claim/getAdditionalInformationEntitySummaryQuery';
import { AdditionalClaimInformation } from 'myaccount/api/Claim/getClaimGroupConfirmationQuery';

export const getAdditionalInformationValue = ({
  additionalInformations,
  additionalClaimInformation,
  comparaisonRule,
  compareValue,
}: {
  additionalInformations?: AdditionalInformation[] | undefined;
  additionalClaimInformation?: AdditionalClaimInformation[];
  comparaisonRule: string;
  compareValue: string;
}) => {
  const additionalInformationId = additionalInformations?.find(
    (additionalInformation) => {
      const compareElement: Record<string, string | boolean> =
        additionalInformation.additionalInformationEntity;

      return compareElement[comparaisonRule] === compareValue;
    },
  )?.id;

  return additionalClaimInformation?.find(
    (additionalInformation) =>
      additionalInformation.additionalInformationId === additionalInformationId,
  )?.additionalInformationStringValue;
};

export const getMultiAdditionalInformationValue = ({
  additionalInformations,
  additionalClaimInformation,
  comparaisonRule,
  compareValues,
}: {
  additionalInformations?: AdditionalInformation[] | undefined;
  additionalClaimInformation?: AdditionalClaimInformation[];
  comparaisonRule: string;
  compareValues: string[];
}): Record<string, string | undefined> => {
  const returnValue: Record<string, string | undefined> = {};

  compareValues.forEach((compareValue) => {
    returnValue[compareValue] = getAdditionalInformationValue({
      additionalInformations,
      additionalClaimInformation,
      comparaisonRule,
      compareValue,
    });
  });

  return returnValue;
};
