import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  FileShortSummary,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.workflow}owners/${COMMON_QUERY_PARAMS.OWNER_ID}/files/closed`;

interface GetClosedFilesQueryPayload {
  ownerId: string;
  orderId?: string;
}

type GetClosedFilesQueryResponse = FileShortSummary[];

type GetClosedFilesQuery = (
  payload: GetClosedFilesQueryPayload,
) => Promise<BackResponse<GetClosedFilesQueryResponse> | BackResponseFailure>;

const getClosedFilesQuery: GetClosedFilesQuery = async ({
  ownerId,
  orderId,
}) => {
  try {
    return await apiCall<BackResponse<GetClosedFilesQueryResponse>>(
      buildUrl(ENDPOINT.replace(COMMON_QUERY_PARAMS.OWNER_ID, ownerId), {
        orderId,
      }),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default getClosedFilesQuery;
