import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';
import { PaymentProvider } from '@savgroup-front-common/types/src/Payment/PaymentProvider';

const ENDPOINT = `${APIConfiguration.payment}payment/${COMMON_QUERY_PARAMS.CLAIM_GROUP_ID}`;

export interface GetPaymentAccountForClaimQueryArgs {
  claimGroupId: string;
}

export interface PaymentAccountDto {
  publicToken: string;
  paymentProvider: PaymentProvider;
}

export async function getPaymentAccountForContextQuery(
  payload: GetPaymentAccountForClaimQueryArgs,
): Promise<BackResponse<PaymentAccountDto> | BackResponseFailure> {
  try {
    const url = ENDPOINT.replace(
      COMMON_QUERY_PARAMS.CLAIM_GROUP_ID,
      payload.claimGroupId,
    );

    return await apiCall<BackResponse<PaymentAccountDto>>(url, {
      method: SUPPORTED_METHODS.GET,
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
