import { Dictionary } from 'lodash';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';

import {
  COUNTRY_CODES,
  HANDLING_GROUPS,
} from '@savgroup-front-common/constants';
import { loadPickupPointsForCarrier } from '@savgroup-front-common/core/src/domains/claims/actionCreators';
import { AddressInfoDto, PickupPoint } from '@savgroup-front-common/types';
import { Carrier } from 'myaccount/api/Claim/getClaimGroupCarrierQuery';
import { profileManagementSelector } from 'myaccount/domains/ProfileManagement/selectors';
import { useGetClaimGroupQuery } from 'myaccount/view/app/hooks/useGetClaimGroupQuery';
import { useGetMultiPickupPoints } from 'myaccount/view/app/hooks/useGetMultiPickupPoints';
import { useGetOrderByOwnerId } from 'myaccount/view/app/hooks/useGetOrderByOwnerId';
import { useLayout } from 'myaccount/view/app/NewLayout/NewLayout.hooks';

import { getPickupPoints } from '../../../helpers/getPickupPoints';
import { preparePickupPoints } from '../../../helpers/preparePickupPoints';

import useGetDefaultAddress from './useGetDefaultAddress';

export interface AddressSelected {
  address?: string;
  countryCode?: COUNTRY_CODES | string;
  defaultAddress?: AddressInfoDto;
  location?: {
    lat: number;
    lng: number;
  };
}
interface UseGetPickupPointsArgs {
  selectedCarrierType?: HANDLING_GROUPS;
  groupedCarriers: Dictionary<Carrier[]>;
  claimGroupId?: string;
}

const useGetPickupPoints = ({
  groupedCarriers,
  selectedCarrierType,
  claimGroupId,
}: UseGetPickupPointsArgs) => {
  const dispatch = useDispatch();

  const { ownerId } = useLayout();
  const { orders } = useGetOrderByOwnerId({ ownerId });
  const sellerId = orders ? orders[0].sellerId : undefined;
  const { claimId: claimIds } = useGetClaimGroupQuery({
    claimGroupId: claimGroupId || '',
  });
  const claimId = claimIds ? claimIds[0] : undefined;
  const profilAddress = useSelector(profileManagementSelector);
  const { addresses } = profilAddress;

  const [addressSelected, setAddressSelected] = useState<
    AddressSelected | undefined
  >({
    address: `${addresses[0].address} ${addresses[0].postalCode} ${addresses[0].city} ${addresses[0].countryCode}`,
    countryCode: addresses[0].countryCode,
    defaultAddress: addresses[0],
  });

  const carriers =
    groupedCarriers[selectedCarrierType || HANDLING_GROUPS.PICKUP_POINT];

  const pickupsPrepared = preparePickupPoints({
    carriers,
    countryCode: addressSelected?.countryCode || COUNTRY_CODES.FR,
    distance: 200,
    maxPickupPoint: 10,
    sellerId,
    address: addressSelected?.address || '',
    claimId,
  });

  const { pickupPointsAddresses, isLoading: isLoadingPickupPointAddresses } =
    useGetMultiPickupPoints({
      pickupsArg: pickupsPrepared,
    });

  const { pickupPoints } = getPickupPoints({
    carriers,
    pickupPoints: pickupPointsAddresses?.flat(1),
  });

  const [selectedPickupPoint, setSelectedPickupPoint] = useState<
    PickupPoint | undefined
  >(undefined);
  const handleSetSelectedPickupPoint = useCallback((payload: PickupPoint) => {
    setSelectedPickupPoint(payload);
  }, []);

  const defaultAddress = useGetDefaultAddress({
    claimGroupId,
  });

  useDeepCompareEffect(() => {
    setAddressSelected(defaultAddress);
  }, [defaultAddress]);

  const handleSetAddressSelected = useCallback(
    (payload: AddressSelected) => {
      const { address, countryCode } = payload;

      carriers.forEach(({ productType, carrierBrand }) => {
        if (!sellerId || !claimId || !carrierBrand) {
          return;
        }

        dispatch(
          loadPickupPointsForCarrier({
            sellerId,
            claimId,
            address,
            countryCode,
            maxDistance: 200,
            carrierCompany:
              productType === 'DropAtStore' ||
              productType === 'PickUpStoreDelivery'
                ? 'ExternalCarrier'
                : carrierBrand,
            productType,
          }),
        );
      });
      setAddressSelected(payload);
    },
    [carriers, claimId, dispatch, sellerId],
  );

  return {
    sellerId,
    carriers,
    pickupPoints,
    noPickupPoints:
      !pickupPointsAddresses || pickupPointsAddresses.length === 0,
    addresses,
    addressSelected,
    handleSetAddressSelected,
    handleSetSelectedPickupPoint,
    selectedPickupPoint,
    pickupPointsIsLoading: isLoadingPickupPointAddresses,
  };
};

export default useGetPickupPoints;
