import { createAction } from '@reduxjs/toolkit';

import * as actionTypes from './actionTypes';
import { LOAD_FULL_CLAIMS_BY_GROUP_ID } from './actionTypes';

export {
  loadStoreActorByClaimId,
  setStoreActor,
} from './actionCreators/storeActor';

export const loadClaimsByGroupId = createAction<string>(
  actionTypes.LOAD_CLAIMS_BY_GROUP_ID.BASE,
);

export const loadPickupPointsForCarrier = createAction<{
  sellerId: string;
  claimId: string;
  address: unknown;
  countryCode?: string;
  maxDistance: number;
  carrierCompany: string;
  productType: string;
}>(actionTypes.LOAD_PICKUP_POINTS_OPTIONS_BY_CARRIER.BASE);

export const loadFullClaimsByGroupId = createAction<{
  claimGroupId: string;
  forceRefresh?: boolean;
}>(LOAD_FULL_CLAIMS_BY_GROUP_ID.BASE);

export const loadTranslation = createAction<{ warrantyTypeId: string }>(
  actionTypes.LOAD_TRANSLATION.BASE,
);

export const setDocumentForClaimGroupSuccess = createAction(
  actionTypes.SET_DOCUMENT_FOR_CLAIM_GROUP.SUCCEEDED,
);

export const deleteDocumentForClaimGroup = createAction<{
  claimGroupId: string;
  documentId: string;
  claimId: string;
}>(actionTypes.DELETE_DOCUMENT_FOR_CLAIM_GROUP.BASE);

export const deleteClaimGroupById = createAction<string>(
  actionTypes.DELETE_CLAIM_GROUP_BY_ID.BASE,
);

export const loadConfirmationInfoByClaimGroup = createAction<string>(
  actionTypes.LOAD_CONFIRMATION_INFO_BY_CLAIM_GROUP.BASE,
);

export const getRmaSupplierInfos = createAction<string>(
  actionTypes.GET_RMA_SUPPLIER_INFOS.BASE,
);

export const getOrderByClaimGroup = createAction<string>(
  actionTypes.GET_ORDER_BY_CLAIM_GROUP.BASE,
);

export * from './actionCreators/claimDeadEnd';
