import React, { useContext, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { Grid, Row } from 'react-styled-flexboxgrid';
import { useTheme } from 'styled-components';

import {
  FormGroup,
  RadioButtonGroup,
} from '@savgroup-front-common/core/src/atoms/Form';
import { RadioCardColumn } from '@savgroup-front-common/core/src/molecules/RadioCardColumn';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';

import useIssuesPage from './IssuesPage.hooks';
import { $Col } from './IssuesPage.styles';
import { getColumn } from './IssuesPage.helpers';
import {
  $ColSvgItem,
  $RowContainer,
} from '../../../../../components/common/Container';
import { sortBy } from '@savgroup-front-common/core/src/helpers';
import { ICONS_TYPE } from '@savgroup-front-common/types';
import { IssueSummaryDto } from '../../../../../../types/IssueSummaryDto';
import { useHistory } from 'react-router-dom';
import { buildProfessionalRoute } from 'myaccount/view/app/NewLayout/ProfessionalRoutes.helpers';
import {
  PROFESSIONAL_STEP_CONFIG,
  PROFESSIONAL_HOME_CONFIG,
  PROFESSIONAL_STEP_PROGRESS,
} from 'myaccount/view/app/NewLayout/ProfessionalRoutes.config';
import StepLayoutProfessional from 'myaccount/view/app/NewLayout/StepLayout/StepLayoutProfessional';
import { ProfessionalPagesContextProvider } from '../../../ProfessionalPages.context';

const IssuesPage: React.FC = () => {
  const theme = useTheme();
  const { issues, issuesCount, formContext, onSubmit } = useIssuesPage();

  const { onReset } = useContext(ProfessionalPagesContextProvider);

  useEffect(() => {
    onReset();
  }, [onReset]);

  const {
    control,
    formState: { isValid },
  } = formContext;
  const history = useHistory();

  const handlePreviousStep = () => {
    history.push(
      buildProfessionalRoute({
        route: PROFESSIONAL_HOME_CONFIG.PROFESSIONAL_HOME_PAGE,
      }),
    );
  };

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();

        return undefined;
      }}
    >
      <StepLayoutProfessional
        handlePreviousStep={handlePreviousStep}
        stepName={PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_ISSUES_REASONS_PAGE}
        isDisabled={!isValid}
        customSteps={PROFESSIONAL_STEP_PROGRESS}
        noConfirmButton
      >
        <FormGroup>
          <Controller
            control={control}
            name="choiceIssue"
            render={({ field }) => {
              return (
                <RadioButtonGroup name="choiceIssueRadioButtonGroup">
                  <Grid fluid>
                    <$RowContainer>
                      {issues
                        .sort(sortBy({ fieldName: 'displayOrder' }))
                        .map((issue: IssueSummaryDto) => {
                          return (
                            <$Col
                              key={issue.name}
                              xs={12}
                              sm={6}
                              md={getColumn(issuesCount)}
                            >
                              <RadioCardColumn
                                title={issue.name}
                                didactics={issue.didactic}
                                name={field.name}
                                value={issue.id}
                                isChecked={field.value === issue.id}
                                onChange={field.onChange}
                                onClick={() =>
                                  onSubmit({ choiceIssue: issue.id })
                                }
                                image={
                                  <Row center="xs">
                                    <$ColSvgItem xs={12}>
                                      <MyAccountIcon
                                        icon={
                                          issue.iconKey ||
                                          ICONS_TYPE.ADD_CIRCLE_OUTLINE_ICON
                                        }
                                        color={
                                          field.value === issue.id
                                            ? theme.colors.primary
                                            : theme.colors.paragraphTextColor
                                        }
                                      />
                                    </$ColSvgItem>
                                  </Row>
                                }
                              />
                            </$Col>
                          );
                        })}
                    </$RowContainer>
                  </Grid>
                </RadioButtonGroup>
              );
            }}
          />
        </FormGroup>
      </StepLayoutProfessional>
    </form>
  );
};

IssuesPage.displayName = 'IssuesPage';

export default IssuesPage;
