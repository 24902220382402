import React, { FunctionComponent, MutableRefObject } from 'react';
import { UseFormRegister } from 'react-hook-form';

import Switch, { SwitchProps } from '../../../atoms/Form/Switch';

function mergeRefs(
  refs: Array<
    | MutableRefObject<HTMLInputElement | null>
    | ((instance: HTMLInputElement | null) => void)
    | null
  >,
): React.RefCallback<HTMLInputElement | null> {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref !== null) {
         
        ref.current = value;
      }
    });
  };
}

interface SwitchHookFormProps extends Exclude<SwitchProps, 'onChange'> {
  register: UseFormRegister<any>;
  name: string;
}
interface SwitcgHookFormPWithRef extends SwitchHookFormProps {
  forwardedRef:
    | MutableRefObject<HTMLInputElement | null>
    | ((instance: HTMLInputElement | null) => void)
    | null;
}

const SwitchHookForm: FunctionComponent<React.PropsWithChildren<SwitcgHookFormPWithRef>> = ({
  register,
  name,
  forwardedRef,
  ...rest
}) => {
  const { ref, ...resgisteredInput } = register(name);

  return (
    <Switch
      {...rest}
      {...resgisteredInput}
      ref={mergeRefs([ref, forwardedRef])}
    />
  );
};

SwitchHookForm.displayName = 'SwitchHookForm';

export default React.forwardRef<HTMLInputElement, SwitchHookFormProps>(
  (props, ref) => <SwitchHookForm forwardedRef={ref} {...props} />,
);
