export enum QuotationState {
  DRAFT = 'draft',
  PENDING_CUSTOMER_APPROVAL = 'pendingCustomerApproval',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  CANCELLED = 'cancelled',
  PAID = 'paid',
  END_OF_MONTH_CONSOLIDATED_PAYMENT = 'endOfMonthConsolidatedPayment',
}

export enum QUOTATION_STATE_BY_KEY {
  Draft = 'draft',
  PendingCustomerValidation = 'pendingCustomerApproval',
  Approved = 'approved',
  Rejected = 'rejected',
  Cancelled = 'cancelled',
  Paid = 'paid',
  EndOfMonthConsolidatedPayment = 'endOfMonthConsolidatedPayment',
}

export enum RawQuotationState {
  DRAFT = 'Draft',
  PENDING_CUSTOMER_APPROVAL = 'PendingCustomerValidation',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  CANCELLED = 'Cancelled',
  PAID = 'Paid',
  END_OF_MONTH_CONSOLIDATED_PAYMENT = 'EndOfMonthConsolidatedPayment',
}

export enum QUOTATION_PAYMENT_TYPE {
  IMMEDIATE = 'Immediate',
  END_OF_MONTH = 'EndOfMonth',
}
