import React from 'react';

const IconDiagnosticTreeEntry = () => {
  return (
    <svg
      width="142"
      height="107"
      viewBox="0 0 142 107"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12.7821" cy="23.1625" r="3.309" fill="#EAEEF9" />
      <circle cx="6.1641" cy="80.7391" r="5.95585" fill="#EAEEF9" />
      <circle cx="135.876" cy="18.5311" r="3.97056" fill="#EAEEF9" />
      <circle cx="73.0058" cy="52.944" r="52.944" fill="#EAEEF9" />
      <path
        // eslint-disable-next-line max-len
        d="M75.9499 87.4576H70.0617C69.315 87.4576 68.7096 86.8522 68.7096 86.1056C68.7096 85.0229 67.8283 84.1415 66.7456 84.1415C65.9989 84.1415 65.3936 83.5361 65.3936 82.7895V77.8638C65.3936 77.1171 65.9989 76.5117 66.7456 76.5117H79.266C80.0126 76.5117 80.618 77.1171 80.618 77.8638V82.7895C80.618 83.5361 80.0126 84.1415 79.266 84.1415C78.1833 84.1415 77.3019 85.0229 77.3019 86.1056C77.3019 86.8522 76.6966 87.4576 75.9499 87.4576ZM71.2144 84.7535H74.7972C75.2487 83.2648 76.4252 82.0883 77.9138 81.6368V79.216H68.0976V81.6368C69.5869 82.0884 70.7635 83.2649 71.2144 84.7535Z"
        fill="#205DF3"
        fillOpacity="0.7"
      />
      <path
        // eslint-disable-next-line max-len
        d="M79.2657 79.2151H66.7447C65.998 79.2151 65.3926 78.6097 65.3926 77.863C65.3926 72.124 63.3157 70.0517 59.1771 65.923L58.1525 64.8971C54.2297 60.9499 52.0696 55.7094 52.0696 50.142C52.0696 44.1963 54.614 38.5096 59.0511 34.5386C63.4836 30.5722 69.4384 28.6729 75.3959 29.3403C84.8721 30.3946 92.6008 38.0191 93.7737 47.4696C94.5699 53.8814 92.4599 60.1867 87.9839 64.7692C87.5316 65.2326 87.0953 65.6723 86.6767 66.0948C82.7691 70.0354 80.617 72.2068 80.617 77.8633C80.6176 78.6099 80.0116 79.2153 79.2656 79.2153L79.2657 79.2151ZM68.0586 76.511H77.9527C78.298 70.7052 80.9222 68.0593 84.7573 64.1907C85.1719 63.7728 85.603 63.3377 86.0506 62.8796C89.9482 58.8888 91.7856 53.3934 91.0917 47.8023C90.0704 39.5798 83.3444 32.9456 75.0974 32.0272C69.8377 31.4476 64.7714 33.0485 60.8552 36.5541C56.9905 40.0121 54.7743 44.9649 54.7743 50.1419C54.7743 54.9903 56.6559 59.5535 60.0717 62.9909L61.0877 64.0083C65.0198 67.9325 67.7094 70.6148 68.0586 76.511Z"
        fill="#205DF3"
        fillOpacity="0.7"
      />
      <path
        // eslint-disable-next-line max-len
        d="M73.0068 27.3902C72.2602 27.3902 71.6548 26.7848 71.6548 26.0381V21.2056C71.6548 20.4589 72.2602 19.8535 73.0068 19.8535C73.7535 19.8535 74.3589 20.4589 74.3589 21.2056V26.0381C74.3589 26.7848 73.7535 27.3902 73.0068 27.3902Z"
        fill="#205DF3"
        fillOpacity="0.7"
      />
      <path
        // eslint-disable-next-line max-len
        d="M55.9201 34.4688C55.5741 34.4688 55.2282 34.3368 54.9641 34.0727L51.547 30.6555C51.0188 30.1274 51.0188 29.2718 51.547 28.7437C52.0751 28.2157 52.9307 28.2156 53.4588 28.7437L56.8759 32.1609C57.4041 32.689 57.4041 33.5446 56.8759 34.0727C56.6125 34.3368 56.2667 34.4688 55.9201 34.4688Z"
        fill="#205DF3"
        fillOpacity="0.7"
      />
      <path
        // eslint-disable-next-line max-len
        d="M48.8436 51.5537H44.011C43.2643 51.5537 42.6589 50.9483 42.6589 50.2017C42.6589 49.455 43.2643 48.8496 44.011 48.8496H48.8436C49.5902 48.8496 50.1956 49.455 50.1956 50.2017C50.1956 50.9483 49.5902 51.5537 48.8436 51.5537Z"
        fill="#205DF3"
        fillOpacity="0.7"
      />
      <path
        // eslint-disable-next-line max-len
        d="M52.5041 72.0567C52.1582 72.0567 51.8123 71.9247 51.5482 71.6606C51.0201 71.1325 51.0201 70.2769 51.5482 69.7488L54.9654 66.3317C55.4935 65.8035 56.3491 65.8035 56.8772 66.3317C57.4052 66.8598 57.4053 67.7154 56.8772 68.2435L53.46 71.6606C53.1953 71.9247 52.8494 72.0567 52.5041 72.0567Z"
        fill="#205DF3"
        fillOpacity="0.7"
      />
      <path
        // eslint-disable-next-line max-len
        d="M93.5087 72.0567C93.1628 72.0567 92.8168 71.9247 92.5528 71.6606L89.1356 68.2434C88.6075 67.7153 88.6075 66.8597 89.1356 66.3316C89.6638 65.8036 90.5194 65.8035 91.0474 66.3316L94.4646 69.7488C94.9927 70.2769 94.9927 71.1325 94.4646 71.6606C94.2011 71.9247 93.8546 72.0567 93.5087 72.0567Z"
        fill="#205DF3"
        fillOpacity="0.7"
      />
      <path
        // eslint-disable-next-line max-len
        d="M102.001 51.5537H97.1687C96.422 51.5537 95.8167 50.9483 95.8167 50.2017C95.8167 49.455 96.422 48.8496 97.1687 48.8496H102.001C102.748 48.8496 103.353 49.455 103.353 50.2017C103.353 50.9483 102.748 51.5537 102.001 51.5537Z"
        fill="#205DF3"
        fillOpacity="0.7"
      />
      <path
        // eslint-disable-next-line max-len
        d="M90.0928 34.4688C89.7468 34.4688 89.4009 34.3368 89.1368 34.0727C88.6087 33.5446 88.6087 32.689 89.1368 32.1609L92.554 28.7438C93.0821 28.2156 93.9377 28.2156 94.4658 28.7438C94.9938 29.2719 94.9939 30.1275 94.4658 30.6556L91.0486 34.0727C90.7839 34.3368 90.4387 34.4688 90.0928 34.4688Z"
        fill="#205DF3"
        fillOpacity="0.7"
      />
      <path
        // eslint-disable-next-line max-len
        d="M69.9063 57.2539C69.5478 57.2539 69.2038 57.1113 68.9503 56.8578L63.7513 51.6582C63.2232 51.13 63.2232 50.2744 63.7513 49.7463C64.2795 49.2183 65.1351 49.2182 65.6631 49.7463L69.9061 53.9899L80.3495 43.5465C80.8776 43.0184 81.7332 43.0184 82.2613 43.5465C82.7894 44.0746 82.7894 44.9302 82.2613 45.4583L70.862 56.8576C70.6078 57.1112 70.2638 57.2538 69.906 57.2538L69.9063 57.2539Z"
        fill="#205DF3"
        fillOpacity="0.7"
      />
      <circle cx="138.524" cy="2.6472" r="2.6472" fill="#EAEEF9" />
    </svg>
  );
};

IconDiagnosticTreeEntry.displayName = 'IconDiagnosticTreeEntry';

export default IconDiagnosticTreeEntry;
