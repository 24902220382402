import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import {
  HANDLING_GROUPS,
  HANDLING_MODES,
  REVALIDATE_MODES,
} from '@savgroup-front-common/constants';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import {
  AddressInfoDto,
  PickupPointAddress,
} from '@savgroup-front-common/types';
import { ClaimService } from 'myaccount/api';
import { useGetClaimGroupCarrierQuery } from 'myaccount/view/app/hooks/useGetClaimGroupCarrierQuery';
import { useGetClaimGroupConfirmation } from 'myaccount/view/app/hooks/useGetClaimGroupConfirmation';
import { useGetHandlingByClaimGroup } from 'myaccount/view/app/hooks/useGetHandlingByClaimGroup';
import useGetMultiCarrierProductByIdQuery from 'myaccount/view/app/hooks/useGetMultiCarrierProductByIdQuery';
import { STEP_CONFIG } from 'myaccount/view/app/NewLayout/ClassiqueRoutes.config';
import {
  StepContext,
  StepContextValues,
} from 'myaccount/view/app/NewLayout/StepsProvider/StepsProvider.context';

import { getGroupedCarriers } from '../../../helpers';
import useGetHomePickup from '../../app/hooks/useGetHomePickup';

import ClaimGroupDeliveryPageSchema from './ClaimGroupDeliveryPage.schema';
import { ClaimGroupDeliveryValues } from './ClaimGroupDeliveryPage.types';
import useSuggestedAddress from './hooks/useSuggestedAddress';

const useClaimGroupDeliveryPage = () => {
  const { removeAllNotifications, pushErrors } = useToasts();
  const { values, handleUpdateValue, handleNextStep, handleValidateStep } =
    useContext<StepContextValues>(StepContext);

  const { homePickupAddress, mail } = useGetHomePickup();
  const claimGroupId = values?.claimGroup?.claimGroupId;
  const depositAddress =
    values?.claimGroup?.claims &&
    values?.claimGroup?.claims[0] &&
    values?.claimGroup?.claims[0].depositAddress
      ? values?.claimGroup?.claims[0]?.depositAddress
      : undefined;
  const ownerAddress = depositAddress || homePickupAddress;
  const { claimGroupConfirmation, isLoading: isGetClaimGroupConfirmation } =
    useGetClaimGroupConfirmation({
      claimGroupId,
    });

  const { handling, isLoading: isGetHandlingLoading } =
    useGetHandlingByClaimGroup({ claimGroupId });
  const { carriers, isLoading: isGetCarrierLoading } =
    useGetClaimGroupCarrierQuery({
      claimGroupId,
      handlingMode: HANDLING_MODES.DELIVERY,
    });

  const { multiCarrierProductSummary } = useGetMultiCarrierProductByIdQuery({
    carrierProductIds: carriers
      ?.map((carrier) => carrier.carrierProductId)
      .filter((carrier) => carrier) as string[],
  });

  const { groupedCarriers } = getGroupedCarriers({
    carriers,
    multiCarrierProductSummary,
    handlingInfo: handling,
  });

  const shouldShowTransportDeduction =
    handling?.canDeduceTransportFromRefund &&
    carriers?.some((carrier) => carrier.priceWithTax);

  const formContext = useForm<ClaimGroupDeliveryValues>({
    defaultValues: ownerAddress
      ? {
          addressSelected: {
            ...ownerAddress,
            firstName: ownerAddress.firstname,
            lastName: ownerAddress.lastname,
            mail,
          },
        }
      : {},
    resolver: yupResolver(ClaimGroupDeliveryPageSchema),
    mode: REVALIDATE_MODES.ON_CHANGE,
  });

  const {
    handleSubmit,
    formState: { isValid },
    setValue,
    watch,
  } = formContext;

  const [hasChangeValue, setChangedValue] = useState(false);

  const changeSelection = () => {
    setValue('chooseHandling', undefined);
    setChangedValue(false);
  };

  const {
    mutateAsync: handleDeliveryHandling,
    isLoading: isLoadingDeliveryHandlingSubmit,
  } = useMutation(
    ['setDeliveryHandling'],
    async ({
      claimGroupId,
      payload,
    }: {
      claimGroupId?: string;
      payload: {
        handlingMode: HANDLING_MODES;
        pickupPointId?: string;
        pickupPointAddress?: PickupPointAddress | null;
        carrierCustomerPriceId?: string;
        carrierSellerPriceId?: string;
        address?: AddressInfoDto | null;
      };
    }) => {
      removeAllNotifications();
      const responseHandling = await ClaimService.setClaimGroupHandling({
        claimGroupId,
        payload,
      });

      if (responseHandling.failure) {
        pushErrors(responseHandling.errors);

        return undefined;
      }

      return responseHandling;
    },
  );

  const { handleAddressSubmit } = useSuggestedAddress({
    formContext,
  });

  const chooseHandlingSelected = watch('chooseHandling');
  const handlingSelected = Boolean(
    chooseHandlingSelected === HANDLING_GROUPS.PICKUP_POINT ||
      chooseHandlingSelected === HANDLING_GROUPS.PICKUP_STORE ||
      chooseHandlingSelected === HANDLING_GROUPS.PICKUP_STORE_DELIVERY,
  );

  const homeSelectedAddressChanged = Boolean(
    hasChangeValue &&
      (chooseHandlingSelected === HANDLING_GROUPS.HOME ||
        chooseHandlingSelected === HANDLING_GROUPS.HOME_INTERVENTION ||
        chooseHandlingSelected === HANDLING_GROUPS.EXTERNAL),
  );

  const onSubmit = handleSubmit(
    async ({
      chooseHandling,
      pickupPointSelected,
      addressSelected,
      addressSuggested,
      keepSuggestedAddress,
      keepAddressEntered,
    }) => {
      Promise.resolve(handleAddressSubmit({ addressSelected }))
        .then((shouldBeChoiceAnAddress) => {
          const minimumPrice = Math.min(
            ...groupedCarriers[chooseHandling || HANDLING_GROUPS.HOME].map(
              (carrier) => carrier.priceWithTax || 0,
            ),
          );

          const carrier = groupedCarriers[
            chooseHandling || HANDLING_GROUPS.HOME
          ].find((carrier) => (carrier.priceWithTax || 0) === minimumPrice);

          if (!carrier) {
            throw new Error('carrier not found');
          }

          const payload = pickupPointSelected
            ? {
                handlingMode: HANDLING_MODES.DELIVERY,
                pickupPointId: pickupPointSelected.id,
                pickupPointAddress: pickupPointSelected && {
                  id: pickupPointSelected.id,
                  name: pickupPointSelected.name,
                  firstName: pickupPointSelected.name,
                  address: pickupPointSelected.adress,
                  additionalAddress: pickupPointSelected.adress3,
                  postalCode: pickupPointSelected.postalCode,
                  city: pickupPointSelected.city,
                  countryCode: pickupPointSelected.countryCode,
                },
                carrierCustomerPriceId:
                  pickupPointSelected.carrierCustomerPriceId,
                carrierSellerPriceId: pickupPointSelected.carrierSellerPriceId,
                address: undefined,
              }
            : {
                handlingMode: HANDLING_MODES.DELIVERY,
                pickupPointId: undefined,
                pickupPointAddress: null,
                carrierCustomerPriceId: carrier.carrierCustomerPriceId,
                carrierSellerPriceId: carrier.carrierSellerPriceId,
                address: keepSuggestedAddress
                  ? addressSuggested
                  : addressSelected,
              };

          return {
            shouldBeChoiceAnAddress,
            payload,
          };
        })
        .then(async ({ shouldBeChoiceAnAddress, payload }) => {
          const choiceAnAddress =
            keepSuggestedAddress !== undefined ||
            keepAddressEntered !== undefined ||
            shouldBeChoiceAnAddress;

          const responseHandling = await handleDeliveryHandling({
            claimGroupId,
            payload,
          });

          if (!responseHandling) {
            return undefined;
          }

          return { choiceAnAddress, responseHandling };
        })
        .then((payload) => {
          const choiceAnAddress = payload?.choiceAnAddress;

          if (payload && (choiceAnAddress || !homeSelectedAddressChanged)) {
            changeSelection();

            handleUpdateValue({
              value: {
                claimGroup: {
                  ...values?.claimGroup,
                  claims: values?.claimGroup?.claims?.map((claim) => {
                    return {
                      ...claim,
                      deliveryAddress: keepSuggestedAddress
                        ? addressSuggested
                        : addressSelected,
                    };
                  }),
                },
                handlingDelivery: {
                  chooseHandling,
                  pickupPointSelected,
                },
              },
            });

            return handleNextStep(
              STEP_CONFIG.CLAIM_GROUP_CONFIRMATION,
              claimGroupId,
            );
          }

          return undefined;
        });
    },
  );

  useEffect(() => {
    handleValidateStep(!!isValid);
  }, [handleValidateStep, isValid]);

  const isDeliveryHandlingLoading =
    isGetCarrierLoading || isGetHandlingLoading || isGetClaimGroupConfirmation;

  return {
    claimGroupConfirmation,
    claimGroupId,
    groupedCarriers,
    shouldShowTransportDeduction,
    formContext,
    setChangedValue,
    changeSelection,
    onSubmit,
    isDeliveryHandlingLoading,
    isLoadingSubmit: isLoadingDeliveryHandlingSubmit,
    chooseHandlingSelected,
    handlingSelected,
    homeSelectedAddressChanged,
    hasDeposit: values?.handlingStatus?.hasDeposit,
  };
};

export default useClaimGroupDeliveryPage;
