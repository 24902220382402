import React, { FunctionComponent } from 'react';
import { useMedia } from 'react-use';

import { media } from '@savgroup-front-common/constants';
import { FileStateHistorySummary } from 'myaccount/api/Workflow/getFileStatesQuery';

import { useProductInfoHistory } from './ProductInfoHistory.hooks';
import {
  $CardContentHistory,
  $CardContentHistoryBlankSpace,
  $CardContentHistoryLegend,
  $CardContentHistoryLegendWrapper,
  $CardContentHistoryLine,
  $CardContentHistoryPoint,
  $CardContentHistorySmallLine,
  $CardContentHistoryWrapper,
  $Divider,
} from './ProductInfoHistory.styles';
import ProductInfoHistoryDashedLine from './ProductInfoHistoryDashedLine';
import ProductInfoHistoryDashedSmallLine from './ProductInfoHistoryDashedSmallLine';

const ProductInfoHistory: FunctionComponent<{
  histories: FileStateHistorySummary[];
}> = ({ histories }) => {
  const isMobileView = useMedia(media.maxWidth.xs);
  const { isFirst, isLast, productsHistory } = useProductInfoHistory({
    histories,
  });

  return (
    <>
      {isMobileView && <$Divider />}
      <$CardContentHistoryWrapper>
        {productsHistory.length > 0 && (
          <>
            <$CardContentHistory>
              <$CardContentHistoryBlankSpace />
              <$CardContentHistorySmallLine>
                <ProductInfoHistoryDashedSmallLine isFirst={isFirst} />
              </$CardContentHistorySmallLine>
              <$CardContentHistoryPoint
                active={productsHistory[0].active}
                disabled={productsHistory[0].disabled}
              />
              <$CardContentHistoryLine>
                <ProductInfoHistoryDashedLine
                  isDashed={productsHistory[1].disabled}
                  disabled={productsHistory[1].disabled}
                />
              </$CardContentHistoryLine>
              <$CardContentHistoryPoint
                active={productsHistory[1].active}
                disabled={productsHistory[1].disabled}
              />
              <$CardContentHistorySmallLine>
                <ProductInfoHistoryDashedSmallLine disabled isFirst={isLast} />
              </$CardContentHistorySmallLine>
              <$CardContentHistoryBlankSpace />
            </$CardContentHistory>
            <$CardContentHistoryLegendWrapper>
              <$CardContentHistoryLegend disabled={productsHistory[0].disabled}>
                {productsHistory[0].label}
              </$CardContentHistoryLegend>
              <$CardContentHistoryLegend disabled={productsHistory[1].disabled}>
                {productsHistory[1].label}
              </$CardContentHistoryLegend>
            </$CardContentHistoryLegendWrapper>
          </>
        )}
      </$CardContentHistoryWrapper>
    </>
  );
};

ProductInfoHistory.displayName = 'ProductInfoHistory';
export default ProductInfoHistory;
