import { isValid, parse, parseISO } from 'date-fns';
import { STANDARD_DATE_FORMAT_DATE_FNS, STANDARD_DATE_TIME_FORMAT_DATE_FNS } from '@savgroup-front-common/constants';

export const tryToParse = ({
  value,
  patterns = [],
}: {
  value: string;
  patterns?: string[];
}): { failure: false; value: Date } | { failure: true; value?: undefined } => {
  if (!value) {
    return { failure: true, value: undefined };
  }

  const [firstPattern, ...rest] = patterns;

  const result = firstPattern
    ? parse(value, firstPattern, new Date())
    : parseISO(value);

  if (isValid(result)) {
    return { failure: false, value: result };
  }

  if (rest.length > 0) {
    const nextPatternResult = tryToParse({ value, patterns: rest });

    if (!nextPatternResult.failure) {
      return nextPatternResult;
    }
  }

  return { failure: true };
};

export const parseDate = (value: string): Date => {
  const response = tryToParse({
    value,
    patterns: [
      STANDARD_DATE_FORMAT_DATE_FNS,
      STANDARD_DATE_TIME_FORMAT_DATE_FNS,
    ],
  });

  if (response.failure) {
    throw new Error(`Failed to parse date ${value}`);
  }

  return response.value;
};
