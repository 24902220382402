import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import Origin from '@savgroup-front-common/constants/src/shared/origin';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

 
const ENDPOINT = `${APIConfiguration.claim}claimGroups/withIssue`;

interface SetIssueForClaimArgs {
  issueId: string;
  ownerId: string;
  ownerProductIds: string[];
}

const setCreateClaimGroup = async ({
  issueId,
  ownerId,
  ownerProductIds,
}: SetIssueForClaimArgs): Promise<
  BackResponse<string> | BackResponseFailure
> => {
  try {
    const response = await apiCall<BackResponse<string>>(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: {
        issueId,
        ownerId,
        ownerProductIds,
        origin: Origin.MY_ACCOUNT,
      },
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default setCreateClaimGroup;
