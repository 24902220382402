import get from 'lodash/get';
import { createSelector } from 'reselect';
import { CommonAppState } from '../CommonAppState';

const userState = (state: any) => state.login;
const oidcState = (state: CommonAppState) => state.oidc;

export const selectUserId = createSelector([userState], (user) => {
  return user.id;
});

export const accessTokenSelector = createSelector(
  [oidcState],
  (oidc): string | undefined => get(oidc, ['user', 'access_token']),
);
