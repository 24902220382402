import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.carrier}label/verify`;

export interface VerifiedAddress {
  civility: string;
  name1: string;
  name2: string;
  countryCode: string;
  street1: string;
  street2: string;
  zipCode: string;
  state: string;
  city: string;
  country: string;
  phone: string;
  contactLastName: string;
  contactFirstName: string;
  email: string;
  companyName: string;
  additionalInformation: string;
  hasElevator: boolean;
  housingType: string;
  hasParkingSpace: boolean;
  doorCode: string;
  floor: string;
}

interface GetVerifiedAddressPayload {
  carrierCompany: string;
  address: VerifiedAddress;
}

async function getVerifiedAddress({
  carrierCompany,
  address,
}: GetVerifiedAddressPayload): Promise<
  BackResponse<VerifiedAddress> | BackResponseFailure
> {
  try {
    const response = await apiCall<BackResponse<VerifiedAddress>>(ENDPOINT, {
      json: {
        carrierCompany,
        address,
      },
      method: SUPPORTED_METHODS.POST,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default getVerifiedAddress;
