import { FileProductFileShortSummary } from '@savgroup-front-common/types';

import { AdaptedNeededInformation } from '../AdditionalInformationExpect.types';

export const adaptFileProducts = ({
  additionalInformations,
  fileProducts,
}: {
  additionalInformations: Record<string, AdaptedNeededInformation[]>;
  fileProducts: FileProductFileShortSummary[];
}) => {
  return fileProducts.map((fileProduct) => {
    return {
      ...fileProduct,
      neededInformation: additionalInformations[fileProduct.claimId] || [],
    };
  });
};
