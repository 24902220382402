import { useMutation } from 'react-query';

import { CommonCarrierService } from '../../../api';
import { useBanners } from '../../NotificationsProvider';
import { prepareAppointmentTimeToCreateHomePickupCommand } from '../adapters/prepareAppointmentTimeToCreateHomePickupCommand';
import { Interval } from '../AppointmentCard.types';
import { haveScheduleProblem } from '../helpers/haveScheduleProblem';
import messages from '../messages';

interface UseCreateHomePickupCommandArgs {
  fileId: string;
  onSuccess?: () => void;
}

interface UseCreateHomePickupCommandReturnValue {
  isLoading: boolean;
  handleCreateHomePickup: ({
    appointmentTime,
  }: {
    appointmentTime: Interval | undefined;
  }) => void;
}

const useCreateHomePickupCommand = ({
  fileId,
  onSuccess,
}: UseCreateHomePickupCommandArgs): UseCreateHomePickupCommandReturnValue => {
  const { removeAllNotifications, pushError } = useBanners({
    extendedMessages: messages,
  });

  const { mutateAsync: handleCreateHomePickup, isLoading } = useMutation(
    async ({ appointmentTime }: { appointmentTime?: Interval }) => {
      if (!fileId || !appointmentTime) {
        return undefined;
      }
      removeAllNotifications();

      const response = await CommonCarrierService.createHomePickupCommand({
        fileId,
        appointmentTime: prepareAppointmentTimeToCreateHomePickupCommand({
          appointmentTime,
        }),
      });

      if (response.failure) {
        if (response.errors.some(haveScheduleProblem)) {
          await handleCreateHomePickup({
            appointmentTime: {
              ...appointmentTime,
              endLocalTime: new Date(
                appointmentTime.endLocalTime.setHours(9, 0, 0, 0),
              ),
              startLocalTime: new Date(
                appointmentTime.startLocalTime.setHours(23, 1, 0, 0),
              ),
            },
          });

          return undefined;
        }

        pushError({
          code: 'createHomePickupError',
          message: '',
          subject: '',
          isWarning: false,
        });

        return undefined;
      }

      if (onSuccess) {
        onSuccess();
      }

      return undefined;
    },
  );

  return { isLoading, handleCreateHomePickup };
};

export default useCreateHomePickupCommand;
