import { defineMessages } from 'react-intl';

export default defineMessages({
  productNumber: {
    id: 'components.additionalInformation.productNumber',
    defaultMessage: 'Product {num}',
  },
  thisFieldIsRequired: {
    id: 'components.myaccount.myReturn.quoteInformation.commentMandatory',
    defaultMessage: 'This field is required',
  },
  mustBeValidImei: {
    id: 'components.myaccount.claims.confirmation.mustBeValidImei',
    defaultMessage:
      'This field must be a valid IMEI number. It should be of the form: 123456789012347.',
  },
  mustBeValidRegex: {
    id: 'components.myaccount.claims.confirmation.mustBeValidRegex',
    defaultMessage:
      'This field must be a valid Regex, check the format in placeholder',
  },
  fileUploadedSuccessfully: {
    id: 'components.additionalInformation.fileUploadedSuccessfully',
    defaultMessage: 'File uploaded successfully',
  },
  maximumFiles: {
    id: 'components.additionalInformation.maximumFiles',
    defaultMessage: 'Maximum files: 15',
  },
});
