import { useEffect, useState } from 'react';

import { useBanners } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { QuotationEnrichedDto } from '@savgroup-front-common/types';

import { RepairerService } from '../../../../../../../api';
import messages from '../messages';

const useGetPendingCustomerValidationQuotation = ({
  fileId,
}: {
  fileId: string;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [
    pendingCustomerValidationQuotation,
    setPendingCustomerValidationQuotation,
  ] = useState<QuotationEnrichedDto | undefined>();
  const { pushErrors } = useBanners({
    extendedMessages: messages,
  });

  useEffect(() => {
    const call = async () => {
      setIsLoading(true);

      const response =
        await RepairerService.getPendingCustomerValidationByFileId({
          fileId,
        });

      setIsLoading(false);

      if (response.failure) {
        pushErrors(response.errors);
      } else {
        setPendingCustomerValidationQuotation(response.value);
      }
    };

    call();
  }, [fileId, pushErrors]);

  return {
    pendingCustomerValidationQuotation,
    isLoading,
  };
};

export default useGetPendingCustomerValidationQuotation;
