import React, { FC } from 'react';
import { $HomePageFileItemWrapper } from './HomePageFileItem.styles';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { useGetProductsDetails } from './HomePageFileItem.hooks';
import { OwnerProductSummaryDto } from '../../HomePage.types';
import { ProductsInfo } from 'myaccount/view/components/ProductsInfo';

const HomePageFileItem: FC<{ product: OwnerProductSummaryDto }> = ({
  product,
}) => {
  const { productsDetails, histories, isLoading, handleShowProduct } =
    useGetProductsDetails({
      product,
    });

  if (isLoading) {
    return (
      <$HomePageFileItemWrapper $isSmall>
        <BaseLoader hasMargin={false} hasHeightAuto />
      </$HomePageFileItemWrapper>
    );
  }

  if (!product.fileId) {
    return <></>;
  }

  return (
    <ProductsInfo
      productsInfo={productsDetails}
      histories={histories}
      hasHistory
      onClick={() => handleShowProduct({ fileId: product.fileId })}
    />
  );
};

HomePageFileItem.displayName = 'HomePageFileItem';
export default HomePageFileItem;
