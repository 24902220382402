import styled from 'styled-components';

export const DrawerActionContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 40px;
  gap: 10px;
  justify-content: space-between;

  background-color: ${({ theme }) => theme.colors.white};
  z-index: 15;
`;
