import PropTypes from 'prop-types';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Autocomplete } from '../../../atoms/Form';
import { formContextProps, messagesProps } from '../../../helpers/proptypes';
import { EMPTY_UUID } from '@savgroup-front-common/constants';

const AdditionalInformationEnum = ({
  id,
  name,
  label,
  isRequired,
  postLabel,
  placeholder,
  possibleValues,
  formContext,
  onAdditionalInformationSave,
  isScrollIntoView = false,
}) => {
  const defaultContext = useFormContext();

  const {
    control,
    formState: { errors },
    warnings,
  } = formContext || defaultContext;

  const options = possibleValues.map(
    ({ id: possibleValueId, name: possibleValueName, key }) => ({
      label: possibleValueName,
      value: possibleValueId === EMPTY_UUID ? key : possibleValueId,
    }),
  );

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          return (
            <Autocomplete
              errors={errors}
              warnings={warnings}
              required={isRequired}
              placeholder={placeholder}
              label={label}
              name={name}
              postLabel={postLabel}
              isRequired={isRequired}
              options={options}
              dataTestIdForCypress={`additional-information-input-dropdown-${id}`}
              dataTestId={`data-additional-information-${id}`}
              onChange={async (option) => {
                field.onChange(option);
                await onAdditionalInformationSave();
              }}
              ref={field.ref}
              value={field.value}
              onBlur={field.onBlur}
              isScrollIntoView={isScrollIntoView}
            />
          );
        }}
      />
    </>
  );
};

AdditionalInformationEnum.propTypes = {
  name: PropTypes.string,
  label: PropTypes.oneOfType([messagesProps, PropTypes.string]),
  placeholder: PropTypes.string,
  id: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  postLabel: PropTypes.shape({}),
  fileUploadEndpoints: PropTypes.shape({
    uploadEndpoint: PropTypes.string,
    deleteEndpoint: PropTypes.string,
  }),
  possibleValues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  ),
  formContext: formContextProps,
  isScrollIntoView: PropTypes.bool,
};
AdditionalInformationEnum.defaultProps = {
  name: null,
  label: null,
  placeholder: null,
  possibleValues: [],
  isRequired: false,
  postLabel: null,
  fileUploadEndpoints: {},
  formContext: null,
  isScrollIntoView: false,
};

AdditionalInformationEnum.displayName = 'AdditionalInformationEnum';

export default AdditionalInformationEnum;
