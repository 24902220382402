import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useDeepCompareEffect } from 'react-use';

import { logVerbose } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { signIn } from 'myaccount/domains/Login/loginActions';

const CheckLogged = ({ userManager, children }) => {
  const { location } = useHistory();
  const dispatch = useDispatch();

  const { auth, login } = useSelector((state) => ({
    auth: state.auth,
    login: state.login,
  }));

  useDeepCompareEffect(() => {
    if (auth.failed) {
      logVerbose(
        `Failed authentication MA : redirectUrl => ${
          login.isLoggedIn ? '/' : location.pathname
        }`,
        {
          auth,
        },
      );

      setTimeout(() =>
        userManager.current.signinRedirect({
          data: {
            redirectUrl: login.isLoggedIn ? '/' : location.pathname,
          },
        }),
      );
    } else if (login.failure) {
      logVerbose('Failed login signoutRedirect', {
        auth,
      });

      // setTimeout to prevent from infinite loop problem
      setTimeout(() => userManager.current.signoutRedirect());
    } else if (auth.isLoggedIn && !login.isLoggedIn) {
      dispatch(signIn());
    }
  }, [
    auth,
    dispatch,
    location.pathname,
    login.failure,
    login.isLoggedIn,
    userManager,
  ]);

  if (login.isLoggedIn) {
    return <>{children}</>;
  }

  return null;
};

CheckLogged.whyDidYouRender = true;
CheckLogged.displayName = 'CheckLogged';

export default CheckLogged;
