import { useQuery } from 'react-query';

import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { PaymentService } from 'myaccount/api';

const GetPaymentAccountForContextQuery = 'getPaymentAccountForContextQuery';

export const useGetPaymentAccount = ({
  claimGroupId,
}: {
  claimGroupId: string;
}) => {
  const { pushErrors } = useToasts();

  const { data: paymentAccountDto } = useQuery(
    [GetPaymentAccountForContextQuery, claimGroupId],
    async () => {
      const response = await PaymentService.getPaymentAccountForContextQuery({
        claimGroupId,
      });

      if (response.failure) {
        pushErrors(response.errors);

        return undefined;
      }

      return response.value;
    },
    {
      staleTime: Infinity,
      suspense: true,
    },
  );

  return { paymentAccountDto };
};
