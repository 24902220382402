import { Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

export const $CloseModalBlock = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  line-height: 12px;
  outline: none;
  cursor: pointer;
`;

export const $ConfirmCancelModalRow = styled(Row)`
  font-size: 16px;
  padding: 10px 0 30px;
`;
