import { useMedia } from 'react-use';

import { media } from '@savgroup-front-common/constants';

export const useGetPageSize = () => {
  const isWidthMobileView = useMedia(media.maxWidth.xs);
  const isHeightMobileView = useMedia(media.maxHeight.xs);

  return isWidthMobileView || isHeightMobileView ? 3 : 7;
};
