import { defineMessages } from 'react-intl';

export default defineMessages({
  paginationPerPage: {
    id: 'view.ExpendableList.paginationPerPage',
    defaultMessage: 'Elements per page',
  },
  paginationPerPageSingular: {
    id: 'view.ExpendableList.paginationPerPageSingular',
    defaultMessage: 'Element per page',
  },
  totalPage: {
    id: 'view.ExpendableList.totalPage',
    defaultMessage: 'on {totalPage}',
  },
});
