import React, { FC } from 'react';

import {
  QuotationPendingCustomerValidationNewModel,
  useCalculateQuotationPendingCustomerValidationTotals,
} from '@savgroup-front-common/core/src/components/Quotation';
import { StatelessQuotationTotal } from '@savgroup-front-common/core/src/components/Quotation/StatelessQuotationTotal/StatelessQuotationTotal';
import {
  StatelessQuotationTotalVariants
} from '@savgroup-front-common/core/src/components/Quotation/StatelessQuotationTotal/StatelessQuotationTotal.constants';

interface QuotationTotalProps {
  quotation: QuotationPendingCustomerValidationNewModel;
  fileId: string;
}

const QuotationTotal: FC<QuotationTotalProps> = ({ quotation, fileId }) => {
  const {
    subtotalVat,
    subtotalPreVat,
    totalDiscountIncludedTax,
    alreadyPaid,
    totalAmountToBePaid,
    bonus,
  } = useCalculateQuotationPendingCustomerValidationTotals({
    quotation,
    fileId,
  });

  return (
    <StatelessQuotationTotal
      variant={StatelessQuotationTotalVariants.SELFCARE}
      alreadyPaid={alreadyPaid}
      bonus={bonus}
      subtotalPreVat={subtotalPreVat}
      subtotalVat={subtotalVat}
      totalAmountToBePaid={totalAmountToBePaid}
      totalDiscountIncludedTax={totalDiscountIncludedTax}
    />
  );
};

QuotationTotal.displayName = 'QuotationTotal';

export default QuotationTotal;
