import React, { FunctionComponent } from 'react';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import {
  safeFormattedIntlString,
  SafeFormattedMessageWithoutSpread,
} from '@savgroup-front-common/core/src/formatters';
import { DocumentData } from '@savgroup-front-common/core/src/formatters/getDocumentNameTranslated';
import { SUPPORTED_ICON_POSITIONS } from '@savgroup-front-common/core/src/helpers';
import { DownloadIcon } from '@savgroup-front-common/core/src/protons/icons';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';
import { ICONS_TYPE } from '@savgroup-front-common/types/src/Icon';

import { getDocumentInfo } from './FileDocument.helpers';
import useGetDocument from './FileDocument.hooks';
import {
  $DocumentButton,
  $DocumentIcon,
  $DocumentName,
  $DocumentNameDescription,
  $DocumentWrapper,
} from './FileDocument.styles';
import messages from './messages';

const FileDocument: FunctionComponent<{
  document: DocumentData;
}> = ({ document }) => {
  const {
    label,
    description,
    icon,
    url,
    fileId,
    moduleDefinitionId,
    moduleInstanceId,
    downloadType,
    date,
  } = getDocumentInfo({
    document,
  });

  const { handleActionDownloadDocument } = useGetDocument();

  return (
    <$DocumentWrapper>
      <$DocumentIcon>
        <MyAccountIcon icon={icon as ICONS_TYPE} color="#8A94A6" />
      </$DocumentIcon>
      <$DocumentName>
        <SafeFormattedMessageWithoutSpread message={label} />
        <$DocumentNameDescription>
          <SafeFormattedMessageWithoutSpread
            message={description}
            values={{ date }}
          />
        </$DocumentNameDescription>
      </$DocumentName>
      <$DocumentButton
        icon={<DownloadIcon />}
        position={SUPPORTED_ICON_POSITIONS.LEFT}
        type={BUTTON_TYPES.BUTTON}
        naked
        small
        primary
        onClick={() =>
          handleActionDownloadDocument({
            downloadType,
            tokenId: url?.split('token=')[1],
            fileId,
            moduleDefinitionId,
            moduleInstanceId,
            fileName: safeFormattedIntlString(label),
          })
        }
      >
        <SafeFormattedMessageWithoutSpread
          message={messages.downloadDocument}
        />
      </$DocumentButton>
    </$DocumentWrapper>
  );
};

FileDocument.displayName = 'FileDocument';
export default FileDocument;
