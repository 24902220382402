import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.sparePart}requestLine/update`;

interface SetRequestLineStatusResponse {
  errors: [
    {
      subject: string;
      code: string;
      message: string;
      developerMessage: string;
      moreInfo: string;
      field: string;
      index: 0;
      isWarning: true;
      extraData: string;
    },
  ];
  isHandledError: true;
  allowHttpCache: true;
  failure: true;
  success: true;
  hasWarnings: true;
  isNotFound: true;
}

const setRequestLineStatus = async (
  requestLineId: string,
  requestLineStatus: string,
): Promise<
  BackResponse<SetRequestLineStatusResponse> | BackResponseFailure
> => {
  try {
    const response = await apiCall<BackResponse<SetRequestLineStatusResponse>>(
      ENDPOINT,
      {
        method: SUPPORTED_METHODS.PUT,
        json: {
          requestLineId,
          requestLineStatus,
        },
      },
    );

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default setRequestLineStatus;
