import React, { FC, useCallback } from 'react';

import { Skeleton } from '@savgroup-front-common/core/src/atoms/Skeleton';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';

import { useConfirmPaymentIntent } from '../hooks/useConfirmPaymentIntent';
import useCreatePaymentIntent from '../hooks/useCreatePaymentIntent';

import { $PaylineWidget } from './PaymentMonext.styles';
import './widget-min.css';

interface PaymentMonextProps {
  invoiceId: string;
  onSubmitSuccess: () => void;
}

export const PaymentMonext: FC<PaymentMonextProps> = ({
  invoiceId,
  onSubmitSuccess,
}) => {
  const { pushErrors } = useToasts();

  const { paymentIntentClientSecret } = useCreatePaymentIntent({
    invoiceId,
    onInvoiceAlreadyPaid: onSubmitSuccess,
  });
  const { handleConfirmPaymentIntent } = useConfirmPaymentIntent({
    invoiceId,
  });

  const handleFinalStateHasBeenReached = useCallback(
    async ({ state }: { state: string }) => {
      if (state === 'PAYMENT_SUCCESS') {
        try {
          await handleConfirmPaymentIntent();

          onSubmitSuccess();
        } catch (error: any) {
          pushErrors(error);

          return undefined;
        }
      }
    },
    [handleConfirmPaymentIntent, onSubmitSuccess, pushErrors],
  );

  if (!paymentIntentClientSecret) {
    return <Skeleton width="100%" height="20rem" />;
  }

  return (
    <$PaylineWidget
      token={paymentIntentClientSecret}
      template="column"
      embeddedRedirectionAllowed={false}
      onFinalStateHasBeenReached={handleFinalStateHasBeenReached as any}
    />
  );
};

PaymentMonext.displayName = 'PaymentMonext';
