import createHomePickupCommand from './createHomePickupCommand';
import getCarrierProductByIdQuery from './getCarrierProductByIdQuery';
import getHomePickupScheduleQuery from './getHomePickupScheduleQuery';

const CommonCarrierService = {
  getHomePickupScheduleQuery,
  getCarrierProductByIdQuery,
  createHomePickupCommand,
};

export default CommonCarrierService;
