import { defineMessages } from 'react-intl';

export default defineMessages({
  date: {
    id: 'components.myaccount.myReturn.details.date',
    defaultMessage: 'Date',
  },
  status: {
    id: 'components.myaccount.myReturn.details.status',
    defaultMessage: 'Status',
  },
  linkText: {
    id: 'components.myaccount.myReturn.details.linkText',
    defaultMessage: '(View detailed tracking)',
  },
  orderNumber: {
    id: 'view.myaccount.claims.orderNumber',
    defaultMessage: 'Order number N°{orderReference}',
  },
  title_file_detail: {
    id: 'components.common.pageHeader.title_file_detail',
    defaultMessage: 'File N° {fileReference}',
  },
  displayProducInfo: {
    id: 'view.myaccount.claims.displayProducInfo',
    defaultMessage: 'Display product info for #{product}',
  },
  back: {
    id: 'components.myaccount.myReturn.details.back',
    defaultMessage: 'Back to home',
  },
  summary: {
    id: 'components.myaccount.myReturn.details.summary',
    defaultMessage: 'Summary',
  },
  history: {
    id: 'components.myaccount.myReturn.details.history',
    defaultMessage: 'Claim history',
  },
  currentStatus: {
    id: 'components.myaccount.myReturn.details.currentStatus',
    defaultMessage: 'Current status',
  },
  statusWarning: {
    id: 'components.myaccount.myReturn.details.statusWarning',
    defaultMessage: 'Something went wrong',
  },
  sellerComment: {
    id: 'components.myaccount.myReturn.details.sellerComment',
    defaultMessage: 'Seller comment',
  },
  viewSellerComment: {
    id: 'components.myaccount.myReturn.details.viewSellerComment',
    defaultMessage: '    View seller comment',
  },
  Monday: {
    id: 'components.myaccount.claims.handling.pickup.infos.monday',
    defaultMessage: 'Monday',
  },
  Tuesday: {
    id: 'components.myaccount.claims.handling.pickup.infos.tuesday',
    defaultMessage: 'Tuesday',
  },
  Wednesday: {
    id: 'components.myaccount.claims.handling.pickup.infos.wednesday',
    defaultMessage: 'Wednesday',
  },
  Thursday: {
    id: 'components.myaccount.claims.handling.pickup.infos.thursday',
    defaultMessage: 'Thursday',
  },
  Friday: {
    id: 'components.myaccount.claims.handling.pickup.infos.friday',
    defaultMessage: 'Friday',
  },
  Saturday: {
    id: 'components.myaccount.claims.handling.pickup.infos.saturday',
    defaultMessage: 'Saturday',
  },
  Sunday: {
    id: 'components.myaccount.claims.handling.pickup.infos.sunday',
    defaultMessage: 'Sunday',
  },
  technicalInterventionAppointmentValue: {
    id: 'view.folder.nav.fileSpareParts.technicalIntervention.Appointment.value',
    defaultMessage: '{startDate} from {startTime} to {endTime}',
  },
});
