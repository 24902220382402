import { defineMessages } from 'react-intl';

export default defineMessages({
  accountHolder: {
    id: 'view.component.expect.collectBankAccountDetails.accountHolder.label',
    defaultMessage: 'Account holder',
  },
  bankIdentificationCode: {
    id: 'view.component.expect.collectBankAccountDetails.bankIdentificationCode.label',
    defaultMessage: 'Bank identification code',
  },
  iban: {
    id: 'view.component.expect.collectBankAccountDetails.iban.label',
    defaultMessage: 'IBAN',
  },
  bankName: {
    id: 'view.component.expect.collectBankAccountDetails.bankName.label',
    defaultMessage: 'Bank name',
  },
  title: {
    id: 'view.component.expect.collectBankAccountDetails.title',
    defaultMessage: 'Collect account details',
  },
  didactics: {
    id: 'view.component.expect.collectBankAccountDetails.didactics',
    defaultMessage: 'Please fill this form',
  },
  ibanInvalid: {
    id: 'view.component.expect.collectBankAccountDetails.ibanInvalid',
    defaultMessage: 'The iban provided is not valid',
  },
  bankIdentificationCodeInvalid: {
    id: 'view.component.expect.collectBankAccountDetails.bankIdentificationCodeInvalid',
    defaultMessage: 'Bank identification code is invalid',
  },
});
