import { defineMessages } from 'react-intl';

export default defineMessages({
  takeAPictureDidactic: {
    id: 'components.b2b.takeAPictureDidactic',
    defaultMessage: 'Scan your product.',
  },
  returnToCart: {
    id: 'components.b2b.returnToCart',
    defaultMessage: 'Return to cart',
  },
  scanButton: {
    id: 'components.b2b.cart.scanButton',
    defaultMessage: 'Scan',
  },
});
