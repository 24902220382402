import { fromJS } from 'immutable';
import get from 'lodash/get';
import head from 'lodash/head';

export function onFullClaimSuccess(state, { payload, meta }) {
  const claimGroupId = get(meta, ['claimGroupId']);
  const fullClaims = get(payload, ['value']);

  if (!claimGroupId) {
    return state;
  }

  let newState = state;

  fullClaims.forEach((claim) => {
    const document = head(get(claim, 'documents'));

    newState = newState.setIn(
      ['fullClaims', claim.claimId],
      fromJS({ isLoaded: true, isDirty: false }).setIn(['value'], claim),
    );

    if (document) {
      newState = newState.updateIn(
        ['claimUploads', claim.claimId],
        {},
        (oldValue) => {
          const documentName = get(document, 'name');

          return {
            ...oldValue,
            [documentName]: {
              isLoaded: true,
              isDirty: false,
              value: {
                fileName: get(document, 'originalFileName'),
                progress: 100,
              },
              fileName: get(document, 'originalFileName'),
              name: get(document, 'originalFileName'),
              progress: 100,
              hasErrors: false,
            },
          };
        },
      );
    }

    return undefined;
  });

  return newState;
}
