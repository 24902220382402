import startsWith from 'lodash/startsWith';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  AdditionalClaimInformation,
  AdditionalInformation,
} from '@savgroup-front-common/types';

import { CommonAttachmentService } from '../../../../api';

export const downloadFileAdditionalInformationBlob = async ({
  fileAdditionalInformation,
}: {
  fileAdditionalInformation?:
    | AdditionalInformation
    | AdditionalClaimInformation;
}) => {
  if (
    !fileAdditionalInformation ||
    !fileAdditionalInformation.additionalInformationFileValue
  ) {
    return undefined;
  }

  const isUrlReady =
    startsWith(
      fileAdditionalInformation.additionalInformationFileValue.url,
      'https://',
    ) ||
    startsWith(
      fileAdditionalInformation.additionalInformationFileValue.url,
      '//localhost/',
    );
  const formattedUrl = isUrlReady
    ? fileAdditionalInformation.additionalInformationFileValue.url
    : `${APIConfiguration.base}${fileAdditionalInformation.additionalInformationFileValue.url}`;

  const blob = await CommonAttachmentService.downloadAttachment({
    endpoint: formattedUrl,
  });

  if ('failure' in blob) {
    return undefined;
  }

  return new File(
    [blob],
    fileAdditionalInformation.additionalInformationFileValue.fileName,
    {
      type: blob.type,
    },
  );
};
