import { useQuery } from 'react-query';

import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { SellerConfiguration } from '@savgroup-front-common/types';

import { SellerService } from '../../../api';

interface UseGetSellerConfigurationArgs {
  sellerId?: string;
}

interface UseGetSellerConfigurationReturnValues {
  sellerConfiguration?: SellerConfiguration;
  isLoading?: boolean;
}

export const GetSellerConfigurationQuery = 'getSellerConfigurationQuery';

const useGetSellerConfiguration = ({
  sellerId,
}: UseGetSellerConfigurationArgs): UseGetSellerConfigurationReturnValues => {
  const { pushErrors, removeAllNotifications } = useToasts();

  const { data: sellerConfiguration, isLoading } = useQuery(
    [GetSellerConfigurationQuery, { sellerId }],
    async () => {
      if (!sellerId) {
        return undefined;
      }

      removeAllNotifications();

      const response = await SellerService.getSellerConfigurationQuery({
        sellerId,
      });

      if (response.failure) {
        pushErrors(response.errors);

        return undefined;
      }

      return response.value;
    },
    {
      staleTime: Infinity,
      suspense: true,
    },
  );

  return { sellerConfiguration, isLoading };
};

export default useGetSellerConfiguration;
