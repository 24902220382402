import { Action } from 'redux-actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';

import { GetTechnicalInterventionByFileIdPayload } from './actionCreators';
import * as types from './actionTypes';
import callAndGetResponse from '../../services/callAndGetResponse';
import { buildUrl } from '../../helpers';

function* getTechnicalInterventionsByFileIdWorker({
  payload,
}: {
  payload: GetTechnicalInterventionByFileIdPayload;
}): Generator {
  const { fileId } = payload;

  yield call(
    callAndGetResponse,
    types.GET_TECHNICAL_INTERVENTIONS_BY_FILE_ID,
    buildUrl(`${APIConfiguration.workflow}files/technicalIntervention`, {
      fileId,
    }),
    { method: SUPPORTED_METHODS.GET },
    { indexer: fileId, fileId },
  );

  yield put(types.GET_TECHNICAL_INTERVENTIONS_BY_FILE_ID.end());
}
function* getTechnicalInterventionsByFileIdWatcher() {
  yield takeEvery<Action<GetTechnicalInterventionByFileIdPayload>>(
    types.GET_TECHNICAL_INTERVENTIONS_BY_FILE_ID.BASE,
    getTechnicalInterventionsByFileIdWorker,
  );
}

export default function* main(): Generator {
  try {
    yield all([getTechnicalInterventionsByFileIdWatcher()]);
  } catch (error) {
    logCritical(error);
  }
}
