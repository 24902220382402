import React, { FunctionComponent, useContext } from 'react';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';

import { $ActionRow } from '../AppointmentCalendarContainerLegacy.styles';

import {
  $AppointmentCalendar,
  AppointmentCalendarContainer,
  LeftArrowButton,
  RightArrowButton,
} from './AppointmentCalendarLegacy.styles';
import { AppointmentCalendarScreen } from './AppointmentCalendarScreenLegacy';
import messages from './messages';
import { Timeslot } from '@savgroup-front-common/types';
import { useLegacyInterventionAppointment } from './AppointmentCalendarLegacy.hooks';
import { useTakeInterventionAppointmentLegacyContext } from '../../TakeInterventionAppointmentLegacy.context';
import BannersProviderContext from '../../../../../../molecules/NotificationsProvider/BannersProvider/BannersProvider.context';
import Button from '../../../../../../atoms/button/Button';
import { SafeFormattedMessageWithoutSpread } from '../../../../../../formatters';
import { AppointmentCalendarLoader } from './AppointmentCalendarLoader/AppointmentCalendarLoader';

interface AppointmentCalendarProps {
  fileId: string;
  isActive?: boolean;
  isLoading: boolean;
  isNextPageDisabled: boolean;
  onValidateSchedule: ({
    selectedTimeslot,
  }: {
    selectedTimeslot: Timeslot;
  }) => void;
}

const AppointmentCalendarLegacy: FunctionComponent<
  React.PropsWithChildren<AppointmentCalendarProps>
> = ({
  fileId,
  isActive = true,
  children,
  isLoading,
  isNextPageDisabled,
  onValidateSchedule,
}) => {
  const { selectedTimeslot, pageNumber, handlePageChange, hasErrors } =
    useTakeInterventionAppointmentLegacyContext();
  const { notifications } = useContext(BannersProviderContext);

  const {
    detailedSchedules,
    isCreateTechnicalInterventionLoading,
    isGetTimeslotsLoading,
    handleValidateSchedule,
  } = useLegacyInterventionAppointment({
    fileId,
    onSubmit: onValidateSchedule,
  });

  const isCurrentWeekEmpty = (detailedSchedules || []).every(
    ({ timeslots = [] }) =>
      timeslots.filter((timeslot) => !!timeslot).length === 0,
  );
  const isValidateScheduleDisabled =
    !detailedSchedules ||
    detailedSchedules?.length === 0 ||
    isGetTimeslotsLoading;

  if (isGetTimeslotsLoading) {
    return <AppointmentCalendarLoader />;
  }

  return (
    <$AppointmentCalendar>
      <AppointmentCalendarContainer>
        <Button
          type={BUTTON_TYPES.BUTTON}
          onClick={() => {
            handlePageChange({ newPageNumber: pageNumber - 1 });
          }}
          icon={<LeftArrowButton />}
          disabled={pageNumber === 1}
          dataTestId="previousScreenButton"
          primary
          naked
          small
        />

        <AppointmentCalendarScreen
          isActive={isActive}
          isGetTimeslotsLoading={isGetTimeslotsLoading}
          detailedSchedules={detailedSchedules}
        />

        <Button
          type={BUTTON_TYPES.BUTTON}
          onClick={() => {
            handlePageChange({ newPageNumber: pageNumber + 1 });
          }}
          icon={<RightArrowButton />}
          disabled={isNextPageDisabled}
          dataTestId="nextScreenButton"
          primary
          naked
          small
        />
      </AppointmentCalendarContainer>

      <$ActionRow>
        {children}

        {!isCurrentWeekEmpty && notifications.length === 0 && (
          <Button
            disabled={isValidateScheduleDisabled || !selectedTimeslot}
            isLoading={isLoading || isCreateTechnicalInterventionLoading}
            primary
            type={BUTTON_TYPES.BUTTON}
            isError={hasErrors}
            onClick={() => handleValidateSchedule()}
            dataTestId="validateSchedule"
          >
            <SafeFormattedMessageWithoutSpread message={messages.validate} />
          </Button>
        )}

        {notifications.length > 0 && (
          <Button
            isLoading={isLoading || isCreateTechnicalInterventionLoading}
            primary
            type={BUTTON_TYPES.BUTTON}
            onClick={() => {
              window.location.reload();
            }}
          >
            <SafeFormattedMessageWithoutSpread message={messages.tryAgain} />
          </Button>
        )}
      </$ActionRow>
    </$AppointmentCalendar>
  );
};

AppointmentCalendarLegacy.displayName = 'AppointmentCalendarLegacy';

export default AppointmentCalendarLegacy;
