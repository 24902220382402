import React, { FunctionComponent, useContext } from 'react';

import { ActorInformation } from '../../ActorInformation';
import { ContentTemplateContext } from '../MDXCustomComponents.context';

interface CurrentActorArgs {
  withTitle: boolean;
}

const CurrentActor: FunctionComponent<
  React.PropsWithChildren<CurrentActorArgs>
> = ({ withTitle }) => {
  const { actorSummary } = useContext(ContentTemplateContext);

  return (
    <ActorInformation
      actor={actorSummary}
      withCard={false}
      withTitle={withTitle}
    />
  );
};

CurrentActor.displayName = 'FirstSupplierMail';

export default CurrentActor;
