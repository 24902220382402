import {
  HANDLING_GROUPS,
  HANDLING_MODES,
} from '@savgroup-front-common/constants';
import { CarrierProductSummary } from '@savgroup-front-common/core/src/api/Carrier/getCarrierProductByIdQuery';
import { HandlingSummary } from '@savgroup-front-common/types';
import { Carrier } from 'myaccount/api/Claim/getClaimGroupCarrierQuery';

const getAllCarriers = ({
  carriers,
  multiCarrierProductSummary,
  handlingInfo,
  handlingMode,
}: {
  carriers?: Carrier[];
  multiCarrierProductSummary?: CarrierProductSummary[];
  handlingInfo?: HandlingSummary;
  handlingMode: HANDLING_MODES;
}): Carrier[] => {
  const hasHome = handlingInfo?.hasHome;
  const homeInterventionCarrier = {
    handlingMode,
    carrierBrand: HANDLING_GROUPS.HOME_INTERVENTION,
    productType: HANDLING_GROUPS.HOME_INTERVENTION,
  } as Carrier;

  const allCarriers = hasHome
    ? (carriers || []).concat([homeInterventionCarrier])
    : carriers || [];

  return allCarriers
    .filter(
      (carrier) =>
        carrier?.productType !== HANDLING_GROUPS.IMMEDIATE_DROP_AT_STORE,
    )
    .map((carrier) => ({
      ...carrier,
      carrierBrand: multiCarrierProductSummary?.find(
        (carrierProduct) => carrierProduct.carrierId === carrier.carrierId,
      )?.carrierOperator,
    }));
};

export default getAllCarriers;
