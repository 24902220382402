import { OwnerService } from 'myaccount/api';
import { useLayout } from 'myaccount/view/app/NewLayout/NewLayout.hooks';
import { useQuery } from 'react-query';
import { adaptCustomerProducts } from './HomePageProducts.adapters';
import { useState } from 'react';

const useListOwnerProductsQuery = () => {
  const { ownerId } = useLayout();
  const [isOpen, setOpen] = useState(false);
  const [paginationInterval, setPaginationInterval] = useState(10);

  const {
    data: products,
    isLoading,
    refetch: refetchListProduct,
  } = useQuery(
    ['useListOwnerProductsQuery', { ownerId }],
    async () => {
      if (!ownerId) {
        return undefined;
      }

      const response = await OwnerService.listOwnerProductsQuery({
        ownerId,
      });

      if (response.failure) {
        return undefined;
      }

      return adaptCustomerProducts(response.value);
    },
    {
      suspense: true,
    },
  );

  const [pagination, setPagination] = useState({
    total: products?.length || 0,
    page: 1,
    pageSize: paginationInterval,
    pageCount: Math.ceil((products?.length || 1) / paginationInterval),
    hasPreviousPage: false,
    hasNextPage: paginationInterval < (products?.length || 0),
  });

  const handleChangePage = (pageNumber: number) => {
    setPagination({
      total: products?.length || 0,
      page: pageNumber,
      pageSize: paginationInterval,
      pageCount: Math.ceil((products?.length || 1) / paginationInterval),
      hasPreviousPage: pageNumber > 1,
      hasNextPage: pageNumber < (products?.length || 1 / paginationInterval),
    });
  };

  const handleChangeInterval = (interval: number) => {
    const pageCount = Math.ceil((products?.length || 1) / interval);

    setPaginationInterval(interval);

    if (pagination) {
      setPagination({
        ...pagination,
        pageSize: interval,
        pageCount,
      });
    }
  };

  return {
    products,
    isLoading,
    refetchListProduct,
    isOpen,
    setOpen,
    pagination,
    handleChangePage,
    handleChangeInterval,
  };
};

export default useListOwnerProductsQuery;
