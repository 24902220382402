import React, { FunctionComponent } from 'react';

import { FileShortSummary } from '@savgroup-front-common/types';

import ProductInfoHeader from './ProductInfoHeader/ProductInfoHeader';
import FileProgress from './FileProgress/FileProgress';

import { $Card, $CardContent, $Divider } from './MultiProductInfo.styles';
import MultiProductInfoDescription from './MultiProductInfoDescription/MultiProductInfoDescription';
import { FileStateHistorySummary } from 'myaccount/api/Workflow/getFileStatesQuery';

interface MultiProductInfoProps {
  file: FileShortSummary;
  histories?: FileStateHistorySummary[];
  onClick: () => void;
}

export const MultiProductInfo: FunctionComponent<MultiProductInfoProps> = ({
  file,
  histories,
  onClick,
}) => {
  return (
    <$Card minimal elevation={2} onClick={onClick} isCliquable={!!onClick}>
      <ProductInfoHeader file={file} />
      <$Divider />
      <$CardContent>
        <MultiProductInfoDescription file={file} />
        {!!histories && <FileProgress histories={histories} />}
      </$CardContent>
    </$Card>
  );
};

MultiProductInfo.displayName = 'MultiProductInfo';
