import { createAction } from '@reduxjs/toolkit';

import * as actionTypes from './actionTypes';

export const getActorSummaryById = createAction<{
  actorId?: string;
  actorType?: string;
  sellerId?: string;
  ownerId?: string;
}>(actionTypes.GET_ACTOR_SUMMARY_BY_ID.BASE);
