import { rem, transparentize } from 'polished';
import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';

export const $HomePageProductItemWrapper = styled.div<{
  $isSmall?: boolean;
  $isDisabled?: boolean;
}>`
  display: flex;
  width: 100%;
  align-items: center;
  border-radius: 8px;
  border: 1px solid
    ${({ theme, $isDisabled }) =>
      $isDisabled ? theme.colors.secondaryColor2 : '#d9d9d9'};
  padding: 0 1rem;
  gap: 1rem;
  display: flex;
  height: ${({ $isSmall }) => ($isSmall ? rem(140) : 'auto')};
  align-items: ${({ $isSmall }) => ($isSmall ? 'center' : 'stretch')};
  background-color: ${({ theme, $isDisabled }) =>
    $isDisabled ? theme.colors.secondaryColor2 : 'transparent'};

  @media ${media.maxWidth.xs} {
    flex-direction: ${({ $isSmall }) => ($isSmall ? 'row' : 'column')};
    padding: 1rem;
    height: ${({ $isSmall }) => ($isSmall ? rem(300) : 'auto')};
  }
`;

export const $ProductItemWrapper = styled.div`
  flex: 3;
  display: flex;
  gap: 1rem;
  align-items: center;
  height: auto;
  padding: 2.5rem 0;

  @media ${media.maxWidth.xs} {
    width: 100%;
    padding: 1rem 0;
  }
`;

export const $ProductItemAction = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;

  @media ${media.maxWidth.xs} {
    flex: 1;
    width: 100%;
    border-top: 1px solid ${({ theme }) => theme.colors.disabled};
  }

  && > button {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    text-align: left;

    @media ${media.maxWidth.xs} {
      padding: 0;
    }

    &:hover,
    &:focus {
      background-color: ${({ theme }) =>
        transparentize(0.95, theme.colors.primary)};
    }

    & svg {
      fill: none;

      @media ${media.maxWidth.xs} {
        margin-right: 1rem;
      }
    }
  }
`;

export const $ChevronForMobile = styled.div`
  display: none;

  @media ${media.maxWidth.xs} {
    display: flex;

    &&&& svg {
      fill: ${({ theme }) => theme.colors.paragraphTextColor};
    }
  }
`;

export const $Separator = styled.div`
  width: 100%;
  height: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.disabled};
`;

export const $ProductItemPictureWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  padding: 0.25rem 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.borders.radius};
  margin-right: 1rem;
  width: 70px;
  height: 70px;
  overflow: hidden;
`;

export const $CardContentThumbnail = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  && svg {
    max-width: 36px;
    max-height: 36px;
  }

  && img {
    object-fit: contain;
    max-height: 4.285714rem;
    max-width: 4.285714rem;
  }
`;

export const $ProductItemContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
export const $ProductItemTitle = styled.div`
  color: ${({ theme }) => theme.colors.darkBackground};
  font-size: ${({ theme }) => theme.fonts.size.large};
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
`;
export const $ProductItemInfo = styled.div`
  display: flex;
  gap: 2rem;
`;
export const $ProductItemInfoLabel = styled.div`
  color: ${({ theme }) => theme.colors.paragraphTextColor};
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  line-height: calc(${({ theme }) => theme.fonts.size.small} + 2px);
`;
export const $ProductItemInfoDate = styled.div`
  color: ${({ theme }) => theme.colors.darkBackground};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
`;

export const $CardTagWrapperHeader = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  @media ${media.maxWidth.sm} {
    flex-direction: row;
  }
`;

export const $CardTagHeader = styled.div`
  margin-right: 0.5rem;

  &:last-child {
    margin-right: 0;
  }
  @media ${media.maxWidth.sm} {
    margin-bottom: 0;
    margin-left: 0;
    margin-top: 0.5rem;
  }
`;
