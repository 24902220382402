import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getTechnicalInterventionsByFileId } from '../../../../../domains/technicalInterventions/actionCreators';

interface UseRescheduleTechnicalInterventionModalContentArgs {
  fileId: string;
  onRescheduleSuccess: () => void;
}

const useRescheduleTechnicalInterventionModalContent = ({
  fileId,
  onRescheduleSuccess,
}: UseRescheduleTechnicalInterventionModalContentArgs) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleValidateNewSchedule = useCallback(() => {
    setIsLoading(true);

    dispatch(getTechnicalInterventionsByFileId({ fileId }));

    onRescheduleSuccess();
  }, [dispatch, fileId, onRescheduleSuccess]);

  return {
    handleValidateNewSchedule,
    isRescheduleNewScheduleLoading: isLoading,
  };
};

export default useRescheduleTechnicalInterventionModalContent;
