import { Col } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

export const AlignedCol = styled(Col)`
  && {
    display: flex;
    justify-content: flex-end;
    white-space: nowrap;
  }
`;

export const PaddedCol = styled(Col)`
  && {
    padding: 15px;
  }
`;

export const $ImportantHighlight = styled('span')`
  font-size: 16px;
  font-weight: bold;
`;

export const Date = styled('div')`
  margin-left: 3px;
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
`;
