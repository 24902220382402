import { API_COMMON_ERROR } from '@savgroup-front-common/constants';

export const haveScheduleProblem = (error = {}) => {
  const { code = '' } = error as any;

  return [
    API_COMMON_ERROR.PROBLEM_WITH_SCHEDULE,
    API_COMMON_ERROR.READY_TIME_LATER_THAN_ALLOWABLE,
  ].includes(code);
};
