import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  FileSparePartRequestLine,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.sparePart}files/${COMMON_QUERY_PARAMS.FILE_ID}/requestLines`;

async function getRequestLines(
  fileId: string,
): Promise<BackResponse<FileSparePartRequestLine[]> | BackResponseFailure> {
  const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId);

  try {
    const response = await apiCall<BackResponse<FileSparePartRequestLine[]>>(
      url,
      {
        method: SUPPORTED_METHODS.GET,
      },
    );

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default getRequestLines;
