import React, { FC, Suspense } from 'react';

import { NOTIFICATION_TYPES } from '@savgroup-front-common/constants';
import { SALESFORCE_INTEGRATION_TYPE } from '@savgroup-front-common/types';

import ActionRow from '../../../../atoms/ActionRow/ActionRow';
import { Banner } from '../../../../atoms/Banners';
import { SafeFormattedMessageWithoutSpread } from '../../../../formatters/SafeFormattedMessageWithoutSpread';
import AppointmentCalendarContainerSkeleton from '../../TakeInterventionAppointmentContainer/TakeInterventionAppointmentContainer.skeleton';

import { AppointmentCalendarContainer } from './AppointmentCalendarContainer';
import messages from './messages';
import { TakeInterventionAppointmentContextProvider } from './TakeInterventionAppointment.context';
import { useInterventionAppointment } from './TakeInterventionAppointment.hooks';

interface InterventionAppointmentContentProps {
  fileId: string;
  sellerId: string;
  onSubmit: () => void;
  isLoading?: boolean;
}

const TakeInterventionAppointment: FC<
  React.PropsWithChildren<InterventionAppointmentContentProps>
> = ({ children, fileId, sellerId, onSubmit, isLoading = false }) => {
  const { detailedSchedules, sellerConfiguration } = useInterventionAppointment(
    { fileId, onSubmit, sellerId },
  );

  const isNone =
    sellerConfiguration?.salesforceFieldServiceLightningIntegrationType ===
      SALESFORCE_INTEGRATION_TYPE.None ||
    !sellerConfiguration?.salesforceFieldServiceLightningIntegrationType;

  if (isNone) {
    return (
      <>
        <Banner isOpen notificationType={NOTIFICATION_TYPES.ERROR}>
          <SafeFormattedMessageWithoutSpread
            message={messages.integrationTypeIsNotConfigured}
          />
        </Banner>
        <ActionRow>{children}</ActionRow>
      </>
    );
  }

  return (
    <TakeInterventionAppointmentContextProvider
      detailedSchedules={detailedSchedules}
    >
      <Suspense fallback={<AppointmentCalendarContainerSkeleton />}>
        <AppointmentCalendarContainer
          fileId={fileId}
          sellerId={sellerId}
          isLoading={isLoading}
          onSubmit={onSubmit}
        >
          {children}
        </AppointmentCalendarContainer>
      </Suspense>
    </TakeInterventionAppointmentContextProvider>
  );
};

TakeInterventionAppointment.displayName = 'TakeInterventionAppointment';

export default TakeInterventionAppointment;
