import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared/supportedMethods';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { BackResponseFailure, Theme } from '@savgroup-front-common/types';
import { AuthConfiguration } from 'myaccount/configuration';

const ENDPOINT = `${AuthConfiguration.authority}/Style/Json?clientId=${AuthConfiguration.clientId}`;

const getDefaultTheme = async (): Promise<Theme | BackResponseFailure> => {
  try {
    const response = await fetch(ENDPOINT, {
      method: SUPPORTED_METHODS.GET,
    });

    return await response.json();
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default getDefaultTheme;
