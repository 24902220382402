export const ICON_TYPE = {
  POINT: 'point',
  SELECTED_POINT: 'selectedPoint',
  HOME: 'home',
};
export const mapStyles = [
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
];
export const markerShape = {
  coords: [29, 59, 38, 50, 53, 26, 47, 3, 30, 0, 13, 3, 6, 24, 20, 48],
  type: 'poly',
};
