import { TransitionStatus } from 'react-transition-group/Transition';
import styled from 'styled-components';

import { ANIMATION_STATE } from '@savgroup-front-common/constants/src/shared';

import { getAnimationDuration } from '../../animations/helper';
import { isAnimationEntering, rem } from '../../helpers';
import { decelerateTimingFunctionTransition } from '../../animations/timingFunction';
import { slideBottomToBottom } from '../../animations/stateAnimation';

interface $ContentProps {
  $height: number;
  animationState: TransitionStatus;
  animationDuration: number;
  $isOpen: boolean;
  $unmountOnExit: boolean;
}

const $Content = styled.div<$ContentProps>`
  transition-duration: ${getAnimationDuration};
  ${decelerateTimingFunctionTransition};
  overflow: ${({ animationState, $isOpen }) =>
    $isOpen === true && animationState === ANIMATION_STATE.ENTERED
      ? 'visible'
      : 'hidden'};

  height: ${({ $isOpen, $height, animationState }) => {
    const isOpening =
      $isOpen === true && animationState === ANIMATION_STATE.ENTERING;
    const isOpened =
      $isOpen === true && animationState === ANIMATION_STATE.ENTERED;
    const willBeClosed =
      $isOpen === false && animationState === ANIMATION_STATE.ENTERED;

    if (isOpened) {
      return 'auto';
    }
    if (isOpening || willBeClosed) {
      return rem($height);
    }

    return 0;
  }};

  position: relative;

  & > * {
    ${slideBottomToBottom};
    transition-property: opacity;
    transition-duration: ${getAnimationDuration};
    opacity: ${({ animationState }) =>
      isAnimationEntering(animationState) ? 1 : 0};
    ${decelerateTimingFunctionTransition};
  }
`;

export default $Content;
