import React, { FC } from 'react';

import { $QualiReparLogo } from './QualireparLogo.styles';

export const QualireparLogo: FC = () => {
  return (
    <$QualiReparLogo
      data-testid="logoBonus"
      src={`${import.meta.env.VITE_STATIC_HASH}/images/quali-repar.png`}
      alt=""
    />
  );
};

QualireparLogo.displayName = 'QualireparLogo';
