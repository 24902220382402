import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { LANGUAGES } from '@savgroup-front-common/constants';
import { setOwnerLanguagePreference } from '@savgroup-front-common/core/src/domains/i18n/actions';
import { LanguageChooser } from '@savgroup-front-common/core/src/molecules/LanguageChooser';
import { SUPPORTED_COMPONENTS } from '@savgroup-front-common/types';

import { $HeaderContainer, $MenuContainer, $RightSide } from './Header.styles';
import Logo from './logo/Logo';

interface HeaderTestProps {
  sellerId: string;
  enableServicePortal?: boolean;
}

const Header: React.FC<HeaderTestProps> = ({
  sellerId,
  enableServicePortal,
}) => {
  const dispatch = useDispatch();

  const handleLanguageChange = useCallback(
    (locale: LANGUAGES) => {
      dispatch(setOwnerLanguagePreference({ locale }));
    },
    [dispatch],
  );

  return (
    <$HeaderContainer
      componentThemeName={SUPPORTED_COMPONENTS.APP_HEADER}
      enableServicePortal={enableServicePortal}
    >
      <$MenuContainer>
        <Logo sellerId={sellerId} />
        <$RightSide>
          <LanguageChooser
            sellerId={sellerId}
            onLangueChange={handleLanguageChange}
          />
        </$RightSide>
      </$MenuContainer>
    </$HeaderContainer>
  );
};

export default Header;
