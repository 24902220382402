import get from 'lodash/get';

import { ADDITIONAL_INFORMATION_TYPES } from '@savgroup-front-common/types';

import {
  ALL_FULL_SIZE_FIELD,
  FULL_SIZE_FIELD,
} from './AdditionalInformation.types';

export const checkIfNextInputIsFull = ({
  ignoreUniquenessByProduct,
  nextGroup,
}: {
  ignoreUniquenessByProduct: boolean;
  nextGroup: [string, { uniquenessByProduct: boolean }[]];
}) => {
  const nextGroupType = get(nextGroup, [1, 0, 'type']);

  if (
    nextGroupType &&
    nextGroupType === ADDITIONAL_INFORMATION_TYPES.TEXT_AREA
  ) {
    return true;
  }

  if (ignoreUniquenessByProduct) {
    return nextGroup && nextGroup[1].length > 1;
  }

  return (
    nextGroup && nextGroup[1][0].uniquenessByProduct && nextGroup[1].length > 1
  );
};

export const checkIfShouldRenderWithFullWith = ({
  ignoreUniquenessByProduct,
  isEven,
  nextInputIsFull,
  neededInformationGroup,
  nextGroup,
  neededInformationItem,
  fullSizeItem = false,
}: {
  ignoreUniquenessByProduct: boolean;
  isEven: boolean;
  nextInputIsFull: boolean;
  neededInformationGroup: unknown[];
  nextGroup: [string, unknown[]];
  neededInformationItem: {
    type: ADDITIONAL_INFORMATION_TYPES;
    uniquenessByProduct: boolean;
  };
  fullSizeItem?: boolean;
}) => {
  if (
    neededInformationItem &&
    FULL_SIZE_FIELD.includes(neededInformationItem.type)
  ) {
    return true;
  }

  if (ignoreUniquenessByProduct) {
    if (
      fullSizeItem &&
      neededInformationItem &&
      ALL_FULL_SIZE_FIELD.includes(neededInformationItem.type)
    )
      {return true;}

    return (
      (isEven && nextInputIsFull) ||
      neededInformationGroup.length > 1 ||
      (isEven && !nextGroup)
    );
  }

  return (
    (isEven && nextInputIsFull) ||
    (neededInformationItem.uniquenessByProduct &&
      neededInformationGroup.length > 1) ||
    (isEven && !nextGroup)
  );
};
