import { AdditionalInformationDto } from '@savgroup-front-common/types';

import { injectInternalId } from '../helpers/injectInternalId';

import { adaptFileTypeNeededInformation } from './adaptFileTypeNeededInformation';

const adaptNeededFileAdditionalInformationByClaimIds =
  ({ claimIds, fileId }: { claimIds: string[]; fileId?: string }) =>
  (
    item: { claimId: string; neededInformation: AdditionalInformationDto[] },
    index: number,
  ) => {
    const { claimId, neededInformation } = item;

    if (index === 0) {
      return {
        claimId,
        neededInformation: neededInformation
          .map(injectInternalId(claimId, claimIds))
          .reduce<
            (AdditionalInformationDto & {
              internalId: string;
              fileUploadEndpoints?: {
                uploadEndpoint: string;
                deleteEndpoint: string;
              };
            })[]
          >(adaptFileTypeNeededInformation({ fileId }), []) as any,
      };
    }

    return {
      claimId,
      neededInformation: neededInformation
        .filter(({ uniquenessByProduct }) => uniquenessByProduct)
        .map(injectInternalId(claimId))
        .reduce<
          (AdditionalInformationDto & {
            internalId: string;
            fileUploadEndpoints?: {
              uploadEndpoint: string;
              deleteEndpoint: string;
            };
          })[]
        >(adaptFileTypeNeededInformation({ fileId }), []) as any,
    };
  };

export default adaptNeededFileAdditionalInformationByClaimIds;
