import React, { FC, useCallback } from 'react';
import { FileShortSummary } from '@savgroup-front-common/types';
import { useHistory } from 'react-router-dom';
import { buildProfessionalRoute } from '../../../../../app/NewLayout/ProfessionalRoutes.helpers';
import { PROFESSIONAL_STEP_CONFIG } from '../../../../../app/NewLayout/ProfessionalRoutes.config';
import { MultiProductInfo } from '../../components/MultiProductInfo/MultiProductInfo';
import { useGetFileStatesQuery } from 'myaccount/view/app/hooks/Workflow/useGetFileStatesQuery';

interface HomePageFileItemProps {
  file: FileShortSummary;
}

const HomePageFileItem: FC<HomePageFileItemProps> = ({ file }) => {
  const history = useHistory();
  const { histories, isLoading: isLoadingHistories } = useGetFileStatesQuery({
    fileId: file.fileId,
  });

  const handleFileClick = useCallback(() => {
    history.push(
      buildProfessionalRoute({
        route: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_FILE_FOLLOWUPS_PAGE,
        payload: {
          fileId: file.fileId,
        },
      }),
    );
  }, [file.fileId, history]);

  if (isLoadingHistories) {
    return <></>;
  }

  return (
    <MultiProductInfo
      file={file}
      histories={histories}
      onClick={handleFileClick}
    />
  );
};

HomePageFileItem.displayName = 'HomePageFileItem';

export default HomePageFileItem;
