import React, { Fragment, useContext } from 'react';

import { Link } from '../../../atoms/link';
import { Field } from '../../../molecules/Field';
import { ActorInformationItem } from '../../ActorInformation/components/GenericActorInformation/GenericActorInformation.styles';
import messages from '../../ActorInformation/components/GenericActorInformation/messages';
import { ContentTemplateContext } from '../MDXCustomComponents.context';

const WebsitesCurrentActor = () => {
  const { actorSummary } = useContext(ContentTemplateContext);
  const websites = (actorSummary?.websitesUrl || []).filter((v) => v);

  return (
    <>
      {websites.length > 0 && (
        <ActorInformationItem>
          <Field label={messages.website}>
            {websites.map((website) => (
              <Fragment key={website}>
                <Link isExternal href={website}>
                  {website}
                </Link>
                <br />
              </Fragment>
            ))}
          </Field>
        </ActorInformationItem>
      )}
    </>
  );
};

WebsitesCurrentActor.displayName = 'WebsitesCurrentActor';

export default WebsitesCurrentActor;
