import {
  ADDITIONAL_INFORMATION_TYPES,
  AddressInfoDto,
  FileShortSummary,
  ICONS_TYPE,
  OrderSummary,
  Product,
} from '@savgroup-front-common/types';
import { Reason } from 'myaccount/types';

import { STEP_CONFIG } from '../ClassiqueRoutes.config';
import { LoginState } from '@savgroup-front-common/core/src/domains/login';

export enum FORM_ACTION_TYPES {
  NEXT_STEP = 'NEXT_STEP',
  PREVIOUS_STEP = 'PREVIOUS_STEP',
  UPDATE_STEP = 'UPDATE_STEP',
  JUMP_TO_STEP = 'JUMP_TO_STEP',
}

export enum VALUE_ACTION_TYPES {
  UPDATE_VALUE = 'UPDATE_VALUE',
}

export interface Step {
  stepName: STEP_CONFIG | undefined;
  stepUrl: string | undefined;
}

export type FormState = {
  currentStep: Step;
  history: Step[];
  issuePageIsSkipped?: boolean;
  solutionPageIsSkipped?: boolean;
};

export interface Claim {
  state: string;
  claimId: string;
  sellerId: string;
  ownerId: string;
  warrantyTypeId: string;
  issueId: string;
  symptomId: string;
  questionId: string;
  reasonId: string;
  solutionTypeId: string;
  ignoreDateRulesChecking: boolean;
  groupId: string;
  ownerProductId: string;
  additionalClaimInformation: {
    additionalInformationId: string;
    additionalInformationEnumValue?: string;
    additionalInformationStringValue?: string;
    additionalInformationFileValue?: {
      fileName: string;
      url: string;
      uploaderId: string;
      uploaderFullName: string;
      isUploadedByOwner: boolean;
      uploadDateUtc: Date;
      customerVisibility: boolean;
      additionalInformationFileValue?: File;
    };
  }[];
  fileId: string;
  externalCaseId: string;
  safetyRiskDeclared: boolean;
  depositAddress?: AddressInfoDto;
  deliveryAddress?: AddressInfoDto;
  reasonComment?: string;
}

export interface AdditionalInformation {
  id: string;
  key: string;
  name: string;
  isRequired: boolean;
  uniqueName: string;
  type: ADDITIONAL_INFORMATION_TYPES;
  uniquenessByProduct: boolean;
  description: string;
  displayOrder: number;
  possibleValues: AdditionalInformationPossibleValue[];
  internalId: string;
  claimIds: string[];
  fileUploadEndpoints?: {
    [key: string]: {
      uploadEndpoint: string;
      deleteEndpoint: string;
    };
  };
}

interface AdditionalInformationPossibleValue {
  id: string;
  name: string;
  key: string;
}

export type ValuesState = {
  ownerId?: string;
  login?: LoginState;
  orders?: OrderSummary[];
  openedFiles?: FileShortSummary[];
  closedFiles?: FileShortSummary[];
  issue?: {
    ownerProductIds?: string[];
    sellerProductIds?: string[];
    id: string;
    name: string;
    uniqueName: string;
    key: string;
    displayOrder: number;
    didactic: string;
    iconKey: ICONS_TYPE;
    fromProductWarranty: boolean;
    winningWarrantyTypeId: string;
  };
  chosenProduct?: Product;
  reason?: Reason;
  diagnosticIsAlreadyMade?: boolean;
  solution?: {
    solutionId?: string;
    solutionUniqueName?: string;
  };
  handlingStatus?: {
    hasHome: boolean;
    hasDeposit: boolean;
    hasDelivery: boolean;
  };
  handlingDeposit?: Record<string, unknown>;
  handlingDelivery?: Record<string, unknown>;
  claimGroup?: {
    claimGroupId?: string;
    claims?: Claim[];
  };
};
