import get from 'lodash/get';
import React, { Fragment, FunctionComponent } from 'react';

import { ActorSummary } from '@savgroup-front-common/types';

import { Link } from '../../../../atoms/link';
import {
  getActorTypesMessage,
  safeFormattedIntlString,
} from '../../../../formatters';
import useAddressCompanyFormat from '../../../../hooks/useAddressFormatter';
import { Field } from '../../../../molecules/Field';
import { MDXContent } from '../../../MDXContent/MDXContent';
import { customComponents } from '../../../MDXCustomComponents';

import {
  $NoteContainer,
  ActorInformationItem,
  ActorInformationList,
} from './GenericActorInformation.styles';
import messages from './messages';

interface GenericActorInformationProps {
  actor: ActorSummary;
}

const GenericActorInformation: FunctionComponent<
  React.PropsWithChildren<GenericActorInformationProps>
> = ({ actor }) => {
  const fullName = get(actor, 'fullName');
  const address = get(actor, 'address');
  const note = get(actor, 'note');

  const phone = get(address, 'phone');
  const floor = get(address, 'floor');
  const doorCode = get(address, 'doorCode');
  const additionalAddress = get(address, 'additionalAddress');
  const additionalInformation = get(address, 'additionalInformation');

  const addressLine = get(address, 'address');
  const city = get(address, 'city');
  const postalCode = get(address, 'postalCode');
  const countryCode = get(address, 'countryCode');
  const fullAddress = useAddressCompanyFormat({
    address: addressLine,
    city,
    postalCode,
    countryCode,
  });

  const emailAddresses = get(actor, 'mailAddresses', []).filter((v) => v);
  const websites = get(actor, 'websitesUrl', []).filter((v) => v);

  return (
    <ActorInformationList data-testid="genericActorInformation">
      {fullName && (
        <ActorInformationItem>
          <Field
            label={messages.fullName}
            copy={fullName}
            isEllipsisEnabled={false}
          >
            {fullName}
          </Field>
        </ActorInformationItem>
      )}
      <ActorInformationItem>
        <Field
          label={messages.actorType}
          copy={actor.actorType}
          isEllipsisEnabled={false}
        >
          {safeFormattedIntlString(getActorTypesMessage(actor.actorType))}
        </Field>
      </ActorInformationItem>
      {fullAddress && (
        <ActorInformationItem>
          <Field
            label={messages.address}
            copy={fullAddress}
            isEllipsisEnabled={false}
          >
            {fullAddress}
          </Field>
        </ActorInformationItem>
      )}
      {additionalAddress && (
        <ActorInformationItem>
          <Field
            label={messages.additionalAddress}
            copy={additionalAddress}
            isEllipsisEnabled={false}
          >
            {additionalAddress}
          </Field>
        </ActorInformationItem>
      )}
      {floor && (
        <ActorInformationItem>
          <Field label={messages.floor} copy={floor} isEllipsisEnabled={false}>
            {floor}
          </Field>
        </ActorInformationItem>
      )}
      {doorCode && (
        <ActorInformationItem>
          <Field
            label={messages.doorCode}
            copy={doorCode}
            isEllipsisEnabled={false}
          >
            {doorCode}
          </Field>
        </ActorInformationItem>
      )}
      {additionalInformation && (
        <ActorInformationItem>
          <Field
            label={messages.additionalInformation}
            copy={additionalInformation}
            isEllipsisEnabled={false}
          >
            {additionalInformation}
          </Field>
        </ActorInformationItem>
      )}
      {phone && (
        <ActorInformationItem>
          <Field label={messages.phone} copy={phone}>
            {phone}
          </Field>
        </ActorInformationItem>
      )}
      {emailAddresses.length > 0 && (
        <ActorInformationItem>
          <Field label={messages.email} isEllipsisEnabled={false}>
            {emailAddresses.map((emailAddress) => (
              <Fragment key={emailAddress}>
                <Link isExternal href={`mailto:${emailAddress}`}>
                  {emailAddress}
                </Link>
                <br />
              </Fragment>
            ))}
          </Field>
        </ActorInformationItem>
      )}
      {websites.length > 0 && (
        <ActorInformationItem>
          <Field label={messages.website} isEllipsisEnabled={false}>
            {websites.map((website) => (
              <Fragment key={website}>
                <Link isExternal href={website} target="_blank">
                  {website}
                </Link>
                <br />
              </Fragment>
            ))}
          </Field>
        </ActorInformationItem>
      )}
      {note && (
        <ActorInformationItem isFluid>
          <Field label={messages.note} isEllipsisEnabled={false} isFluid>
            <$NoteContainer>
              <MDXContent customComponents={customComponents}>
                {note}
              </MDXContent>
            </$NoteContainer>
          </Field>
        </ActorInformationItem>
      )}
    </ActorInformationList>
  );
};

GenericActorInformation.displayName = 'GenericActorInformation';

export default GenericActorInformation;
