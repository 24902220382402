import { Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import { media } from '@savgroup-front-common/constants';

export const $RowContent = styled(Row)<{ $stepNumberLength: number }>`
  display: flex;

  @media ${media.maxWidth.sm} {
    justify-content: ${({ $stepNumberLength }) =>
      $stepNumberLength >= 3 ? 'end' : 'start'};
  }
`;
