import React, { useState } from 'react';
import { FormattedNumber, useIntl } from 'react-intl';
import { Col, Row } from 'react-styled-flexboxgrid';

import {
  BUTTON_TYPES,
  CIVILITY,
  CURRENCIES,
} from '@savgroup-front-common/constants';

import { Button } from '../../atoms/button';
import { Modal } from '../../atoms/modal';
import {
  addressFormatter,
  SafeFormattedMessageWithoutSpread,
} from '../../formatters';
import { LocationIcon } from '../../protons/icons';
import PickupPointItemInfo from '../PickupPointItemInfo';

import {
  $AddressCol,
  $AddressRow,
  $BrandLogoContainer,
  $DistanceContainer,
  $Price,
  $TransportMethod,
} from './AddressConfirmation.styles';
import messages from './messages';

interface AddressConfirmationProps {
  openingHours?: string[];
  address?: string;
  city?: string;
  lastname?: string;
  firstname?: string;
  label?: string;
  imagePath?: string;
  postalCode?: string;
  civility?: CIVILITY;
  price?: number;
  priceUnit: string;
  distanceInMeters?: number;
  additionalAddress?: string;
  showPrice?: boolean;
  transportMethod?: string;
}

const AddressConfirmation = ({
  openingHours = undefined,
  firstname: firstName = undefined,
  lastname: lastName = undefined,
  distanceInMeters = undefined,
  address = undefined,
  additionalAddress = undefined,
  postalCode = undefined,
  city = undefined,
  imagePath = undefined,
  label = undefined,
  civility = CIVILITY.NOT_SET,
  price = undefined,
  priceUnit = CURRENCIES.EUR,
  showPrice = false,
  transportMethod = undefined,
}: AddressConfirmationProps) => {
  const intl = useIntl();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const formatName = () => {
    if (!firstName || !lastName || civility === CIVILITY.NOT_SET) {
      return label;
    }

    return `${addressFormatter.formattedCivility({
      civility,
    })}${firstName} ${lastName}`;
  };
  const normalizedDistance = ():
    | Record<string, number | bigint | string>
    | undefined => {
    if (!distanceInMeters) {
      return undefined;
    }

    const distanceInMetersDistance =
      distanceInMeters < 1000
        ? distanceInMeters
        : Math.round(distanceInMeters / 100) / 10;

    return {
      distanceInMeters: intl.formatNumber(distanceInMetersDistance),
      distanceUnit: distanceInMeters < 1000 ? 'm' : 'km',
    };
  };

  return (
    <>
      <Row between="xs">
        <Col>
          {transportMethod && (
            <$AddressRow>
              <$TransportMethod>{transportMethod}</$TransportMethod>
            </$AddressRow>
          )}
          <$AddressRow>
            <>
              <div>{formatName()}</div>
              {address && <div>{address}</div>}
              {additionalAddress && <div>{additionalAddress}</div>}
              {postalCode && city ? (
                <div>
                  <span>{postalCode}</span> <span>{city}</span>
                </div>
              ) : (
                <></>
              )}
            </>
          </$AddressRow>
          {normalizedDistance()?.distanceInMeters ? (
            <$DistanceContainer>
              <LocationIcon />{' '}
              {`${intl.formatMessage(messages.distance)} ${
                normalizedDistance()?.distanceInMeters
              }${normalizedDistance()?.distanceUnit}`}
            </$DistanceContainer>
          ) : null}
          {openingHours && openingHours.length ? (
            <Button
              underline
              onClick={() => setModalIsOpen(true)}
              type={BUTTON_TYPES.BUTTON}
            >
              <SafeFormattedMessageWithoutSpread
                message={messages.openingHours}
              />
            </Button>
          ) : null}
        </Col>
        <$AddressCol>
          {imagePath && (
            <$BrandLogoContainer>
              <img src={imagePath} alt="BrandLogoContainer" />
            </$BrandLogoContainer>
          )}

          {showPrice && (
            <div>
              <$Price>
                {price ? (
                  <FormattedNumber value={price} format={priceUnit} />
                ) : (
                  <SafeFormattedMessageWithoutSpread message={messages.free} />
                )}
              </$Price>
            </div>
          )}
        </$AddressCol>
      </Row>
      <Modal isOpen={modalIsOpen} onClose={() => setModalIsOpen(false)}>
        <PickupPointItemInfo openingHours={openingHours} />
        <Button
          primary
          fluid
          hollow
          onClick={() => setModalIsOpen(false)}
          type={BUTTON_TYPES.BUTTON}
        >
          <SafeFormattedMessageWithoutSpread message={messages.cancel} />
        </Button>
      </Modal>
    </>
  );
};

AddressConfirmation.displayName = 'AddressConfirmation';

export default AddressConfirmation;
