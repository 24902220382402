import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';

export const $LayoutGrid = styled.div`
  padding: unset;
  @media ${media.maxWidth.sm} {
    padding: 1rem;
  }
`;
