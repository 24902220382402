import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useMutation } from 'react-query';

import {
  NOTIFICATION_TYPES,
  REVALIDATE_MODES,
} from '@savgroup-front-common/constants';
import { buildNotification } from '@savgroup-front-common/core/src/helpers';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { ADDITIONAL_INFORMATION_TYPES } from '@savgroup-front-common/types';
import {
  AdditionalInformation,
  AdditionalInformationExtended,
  RELATED_TO,
} from 'myaccount/types';
import { STEP_CONFIG } from 'myaccount/view/app/NewLayout/ClassiqueRoutes.config';
import {
  StepContext,
  StepContextValues,
} from 'myaccount/view/app/NewLayout/StepsProvider/StepsProvider.context';

import useGetClaimReasons from '../../app/hooks/useGetClaimReasons';
import { Claim } from '../../app/NewLayout/StepsProvider/StepsProvider.types';

import claimGroupDocumentPageRelatedToClaimSchema from './ClaimGroupDocumentPageRelatedToClaim.schema';
import { ClaimGroupDocumentRelatedToClaimValues } from './ClaimGroupDocumentPageRelatedToClaim.types';
import { uploadAdditionalInformationFilesToClaim } from './helpers/document.adapters';
import { reasonAdapter } from './helpers/reason.adapters';
import messages from './messages';

interface UseClaimGroupDocumentReturnValues {
  claims?: Claim[];
  neededInformationOnlyFileType: AdditionalInformationExtended[];
  isLoading: boolean;
  formContext: UseFormReturn<ClaimGroupDocumentRelatedToClaimValues>;
  onSubmit: () => void;
  isLoadingSubmit: boolean;
}

const useClaimGroupDocumentRelatedToClaim =
  (): UseClaimGroupDocumentReturnValues => {
    const { pushNotification, removeAllNotifications, pushErrors } =
      useToasts();
    const { values, handleNextStep, handleValidateStep, isMultiProduct } =
      useContext<StepContextValues>(StepContext);

    const claims = values.claimGroup?.claims || [];
    const claimGroupId = values.claimGroup?.claimGroupId || '';

    const { reasons, reasonsIsLoading } = useGetClaimReasons({
      claimGroupId,
    });

    const reasonSelected: any = reasons.find(
      (reason) => reason.id === claims.at(0)?.reasonId,
    );

    const reasonSelectedAdapted = reasonAdapter({
      reasonSelected,
      claimIds: claims?.map((claim) => claim.claimId) || [],
    });

    const neededInformationOnlyFileType =
      isMultiProduct && reasonSelectedAdapted
        ? reasonSelectedAdapted?.neededInformation
            ?.filter(
              (item) =>
                item.type === ADDITIONAL_INFORMATION_TYPES.FILE ||
                item.type === ADDITIONAL_INFORMATION_TYPES.MULTI_FILES,
            )
            ?.filter((item) => item.relatedTo === RELATED_TO.CLAIM)
        : reasonSelectedAdapted?.neededInformation?.filter(
            (item) =>
              item.type === ADDITIONAL_INFORMATION_TYPES.FILE ||
              item.type === ADDITIONAL_INFORMATION_TYPES.MULTI_FILES,
          );

    const formContext = useForm<ClaimGroupDocumentRelatedToClaimValues>({
      resolver: yupResolver(
        claimGroupDocumentPageRelatedToClaimSchema({
          reasonSelected: reasonSelectedAdapted,
          isMultiProduct,
        }),
      ),

      mode: REVALIDATE_MODES.ON_CHANGE,
    });

    const {
      handleSubmit,
      formState: { isValid },
    } = formContext;

    const {
      mutateAsync: handleUploadDocument,
      isLoading: isLoadingUploadDocument,
    } = useMutation(
      ['setUploadDocument'],
      async ({
        neededInformations,
        reasonAdditionalInformationDocument,
        claimId,
      }: {
        neededInformations?: ({
          internalId: string;
          fileUploadEndpoints?: Record<string, { uploadEndpoint: string }>;
        } & AdditionalInformation)[];
        reasonAdditionalInformationDocument?: Record<
          string,
          { value: File } | { value: File }[]
        >;
        claimId: string;
      }) => {
        removeAllNotifications();
        const responseAdditionalInformationDocument =
          await uploadAdditionalInformationFilesToClaim({
            neededInformations,
            reasonAdditionalInformationDocument,
            claimId,
          });

        if (responseAdditionalInformationDocument?.some((res) => res.failure)) {
          pushNotification(
            buildNotification({
              message: messages.uploadDocumentError,
              notificationType: NOTIFICATION_TYPES.ERROR,
            }),
          );

          return undefined;
        }

        return responseAdditionalInformationDocument;
      },
    );

    const onSubmit = handleSubmit(async (reasonDocument) => {
      if (!claims) {
        return undefined;
      }

      return Promise.all(
        claims.map(async (claim) => {
          const responseAdditionalInformationDocument =
            await handleUploadDocument({
              neededInformations: neededInformationOnlyFileType,
              reasonAdditionalInformationDocument:
                reasonDocument.reasonAdditionalInformationDocumentRelatedToClaim as any,
              claimId: claim.claimId,
            });

          if (!responseAdditionalInformationDocument) {
            return undefined;
          }

          return responseAdditionalInformationDocument;
        }),
      )
        .then(() =>
          handleNextStep(STEP_CONFIG.CLAIM_GROUP_SOLUTION, claimGroupId),
        )
        .catch((err) => {
          pushErrors(err);

          return undefined;
        });
    });

    useEffect(() => {
      handleValidateStep(isValid);
    }, [handleValidateStep, isValid]);

    return {
      claims,
      neededInformationOnlyFileType,
      isLoading: reasonsIsLoading,
      formContext,
      onSubmit,
      isLoadingSubmit: isLoadingUploadDocument,
    };
  };

export default useClaimGroupDocumentRelatedToClaim;
