import { Configuration } from '@pixelverse/strichjs-sdk';

export const config: Configuration = {
  selector: '#scanner',
  engine: {
    symbologies: [
      'datamatrix',
      'ean13',
      'code128',
      'code39',
      'code93',
      'codabar',
      'i25',
    ],
    duplicateInterval: 1500,
    hysteresisInterval: 50,
    invertedCodes: true,
  },
  locator: {
    regionOfInterest: {
      left: 0.25,
      right: 0.25,
      top: 0.35,
      bottom: 0.35,
    },
  },
  feedback: {
    audio: true,
    vibration: true,
  },
  overlay: {
    focusOnTap: false,
  },
  frameSource: {
    resolution: 'auto',
  },
};
