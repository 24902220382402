import { BASE_ROUTES } from './Routes';

export const ROUTES_PROFESSIONAL = {
  HOME: `${BASE_ROUTES.PROFESSIONAL}/home`,
  ISSUES_REASONS: `${BASE_ROUTES.PROFESSIONAL}/issues/reasons/:orderReference?`,
  PRODUCTS_TO_RETURN: `${BASE_ROUTES.PROFESSIONAL}/productsToReturn/:orderReference`,
  SOLUTIONS: `${BASE_ROUTES.PROFESSIONAL}/solutions/:claimGroupId`,
  DEPOSIT: `${BASE_ROUTES.PROFESSIONAL}/deposit/:claimGroupId`,
  DELIVERY: `${BASE_ROUTES.PROFESSIONAL}/delivery/:claimGroupId`,
  CONFIRMATION: `${BASE_ROUTES.PROFESSIONAL}/confirmation/:claimGroupId`,
  FILE_FOLLOWUPS: `${BASE_ROUTES.PROFESSIONAL}/files/:fileId`,
};

export const HOME_PROFESSIONAL_ROUTES = {
  PRODUCTS: `${ROUTES_PROFESSIONAL.HOME}/products`,
  REPAIRS: `${ROUTES_PROFESSIONAL.HOME}/repairs`,
};
