import { Col } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { H3 } from '../../atoms/headings';
import { getTextColorBasedOnProps } from '../../theme/helpers';

export const $TransportMethod = styled(H3)`
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
  font-size: 16px;
`;

export const $Price = styled(H3)`
  font-size: 14px;
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  line-height: 20px;
`;

export const $BrandLogoContainer = styled('div')`
  img {
    width: 40px;
  }
`;

export const $DistanceContainer = styled('div')`
  color: ${(props) =>
    getTextColorBasedOnProps({ theme: props.theme, primary: true })};
  display: flex;
  align-items: center;
  margin: 6px 0;

  svg {
    color: ${(props) =>
      getTextColorBasedOnProps({ theme: props.theme, primary: true })};
  }
`;

export const $AddressRow = styled.div`
  h3,
  p {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 24px;
  }

  h3 {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: lowercase;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  p {
    padding-top: 12px;
    font-size: 14px;
    padding-bottom: 0;
  }
  h5 {
    font-size: 14px;
    line-height: 20px;
    font-weight: ${({ theme }) => theme.fonts.weight.normal};
  }
`;

export const $AddressCol = styled(Col)`
  display: 'flex'
  flexDirection: 'column'
  justifyContent: 'space-between'
`;
