import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { BackResponseFailure } from '@savgroup-front-common/types';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.claim}claims/files?token=${COMMON_QUERY_PARAMS.TOKEN_ID}&filename=${COMMON_QUERY_PARAMS.FILE_NAME}`;

export interface GetClaimDocumentArgs {
  tokenId: string;
  fileName: string;
}

export default async function getClaimDocument(
  payload: GetClaimDocumentArgs,
): Promise<Blob | BackResponseFailure> {
  try {
    const file = await apiCall<Blob>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.TOKEN_ID, payload.tokenId).replace(
        COMMON_QUERY_PARAMS.FILE_NAME,
        payload.fileName,
      ),
      {
        method: SUPPORTED_METHODS.GET,
        responseType: 'blob',
      },
    );

    return file;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
